import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getUnsubscribeReasons } from 'store/unsubscribe-reasons/actions';
import { unsubscribeReasonsSelector } from 'store/unsubscribe-reasons/selectors';

import { usePagination } from 'hooks/usePagination';

import { getTranslation } from 'helpers/getTranslation';

import StudentsUnsubscribeReasons from './StudentsUnsubscribeReasons';

const StudentsUnsubscribeReasonsContainer = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const {
    data: unsubscribeReason,
    currentPage,
    totalPages,
    perPage,
  } = useSelector(unsubscribeReasonsSelector);

  const [loading, setLoading] = useState(true);

  const [sortParams, setSortParams] = useState({
    sortBy: {
      label: getTranslation('inbox_page.action.date'),
      value: 'createdAt',
    },
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getUnsubscribeReasons = async () => {
      try {
        await dispatch(
          getUnsubscribeReasons({
            page: 1,
            limit: perPage,
            sortBy: sortParams.sortBy.value,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getUnsubscribeReasons();
  }, [sortParams]);

  const handleSortChange = (values) => {
    setSortParams((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction: getUnsubscribeReasons,
    currentPage,
    totalPages,
    perPage,
    sortBy: sortParams.sortBy.value,
    sortDirection: sortParams.sortDirection,
  });

  return (
    <StudentsUnsubscribeReasons
      locale={i18n.locale}
      loading={loading}
      reasons={unsubscribeReason}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      sortParams={sortParams}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onSortChange={handleSortChange}
    />
  );
};

export default React.memo(StudentsUnsubscribeReasonsContainer);
