import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import App from 'App';

import { getTranslation } from 'helpers/getTranslation';

import { PrivateRoute } from 'components/PrivateRoute';

import { NotFoundPage } from 'pages/NotFoundPage';

import { LessonProvider } from 'providers/LessonProvider';
import { LessonPreviewProvider } from 'providers/LessonPreviewProvider';

import { AuthPage } from 'pages/AuthPage';
import { ForgotPasswordPage } from 'pages/ForgotPassordPage';
import { DashboardPage } from 'pages/DashboardPage';
import { ProfilePage } from 'pages/ProfilePage';
import { CoursesPage } from 'pages/CoursesPage';
import { StudentsPage } from 'pages/StudentsPage';
import { StudentPage } from 'pages/StudentPage';
import { AdminsPage } from 'pages/AdminsPage';
import { AdminPage } from 'pages/AdminPage';
import { CoursePage } from 'pages/CoursePage';
import { CoursePreviewPage } from 'pages/CoursePreviewPage';
import { FigmaAuthPage } from 'pages/FigmaAuthPage';
import { SchoolsPage } from 'pages/SchoolsPage';
import { SchoolPage } from 'pages/SchoolPage';
import { InboxPage } from 'pages/InboxPage';
import { ArcadesPage } from 'pages/ArcadesPage';
import { TrainingsPage } from 'pages/TrainingsPage';
import { ArcadePage } from 'pages/ArcadePage';
import { TrainingPage } from 'pages/TrainingPage';
import { TrainingPreviewPage } from 'pages/TrainingPreviewPage';
import { ArcadePreviewPage } from 'pages/ArcadePreviewPage';

export const router = createBrowserRouter([
  {
    path: ROUTES.HOME_PAGE,
    element: <App />,
    children: [
      {
        index: true,
        element: <AuthPage />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD_PAGE,
        element: <ForgotPasswordPage />,
      },
      {
        path: ROUTES.DASHBOARD_PAGE,
        element: (
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.PROFILE_PAGE,
        element: (
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.COURSES_PAGE,
        element: (
          <PrivateRoute>
            <CoursesPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.courses'),
        },
      },
      {
        path: ROUTES.STUDENTS_PAGE,
        element: (
          <PrivateRoute>
            <StudentsPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.students'),
        },
      },
      {
        path: ROUTES.STUDENT_PAGE,
        element: (
          <PrivateRoute>
            <StudentPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.student'),
        },
      },
      {
        path: ROUTES.ADMINS_PAGE,
        element: (
          <PrivateRoute>
            <AdminsPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.admins'),
        },
      },
      {
        path: ROUTES.ADMIN_PAGE,
        element: (
          <PrivateRoute>
            <AdminPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.admin'),
        },
      },
      {
        path: ROUTES.COURSE_PAGE,
        element: (
          <PrivateRoute>
            <CoursePage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.course'),
        },
      },
      {
        path: ROUTES.COURSE_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <CoursePreviewPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.course_preview'),
        },
      },
      {
        path: ROUTES.COURSE_LESSON_PAGE,
        element: (
          <PrivateRoute>
            <LessonProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.lesson'),
        },
      },
      {
        path: ROUTES.COURSE_LESSON_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <LessonPreviewProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => getTranslation('config.crumbs.lesson_preview'),
        },
      },
      {
        path: ROUTES.FIGMA_LESSON_AUTH_PAGE,
        element: (
          <PrivateRoute>
            <FigmaAuthPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.SCHOOLS_PAGE,
        element: (
          <PrivateRoute>
            <SchoolsPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.SCHOOL_PAGE,
        element: (
          <PrivateRoute>
            <SchoolPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.SCHOOL_PROFILE_PAGE,
        element: (
          <PrivateRoute>
            <SchoolPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.INBOX_PAGE,
        element: (
          <PrivateRoute>
            <InboxPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.ARCADES_PAGE,
        element: (
          <PrivateRoute>
            <ArcadesPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.ARCADE_PAGE,
        element: (
          <PrivateRoute>
            <ArcadePage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.ARCADE_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <ArcadePreviewPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Arcade Preview',
        },
      },
      {
        path: ROUTES.ARCADE_LESSON_PAGE,
        element: (
          <PrivateRoute>
            <LessonProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Lesson',
        },
      },
      {
        path: ROUTES.ARCADE_LESSON_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <LessonPreviewProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Lesson Preview',
        },
      },
      {
        path: ROUTES.TRAININGS_PAGE,
        element: (
          <PrivateRoute>
            <TrainingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.TRAINING_PAGE,
        element: (
          <PrivateRoute>
            <TrainingPage />
          </PrivateRoute>
        ),
      },
      {
        path: ROUTES.TRAINING_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <TrainingPreviewPage />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Training Preview',
        },
      },
      {
        path: ROUTES.TRAINING_LESSON_PAGE,
        element: (
          <PrivateRoute>
            <LessonProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Lesson',
        },
      },
      {
        path: ROUTES.TRAINING_LESSON_PREVIEW_PAGE,
        element: (
          <PrivateRoute>
            <LessonPreviewProvider />
          </PrivateRoute>
        ),
        handle: {
          crumb: () => 'Lesson Preview',
        },
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
