import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import * as api from 'api/methods';

export const useUpload = ({ multiple, value, helpers, onUploaded }) => {
  const [removeLoading, setRemoveLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const handleFileDialogOpen = () => {
    helpers.setTouched(true);
  };

  const handleDropAccepted = (files) => {
    if (!files.length) {
      return;
    }

    const currentFile = files[0];

    const fileId = uuid();

    if (multiple) {
      helpers.setValue([
        ...value,
        {
          id: fileId,
          name: currentFile.name,
          size: currentFile.size,
          type: currentFile.type,
          percentage: 0.1,
        },
      ]);
    } else {
      setPercentage(0.1);
    }

    const data = new FormData();
    data.append('file', currentFile);

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(progressEvent.progress * 100);

        if (multiple) {
          const haveFile = value.some((file) => file.id === fileId);

          const nextFiles = haveFile
            ? value.map((file) => {
                if (file.id === fileId) {
                  return {
                    ...file,
                    percentage: percentCompleted,
                  };
                }

                return file;
              })
            : [
                ...value,
                {
                  id: fileId,
                  name: currentFile.name,
                  size: currentFile.size,
                  type: currentFile.type,
                  percentage: percentCompleted,
                },
              ];

          helpers.setValue(nextFiles);
        } else {
          setPercentage(percentCompleted);
        }
      },
    };

    api
      .uploadFile({ data, config })
      .then((response) => {
        if (multiple) {
          const haveFile = value.some((file) => file.id === fileId);

          const nextFiles = haveFile
            ? value.map((file) => {
                if (file.id === fileId) {
                  return {
                    ...file,
                    url: response.path,
                  };
                }

                return file;
              })
            : [
                ...value,
                {
                  id: fileId,
                  name: response.name,
                  size: response.size,
                  type: response.type,
                  url: response.path,
                  percentage: 100,
                },
              ];

          helpers.setValue(nextFiles);
        } else {
          setPercentage(0);

          helpers.setValue(response.path);
        }

        if (onUploaded) {
          onUploaded(response);
        }
      })
      .catch(() => {});
  };

  const handleDropRejected = (event) => {
    const eventError = event[0].errors[0];

    switch (eventError.code) {
      // case EVENT_ERROR_CODE.INVALID_TYPE: {
      //   form.setFieldError(
      //     field.name,
      //     'The specified file could not be uploaded. Allowed type jpeg, png, gif, avi, mp4, mov, mkv, mpg, wmv, mts, 3gp',
      //   );

      //   break;
      // }
      // case EVENT_ERROR_CODE.LARGE_SIZE: {
      //   form.setFieldError(field.name, eventError.message);

      //   break;
      // }
      // case EVENT_ERROR_CODE.MANY_FILES: {
      //   form.setFieldError(field.name, 'No more than 3 files can be attached');

      //   break;
      // }

      default: {
        helpers.setError(eventError.message);

        break;
      }
    }
  };

  const handleRemoveClick = (fileId) => {
    let fileToRemove = null;

    if (multiple) {
      fileToRemove = value.find((file) => file.id === fileId);
    } else {
      fileToRemove = { url: value };
    }

    const filePath = fileToRemove.url.substring(1);

    setRemoveLoading(true);

    api
      .removeFile({ path: filePath })
      .then(() => {
        setRemoveLoading(false);

        if (multiple) {
          helpers.setValue([]);
        } else {
          helpers.setValue('');
        }
      })
      .catch(() => {
        setRemoveLoading(false);
      });
  };

  return [
    removeLoading,
    percentage,
    handleFileDialogOpen,
    handleDropAccepted,
    handleDropRejected,
    handleRemoveClick,
  ];
};
