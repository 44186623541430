import React from 'react';

import { useModal } from 'hooks/useModal';

// import { TheorySheet } from '../TheorySheet';
// import { HintsSheet } from '../HintsSheet';

import Tasks from './Tasks';

const TasksContainer = ({ currentTaskIndex, ...restProps }) => {
  const [hintsOpen, openHints, closeHints, hintsData] = useModal({
    defaultOpen: false,
  });
  const [theoryOpen, openTheory, closeTheory, theoryData] = useModal({
    defaultOpen: false,
  });

  const handleTaskHintsIconClick = (hintsBlocks) => {
    openHints({ blocks: hintsBlocks, taskIndex: currentTaskIndex });
  };

  const handleTaskTheoryIconClick = (theoryBlocks) => {
    openTheory({ blocks: theoryBlocks, taskIndex: currentTaskIndex });
  };

  return (
    <>
      <Tasks
        {...restProps}
        currentTaskIndex={currentTaskIndex}
        onTaskHintsIconClick={handleTaskHintsIconClick}
        onTaskTheoryIconClick={handleTaskTheoryIconClick}
      />
      {/* <TheorySheet {...theoryData} open={theoryOpen} onClose={closeTheory} />
      <HintsSheet {...hintsData} open={hintsOpen} onClose={closeHints} /> */}
    </>
  );
};

export default React.memo(TasksContainer);
