import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';

import styles from './UserMenu.styles.scss';

const UserMenu = ({
  className,
  collapsed,
  user,
  onAvatarClick,
  onLogoutClick,
}) => (
  <div
    className={cn(
      styles.menu,
      { [styles.menuCollapsed]: collapsed },
      className,
    )}
  >
    <Avatar
      className={styles.avatar}
      size="sm"
      text={user.name || user.email}
      url={user.avatar}
      onClick={onAvatarClick}
    />
    {!collapsed && (
      <>
        <div className={styles.inner} onClick={onAvatarClick}>
          <Typography
            className={styles.text}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {user.name || user.email}
          </Typography>
        </div>
        <Icon name="logout" onClick={onLogoutClick} className={styles.icon} />
      </>
    )}
  </div>
);

export default React.memo(UserMenu);
