import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './CountTab.styles.scss';

const CountTab = ({
  className,
  active,
  disabled,
  label,
  icon,
  count,
  onClick,
}) => (
  <div
    className={cn(
      styles.tab,
      { [styles.tabActive]: active, [styles.tabDisabled]: disabled },
      className,
    )}
    onClick={() => {
      if (disabled) {
        return;
      }
      onClick(label);
    }}
  >
    <Icon className={styles.icon} name={icon} />
    <Typography
      className={styles.text}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {label}
    </Typography>
    <Typography
      className={styles.count}
      variant="body3"
      weight="medium"
      mode="compact"
    >
      {count}
    </Typography>
  </div>
);

export default React.memo(CountTab);
