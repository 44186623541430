import { getTranslation } from 'helpers/getTranslation';

export const contextItems = [
  {
    key: 'heading1',
    text: getTranslation('components.theory_blocks.config.heading_1'),
    icon: 'heading1',
  },
  {
    key: 'heading2',
    text: getTranslation('components.theory_blocks.config.heading_2'),
    icon: 'heading2',
  },
  {
    key: 'heading3',
    text: getTranslation('components.theory_blocks.config.heading_3'),
    icon: 'heading3',
  },
  {
    key: 'text',
    text: getTranslation('components.theory_blocks.config.text'),
    icon: 'text',
  },
  {
    key: 'spoiler',
    text: getTranslation('components.theory_blocks.config.spoiler'),
    icon: 'spoiler',
  },
  {
    key: 'markerList',
    text: getTranslation('components.theory_blocks.config.marker_list'),
    icon: 'markerList',
  },
  {
    key: 'numberList',
    text: getTranslation('components.theory_blocks.config.number_list'),
    icon: 'numberList',
  },
  {
    key: 'image',
    text: getTranslation('components.theory_blocks.config.image'),
    icon: 'image',
  },
  {
    key: 'video',
    text: getTranslation('components.theory_blocks.config.video'),
    icon: 'video',
  },
  {
    key: 'divider',
    text: getTranslation('components.theory_blocks.config.divider'),
    icon: 'divider',
  },
];
