import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Select } from 'components/shared/Select';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { perPageOptions } from './TablePagination.config';

import styles from './TablePagination.styles.scss';

const TablePagination = ({
  prevPageDisabled,
  nextPageDisabled,
  currentPerPage,
  currentPage,
  totalPages,
  onPerPageChange,
  onNextPageClick,
  onPrevPageClick,
}) => (
  <div className={styles.pagination}>
    <div className={styles.perPage}>
      <Typography
        className={styles.perPageText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('components.table_pagination.per_page_text')}
      </Typography>
      <Select
        className={styles.perPageSelect}
        hideSelectedOptions={false}
        options={perPageOptions}
        value={currentPerPage}
        onChange={onPerPageChange}
      />
    </div>
    <div className={styles.controls}>
      <Typography
        className={styles.controlsText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('components.table_pagination.controls_text', {
          currentPage,
          totalPages,
        })}
      </Typography>
      <Button
        className={styles.control}
        disabled={prevPageDisabled}
        mode="icon"
        variant="outlined"
        size="sm"
        iconName="chevronLeft"
        onClick={onPrevPageClick}
      />
      <Button
        className={styles.control}
        disabled={nextPageDisabled}
        mode="icon"
        variant="outlined"
        size="sm"
        iconName="chevronRight"
        onClick={onNextPageClick}
      />
    </div>
  </div>
);

export default React.memo(TablePagination);
