export const getSchoolsOptions = (schools) =>
  schools.map((school) => ({
    label: school.name,
    value: school.id,
  }));

export const convertDataToApi = ({ data, isRootAdmin, schoolId }) => {
  if (isRootAdmin && data.school.value) {
    return {
      email: data.email.toLowerCase(),
      firstName: data.firstName,
      lastName: data.lastName,
      courses: data.courses,
      schoolId: data.school.value,
      isTest: data.isTest,
      isAdmin: data.isAdmin,
    };
  }

  return {
    email: data.email.toLowerCase(),
    firstName: data.firstName,
    lastName: data.lastName,
    courses: data.courses,
    schoolId,
    isTest: data.isTest,
    isAdmin: data.isAdmin,
  };
};
