import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { FIGMA_TOKEN_KEY } from 'constants/token';

import * as api from 'api/methods';

import ResyncSection from './ResyncSection';

const ResyncSectionContainer = (props) => {
  const { values } = useFormikContext();

  const [initLoading, setInitLoading] = useState(true);
  const [fileName, setFileName] = useState(null);
  const [filePageName, setFilePageName] = useState(null);
  const [haveToken, setHaveToken] = useState(
    !!localStorage.getItem(FIGMA_TOKEN_KEY),
  );

  useEffect(() => {
    const _getFigmaFile = async () => {
      try {
        const file = await api.getFigmaFile(values.fileId);

        const pageNode = file.document.children.find(
          (page) => page.id === values.filePageId,
        );

        setFileName(file.name);
        setFilePageName(pageNode.name);
      } catch (error) {
        console.warn(error);

        localStorage.removeItem(FIGMA_TOKEN_KEY);

        setHaveToken(false);
      }

      setInitLoading(false);
    };

    _getFigmaFile();
  }, []);

  return (
    <ResyncSection
      {...props}
      initLoading={initLoading}
      haveToken={haveToken}
      fileName={fileName}
      filePageName={filePageName}
    />
  );
};

export default React.memo(ResyncSectionContainer);
