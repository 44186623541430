import { createSlice } from '@reduxjs/toolkit';

import { createSchool, getSchools } from './actions';

const schoolSlice = createSlice({
  name: 'schools',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchools.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(createSchool.fulfilled, (state, action) => {
      state.push(action.payload);
    });
  },
});

export default schoolSlice.reducer;
