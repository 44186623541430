import React from 'react';
import { DragOverlay, DndContext, pointerWithin } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { SortableItem } from 'components/SortableItem';

import { DragMenu } from 'components/DragMenu';

import { ModuleItem } from '../ModuleItem';

import styles from './Modules.styles.scss';

const Modules = ({
  courseReadOnly,
  modules,
  courseType,
  sensors,
  activeDragModule,
  onModuleDragStart,
  onModuleDragEnd,
  onModuleDragOver,
  onEditLessonClick,
  onRemoveLessonClick,
  onRemoveModuleClick,
}) => (
  <div className={styles.modules}>
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={onModuleDragStart}
      onDragEnd={onModuleDragEnd}
      onDragOver={onModuleDragOver}
    >
      <SortableContext items={modules} strategy={() => null}>
        {modules.map((module, moduleIndex) => (
          <SortableItem
            className={styles.module}
            key={module.id}
            id={module.id}
          >
            {({ listeners }) => (
              <>
                {!courseReadOnly && (
                  <DragMenu
                    className={styles.moduleMenu}
                    dragListeners={listeners}
                    onRemoveClick={() => onRemoveModuleClick(module.id)}
                  />
                )}
                <ModuleItem
                  courseReadOnly={courseReadOnly}
                  module={module}
                  moduleIndex={moduleIndex}
                  courseType={courseType}
                  onEditLessonClick={onEditLessonClick}
                  onRemoveLessonClick={onRemoveLessonClick}
                />
              </>
            )}
          </SortableItem>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeDragModule ? (
          <ModuleItem
            courseReadOnly
            courseType={courseType}
            module={activeDragModule}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  </div>
);

export default React.memo(Modules);
