import { getLayerBaseProperties } from './getLayerBaseProperties';
import { getLayerProperties } from './getLayerProperties';

const _formatLayer = (layer) => ({
  id: layer.id,
  name: layer.name,
  type: layer.type,
  children: layer.children,
  properties: [
    ...getLayerBaseProperties(layer),
    ...getLayerProperties({ layer }),
  ],
});

export const _transformLayers = ({ layers, parent }) => {
  const nextLayers = [];

  for (let i = 0; i < layers.length; i++) {
    const nextLayer = _formatLayer({
      ...layers[i],
      parent,
    });

    nextLayers.push({
      ...nextLayer,
      children:
        nextLayer.children && nextLayer.children.length
          ? _transformLayers({
              layers: nextLayer.children,
              parent: { type: nextLayer.type, name: nextLayer.name },
            })
          : [],
      parent,
    });
  }

  return nextLayers;
};

export const transformComponentsLayer = (componentsLayer) => {
  if (!componentsLayer) {
    return null;
  }

  const layer = _formatLayer({
    ...componentsLayer,
    parent: null,
    children:
      componentsLayer.children && componentsLayer.children.length
        ? _transformLayers({
            layers: componentsLayer.children,
            parent: { type: componentsLayer.type, name: componentsLayer.name },
          })
        : [],
  });

  return layer;
};
