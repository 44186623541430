export const formatLayerEffect = (data) => {
  switch (data.type) {
    case 'DROP_SHADOW': {
      return {
        blendMode: data.blendMode,
        type: data.type,
        visible: data.visible,
        showShadowBehindNode: data.showShadowBehindNode,
        offset: {
          x: data.offset.x,
          y: data.offset.y,
        },
        color: data.color,
        radius: data.radius,
        spread: data.spread || 0,
      };
    }
    case 'INNER_SHADOW': {
      return {
        blendMode: data.blendMode,
        type: data.type,
        visible: data.visible,
        offset: {
          x: data.offset.x,
          y: data.offset.y,
        },
        color: data.color,
        radius: data.radius,
        spread: data.spread || 0,
      };
    }
    case 'BACKGROUND_BLUR':
    case 'LAYER_BLUR': {
      return {
        type: data.type,
        visible: data.visible,
        radius: data.radius,
      };
    }
    default: {
      console.warn('NEW EFFECT TO CONFIGURE');

      return null;
    }
  }
};
