import React from 'react';
import { useDayPicker } from 'react-day-picker';
import getUnixTime from 'date-fns/getUnixTime';

import styles from './Row.styles.scss';

const Row = ({ dates, displayMonth }) => {
  const { components } = useDayPicker();

  const DayComponent = components.Day;

  return (
    <div className={styles.row}>
      {dates.map((date) => (
        <div
          className={styles.cell}
          key={getUnixTime(date)}
          role="presentation"
        >
          <DayComponent displayMonth={displayMonth} date={date} />
        </div>
      ))}
    </div>
  );
};

export default React.memo(Row);
