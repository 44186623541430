import React from 'react';

import { RadioBox } from 'components/shared/RadioBox';

const RadioBoxGroupField = ({
  helpers,
  haveError,
  field,
  form,
  onChanged,
  ...restProps
}) => {
  const handleChange = (event) => {
    field.onChange(event);

    if (onChanged) {
      onChanged(event.target.value);
    }
  };

  return (
    <RadioBox {...restProps} checked={field.checked} onChange={handleChange} />
  );
};

export default React.memo(RadioBoxGroupField);
