import React from 'react';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';

import { STATUS_ICON, STATUS_TEXT } from 'constants/common';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Popover } from 'components/Popover';
import { ContextMenu } from 'components/ContextMenu';

import styles from './StatusSelect.styles.scss';

const SIZES = {
  sm: styles.sm,
  lg: styles.lg,
};

const StatusSelect = ({
  className,
  contextItems,
  status,
  size,
  onContextItemClick,
}) => (
  <Popover
    placement="bottom-end"
    triggerElement={({ isMounted }) => (
      <div
        className={cn(
          styles.status,
          styles[`status${_upperFirst(status)}`],
          SIZES[size],
          {
            [styles.statusActive]: isMounted,
          },
          className,
        )}
      >
        <Icon className={styles.icon} name={STATUS_ICON[status]} />
        <Typography
          className={styles.text}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          {STATUS_TEXT[status]}
        </Typography>
        <Icon className={styles.dropdownIcon} name="chevronDown" />
      </div>
    )}
  >
    <ContextMenu
      className={styles.menu}
      items={contextItems}
      onItemClick={(contextItem) => onContextItemClick(contextItem)}
    />
  </Popover>
);

StatusSelect.defaultProps = {
  size: 'lg',
};

export default React.memo(StatusSelect);
