import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import { SOCIAL_ICONS } from './SocialLinks.constants';

import styles from './SocialLinks.styles.scss';

const SocialLinks = ({ className, iconWrapperClassName, iconClassName }) => {
  return (
    <div className={cn(styles.icons, className)}>
      {SOCIAL_ICONS.map((socialIcon, socialIconIndex) => (
        <div
          className={cn(styles.iconWrapper, iconWrapperClassName)}
          key={socialIconIndex}
          onClick={() => {
            window.open(socialIcon.link, '_blank');
          }}
        >
          <Icon
            name={socialIcon.icon}
            className={cn(styles.socialIcon, iconClassName)}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(SocialLinks);
