import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { Task } from '../Task';

import styles from './Tasks.styles.scss';

const Tasks = ({
  tasks,
  currentTaskIndex,
  onTaskHintsIconClick,
  onTaskTheoryIconClick,
  isTestMode,
}) => (
  <div className={styles.tasks}>
    <Typography
      className={styles.title}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('figma_lesson_preview_page.tasks.text')} (
      {currentTaskIndex + 1}/{tasks.length})
    </Typography>
    <div className={styles.inner}>
      {tasks.map((task, taskIndex) => (
        <Task
          key={task.id}
          {...task}
          current={currentTaskIndex === taskIndex}
          taskIndex={taskIndex}
          onHintsIconClick={onTaskHintsIconClick}
          onTheoryIconClick={onTaskTheoryIconClick}
          isTestMode={isTestMode}
        />
      ))}
    </div>
  </div>
);

export default React.memo(Tasks);
