import axios from 'axios';
import qs from 'qs';

// ------------------- COURSE API ------------------- //
export const createArcade = (data) => axios.post('/api/v1/arcades', data);

export const updateArcadeById = (
  { arcadeId, data }, // NEW
) => axios.put(`/api/v1/arcades/${arcadeId}`, data);

export const submitArcade = (arcadeId) =>
  axios.post(`/api/v1/arcades/${arcadeId}/submit`);

export const publishArcade = (arcadeId) =>
  axios.post(`/api/v1/arcades/${arcadeId}/publish`);

export const archiveArcade = (arcadeId) =>
  axios.post(`/api/v1/arcades/${arcadeId}/archive`);

export const restoreArcade = (arcadeId) =>
  axios.post(`/api/v1/arcades/${arcadeId}/restore`);

export const removeArcade = (arcadeId) =>
  axios.delete(`/api/v1/arcades/${arcadeId}`);

export const getArcades = (params) =>
  axios.get(`/api/v1/arcades?${qs.stringify(params)}`);

export const getArcadeById = (arcadeId) =>
  axios.get(`/api/v1/arcades/${arcadeId}`);

export const getArcadesStats = () => axios.get('/api/v1/arcades/stats');

export const getArcadeStats = (arcadeId) =>
  axios.get(`/api/v1/arcades/${arcadeId}/stats`);

export const getArcadeStatsByStudent = ({ arcadeId, studentId }) =>
  axios.get(`/api/v1/arcades/${arcadeId}/stats/${studentId}`);

// ------------------- COURSE API ------------------- //

// ------------------- COURSE MODULES API ------------------- //

export const getArcadeModules = (arcadeId) =>
  axios.get(`/api/v1/arcades/${arcadeId}/modules`);

export const createArcadeModule = ({ arcadeId, data }) =>
  axios.post(`/api/v1/arcades/${arcadeId}/modules`, data);

export const updateArcadeModuleById = ({ arcadeId, moduleId, data }) =>
  axios.put(`/api/v1/arcades/${arcadeId}/modules/${moduleId}`, data);

export const removeArcadeModuleById = ({ arcadeId, moduleId }) =>
  axios.delete(`/api/v1/arcades/${arcadeId}/modules/${moduleId}`);

export const reorderArcadeModules = ({ arcadeId, data }) =>
  axios.post(`/api/v1/arcades/${arcadeId}/modules/reorder`, data);

// ------------------- COURSE MODULES API ------------------- //

// ------------------- COURSE LESSONS API ------------------- //

export const getArcadeLessons = (arcadeId) =>
  axios.get(`/api/v1/arcades/${arcadeId}/lessons`);

export const reorderArcadeLessons = ({ arcadeId, data }) =>
  axios.post(`/api/v1/arcades/${arcadeId}/lessons/reorder`, data);

export const getArcadeLessonById = ({ arcadeId, lessonId }) =>
  axios.get(`/api/v1/arcades/${arcadeId}/lessons/${lessonId}`);

export const removeArcadeLessonById = ({ arcadeId, lessonId }) =>
  axios.delete(`/api/v1/arcades/${arcadeId}/lessons/${lessonId}`);
