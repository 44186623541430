import React from 'react';
import { useDayPicker } from 'react-day-picker';

import styles from './Head.styles.scss';

const Head = () => {
  const { components } = useDayPicker();

  const HeadRowComponent = components.HeadRow;

  return (
    <div className={styles.head}>
      <HeadRowComponent />
    </div>
  );
};

export default React.memo(Head);
