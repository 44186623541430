import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './UploadResultHeader.styles.scss';

const UploadResultHeader = ({
  triggerIconClassName,
  iconName,
  iconColor,
  title,
  rowsCount,
}) => (
  <div className={styles.header}>
    <div className={styles.inner}>
      <Icon
        name={iconName}
        style={{
          color: iconColor,
        }}
        className={styles.icon}
      />
      <Typography
        variant="body2"
        weight="medium"
        mode="compact"
        className={styles.label}
      >
        {title}:
      </Typography>
      <Typography
        variant="body2"
        weight="medium"
        mode="compact"
        className={styles.text}
      >
        {getTranslation('components.import_admins.count_rows', { rowsCount })}
      </Typography>
    </div>

    <Icon
      name="chevronDown"
      className={cn(styles.triggerIcon, triggerIconClassName)}
    />
  </div>
);

export default React.memo(UploadResultHeader);
