import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getArcadeById } from 'store/arcade/actions';
import { currentArcadeSelector } from 'store/arcade/selectors';

import { getTranslation } from 'helpers/getTranslation';

import TrainingPreviewPage from './ArcadePreviewPage';

const ArcadePreviewPageContainer = () => {
  const dispatch = useDispatch();

  const { id: arcadeId } = useParams();

  const arcade = useSelector(currentArcadeSelector);

  const [loading, setLoading] = useState(true);
  const [previewType, setPreviewType] = useState(
    getTranslation('common.text.plugin'),
  );

  useEffect(() => {
    const _getCourseById = async () => {
      try {
        await dispatch(getArcadeById(arcadeId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCourseById();
  }, []);

  return (
    <TrainingPreviewPage
      loading={loading}
      arcade={arcade}
      previewType={previewType}
      setPreviewType={setPreviewType}
    />
  );
};

export default React.memo(ArcadePreviewPageContainer);
