import React from 'react';

import { Loading } from 'components/shared/Loading';

import { MenuLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { ListToolbar } from 'components/Toolbar';

import { InfoCard } from './components/InfoCard';
import { CourseCard } from './components/CourseCard';
import { ModulesCard } from './components/ModulesCard';
import { LessonsCard } from './components/LessonsCard';
import { CourseSelect } from './components/CourseSelect';
import { StudentsCard } from './components/StudentsCard';
import { ProgressCard } from './components/ProgressCard';

import {
  prepareLessonsProgress,
  prepareOverallProgress,
  prepareStudents,
  getSelectCourseOptions,
  getCurrentCourseOption,
} from './DashboardPage.helpers';
import { getTranslation } from 'helpers/getTranslation';

import styles from './DashboardPage.styles.scss';

const DashboardPage = ({
  locale,
  loadingCourses,
  loadingCourseStats,
  isMoreInfo,
  isCurrentCourseStatsEmpty,
  courses,
  currentCourse,
  currentCourseStats,
  onCourseChange,
  onMoreInfoToggle,
}) => (
  <MenuLayout>
    {loadingCourses ? (
      <Loading className={styles.loading} />
    ) : (
      <>
        <div className={styles.header}>
          <ListToolbar title={getTranslation('dashboard_page.title')} />
          {courses && courses.length && (
            <CourseSelect
              options={getSelectCourseOptions(courses)}
              value={getCurrentCourseOption(currentCourse)}
              onChange={onCourseChange}
            />
          )}
        </div>
        <div className={styles.inner}>
          {(!courses || courses.length === 0) && (
            <EmptyState
              className={styles.emptyCoursesState}
              icon="drafts"
              title={getTranslation('dashboard_page.empty_stats_state.title')}
              text={getTranslation('dashboard_page.empty_stats_state.text')}
            />
          )}
          {loadingCourseStats ? (
            <Loading />
          ) : (
            <div className={styles.card}>
              <CourseCard
                // isStatsEmpty={isStatsEmpty}
                isMoreInfo={isMoreInfo}
                preview={currentCourse.preview}
                title={currentCourse.title}
                status={currentCourse.status}
                onMoreClick={onMoreInfoToggle}
              />
              {isCurrentCourseStatsEmpty ? (
                <EmptyState
                  className={styles.emptyState}
                  icon="drafts"
                  title={getTranslation(
                    'dashboard_page.empty_stats_state.title',
                  )}
                  text={getTranslation('dashboard_page.empty_stats_state.text')}
                />
              ) : (
                <>
                  {isMoreInfo ? (
                    <InfoCard
                      courseStructure={currentCourse.structure}
                      courseStats={currentCourseStats}
                      className={styles.infoCard}
                    />
                  ) : (
                    <>
                      <div className={styles.section}>
                        <ProgressCard
                          className={styles.sectionItem}
                          data={prepareOverallProgress(
                            currentCourseStats.overallProgress,
                          )}
                          studentsCount={
                            currentCourseStats.studentsProgress.length
                          }
                        />
                        {currentCourseStats.modulesProgress && (
                          <ModulesCard
                            className={styles.sectionItem}
                            modules={currentCourseStats.modulesProgress}
                          />
                        )}
                      </div>
                      <div className={styles.section}>
                        <StudentsCard
                          locale={locale}
                          className={styles.sectionItem}
                          data={prepareStudents(currentCourseStats.students)}
                        />
                        <LessonsCard
                          className={styles.sectionItem}
                          {...prepareLessonsProgress(
                            currentCourseStats.lessonsProgress,
                          )}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </>
    )}
  </MenuLayout>
);

export default React.memo(DashboardPage);
