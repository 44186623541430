import { createSlice } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';

import { ARCADE_STRUCTURES } from 'constants/common';

import { removeLessonById, updateLessonStatusById } from 'store/lesson/actions';

import {
  getArcadeById,
  updateArcadeById,
  createArcadeModule,
  updateArcadeModuleById,
  removeArcadeModuleById,
  reorderArcadeLessons,
  reorderArcadeModules,
  updateArcadeStatus,
} from './actions';

const arcadeSlice = createSlice({
  name: 'currentArcade',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArcadeById.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateArcadeById.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(createArcadeModule.fulfilled, (state, action) => {
      return {
        ...state,
        modules: [...(state.modules || []), { ...action.payload, lessons: [] }],
      };
    });

    builder.addCase(updateArcadeModuleById.fulfilled, (state, action) => {
      return {
        ...state,
        modules: state.modules.map((module) => {
          if (module.id === action.payload.id) {
            return {
              ...module,
              ...action.payload,
            };
          }

          return module;
        }),
      };
    });

    builder.addCase(removeArcadeModuleById.fulfilled, (state, action) => {
      return {
        ...state,
        modules: state.modules.filter(
          (module) => module.id !== action.payload.id,
        ),
      };
    });

    builder.addCase(updateArcadeStatus.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(removeLessonById.fulfilled, (state, action) => {
      if (!state || !state.structure) {
        return state;
      }

      if (state.structure === ARCADE_STRUCTURES.MODULES) {
        return {
          ...state,
          modules: state.modules.map((module) => ({
            ...module,
            lessons: module.lessons.filter(
              (lesson) => lesson.id !== action.payload.id,
            ),
          })),
        };
      } else {
        return {
          ...state,
          lessons: state.lessons.filter(
            (lesson) => lesson.id !== action.payload.id,
          ),
        };
      }
    });

    builder.addCase(updateLessonStatusById.fulfilled, (state, action) => {
      if (!state || !state.structure) {
        return state;
      }

      if (state.structure === ARCADE_STRUCTURES.MODULES) {
        return {
          ...state,
          modules: state.modules.map((module) => ({
            ...module,
            lessons: module.lessons.map((lesson) => {
              if (lesson.id === action.payload.id) {
                return {
                  ...lesson,
                  status: action.payload.status,
                };
              }

              return lesson;
            }),
          })),
        };
      } else {
        return {
          ...state,
          lessons: state.lessons.map((lesson) => {
            if (lesson.id === action.payload.id) {
              return {
                ...lesson,
                status: action.payload.status,
              };
            }

            return lesson;
          }),
        };
      }
    });

    builder.addCase(reorderArcadeLessons.fulfilled, (state, action) => {
      const { moduleId, lessons } = action.payload;

      return {
        ...state,
        modules: state.modules.map((module) => {
          if (module.id === moduleId) {
            return {
              ...module,
              lessons,
            };
          }

          return module;
        }),
      };
    });

    builder.addCase(reorderArcadeModules.fulfilled, (state, action) => {
      return {
        ...state,
        modules: _orderBy(
          state.modules.map((currentModule) => {
            const nextModule = action.payload.find(
              (module) => module.id === currentModule.id,
            );

            return {
              ...nextModule,
              lessons: currentModule.lessons,
            };
          }),
          ['order'],
          ['asc'],
        ),
      };
    });
  },
});

export default arcadeSlice.reducer;
