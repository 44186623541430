import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Button } from 'components/shared/Button';

import styles from './UploadPreview.styles.scss';

const SIZES = {
  sm: styles.sm,
  md: styles.md,
};

const UploadPreview = ({
  className,
  readOnly,
  loading,
  haveError,
  value,
  size,
  text,
  percentage,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      SIZES[size],
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      { [styles.uploadReadOnly]: readOnly },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {value && !loading && !readOnly && (
      <>
        <div className={styles.actionOverlay} />
        <Button
          className={styles.action}
          variant="ghost"
          mode="icon"
          size="md"
          iconName="trash"
          iconColor="#fff"
          onClick={onRemoveClick}
        />
      </>
    )}
    {value ? (
      <img src={value} className={styles.preview} />
    ) : (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <LinearProgressBar variant="upload" progress={percentage} />
        ) : (
          <div
            className={styles.inner}
            onClick={!readOnly ? onBrowseClick : () => {}}
          >
            <Icon name="image" className={styles.icon} />
            {size !== 'sm' && text && (
              <Typography
                className={styles.text}
                variant="body3"
                weight="medium"
                mode="compact"
              >
                {text}
              </Typography>
            )}
          </div>
        )}
      </>
    )}
  </div>
);

UploadPreview.defaultProps = {
  size: 'md',
};

export default React.memo(UploadPreview);
