import { useState, useMemo } from 'react';
import _debounce from 'lodash/debounce';

export const useSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchQueryChange = (event) => {
    const nextSearchQuery = event.target.value;

    setSearchQuery(nextSearchQuery);
  };

  const debouncedSearchQueryChange = useMemo(() => {
    return _debounce(handleSearchQueryChange, 300);
  }, []);

  return [searchQuery, debouncedSearchQueryChange];
};
