import * as Yup from 'yup';

import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const getInitialValues = ({
  isSchool,
  schools,
  courses,
  currentSchoolId,
}) => {
  const currentSchool = schools.find((school) => school.id === currentSchoolId);
  const isDefaultSchool = currentSchool
    ? currentSchool.name === 'Befront'
    : false;

  if (!isSchool) {
    return {
      email: '',
      firstName: '',
      lastName: '',
      school: '',
      courses: [],
      isTest: false,
      isAdmin: false,
    };
  }

  return {
    email: '',
    firstName: '',
    lastName: '',
    school: '',
    courses: isDefaultSchool
      ? courses
          .filter((course) => course.status === STATUSES.PUBLISHED)
          .map((course) => course.id)
      : [],
    isTest: false,
    isAdmin: false,
  };
};

export const getValidationSchema = ({ isRootAdmin, isSchool }) => {
  if (isRootAdmin && !isSchool) {
    return Yup.object().shape({
      school: Yup.object().required(
        getTranslation('components.add_student.validation.school'),
      ),
      email: Yup.string()
        .email(getTranslation('components.add_student.validation.email_type'))
        .required(getTranslation('components.add_student.validation.email')),
      firstName: Yup.string().ensure(),
      lastName: Yup.string().ensure(),
      courses: Yup.array().ensure(),
    });
  }

  return Yup.object().shape({
    email: Yup.string()
      .email(getTranslation('components.add_student.validation.email_type'))
      .required(getTranslation('components.add_student.validation.email')),
    firstName: Yup.string().ensure(),
    lastName: Yup.string().ensure(),
    courses: Yup.array().ensure(),
  });
};
