import React from 'react';
import cn from 'classnames';
import pluralize from 'pluralize';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './LessonsCount.styles.scss';

const LessonsCount = ({ className, iconClassName, count }) => (
  <div className={cn(styles.count, className)}>
    <Typography
      className={styles.text}
      variant="body3"
      weight="medium"
      mode="compact"
    >
      {pluralize('lessons', count, true)}
    </Typography>
    <Icon className={cn(styles.icon, iconClassName)} name="chevronDown" />
  </div>
);

export default React.memo(LessonsCount);
