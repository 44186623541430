import * as api from 'api/methods';

import { getTranslation } from 'helpers/getTranslation';

export const COURSE_STEPS = [
  {
    key: 'remove-students',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_students',
    ),
    request: api.removeCourseStudents,
  },
  {
    key: 'remove-lessons',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_lessons',
    ),
    request: api.removeCourseLessons,
  },
  {
    key: 'remove-modules',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_modules',
    ),
    request: api.removeCourseModules,
  },
  {
    key: 'remove-course-resources',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_course_resources',
    ),
    request: api.removeCourse,
  },
];

export const STUDENT_STEPS = [
  {
    key: 'remove-courses',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_courses',
    ),
    request: api.removeStudentCourses,
  },
  {
    key: 'remove-reviews',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_reviews',
    ),
    request: api.removeStudentReviews,
  },
  {
    key: 'remove-ratings',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_ratings',
    ),
    request: api.removeStudentRatings,
  },
  {
    key: 'remove-reasons',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_reasons',
    ),
    request: api.removeStudentReports,
  },
  {
    key: 'remove-student',
    title: getTranslation(
      'components.remove_with_steps_modal.config.remove_student',
    ),
    request: api.removeStudentById,
  },
];
