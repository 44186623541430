import React from 'react';
import { useFormikContext, getIn } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SwitchField } from 'components/FormikFields';
import { TheoryBlocks } from 'components/TheoryBlocks';

import styles from './HintsSection.styles.scss';

const HintsSection = ({ namePrefix }) => {
  const { values } = useFormikContext();

  const withHints = getIn(values, `${namePrefix}.withHints`);

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <FormikField
          withControl={false}
          name={`${namePrefix}.withHints`}
          component={SwitchField}
          componentProps={{ readOnly: values.readOnly, type: 'checkbox' }}
        />
        <Typography
          className={styles.headerText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTranslation('figma_lesson_page.main_settings.hints_section_text')}
        </Typography>
      </div>
      {withHints && (
        <div className={styles.inner}>
          <TheoryBlocks namePrefix={`${namePrefix}.hintsBlocks`} />
        </div>
      )}
    </div>
  );
};

export default React.memo(HintsSection);
