import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getSchools = createAsyncThunk(types.GET_SCHOOLS, async () => {
  const schools = await api.getSchools();

  return schools;
});

export const createSchool = createAsyncThunk(
  types.CREATE_SCHOOL,
  async (data) => {
    const school = await api.createSchool(data);

    return school;
  },
);
