import React, { useState, useEffect } from 'react';

import { getInitialTableData } from './InfoByModule.helpers';

import InfoByModule from './InfoByModule';

const InfoByModuleContainer = ({
  courseStructure,
  courseStats,
  ...restProps
}) => {
  const [data, setData] = useState(
    getInitialTableData({ courseStructure, courseStats }),
  );

  useEffect(() => {
    setData(getInitialTableData({ courseStructure, courseStats }));
  }, [courseStats]);

  const handleRowClick = (item) => {
    if (item.cellType === 'MODULE' && !item.expanded) {
      const nextData = data.map((module) => {
        if (module.id === item.id) {
          return { ...item, expanded: true };
        }

        return module;
      });
      const moduleIndex = nextData.findIndex((module) => module.id === item.id);
      const lessons = courseStats.lessonsProgress
        .filter((lesson) => lesson.moduleId === item.id)
        .map((lesson) => ({
          ...lesson,
          cellType: 'LESSON',
        }));

      setData([
        ...nextData.slice(0, moduleIndex + 1),
        ...lessons,
        ...nextData.slice(moduleIndex + 1),
      ]);
    }

    if (item.cellType === 'MODULE' && item.expanded) {
      setData(
        data
          .filter(
            (element) =>
              element.cellType === 'MODULE' ||
              (element.cellType === 'LESSON' && element.moduleId !== item.id),
          )
          .map((module) => {
            if (module.id === item.id) {
              return { ...item, expanded: false };
            }

            return module;
          }),
      );
    }
  };

  return (
    <InfoByModule
      {...restProps}
      data={data}
      courseStructure={courseStructure}
      onRowClick={handleRowClick}
    />
  );
};

export default React.memo(InfoByModuleContainer);
