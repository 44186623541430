import React from 'react';

import { useModal } from 'hooks/useModal';

import { MoreItems } from 'components/MoreItems';

import MoreItemsButton from './MoreItemsButton';

const MoreItemsButtonContainer = ({ courses, item, ...restProps }) => {
  const [moreItemsOpen, openMoreItems, closeMoreItems] = useModal({
    defaultOpen: false,
  });

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    openMoreItems();
  };

  return (
    <>
      <MoreItemsButton {...restProps} onClick={handleClick} />
      <MoreItems
        open={moreItemsOpen}
        courses={courses}
        item={item}
        onClose={closeMoreItems}
      />
    </>
  );
};

export default React.memo(MoreItemsButtonContainer);
