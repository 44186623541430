import { getTranslation } from 'helpers/getTranslation';

export const getArcadesIcon = (format) => {
  if (format === 'GAME') {
    return 'game';
  }
  if (format === 'CHALLENGE') {
    return 'challenge';
  }
};

export const getArcadesText = (format) => {
  if (format === 'GAME') {
    return getTranslation('course_page.format_select.game');
  }

  if (format === 'CHALLENGE') {
    return getTranslation('course_page.format_select.challenge');
  }
};
