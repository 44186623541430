import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Typography } from 'components/shared/Typography';

import styles from './Breadcrumbs.styles.scss';

const Breadcrumbs = ({ breadcrumbs }) => (
  <div className={styles.breadcrumbs}>
    <div className={styles.items}>
      {breadcrumbs.map(({ match, breadcrumb }, breadcrumbIndex) => (
        <div className={styles.item} key={breadcrumbIndex}>
          {breadcrumbIndex === breadcrumbs.length - 1 ? (
            <Typography
              className={styles.text}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {breadcrumb}
            </Typography>
          ) : (
            <Link className={styles.link} to={match.pathname}>
              {breadcrumb}
            </Link>
          )}
          {breadcrumbIndex !== breadcrumbs.length - 1 && (
            <Icon className={styles.icon} name="chevronRight" />
          )}
        </div>
      ))}
    </div>
  </div>
);

export default React.memo(Breadcrumbs);
