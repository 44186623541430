import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Input } from 'components/shared/Input';
import { Button } from 'components/shared/Button';

import styles from './TableToolbar.styles.scss';

const TableToolbar = ({
  searchExpanded,
  onSearchChange,
  onSearchBlur,
  onSearchBtnClick,
}) => (
  <div className={styles.toolbar}>
    <div className={styles.searchAction}>
      {!searchExpanded && (
        <Button
          className={styles.searchBtn}
          variant="ghost"
          mode="icon"
          size="md"
          iconName="search"
          onClick={onSearchBtnClick}
        />
      )}
      {searchExpanded && (
        <Input
          className={styles.searchInput}
          fieldClassName={styles.searchField}
          autoFocus
          endAdornment={<Icon name="search" className={styles.searchIcon} />}
          onChange={onSearchChange}
          onBlur={onSearchBlur}
        />
      )}
    </div>
  </div>
);

export default React.memo(TableToolbar);
