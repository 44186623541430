import { getTranslation } from 'helpers/getTranslation';

export const getStatusText = (status) => {
  switch (status) {
    case 'ACTIVE': {
      return getTranslation('common.text.active');
    }
    case 'BLOCKED': {
      return getTranslation('common.text.blocked');
    }
    case 'PENDING': {
      return getTranslation('common.text.pending');
    }
  }
};
