import { getTranslation } from 'helpers/getTranslation';

export const getStudentsTableColumns = () => [
  {
    key: 'name',
    label: getTranslation('school_page.students.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('school_page.students.table_keys.email'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('school_page.students.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'courses',
    label: getTranslation('school_page.students.table_keys.courses'),
    sortable: false,
  },
  {
    key: 'status',
    label: getTranslation('school_page.students.table_keys.status'),
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: getTranslation('school_page.students.table_keys.updated_at'),
    sortable: true,
  },
];
