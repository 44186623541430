export const getPropertyNamePrefix = ({
  namePrefix,
  properties,
  propertyKey,
}) => {
  const propertyIndex = properties.findIndex(
    (property) => property.key === propertyKey,
  );

  return `${namePrefix}.properties[${propertyIndex}]`;
};
