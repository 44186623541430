import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateCourseById } from 'store/course/actions';
import { currentCourseSelector } from 'store/course/selectors';

import { getTranslation } from 'helpers/getTranslation';
import { showToast } from 'helpers/showToast';

import CourseSettings from './CourseSettings';

const CourseSettingsContainer = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const course = useSelector(currentCourseSelector);

  const [saveLoading, setSaveLoading] = useState(false);

  const handleSaveClick = async (data) => {
    try {
      await dispatch(updateCourseById({ courseId, data })).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_saved.title'),
          text: getTranslation('common.toast.course_saved.text'),
        },
      });

      onClose();
    } catch (error) {
      showToast({
        variant: 'error',
        title: getTranslation('common.toast.error.title'),
        text: getTranslation('common.toast.error.text'),
      });
    }

    setSaveLoading(false);
  };

  return (
    <CourseSettings
      {...restProps}
      saveLoading={saveLoading}
      course={course}
      onClose={onClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(CourseSettingsContainer);
