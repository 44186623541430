import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { Action } from '../Action';
import { CurrentAnswer } from '../CurrentAnswer';

import styles from './WebPreviewPage.styles.scss';

const WebPreviewPage = ({
  className,
  submitted,
  nextLoading,
  isLastLessonInCourse,
  currentAnswer,
  currentCompareAnswer,
  lesson,
  onAnswerClick,
  onNextLessonClick,
  onRestartLessonClick,
  isTestMode,
}) => (
  <div className={cn(styles.page, className)}>
    <Typography variant="subtitle">{lesson.text}</Typography>
    {submitted ? (
      <CurrentAnswer
        answer={currentAnswer}
        compareAnswer={currentCompareAnswer}
      />
    ) : (
      <div className={styles.answers}>
        <div
          className={cn(styles.answer, {
            [styles.readOnlyAnswer]: !isTestMode,
          })}
        >
          <img
            className={styles.answerImg}
            src={lesson.leftItem.url}
            onClick={() => {
              if (!isTestMode) {
                return;
              }
              onAnswerClick({
                answer: lesson.leftItem,
                compareAnswer: lesson.rightItem,
              });
            }}
          />
        </div>
        <div
          className={cn(styles.answer, {
            [styles.readOnlyAnswer]: !isTestMode,
          })}
        >
          <img
            className={styles.answerImg}
            src={lesson.rightItem.url}
            onClick={() => {
              if (!isTestMode) {
                return;
              }
              onAnswerClick({
                answer: lesson.rightItem,
                compareAnswer: lesson.leftItem,
              });
            }}
          />
        </div>
      </div>
    )}
    <div className={styles.actions}>
      {isTestMode && (
        <Action
          submitted={submitted}
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          onNextLessonClick={onNextLessonClick}
          onRestartLessonClick={onRestartLessonClick}
        />
      )}
    </div>
  </div>
);

export default React.memo(WebPreviewPage);
