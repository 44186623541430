import { formatLayerBaseProperties } from './formatLayerBaseProperties';
import { formatLayerProperties } from './formatLayerProperties';

export const formatLayers = (layers) => {
  const nextLayers = [];

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];

    nextLayers.push({
      ...layer,
      children: formatLayers(layer.children),
      properties: {
        ...formatLayerBaseProperties(layer.properties),
        ...formatLayerProperties({
          layerType: layer.type,
          properties: layer.properties,
        }),
      },
    });
  }

  return nextLayers;
};
