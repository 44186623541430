import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';

import { isAuthenticatedSelector } from 'store/auth/selectors';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.HOME_PAGE} />;
  }

  return children;
};

export default React.memo(PrivateRoute);
