import React from 'react';
import cn from 'classnames';

import { Tooltip } from 'components/Tooltip';

import { MenuTab } from './components/MenuTab';
import { SwitchTab } from './components/SwitchTab';
import { CountTab } from './components/CountTab';
import { DefaultTab } from './components/DefaultTab';
import { PropertiesTab } from './components/PropertiesTab';

import styles from './Tabs.styles.scss';

const Tabs = ({
  className,
  innerClassName,
  items,
  actions,
  startActions,
  variant,
  activeTab,
  onTabClick,
  textClassName,
}) => (
  <div className={cn(styles.tabs, className)}>
    <div
      className={cn(styles.header, {
        [styles.headerSwitch]: variant === 'switch',
      })}
    >
      {startActions.length > 0 && (
        <div className={styles.actions}>
          {startActions.map((action) => {
            if (action.withTooltip) {
              return (
                <Tooltip
                  key={action.key}
                  text={action.tooltipText}
                  triggerElement={<action.component key={action.key} />}
                />
              );
            }

            return action.component;
          })}
        </div>
      )}
      <div
        className={cn(styles.items, {
          [styles.itemsMenu]: variant === 'menu',
          [styles.itemsSwitch]: variant === 'switch',
        })}
      >
        {items.map((item) => {
          const { label, icon, count, disabled } = item;

          switch (variant) {
            case 'count': {
              return (
                <CountTab
                  key={label}
                  label={label}
                  icon={icon}
                  count={count}
                  active={activeTab === label}
                  disabled={disabled}
                  onClick={onTabClick}
                />
              );
            }
            case 'menu': {
              return (
                <MenuTab
                  key={label}
                  label={label}
                  icon={icon}
                  active={activeTab === label}
                  disabled={disabled}
                  onClick={onTabClick}
                />
              );
            }
            case 'properties': {
              return (
                <PropertiesTab
                  key={label}
                  label={label}
                  active={activeTab === label}
                  disabled={disabled}
                  onClick={onTabClick}
                />
              );
            }
            case 'switch': {
              return (
                <SwitchTab
                  key={label}
                  label={label}
                  icon={icon}
                  active={activeTab === label}
                  disabled={disabled}
                  onClick={onTabClick}
                />
              );
            }
            default: {
              return (
                <DefaultTab
                  key={label}
                  label={label}
                  active={activeTab === label}
                  disabled={disabled}
                  onClick={onTabClick}
                  textClassName={textClassName}
                />
              );
            }
          }
        })}
      </div>
      {actions.length > 0 && (
        <div className={styles.actions}>
          {actions.map((action) => {
            if (action.withTooltip) {
              return (
                <Tooltip
                  key={action.key}
                  text={action.tooltipText}
                  triggerElement={<action.component key={action.key} />}
                />
              );
            }

            return action.component;
          })}
        </div>
      )}
    </div>
    <div
      className={cn(
        styles.inner,
        { [styles.innerMenu]: variant === 'menu' },
        { [styles.innerProperties]: variant === 'properties' },
        innerClassName,
      )}
    >
      {items
        .filter((item) => item.label === activeTab)
        .map((item) => {
          if (!item.content) {
            return null;
          }

          return item.content;
        })}
    </div>
  </div>
);

Tabs.defaultProps = {
  startActions: [],
  actions: [],
  variant: 'default',
};

export default React.memo(Tabs);
