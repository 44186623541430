import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const initialValues = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      getTranslation(
        'forgot_password_page.form_config.email_format_validation',
      ),
    )
    .required(
      getTranslation(
        'forgot_password_page.form_config.email_required_validation',
      ),
    ),
});
