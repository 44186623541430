import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCourseById } from 'store/course/actions';
import { currentCourseSelector } from 'store/course/selectors';

import { getTranslation } from 'helpers/getTranslation';

import CoursePreviewPage from './CoursePreviewPage';

const CoursePreviewPageContainer = () => {
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const course = useSelector(currentCourseSelector);

  const [loading, setLoading] = useState(true);
  const [previewType, setPreviewType] = useState(
    getTranslation('common.text.plugin'),
  );

  useEffect(() => {
    const _getCourseById = async () => {
      try {
        await dispatch(getCourseById(courseId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCourseById();
  }, []);

  return (
    <CoursePreviewPage
      loading={loading}
      course={course}
      previewType={previewType}
      setPreviewType={setPreviewType}
    />
  );
};

export default React.memo(CoursePreviewPageContainer);
