import React from 'react';
import { Formik } from 'formik';

import { ARCADE_STRUCTURES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { MainLayout } from 'components/Layout';
import { BreadcrumbsToolbar } from 'components/Toolbar';
import { AutoSave } from 'components/AutoSave';
import { ToolbarActions } from 'components/ToolbarActions';
import { ReadOnlySection } from 'components/ReadOnlySection';

import { MainSettings } from 'pages/CoursePage/components/MainSettings';
import { LessonsSettings } from 'pages/CoursePage/components/LessonsSettings';
import { ModulesSettings } from 'pages/CoursePage/components/ModulesSettings';
import { StructureSelect } from 'pages/CoursePage/components/StructureSelect';
import { CourseResultPreview } from 'pages/CoursePage/components/CourseResultPreview';

import {
  convertDataToForm,
  getContextItems,
  getReadonlyArcadeText,
} from './ArcadePage.helpers';

import { moreContextItems } from './ArcadePage.config';

import styles from 'pages/CoursePage/CoursePage.styles.scss';

const ArcadePage = ({
  locale,
  loading,
  isRootAdmin,
  saveLoading,
  autoSaveLoading,
  readOnly,
  arcade,
  lastActionAt,
  onAutoSaveSubmit,
  onSaveClick,
  onPreviewClick,
  onStatusContextItemClick,
  onMoreContextItemClick,
}) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={convertDataToForm(arcade)}
    onSubmit={onAutoSaveSubmit}
  >
    {({ values, setFieldValue }) =>
      loading ? (
        <Loading />
      ) : (
        <MainLayout
          toolbarComponent={
            <BreadcrumbsToolbar
              breadcrumbsData={{
                courseTitle:
                  values.title || getTranslation('common.text.untitled'),
              }}
              endAdornment={
                <ToolbarActions
                  locale={locale}
                  saveLoading={saveLoading}
                  isRootAdmin={isRootAdmin}
                  autoSaveLoading={autoSaveLoading}
                  contextItems={getContextItems(arcade.status)}
                  moreContextItems={moreContextItems}
                  updatedAt={lastActionAt}
                  status={arcade.status}
                  onSaveClick={() => onSaveClick(values)}
                  onPreviewClick={onPreviewClick}
                  onStatusContextItemClick={(item) =>
                    onStatusContextItemClick(item, { values, setFieldValue })
                  }
                  onMoreContextItemClick={onMoreContextItemClick}
                />
              }
            />
          }
        >
          <div className={styles.page}>
            <AutoSave debounceMs={1000} />
            {readOnly && (
              <ReadOnlySection text={getReadonlyArcadeText(arcade.status)} />
            )}
            <MainSettings type={arcade.type} />
            <CourseResultPreview type={arcade.type} />
            {!values.structure && !readOnly && <StructureSelect />}
            {values.structure === ARCADE_STRUCTURES.MODULES && (
              <ModulesSettings />
            )}
            {values.structure === ARCADE_STRUCTURES.LESSONS && (
              <LessonsSettings />
            )}
          </div>
        </MainLayout>
      )
    }
  </Formik>
);

export default React.memo(ArcadePage);
