import React from 'react';
import cn from 'classnames';
import { useFormikContext, getIn } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './NumberListBlock.styles.scss';

const NumberListBlock = ({ readOnly, namePrefix, onKeyDown }) => {
  const { values } = useFormikContext();

  const items = getIn(values, `${namePrefix}.data`);

  return (
    <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
      {items.map((_, itemIndex) => (
        <div className={styles.item}>
          <div className={styles.itemOrder}>
            <Typography
              className={styles.itemOrderText}
              variant="body3"
              mode="compact"
              weight="medium"
            >
              {itemIndex + 1}
            </Typography>
          </div>
          <FormikField
            name={`${namePrefix}.data[${itemIndex}]`}
            withControl={false}
            component={EditableTextField}
            componentProps={{
              className: styles.itemText,
              readOnly,
              variant: 'text',
              autoFocus: true,
              placeholder: getTranslation(
                'components.theory_blocks.list_placeholder',
              ),
              onKeyDown: (event) => onKeyDown(event, itemIndex),
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(NumberListBlock);
