import React from 'react';

import styles from './DividerBlock.styles.scss';

const DividerBlock = () => (
  <div className={styles.block}>
    <div className={styles.divider} />
  </div>
);

export default React.memo(DividerBlock);
