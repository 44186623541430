import React from 'react';

import LogoIcon from 'assets/img/logo.svg';

import { getTranslation } from 'helpers/getTranslation';

import { Link } from 'components/shared/Link';
import { Typography } from 'components/shared/Typography';

import { SocialLinks } from 'components/SocialLinks';

import { AuthForm } from './components/AuthForm';

import styles from './AuthPage.styles.scss';

const AuthPage = () => (
  <div className={styles.page}>
    <LogoIcon className={styles.logo} />
    <div className={styles.inner}>
      <Typography variant="heading2">
        {getTranslation('auth_page.text')}
      </Typography>
      <div className={styles.form}>
        <AuthForm />
      </div>
      <Typography className={styles.agreementText} variant="body3">
        {getTranslation('auth_page.agreement_text')}{' '}
        <Link
          className={styles.agreementLink}
          variant="body3"
          target="_blank"
          href="https://befront.io/terms-of-use"
        >
          {getTranslation('auth_page.terms_link')}
        </Link>{' '}
        {getTranslation('auth_page.and')}{' '}
        <Link
          className={styles.agreementLink}
          variant="body3"
          target="_blank"
          href="https://befront.io/privacy-policy"
        >
          {getTranslation('auth_page.privacy_link')}
        </Link>
      </Typography>
    </div>
    <SocialLinks
      className={styles.icons}
      iconWrapperClassName={styles.iconWrapper}
      iconClassName={styles.icon}
    />
    <Typography variant="body3" className={styles.helpText}>
      {getTranslation('auth_page.help_text')}
    </Typography>
    <Link
      className={styles.helpLink}
      href="mailto:info@befront.io"
      variant="body3"
      mode="compact"
    >
      {getTranslation('auth_page.help_link')}
    </Link>
  </div>
);

export default React.memo(AuthPage);
