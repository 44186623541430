import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import * as api from 'api/methods';

import { STATUSES } from 'constants/common';

import { updateLessonById } from 'store/lesson/actions';

import { currentCourseSelector } from 'store/course/selectors';
import { currentLessonSelector } from 'store/lesson/selectors';

import { showToast } from 'helpers/showToast';
import { getChangedPropertiesByKeys } from 'helpers/getChangedPropertiesByKeys';
import { getTranslation } from 'helpers/getTranslation';

import { useStatusChange } from 'hooks/useStatusChange';

import {
  convertDataToApi,
  getChangedProperties,
  checkQuestionsValidation,
} from './DifferenceLessonPage.helpers';

import DifferenceLessonPage from './DifferenceLessonPage';

const DifferenceLessonPageContainer = (props) => {
  const lesson = useSelector(currentLessonSelector);

  const [saveLoading, setSaveLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [lastActionAt, setLastActionAt] = useState(lesson.updatedAt);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courseId, lessonId } = useParams();

  const course = useSelector(currentCourseSelector);

  const [onStatusContextItemClick] = useStatusChange(lesson);

  const handleStatusContextItemClick = (
    contextItem,
    values,
    { setFieldValue },
  ) => {
    if (
      contextItem.key === STATUSES.PUBLISHED &&
      !checkQuestionsValidation(values)
    ) {
      showToast({
        data: {
          variant: 'warning',
          title: getTranslation('common.toast.answer_warning.title'),
          text: getTranslation('common.toast.answer_warning.text'),
        },
      });
      setFieldValue(
        'statusError',
        getTranslation('difference_lesson_page.status_error_text'),
      );

      return;
    }

    onStatusContextItemClick(contextItem, convertDataToApi(values));
  };

  const handleAutoSaveSubmit = async (values) => {
    if (lessonId !== values.id) {
      return;
    }

    const data = convertDataToApi(values);

    const changedPropertiesKeys = getChangedProperties({
      currentLesson: lesson,
      nextLesson: data,
    });

    if (changedPropertiesKeys && changedPropertiesKeys.length > 0) {
      setAutoSaveLoading(true);

      const updatedLesson = await api.updateDifferenceLessonById({
        lessonId,
        data: getChangedPropertiesByKeys({ data, keys: changedPropertiesKeys }),
      });

      setLastActionAt(updatedLesson.updatedAt);

      setAutoSaveLoading(false);
    }
  };

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    try {
      const data = convertDataToApi(values);

      const changedPropertiesKeys = getChangedProperties({
        currentLesson: lesson,
        nextLesson: data,
      });

      const updatedLesson = await dispatch(
        updateLessonById({
          lessonId,
          lessonType: lesson.type,
          data,
          changedPropertiesKeys,
        }),
      ).unwrap();

      setLastActionAt(updatedLesson.updatedAt);

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.lesson_saved.title'),
          text: getTranslation('common.toast.lesson_saved.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const handlePreviewClick = () => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/preview`);
  };

  const lessonReadOnly = lesson.status !== STATUSES.DRAFT;

  return (
    <DifferenceLessonPage
      {...props}
      saveLoading={saveLoading}
      autoSaveLoading={autoSaveLoading}
      readOnly={lessonReadOnly}
      lesson={lesson}
      lastActionAt={lastActionAt}
      courseTitle={course.title || getTranslation('common.text.untitled')}
      courseStatus={course.status}
      onAutoSaveSubmit={handleAutoSaveSubmit}
      onSaveClick={handleSaveClick}
      onPreviewClick={handlePreviewClick}
      onStatusContextItemClick={handleStatusContextItemClick}
    />
  );
};

export default React.memo(DifferenceLessonPageContainer);
