import React from 'react';
import { useFormikContext } from 'formik';

import PrototypeSection from './PrototypeSection';

const PrototypeSectionContainer = ({
  properties,
  namePrefix,
  ...restProps
}) => {
  const { setFieldValue } = useFormikContext();

  const categoryKey = 'prototype';
  const categoryProperties = ['reactions'];

  const handleCategoryCheckChanged = (_, checked) => {
    const propertiesIndexes = properties.reduce(
      (indexes, property, propertyIndex) => {
        if (categoryProperties.includes(property.key)) {
          indexes.push(propertyIndex);
        }

        return indexes;
      },
      [],
    );

    for (const propertyIndex of propertiesIndexes) {
      setFieldValue(
        `${namePrefix}.properties[${propertyIndex}].check`,
        checked,
      );
    }
  };

  return (
    <PrototypeSection
      {...restProps}
      properties={properties}
      categoryProperties={categoryProperties}
      categoryKey={categoryKey}
      onCategoryCheckChanged={handleCategoryCheckChanged}
    />
  );
};

export default React.memo(PrototypeSectionContainer);
