import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';

import { IconTextProperty } from '../IconTextProperty';
import { TextTextProperty } from '../TextTextProperty';
import { BooleanProperty } from '../BooleanProperty';

import styles from './LayerProperties.styles.scss';

const LayerProperties = ({
  isInitialState,
  readOnly,
  designType,
  properties,
  namePrefix,
  onPropertyChanged,
}) => {
  return (
    <div className={styles.properties}>
      <div className={styles.propertiesRow}>
        <IconTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'blendMode',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'blendMode', properties })}
          onChanged={onPropertyChanged}
        />
        <TextTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'opacity',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'opacity', properties })}
          onChanged={onPropertyChanged}
        />
        <TextTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'layerOrder',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'layerOrder', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <BooleanProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'locked',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'locked', properties })}
          onChanged={onPropertyChanged}
        />
        <BooleanProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'visible',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'visible', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
    </div>
  );
};

export default React.memo(LayerProperties);
