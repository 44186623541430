import React from 'react';

import { Typography } from 'components/shared/Typography';

import { formatDate } from 'helpers/formatDate';

import { StudentInfo } from '../StudentInfo';

import styles from './StudentsReviewsItem.styles.scss';

const StudentsReviewsItem = ({ studentReview, locale }) => {
  return (
    <>
      <div className={styles.header}>
        <StudentInfo data={studentReview} />
        <Typography variant="body3" className={styles.date}>
          {studentReview.createdAt &&
            formatDate(studentReview.createdAt, 'dd MMM, yyyy HH:MM', locale)}
        </Typography>
      </div>
      <div className={styles.content}>
        <Typography variant="body3">{studentReview.text}</Typography>
      </div>
    </>
  );
};

export default React.memo(StudentsReviewsItem);
