import React from 'react';
import { useFormikContext, getIn } from 'formik';

import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './GeneralSection.styles.scss';

const GeneralSection = ({ namePrefix, taskIndex, designType }) => {
  const { values } = useFormikContext();

  const task = getIn(values, namePrefix);

  if (!task) {
    return null;
  }

  const isInitialState = namePrefix === 'initialState';

  return (
    <div className={styles.section}>
      {designType === 'DESIGN' && (
        <div className={styles.preview}>
          <img className={styles.previewImg} src={task.preview} />
        </div>
      )}
      {isInitialState ? (
        <div className={styles.info}>
          <Typography className={styles.infoTitle} variant="subtitle">
            {getTranslation(
              'figma_lesson_page.main_settings.general_section.initial_state',
            )}
          </Typography>
          <Typography className={styles.infoText} variant="body1">
            {getTranslation(
              'figma_lesson_page.main_settings.general_section.initial_text',
            )}
          </Typography>
        </div>
      ) : (
        <div className={styles.inner}>
          <Typography className={styles.title} variant="subtitle">
            {getTranslation(
              'figma_lesson_page.main_settings.general_section.task',
            )}{' '}
            {taskIndex + 1}
          </Typography>
          <FormikField
            name={`${namePrefix}.text`}
            withControl={false}
            component={EditableTextField}
            componentProps={{
              readOnly: values.readOnly,
              className: styles.text,
              variant: 'text',
              multiline: true,
              placeholder: getTranslation(
                'figma_lesson_page.main_settings.general_section_placeholder',
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(GeneralSection);
