import axios from 'axios';
import qs from 'qs';

export const getSchoolCodes = ({ schoolId, params }) =>
  axios.get(`/api/v1/school-codes/${schoolId}?${qs.stringify(params)}`);

export const generateSchoolCodes = (data) =>
  axios.post('/api/v1/school-codes/generate', data);

export const exportSchoolCodes = (schoolId, data) =>
  axios.post(`/api/v1/school-codes/${schoolId}/export`, data, {
    responseType: 'blob',
  });
