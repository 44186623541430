import { createSlice } from '@reduxjs/toolkit';

import {
  createStudent,
  getStudents,
  getSchoolStudents,
  finishImportStudents,
  removeStudentById,
} from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  perPage: 10,
  totalPages: null,
};

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudents.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(getSchoolStudents.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(createStudent.fulfilled, (state, action) => {
      const existingStudent = state.data.some(
        (student) => student.id === action.payload.id,
      );

      return {
        ...state,
        data: existingStudent
          ? state.data.map((student) => {
              if (student.id === action.payload.id) {
                return action.payload;
              }

              return student;
            })
          : [...state.data, action.payload],
      };
    });

    builder.addCase(finishImportStudents.fulfilled, (state, action) => {
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    });

    builder.addCase(removeStudentById, (state, action) => {
      return {
        ...state,
        data: state.data.filter((student) => student.id !== action.payload),
      };
    });
  },
});

export default studentsSlice.reducer;
