import { createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getAdmins = createAsyncThunk(
  types.GET_ADMINS,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const admins = isRootAdmin
      ? await api.getAdmins(params)
      : await api.getSchoolAdmins({ schoolId: user.schoolId, params });

    return admins;
  },
);

export const getSchoolAdmins = createAsyncThunk(
  types.GET_SCHOOL_ADMINS,
  async (data) => {
    const admins = await api.getSchoolAdmins(data);

    return admins;
  },
);

export const createAdmin = createAsyncThunk(
  types.CREATE_ADMIN,
  async (data) => {
    const admin = await api.createAdmin(data);

    return admin;
  },
);

export const finishImportAdmins = createAsyncThunk(
  types.FINISH_IMPORT_ADMINS,
  async (data) => {
    const admins = await api.finishImportAdmins(data);

    return admins;
  },
);
