import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import templateFile from './import-admins.xlsx';

import { finishImportAdmins } from 'store/admins/actions';

import { useFileUpload } from './ImportAdmins.hooks';

import ImportAdmins from './ImportAdmins';

const ImportAdminsContainer = ({ schoolId, onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const swiperRef = useRef();

  const [importedAdmins, setImportedAdmins] = useState([]);

  const handleUploaded = (response) => {
    setImportedAdmins(response);

    swiperRef.current.slideNext();
  };

  const [percentage, uploadError, handleDropAccepted, handleDropRejected] =
    useFileUpload({
      onUploaded: handleUploaded,
    });

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleDownloadTemplateClick = () => {
    const link = document.createElement('a');

    link.href = templateFile;

    link.setAttribute('download', 'import-admins-template.xlsx');

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const handleImportClick = async () => {
    try {
      await dispatch(
        finishImportAdmins({
          schoolId,
          data: {
            admins: importedAdmins
              .filter((importedAdmin) => importedAdmin.status === 'SUCCESS')
              .map((importedAdmin) => ({
                email: importedAdmin.email,
                firstName: importedAdmin.firstName,
                lastName: importedAdmin.lastName,
              })),
          },
        }),
      ).unwrap();

      onClose();
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <ImportAdmins
      {...restProps}
      importedAdmins={importedAdmins}
      percentage={percentage}
      uploadError={uploadError}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      onClose={onClose}
      onSwiper={handleSwiper}
      onImportClick={handleImportClick}
      onDownloadTemplateClick={handleDownloadTemplateClick}
    />
  );
};

export default React.memo(ImportAdminsContainer);
