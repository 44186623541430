import React from 'react';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';

import { Input } from 'components/shared/Input';
import { Typography } from 'components/shared/Typography';

import styles from './TimeInput.styles.scss';

const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

const TimeInput = ({
  currentFormat,
  inputRef,
  onTimeFormatChange,
  ...restProps
}) => (
  <MaskedInput
    {...restProps}
    keepCharPositions
    mask={mask}
    guide={false}
    placeholderChar=" "
    render={(ref, props) => (
      <Input
        {...props}
        fieldClassName={cn(styles.field, props.fieldClassName)}
        inputRef={(element) => {
          ref(element);

          if (inputRef) {
            inputRef.current = element;
          }
        }}
      />
    )}
    endAdornment={
      <div className={styles.switcher}>
        <div
          className={cn(styles.item, {
            [styles.itemActive]: currentFormat === 'AM',
          })}
          onClick={() => onTimeFormatChange('AM')}
        >
          <Typography variant="body2" weight="medium" className={styles.text}>
            AM
          </Typography>
        </div>
        <div
          className={cn(styles.item, {
            [styles.itemActive]: currentFormat === 'PM',
          })}
          onClick={() => onTimeFormatChange('PM')}
        >
          <Typography variant="body2" weight="medium" className={styles.text}>
            PM
          </Typography>
        </div>
      </div>
    }
  />
);

export default React.memo(TimeInput);
