import React from 'react';
import { useParams } from 'react-router-dom';
import qs from 'qs';

import { FIGMA_CLIENT_ID, FIGMA_STATE } from 'constants/env';
import { FIGMA_TOKEN_KEY } from 'constants/token';

import FigmaFileAccess from './FigmaFileAccess';

const FigmaFileAccessContainer = (props) => {
  const { courseId, lessonId } = useParams();

  const handleProvideAccessClick = () => {
    const baseParams = {
      redirect_uri: `${window.location.origin}/auth/figma`,
      client_id: FIGMA_CLIENT_ID,
      state: JSON.stringify({
        figmaState: FIGMA_STATE,
        courseId,
        lessonId,
      }),
      scope: 'file_read',
      response_type: 'code',
    };

    const url = `https://www.figma.com/oauth?${qs.stringify(baseParams)}`;

    window.location.href = url;
  };

  const figmaToken = localStorage.getItem(FIGMA_TOKEN_KEY);

  if (figmaToken) {
    return null;
  }

  return (
    <FigmaFileAccess
      {...props}
      onProvideAccessClick={handleProvideAccessClick}
    />
  );
};

export default React.memo(FigmaFileAccessContainer);
