import React from 'react';
import { useDayPicker } from 'react-day-picker';

import { Typography } from 'components/shared/Typography';

import { getWeekdays } from './HeadRow.helpers';

import styles from './HeadRow.styles.scss';

const HeadRow = () => {
  const {
    showWeekNumber,
    locale,
    weekStartsOn,
    ISOWeek,
    formatters: { formatWeekdayName },
    labels: { labelWeekday },
  } = useDayPicker();

  const weekdays = getWeekdays(locale, weekStartsOn, ISOWeek);

  return (
    <div className={styles.row}>
      {showWeekNumber && <div className={styles.cell} />}
      {weekdays.map((weekday, weekdayIndex) => (
        <div
          key={weekdayIndex}
          scope="col"
          className={styles.cell}
          aria-label={labelWeekday(weekday, { locale })}
        >
          <Typography
            className={styles.text}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {formatWeekdayName(weekday, { locale })}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default React.memo(HeadRow);
