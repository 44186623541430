import { STATUSES } from 'constants/common';
import _orderBy from 'lodash/orderBy';
import parse from 'date-fns/parse';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

export const getSelectCourseOptions = (courses) =>
  courses.map((course) => ({
    label: course.title || getTranslation('common.text.untitled'),
    value: course.id,
  }));

export const getCurrentCourseOption = (course) => {
  if (!course) {
    return null;
  }

  return {
    label: course.title || getTranslation('common.text.untitled'),
    value: course.id,
  };
};

export const prepareOverallProgress = (data) => {
  const progressData = [
    {
      id: 'completed',
      label: getTranslation('dashboard_page.helpers.completed'),
      value: data.completed,
      color: 'rgb(133, 224, 171)',
    },
    {
      id: 'inProgress',
      label: getTranslation('dashboard_page.helpers.in_progress'),
      value: data.inProgress,
      color: 'rgb(112, 180, 250)',
    },
    {
      id: 'notStarted',
      label: getTranslation('dashboard_page.helpers.not_started'),
      value: data.notStarted,
      color: 'rgb(253, 206, 130)',
    },
  ];

  if (data.completed === 0 && data.notStarted === 0 && data.inProgress === 0) {
    return [{ id: null, label: '', color: 'rgb(235, 250, 241)', value: true }];
  }

  return progressData;
};

export const prepareStudents = (students) => {
  const data = students.map((student) => ({
    ...student,
    date: formatDate(student.date, 'yyyy-MM-dd'),
  }));

  const groupedData = _orderBy(
    data,
    [(student) => parse(student.date, 'yyyy-MM-dd', new Date())],
    ['asc'],
  ).reduce((acc, item) => {
    if (!acc[item.date]) {
      acc[item.date] = { x: item.date, y: 1 };
    } else {
      acc[item.date].y += 1;
    }

    return acc;
  }, {});

  return [
    {
      id: 'students',
      color: 'rgb(133, 224, 171)',
      data: Object.values(groupedData),
    },
  ];
};

export const prepareLessonsProgress = (lessons) => {
  const lessonsCount = lessons.length;

  const completed = Number(
    (
      lessons.reduce(
        (lessonsCompleted, lesson) =>
          (lessonsCompleted += lesson.completedPercent),
        0,
      ) / lessonsCount
    ).toFixed(0),
  );
  const notStarted = Number(
    (
      lessons.reduce(
        (lessonsNotStarted, lesson) =>
          (lessonsNotStarted += lesson.notStartedPercent),
        0,
      ) / lessonsCount
    ).toFixed(0),
  );

  const completedData = [
    {
      id: 'completed',
      label: getTranslation('dashboard_page.helpers.completed'),
      value: completed,
      color: 'rgb(133, 224, 171)',
    },
    {
      id: 'notStarted',
      label: getTranslation('dashboard_page.helpers.not_started'),
      value: notStarted,
      color: 'rgb(223, 233, 249)',
    },
  ];

  const notStartedData = [
    {
      id: 'notStarted',
      label: getTranslation('dashboard_page.helpers.not_started'),
      value: notStarted,
      color: 'rgb(253, 206, 130)',
    },
    {
      id: 'empty',
      label: getTranslation('dashboard_page.helpers.empty'),
      value: completed,
      color: 'rgb(223, 233, 249)',
    },
  ];

  return {
    lessonsCount,
    completed,
    notStarted,
    completedData,
    notStartedData,
  };
};

export const checkCourseStatsEmpty = ({ course, courseStats }) => {
  if (!courseStats || !course) {
    return true;
  }

  if (course.status !== STATUSES.PUBLISHED) {
    return true;
  }
};
