import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Cell.styles.scss';

const Cell = ({
  children,
  className,
  minWidth,
  maxWidth,
  justifyContent,
  ...restProps
}) => (
  <div
    className={cn(styles.cell, className)}
    style={{ minWidth, maxWidth, justifyContent }}
    {...restProps}
  >
    <Typography className={styles.text} variant="body3" mode="compact">
      {children}
    </Typography>
  </div>
);

export default React.memo(Cell);
