import React from 'react';
import cn from 'classnames';

import { STATUS_ICON, STATUS_TEXT } from 'constants/common';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './PublishStatusTag.styles.scss';

const STATUS_VARIANTS = {
  draft: styles.draft,
  published: styles.published,
  archived: styles.archived,
};

const PublishStatusTag = ({ className, variant }) => (
  <div className={cn(styles.tag, STATUS_VARIANTS[variant], className)}>
    <Icon className={styles.icon} name={STATUS_ICON[variant]} />
    <Typography
      className={styles.text}
      variant="body3"
      weight="medium"
      mode="compact"
    >
      {STATUS_TEXT[variant]}
    </Typography>
  </div>
);

export default React.memo(PublishStatusTag);
