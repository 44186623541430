import React from 'react';
import { DragOverlay, DndContext, pointerWithin } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { DragMenu } from 'components/DragMenu';
import { SortableItem } from 'components/SortableItem';

import { Question } from '../Question';

import styles from './Questions.styles.scss';

const Questions = ({
  readOnly,
  questions,
  sensors,
  activeDragQuestion,
  activeDragQuestionIndex,
  onQuestionDragStart,
  onQuestionDragEnd,
  onQuestionDragOver,
  onAddQuestionClick,
  onRemoveQuestionClick,
}) => (
  <>
    {questions && questions.length > 0 && (
      <div className={styles.questions}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={onQuestionDragStart}
          onDragEnd={onQuestionDragEnd}
          onDragOver={onQuestionDragOver}
        >
          <SortableContext items={questions} strategy={() => null}>
            {questions.map((question, questionIndex) => (
              <SortableItem
                className={styles.question}
                key={question.id}
                id={question.id}
              >
                {({ listeners }) => (
                  <>
                    {!readOnly && (
                      <DragMenu
                        className={styles.questionMenu}
                        dragListeners={listeners}
                        onRemoveClick={() => onRemoveQuestionClick(question.id)}
                      />
                    )}
                    <Question
                      {...question}
                      readOnly={readOnly}
                      questionIndex={questionIndex}
                    />
                  </>
                )}
              </SortableItem>
            ))}
          </SortableContext>
          <DragOverlay>
            {activeDragQuestion ? (
              <Question
                {...activeDragQuestion}
                questionIndex={activeDragQuestionIndex}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    )}
    {!readOnly && (
      <Button
        className={styles.addBtn}
        size="sm"
        variant="link"
        startIcon={<Icon name="plus" />}
        onClick={onAddQuestionClick}
      >
        {getTranslation('common.button.add_question')}
      </Button>
    )}
  </>
);

export default React.memo(Questions);
