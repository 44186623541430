import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ReactModal from 'react-modal';

import { router } from 'config/router';
import { setupAxiosInterceptors } from 'config/axios';

import store from 'store';

import 'config/i18n';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import 'swiper/css';
import 'assets/styles/styles.scss';

setupAxiosInterceptors();

ReactModal.setAppElement('#bef-app');

const root = createRoot(document.getElementById('bef-app'));

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
