import React from 'react';
import cn from 'classnames';

import { bytesToSize } from 'helpers/bytesToSize';
import { getFileTypeIcon } from 'helpers/getFileTypeIcon';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Button } from 'components/shared/Button';

import styles from './UploadMaterials.styles.scss';

const UploadMaterials = ({
  readOnly,
  className,
  haveError,
  value,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <div className={styles.files}>
      {value.map((file) => (
        <div key={file.id} className={styles.file}>
          {!readOnly && (
            <Button
              className={styles.fileRemoveIcon}
              variant="ghost"
              mode="icon"
              size="sm"
              iconName="trash"
              onClick={() => onRemoveClick(file)}
            />
          )}
          <Icon className={styles.fileIcon} name={getFileTypeIcon(file.type)} />
          <div className={styles.fileInner}>
            <Typography
              className={styles.fileName}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {file.name}
            </Typography>
            <Typography
              className={styles.fileSize}
              variant="body3"
              mode="compact"
            >
              {bytesToSize(file.size)}
            </Typography>
            <div className={styles.fileProgress}>
              <LinearProgressBar progress={file.percentage} />
              <Typography
                className={styles.fileProgressText}
                variant="body3"
                mode="compact"
              >
                {file.percentage}%
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
    {!readOnly && (
      <div {...getRootProps()} className={styles.inner}>
        <input {...getInputProps()} className={styles.field} />
        <Button
          className={styles.addBtn}
          size="sm"
          variant="link"
          startIcon={<Icon name="plus" />}
          onClick={onBrowseClick}
        >
          Add file
        </Button>
      </div>
    )}
  </div>
);

export default React.memo(UploadMaterials);
