import React from 'react';

import { useUpload } from 'hooks/useUpload';

import { Upload } from 'components/shared/Upload';

const UploadField = ({
  multiple,
  field,
  helpers,
  onUploaded,
  ...restProps
}) => {
  const [
    removeLoading,
    percentage,
    onFileDialogOpen,
    onDropAccepted,
    onDropRejected,
    onRemoveClick,
  ] = useUpload({
    multiple,
    value: field.value,
    helpers,
    onUploaded,
  });

  return (
    <Upload
      {...restProps}
      removeLoading={removeLoading}
      name={field.name}
      value={field.value}
      percentage={percentage}
      onFileDialogOpen={onFileDialogOpen}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      onRemoveClick={onRemoveClick}
    />
  );
};

export default React.memo(UploadField);
