import React from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableItem } from 'components/SortableItem';

import { DragMenu } from 'components/DragMenu';

import { LessonItem } from '../LessonItem';

import styles from './Lessons.styles.scss';

const Lessons = ({
  courseReadOnly,
  structure,
  sensors,
  lessons,
  onLessonDragEnd,
  onEditLessonClick,
  onRemoveLessonClick,
}) => (
  <div className={styles.lessons}>
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onLessonDragEnd}
    >
      <SortableContext items={lessons} strategy={verticalListSortingStrategy}>
        {lessons.map((lesson) => (
          <SortableItem
            className={styles.lesson}
            key={lesson.id}
            id={lesson.id}
          >
            {({ listeners }) => (
              <>
                {!courseReadOnly && (
                  <DragMenu
                    className={styles.lessonMenu}
                    dragListeners={listeners}
                    onRemoveClick={() => onRemoveLessonClick(lesson)}
                  />
                )}
                <LessonItem
                  {...lesson}
                  courseReadOnly={courseReadOnly}
                  structure={structure}
                  onEditClick={onEditLessonClick}
                />
              </>
            )}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  </div>
);

export default React.memo(Lessons);
