import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { studentsSelector } from 'store/students/selectors';

import { usePagination } from 'hooks/usePagination';

import { getTranslation } from 'helpers/getTranslation';

import { showToast } from 'helpers/showToast';
import { copyToClipboard } from 'helpers/copyToClipboard';

import StudentsTable from './StudentsTable';

const StudentsTableContainer = ({ dataAction, params, ...restProps }) => {
  const { i18n } = useTranslation();
  const { currentPage, totalPages, perPage } = useSelector(studentsSelector);

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction,
    currentPage,
    totalPages,
    perPage,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  const handleCopyEmailClick = (email) => {
    copyToClipboard(email);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.clipboard_copy.title'),
        text: getTranslation('common.toast.clipboard_copy.text'),
      },
    });
  };

  return (
    <StudentsTable
      {...restProps}
      locale={i18n.language}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      params={params}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onCopyEmailClick={handleCopyEmailClick}
    />
  );
};

export default React.memo(StudentsTableContainer);
