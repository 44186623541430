import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { v4 as uuid } from 'uuid';

import { getTranslation } from 'helpers/getTranslation';

import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import Column from './Column';

const ColumnContainer = ({ namePrefix, ...restProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const [removeModalOpen, openRemoveModal, closeRemoveModal, removeModalData] =
    useModal({
      defaultOpen: false,
    });

  const variants = getIn(values, namePrefix);

  const handleAddVariantClick = () => {
    setFieldValue(namePrefix, [...variants, { id: uuid(), text: '' }]);
  };

  const handleRemoveVariantClick = (variantId) => {
    openRemoveModal({
      data: variantId,
      title: getTranslation('two_columns_lesson_page.remove_modal.title'),
      text: getTranslation('two_columns_lesson_page.remove_modal.text'),
    });
  };

  const handleConfirmRemoveClick = (variantId) => {
    setFieldValue(
      namePrefix,
      variants.filter((variant) => variant.id !== variantId),
    );

    closeRemoveModal();
  };

  return (
    <>
      <Column
        {...restProps}
        variants={variants}
        namePrefix={namePrefix}
        onAddVariantClick={handleAddVariantClick}
        onRemoveVariantClick={handleRemoveVariantClick}
      />
      <DefaultModal
        {...removeModalData}
        open={removeModalOpen}
        confirmText={getTranslation(
          'two_columns_lesson_page.remove_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveModal}
        onClose={closeRemoveModal}
      />
    </>
  );
};

export default React.memo(ColumnContainer);
