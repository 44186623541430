import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import styles from './AutoLayoutModeProperty.styles.scss';

const _renderContent = ({ value: { layoutMode, layoutWrap } }) => (
  <>
    <div
      className={cn(styles.item, {
        [styles.itemActive]:
          layoutMode.value === 'VERTICAL' && layoutWrap.value === 'NO_WRAP',
      })}
    >
      <Icon className={styles.itemIcon} name="layoutVertical" />
    </div>
    <div
      className={cn(styles.item, {
        [styles.itemActive]:
          layoutMode.value === 'HORIZONTAL' && layoutWrap.value === 'NO_WRAP',
      })}
    >
      <Icon className={styles.itemIcon} name="layoutHorizontal" />
    </div>
    <div
      className={cn(styles.item, {
        [styles.itemActive]:
          layoutMode.value === 'HORIZONTAL' && layoutWrap.value === 'WRAP',
      })}
    >
      <Icon className={styles.itemIcon} name="layoutWrap" />
    </div>
  </>
);

const _renderField = ({ namePrefix, readOnly, property, onChanged }) => (
  <FormikField
    name={namePrefix}
    withControl={false}
    component={PropertySelectField}
    componentProps={{
      type: 'checkbox',
      readOnly,
      property,
      renderProperty: _renderContent,
      onChanged,
    }}
  />
);

const AutoLayoutModeProperty = ({
  isInitialState,
  readOnly,
  tooltip,
  designType,
  namePrefix,
  property,
  onChanged,
}) => {
  if (isInitialState || designType === 'PROTOTYPE') {
    if (tooltip) {
      return (
        <Tooltip
          text={tooltip}
          triggerElement={
            <div className={styles.propertyReadOnly}>
              {_renderContent(property)}
            </div>
          }
        />
      );
    }

    return (
      <div className={styles.propertyReadOnly}>{_renderContent(property)}</div>
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        text={tooltip}
        triggerElement={_renderField({
          namePrefix,
          readOnly,
          property,
          onChanged,
        })}
      />
    );
  }

  return _renderField({
    namePrefix,
    readOnly,
    property,
    onChanged,
  });
};

export default React.memo(AutoLayoutModeProperty);
