import React from 'react';
import { useFormikContext, getIn } from 'formik';

import { COURSE_TYPES } from 'constants/common';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SwitchField, UploadField } from 'components/FormikFields';

import { getTitleByType } from './CourseResultPreview.helpers';

import styles from './CourseResultPreview.styles.scss';

const CourseResultPreview = ({ type }) => {
  const { values } = useFormikContext();

  const withResultPreview = getIn(values, 'withResultPreview');

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {type !== COURSE_TYPES.TRAINING && (
          <FormikField
            withControl={false}
            name="withResultPreview"
            component={SwitchField}
            componentProps={{
              className: styles.headerSwitch,
              readOnly: values.readOnly,
              type: 'checkbox',
            }}
          />
        )}
        <Typography
          className={styles.headerText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTitleByType(type)}
        </Typography>
      </div>
      {withResultPreview && (
        <div className={styles.inner}>
          <FormikField
            name="resultPreview"
            withControl={false}
            component={UploadField}
            componentProps={{
              readOnly: values.readOnly,
              type: 'input',
              variant: 'imageBlock',
              className: styles.upload,
              accept: {
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/png': ['.png'],
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(CourseResultPreview);
