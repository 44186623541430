import parseISO from 'date-fns/parseISO';
import max from 'date-fns/max';

import { USER_ROLES } from 'constants/common';

export const getLastUpdatedByCourses = (courses) => {
  const coursesWithUpdatedAt = courses.filter((course) => !!course.updatedAt);

  if (!coursesWithUpdatedAt || coursesWithUpdatedAt.length === 0) {
    return null;
  }

  return max(
    coursesWithUpdatedAt.map((course) => {
      return parseISO(course.updatedAt);
    }),
  );
};

export const getStudentSchools = ({ user, currentStudent }) => {
  if (!currentStudent) {
    return [];
  }

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  const studentSchools = isRootAdmin
    ? currentStudent.schools
    : currentStudent.schools.filter((school) => school.id === user.schoolId);

  return studentSchools;
};

export const getSchoolStudentStatus = ({ currentStudent, studentSchools }) => {
  const schoolId = studentSchools[0].id; // SHOW ONLY ADMIN SCHOOL IF IT'S NOT BEFRONT ROOT ADMIN

  const status = currentStudent.status.find(
    (item) => item.schoolId === schoolId,
  );

  return status;
};

export const getSchoolStudentCourses = ({ currentStudent, studentSchools }) => {
  const schoolId = studentSchools[0].id; // SHOW ONLY ADMIN SCHOOL IF IT'S NOT BEFRONT ROOT ADMIN

  return currentStudent.courses.filter(
    (course) => course.schoolId === schoolId,
  );
};
