import React from 'react';

import { COURSE_STRUCTURES } from 'constants/common';

import { AddLessonButton } from 'components/AddLessonButton';

import { Modules } from './components/Modules';
import { LessonItem } from './components/LessonItem';

import styles from './CourseTree.styles.scss';

const CourseTree = ({ course, currentLesson, onLessonClick }) => (
  <div className={styles.tree}>
    {course.structure === COURSE_STRUCTURES.MODULES ? (
      <Modules
        modules={course.modules}
        currentLesson={currentLesson}
        onLessonClick={onLessonClick}
      />
    ) : (
      <div className={styles.lessons}>
        {course.lessons.map((lesson) => (
          <LessonItem
            key={lesson.id}
            lesson={lesson}
            currentLesson={currentLesson}
            onClick={onLessonClick}
          />
        ))}
        <AddLessonButton
          buttonClassName={styles.addBtn}
          lessons={course.lessons}
          courseType={course.type}
        />
      </div>
    )}
  </div>
);

export default React.memo(CourseTree);
