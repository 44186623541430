import { useEffect, useState } from 'react';

import * as api from 'api/methods';

import { useFetch } from 'hooks/useFetch';

export const useInboxCount = (requestConfig) => {
  const [count, setCount] = useState({
    lessonsRatingsCount: 0,
    lessonsReportsCount: 0,
    studentReviewCount: 0,
    unsibscribeReasonsCount: 0,
    surveysCount: 0,
  });

  const requestParams = requestConfig.isRootAdmin
    ? { sortBy: 'createdAt', sortDirection: 'desc' }
    : {
        schoolId: requestConfig.schoolId,
        params: { sortBy: 'createdAt', sortDirection: 'desc' },
      };

  const { loading: ratingsLoading, data: lessonsRatings } = useFetch(
    {
      defaultData: {
        data: [],
      },
      fetcher: requestConfig.isRootAdmin
        ? api.getLessonsRatings
        : api.getSchoolLessonRatings,
      immediately: false,
      stopRequest: !requestConfig.lessonsRatings,
    },
    requestParams,
    [],
  );

  const { loading: reviewsLoading, data: studentsReviews } = useFetch(
    {
      defaultData: {
        data: [],
      },
      fetcher: api.getStudentReviews,
      immediately: false,
      stopRequest: !requestConfig.studentsReviews,
    },
    {
      sortBy: 'createdAt',
      sortDirection: 'desc',
    },
    [],
  );

  const { loading: reportsLoading, data: lessonsReports } = useFetch(
    {
      defaultData: {
        data: [],
      },
      fetcher: requestConfig.isRootAdmin
        ? api.getLessonsReports
        : api.getSchoolLessonReports,
      immediately: false,
      stopRequest: !requestConfig.lessonsReports,
    },
    requestParams,
    [],
  );

  const { loading: loadingReasons, data: reasons } = useFetch(
    {
      defaultData: {
        data: [],
      },
      fetcher: api.getStudentUnsubscribeReasons,
      immediately: false,
      stopRequest: !requestConfig.unsibscribeReasons,
    },
    {
      sortBy: 'createdAt',
      sortDirection: 'desc',
    },
    [],
  );

  const { loading: loadingSurveys, data: surveys } = useFetch(
    {
      defaultData: {
        data: [],
      },
      fetcher: api.getSurveys,
      immediately: false,
      stopRequest: !requestConfig.surveys,
    },
    {
      sortBy: 'createdAt',
      sortDirection: 'desc',
    },
    [],
  );

  const loading =
    reportsLoading ||
    loadingReasons ||
    ratingsLoading ||
    reviewsLoading ||
    loadingSurveys;

  useEffect(() => {
    if (!loading) {
      setCount({
        lessonsRatingsCount: lessonsRatings.totalItems,
        lessonsReportsCount: lessonsReports.totalItems,
        studentReviewCount: studentsReviews.totalItems,
        unsibscribeReasonsCount: reasons.totalItems,
        surveysCount: surveys.totalItems,
      });
    }
  }, [loading]);

  return [
    loading,
    count.lessonsRatingsCount,
    count.lessonsReportsCount,
    count.studentReviewCount,
    count.unsibscribeReasonsCount,
    count.surveysCount,
  ];
};
