export const ROUTES = {
  HOME_PAGE: '/',
  FORGOT_PASSWORD_PAGE: '/forgot-password',
  DASHBOARD_PAGE: '/dashboard',
  PROFILE_PAGE: '/profile',
  SCHOOL_PROFILE_PAGE: '/school-profile',
  COURSES_PAGE: '/courses',
  COURSE_PAGE: '/courses/:id',
  COURSE_PREVIEW_PAGE: 'courses/:id/preview',
  STUDENTS_PAGE: '/students',
  STUDENT_PAGE: '/students/:id',
  ADMINS_PAGE: '/admins',
  ADMIN_PAGE: '/admins/:id',
  SCHOOLS_PAGE: '/schools',
  SCHOOL_PAGE: '/schools/:id',
  INBOX_PAGE: '/inbox',
  COURSE_LESSON_PAGE: '/courses/:courseId/lessons/:lessonId',
  COURSE_LESSON_PREVIEW_PAGE: '/courses/:courseId/lessons/:lessonId/preview',
  FIGMA_LESSON_AUTH_PAGE: '/auth/figma',
  ARCADES_PAGE: '/arcades',
  ARCADE_PAGE: '/arcades/:id',
  ARCADE_PREVIEW_PAGE: 'arcades/:id/preview',
  ARCADE_LESSON_PAGE: '/arcades/:courseId/lessons/:lessonId',
  ARCADE_LESSON_PREVIEW_PAGE: '/arcades/:courseId/lessons/:lessonId/preview',
  TRAININGS_PAGE: '/trainings',
  TRAINING_PAGE: '/trainings/:id',
  TRAINING_PREVIEW_PAGE: 'trainings/:id/preview',
  TRAINING_LESSON_PAGE: '/trainings/:courseId/lessons/:lessonId',
  TRAINING_LESSON_PREVIEW_PAGE:
    '/trainings/:courseId/lessons/:lessonId/preview',
};
