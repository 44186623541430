import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const initialValues = {
  platform: '',
  school: '',
};

export const validationSchema = Yup.object().shape({
  platform: Yup.object().required(
    getTranslation('courses_page.validation.type'),
  ),
  school: Yup.object().required(
    getTranslation('courses_page.validation.school'),
  ),
});
