import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getReviews } from 'store/reviews/actions';
import { reviewsSelector } from 'store/reviews/selectors';

import { usePagination } from 'hooks/usePagination';

import { getTranslation } from 'helpers/getTranslation';

import StudentsReviews from './StudentsReviews';

const StudentsReviewsContainer = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const {
    data: studentsReviews,
    currentPage,
    totalPages,
    perPage,
  } = useSelector(reviewsSelector);

  const [loading, setLoading] = useState(true);

  const [sortParams, setSortParams] = useState({
    sortBy: {
      label: getTranslation('inbox_page.action.date'),
      value: 'createdAt',
    },
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getStudentsReviews = async () => {
      try {
        await dispatch(
          getReviews({
            page: 1,
            limit: perPage,
            sortBy: sortParams.sortBy.value,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getStudentsReviews();
  }, [sortParams]);

  const handleSortChange = (values) => {
    setSortParams((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction: getReviews,
    currentPage,
    totalPages,
    perPage,
    sortBy: sortParams.sortBy.value,
    sortDirection: sortParams.sortDirection,
  });

  return (
    <StudentsReviews
      locale={i18n.language}
      loading={loading}
      studentsReviews={studentsReviews}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      sortParams={sortParams}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onSortChange={handleSortChange}
    />
  );
};

export default React.memo(StudentsReviewsContainer);
