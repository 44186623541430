import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

import { STATUSES } from 'constants/common';

export const getInitialValues = ({ courses, currentSchool }) => {
  const isDefaultSchool = currentSchool
    ? currentSchool.name === 'Befront'
    : false;

  return {
    export: false,
    duration: '',
    quantity: '',
    courses: isDefaultSchool
      ? courses
          .filter((course) => course.status === STATUSES.PUBLISHED)
          .map((course) => course.id)
      : [],
  };
};

export const getValidationSchema = () =>
  Yup.object().shape({
    duration: Yup.object().required(
      getTranslation('config.generate_code.duration_required'),
    ),
    quantity: Yup.number()
      .min(1, getTranslation('config.generate_code.quantity_min'))
      .required(getTranslation('config.generate_code.quantity_required')),
  });

export const getDurationOptions = () => [
  { label: getTranslation('config.generate_code.1_month'), value: 1 },
  { label: getTranslation('config.generate_code.2_months'), value: 2 },
  { label: getTranslation('config.generate_code.3_months'), value: 3 },
  { label: getTranslation('config.generate_code.4_months'), value: 4 },
  { label: getTranslation('config.generate_code.5_months'), value: 5 },
  { label: getTranslation('config.generate_code.6_months'), value: 6 },
  { label: getTranslation('config.generate_code.7_months'), value: 7 },
  { label: getTranslation('config.generate_code.8_months'), value: 8 },
  { label: getTranslation('config.generate_code.9_months'), value: 9 },
  { label: getTranslation('config.generate_code.10_months'), value: 10 },
  { label: getTranslation('config.generate_code.11_months'), value: 11 },
  { label: getTranslation('config.generate_code.1_year'), value: 12 },
];
