import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import styles from './UploadExcelFile.styles.scss';

const UploadExcelFile = ({
  className,
  readOnly,
  loading,
  haveError,
  value,
  size,
  text,
  percentage,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {!value && (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <div className={styles.progress}>
            <div
              className={styles.progressTrack}
              style={{ width: `${percentage}%` }}
            />
            <Typography
              className={styles.progressText}
              variant="body1"
              weight="medium"
            >
              {percentage}%
            </Typography>
          </div>
        ) : (
          <div className={styles.inner} onClick={onBrowseClick}>
            <Icon className={styles.icon} name="plusMini" />
            <div className={styles.main}>
              <Typography
                className={styles.title}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                {getTranslation('components.upload.upload_excel_file.upload')}
              </Typography>
              <Typography
                className={styles.text}
                variant="body3"
                mode="compact"
              >
                {getTranslation('components.upload.upload_excel_file.download')}
              </Typography>
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

export default React.memo(UploadExcelFile);
