import React from 'react';
import { DndContext } from '@dnd-kit/core';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { Action } from '../Action';
import { DraggableItem } from '../DraggableItem';
import { DraggableOverlay } from '../DraggableOverlay';
import { DropItemArea } from '../DropItemArea';

import styles from './WebPreviewPage.styles.scss';

const WebPreviewPage = ({
  correct,
  submitted,
  allChecked,
  nextLoading,
  isLastLessonInCourse,
  variants,
  leftItems,
  rightItems,
  draggingItem,
  lesson,
  onItemDragStart,
  onItemDragEnd,
  onItemDragCancel,
  onTryAgainClick,
  onNextLessonClick,
  onCheckClick,
  onRestartLessonClick,
  isTestMode,
}) => {
  return (
    <div className={styles.page}>
      <Typography variant="subtitle" className={styles.subtitle}>
        {lesson.text}
      </Typography>
      <DndContext
        onDragStart={isTestMode && onItemDragStart}
        onDragEnd={isTestMode && onItemDragEnd}
        onItemDragCancel={isTestMode && onItemDragCancel}
      >
        <div className={styles.items}>
          {variants
            .filter(
              (item) =>
                ![...leftItems, ...rightItems].some(
                  (droppedItem) => droppedItem.id === item.id,
                ),
            )
            .map((item) => (
              <DraggableItem
                className={cn(styles.item, {
                  [styles.readOnlyItem]: !isTestMode,
                  [styles.itemDragging]:
                    draggingItem && draggingItem.id === item.id,
                  [styles.itemDropped]:
                    leftItems.some((leftItem) => leftItem.id === item.id) ||
                    rightItems.some((rightItem) => rightItem.id === item.id),
                })}
                key={item.id}
                data={item}
              />
            ))}
        </div>
        <div className={styles.inner}>
          <div className={styles.column}>
            <Typography
              className={styles.columnTitle}
              variant="body1"
              weight="medium"
              mode="compact"
            >
              {lesson.leftVariantsTitle}
            </Typography>
            <DropItemArea
              id="leftColumn"
              submitted={submitted}
              items={leftItems}
            />
          </div>
          <div className={styles.column}>
            <Typography
              className={styles.columnTitle}
              variant="body1"
              weight="medium"
              mode="compact"
            >
              {lesson.rightVariantsTitle}
            </Typography>
            <DropItemArea
              id="rightColumn"
              correct={correct}
              submitted={submitted}
              items={rightItems}
            />
          </div>
        </div>
        <DraggableOverlay />
      </DndContext>
      <div className={styles.actions}>
        {isTestMode && (
          <Action
            correct={correct}
            submitted={submitted}
            allChecked={allChecked}
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            onTryAgainClick={onTryAgainClick}
            onNextLessonClick={onNextLessonClick}
            onCheckClick={onCheckClick}
            onRestartLessonClick={onRestartLessonClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(WebPreviewPage);
