export const getBarTrackColor = (progress) => {
  if (progress === 0) {
    return '#FDCE82';
  }

  if (progress === 100) {
    return '#85E0AB';
  }

  return '#70B4FA';
};
