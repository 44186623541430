import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FigmaFileAccess } from '../FigmaFileAccess';

import styles from './ResyncSection.styles.scss';

const ResyncSection = ({
  initLoading,
  haveToken,
  loading,
  readOnly,
  fileName,
  filePageName,
  onResyncClick,
}) => (
  <div className={styles.section}>
    {haveToken && (
      <div className={styles.inner}>
        {initLoading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.item}>
              <Typography
                className={styles.label}
                variant="body3"
                mode="compact"
              >
                {getTranslation('figma_lesson_page.resync_section.label_1')}
              </Typography>

              <Typography
                className={styles.value}
                variant="body3"
                mode="compact"
                weight="medium"
              >
                {fileName}
              </Typography>
            </div>
            <div className={styles.item}>
              <Typography
                className={styles.label}
                variant="body3"
                mode="compact"
              >
                {getTranslation('figma_lesson_page.resync_section.label_2')}
              </Typography>

              <Typography
                className={styles.value}
                variant="body3"
                mode="compact"
                weight="medium"
              >
                {filePageName}
              </Typography>
            </div>
          </>
        )}
      </div>
    )}
    {!haveToken && <FigmaFileAccess className={styles.fileAccess} size="sm" />}
    {haveToken && (
      <Button
        className={styles.action}
        variant="outlined"
        size="sm"
        disabled={readOnly}
        startIcon={loading ? <Loading size={18} /> : <Icon name="resync" />}
        onClick={onResyncClick}
      >
        {getTranslation('common.button.resync')}
      </Button>
    )}
  </div>
);

export default React.memo(ResyncSection);
