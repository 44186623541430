import React from 'react';
import { Formik, Form } from 'formik';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SelectField, InputField, UploadField } from 'components/FormikFields';

import {
  getLanguageOptions,
  getValidationSchema,
} from './EditSchool.formConfig';

import { convertDataToForm } from './EditSchool.helpers';

import styles from './EditSchool.styles.scss';

const EditSchool = ({
  open,
  updateLoading,
  currentSchool,
  onClose,
  locale,
  onUpdateSchoolClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('school_page.edit_school.title')}
    onClose={onClose}
  >
    <Typography className={styles.lastSave} variant="body3" mode="compact">
      {getTranslation('school_page.edit_school.last_save')}
      {currentSchool?.updatedAt &&
        formatDate(currentSchool.updatedAt, 'dd MMM, yyyy', locale)}
    </Typography>
    <Formik
      enableReinitialize
      initialValues={convertDataToForm(currentSchool)}
      validationSchema={getValidationSchema()}
      onSubmit={onUpdateSchoolClick}
    >
      <Form className={styles.form}>
        <div className={styles.main}>
          <FormikField
            name="preview"
            component={UploadField}
            componentProps={{ variant: 'avatar' }}
            controlProps={{
              label: getTranslation('school_page.edit_school.logo_label'),
            }}
          />
          <FormikField
            name="name"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('school_page.edit_school.name_label'),
            }}
          />
          <FormikField
            name="language"
            component={SelectField}
            controlProps={{
              required: true,
              label: getTranslation('school_page.edit_school.language_label'),
            }}
            componentProps={{ options: getLanguageOptions() }}
          />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              updateLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.save')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(EditSchool);
