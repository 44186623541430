export const getPropertyIconName = ({ type, dependValue }) => {
  switch (type) {
    case 'x':
    case 'effectX': {
      return 'positionX';
    }
    case 'y':
    case 'effectY': {
      return 'positionY';
    }
    case 'width': {
      return 'width';
    }
    case 'height': {
      return 'height';
    }
    case 'rotation': {
      return 'rotation';
    }
    case 'itemSpacing': {
      if (dependValue === 'VERTICAL') {
        return 'spacingVertical';
      }

      if (dependValue === 'HORIZONTAL') {
        return 'spacingHorizontal';
      }

      return null;
    }
    case 'counterAxisSpacing': {
      return 'spacingVertical';
    }
    case 'letterSpacing': {
      return 'letterSpacing';
    }
    case 'paragraphSpacing': {
      return 'paragraphSpacing';
    }
    case 'arcDataStartingAngle': {
      return 'arcStart';
    }
  }
};

export const getPropertyValue = ({ type, value, dependValue }) => {
  switch (type) {
    case 'rotation': {
      return `${value}°`;
    }
    case 'arcDataStartingAngle': {
      return `${Number(((value * 180) / Math.PI).toFixed(2))}°`;
    }
    case 'paragraphSpacing':
    case 'x':
    case 'y':
    case 'effectX':
    case 'effectY':
    case 'width':
    case 'height':
    case 'itemSpacing':
    case 'counterAxisSpacing': {
      return value;
    }
    case 'letterSpacing': {
      return `${Number(((value * 100) / dependValue).toFixed(2))}%`;
    }
    default: {
      return null;
    }
  }
};
