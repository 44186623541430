import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import templateFile from './import-students.xlsx';

import { finishImportStudents } from 'store/students/actions';

import { useFileUpload } from './ImportStudents.hooks';

import ImportStudents from './ImportStudents';

const ImportStudentsContainer = ({ schoolId, onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const swiperRef = useRef();

  const [importedStudents, setImportedStudents] = useState([]);

  const handleUploaded = (response) => {
    setImportedStudents(response);

    swiperRef.current.slideNext();
  };

  const [percentage, uploadError, handleDropAccepted, handleDropRejected] =
    useFileUpload({
      onUploaded: handleUploaded,
    });

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleDownloadTemplateClick = () => {
    const link = document.createElement('a');

    link.href = templateFile;

    link.setAttribute('download', 'import-students-template.xlsx');

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };

  const handleImportClick = async () => {
    try {
      await dispatch(
        finishImportStudents({
          schoolId,
          data: {
            students: importedStudents
              .filter((importedStudent) => importedStudent.status === 'SUCCESS')
              .map((importedStudent) => ({
                email: importedStudent.email,
                firstName: importedStudent.firstName,
                lastName: importedStudent.lastName,
              })),
          },
        }),
      ).unwrap();

      onClose();
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <ImportStudents
      {...restProps}
      importedStudents={importedStudents}
      percentage={percentage}
      uploadError={uploadError}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
      onClose={onClose}
      onSwiper={handleSwiper}
      onImportClick={handleImportClick}
      onDownloadTemplateClick={handleDownloadTemplateClick}
    />
  );
};

export default React.memo(ImportStudentsContainer);
