import React from 'react';
import pluralize from 'pluralize';
import cn from 'classnames';

import { COURSE_TYPES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Tag } from 'components/shared/Tag';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { ArcadesTag } from 'components/ArcadesTag';
import { CoursePreview } from 'components/CoursePreview';

import { CourseProgress } from '../CourseProgress';
import { CourseInfo } from '../CourseInfo';

import styles from './Header.styles.scss';

const Header = ({
  loading,
  premium,
  type,
  format,
  preview,
  description,
  title,
  difficulty,
  previewType,
}) => (
  <div className={styles.header}>
    {type !== COURSE_TYPES.TRAINING && (
      <CoursePreview
        preview={preview}
        className={cn(styles.preview, {
          [styles.webPreview]:
            previewType === getTranslation('common.text.web'),
        })}
      />
    )}
    <div
      className={cn(styles.tags, {
        [styles.trainingTags]: type === COURSE_TYPES.TRAINING,
      })}
    >
      {premium && (
        <Tag className={styles.tag} variant="info">
          {getTranslation('common.text.pro')}
        </Tag>
      )}
      {!premium && (
        <Tag className={styles.tag} variant="success">
          {getTranslation('common.text.free')}
        </Tag>
      )}
    </div>
    <Typography variant="heading2" weight="semiBold" className={styles.title}>
      {title || getTranslation('common.text.untitled')}
    </Typography>
    {type === COURSE_TYPES.ARCADE && (
      <ArcadesTag
        format={format}
        iconWrapperClassName={styles.arcadesTagIconWrapper}
        iconClassName={styles.arcadesTagIcon}
        textClassName={styles.arcadesTagText}
      />
    )}
    {description && (
      <Typography variant="body2" className={styles.descriptionText}>
        {description}
      </Typography>
    )}
    <div className={styles.info}>
      <div className={styles.infoItem}>
        <CourseProgress progress={0} />
      </div>
      <div className={styles.infoItem}>
        <Typography
          variant="caption"
          weight="medium"
          className={styles.lessonsLeftText}
        >
          {pluralize(getTranslation('common.text.lessons_count_text'), 0, true)}{' '}
          {getTranslation('common.text.left')}
        </Typography>
      </div>
    </div>

    <CourseInfo
      className={styles.courseInfo}
      withBorder
      items={['STUDENTS', 'DIFFICULTY']}
      difficulty={difficulty}
      studentsCount={0}
    />
    <Button
      disabled
      size="md"
      loading={loading}
      className={cn(styles.button, {
        [styles.webButton]: previewType === getTranslation('common.text.web'),
      })}
    >
      {getTranslation('common.button.start_course')}
    </Button>
  </div>
);

export default React.memo(Header);
