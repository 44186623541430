import React from 'react';

import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';

import styles from './IconTextProperty.styles.scss';

export const getPropertyIconName = ({ type, value }) => {
  switch (type) {
    case 'layoutSizingHorizontal': {
      if (value === 'HUG') {
        return 'hugHorizontal';
      }

      if (value === 'FILL') {
        return 'fillHorizontal';
      }

      if (value === 'FIXED') {
        return 'constraintsHorizontal';
      }

      return null;
    }
    case 'layoutSizingVertical': {
      if (value === 'HUG') {
        return 'hugVertical';
      }

      if (value === 'FILL') {
        return 'fillVertical';
      }

      if (value === 'FIXED') {
        return 'constraintsVertical';
      }

      return null;
    }
    case 'constraintsHorizontal': {
      return 'constraintsHorizontal';
    }
    case 'constraintsVertical': {
      return 'constraintsVertical';
    }
    case 'blendMode':
    case 'fillBlendMode':
    case 'strokeBlendMode':
    case 'effectBlendMode': {
      return 'blendMode';
    }
    case 'effectType': {
      return 'shadowEffect';
    }
    case 'lineHeight': {
      return 'lineHeight';
    }
    case 'starPointCount': {
      return 'starPointCount';
    }
    case 'polygonPointCount': {
      return 'polygonPointCount';
    }
    case 'innerRadius': {
      return 'ratio';
    }
    case 'interactionNavigationType': {
      if (value === 'NAVIGATE') {
        return 'layoutHorizontal';
      }

      if (value === 'BACK') {
        return 'navigationBack';
      }

      if (value === 'SCROLL_TO') {
        return 'scrollTo';
      }

      if (value === 'OVERLAY') {
        return 'openOverlay';
      }

      if (value === 'SWAP') {
        return 'swapOverlay';
      }

      if (value === 'CLOSE') {
        return 'closeOverlay';
      }

      if (value === 'URL') {
        return 'link';
      }

      return null;
    }
    case 'interactionOffsetX': {
      return 'offsetX';
    }
    case 'interactionOffsetY': {
      return 'offsetY';
    }
  }
};

const _getConstraintsTextByValue = (value) => {
  switch (value) {
    case 'LEFT': {
      return 'Left';
    }
    case 'RIGHT': {
      return 'Right';
    }
    case 'LEFT_RIGHT': {
      return 'Left and right';
    }
    case 'CENTER': {
      return 'Center';
    }
    case 'TOP': {
      return 'Top';
    }
    case 'BOTTOM': {
      return 'Bottom';
    }
    case 'TOP_BOTTOM': {
      return 'Top and bottom';
    }
    case 'SCALE': {
      return 'Scale';
    }
  }
};

const _getBlendModeTextByValue = (value) => {
  switch (value) {
    case 'PASS_THROUGH': {
      return 'Pass through';
    }
    case 'NORMAL': {
      return 'Normal';
    }
    case 'DARKEN': {
      return 'Darken';
    }
    case 'MULTIPLY': {
      return 'Multiply';
    }
    case 'LINEAR_BURN': {
      return 'Linear burn';
    }
    case 'COLOR_BURN': {
      return 'Color burn';
    }
    case 'LIGHTEN': {
      return 'Lighten';
    }
    case 'SCREEN': {
      return 'Screen';
    }
    case 'LINEAR_DODGE': {
      return 'Linear dodge';
    }
    case 'COLOR_DODGE': {
      return 'Color dodge';
    }
    case 'OVERLAY': {
      return 'Overlay';
    }
    case 'SOFT_LIGHT': {
      return 'Soft light';
    }
    case 'HARD_LIGHT': {
      return 'Hard light';
    }
    case 'DIFFERENCE': {
      return 'Difference';
    }
    case 'EXCLUSION': {
      return 'Exclusion';
    }
    case 'HUE': {
      return 'Hue';
    }
    case 'SATURATION': {
      return 'Saturation';
    }
    case 'COLOR': {
      return 'Color';
    }
    case 'LUMINOSITY': {
      return 'Luminosity';
    }
  }
};

const _getEffectTextByValue = (value) => {
  switch (value) {
    case 'INNER_SHADOW': {
      return 'Inner shadow';
    }
    case 'DROP_SHADOW': {
      return 'Drop shadow';
    }
    case 'LAYER_BLUR': {
      return 'Layer blur';
    }
    case 'BACKGROUND_BLUR': {
      return 'Background blur';
    }
  }
};

const _getLineHeightTextByValue = (value) => {
  if (value.unit === 'AUTO') {
    return 'Auto';
  }

  if (value.unit === 'PIXELS') {
    return value.value;
  }

  if (value.unit === 'PERCENT') {
    return `${value.value}%`;
  }

  return null;
};

const _getInteractionNavigationType = ({ value, dependValue }) => {
  switch (value) {
    case 'NAVIGATE': {
      return `Navigate to: ${dependValue ? dependValue.name : 'None'}`;
    }
    case 'BACK': {
      return 'Back';
    }
    case 'SCROLL_TO': {
      return `Scroll to: ${dependValue ? dependValue.name : 'None'}`;
    }
    case 'OVERLAY': {
      return `Open overlay: ${dependValue ? dependValue.name : 'None'}`;
    }
    case 'SWAP': {
      return `Swap overlay: ${dependValue ? dependValue.name : 'None'}`;
    }
    case 'CLOSE': {
      return 'Close overlay';
    }
    case 'URL': {
      return 'Open link';
    }
  }
};

export const getPropertyValue = ({ type, value, dependValue }) => {
  switch (type) {
    case 'layoutSizingHorizontal':
    case 'layoutSizingVertical': {
      return _upperFirst(_toLower(value));
    }
    case 'constraintsHorizontal': {
      return _getConstraintsTextByValue(value);
    }
    case 'constraintsVertical': {
      return _getConstraintsTextByValue(value);
    }
    case 'blendMode':
    case 'fillBlendMode':
    case 'strokeBlendMode':
    case 'effectBlendMode': {
      return _getBlendModeTextByValue(value);
    }
    case 'effectType': {
      return _getEffectTextByValue(value);
    }
    case 'lineHeight': {
      return _getLineHeightTextByValue(value);
    }
    case 'starPointCount': {
      if (!value) {
        return <span className={styles.missingValue}>Sync in plugin</span>;
      }

      return value;
    }
    case 'polygonPointCount': {
      if (!value) {
        return <span className={styles.missingValue}>Sync in plugin</span>;
      }

      return value;
    }
    case 'innerRadius': {
      if (!value) {
        return <span className={styles.missingValue}>Sync in plugin</span>;
      }

      return `${Number((value * 100).toFixed(1))}%`;
    }
    case 'interactionOffsetX': {
      return null;
    }
    case 'interactionOffsetY': {
      return null;
    }
    case 'interactionNavigationType': {
      return _getInteractionNavigationType({ value, dependValue });
    }
    default: {
      return null;
    }
  }
};
