import React from 'react';

import { TheoryBlocks } from 'components/TheoryBlocks';

import styles from './GoodToKnowSettings.styles.scss';

const GoodToKnowSettings = () => (
  <div className={styles.settings}>
    <TheoryBlocks
      addBtnClassName={styles.addBtn}
      namePrefix="goodToKnowBlocks"
    />
  </div>
);

export default React.memo(GoodToKnowSettings);
