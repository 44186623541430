import React from 'react';
import { Formik } from 'formik';

import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';
import { getLessonStatusContextItems } from 'helpers/getLessonStatusContextItems';
import { getReadonlyLessonText } from 'helpers/getReadonlyLessonText';

import { AutoSave } from 'components/AutoSave';
import { CourseTree } from 'components/CourseTree';
import { LessonHeader } from 'components/LessonHeader';
import { LessonLayout } from 'components/Layout';
import { ToolbarActions } from 'components/ToolbarActions';
import { ReadOnlySection } from 'components/ReadOnlySection';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { Questions } from './components/Questions';

import { convertDataToForm } from './QuizPage.helpers';

import styles from './QuizPage.styles.scss';

const QuizPage = ({
  saveLoading,
  autoSaveLoading,
  readOnly,
  lesson,
  lastActionAt,
  courseTitle,
  courseStatus,
  onAutoSaveSubmit,
  onSaveClick,
  onPreviewClick,
  onRemoveClick,
  onStatusContextItemClick,
}) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={convertDataToForm(lesson, readOnly)}
    onSubmit={onAutoSaveSubmit}
  >
    {({ values, setFieldValue }) => (
      <LessonLayout
        sidebarComponent={<CourseTree />}
        toolbarComponent={
          <BreadcrumbsToolbar
            breadcrumbsData={{
              courseTitle,
              lessonTitle:
                values.title || getTranslation('common.text.untitled'),
            }}
            endAdornment={
              <ToolbarActions
                withRemove
                saveLoading={saveLoading}
                autoSaveLoading={autoSaveLoading}
                contextItems={getLessonStatusContextItems({
                  courseReadOnly: courseStatus === STATUSES.PUBLISHED,
                  status: lesson.status,
                })}
                removeData={{ id: lesson.id, title: lesson.title }}
                updatedAt={lastActionAt}
                status={lesson.status}
                onSaveClick={() => onSaveClick(values)}
                onPreviewClick={onPreviewClick}
                onRemoveClick={onRemoveClick}
                onStatusContextItemClick={(item) =>
                  onStatusContextItemClick(item, values, { setFieldValue })
                }
              />
            }
          />
        }
      >
        <div className={styles.page}>
          <AutoSave debounceMs={1000} />
          {readOnly && (
            <ReadOnlySection text={getReadonlyLessonText(lesson.status)} />
          )}
          <LessonHeader readOnly={readOnly} />
          <Questions />
        </div>
      </LessonLayout>
    )}
  </Formik>
);

export default React.memo(QuizPage);
