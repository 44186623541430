import { getTranslation } from 'helpers/getTranslation';

export const getTrainingsTableColumnsRootAdmin = () => [
  {
    key: 'title',
    label: getTranslation('trainings_page.table_keys.title'),
    minWidth: 400,
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('trainings_page.table_keys.status'),
    sortable: true,
  },
  {
    key: 'authors',
    label: getTranslation('trainings_page.table_keys.authors'),
    sortable: false,
  },
  {
    key: 'school',
    label: getTranslation('trainings_page.table_keys.school'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('trainings_page.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'lastActionAt',
    label: getTranslation('trainings_page.table_keys.updated_at'),
    sortable: true,
  },
];
export const getTrainingsTableColumns = () => [
  {
    key: 'title',
    label: getTranslation('trainings_page.table_keys.title'),
    minWidth: 400,
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('trainings_page.table_keys.status'),
    sortable: true,
  },
  {
    key: 'authors',
    label: getTranslation('trainings_page.table_keys.authors'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('trainings_page.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'lastActionAt',
    label: getTranslation('trainings_page.table_keys.updated_at'),
    sortable: true,
  },
];
