import React from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Loading } from 'components/shared/Loading';
import { Button } from 'components/shared/Button';

import { CoursesAccessList } from 'components/CoursesAccessList';
import { EmptyState } from 'components/EmptyState';

import { convertDataToForm } from './ManageCoursesAccess.helpers';

import styles from './ManageCoursesAccess.styles.scss';

const ManageCoursesAccess = ({
  open,
  loading,
  saveLoading,
  isTestStudent,
  courses,
  onClose,
  onSaveClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('student_page.manage_access.header')}
    onClose={onClose}
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={convertDataToForm(courses)}
          onSubmit={onSaveClick}
        >
          <Form
            className={cn(styles.form, {
              [styles.formEmpty]: !courses || courses.length === 0,
            })}
          >
            {courses && courses.length > 0 ? (
              <>
                <CoursesAccessList
                  isTestStudent={isTestStudent}
                  courses={courses}
                />
                <div className={styles.actions}>
                  <Button
                    className={styles.action}
                    size="md"
                    variant="outlined"
                    onClick={onClose}
                  >
                    {getTranslation('common.button.cancel')}
                  </Button>
                  <Button
                    className={styles.action}
                    type="submit"
                    size="md"
                    endIcon={saveLoading && <Loading />}
                  >
                    {getTranslation('common.button.save')}
                  </Button>
                </div>
              </>
            ) : (
              <EmptyState
                className={styles.emptyState}
                icon="courses"
                title={getTranslation(
                  'student_page.manage_access.header.empty_state.title',
                )}
                text={getTranslation(
                  'student_page.manage_access.header.empty_state.text',
                )}
              />
            )}
          </Form>
        </Formik>
      </>
    )}
  </Drawer>
);

export default React.memo(ManageCoursesAccess);
