import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { CoursePreview } from 'components/CoursePreview';
import { PublishStatusTag } from 'components/PublishStatusTag';

import styles from './CourseCard.styles.scss';

const CourseCard = ({
  isMoreInfo,
  isStatsEmpty,
  preview,
  title,
  status,
  onMoreClick,
}) => (
  <div className={styles.card}>
    <div className={styles.main}>
      <CoursePreview className={styles.preview} preview={preview} />
      <div className={styles.inner}>
        <Typography
          className={styles.title}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {title || getTranslation('common.text.untitled')}
        </Typography>
        <PublishStatusTag
          className={styles.tag}
          variant={status.toLowerCase()}
        />
      </div>
    </div>
    {!isStatsEmpty && (
      <Button
        className={styles.action}
        variant="outlined"
        size="sm"
        onClick={onMoreClick}
      >
        {isMoreInfo
          ? getTranslation('common.button.less_info')
          : getTranslation('common.button.more_info')}
      </Button>
    )}
  </div>
);

export default React.memo(CourseCard);
