import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getStatusText } from 'helpers/getStatusText';
import { getTranslation } from 'helpers/getTranslation';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Table } from 'components/shared/Table';
import { Typography } from 'components/shared/Typography';
import { Avatar } from 'components/shared/Avatar';

import { StatusTag } from 'components/StatusTag';
import { Tooltip } from 'components/Tooltip';
import { SchoolPreview } from 'components/SchoolPreview';
import { TablePagination } from 'components/TablePagination';

import styles from './AdminsTable.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  { user },
  locale,
) => {
  switch (key) {
    case 'name': {
      return (
        <div
          className={styles.nameCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Avatar size="sm" text={item.name || item.email} />
          <Typography
            className={styles.name}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {item[key]}{' '}
            {user.id === item.id && (
              <span className={styles.nameCaption}>
                {getTranslation('components.admins_table.you')}
              </span>
            )}
          </Typography>
        </div>
      );
    }
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {formatDate(item[key], 'dd MMM, yyyy', locale)}
        </DefaultCell>
      );
    }
    case 'updatedAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {formatDistance(parseISO(item[key]), new Date(), {
            addSuffix: true,
            locale: locale === 'ru' ? ru : enUS,
          })}
        </DefaultCell>
      );
    }
    case 'school': {
      const school = item.school;

      return (
        <div
          className={styles.schoolCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Tooltip
            key={school.id}
            text={school.name}
            triggerElement={
              <SchoolPreview preview={school.preview} name={school.name} />
            }
          />
        </div>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <StatusTag variant={getUserStatusVariant(item[key])}>
            {getStatusText(item[key])}
          </StatusTag>
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const AdminsTable = ({
  className,
  columns,
  data,
  user,
  params,
  locale,
  currentPage,
  perPage,
  totalPages,
  onPerPageChange,
  onSortChange,
  onNextPageClick,
  onPrevPageClick,
  onEditAdminClick,
  onAdminRowClick,
}) => (
  <>
    <Table
      className={className}
      columns={columns}
      actions={[
        {
          key: 'edit',
          icon: 'edit',
          tooltipText: getTranslation('components.admins_table.tooltip'),
          onClick: onEditAdminClick,
        },
      ]}
      data={data}
      params={params}
      cellRenderer={(props, DefaultCell) =>
        cellRenderer(props, DefaultCell, { user }, locale)
      }
      onSortChange={onSortChange}
      onRowClick={(student) => onAdminRowClick(student.id)}
    />
    <TablePagination
      currentPage={currentPage}
      perPage={perPage}
      totalPages={totalPages}
      onSortChange={onSortChange}
      onPerPageChange={onPerPageChange}
      onNextPageClick={onNextPageClick}
      onPrevPageClick={onPrevPageClick}
    />
  </>
);

export default React.memo(AdminsTable);
