import React from 'react';
import cn from 'classnames';
import DefaultSelect, { components as selectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './Select.styles.scss';

const MODES = {
  default: 'default',
  portal: 'portal',
};

const TYPES = {
  default: DefaultSelect,
  async: AsyncSelect,
};

// const Control = React.memo(
//   ({ children, innerProps, menuIsOpen, innerRef, haveError, isFocused }) => {
//     return (
//       <div
//         {...innerProps}
//         ref={innerRef}
//         className={cn(styles.control, {
//           [styles.controlFocused]: isFocused,
//           [styles.controlHaveError]: haveError,
//           [styles.controlOpen]: menuIsOpen,
//         })}
//       >
//         {children}
//       </div>
//     );
//   },
// );

const Control = ({ isFocused, haveError, menuIsOpen, ...restProps }) => (
  <selectComponents.Control
    {...restProps}
    className={cn(styles.control, {
      [styles.controlFocused]: isFocused,
      [styles.controlHaveError]: haveError,
      [styles.controlOpen]: menuIsOpen,
    })}
  />
);

const DropdownIndicator = React.memo(() => (
  <Icon name="chevronDown" className={styles.indicator} />
));

const ClearIndicator = React.memo(({ innerProps }) => (
  <Typography
    {...innerProps}
    variant="body3"
    weight="medium"
    className={styles.clear}
  >
    Clear
  </Typography>
));

const Placeholder = React.memo(({ innerProps, children }) => (
  <Typography
    {...innerProps}
    className={styles.placeholder}
    variant="body2"
    mode="compact"
  >
    {children}
  </Typography>
));

const ValueContainer = React.memo(({ innerProps, children }) => (
  <>{children}</>
));

const SingleValue = React.memo(
  ({ innerProps, children, valueRenderer, data }) => {
    if (valueRenderer) {
      return valueRenderer({ data: data.label, innerProps });
    }

    return (
      <Typography
        {...innerProps}
        className={styles.value}
        variant="body2"
        mode="compact"
      >
        {children}
      </Typography>
    );
  },
);

const MenuList = React.memo((props) => (
  <selectComponents.MenuList {...props} className={styles.menuList} />
));

const Option = React.memo(({ innerProps, label, children, optionRenderer }) => {
  if (optionRenderer) {
    return optionRenderer({ data: label, innerProps });
  }

  return (
    <div {...innerProps} className={styles.option}>
      {children}
    </div>
  );
});

const NoOptionsMessage = React.memo(({ innerProps }) => (
  <Typography {...innerProps} className={styles.noOption}>
    No options
  </Typography>
));

const MultiValueContainer = React.memo(({ innerProps, children }) => (
  <div {...innerProps} className={styles.tag}>
    {children}
  </div>
));

const MultiValueLabel = React.memo(({ innerProps, children }) => (
  <Typography
    {...innerProps}
    className={styles.label}
    variant="body2"
    weight="medium"
    mode="compact"
  >
    {children}
  </Typography>
));

const MultiValueRemove = React.memo(({ innerProps }) => (
  <div {...innerProps} className={styles.removeContainer}>
    <Icon name="cross" className={styles.removeIcon} />
  </div>
));

const Select = ({
  className,
  loading,
  multi,
  searchable,
  mode,
  type,
  placeholder,
  options,
  variant,
  haveError,
  optionRenderer,
  valueRenderer,
  ...restProps
}) => {
  const components = {
    Control: (props) => <Control {...props} haveError={haveError} />,
    DropdownIndicator,
    ClearIndicator,
    Placeholder,
    SingleValue: (valueProps) => (
      <SingleValue {...valueProps} valueRenderer={valueRenderer} />
    ),
    MultiValueLabel,
    MultiValueRemove,
    MenuList,
    Option: (optionProps) => (
      <Option {...optionProps} optionRenderer={optionRenderer} />
    ),
    NoOptionsMessage,
    MultiValueContainer,
    ValueContainer,
    IndicatorSeparator: null,
    // Group,
  };

  const commonProps = {
    isLoading: loading,
    isMulti: multi,
    isSearchable: searchable,
    hideSelectedOptions: !multi,
    closeMenuOnSelect: !multi,
    unstyled: true,
    placeholder: loading ? getTranslation('common.text.loading') : placeholder,
    components,
  };

  if (type === 'default') {
    commonProps.options = loading ? Object.freeze([]) : options;
  }

  if (mode === MODES.default) {
    commonProps.styles = { control: () => null };
  }

  if (mode === MODES.portal) {
    commonProps.styles = {
      control: () => null,
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
  }

  const SelectComponent = TYPES[type];

  switch (MODES[mode]) {
    case MODES.default:
      return <SelectComponent {...commonProps} {...restProps} />;

    case MODES.portal:
      return (
        <SelectComponent
          {...commonProps}
          {...restProps}
          className={cn(styles.container, className)}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          menuPosition="fixed"
          menuShouldBlockScroll
        />
      );
  }
};

Select.defaultProps = {
  variant: 'outlined',
  type: 'default',
  mode: 'portal',
  searchable: false,
  multi: false,
  placeholder: getTranslation('common.text.select'),
};

export default React.memo(Select);
