import { COURSE_TYPES } from 'constants/common';

export const modifyPreviewForTableRender = (data) =>
  data.map((content) => {
    if (content.type !== COURSE_TYPES.COURSE) {
      return { ...content, preview: content.resultPreview };
    }

    return content;
  });
