import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getTrainingById } from 'store/training/actions';
import { currentTrainingSelector } from 'store/training/selectors';

import { getTranslation } from 'helpers/getTranslation';

import TrainingPreviewPage from './TrainingPreviewPage';

const TrainingPreviewPageContainer = () => {
  const dispatch = useDispatch();

  const { id: trainingId } = useParams();

  const training = useSelector(currentTrainingSelector);

  const [loading, setLoading] = useState(true);
  const [previewType, setPreviewType] = useState(
    getTranslation('common.text.plugin'),
  );

  useEffect(() => {
    const _getCourseById = async () => {
      try {
        await dispatch(getTrainingById(trainingId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCourseById();
  }, []);

  return (
    <TrainingPreviewPage
      loading={loading}
      training={training}
      previewType={previewType}
      setPreviewType={setPreviewType}
    />
  );
};

export default React.memo(TrainingPreviewPageContainer);
