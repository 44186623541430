import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Button } from 'components/shared/Button';

import styles from './UploadQuizImage.styles.scss';

const UploadQuizImage = ({
  className,
  readOnly,
  loading,
  value,
  percentage,
  getInputProps,
  getRootProps,
  onRemoveClick,
  onBrowseClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value, [styles.uploadReadOnly]: readOnly },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {value && !loading && !readOnly && (
      <>
        <div className={styles.actionOverlay} />
        <Button
          className={styles.action}
          variant="ghost"
          mode="icon"
          size="md"
          iconName="trash"
          iconColor="#fff"
          onClick={onRemoveClick}
        />
      </>
    )}
    {value ? (
      <img src={value} className={styles.preview} />
    ) : (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <LinearProgressBar
            className={styles.bar}
            variant="upload"
            progress={percentage}
          />
        ) : (
          <div className={styles.inner} onClick={!readOnly && onBrowseClick}>
            <Icon className={styles.icon} name="image" />
            <Typography
              className={styles.text}
              variant="body1"
              mode="compact"
              weight="medium"
            >
              {getTranslation('components.upload.upload_quiz_image.add_image')}
            </Typography>
          </div>
        )}
      </>
    )}
  </div>
);

export default React.memo(UploadQuizImage);
