import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './FormControl.styles.scss';

const VARIANTS = {
  vertical: styles.controlVertical,
  horizontal: styles.controlHorizontal,
};

const FormControl = ({
  className,
  children,
  required,
  withVerticalSpace,
  haveError,
  error,
  variant,
  label,
  labelProps,
}) => (
  <div
    className={cn(
      styles.control,
      { [styles.controlWithSpace]: withVerticalSpace },
      VARIANTS[variant],
      className,
    )}
  >
    {label && (
      <Typography
        className={cn(styles.label, { [styles.labelRequired]: required })}
        variant="body3"
        weight="medium"
        mode="compact"
        {...labelProps}
      >
        {label}
      </Typography>
    )}
    {children}
    {haveError && error && (
      <div className={styles.error}>
        <Typography className={styles.errorText} variant="caption">
          {error}
        </Typography>
      </div>
    )}
  </div>
);

FormControl.defaultProps = {
  variant: 'vertical',
  withVerticalSpace: true,
};

export default React.memo(FormControl);
