import React, { useRef } from 'react';
import { useDayRender } from 'react-day-picker';
import cn from 'classnames';

import styles from './Day.styles.scss';

const Day = ({ date, displayMonth, ...restProp }) => {
  const buttonRef = useRef();

  const { isHidden, buttonProps, activeModifiers } = useDayRender(
    date,
    displayMonth,
    buttonRef,
  );

  if (isHidden) {
    return <div className={styles.day} role="gridcell" />;
  }

  return (
    <div
      {...buttonProps}
      ref={buttonRef}
      className={cn(styles.day, {
        [styles.dayOutside]: activeModifiers.outside,
        [styles.dayToday]: activeModifiers.today,
        [styles.daySelected]: activeModifiers.selected,
      })}
    />
  );
};

export default React.memo(Day);
