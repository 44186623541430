import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { coursesSelector } from 'store/courses/selectors';

import { usePagination } from 'hooks/usePagination';

import CoursesTable from './CoursesTable';

const CoursesTableContainer = ({ dataAction, params, ...restProps }) => {
  const { currentPage, totalPages, perPage } = useSelector(coursesSelector);
  const { i18n } = useTranslation();

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction,
    currentPage,
    totalPages,
    perPage,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  return (
    <CoursesTable
      {...restProps}
      locale={i18n.language}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      params={params}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
    />
  );
};

export default React.memo(CoursesTableContainer);
