import React from 'react';
import cn from 'classnames';

import styles from './Switch.styles.scss';

const SIZES = {
  lg: styles.switchLarge,
  sm: styles.switchSmall,
};

const Switch = ({ className, readOnly, disabled, size, ...restProps }) => (
  <div
    className={cn(
      styles.switch,
      SIZES[size],
      { [styles.switchDisabled]: disabled, [styles.switchReadOnly]: readOnly },
      className,
    )}
    tabIndex="-1"
  >
    <input type="checkbox" className={styles.field} {...restProps} />
    <div className={styles.body} />
    <div className={styles.toggle} />
  </div>
);

Switch.defaultProps = {
  size: 'lg',
};

export default React.memo(Switch);
