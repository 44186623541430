import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getStudentById = createAsyncThunk(
  types.GET_STUDENT_BY_ID,
  async (studentId) => {
    const student = await api.getStudentById(studentId);

    const courses = await api.getCoursesByStudentId(studentId);

    return { ...student, courses };
  },
);

export const updateStudentById = createAsyncThunk(
  types.UPDATE_STUDENT_BY_ID,
  async ({ studentId, data }) => {
    const student = await api.updateStudentById({ studentId, data });

    return {
      firstName: student.firstName,
      lastName: student.lastName,
      isTest: student.isTest,
      isAdmin: student.isAdmin,
    };
  },
);

export const grantStudentCoursesAccess = createAsyncThunk(
  types.GRANT_STUDENT_COURSES_ACCESS,
  async ({ studentId, data }) => {
    const courses = await api.grantStudentCoursesAccess(studentId, data);

    return courses;
  },
);
