export const getAutoLayoutDirectionKey = ({
  layoutMode,
  layoutWrap,
  counterAxisAlignItems,
  primaryAxisAlignItems,
}) => {
  if (
    layoutMode &&
    layoutMode === 'VERTICAL' &&
    layoutWrap &&
    layoutWrap === 'NO_WRAP'
  ) {
    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_LEFT';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_CENTER';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_RIGHT';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_LEFT';
    }

    if (
      counterAxisAlignItems === 'CENTER' &&
      primaryAxisAlignItems === 'CENTER'
    ) {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_CENTER';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_RIGHT';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_LEFT';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_CENTER';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_RIGHT';
    }
  }

  if (
    layoutMode &&
    layoutMode === 'HORIZONTAL' &&
    layoutWrap &&
    layoutWrap === 'NO_WRAP'
  ) {
    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_LEFT';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_CENTER';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_RIGHT';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_LEFT';
    }

    if (
      counterAxisAlignItems === 'CENTER' &&
      primaryAxisAlignItems === 'CENTER'
    ) {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_CENTER';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_RIGHT';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_LEFT';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_CENTER';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_RIGHT';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'MIN'
    ) {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'CENTER'
    ) {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'MAX'
    ) {
      return 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT';
    }
  }

  if (
    layoutMode &&
    layoutMode === 'HORIZONTAL' &&
    layoutWrap &&
    layoutWrap === 'WRAP'
  ) {
    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_TOP_LEFT';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_TOP_CENTER';
    }

    if (counterAxisAlignItems === 'MIN' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_TOP_RIGHT';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_LEFT';
    }

    if (
      counterAxisAlignItems === 'CENTER' &&
      primaryAxisAlignItems === 'CENTER'
    ) {
      return 'AUTO_LAYOUT_WRAP_ALIGN_CENTER';
    }

    if (counterAxisAlignItems === 'CENTER' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_RIGHT';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MIN') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_LEFT';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'CENTER') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_CENTER';
    }

    if (counterAxisAlignItems === 'MAX' && primaryAxisAlignItems === 'MAX') {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_RIGHT';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'MIN'
    ) {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'CENTER'
    ) {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER';
    }

    if (
      counterAxisAlignItems === 'BASELINE' &&
      primaryAxisAlignItems === 'MAX'
    ) {
      return 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT';
    }
  }

  return null;
};
