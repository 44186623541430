import React from 'react';
import { useTranslation } from 'react-i18next';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';
import { copyToClipboard } from 'helpers/copyToClipboard';

import UserCard from './UserCard';

const UserCardContainer = ({ email, ...restProps }) => {
  const { i18n } = useTranslation();

  const handleCopyEmailClick = () => {
    copyToClipboard(email);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.clipboard_copy.title'),
        text: getTranslation('common.toast.clipboard_copy.text'),
      },
    });
  };

  return (
    <UserCard
      {...restProps}
      locale={i18n.language}
      email={email}
      onCopyEmailClick={handleCopyEmailClick}
    />
  );
};

export default React.memo(UserCardContainer);
