export const getPropertyIcon = (type) => {
  if (type === 'VARIANT') {
    return 'variant';
  }

  if (type === 'BOOLEAN') {
    return 'booleanProperty'; // TODO
  }

  if (type === 'TEXT') {
    return 'textChar';
  }

  if (type === 'INSTANCE_SWAP') {
    return 'instance';
  }

  return null;
};

export const getPropertyValue = ({ type, key, variantOptions }) => {
  if (type === 'VARIANT') {
    return `${key}: ${variantOptions.join(', ')}`;
  }

  if (type === 'BOOLEAN') {
    return `${key}`;
  }

  if (type === 'TEXT') {
    return `${key}`;
  }

  if (type === 'INSTANCE_SWAP') {
    return `${key}`;
  }
};
