import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { isAnswersMultiple } from '../../QuizPreviewPage.helpers';

import styles from './Action.styles.scss';

const Action = ({
  submitted,
  correct,
  questions,
  currentAnswers,
  currentQuestion,
  onNextQuestionClick,
  onCheckClick,
  onRestartLessonClick,
  onTryAgainClick,
}) => {
  const question = questions[currentQuestion];

  if (isAnswersMultiple(question) && !submitted) {
    return (
      <Button
        className={styles.action}
        disabled={currentAnswers.length < 1}
        endIcon={<Icon name="arrowRight" />}
        onClick={onCheckClick}
      >
        {getTranslation('common.button.check')}
      </Button>
    );
  }

  if (!correct && submitted) {
    return (
      <Button className={styles.action} onClick={onTryAgainClick}>
        {getTranslation('common.button.try_again')}
      </Button>
    );
  }

  if (correct && questions.length - 1 === currentQuestion) {
    return (
      <Button
        className={styles.action}
        disabled={!submitted}
        onClick={onRestartLessonClick}
      >
        {getTranslation('common.button.restart_lesson')}
      </Button>
    );
  }

  return (
    <Button
      className={styles.action}
      disabled={!submitted}
      endIcon={<Icon name="arrowRight" />}
      onClick={onNextQuestionClick}
    >
      {getTranslation('common.button.next_question')}
    </Button>
  );
};

export default React.memo(Action);
