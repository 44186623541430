import { COURSE_STRUCTURES, LESSON_TYPES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { checkQuestionsValidation as checkDifferenceLessonQuestionsValidation } from 'pages/DifferenceLessonPage/DifferenceLessonPage.helpers';
import { checkCorrectAnswers as checkQuizCorrectAnswers } from 'pages/QuizPage/QuizPage.helpers';
import { checkAnswersValue as checkQuizAnswersValue } from 'pages/QuizPage/QuizPage.helpers';
import { checkAnswersValid as checkConnectionQuizAnswersValid } from 'pages/ConnectionQuizPage/ConnectionQuizPage.helpers';

const _validateLesson = (
  { lesson, lessonIndex, moduleIndex },
  { setFieldValue },
) => {
  const namePrefix =
    moduleIndex !== null
      ? `modules[${moduleIndex}].lessons[${lessonIndex}].statusError`
      : `lessons[${lessonIndex}].statusError`;

  if (
    lesson.type === LESSON_TYPES.DIFFERENCE_LESSON &&
    !checkDifferenceLessonQuestionsValidation(lesson)
  ) {
    const errorText = getTranslation('common.toast.answer_warning.text');

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (lesson.type === LESSON_TYPES.QUIZ && !checkQuizCorrectAnswers(lesson)) {
    const errorText = getTranslation('common.toast.answer_warning.text');

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (lesson.type === LESSON_TYPES.QUIZ && !checkQuizAnswersValue(lesson)) {
    const errorText = getTranslation('course_page.lessom_item.quiz_error_text');

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (
    lesson.type === LESSON_TYPES.CONNECTION_QUIZ &&
    !checkConnectionQuizAnswersValid(lesson)
  ) {
    const errorText = getTranslation(
      'course_page.lessom_item.connection_quiz_error_text',
    );

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  return { errorText: null, valid: true };
};

export const validateCourseLesson = (
  { lessonId, values },
  { setFieldValue },
) => {
  const { structure } = values;

  let validateData = null;

  if (structure === COURSE_STRUCTURES.MODULES) {
    for (let [moduleIndex, module] of values.modules.entries()) {
      for (let [lessonIndex, lesson] of module.lessons.entries()) {
        if (lesson.id === lessonId) {
          validateData = { lesson, lessonIndex, moduleIndex };
        }
      }
    }
  }

  if (structure === COURSE_STRUCTURES.LESSONS) {
    for (let [lessonIndex, lesson] of values.lessons.entries()) {
      if (lesson.id === lessonId) {
        validateData = { lesson, lessonIndex, moduleIndex: null };
      }
    }
  }

  const { valid, errorText } = _validateLesson(validateData, {
    setFieldValue,
  });

  return { valid, errorText };
};
