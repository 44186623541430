import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { CompletedIcon } from 'components/CompletedIcon';

import { TaskMaterial } from '../TaskMaterial';

import styles from './Task.styles.scss';

const Task = ({
  withHints,
  withTheory,
  withMaterials,
  current,
  completed,
  isTextCopyAvailable,
  materials,
  errors,
  theoryBlocks,
  hintsBlocks,
  text,
  taskIndex,
  onCopyTextClick,
  onHintsIconClick,
  onTheoryIconClick,
  isTestMode,
}) => (
  <div
    className={cn(styles.task, {
      [styles.taskCurrent]: current,
      [styles.taskCompleted]: completed,
      [styles.taskError]: errors && errors.length > 0,
    })}
  >
    <div className={styles.header}>
      <div className={styles.headerInner}>
        <Typography
          className={styles.name}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          {getTranslation('figma_lesson_preview_page.task.task')}{' '}
          {taskIndex + 1}
        </Typography>
        {withHints && (
          <Icon
            className={cn(styles.hintsIcon, {
              [styles.readOnlyHintsIcon]: !isTestMode,
            })}
            name="hint"
            onClick={() => {
              if (!isTestMode) {
                return;
              }
              onHintsIconClick(hintsBlocks);
            }}
          />
        )}
        {withTheory && (
          <Icon
            className={cn(styles.theoryIcon, {
              [styles.readOnlyTheoryIcon]: !isTestMode,
            })}
            name="theory"
            onClick={() => {
              if (!isTestMode) {
                return;
              }
              onTheoryIconClick(theoryBlocks);
            }}
          />
        )}
      </div>
      {completed && (
        <CompletedIcon
          className={styles.completed}
          iconClassName={styles.completedIcon}
        />
      )}
      {/* {current && (
        <Link
          className={styles.skipLink}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          Skip
        </Link>
      )} */}
    </div>
    <div className={styles.inner}>
      <Typography className={styles.text} variant="body2">
        {text.replace(/\n/g, '<br>')}
      </Typography>
      {isTextCopyAvailable && (
        <div className={styles.copy} onClick={onCopyTextClick}>
          <Icon className={styles.copyIcon} name="copy" />
          <Typography
            className={styles.copyText}
            variant="body3"
            weight="medium"
          >
            {getTranslation('figma_lesson_preview_page.task.copy_text')}
          </Typography>
        </div>
      )}
      {withMaterials && (
        <div className={styles.materials}>
          {materials.map((material) => (
            <TaskMaterial key={material.id} material={material} />
          ))}
        </div>
      )}
      {errors && errors.length > 0 && (
        <div className={styles.errors}>
          {errors.map((error) => (
            <Typography className={styles.error} key={error} variant="caption">
              {error}
            </Typography>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default React.memo(Task);
