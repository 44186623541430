import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getTrainings = createAsyncThunk(
  types.GET_TRAININGS,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const trainings = isRootAdmin
      ? await api.getTrainings(params)
      : await api.getSchoolTrainings({ schoolId: user.schoolId, params });

    return trainings;
  },
);

export const getSchoolTrainings = createAsyncThunk(
  types.GET_SCHOOL_TRAININGS,
  async (data) => {
    const trainings = await api.getSchoolTrainings(data);

    return trainings;
  },
);

export const updateTrainingById = createAsyncThunk(
  types.UPDATE_TRAINING_BY_ID,
  async ({ trainingId, fieldName, data }) => {
    const training = await api.updateTrainingById({ trainingId, data });

    return { fieldName, updatedTraining: training };
  },
);

export const removeTrainingById = createAction(types.REMOVE_TRAINING_BY_ID);
