import React from 'react';
import { Formik } from 'formik';

import { STATUSES } from 'constants/common';

import { getLessonStatusContextItems } from 'helpers/getLessonStatusContextItems';
import { getReadonlyLessonText } from 'helpers/getReadonlyLessonText';

import { AutoSave } from 'components/AutoSave';
import { CourseTree } from 'components/CourseTree';
import { LessonLayout } from 'components/Layout';
import { LessonHeader } from 'components/LessonHeader';
import { ToolbarActions } from 'components/ToolbarActions';
import { ReadOnlySection } from 'components/ReadOnlySection';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { Column } from './components/Column';
import { Question } from './components/Question';

import {
  convertDataToForm,
  convertDataToApi,
} from './TwoColumnsLessonPage.helpers';

import styles from './TwoColumnsLessonPage.styles.scss';

const TwoColumnsLessonPage = ({
  saveLoading,
  autoSaveLoading,
  readOnly,
  lesson,
  lastActionAt,
  courseTitle,
  courseStatus,
  onAutoSaveSubmit,
  onSaveClick,
  onPreviewClick,
  onRemoveClick,
  onStatusContextItemClick,
}) => (
  <Formik
    enableReinitialize
    validateOnMount
    initialValues={convertDataToForm(lesson, readOnly)}
    onSubmit={onAutoSaveSubmit}
  >
    {({ values }) => (
      <LessonLayout
        sidebarComponent={<CourseTree />}
        toolbarComponent={
          <BreadcrumbsToolbar
            breadcrumbsData={{ courseTitle, lessonTitle: values.title }}
            endAdornment={
              <ToolbarActions
                withRemove
                saveLoading={saveLoading}
                autoSaveLoading={autoSaveLoading}
                contextItems={getLessonStatusContextItems({
                  courseReadOnly: courseStatus === STATUSES.PUBLISHED,
                  status: lesson.status,
                })}
                removeData={{ id: lesson.id, title: lesson.title }}
                updatedAt={lastActionAt}
                status={lesson.status}
                onSaveClick={() => onSaveClick(values)}
                onPreviewClick={onPreviewClick}
                onRemoveClick={onRemoveClick}
                onStatusContextItemClick={(contextItem) =>
                  onStatusContextItemClick(
                    contextItem,
                    convertDataToApi(values),
                  )
                }
              />
            }
          />
        }
      >
        <div className={styles.page}>
          <AutoSave debounceMs={1000} />
          {readOnly && (
            <ReadOnlySection text={getReadonlyLessonText(lesson.status)} />
          )}
          <LessonHeader readOnly={readOnly} />

          <div className={styles.inner}>
            <Question readOnly={readOnly} />
            <div className={styles.divider} />
            <div className={styles.main}>
              <Column namePrefix="leftVariants" readOnly={readOnly} />
              <Column namePrefix="rightVariants" readOnly={readOnly} />
            </div>
          </div>
        </div>
      </LessonLayout>
    )}
  </Formik>
);

export default React.memo(TwoColumnsLessonPage);
