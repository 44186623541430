import React from 'react';

import { Radio } from 'components/shared/Radio';

const RadioField = ({
  haveError,
  helpers,
  field,
  form,
  onChange,
  ...restProps
}) => {
  const handleChange = (event) => {
    field.onChange(event);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Radio
      {...restProps}
      checked={field.checked}
      name={field.name}
      onChange={handleChange}
    />
  );
};

export default React.memo(RadioField);
