import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { SessionProvider } from 'components/SessionProvider';
import { ScrollRestoration } from 'components/ScrollRestoration';

const App = () => {
  return (
    <SessionProvider>
      <Outlet />

      <ScrollRestoration />
      <ToastContainer hideProgressBar newestOnTop={false} closeOnClick />
    </SessionProvider>
  );
};

export default App;
