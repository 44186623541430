import React from 'react';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';

import { getLayerTypeIcon } from 'helpers/getLayerTypeIcon';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import {
  getLayerTypeIconClassName,
  getLayerSubType,
  getLayerCheck,
} from './Layer.helpers';

import styles from './Layer.styles.scss';

const Layer = ({
  open,
  current,
  hasChildren,
  namePrefix,
  layer,
  level,
  onArrowClick,
  onClick,
}) => {
  const TypeIconComponent = getLayerTypeIcon(
    layer.type,
    getLayerSubType(layer),
  );

  const typeIconClassName = getLayerTypeIconClassName(layer);

  return (
    <div
      className={cn(styles.layer, { [styles.layerCurrent]: current })}
      style={{ paddingLeft: `${8 + level * 30}px` }}
      onClick={(event) => onClick(event, namePrefix, layer)}
    >
      <div className={styles.inner}>
        <TypeIconComponent
          className={cn(
            styles.typeIcon,
            styles[`typeIcon${_upperFirst(typeIconClassName)}`],
          )}
        />
        <Typography
          className={cn(
            styles.name,
            styles[`name${_upperFirst(typeIconClassName)}`],
          )}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {layer.name}
        </Typography>
      </div>
      <div className={styles.info}>
        {getLayerCheck(layer) && <div className={styles.checkCircle} />}
        {hasChildren && (
          <Icon
            className={cn(styles.icon, { [styles.iconOpen]: open })}
            name="chevronDown"
            onClick={onArrowClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Layer);
