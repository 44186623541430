import React, { useState, useRef } from 'react';
import screenfull from 'screenfull';

import { formatTime } from './VideoBlock.helpers';

import VideoBlock from './VideoBlock';

const VideoBlockContainer = ({ data, ...restProps }) => {
  const playerContainerRef = useRef();
  const playerRef = useRef();

  const [progress, setProgress] = useState({
    percent: 0,
    total: 0,
    current: 0,
  });

  const [playerSettings, setPlayerSettings] = useState({
    playing: false,
    muted: false,
    playbackRate: 1,
    volume: 1,
    fullScreen: false,
  });

  const handleProgress = ({ playedSeconds, played, loadedSeconds }) => {
    setProgress({
      percent: played,
      total: formatTime(loadedSeconds),
      current: formatTime(playedSeconds),
    });
  };

  const handleSettingsChange = (action, value) => {
    switch (action) {
      case 'PLAY_PAUSE': {
        setPlayerSettings((prevState) => ({
          ...prevState,
          playing: !prevState.playing,
        }));

        break;
      }
      case 'MUTE_UNMUTE': {
        setPlayerSettings((prevState) => ({
          ...prevState,
          muted: !prevState.muted,
          volume: prevState.muted ? 0.5 : 0,
        }));

        break;
      }
      case 'CHANGE_RATE': {
        setPlayerSettings((prevState) => ({
          ...prevState,
          playbackRate: value,
        }));

        break;
      }
      case 'CHANGE_VOLUME': {
        setPlayerSettings((prevState) => ({
          ...prevState,
          muted: value === 0,
          volume: value,
        }));

        break;
      }
      case 'CHANGE_TIMING': {
        playerRef.current.seekTo(value, 'fraction');

        break;
      }
      case 'FULL_SCREEN': {
        screenfull.toggle(playerContainerRef.current);
        setPlayerSettings((prevState) => ({
          ...prevState,
          fullScreen: !prevState.fullScreen,
        }));

        break;
      }
    }
  };

  const handleLoopVideo = () => {
    setPlayerSettings({ ...playerSettings, playing: false });

    setTimeout(() => {
      setPlayerSettings({ ...playerSettings, playing: true });
    }, 2000);
  };

  return (
    <VideoBlock
      {...restProps}
      playerRef={playerRef}
      playerContainerRef={playerContainerRef}
      caption={data.caption}
      url={data.url}
      progress={progress}
      playerSettings={playerSettings}
      onProgress={handleProgress}
      onLoopVideo={handleLoopVideo}
      onSettingsChange={handleSettingsChange}
    />
  );
};

export default React.memo(VideoBlockContainer);
