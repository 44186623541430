import { useState } from 'react';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useFormikContext } from 'formik';

export const useChangeOrder = ({
  withOrder = true,
  items,
  namePrefix,
  onItemDragEnd,
}) => {
  const { setFieldValue } = useFormikContext();

  const [activeDragItem, setActiveDragItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleItemDragOver = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      let nextItems = [];

      if (withOrder) {
        nextItems = arrayMove(items, oldIndex, newIndex).map(
          (item, itemIndex) => ({ ...item, order: itemIndex + 1 }),
        );
      } else {
        nextItems = arrayMove(items, oldIndex, newIndex);
      }

      setFieldValue(namePrefix, nextItems);
    }
  };

  const handleItemDragStart = (event) => {
    const { active } = event;

    const dragItem = items.find((item) => item.id === active.id);

    setActiveDragItem(dragItem);
  };

  const handleItemDragEnd = () => {
    setActiveDragItem(null);

    if (onItemDragEnd) {
      onItemDragEnd(items.map((item) => item.id));
    }
  };

  return {
    sensors,
    activeDragItem,
    onItemDragStart: handleItemDragStart,
    onItemDragEnd: handleItemDragEnd,
    onItemDragOver: handleItemDragOver,
  };
};
