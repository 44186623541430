import React from 'react';
import { FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Tooltip.styles.scss';

const Tooltip = ({
  isMounted,
  triggerClassName,
  refs,
  animationStyles,
  styleProps,
  triggerElement,
  text,
  getReferenceProps,
  getFloatingProps,
}) => (
  <>
    <div
      className={cn(styles.trigger, triggerClassName)}
      ref={refs.setReference}
      {...getReferenceProps()}
    >
      {triggerElement}
    </div>
    <FloatingPortal>
      {isMounted && (
        <div
          className={styles.tooltip}
          ref={refs.setFloating}
          style={{ ...styleProps, ...animationStyles }}
          {...getFloatingProps()}
        >
          <Typography className={styles.text} variant="caption" mode="compact">
            {text}
          </Typography>
        </div>
      )}
    </FloatingPortal>
  </>
);

export default React.memo(Tooltip);
