import React from 'react';
import cn from 'classnames';

import { DEFAULT_TEXT_COLOR } from './ContextMenu.constants';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './ContextMenu.styles.scss';

const ContextMenu = ({ className, items, onItemClick }) => (
  <div className={cn(styles.menu, className)}>
    {items.map((item) => (
      <div
        key={item.key}
        className={styles.item}
        onClick={(event) => {
          event.stopPropagation();

          onItemClick(item);
        }}
      >
        {item.icon && (
          <Icon
            name={item.icon}
            style={{ color: item.iconColor || DEFAULT_TEXT_COLOR }}
            className={styles.itemIcon}
          />
        )}
        <Typography
          variant="body2"
          weight="medium"
          mode="compact"
          style={{ color: item.textColor || DEFAULT_TEXT_COLOR }}
          className={styles.itemText}
        >
          {item.text}
        </Typography>
      </div>
    ))}
  </div>
);

ContextMenu.defaultProps = {
  items: [],
};

export default React.memo(ContextMenu);
