import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './ImageBlock.styles.scss';

const ImageBlock = ({ className, data }) => (
  <div className={className}>
    <img className={styles.preview} src={data.url} />
    {data.caption && (
      <Typography className={styles.caption}>{data.caption}</Typography>
    )}
  </div>
);

export default React.memo(ImageBlock);
