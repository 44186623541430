import _isEqual from 'lodash/isEqual';

export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  title: data.title,
  status: data.status,
  blocks: data.blocks,
  free: data.free,
  readOnly,
  updatedAt: data.updatedAt,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  free: data.free,
  blocks: data.blocks,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (!_isEqual(currentLesson.blocks, nextLesson.blocks)) {
    properties.push('blocks');
  }

  return properties;
};
