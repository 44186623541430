import React from 'react';

import { DatePickerInput } from 'components/shared/DatePickerInput';

const DatePickerInputField = ({ field, helpers, ...restProps }) => {
  const handleChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <DatePickerInput
      {...restProps}
      value={field.value}
      onChange={handleChange}
    />
  );
};

export default React.memo(DatePickerInputField);
