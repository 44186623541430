import React from 'react';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reorderCourseLessons } from 'store/course/actions';

import { getTranslation } from 'helpers/getTranslation';

import Lessons from './Lessons';

const LessonsContainer = ({ lessons, moduleId, namePrefix, ...restProps }) => {
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const { values, setFieldValue } = useFormikContext();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleLessonDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = lessons.findIndex((lesson) => lesson.id === active.id);
      const newIndex = lessons.findIndex((lesson) => lesson.id === over.id);

      const nextLessons = arrayMove(lessons, oldIndex, newIndex).map(
        (lesson, lessonIndex) => ({ ...lesson, order: lessonIndex + 1 }),
      );

      setFieldValue(
        namePrefix
          ? `${namePrefix}.lessons`
          : getTranslation('course_page.lessons.text'),
        nextLessons,
      );

      dispatch(
        reorderCourseLessons({
          courseId,
          data: {
            moduleId: moduleId,
            lessons: nextLessons.map((lesson) => lesson.id),
          },
        }),
      );
    }
  };

  return (
    <Lessons
      {...restProps}
      structure={values.structure}
      lessons={lessons}
      sensors={sensors}
      onLessonDragEnd={handleLessonDragEnd}
    />
  );
};

export default React.memo(LessonsContainer);
