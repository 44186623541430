import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './ListToolbar.styles.scss';

const ListToolbar = ({ title, actionComponent }) => (
  <div className={styles.toolbar}>
    <Typography className={styles.title} variant="heading2" weight="semiBold">
      {title}
    </Typography>
    {actionComponent}
  </div>
);

export default React.memo(ListToolbar);
