import React from 'react';

import { FIGMA_LAYER_TYPES } from 'constants/common';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';

import { IconNumberProperty } from '../IconNumberProperty';
import { CornerRadiusProperty } from '../CornerRadiusProperty';
import { BooleanProperty } from '../BooleanProperty';
import { IconTextProperty } from '../IconTextProperty';
import { TextTextProperty } from '../TextTextProperty';

import styles from './BoundingBoxProperties.styles.scss';

const BoundingBoxProperties = ({
  isInitialState,
  readOnly,
  properties,
  namePrefix,
  layerType,
  designType,
  onPropertyChanged,
}) => {
  const layoutSizingHorizontal = getLayerPropertyByKey({
    key: 'layoutSizingHorizontal',
    properties,
  });
  const layoutSizingVertical = getLayerPropertyByKey({
    key: 'layoutSizingVertical',
    properties,
  });

  return (
    <div className={styles.properties}>
      <div className={styles.propertiesRow}>
        <IconNumberProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip="Position x"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'x',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'x', properties })}
          onChanged={onPropertyChanged}
        />
        <IconNumberProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip="Position y"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'y',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'y', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <IconNumberProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip="Width"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'width',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'width', properties })}
          onChanged={onPropertyChanged}
        />
        <IconNumberProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip="Height"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'height',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'height', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      {((layoutSizingHorizontal && layoutSizingHorizontal.value) ||
        (layoutSizingVertical && layoutSizingVertical.value)) && (
        <div className={styles.propertiesRow}>
          {layoutSizingHorizontal && layoutSizingHorizontal.value && (
            <IconTextProperty
              isInitialState={isInitialState}
              readOnly={readOnly}
              tooltip="Horizontal resizing"
              designType={designType}
              namePrefix={`${getPropertyNamePrefix({
                propertyKey: 'layoutSizingHorizontal',
                properties,
                namePrefix,
              })}.check`}
              property={layoutSizingHorizontal}
              onChanged={onPropertyChanged}
            />
          )}
          {layoutSizingVertical && layoutSizingVertical.value && (
            <IconTextProperty
              isInitialState={isInitialState}
              readOnly={readOnly}
              tooltip="Vertical resizing"
              designType={designType}
              namePrefix={`${getPropertyNamePrefix({
                propertyKey: 'layoutSizingVertical',
                properties,
                namePrefix,
              })}.check`}
              property={layoutSizingVertical}
              onChanged={onPropertyChanged}
            />
          )}
        </div>
      )}
      <div className={styles.propertiesRow}>
        <IconNumberProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip="Rotation"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'rotation',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'rotation', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      {layerType !== FIGMA_LAYER_TYPES.TEXT &&
        layerType !== FIGMA_LAYER_TYPES.LINE &&
        layerType !== FIGMA_LAYER_TYPES.GROUP &&
        layerType !== FIGMA_LAYER_TYPES.VECTOR && (
          <div className={styles.propertiesRow}>
            <CornerRadiusProperty
              isInitialState={isInitialState}
              readOnly={readOnly}
              tooltip="Corner radius"
              designType={designType}
              namePrefix={`${getPropertyNamePrefix({
                propertyKey: 'cornerRadius',
                properties,
                namePrefix,
              })}.check`}
              property={getLayerPropertyByKey({
                key: 'cornerRadius',
                properties,
              })}
              onChanged={onPropertyChanged}
            />
          </div>
        )}
      {layerType === FIGMA_LAYER_TYPES.STAR && (
        <div className={styles.propertiesRow}>
          <IconTextProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            tooltip="Count"
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'pointCount',
              properties,
              namePrefix,
            })}.check`}
            property={{
              ...getLayerPropertyByKey({
                key: 'pointCount',
                properties,
              }),
              key: 'starPointCount',
            }}
            onChanged={onPropertyChanged}
          />
          <IconTextProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            tooltip="Ratio"
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'innerRadius',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'innerRadius',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
      {layerType === FIGMA_LAYER_TYPES.REGULAR_POLYGON && (
        <div className={styles.propertiesRow}>
          <IconTextProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            tooltip="Count"
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'pointCount',
              properties,
              namePrefix,
            })}.check`}
            property={{
              ...getLayerPropertyByKey({
                key: 'pointCount',
                properties,
              }),
              key: 'polygonPointCount',
            }}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
      {layerType === FIGMA_LAYER_TYPES.ELLIPSE && (
        <div className={styles.propertiesRow}>
          <IconNumberProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            tooltip="Start"
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'arcDataStartingAngle',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'arcDataStartingAngle',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
          <TextTextProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'arcDataEndingAngle',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'arcDataEndingAngle',
              properties,
            })}
            dependProperty={getLayerPropertyByKey({
              key: 'arcDataStartingAngle',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
          <TextTextProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'arcDataInnerRadius',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'arcDataInnerRadius',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
      {(layerType === FIGMA_LAYER_TYPES.FRAME ||
        layerType === FIGMA_LAYER_TYPES.COMPONENT ||
        layerType === FIGMA_LAYER_TYPES.COMPONENT_SET ||
        layerType === FIGMA_LAYER_TYPES.INSTANCE) && (
        <div className={styles.propertiesRow}>
          <BooleanProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'clipsContent',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'clipsContent',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
      <div className={styles.propertiesRow}>
        <TextTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'parent',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'parent', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      {layerType === FIGMA_LAYER_TYPES.BOOLEAN_OPERATION && (
        <div className={styles.propertiesRow}>
          <TextTextProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'booleanOperation',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({
              key: 'booleanOperation',
              properties,
            })}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
      <div className={styles.propertiesRow}>
        <BooleanProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'isMask',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'isMask',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      {layerType === FIGMA_LAYER_TYPES.VECTOR && (
        <div className={styles.propertiesRow}>
          <TextTextProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'vectorPaths',
              properties,
              namePrefix,
            })}.check`}
            property={{
              ...getLayerPropertyByKey({
                key: 'vectorPaths',
                properties,
              }),
            }}
            onChanged={onPropertyChanged}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(BoundingBoxProperties);
