import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { TOKEN_KEY } from 'constants/token';
import { ROUTES } from 'constants/routes';

import { getUserInfo } from 'store/auth/actions';

import SessionProvider from './SessionProvider';

const SessionProviderContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { i18n } = useTranslation();

  const authToken = localStorage.getItem(TOKEN_KEY);

  const [loading, setLoading] = useState(!!authToken);

  const _getUserInfo = async () => {
    if (authToken) {
      try {
        await dispatch(getUserInfo()).unwrap();

        setLoading(false);

        if (location.pathname === '/') {
          navigate(ROUTES.COURSES_PAGE, { replace: true });
        }
      } catch (error) {
        setLoading(false);

        navigate('/', { replace: true });
      }
    }
  };

  useEffect(() => {
    _getUserInfo();
  }, []);

  // console.log(i18n);

  // i18n.changeLanguage('ru');

  return <SessionProvider {...props} loading={loading} />;
};

export default React.memo(SessionProviderContainer);
