import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const initialValues = {
  email: '',
  password: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(getTranslation('config.auth_form.email_format'))
      .required(getTranslation('config.auth_form.email_required')),
    password: Yup.string().required(
      getTranslation('config.auth_form.password_required'),
    ),
  });
