import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';
import { getPropertyTooltipValue } from 'helpers/getPropertyTooltipValue';

import { TextTextProperty } from '../TextTextProperty';
import { IconNumberProperty } from '../IconNumberProperty';
import { IconTextProperty } from '../IconTextProperty';
import { TextIconProperty } from '../TextIconProperty';
import { BooleanProperty } from '../BooleanProperty';

import styles from './TextProperties.styles.scss';

const TextProperties = ({
  isInitialState,
  readOnly,
  designType,
  properties,
  namePrefix,
  onPropertyChanged,
}) => {
  return (
    <div className={styles.properties}>
      <div className={styles.propertiesRow}>
        <TextTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'fontName',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'fontName', properties })}
          onChanged={onPropertyChanged}
        />
        <TextTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'fontSize',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'fontSize', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <TextTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={
            getLayerPropertyByKey({ key: 'characters', properties })?.value
          }
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'characters',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'characters', properties })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <IconTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip="Line height"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'lineHeight',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'lineHeight', properties })}
          onChanged={onPropertyChanged}
        />
        <IconNumberProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip="Letter spacing"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'letterSpacing',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({ key: 'letterSpacing', properties })}
          dependProperty={getLayerPropertyByKey({
            key: 'fontSize',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <IconNumberProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip="Paragraph spacing"
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paragraphSpacing',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'paragraphSpacing',
            properties,
          })}
          onChanged={onPropertyChanged}
        />

        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'textAutoResize',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textAutoResize',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textAutoResize',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'textAlignHorizontal',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textAlignHorizontal',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textAlignHorizontal',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'textAlignVertical',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textAlignVertical',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textAlignVertical',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'textDecoration',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textDecoration',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textDecoration',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'textCase',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textCase',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textCase',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'textTruncation',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'textTruncation',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <TextIconProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          tooltip={getPropertyTooltipValue({
            key: 'leadingTrim',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'leadingTrim',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'leadingTrim',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <TextTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'listSpacing',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'listSpacing',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <TextTextProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paragraphIndent',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'paragraphIndent',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={styles.propertiesRow}>
        <BooleanProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'hangingPunctuation',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'hangingPunctuation',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <BooleanProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'hangingList',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'hangingList',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
    </div>
  );
};

export default React.memo(TextProperties);
