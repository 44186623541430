import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { LessonPreviewLayout } from 'components/Layout';

import { Tasks } from './components/Tasks';
import { Toolbar } from './components/Toolbar';
import { LessonResult } from './components/LessonResult';
import { GoodToKnowSection } from './components/GoodToKnowSection';
import { ExpandedResultPreview } from './components/ExpandedResultPreview';

import styles from './FigmaLessonPreviewPage.styles.scss';

const FigmaLessonPreviewPage = ({
  loading,
  nextLoading,
  previewExpanded,
  isLastLessonInCourse,
  completed,
  currentTasks,
  currentTaskIndex,
  currentLesson,
  lessonsCount,
  lessonTitle,
  resultPreview,
  goodToKnowBlocks,
  onNextLessonClick,
  onExpandClick,
  onExitPreviewClick,
  setPreviewType,
  isTestMode,
  setTestMode,
}) => (
  <LessonPreviewLayout
    size="sm"
    setPreviewType={setPreviewType}
    setTestMode={setTestMode}
    isFigmaLesson
    toolbarComponent={
      <div className={styles.toolbar}>
        <Toolbar
          lessonTitle={lessonTitle}
          currentLesson={currentLesson}
          lessonsCount={lessonsCount}
        />
      </div>
    }
  >
    <div className={styles.page}>
      {loading ? (
        <Loading />
      ) : !previewExpanded ? (
        <>
          <LessonResult
            preview={resultPreview}
            onExpandClick={isTestMode && onExpandClick}
            isTestMode={isTestMode}
          />
          <Tasks
            tasks={currentTasks}
            currentTaskIndex={currentTaskIndex}
            isTestMode={isTestMode}
          />
          {goodToKnowBlocks.length > 0 && (
            <GoodToKnowSection
              data={{ blocks: goodToKnowBlocks }}
              isTestMode={isTestMode}
            />
          )}
          {isTestMode && (
            <div className={styles.actions}>
              <Button
                className={styles.action}
                disabled={!completed}
                size="md"
                startIcon={
                  nextLoading && <Loading color="var(--color-white-primary)" />
                }
                onClick={onNextLessonClick}
              >
                {isLastLessonInCourse
                  ? getTranslation('figma_lesson_preview_page.finish_course')
                  : getTranslation('figma_lesson_preview_page.next_lesson')}
              </Button>

              <Typography
                className={styles.text}
                variant="body3"
                mode="compact"
              >
                {getTranslation('figma_lesson_preview_page.text')}
              </Typography>
            </div>
          )}
        </>
      ) : (
        <ExpandedResultPreview
          preview={resultPreview}
          onExitPreviewClick={onExitPreviewClick}
        />
      )}
    </div>
  </LessonPreviewLayout>
);

export default React.memo(FigmaLessonPreviewPage);
