import React from 'react';

import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';

import styles from './StudentInfo.styles.scss';

const StudentInfo = ({ data, onStudentClick }) => {
  return (
    <div
      className={styles.inner}
      onClick={() => onStudentClick(data.student.id)}
    >
      {data.student && (
        <Avatar
          url={data.student.avatar}
          size="sm"
          text={data.student.name || data.student.email}
        />
      )}
      <div className={styles.main}>
        <div>
          {data.student && (
            <Typography variant="body3" weight="medium" mode="compact">
              {data.student.firstName || '-'}
            </Typography>
          )}
        </div>
        <div>
          {data.student && (
            <Typography className={styles.email} variant="body3" mode="compact">
              {data.student.email}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(StudentInfo);
