import React from 'react';
import cn from 'classnames';
import pluralize from 'pluralize';

import { getDifficultyText } from 'helpers/getDifficultyText';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { Difficulty } from '../Difficulty';

import styles from './CourseInfo.styles.scss';

const renderItem = (
  itemType,
  {
    withBorder,
    itemClassName,
    iconClassName,
    textClassName,
    lessonsCount,
    studentsCount,
    difficulty,
  },
) => {
  switch (itemType) {
    case 'LESSONS_COUNT': {
      return (
        <div
          key={itemType}
          className={cn(styles.item, itemClassName, {
            [styles.itemWithBorder]: withBorder,
          })}
        >
          <Icon name="play" className={cn(styles.icon, iconClassName)} />

          <Typography
            variant="body3"
            weight="medium"
            className={cn(styles.text, textClassName)}
          >
            {pluralize('lessons', lessonsCount, true)} left
          </Typography>
        </div>
      );
    }
    case 'DIFFICULTY': {
      return (
        <div
          key={itemType}
          className={cn(styles.item, itemClassName, {
            [styles.itemWithBorder]: withBorder,
          })}
        >
          <Difficulty
            className={cn(styles.difficultyIcon, iconClassName)}
            value={difficulty}
          />
          <Typography
            variant="body3"
            weight="medium"
            className={cn(styles.text, textClassName)}
          >
            {getDifficultyText(difficulty)}
          </Typography>
        </div>
      );
    }
    case 'STUDENTS': {
      return (
        <div
          key={itemType}
          className={cn(styles.item, itemClassName, {
            [styles.itemWithBorder]: withBorder,
          })}
        >
          <Icon name="users" className={cn(styles.icon, iconClassName)} />
          <Typography
            variant="body3"
            weight="medium"
            className={cn(styles.text, textClassName)}
          >
            {studentsCount}
          </Typography>
        </div>
      );
    }
  }
};

const CourseInfo = ({
  className,
  itemClassName,
  iconClassName,
  textClassName,
  withBorder,
  items,
  lessonsCount,
  studentsCount,
  difficulty,
}) => (
  <div className={cn(styles.info, className)}>
    {items.map((item) =>
      renderItem(item, {
        withBorder,
        itemClassName,
        iconClassName,
        textClassName,
        lessonsCount,
        studentsCount,
        difficulty,
      }),
    )}
  </div>
);

CourseInfo.defaultProps = {
  items: [],
  withBorder: false,
};

export default React.memo(CourseInfo);
