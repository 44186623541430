import React from 'react';
import { createPortal } from 'react-dom';
import { DragOverlay, useDndContext } from '@dnd-kit/core';

import { Item } from '../Item';

import { dropAnimationConfig } from './DraggableOverlay.config';

const DraggableOverlay = ({ dropAnimation = dropAnimationConfig }) => {
  const { active } = useDndContext();

  const data = active ? active.data?.current : null;

  return createPortal(
    <DragOverlay dropAnimation={dropAnimation}>
      {active ? <Item {...data} dragging id={active.id} /> : null}
    </DragOverlay>,
    document.body,
  );
};

export default React.memo(DraggableOverlay);
