import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './ProgressCard.styles.scss';

const CenteredMetric = ({ currentPercent, centerX, centerY, ...restProps }) => {
  //   let total = 0;
  //   dataWithArc.forEach((datum) => {
  //     total += datum.value;
  //   });

  if (currentPercent) {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 20,
          lineHeight: 24,
          fontFamily: 'Inter',
          fontWeight: 600,
          fill: '#021930',
        }}
      >
        {currentPercent}%
      </text>
    );
  }
};

const Tooltip = ({ datum }) => {
  const { value } = datum;

  return (
    <div className={styles.tooltip}>
      <Typography
        className={styles.tooltipText}
        variant="caption"
        mode="compact"
      >
        {getTranslation('dashboard_page.progress_card.students_value', {
          value,
        })}
      </Typography>
    </div>
  );
};

const ProgressCard = ({
  className,
  data,
  studentsCount,
  currentPercent,
  onPieSliceMouseEnter,
  onPieSliceMouseLeave,
}) => (
  <div className={cn(styles.card, className)}>
    <div className={styles.header}>
      <Typography
        className={styles.title}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('dashboard_page.progress_card.title')}
      </Typography>
      <Typography
        className={styles.text}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('dashboard_page.progress_card.students_count', {
          studentsCount,
        })}
      </Typography>
    </div>
    <div className={styles.inner}>
      <ResponsivePie
        colors={{ datum: 'data.color' }}
        data={data}
        margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
        innerRadius={0.83}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        theme={{
          legends: {
            text: {
              fontFamily: 'Inter',
              fontSize: 12,
              lineHeight: 14,
              fontWeight: 500,
            },
          },
        }}
        layers={[
          'arcLinkLabels',
          'arcs',
          'arcLabels',
          'legends',
          (props) => (
            <CenteredMetric {...props} currentPercent={currentPercent} />
          ),
        ]}
        tooltip={({ datum }) => <Tooltip datum={datum} />}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 40,
            itemsSpacing: 110,
            itemWidth: 85,
            itemHeight: 14,
            itemTextColor: '#021930',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 12,
            symbolShape: 'circle',
          },
        ]}
        onMouseEnter={onPieSliceMouseEnter}
        onMouseLeave={onPieSliceMouseLeave}
      />
    </div>
  </div>
);

export default React.memo(ProgressCard);
