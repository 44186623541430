import React from 'react';

import { getTranslation } from 'helpers/getTranslation';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './LessonResult.styles.scss';

const LessonResult = ({ preview, onExpandClick, isTestMode }) => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <img className={styles.img} src={preview} />
      <div className={styles.actions}>
        <Button
          mode="icon"
          iconName="expand"
          onClick={onExpandClick}
          className={!isTestMode && styles.readOnlyButton}
        />
      </div>
    </div>
    <div className={styles.lessonResult}>
      <Typography
        className={styles.title}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('figma_lesson_preview_page.lesson_result.text')}
      </Typography>
      <Icon className={styles.icon} name="expand" />
    </div>
  </div>
);

export default React.memo(LessonResult);
