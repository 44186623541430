import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';

import { UploadStep } from './components/UploadStep';
import { UploadResultsStep } from './components/UploadResultsStep';

import styles from './ImportAdmins.styles.scss';

const ImportAdmins = ({
  open,
  importedAdmins,
  percentage,
  uploadError,
  onClose,
  onSwiper,
  onDropAccepted,
  onDropRejected,
  onImportClick,
  onDownloadTemplateClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('components.import_admins.header_text')}
    onClose={onClose}
  >
    <div className={styles.inner}>
      <div className={styles.main}>
        <Swiper
          className={styles.swiper}
          allowTouchMove={false}
          spaceBetween={16}
          slidesPerView={1}
          onSwiper={onSwiper}
        >
          <SwiperSlide>
            <UploadStep
              percentage={percentage}
              uploadError={uploadError}
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              onDownloadTemplateClick={onDownloadTemplateClick}
            />
          </SwiperSlide>
          <SwiperSlide>
            <UploadResultsStep importedAdmins={importedAdmins} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.action}
          size="md"
          variant="outlined"
          onClick={onClose}
        >
          {getTranslation('common.button.cancel')}
        </Button>
        <Button
          className={styles.action}
          disabled={
            (importedAdmins && importedAdmins.length) === 0 ||
            importedAdmins.filter(
              (importedAdmin) => importedAdmin.status === 'SUCCESS',
            ).length === 0
          }
          size="md"
          onClick={onImportClick}
        >
          {getTranslation('common.button.import')}
        </Button>
      </div>
    </div>
  </Drawer>
);

export default React.memo(ImportAdmins);
