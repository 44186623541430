import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createSchool } from 'store/schools/actions';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { convertDataToApi } from './AddSchool.helpers';

import AddSchool from './AddSchool';

const AddSchoolContainer = ({ open, onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const [addLoading, setAddLoading] = useState(false);

  const handleAddSchoolClick = async (values, { resetForm }) => {
    try {
      setAddLoading(true);

      await dispatch(createSchool(convertDataToApi(values))).unwrap();

      onClose();
      resetForm();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.school_invited.title'),
          text: getTranslation('common.toast.school_invited.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setAddLoading(false);
  };

  return (
    <AddSchool
      {...restProps}
      open={open}
      addLoading={addLoading}
      onClose={onClose}
      onAddSchoolClick={handleAddSchoolClick}
    />
  );
};

export default React.memo(AddSchoolContainer);
