import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getCourses = createAsyncThunk(
  types.GET_COURSES,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const courses = isRootAdmin
      ? await api.getCourses(params)
      : await api.getSchoolCourses({ schoolId: user.schoolId, params });

    return courses;
  },
);

export const getSchoolCourses = createAsyncThunk(
  types.GET_SCHOOL_COURSES,
  async (data) => {
    const courses = await api.getSchoolCourses(data);

    return courses;
  },
);

export const updateCourseById = createAsyncThunk(
  types.UPDATE_COURSE_BY_ID,
  async ({ courseId, fieldName, data }) => {
    const course = await api.updateCourseById({ courseId, data });

    return { fieldName, updatedCourse: course };
  },
);

export const removeCourseById = createAction(types.REMOVE_COURSE_BY_ID);
