import addDays from 'date-fns/addDays';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import startOfWeek from 'date-fns/startOfWeek';

export const getWeekdays = (locale, weekStartsOn, ISOWeek) => {
  const start = ISOWeek
    ? startOfISOWeek(new Date())
    : startOfWeek(new Date(), { locale, weekStartsOn });

  const days = [];

  for (let i = 0; i < 7; i++) {
    const day = addDays(start, i);

    days.push(day);
  }

  return days;
};
