import { createSlice } from '@reduxjs/toolkit';

import {
  getStudentById,
  updateStudentById,
  grantStudentCoursesAccess,
} from './actions';

const studentSlice = createSlice({
  name: 'currentStudent',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudentById.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateStudentById.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(grantStudentCoursesAccess.fulfilled, (state, action) => {
      state.courses = action.payload;
    });
  },
});

export default studentSlice.reducer;
