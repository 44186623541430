import { COURSE_PLATFORMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const getPlatformOptions = () => [
  {
    label: getTranslation('common.text.figma_plugin'),
    value: COURSE_PLATFORMS.FIGMA_PLUGIN,
  },
  {
    label: getTranslation('common.text.web'),
    value: COURSE_PLATFORMS.WEB,
  },
];
