import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Typography } from 'components/shared/Typography';

import { CourseTree } from 'components/CourseTree';

import styles from './LessonsSection.styles.scss';

const LessonsSection = ({ open, onClose }) => (
  <Drawer
    className={styles.drawer}
    headerClassName={styles.drawerHeader}
    withBackdrop={false}
    open={open}
    headerContent={
      <Typography
        className={styles.title}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('figma_lesson_page.lessons_section.lessons')}
      </Typography>
    }
    position="left"
    onClose={onClose}
  >
    <CourseTree />
  </Drawer>
);

export default React.memo(LessonsSection);
