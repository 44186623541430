import React from 'react';
import cn from 'classnames';

import { Line } from '../Line';

import styles from './Lines.styles.scss';

const Lines = ({ className, lineDrawing, lines, containerRef }) => {
  return (
    <div className={cn(styles.lines, className)}>
      <div
        className={styles.inner}
        ref={containerRef}
        style={{
          cursor: lineDrawing ? 'none' : 'auto',
        }}
      >
        {lines.map((line, index) => (
          <Line
            key={line.startId + line.startType}
            containerRef={containerRef}
            {...line}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Lines);
