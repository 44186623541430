import React from 'react';

import { Toolbar } from './components/Toolbar';
import { PluginPreviewPage } from './components/PluginPreviewPage';
import { LessonPreviewLayout } from 'components/Layout';

import styles from './ColorMatchLessonPreviewPage.styles.scss';

const ColorMatchLessonPage = ({
  nextLoading,
  isLastLessonInCourse,
  submitted,
  imageRef,
  lesson,
  currentColor,
  currentImage,
  matchPercent,
  onColorChange,
  openReportLessonModal,
  onCheckColorClick,
  onNextLessonClick,
  previewType,
  setTestMode,
  setPreviewType,
  onChangePreviewType,
  lessonTitle,
  currentLesson,
  lessonsCount,
  ...props
}) => {
  return (
    <LessonPreviewLayout
      setPreviewType={setPreviewType}
      setTestMode={setTestMode}
      toolbarComponent={
        <div className={styles.toolbar}>
          <Toolbar
            lessonTitle={lessonTitle}
            currentLesson={currentLesson}
            lessonsCount={lessonsCount}
          />
        </div>
      }
    >
      {previewType === 'Plugin' ? (
        <PluginPreviewPage
          {...props}
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          submitted={submitted}
          imageRef={imageRef}
          lesson={lesson}
          currentColor={currentColor}
          currentImage={currentImage}
          matchPercent={matchPercent}
          onColorChange={onColorChange}
          openReportLessonModal={openReportLessonModal}
          onCheckColorClick={onCheckColorClick}
          onNextLessonClick={onNextLessonClick}
        />
      ) : (
        <PluginPreviewPage
          {...props}
          nextLoading={nextLoading}
          isLastLessonInCourse={isLastLessonInCourse}
          submitted={submitted}
          imageRef={imageRef}
          lesson={lesson}
          currentColor={currentColor}
          currentImage={currentImage}
          matchPercent={matchPercent}
          onColorChange={onColorChange}
          openReportLessonModal={openReportLessonModal}
          onCheckColorClick={onCheckColorClick}
          onNextLessonClick={onNextLessonClick}
        />
      )}
    </LessonPreviewLayout>
  );
};

export default React.memo(ColorMatchLessonPage);
