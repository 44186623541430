import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './PreviewPlaceholder.styles.scss';

const VARIANTS = {
  quiz: styles.quiz,
  connectionQuiz: styles.connectionQuiz,
  difference: styles.difference,
};

const PreviewPlaceholder = ({ className, variant }) => (
  <div className={cn(styles.placeholder, VARIANTS[variant], className)}>
    <Icon className={styles.icon} name="image" />
    <Typography
      className={styles.text}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('components.preview_placeholder.text')}
    </Typography>
  </div>
);

export default React.memo(PreviewPlaceholder);
