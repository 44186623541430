export const getLayerReactions = (layer) => {
  if (!layer.reactions || layer.reactions.length === 0) {
    return [];
  }

  // return layer.reactions.map((reaction) => {
  //   const commonProperties = {
  //     triggerType: reaction.trigger.type,
  //     actionType: reaction.action.type,
  //     actionDestinationLayer: null, // TODO: get the name of the layer,
  //     actionNavigation: reaction.action.navigation,
  //   };

  //   let transitionProperties = {};

  //   if (reaction.action.transition) {
  //     transitionProperties.actionTransitionType = reaction.transition.type;
  //     transitionProperties.actionTransitionEasingType =
  //       reaction.transition.easing.type;
  //     transitionProperties.actionTransitionDuration =
  //       reaction.transition.duration;
  //   }

  //   return {
  //     ...commonProperties,
  //     ...transitionProperties,
  //   };
  // });

  return layer.reactions.map((reaction) => ({
    action: reaction.action,
    trigger: reaction.trigger,
  }));
};
