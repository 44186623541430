import React, { useEffect, useRef } from 'react';

import ColorItem from './ColorItem';

const ColorItemContainer = ({ color, ...restProps }) => {
  const imageRef = useRef();

  useEffect(() => {
    if (imageRef.current && color) {
      const svgImage = imageRef.current.children[0];

      if (svgImage) {
        svgImage.style = `color: ${color}`;
      }
    }
  }, [imageRef, color]);

  return <ColorItem {...restProps} imageRef={imageRef} color={color} />;
};

export default React.memo(ColorItemContainer);
