import { createAsyncThunk } from '@reduxjs/toolkit';

import { LESSON_TYPES } from 'constants/common';

import * as api from 'api/methods';

import { getChangedPropertiesByKeys } from 'helpers/getChangedPropertiesByKeys';

import * as types from './types';

export const getLessonById = createAsyncThunk(
  types.GET_LESSON_BY_ID,
  async ({ courseId, lessonId }) => {
    const lesson = await api.getCourseLessonById({ courseId, lessonId });

    return lesson;
  },
);

export const updateLessonStatusById = createAsyncThunk(
  types.UPDATE_LESSON_STATUS_BY_ID,
  async ({ lessonId, lessonType, data }) => {
    let lesson = null;

    switch (lessonType) {
      case LESSON_TYPES.QUIZ: {
        lesson = await api.updateQuizById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.CONNECTION_QUIZ: {
        lesson = await api.updateConnectionQuizById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.THEORY_LESSON: {
        lesson = await api.updateTheoryLessonById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.FIGMA_LESSON: {
        lesson = await api.updateFigmaLessonById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.DIFFERENCE_LESSON: {
        lesson = await api.updateDifferenceLessonById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.TWO_COLUMNS_LESSON: {
        lesson = await api.updateTwoColumnsLessonById({
          lessonId,
          data,
        });

        break;
      }
      case LESSON_TYPES.COLOR_MATCH_LESSON: {
        lesson = await api.updateColorMatchLessonById({
          lessonId,
          data,
        });

        break;
      }
    }

    return lesson;
  },
);

export const updateLessonById = createAsyncThunk(
  types.UPDATE_LESSON_BY_ID,
  async ({ lessonId, lessonType, data, changedPropertiesKeys }) => {
    let changedLessonProperties = null;

    switch (lessonType) {
      case LESSON_TYPES.QUIZ: {
        const lesson = await api.updateQuizById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.CONNECTION_QUIZ: {
        const lesson = await api.updateConnectionQuizById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.THEORY_LESSON: {
        const lesson = await api.updateTheoryLessonById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.FIGMA_LESSON: {
        const lesson = await api.updateFigmaLessonById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.DIFFERENCE_LESSON: {
        const lesson = await api.updateDifferenceLessonById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.TWO_COLUMNS_LESSON: {
        const lesson = await api.updateTwoColumnsLessonById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
      case LESSON_TYPES.COLOR_MATCH_LESSON: {
        const lesson = await api.updateColorMatchLessonById({
          lessonId,
          data: getChangedPropertiesByKeys({
            data,
            keys: changedPropertiesKeys,
          }),
        });

        changedLessonProperties = getChangedPropertiesByKeys({
          data: lesson,
          keys: [...changedPropertiesKeys, 'updatedAt'],
        });

        break;
      }
    }

    return changedLessonProperties;
  },
);

export const removeLessonById = createAsyncThunk(
  types.REMOVE_LESSON_BY_ID,
  async ({ courseId, lessonId }) => {
    const lesson = api.removeCourseLessonById({ courseId, lessonId });

    return lesson;
  },
);
