import { createSlice } from '@reduxjs/toolkit';

import {
  createAdmin,
  getAdmins,
  getSchoolAdmins,
  finishImportAdmins,
} from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  perPage: 10,
  totalPages: null,
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdmins.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(getSchoolAdmins.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(createAdmin.fulfilled, (state, action) => {
      const existingAdmin = state.data.some(
        (admin) => admin.id === action.payload.id,
      );

      return {
        ...state,
        data: existingAdmin
          ? state.data.map((admin) => {
              if (admin.id === action.payload.id) {
                return action.payload;
              }

              return admin;
            })
          : [...state.data, action.payload],
      };
    });

    builder.addCase(finishImportAdmins.fulfilled, (state, action) => {
      state.data.push(...action.payload);
    });
  },
});

export default adminsSlice.reducer;
