import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  COURSE_STRUCTURES,
  STATUSES,
  LESSON_TYPES,
  COURSE_TYPES,
} from 'constants/common';

import * as api from 'api/methods';

import {
  getCourseById,
  updateCourseById,
  updateCourseModuleById,
  updateCourseStatus,
} from 'store/course/actions';
import { currentCourseSelector } from 'store/course/selectors';

import { useCheckCourseStructure } from 'hooks/useCheckCourseStructure';
import { useModal } from 'hooks/useModal';

import { checkQuestionsValidation as checkDifferenceLessonQuestionsValidation } from 'pages/DifferenceLessonPage/DifferenceLessonPage.helpers';
import { checkCorrectAnswers as checkQuizCorrectAnswers } from 'pages/QuizPage/QuizPage.helpers';
import { checkAnswersValue as checkQuizAnswersValue } from 'pages/QuizPage/QuizPage.helpers';
import { checkAnswersValid as checkConnectionQuizAnswersValid } from 'pages/ConnectionQuizPage/ConnectionQuizPage.helpers';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

const generateCourseSlug = (courseTitle) =>
  courseTitle
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replaceAll(' ', '-');

const _convertCourseDataToApi = (data) => ({
  slug: generateCourseSlug(data.title),
  title: data.title,
  description: data.description,
  preview: data.preview,
  resultPreview: data.withResultPreview ? data.resultPreview : '',
  difficulty: data.difficulty,
  format: data.format || null,
  premium: !data.free,
});

const _convertCourseModuleDataToApi = (data) => ({
  title: data.title,
  preview: data.preview,
});

const _checkCourseChanged = ({ currentCourse, nextCourse }) => {
  if (currentCourse.title !== nextCourse.title) {
    return true;
  }

  if (currentCourse.description !== nextCourse.description) {
    return true;
  }

  if (currentCourse.preview !== nextCourse.preview) {
    return true;
  }

  if (currentCourse.difficulty !== nextCourse.difficulty) {
    return true;
  }

  if (
    currentCourse.type === COURSE_TYPES.ARCADE &&
    currentCourse.format !== nextCourse.format
  ) {
    return true;
  }

  if (currentCourse.premium !== nextCourse.premium) {
    return true;
  }

  if (currentCourse.resultPreview !== nextCourse.resultPreview) {
    return true;
  }

  return false;
};

export const _validateResultPreview = (values) => {
  return (
    (values.withResultPreview && values.resultPreview !== '') ||
    !values.withResultPreview
  );
};

const _getModulesToUpdate = ({ currentModules, nextModules }) => {
  const toUpdate = [];

  for (let i = 0; i < currentModules.length; i++) {
    const currentModule = currentModules[i];
    const nextModule = nextModules.find(
      (module) => module.id === currentModule.id,
    );

    if (currentModule.title !== nextModule.title) {
      toUpdate.push(nextModule);
    }

    if (currentModule.preview !== nextModule.preview) {
      toUpdate.push(nextModule);
    }
  }

  return toUpdate;
};

const _getErrorText = (error) => {
  if (error.code === 'COURSE_TITLE_ALREADY_EXISTS') {
    return {
      variant: 'warning',
      title: error.title,
      text: error.text,
    };
  }

  return {
    variant: 'error',
    title: getTranslation('common.toast.error.title'),
    text: getTranslation('common.toast.error.text'),
  };
};

const _validateCourseLesson = (
  { lesson, lessonIndex, moduleIndex },
  { setFieldValue },
) => {
  const namePrefix =
    moduleIndex !== null
      ? `modules[${moduleIndex}].lessons[${lessonIndex}].statusError`
      : `lessons[${lessonIndex}].statusError`;

  if (
    lesson.type === LESSON_TYPES.DIFFERENCE_LESSON &&
    !checkDifferenceLessonQuestionsValidation(lesson)
  ) {
    const errorText = getTranslation('common.toast.answer_warning.text');
    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (lesson.type === LESSON_TYPES.QUIZ && !checkQuizCorrectAnswers(lesson)) {
    const errorText = getTranslation('common.toast.answer_warning.text');

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (lesson.type === LESSON_TYPES.QUIZ && !checkQuizAnswersValue(lesson)) {
    const errorText = getTranslation('hooks.use_course.quiz_error_text');

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  if (
    lesson.type === LESSON_TYPES.CONNECTION_QUIZ &&
    !checkConnectionQuizAnswersValid(lesson)
  ) {
    const errorText = getTranslation(
      'hooks.use_course.connection_quiz_error_text',
    );

    setFieldValue(namePrefix, errorText);

    return { errorText, valid: false };
  }

  return { errorText: null, valid: true };
};

const _validateCourseLessons = (values, setFieldValue) => {
  const { structure } = values;

  let valid = true;

  if (structure === COURSE_STRUCTURES.MODULES) {
    for (let [moduleIndex, module] of values.modules.entries()) {
      for (let [lessonIndex, lesson] of module.lessons.entries()) {
        const { valid: lessonValid } = _validateCourseLesson(
          { lesson, lessonIndex, moduleIndex },
          { setFieldValue },
        );

        valid = lessonValid;
      }
    }
  }

  if (structure === COURSE_STRUCTURES.LESSONS) {
    for (let [lessonIndex, lesson] of values.lessons.entries()) {
      const { valid: lessonValid } = _validateCourseLesson(
        { lesson, lessonIndex, moduleIndex: null },
        { setFieldValue },
      );

      valid = lessonValid;
    }
  }

  return valid;
};

const _validateTraningLessons = (values) => {
  if (values.resultPreview === '') {
    return false;
  }

  return true;
};

export const useCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: courseId } = useParams();

  const course = useSelector(currentCourseSelector);

  const readOnly = course?.status !== 'DRAFT';

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [lastActionAt, setLastActionAt] = useState(null);

  const [marketingSettingsOpen, openMarketingSettings, closeMarketingSettings] =
    useModal({
      defaultOpen: false,
    });
  const [paymentSettingsOpen, openPaymentSettings, closePaymentSettings] =
    useModal({
      defaultOpen: false,
    });
  const [courseSettingsOpen, openCourseSettings, closeCourseSettings] =
    useModal({
      defaultOpen: false,
    });

  useEffect(() => {
    const _getCourseById = async () => {
      try {
        const initialCourse = await dispatch(getCourseById(courseId)).unwrap();

        setLastActionAt(initialCourse.lastActionAt);
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCourseById();
  }, []);

  useCheckCourseStructure();

  const handleAutoSaveSubmit = async (values, { setFieldValue }) => {
    setAutoSaveLoading(true);

    const data = _convertCourseDataToApi(values);

    try {
      const courseChanged = _checkCourseChanged({
        currentCourse: course,
        nextCourse: data,
      });

      if (courseChanged) {
        const updatedCourse = await api.updateCourseById({ courseId, data });

        setLastActionAt(updatedCourse.lastActionAt);
      }

      if (course.structure === COURSE_STRUCTURES.MODULES) {
        const modulesToUpdate = _getModulesToUpdate({
          currentModules: course.modules,
          nextModules: values.modules,
        });

        const modulesToUpdatePromises = modulesToUpdate.map((module) =>
          api.updateCourseModuleById({
            courseId,
            moduleId: module.id,
            data: _convertCourseModuleDataToApi(module),
          }),
        );

        await Promise.all(modulesToUpdatePromises);
      }
    } catch (error) {
      showToast({
        data: _getErrorText(error),
      });
    }

    setAutoSaveLoading(false);
  };

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    const data = _convertCourseDataToApi(values);

    if (!_validateResultPreview(values)) {
      showToast({
        data: {
          variant: 'warning',
          title: getTranslation('common.toast.upload_preview.title'),
          text: getTranslation('common.toast.upload_preview.text'),
        },
      });

      setSaveLoading(false);

      return;
    }

    try {
      await dispatch(updateCourseById({ courseId, data })).unwrap();

      if (course.structure === COURSE_STRUCTURES.MODULES) {
        const modulesToUpdate = _getModulesToUpdate({
          currentModules: course.modules,
          nextModules: values.modules,
        });

        const modulesToUpdatePromises = modulesToUpdate.map((module) =>
          dispatch(
            updateCourseModuleById({
              courseId,
              moduleId: module.id,
              data: _convertCourseModuleDataToApi(module),
            }),
          ),
        );

        await Promise.all(modulesToUpdatePromises);
      }

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_saved.title'),
          text: getTranslation('common.toast.course_saved.text'),
        },
      });
    } catch (error) {
      showToast({
        data: _getErrorText(error),
      });
    }

    setSaveLoading(false);
  };

  const handlePreviewClick = () => {
    switch (course.type) {
      case COURSE_TYPES.TRAINING: {
        navigate(`/trainings/${courseId}/preview`);

        break;
      }
      case COURSE_TYPES.ARCADE: {
        navigate(`/arcades/${courseId}/preview`);

        break;
      }
      case COURSE_TYPES.COURSE: {
        navigate(`/courses/${courseId}/preview`);

        break;
      }
    }
  };

  const _publishCourse = async (withLessons) => {
    try {
      await dispatch(
        updateCourseStatus({
          courseId,
          withLessons,
          status: STATUSES.PUBLISHED,
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_published.title'),
          text: getTranslation('common.toast.course_published.text'),
        },
      });
    } catch (error) {
      showToast({
        data: _getErrorText(error),
      });
    }
  };

  const _moveCourseToArchive = async () => {
    try {
      await dispatch(
        updateCourseStatus({
          courseId,
          withLessons: true,
          status: STATUSES.ARCHIVED,
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_archived.title'),
          text: getTranslation('common.toast.course_archived.text'),
        },
      });
    } catch (error) {
      showToast({
        data: _getErrorText(error),
      });
    }
  };

  const _moveCourseToDraft = async () => {
    try {
      await dispatch(
        updateCourseStatus({
          courseId,
          withLessons: true,
          status: STATUSES.DRAFT,
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_restored.title'),
          text: getTranslation('common.toast.course_restored.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }
  };

  const handleStatusContextItemClick = (
    contextItem,
    { values, setFieldValue },
  ) => {
    switch (contextItem.value) {
      case STATUSES.PUBLISHED: {
        if (course.type === 'TRAINING' && !_validateTraningLessons(values)) {
          showToast({
            data: {
              variant: 'warning',
              title: getTranslation(
                'common.toast.training_publis_warning.title',
              ),
              text: getTranslation('common.toast.training_publis_warning.text'),
            },
          });

          break;
        }

        _publishCourse(false);

        break;
      }
      case 'PUBLISH_COURSE_AND_ALL_LESSONS': {
        if (!_validateCourseLessons(values, setFieldValue)) {
          showToast({
            data: {
              variant: 'warning',
              title: getTranslation(
                'common.toast.publish_course_and_lessons.title',
              ),
              text: getTranslation(
                'common.toast.publish_course_and_lessons.text',
              ),
            },
          });

          break;
        }

        if (course.type === 'TRAINING' && !_validateTraningLessons(values)) {
          showToast({
            data: {
              variant: 'warning',
              title: getTranslation(
                'common.toast.training_publis_warning.title',
              ),
              text: getTranslation('common.toast.training_publis_warning.text'),
            },
          });

          break;
        }

        _publishCourse(true);

        break;
      }
      case STATUSES.DRAFT: {
        _moveCourseToDraft();

        break;
      }
      case STATUSES.ARCHIVED: {
        _moveCourseToArchive();

        break;
      }
    }
  };

  const handleMoreContextItemClick = (contextItem) => {
    switch (contextItem.key) {
      case 'marketing-settings': {
        openMarketingSettings();

        break;
      }
      case 'payment-settings': {
        openPaymentSettings();

        break;
      }
      case 'course-settings': {
        openCourseSettings();

        break;
      }
    }
  };

  return {
    loading,
    saveLoading,
    autoSaveLoading,
    readOnly,
    marketingSettingsOpen,
    paymentSettingsOpen,
    courseSettingsOpen,
    data: course,
    lastActionAt,
    closeMarketingSettings,
    closePaymentSettings,
    closeCourseSettings,
    onAutoSaveSubmit: handleAutoSaveSubmit,
    onSaveClick: handleSaveClick,
    onPreviewClick: handlePreviewClick,
    onMoreContextItemClick: handleMoreContextItemClick,
    onStatusContextItemClick: handleStatusContextItemClick,
  };
};
