import React from 'react';
import format from 'date-fns/format';

import { DEFAULT_DATE_FORMAT } from 'constants/common';

import { useModal } from 'hooks/useModal';

import DatePickerInput from './DatePickerInput';

const DatePickerInputContainer = ({ onChange, ...restProps }) => {
  const [datePickerOpen, openDatePicker, closeDatePicker] = useModal({
    defaultOpen: false,
  });

  const handleChange = (event) => {
    const value = event.target.value;

    if (onChange) {
      onChange(value);
    }
  };

  const handleDaySelect = (nextDate) => {
    if (onChange) {
      onChange(format(nextDate, DEFAULT_DATE_FORMAT));
    }

    closeDatePicker();
  };

  return (
    <DatePickerInput
      {...restProps}
      datePickerOpen={datePickerOpen}
      onChange={handleChange}
      onDaySelect={handleDaySelect}
    />
  );
};

export default React.memo(DatePickerInputContainer);
