import { formatLayers } from './formatLayers';

export const formatTasks = (tasks) =>
  tasks.map((task) => ({
    ...task,
    withTheory: task.theoryBlocks && !!task.theoryBlocks.length,
    withHints: task.hintsBlocks && !!task.hintsBlocks.length,
    withMaterials: task.materials && !!task.materials.length,
    completed: false,
    layers: formatLayers(task.layers),
    errors: [],
  }));
