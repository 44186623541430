import * as Yup from 'yup';

export const getValidationSchema = ({ isRootAdmin }) => {
  if (isRootAdmin) {
    return Yup.object().shape({
      email: Yup.string().ensure(),
      isTest: Yup.string().ensure(),
      isAdmin: Yup.string().ensure(),
    });
  }

  return Yup.object().shape({
    email: Yup.string().ensure(),
  });
};
