import React from 'react';
import { getIn, useFormikContext } from 'formik';

import MaterialsSection from './MaterialsSection';

const MaterialsSectionContainer = ({ namePrefix, ...restProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleMaterialChecked = (event, name, material) => {
    const checked = event.target.checked;

    setFieldValue(name, { ...material, check: checked });
  };

  const withMaterials = getIn(values, `${namePrefix}.withMaterials`);
  const materials = getIn(values, `${namePrefix}.materials`);

  return (
    <MaterialsSection
      {...restProps}
      withMaterials={withMaterials}
      readOnly={values.readOnly}
      materials={materials}
      namePrefix={namePrefix}
      onMaterialChecked={handleMaterialChecked}
    />
  );
};

export default React.memo(MaterialsSectionContainer);
