import React from 'react';
import { Hue, Saturation } from 'react-color/lib/components/common';
import cn from 'classnames';

import styles from './ColorPicker.styles.scss';

const HuePointer = () => <div className={styles.huePointer} />;

const SaturationPointer = () => <div className={styles.saturationPointer} />;

const ColorPicker = ({
  className,
  hsl,
  hsv,
  onSaturationChange,
  onHueChange,
}) => (
  <div className={cn(styles.picker, className)}>
    <div className={styles.saturation}>
      <Saturation
        hsl={hsl}
        hsv={hsv}
        pointer={SaturationPointer}
        onChange={onSaturationChange}
      />
    </div>
    <div className={styles.hue}>
      <Hue hsl={hsl} pointer={HuePointer} onChange={onHueChange} />
    </div>
  </div>
);

export default React.memo(ColorPicker);
