import React from 'react';
import cn from 'classnames';

import DefaultPreview from 'assets/img/course-preview.svg';

import styles from './TrainingPreview.styles.scss';

const TrainingPreview = ({ className, preview }) =>
  preview ? (
    <div className={cn(styles.preview, className)}>
      <img className={styles.img} src={preview} />
    </div>
  ) : (
    <DefaultPreview className={cn(styles.preview, className)} />
  );

export default React.memo(TrainingPreview);
