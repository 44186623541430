import { useState, useEffect } from 'react';

import { getFigmaFileImages } from './helpers/getFigmaFileImages';

export const useFigmaFileImages = (fileId) => {
  const [figmaFileImages, setFigmaFileImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const _getFileImages = async () => {
      try {
        const fileImages = await getFigmaFileImages(fileId);

        setFigmaFileImages(fileImages);
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getFileImages();
  }, []);

  return [loading, figmaFileImages];
};
