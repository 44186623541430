import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';

import { shuffle } from './TwoColumnsLessonPreviewPage.helpers';

import TwoColumnsLessonPreviewPage from './TwoColumnsLessonPreviewPage';

const TwoColumnsLessonPreviewPageContainer = () => {
  const lesson = useSelector(currentLessonSelector);

  const [draggingItem, setDraggingItem] = useState(null);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [variants, setVariants] = useState([]);
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  useEffect(() => {
    const nextVariants = shuffle([
      ...lesson.leftVariants.map((variant) => ({ ...variant, side: 'left' })),
      ...lesson.rightVariants.map((variant) => ({ ...variant, side: 'right' })),
    ]);

    setVariants(nextVariants);
  }, []);

  const handleCheckClick = () => {
    setSubmitted(true);
  };

  const resetLesson = () => {
    setLeftItems([]);
    setRightItems([]);
    setDraggingItem(null);
    setSubmitted(false);
  };

  const handleTryAgainClick = () => resetLesson();

  const handleRestartLessonClick = () => resetLesson();

  const handleItemDragStart = (event) => {
    const { active } = event;

    const data = active.data?.current;

    setDraggingItem({ ...data, id: active.id });
  };

  const handleItemDragEnd = (event) => {
    const { over, active } = event;

    const draggingSectionData = active.data?.current;

    if (over && over.id === 'leftColumn') {
      setLeftItems((prevItems) => [...prevItems, draggingSectionData]);

      return;
    }

    if (over && over.id === 'rightColumn') {
      setRightItems((prevItems) => [...prevItems, draggingSectionData]);

      return;
    }

    setDraggingItem(null);
  };

  const handleItemDragCancel = () => {
    setDraggingItem(null);
  };

  const allChecked = [...leftItems, ...rightItems].length === variants.length;
  const correct =
    allChecked &&
    leftItems.every((item) => item.side === 'left') &&
    rightItems.every((item) => item.side === 'right');

  return (
    <TwoColumnsLessonPreviewPage
      correct={correct}
      submitted={submitted}
      allChecked={allChecked}
      variants={variants}
      leftItems={leftItems}
      rightItems={rightItems}
      lesson={lesson}
      draggingItem={draggingItem}
      onItemDragStart={handleItemDragStart}
      onItemDragEnd={handleItemDragEnd}
      onItemDragCancel={handleItemDragCancel}
      onTryAgainClick={handleTryAgainClick}
      onRestartLessonClick={handleRestartLessonClick}
      onCheckClick={handleCheckClick}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
    />
  );
};

export default React.memo(TwoColumnsLessonPreviewPageContainer);
