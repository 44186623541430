import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { getPropertyIcon } from './ComponentRefProperties.helpers';

import styles from './ComponentRefProperties.styles.scss';
import { EmptyProperties } from '../EmptyProperties';

const ComponentRefProperties = ({ properties }) => {
  const propertyReferences = getLayerPropertyByKey({
    key: 'componentPropertyReferences',
    properties,
  });
  const parent = getLayerPropertyByKey({
    key: 'parent',
    properties,
  });

  if (
    !propertyReferences ||
    !propertyReferences.value ||
    propertyReferences.value.length === 0
  ) {
    return <EmptyProperties />;
  }

  if (
    parent.value &&
    (parent.value.type === 'COMPONENT' || parent.value.type === 'INSTANCE')
  ) {
    return (
      <div className={styles.root}>
        <Typography
          className={styles.title}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          References to properties:
        </Typography>
        <div className={styles.properties}>
          {propertyReferences.value.map((propertyRef, propertyRefIndex) => (
            <div className={styles.property} key={propertyRefIndex}>
              <Icon
                className={styles.icon}
                name={getPropertyIcon(propertyRef.type)}
              />
              <Typography
                className={styles.key}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                {propertyRef.key}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default React.memo(ComponentRefProperties);
