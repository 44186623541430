import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import styles from './AutoLayoutPaddingProperty.styles.scss';

const _renderPaddingContent = ({ value, iconName }) => (
  <>
    <Icon className={styles.icon} name={iconName} />
    <Typography
      className={styles.value}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {value}
    </Typography>
  </>
);

const AutoLayoutPaddingProperty = ({
  isInitialState,
  readOnly,
  designType,
  paddingLeftNamePrefix,
  paddingRightNamePrefix,
  paddingTopNamePrefix,
  paddingBottomNamePrefix,
  paddingLeftProperty,
  paddingRightProperty,
  paddingTopProperty,
  paddingBottomProperty,
  onChanged,
}) => {
  if (isInitialState || designType === 'PROTOTYPE') {
    return (
      <div className={styles.properties}>
        <div className={styles.propertyReadOnly}>
          <Tooltip
            text="Left padding"
            triggerClassName={styles.fieldTrigger}
            triggerElement={_renderPaddingContent({
              value: paddingLeftProperty.value,
              iconName: 'paddingLeft',
            })}
          />

          <Tooltip
            text="Top padding"
            triggerClassName={styles.fieldTrigger}
            triggerElement={_renderPaddingContent({
              value: paddingTopProperty.value,
              iconName: 'paddingTop',
            })}
          />

          <Tooltip
            text="Right padding"
            triggerClassName={styles.fieldTrigger}
            triggerElement={_renderPaddingContent({
              value: paddingRightProperty.value,
              iconName: 'paddingRight',
            })}
          />

          <Tooltip
            text="Bottom padding"
            triggerClassName={styles.fieldTrigger}
            triggerElement={_renderPaddingContent({
              value: paddingBottomProperty.value,
              iconName: 'paddingBottom',
            })}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.properties}>
      <Tooltip
        text="Left padding"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={paddingLeftNamePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property: { value: paddingLeftProperty.value },
              renderProperty: ({ value }) =>
                _renderPaddingContent({ value, iconName: 'paddingLeft' }),
              onChanged,
            }}
          />
        }
      />

      <Tooltip
        text="Top padding"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={paddingTopNamePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property: { value: paddingTopProperty.value },
              renderProperty: ({ value }) =>
                _renderPaddingContent({ value, iconName: 'paddingTop' }),
              onChanged,
            }}
          />
        }
      />

      <Tooltip
        text="Right padding"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={paddingRightNamePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property: { value: paddingRightProperty.value },
              renderProperty: ({ value }) =>
                _renderPaddingContent({ value, iconName: 'paddingRight' }),
              onChanged,
            }}
          />
        }
      />

      <Tooltip
        text="Bottom padding"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={paddingBottomNamePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property: { value: paddingBottomProperty.value },
              renderProperty: ({ value }) =>
                _renderPaddingContent({ value, iconName: 'paddingBottom' }),
              onChanged,
            }}
          />
        }
      />
    </div>
  );
};

export default React.memo(AutoLayoutPaddingProperty);
