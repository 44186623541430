import React from 'react';
import cn from 'classnames';

import CourseDefaultPreview from 'assets/img/course-preview.svg';

import styles from './CoursePreview.styles.scss';

const CoursePreview = ({ className, preview }) =>
  preview ? (
    <img className={cn(styles.preview, className)} src={preview} />
  ) : (
    <CourseDefaultPreview className={cn(styles.preview, className)} />
  );

export default React.memo(CoursePreview);
