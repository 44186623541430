import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import {
  EditableTextField,
  SwitchField,
  UploadField,
} from 'components/FormikFields';

import styles from './Answer.styles.scss';

const Answer = ({ readOnly, answersType, namePrefix, order }) => (
  <div className={cn(styles.answer, { [styles.readOnlyAnswer]: readOnly })}>
    <div className={styles.orderWrapper}>
      <Typography
        className={styles.order}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {order}
      </Typography>
    </div>
    <div className={styles.inner}>
      {answersType === 'IMAGE' ? (
        <FormikField
          name={`${namePrefix}.data`}
          withControl={false}
          component={UploadField}
          componentProps={{
            readOnly,
            className: styles.image,
            variant: 'quizImage',
          }}
        />
      ) : (
        <FormikField
          name={`${namePrefix}.data`}
          withControl={false}
          component={EditableTextField}
          componentProps={{
            readOnly,
            className: styles.text,
            variant: 'text',
            placeholder: getTranslation('quiz_page.answer.answer_placeholder'),
          }}
        />
      )}
      <FormikField
        name={`${namePrefix}.comment`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          multiline: true,
          className: styles.comment,
          variant: 'text',
          placeholder: getTranslation('quiz_page.answer.comment_placeholder'),
        }}
      />
    </div>
    <div className={styles.checkboxWrapper}>
      <Typography className={styles.caption} variant="body3" mode="compact">
        {getTranslation('quiz_page.answer.correct')}
      </Typography>
      <FormikField
        name={`${namePrefix}.correct`}
        withControl={false}
        component={SwitchField}
        componentProps={{ readOnly, type: 'checkbox' }}
      />
    </div>
  </div>
);

export default React.memo(Answer);
