export const getPropertyLabel = ({ type, value }) => {
  switch (type) {
    case 'clipsContent': {
      return 'Clips content:';
    }
    case 'strokesIncludedInLayout': {
      return 'Strokes:';
    }
    case 'itemReverseZIndex': {
      return 'Canvas stacking:';
    }
    case 'hangingPunctuation': {
      return 'Hanging punctuation:';
    }
    case 'hangingList': {
      return 'Hanging list:';
    }
    case 'isMask': {
      return 'Mask:';
    }
    case 'interactionPreserveScrollPosition': {
      return 'Preserve scroll position:';
    }
    case 'interactionTransitionMatchLayers': {
      return 'Animate matching layers:';
    }
    case 'visible': {
      return 'Visible:';
    }
    case 'locked': {
      return 'Locked:';
    }
    default: {
      return null;
    }
  }
};

export const getPropertyValue = ({ type, value }) => {
  switch (type) {
    case 'clipsContent':
    case 'hangingPunctuation':
    case 'hangingList':
    case 'isMask':
    case 'interactionPreserveScrollPosition':
    case 'interactionTransitionMatchLayers':
    case 'visible':
    case 'locked': {
      return value ? 'On' : 'Off';
    }
    case 'strokesIncludedInLayout': {
      if (value) {
        return 'Included';
      }

      return 'Excluded';
    }
    case 'itemReverseZIndex': {
      if (value) {
        return 'First on top';
      }

      return 'Last on top';
    }
    default: {
      return value;
    }
  }
};
