export const getUserStatusVariant = (status) => {
  switch (status) {
    case 'ACTIVE': {
      return 'success';
    }
    case 'BLOCKED': {
      return 'error';
    }
    case 'PENDING': {
      return 'info';
    }
    default: {
      return 'default';
    }
  }
};
