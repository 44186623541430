import { getTranslation } from 'helpers/getTranslation';

export const importedStudentsTypes = [
  {
    key: 'SUCCESS',
    iconName: 'published',
    iconColor: '#34CB73',
    title: getTranslation('components.import_admins.config.success'),
  },
  {
    key: 'DUPLICATE',
    iconName: 'warning',
    iconColor: '#FCAE2F',
    title: getTranslation('components.import_admins.config.duplicates'),
  },
  {
    key: 'ERROR',
    iconName: 'stop',
    iconColor: '#F53149',
    title: getTranslation('components.import_admins.config.errors'),
  },
];
