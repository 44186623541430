import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';
import { currentCourseSelector } from 'store/course/selectors';

import TheoryLessonPreviewPage from './TheoryLessonPreviewPage';
import { getLessonsCount } from './TheoryLessonPreviewPage.helpers';

const TheoryLessonPageContainer = (props) => {
  const lesson = useSelector(currentLessonSelector);
  const course = useSelector(currentCourseSelector);

  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  return (
    <TheoryLessonPreviewPage
      lesson={lesson}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
      lessonTitle={lesson.title}
      currentLesson={getLessonsCount(course, lesson).lessonIndex}
      lessonsCount={getLessonsCount(course, lesson).totalLessons}
    />
  );
};

export default React.memo(TheoryLessonPageContainer);
