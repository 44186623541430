import React, { useEffect, useState } from 'react';

import Rating from './Rating';

const RatingContainer = ({ readonly, value, ...restProps }) => {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const handleMouseLeave = () => {
    if (!readonly) {
      setDisplayValue(value);
    }
  };

  const handleStarMouseMove = (event, index) => {
    if (!readonly) {
      setDisplayValue(index + 1);
    }
  };

  return (
    <Rating
      {...restProps}
      readonly={readonly}
      value={displayValue}
      onMouseLeave={handleMouseLeave}
      onStarMouseMove={handleStarMouseMove}
    />
  );
};

export default React.memo(RatingContainer);
