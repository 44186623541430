import React from 'react';
import InputRange from 'react-input-range';
import cn from 'classnames';

import styles from './ValueBar.styles.scss';

const ValueBar = ({
  className,
  trackClassName,
  thumbClassName,
  value,
  onChange,
}) => (
  <div className={cn(styles.bar, className)}>
    <InputRange
      classNames={{
        inputRange: styles.inputRange,
        disabledInputRange: styles.inputRangeDisabled,
        track: cn(styles.track, thumbClassName),
        activeTrack: cn(styles.trackActive, trackClassName),
        sliderContainer: styles.sliderContainer,
        slider: styles.slider,
        minLabel: styles.minLabel,
        maxLabel: styles.maxLabel,
        labelContainer: styles.labelContainer,
        valueLabel: styles.valueLabel,
      }}
      step={0.1}
      maxValue={1}
      minValue={0}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default React.memo(ValueBar);
