import { getTranslation } from 'helpers/getTranslation';

export const getActivationType = (activationType) => {
  switch (activationType) {
    case 'DIRECT':
      return getTranslation('common.text.direct');
    case 'PROMOCODE':
      return getTranslation('common.text.promocode');
  }
};
