import React, { useState } from 'react';

import { perPageOptions } from './TablePagination.config';

import TablePagination from './TablePagination';

const TablePaginationContainer = ({
  currentPage,
  perPage,
  totalPages,
  onPerPageChange,
  ...restProps
}) => {
  const [currentPerPage, setCurrentPerPage] = useState(
    perPageOptions.find((option) => option.value === perPage),
  );

  const handlePerPageChange = (nextPerPage) => {
    setCurrentPerPage(nextPerPage);

    if (onPerPageChange) {
      onPerPageChange(nextPerPage.value);
    }
  };

  const nextPageDisabled = currentPage === totalPages;
  const prevPageDisabled = currentPage === 1;

  return (
    <TablePagination
      {...restProps}
      prevPageDisabled={prevPageDisabled}
      nextPageDisabled={nextPageDisabled}
      currentPage={currentPage}
      totalPages={totalPages}
      currentPerPage={currentPerPage}
      onPerPageChange={handlePerPageChange}
    />
  );
};

export default React.memo(TablePaginationContainer);
