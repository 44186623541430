import React from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import { getTranslation } from 'helpers/getTranslation';

import styles from './EditableText.styles.scss';

const VARIANTS = {
  heading1: styles.heading1,
  heading2: styles.heading2,
  heading3: styles.heading3,
  text: styles.text,
};

const EditableText = ({
  className,
  readOnly,
  multiline,
  editableRef,
  placeholder,
  variant,
  name,
  value,
  onKeyDown,
  onChange,
  onBlur,
}) => {
  const editableProps = {
    ref: editableRef,
    placeholder,
    name,
    value,
    onKeyDown,
    onChange,
    onBlur,
    readOnly,
  };

  if (multiline) {
    return (
      <TextareaAutosize
        className={cn(
          styles.editable,
          styles.editableMultiline,
          { [styles.editableReadOnly]: readOnly },
          VARIANTS[variant],
          className,
        )}
        {...editableProps}
      />
    );
  }

  return (
    <input
      className={cn(
        styles.editable,
        VARIANTS[variant],
        { [styles.editableReadOnly]: readOnly },
        className,
      )}
      {...editableProps}
    />
  );
};

EditableText.defaultProps = {
  multiline: false,
  variant: 'text',
  placeholder: getTranslation('common.text.untitled'),
};

export default React.memo(EditableText);
