import { getTranslation } from 'helpers/getTranslation';

export const getCoursesTableColumns = () => [
  {
    key: 'title',
    label: getTranslation('admin_page.course_cards.table_keys.title'),
  },
  {
    key: 'status',
    label: getTranslation('admin_page.course_cards.table_keys.status'),
  },
  {
    key: 'createdAt',
    label: getTranslation('admin_page.course_cards.table_keys.createdAt'),
  },
  {
    key: 'lastActionAt',
    label: getTranslation('admin_page.course_cards.table_keys.updatedAt'),
  },
];
