import React from 'react';

import { ADD_COURSE_CONTEXT_ITEMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { getTrainings } from 'store/trainings/actions';

import { Loading } from 'components/shared/Loading';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { MenuLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { ListToolbar } from 'components/Toolbar';
import { TrainingsTable } from 'components/TrainingsTable';
import { CreateButton } from 'components/CreateButton';

import styles from './TrainingsPage.styles.scss';

const TrainingsPage = ({
  loading,
  isRootAdmin,
  trainings,
  sortParams,
  columns,
  onSearchChange,
  onSortChange,
  onContextItemClick,
  onTrainingRowClick,
  onCreateTrainingClick,
  onTrainingRemoveClick,
  onTrainingEditClick,
}) => {
  return (
    <MenuLayout>
      <ListToolbar
        title={getTranslation('trainings_page.title')}
        actionComponent={
          isRootAdmin ? (
            <Button
              size="md"
              variant="link"
              startIcon={<Icon name="plusRound" />}
              onClick={onCreateTrainingClick}
            >
              {getTranslation('trainings_page.create_training_button')}
            </Button>
          ) : (
            <CreateButton
              buttonText={getTranslation(
                'trainings_page.create_training_button',
              )}
              contextItems={ADD_COURSE_CONTEXT_ITEMS}
              onContextItemClick={onContextItemClick}
            />
          )
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {trainings.length ? (
            <div className={styles.inner}>
              <TrainingsTable
                params={sortParams}
                data={trainings}
                columns={columns}
                dataAction={getTrainings}
                onSortChange={onSortChange}
                onSearchChange={onSearchChange}
                onRowClick={onTrainingRowClick}
                onRemoveClick={onTrainingRemoveClick}
                onEditClick={onTrainingEditClick}
              />
            </div>
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="trainings"
              title={getTranslation('trainings_page.empty_state_title')}
              text={getTranslation('trainings_page.empty_state_text')}
            />
          )}
        </>
      )}
    </MenuLayout>
  );
};

export default React.memo(TrainingsPage);
