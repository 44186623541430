import React, { useState, useEffect } from 'react';

import { getInitialTableData } from './InfoByStudents.helpers';

import { useModal } from 'hooks/useModal';

import { StudentInfo } from '../StudentInfo';

import InfoByStudents from './InfoByStudents';

const InfoByStudentsContainer = ({ courseStats, props }) => {
  const [data, setData] = useState(getInitialTableData(courseStats));

  const [studentInfoOpen, openStudentInfo, closeStudentInfo, studentData] =
    useModal({
      defaultOpen: false,
    });

  useEffect(() => {
    setData(getInitialTableData(courseStats));
  }, [courseStats]);

  const handleStudentRowClick = (student) =>
    openStudentInfo({ studentId: student.id, courseId: courseStats.courseId });

  return (
    <>
      <InfoByStudents
        {...props}
        data={data}
        onStudentRowClick={handleStudentRowClick}
      />
      <StudentInfo
        {...studentData}
        open={studentInfoOpen}
        onClose={closeStudentInfo}
      />
    </>
  );
};

export default React.memo(InfoByStudentsContainer);
