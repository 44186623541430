import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import UploadPreview from './UploadPreview/UploadPreview';
import UploadMaterials from './UploadMaterials/UploadMaterials';
import UploadImageBlock from './UploadImageBlock/UploadImageBlock';
import UploadQuizImage from './UploadQuizImage/UploadQuizImage';
import UploadVideoBlock from './UploadVideoBlock/UploadVideoBlock';
import UploadExcelFile from './UploadExcelFile/UploadExcelFile';
import UploadAvatar from './UploadAvatar/UploadAvatar';

const VARIANTS = {
  avatar: UploadAvatar,
  preview: UploadPreview,
  imageBlock: UploadImageBlock,
  videoBlock: UploadVideoBlock,
  materials: UploadMaterials,
  quizImage: UploadQuizImage,
  excelFile: UploadExcelFile,
};

const Upload = ({ variant, ...restProps }) => {
  const UploadComponent = VARIANTS[variant];

  return <UploadComponent {...restProps} />;
};

Upload.defaultProps = {
  variant: 'preview',
  text: getTranslation('components.upload.default_text'),
};

export default React.memo(Upload);
