import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './ProTag.styles.scss';

const ProTag = ({ className }) => (
  <div className={cn(styles.tag, className)}>
    <Typography variant="body3" weight="semiBold" className={styles.text}>
      {getTranslation('common.text.pro')}
    </Typography>
  </div>
);

export default React.memo(ProTag);
