import axios from 'axios';

export const createTheoryLesson = (data) =>
  axios.post('/api/v1/theory-lessons', data);

export const updateTheoryLessonById = ({ lessonId, data }) =>
  axios.put(`/api/v1/theory-lessons/${lessonId}`, data);

export const removeTheoryLessonById = (lessonId) =>
  axios.delete(`/api/v1/theory-lessons/${lessonId}`);
