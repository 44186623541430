export const prepareModule = (module) => {
  const lessons = module.lessons.map((lesson) => ({
    ...lesson,
    available: true,
    completed: false,
  }));

  return {
    ...module,
    available: true,
    completed: false,
    progress: 0,
    lessons,
  };
};
