import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import cn from 'classnames';
import format from 'date-fns/format';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './StudentsCard.styles.scss';
import { enUS, ru } from 'date-fns/locale';

const CustomPoint = (props) => {
  const { currentSlice } = props;

  const currentPoint = currentSlice?.points[0];

  if (currentPoint) {
    return (
      <g style={{ pointerEvents: 'none' }}>
        <circle
          fill="#85E0AB"
          r={5}
          strokeWidth={2}
          stroke="#fff"
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
};

const Tooltip = ({ locale, slice }) => {
  const currentSlice = slice?.points[0];

  const currentValue = currentSlice?.data.y;
  const currentDate = format(currentSlice?.data.x, 'MMM dd, yyyy', {
    locale: locale === 'ru' ? ru : enUS,
  });

  return (
    <div className={styles.tooltip}>
      <Typography
        className={styles.tooltipText}
        variant="caption"
        mode="compact"
      >
        {getTranslation('dashboard_page.student_card.tooltip', {
          currentDate,
          currentValue,
        })}
      </Typography>
    </div>
  );
};

const StudentsCard = ({ locale, className, data }) => (
  <div className={cn(styles.card, className)}>
    <div className={styles.header}>
      <Typography
        className={styles.title}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('dashboard_page.student_card.title')}
      </Typography>
    </div>
    <div className={styles.inner}>
      <ResponsiveLine
        colors={{ datum: 'color' }}
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 40 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: false,
          // min: 0,
          // max: 500,
        }}
        axisLeft={{
          orient: 'left',
          tickPadding: 5,
          tickSize: 0,
        }}
        axisBottom={{
          orient: 'bottom',
          format: (value) =>
            format(value, 'MMM dd', {
              locale: locale === 'ru' ? ru : enUS,
            }),

          tickValues: 'every month',
          tickSize: 0,
        }}
        theme={{
          crosshair: {
            line: {
              stroke: '#DFE9F9',
              strokeWidth: 2,
              strokeDasharray: 0,
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 10,
                lineHeight: 12,
                fontFamily: 'Inter',
                fontWeight: 500,
                fill: '#5C738C',
              },
            },
          },
        }}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        lineWidth={4}
        enableSlices="x"
        crosshairType="top-left"
        useMesh
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'legends',
          CustomPoint,
        ]}
        // tooltip={Tooltip}
        sliceTooltip={(props) => Tooltip({ ...props, locale })}
      />
    </div>
  </div>
);

export default React.memo(StudentsCard);
