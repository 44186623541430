import React from 'react';
import { useNavigation } from 'react-day-picker';

import Caption from './Caption';
import { addYears } from 'date-fns';

const CaptionContainer = (props) => {
  const { nextMonth, previousMonth, currentMonth, goToMonth, goToDate } =
    useNavigation();

  const handleNextMonthClick = () => {
    if (nextMonth) {
      goToMonth(nextMonth);
    }
  };

  const handlePrevMonthClick = () => {
    if (previousMonth) {
      goToMonth(previousMonth);
    }
  };

  const handleNextYearClick = () => {
    goToDate(addYears(currentMonth, 1));
  };

  const handlePrevYearClick = () => {
    goToDate(addYears(currentMonth, -1));
  };

  return (
    <Caption
      {...props}
      onPrevMonthClick={handlePrevMonthClick}
      onNextMonthClick={handleNextMonthClick}
      onPrevYearClick={handlePrevYearClick}
      onNextYearClick={handleNextYearClick}
    />
  );
};

export default React.memo(CaptionContainer);
