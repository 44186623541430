import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import {
  UploadField,
  SwitchField,
  EditableTextField,
} from 'components/FormikFields';

import styles from './DifferenceItem.styles.scss';

const DifferenceItem = ({ readOnly, namePrefix }) => (
  <div className={styles.item}>
    <FormikField
      name={`${namePrefix}.url`}
      withControl={false}
      component={UploadField}
      componentProps={{
        readOnly,
        className: styles.image,
        variant: 'quizImage',
      }}
    />
    <div className={styles.correct}>
      <Typography className={styles.correctText} variant="body3" mode="compact">
        {getTranslation('difference_lesson_page.difference_item.correct')}
      </Typography>
      <FormikField
        name={`${namePrefix}.correct`}
        withControl={false}
        component={SwitchField}
        componentProps={{ readOnly, type: 'checkbox' }}
      />
    </div>
    <FormikField
      name={`${namePrefix}.text`}
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        className: styles.text,
        multiline: true,
        variant: 'text',
        placeholder: getTranslation(
          'difference_lesson_page.difference_item.placeholder',
        ),
      }}
    />
  </div>
);

export default React.memo(DifferenceItem);
