import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import LogoIcon from 'assets/img/logo-icon.svg';
import Logo from 'assets/img/logo.svg';

import { Icon } from 'components/shared/Icon';

import { UserMenu } from './components/UserMenu';
import { Menu } from './components/Menu';

import styles from './Sidebar.styles.scss';

const Sidebar = ({
  className,
  collapsed,
  isAuthenticated,
  onCollapseClick,
}) => (
  <div
    className={cn(
      styles.sidebar,
      { [styles.sidebarCollapsed]: collapsed },
      className,
    )}
  >
    <div className={styles.collapse} onClick={onCollapseClick}>
      <Icon
        className={styles.collapseIcon}
        name={collapsed ? 'chevronRight' : 'chevronLeft'}
      />
    </div>
    <div className={styles.inner}>
      <NavLink
        className={cn(styles.logo, { [styles.logoCollapsed]: collapsed })}
        to={isAuthenticated ? '/dashboard' : '/'}
      >
        {collapsed ? (
          <LogoIcon className={styles.logoImg} />
        ) : (
          <Logo className={styles.logoImg} />
        )}
      </NavLink>
      <Menu collapsed={collapsed} />
    </div>

    <UserMenu collapsed={collapsed} />
  </div>
);

export default React.memo(Sidebar);
