import React from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';

import { getTranslation } from 'helpers/getTranslation';

import { useModal } from 'hooks/useModal';
import { useChangeOrder } from 'hooks/useChangeOrder';

import { DefaultModal } from 'components/DefaultModal';

import Questions from './Questions';

const QuestionsContainer = () => {
  const { values, setFieldValue } = useFormikContext();

  const [removeModalOpen, openRemoveModal, closeRemoveModal, removeModalData] =
    useModal({
      defaultOpen: false,
    });

  const _handleModuleDragEnd = () => {};

  const {
    sensors,
    activeDragItem,
    onItemDragStart,
    onItemDragEnd,
    onItemDragOver,
  } = useChangeOrder({
    withOrder: false,
    items: values.questions,
    namePrefix: 'questions',
    onItemDragEnd: _handleModuleDragEnd,
  });

  const handleAddQuestionClick = () => {
    setFieldValue('questions', [
      ...values.questions,
      {
        id: uuid(),
        blocks: [{ id: uuid(), type: 'text', data: '' }],
        answersType: '',
        answers: [{ id: uuid(), data: '' }],
      },
    ]);
  };

  const handleRemoveQuestionClick = (questionId) => {
    openRemoveModal({
      data: questionId,
      title: getTranslation('quiz_page.questions.remove_modal.title'),
      text: getTranslation('quiz_page.questions.remove_modal.text'),
    });
  };

  const handleConfirmRemoveClick = (questionId) => {
    setFieldValue(
      'questions',
      values.questions.filter((question) => question.id !== questionId),
    );

    closeRemoveModal();
  };

  const activeDragQuestionIndex = activeDragItem
    ? values.questions.findIndex(
        (question) => question.id === activeDragItem.id,
      )
    : -1;

  return (
    <>
      <Questions
        readOnly={values.readOnly}
        questions={values.questions}
        sensors={sensors}
        activeDragQuestion={activeDragItem}
        activeDragQuestionIndex={activeDragQuestionIndex}
        onQuestionDragStart={onItemDragStart}
        onQuestionDragEnd={onItemDragEnd}
        onQuestionDragOver={onItemDragOver}
        onAddQuestionClick={handleAddQuestionClick}
        onRemoveQuestionClick={handleRemoveQuestionClick}
      />
      <DefaultModal
        {...removeModalData}
        open={removeModalOpen}
        confirmText={getTranslation(
          'quiz_page.questions.remove_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveModal}
        onClose={closeRemoveModal}
      />
    </>
  );
};

export default React.memo(QuestionsContainer);
