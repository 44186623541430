import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { currentLessonSelector } from 'store/lesson/selectors';
import { currentCourseSelector } from 'store/course/selectors';

import { getLessonPreviewData } from './LessonPrevieLayout.helpers';

import LessonPreviewLayout from './LessonPreviewLayout';

const LessonPreviewLayoutContainer = (props) => {
  const { courseId, lessonId } = useParams();

  const lesson = useSelector(currentLessonSelector);
  const course = useSelector(currentCourseSelector);

  const { lessonTitle, currentLesson, lessonsCount } = getLessonPreviewData(
    lesson,
    course,
  );

  const navigate = useNavigate();

  const handleExitPreviewClick = () => {
    navigate(`/courses/${courseId}/lessons/${lessonId}`);
  };

  return (
    <LessonPreviewLayout
      {...props}
      lessonTitle={lessonTitle}
      currentLesson={currentLesson}
      lessonsCount={lessonsCount}
      onExitPreviewClick={handleExitPreviewClick}
    />
  );
};

export default React.memo(LessonPreviewLayoutContainer);
