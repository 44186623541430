import axios from 'axios';

import { TOKEN_KEY, FIGMA_TOKEN_KEY } from 'constants/token';

const _interceptResponseSuccess = (response) => response.data;

const _interceptResponseError = (error) => {
  if (!error.response) {
    return Promise.reject({ message: 'Server Error' });
  }

  return Promise.reject(error.response.data);
};

const _interceptRequest = (request) => {
  let token = null;

  if (request.api && request.api === 'figma') {
    token = localStorage.getItem(FIGMA_TOKEN_KEY);
  } else {
    token = localStorage.getItem(TOKEN_KEY);
  }

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

export const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(_interceptRequest);

  axios.interceptors.response.use(
    _interceptResponseSuccess,
    _interceptResponseError,
  );
};
