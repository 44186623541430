import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';

import styles from './StudentCard.styles.scss';

const StudentCard = ({
  avatar,
  name,
  email,
  onCopyEmailClick,
  onEditClick,
}) => (
  <div className={styles.card}>
    <div className={styles.inner}>
      <Avatar url={avatar} text={name || email} />
      <div className={styles.main}>
        <div className={styles.info}>
          <Typography
            className={styles.name}
            variant="body1"
            weight="medium"
            mode="compact"
          >
            {name || '-'}
          </Typography>
        </div>

        <div className={styles.emailInfo}>
          <Typography className={styles.email} variant="body3" mode="compact">
            {email}
          </Typography>
          <Icon
            className={styles.copyIcon}
            name="copy"
            onClick={onCopyEmailClick}
          />
        </div>
      </div>
    </div>

    <div className={styles.actions}>
      <Button
        className={styles.action}
        startIcon={<Icon name="edit" />}
        size="md"
        variant="outlined"
        onClick={onEditClick}
      >
        {getTranslation('common.button.edit')}
      </Button>
    </div>
  </div>
);

export default React.memo(StudentCard);
