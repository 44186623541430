import { AVATAR_COLORS, AVATAR_OPACITY } from './Avatar.constants';

/* eslint-disable no-bitwise */
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const generateAvatarColor = (string, withOpacity) => {
  let hash = 0;

  if (!string) {
    return hash;
  }

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  hash =
    ((hash % AVATAR_COLORS.length) + AVATAR_COLORS.length) %
    AVATAR_COLORS.length;

  if (withOpacity) {
    return hexToRGB(AVATAR_COLORS[hash], AVATAR_OPACITY);
  }

  return AVATAR_COLORS[hash];
};

export const getInitials = (value) => {
  return (value || '')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('');
};
