import axios from 'axios';
import qs from 'qs';

export const getStudents = (params) =>
  axios.get(`/api/v1/students?${qs.stringify(params)}`);

export const createStudent = (data) => axios.post('/api/v1/students', data);

export const getStudentById = (studentId) =>
  axios.get(`/api/v1/students/${studentId}`);

export const updateStudentById = ({ studentId, data }) =>
  axios.put(`/api/v1/students/${studentId}`, data);

export const getCoursesByStudentId = (studentId) =>
  axios.get(`/api/v1/students/${studentId}/courses`);

export const grantStudentCoursesAccess = (studentId, data) =>
  axios.post(`/api/v1/students/${studentId}/courses/access`, data);

export const importStudents = ({ data, config }) =>
  axios.post('/api/v1/students/import', data, {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const finishImportStudents = (data) =>
  axios.post('/api/v1/students/import/finish', data);

export const removeStudentById = (studentId) =>
  axios.delete(`/api/v1/students/${studentId}`);

export const removeStudentCourses = (studentId) =>
  axios.delete(`/api/v1/students/${studentId}/remove-courses`);

export const removeStudentReviews = (studentId) =>
  axios.delete(`/api/v1/students/${studentId}/remove-reviews`);

export const removeStudentRatings = (studentId) =>
  axios.delete(`/api/v1/students/${studentId}/remove-ratings`);

export const removeStudentReports = (studentId) =>
  axios.delete(`/api/v1/students/${studentId}/remove-reports`);

export const exportStudents = (data) =>
  axios.post('/api/v1/students/export', data, {
    responseType: 'blob',
  });
