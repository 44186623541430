import React from 'react';
import { getIn, useFormikContext } from 'formik';

import {
  getLayerPath,
  getPropertiesByCategory,
} from './CategorySection.helpers';

import CategorySection from './CategorySection';

const CategorySectionContainer = ({
  categoryProperties,
  properties,
  categoryKey,
  namePrefix,
  taskIndex,
  ...restProps
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleCategoryCheckChanged = (_, checked) => {
    const propertiesIndexes = properties.reduce(
      (indexes, property, propertyIndex) => {
        if (categoryProperties.includes(property.key)) {
          indexes.push(propertyIndex);
        }

        return indexes;
      },
      [],
    );

    for (const propertyIndex of propertiesIndexes) {
      setFieldValue(
        `${namePrefix}.properties[${propertyIndex}].check`,
        checked,
      );

      _changeSamePropertyInOtherTasksLayers({
        checked,
        layerName: _getLayerName(namePrefix),
        layerParent: _getLayerParent(namePrefix),
        propertyCheckPrefix: `properties[${propertyIndex}].check`,
      });
    }
  };

  const _changeDependPropertiesCheckIfNeeded = ({
    checked,
    propertiesByCategory,
    propertyByCategory,
  }) => {
    if (propertyByCategory.key === 'counterAxisAlignItems') {
      const primaryAxisAlignItemsPropertyIndex = properties.findIndex(
        (property) => property.key === 'primaryAxisAlignItems',
      );

      setFieldValue(
        `${namePrefix}.properties[${primaryAxisAlignItemsPropertyIndex}].check`,
        checked,
      );
    }

    if (propertyByCategory.key === 'layoutMode') {
      const layoutWrapPropertyIndex = properties.findIndex(
        (property) => property.key === 'layoutWrap',
      );

      setFieldValue(
        `${namePrefix}.properties[${layoutWrapPropertyIndex}].check`,
        checked,
      );
    }
  };

  const _changeSamePropertyInOtherTasksLayers = ({
    checked,
    layerName,
    layerParent,
    propertyCheckPrefix,
  }) => {
    for (let i = taskIndex + 1; i < values.tasks.length; i++) {
      const task = values.tasks[i];

      const layerPrefix = getLayerPath({
        layers: task.layers,
        layerName,
        layerParent,
        layerPath: 'layers',
      });

      setFieldValue(
        `tasks[${i}].${layerPrefix}.${propertyCheckPrefix}`,
        checked,
      );
    }
  };

  const _getLayerName = (layerPrefix) => {
    const layer = getIn(values, layerPrefix);

    if (!layer) {
      return null;
    }

    return layer.name;
  };

  const _getLayerParent = (layerPrefix) => {
    const layer = getIn(values, layerPrefix);

    if (!layer) {
      return null;
    }

    const layerParent = layer.properties.find(
      (property) => property.key === 'parent',
    );

    if (!layerParent || !layerParent.value) {
      return null;
    }

    return layerParent.value;
  };

  const handlePropertyChanged = (name, checked) => {
    const propertyPrefix = name.split('.').slice(0, -1).join('.');
    const propertyByCategory = getIn(values, propertyPrefix);
    const propertiesByCategory = getPropertiesByCategory({
      categoryKey,
      properties,
      categoryProperties,
    }).filter((property) => property.key !== propertyByCategory.key);

    _changeDependPropertiesCheckIfNeeded({
      checked,
      propertiesByCategory,
      propertyByCategory,
    });

    const layerPrefix = name.split('.').slice(0, -2).join('.');

    _changeSamePropertyInOtherTasksLayers({
      checked,
      layerName: _getLayerName(layerPrefix),
      layerParent: _getLayerParent(layerPrefix),
      propertyCheckPrefix: name.split('.').slice(-2).join('.'),
    });
  };

  return (
    <CategorySection
      {...restProps}
      readOnly={values.readOnly}
      properties={properties}
      categoryProperties={categoryProperties}
      categoryKey={categoryKey}
      namePrefix={namePrefix}
      designType={values.designType}
      onCategoryCheckChanged={handleCategoryCheckChanged}
      onPropertyChanged={handlePropertyChanged}
    />
  );
};

export default React.memo(CategorySectionContainer);
