import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';

import styles from './UploadAvatar.styles.scss';

const UploadAvatar = ({
  className,
  loading,
  haveError,
  value,
  text,
  percentage,
  getInputProps,
  getRootProps,
  onBrowseClick,
  onRemoveClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {value && !loading && (
      <div className={styles.actionOverlay} onClick={onRemoveClick}>
        <Icon className={styles.action} name="trash" />
      </div>
    )}
    {value ? (
      <img src={value} className={styles.preview} />
    ) : (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <LinearProgressBar
            className={styles.bar}
            variant="upload"
            progress={percentage}
          />
        ) : (
          <div className={styles.inner} onClick={onBrowseClick}>
            <Icon name="upload" className={styles.icon} />
          </div>
        )}
      </>
    )}
  </div>
);

export default React.memo(UploadAvatar);
