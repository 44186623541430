import { PREMIUM_TYPE } from 'constants/common';

import { premiumTypesOptions } from './PaymentSettings.config';

export const convertDataToForm = (data) => ({
  premiumPrice: data?.premiumPrice || '',
  premiumOldPrice: data?.premiumOldPrice || '',
  premiumType: premiumTypesOptions.find(
    (paymentTypeOption) => paymentTypeOption.value === data?.premiumType,
  ) || { label: 'Subscription', value: PREMIUM_TYPE.SUBSCRIPTION },
  premiumPriceId: data?.premiumPriceId || '',
});

export const convertDataToApi = (data) => ({
  premiumPrice: Number(data.premiumPrice) || null,
  premiumOldPrice: Number(data.premiumOldPrice) || null,
  premiumType: data.premiumType.value,
  premiumPriceId: data.premiumPriceId || '',
});
