import React from 'react';

import { Loading } from 'components/shared/Loading';

import styles from './SessionProvider.styles.scss';

const SessionProvider = ({ loading, children }) =>
  loading ? <Loading className={styles.loading} /> : children;

export default React.memo(SessionProvider);
