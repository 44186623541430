import { getTranslation } from 'helpers/getTranslation';

export const getModuleTitle = ({ moduleId, modules }) => {
  const { title } = modules.find((module) => module.id === moduleId);

  return getTranslation('course_page.module_settings.helpers.delete_module', {
    title: title || getTranslation('common.text.untitled'),
  });
};

export const getLessonTitle = ({ lesson }) => {
  return getTranslation('course_page.module_settings.helpers.delete_lesson', {
    title: lesson.title || getTranslation('common.text.untitled'),
  });
};
