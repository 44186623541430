import { getTranslation } from 'helpers/getTranslation';

export const tabs = [
  {
    key: 'activationCodes',
    value: getTranslation('school_page.config.activation_codes'),
  },
  {
    key: 'students',
    value: getTranslation('school_page.config.students'),
  },
  {
    key: 'courses',
    value: getTranslation('school_page.config.courses'),
  },
];
