import { getTranslation } from 'helpers/getTranslation';

export const CODES_TABLE_COLUMNS = () => [
  {
    key: 'code',
    label: getTranslation('school_page.activation_codes.table_keys.code'),
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('school_page.activation_codes.table_keys.status'),
    minWidth: 100,
    maxWidth: 100,
    sortable: true,
  },
  {
    key: 'student',
    label: getTranslation('school_page.activation_codes.table_keys.student'),
    minWidth: 200,
    maxWidth: 300,
    sortable: false,
  },
  {
    key: 'duration',
    label: getTranslation('school_page.activation_codes.table_keys.duration'),
    sortable: true,
  },
  {
    key: 'startDate',
    label: getTranslation('school_page.activation_codes.table_keys.start_date'),
    sortable: true,
  },
  {
    key: 'endDate',
    label: getTranslation('school_page.activation_codes.table_keys.end_date'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('school_page.activation_codes.table_keys.created_at'),
    sortable: true,
  },
];
