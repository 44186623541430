import React from 'react';
import pluralize from 'pluralize';

import { formatDate } from 'helpers/formatDate';

import { Icon } from 'components/shared/Icon';
import { Table } from 'components/shared/Table';
import { Typography } from 'components/shared/Typography';
import { Avatar } from 'components/shared/Avatar';

import { StatusTag } from 'components/StatusTag';
import { TablePagination } from 'components/TablePagination';
import { Tooltip } from 'components/Tooltip';

import {
  getCodeStatusText,
  getCodeStatusVariant,
} from './SchoolCodesTable.helpers';

import styles from './SchoolCodesTable.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  { onCopyCodeClick, onStudentClick },
  locale,
) => {
  switch (key) {
    case 'startDate':
    case 'endDate': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key] ? formatDate(item[key], 'dd MMM, yyyy', locale) : '-'}
        </DefaultCell>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <StatusTag variant={getCodeStatusVariant(item[key])}>
            {getCodeStatusText(item[key])}
          </StatusTag>
        </div>
      );
    }
    case 'duration': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key] ? pluralize('months', item[key], true) : '-'}
        </DefaultCell>
      );
    }
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key] ? formatDate(item[key], 'dd MMM, yyyy', locale) : '-'}
        </DefaultCell>
      );
    }
    case 'code': {
      return (
        <div
          className={styles.codeCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Typography variant="body3" mode="compact">
            {item[key] || '-'}
          </Typography>
          <Icon
            className={styles.copyIcon}
            name="copy"
            onClick={() => onCopyCodeClick(item[key])}
          />
        </div>
      );
    }
    case 'student': {
      const { student } = item;

      if (!student) {
        return (
          <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
            -
          </DefaultCell>
        );
      }

      return (
        <div
          className={styles.studentCell}
          key={key}
          style={{ minWidth, maxWidth }}
          onClick={() => onStudentClick(student.id)}
        >
          <Tooltip
            key={student.id}
            text={student.name || student.email}
            triggerClassName={styles.studentTrigger}
            triggerElement={
              <Avatar
                className={styles.studentAvatar}
                size="sm"
                text={student.name || student.email}
              />
            }
          />
          <div className={styles.studentInner}>
            <Typography
              className={styles.studentName}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {student.name}
            </Typography>
            <Typography
              className={styles.studentEmail}
              variant="body3"
              mode="compact"
            >
              {student.email}
            </Typography>
          </div>
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const SchoolCodesTable = ({
  locale,
  columns,
  data,
  params,
  currentPage,
  perPage,
  totalPages,
  onSortChange,
  onPerPageChange,
  onNextPageClick,
  onPrevPageClick,
  onCopyCodeClick,
  onStudentClick,
}) => (
  <>
    <Table
      columns={columns}
      actions={[]}
      data={data}
      params={params}
      cellRenderer={(props, DefaultCell) =>
        cellRenderer(
          props,
          DefaultCell,
          { onCopyCodeClick, onStudentClick },
          locale,
        )
      }
      onSortChange={onSortChange}
    />
    <TablePagination
      currentPage={currentPage}
      perPage={perPage}
      totalPages={totalPages}
      onSortChange={onSortChange}
      onPerPageChange={onPerPageChange}
      onNextPageClick={onNextPageClick}
      onPrevPageClick={onPrevPageClick}
    />
  </>
);

export default React.memo(SchoolCodesTable);
