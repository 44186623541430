import axios from 'axios';

export const createConnectionQuiz = (data) =>
  axios.post('/api/v1/connection-quizzes', data);

export const updateConnectionQuizById = ({ lessonId, data }) =>
  axios.put(`/api/v1/connection-quizzes/${lessonId}`, data);

export const removeConnectionQuizById = (quizId) =>
  axios.delete(`/api/v1/connection-quizzes/${quizId}`);
