import React from 'react';
import { useSelector } from 'react-redux';

import { COURSE_TYPES } from 'constants/common';

import * as api from 'api/methods';

import { getArcades, removeArcadeById } from 'store/arcades/actions';
import { arcadesSelector } from 'store/arcades/selectors';

import { getTranslation } from 'helpers/getTranslation';

import { useCourses } from 'hooks/useCourses';

import { DefaultModal } from 'components/DefaultModal';
import { RemoveWithStepsModal } from 'components/RemoveWithStepsModal';

import { useTranslation } from 'react-i18next';

import { CreateArcade } from './components/CreateArcade';

import ArcadesPage from './ArcadesPage';

const ArcadesPageContainer = () => {
  const arcades = useSelector(arcadesSelector);

  const { i18n } = useTranslation();

  const {
    loading,
    isRootAdmin,
    createOpen,
    removeOpen,
    removeConfirmOpen,
    sortParams,
    columns,
    removeConfirmData,
    removeData,
    closeCreate,
    closeRemoveConfirm,
    closeRemove,
    onSearchChange,
    onSortChange,
    onContextItemClick,
    onRowClick,
    onEditClick,
    onRemoveClick,
    onConfirmRemoveClick,
    onCreateClick,
  } = useCourses({
    getItems: getArcades,
    removeItemById: removeArcadeById,
    createItem: api.createArcade,
    itemsPerPage: arcades.perPage,
    type: COURSE_TYPES.ARCADE,
    pathPrefix: '/arcades',
  });

  return (
    <>
      <ArcadesPage
        locale={i18n.language}
        loading={loading}
        isRootAdmin={isRootAdmin}
        arcades={arcades.data}
        sortParams={sortParams}
        columns={columns}
        onSearchChange={onSearchChange}
        onSortChange={onSortChange}
        onContextItemClick={onContextItemClick}
        onArcadeRowClick={onRowClick}
        onArcadeEditClick={onEditClick}
        onArcadeRemoveClick={onRemoveClick}
        onCreateArcadeClick={onCreateClick}
      />
      <CreateArcade open={createOpen} onClose={closeCreate} />
      <DefaultModal
        {...removeConfirmData}
        open={removeConfirmOpen}
        confirmText={getTranslation('arcades_page.confirm_text')}
        onConfirmClick={onConfirmRemoveClick}
        onCancelClick={closeRemoveConfirm}
        onClose={closeRemoveConfirm}
      />
      <RemoveWithStepsModal
        {...removeData}
        open={removeOpen}
        variant="COURSE"
        title={getTranslation('arcades_page.remove_title')}
        onClose={closeRemove}
      />
    </>
  );
};

export default React.memo(ArcadesPageContainer);
