import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import styles from './TasksSection.styles.scss';

const TasksSection = ({
  withError,
  tasks,
  currentTask,
  designType,
  onTaskClick,
  onInitialStateClick,
}) => (
  <div className={styles.section}>
    <div
      className={cn(styles.initialState, {
        [styles.initialStateActive]: currentTask === 'INITIAL',
      })}
      onClick={onInitialStateClick}
    >
      <Typography
        className={styles.initialStateText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('figma_lesson_page.tasks_section.initial_state')}
      </Typography>
    </div>

    <Typography
      className={styles.title}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('figma_lesson_page.tasks_section.tasks')} ({tasks.length})
    </Typography>
    <div className={styles.tasks}>
      {tasks.map((task, taskIndex) => (
        <div
          className={cn(styles.task, {
            [styles.taskCurrent]: taskIndex === currentTask,
          })}
          key={task.id}
          onClick={() => onTaskClick(taskIndex)}
        >
          <div className={styles.taskInner}>
            <Typography
              className={styles.taskName}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {getTranslation('figma_lesson_page.tasks_section.task')}{' '}
              {taskIndex + 1}
            </Typography>
            {withError && (
              <Typography
                className={styles.taskError}
                variant="body3"
                mode="compact"
              >
                {getTranslation('figma_lesson_page.tasks_section.check')}
              </Typography>
            )}
          </div>

          {withError && <Icon className={styles.taskIcon} name="warning" />}
        </div>
      ))}
    </div>
    {designType === 'PROTOTYPE' && (
      <Button
        className={styles.addTaskBtn}
        size="sm"
        variant="link"
        startIcon={<Icon name="plus" />}
      >
        {getTranslation('figma_lesson_page.tasks_section.add_task')}
      </Button>
    )}
  </div>
);

export default React.memo(TasksSection);
