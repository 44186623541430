import React from 'react';
import cn from 'classnames';

// import Icon404 from 'assets/img/404.svg';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { Header } from 'components/Header';

import styles from './NotFoundPage.styles.scss';

const NotFoundPage = ({ onButtonClick }) => (
  <div className={cn(styles.page, 'main-layout')}>
    <Header className={styles.header} withLogo withResponsive />
    <div className={cn('container', styles.container)}>
      <div className={styles.inner}>
        {/* <Icon404 className={styles.icon} /> */}
        <div className={styles.content}>
          <Typography className={styles.title}>
            {getTranslation('not_found_page.title')}
          </Typography>
          <Typography className={styles.text}>
            {getTranslation('not_found_page.text')}
          </Typography>
          <Button
            className={styles.button}
            size="md"
            startIcon={<Icon name="arrowLeft" />}
            onClick={onButtonClick}
          >
            {getTranslation('common.button.go_homepage')}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(NotFoundPage);
