import React from 'react';

import { Typography } from 'components/shared/Typography';

import { Item } from '../Item';
import { Lines } from '../Lines';
import { Action } from '../Action';

import styles from './PluginPreviewPage.styles.scss';

const PluginPreviewPage = ({
  rootRef,
  containerRef,
  leftItemsRef,
  rightItemsRef,
  nextLoading,
  isLastLessonInCourse,
  lineDrawing,
  linesValidated,
  leftItems,
  rightItems,
  lines,
  correctLinesIds,
  startLinePosition,
  lesson,
  onProcessDrawing,
  onItemClick,
  onTryAgainClick,
  onNextLessonClick,
  onRestartLessonClick,
  isTestMode,
}) => (
  <div className={styles.page} ref={rootRef}>
    <div
      className={styles.inner}
      onMouseMove={lineDrawing ? onProcessDrawing : () => {}}
    >
      <Typography variant="body1" className={styles.text}>
        {lesson.text}
      </Typography>
      <div className={styles.main}>
        <div className={styles.leftColumn}>
          {leftItems.map((leftItem, index) => {
            const pointUsed = lines.some(
              (line) =>
                (line.startId === leftItem.id &&
                  line.startType === leftItem.type) ||
                (line.finishId === leftItem.id &&
                  line.finishType === leftItem.type),
            );

            return (
              <Item
                key={leftItem.id + 'question'}
                id={leftItem.id}
                {...leftItem}
                pointUsed={pointUsed}
                lineDrawing={lineDrawing}
                linesValidated={linesValidated}
                correctLine={correctLinesIds.some(
                  (lineId) => lineId === leftItem.id,
                )}
                pointRef={leftItemsRef.current[index]}
                startLinePosition={startLinePosition}
                answersType={lesson.answersType}
                onClick={isTestMode && onItemClick}
                isTestMode={isTestMode}
              />
            );
          })}
        </div>
        <Lines
          lineDrawing={lineDrawing}
          lines={lines}
          containerRef={containerRef}
        />
        <div className={styles.rightColumn}>
          {rightItems.map((rightItem, index) => {
            const pointUsed = lines.some(
              (line) =>
                (line.startId === rightItem.id &&
                  line.startType === rightItem.type) ||
                (line.finishId === rightItem.id &&
                  line.finishType === rightItem.type),
            );

            return (
              <Item
                key={rightItem.id + 'answer'}
                className={styles.answerItem}
                {...rightItem}
                id={rightItem.id}
                pointUsed={pointUsed}
                lineDrawing={lineDrawing}
                linesValidated={linesValidated}
                correctLine={correctLinesIds.some(
                  (lineId) => lineId === rightItem.id,
                )}
                pointRef={rightItemsRef.current[index]}
                startLinePosition={startLinePosition}
                answersType={lesson.answersType}
                onClick={isTestMode && onItemClick}
                isTestMode={isTestMode}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.actions}>
        {isTestMode && (
          <Action
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            linesValidated={linesValidated}
            correctLinesIds={correctLinesIds}
            lines={lines}
            onTryAgainClick={onTryAgainClick}
            onNextLessonClick={onNextLessonClick}
            onRestartLessonClick={onRestartLessonClick}
          />
        )}
      </div>
    </div>
  </div>
);

export default React.memo(PluginPreviewPage);
