import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';

import styles from './Action.styles.scss';

const Action = ({ submitted, onRestartLessonClick }) => {
  return (
    <Button
      className={styles.action}
      disabled={!submitted}
      onClick={onRestartLessonClick}
    >
      {getTranslation('common.button.restart_lesson')}
    </Button>
  );
};

export default React.memo(Action);
