import React from 'react';

import { EditorInput } from 'components/shared/EditorInput';

const EditorInputField = ({ field, form, ...restProps }) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  return <EditorInput {...field} {...restProps} onChange={handleChange} />;
};

export default React.memo(EditorInputField);
