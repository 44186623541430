export const getLayerCornerRadius = (layer) => {
  if (!layer.cornerRadius && !layer.rectangleCornerRadii) {
    return 0;
  }

  if (!layer.cornerRadius && layer.rectangleCornerRadii) {
    return layer.rectangleCornerRadii;
  }

  if (layer.cornerRadius && !layer.rectangleCornerRadii) {
    return layer.cornerRadius;
  }
};
