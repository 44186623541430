import React from 'react';
import cn from 'classnames';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { ContextMenu } from 'components/ContextMenu';
import { Popover } from 'components/Popover';

import styles from './CreateButton.styles.scss';

const CreateButton = ({ buttonText, contextItems, onContextItemClick }) => (
  <Popover
    placement="bottom-end"
    triggerElement={({ isMounted }) => (
      <Button
        className={cn(styles.btn, { [styles.btnActive]: isMounted })}
        size="md"
        variant="link"
        startIcon={<Icon name="plusRound" />}
      >
        {buttonText}
      </Button>
    )}
  >
    <ContextMenu
      className={styles.menu}
      items={contextItems}
      onItemClick={(contextItem) => onContextItemClick(contextItem)}
    />
  </Popover>
);

export default React.memo(CreateButton);
