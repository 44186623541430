import axios from 'axios';
import qs from 'qs';

export const getAdmins = (params) =>
  axios.get(`/api/v1/admins?${qs.stringify(params)}`);

export const getAdminById = (adminId) => axios.get(`/api/v1/admins/${adminId}`);

export const updateAdminById = (adminId, data) =>
  axios.put(`/api/v1/admins/${adminId}`, data);

export const getCoursesByAdminId = (adminId) =>
  axios.get(`/api/v1/admins/${adminId}/courses`);

export const createAdmin = (data) => axios.post('/api/v1/admins', data);

export const importAdmins = ({ data, config }) =>
  axios.post('/api/v1/admins/import', data, {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const finishImportAdmins = (data) =>
  axios.post('/api/v1/admins/import/finish', data);
