import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { NotificationCard } from 'components/NotificationCard';

import { CategorySection } from '../CategorySection';

import { getCategories } from './DesignSection.helpers';

import styles from './DesignSection.styles.scss';

const DesignSection = ({
  isInitialState,
  properties,
  figmaFileImages,
  namePrefix,
  taskIndex,
  layerType,
}) => (
  <div className={styles.section}>
    {!isInitialState && (
      <NotificationCard
        className={styles.notificationCard}
        variant="info"
        text={getTranslation(
          'figma_lesson_page.main_settings.design_section.notification_text',
        )}
      />
    )}
    {getCategories({ properties, layerType }).map((category) => (
      <CategorySection
        key={category.key}
        isInitialState={isInitialState}
        figmaFileImages={figmaFileImages}
        properties={properties}
        title={category.title}
        categoryKey={category.key}
        categoryProperties={category.properties}
        categoryVariant={category.variant}
        namePrefix={namePrefix}
        taskIndex={taskIndex}
        layerType={layerType}
      />
    ))}
  </div>
);

export default React.memo(DesignSection);
