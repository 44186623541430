import React from 'react';

import DifficultySelect from './DifficultySelect';

const DifficultySelectContainer = ({ readOnly, field, helpers }) => {
  const handleItemClick = (itemValue) => {
    if (readOnly) {
      return;
    }

    helpers.setValue(itemValue);
  };

  return (
    <DifficultySelect
      readOnly={readOnly}
      value={field.value}
      onItemClick={handleItemClick}
    />
  );
};

export default React.memo(DifficultySelectContainer);
