import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { useCourse } from 'hooks/useCourse';

import { MarketingSettings } from 'pages/CoursePage/components/MarketingSettings';

import ArcadePage from './ArcadePage';

const ArcadePageContainer = () => {
  const { i18n } = useTranslation();

  const {
    loading,
    saveLoading,
    autoSaveLoading,
    readOnly,
    marketingSettingsOpen,
    data: arcade,
    lastActionAt,
    closeMarketingSettings,
    onAutoSaveSubmit,
    onSaveClick,
    onPreviewClick,
    onStatusContextItemClick,
    onMoreContextItemClick,
  } = useCourse();

  const user = useSelector(userSelector);
  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <>
      <ArcadePage
        locale={i18n.language}
        loading={loading}
        isRootAdmin={isRootAdmin}
        saveLoading={saveLoading}
        autoSaveLoading={autoSaveLoading}
        readOnly={readOnly}
        arcade={arcade}
        lastActionAt={lastActionAt}
        onAutoSaveSubmit={onAutoSaveSubmit}
        onSaveClick={onSaveClick}
        onPreviewClick={onPreviewClick}
        onStatusContextItemClick={onStatusContextItemClick}
        onMoreContextItemClick={onMoreContextItemClick}
      />
      <MarketingSettings
        open={marketingSettingsOpen}
        onClose={closeMarketingSettings}
      />
    </>
  );
};

export default React.memo(ArcadePageContainer);
