import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { getInitials, generateAvatarColor } from './Avatar.helpers';

import styles from './Avatar.styles.scss';

const SIZES = {
  lg: styles.lg,
  md: styles.md,
  sm: styles.sm,
};

const Avatar = ({ className, size, url, text, onClick }) => (
  <div
    className={cn(styles.avatar, SIZES[size], className)}
    style={{
      backgroundColor: generateAvatarColor(text, true),
    }}
    onClick={onClick}
  >
    {url && <img src={url} className={styles.preview} />}
    {text && !url && (
      <Typography className={styles.text} weight="semiBold">
        {getInitials(text)}
      </Typography>
    )}
  </div>
);

Avatar.defaultProps = {
  size: 'lg',
};

export default React.memo(Avatar);
