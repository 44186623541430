import React from 'react';

import { LESSON_TYPES } from 'constants/common';

import { Loading } from 'components/shared/Loading';

import { TheoryLessonPage } from 'pages/TheoryLessonPage';
import { QuizPage } from 'pages/QuizPage';
import { ConnectionQuizPage } from 'pages/ConnectionQuizPage';
import { FigmaLessonPage } from 'pages/FigmaLessonPage';
import { TwoColumnsLessonPage } from 'pages/TwoColumnsLessonPage';
import { DifferenceLessonPage } from 'pages/DifferenceLessonPage';
import { ColorMatchLessonPage } from 'pages/ColorMatchLessonPage';

const LessonProvider = ({ loading, lesson, onRemoveClick }) => {
  if (loading) {
    return <Loading />;
  }

  switch (lesson.type) {
    case LESSON_TYPES.THEORY_LESSON: {
      return <TheoryLessonPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.QUIZ: {
      return <QuizPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.CONNECTION_QUIZ: {
      return <ConnectionQuizPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.FIGMA_LESSON: {
      return <FigmaLessonPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.TWO_COLUMNS_LESSON: {
      return <TwoColumnsLessonPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.DIFFERENCE_LESSON: {
      return <DifferenceLessonPage onRemoveClick={onRemoveClick} />;
    }
    case LESSON_TYPES.COLOR_MATCH_LESSON: {
      return <ColorMatchLessonPage onRemoveClick={onRemoveClick} />;
    }
  }
};

export default React.memo(LessonProvider);
