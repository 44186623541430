import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getUnsubscribeReasons = createAsyncThunk(
  types.GET_UNSUBSCRIBE_REASONS,
  async (params) => {
    const unsubscribeReasons = await api.getStudentUnsubscribeReasons(params);
    return unsubscribeReasons;
  },
);
