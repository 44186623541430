import React from 'react';
import cn from 'classnames';

import styles from './Typography.styles.scss';

const VARIANTS = {
  heading1: styles.heading1,
  heading2: styles.heading2,
  heading3: styles.heading3,
  subtitle: styles.subtitle,
  body1: styles.body1,
  body2: styles.body2,
  body3: styles.body3,
  caption: styles.caption,
};

const MODES = {
  default: styles.default,
  compact: styles.compact,
};

const WEIGHTS = {
  bold: styles.bold,
  regular: styles.regular,
  medium: styles.medium,
  semiBold: styles.semiBold,
};

const FAMILIES = {
  inter: styles.inter,
  prompt: styles.prompt,
};

const Typography = ({
  className,

  children,
  variant,
  weight,
  family,
  mode,
  component,
  withHTML,
  ...restProps
}) => {
  const Component = component || 'p';

  if (withHTML) {
    return (
      <Component
        {...restProps}
        dangerouslySetInnerHTML={{ __html: children }}
        className={cn(
          styles.typography,
          FAMILIES[family],
          WEIGHTS[weight],
          VARIANTS[variant],
          MODES[mode],
          className,
        )}
      />
    );
  }

  return (
    <Component
      {...restProps}
      className={cn(
        styles.typography,
        FAMILIES[family],
        WEIGHTS[weight],
        VARIANTS[variant],
        MODES[mode],
        className,
      )}
    >
      {children}
    </Component>
  );
};

Typography.defaultProps = {
  withHTML: false,
  weight: 'regular',
  mode: 'default',
  family: 'inter',
};

export default React.memo(Typography);
