import { useState } from 'react';

import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';

export const useFileUpload = ({ onUploaded }) => {
  const [uploadError, setUploadError] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const handleDropAccepted = async (files) => {
    if (!files.length) {
      return;
    }

    try {
      const currentFile = files[0];

      setPercentage(0.1);

      const data = new FormData();
      data.append('file', currentFile);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(progressEvent.progress * 100);

          setPercentage(percentCompleted);
        },
      };

      const response = await api.importStudents({ data, config });

      setPercentage(0);

      if (onUploaded) {
        onUploaded(response);
      }
    } catch (error) {
      console.warn(error);

      setUploadError(true);
      setPercentage(0);
    }
  };

  const handleDropRejected = (event) => {
    const eventError = event[0].errors[0];

    // TODO: check errors
    switch (eventError.code) {
      // case EVENT_ERROR_CODE.INVALID_TYPE: {
      //   form.setFieldError(
      //     field.name,
      //     'The specified file could not be uploaded. Allowed type jpeg, png, gif, avi, mp4, mov, mkv, mpg, wmv, mts, 3gp',
      //   );

      //   break;
      // }
      // case EVENT_ERROR_CODE.LARGE_SIZE: {
      //   form.setFieldError(field.name, eventError.message);

      //   break;
      // }
      // case EVENT_ERROR_CODE.MANY_FILES: {
      //   form.setFieldError(field.name, 'No more than 3 files can be attached');

      //   break;
      // }

      default: {
        break;
      }
    }
  };

  return [percentage, uploadError, handleDropAccepted, handleDropRejected];
};
