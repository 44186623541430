import React from 'react';

import { getTranslation } from 'helpers/getTranslation';
import { copyToClipboard } from 'helpers/copyToClipboard';

import { showToast } from 'helpers/showToast';

import StudentCard from './StudentCard';

const StudentCardContainer = ({ email, ...restProps }) => {
  const handleCopyEmailClick = () => {
    copyToClipboard(email);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.clipboard_copy.title'),
        text: getTranslation('common.toast.clipboard_copy.text'),
      },
    });
  };

  return (
    <StudentCard
      {...restProps}
      email={email}
      onCopyEmailClick={handleCopyEmailClick}
    />
  );
};

export default React.memo(StudentCardContainer);
