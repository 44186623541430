import React from 'react';

import styles from './Element.styles.scss';

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case 'link':
      return (
        <a
          style={style}
          className={styles.link}
          {...attributes}
          href={element.url}
        >
          {children}
        </a>
      );
    default:
      return (
        <p {...attributes} style={style}>
          {children}
        </p>
      );
  }
};

export default React.memo(Element);
