import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField, UploadField } from 'components/FormikFields';

import styles from './MainSettings.styles.scss';

const Question = ({ readOnly }) => (
  <div className={styles.settings}>
    <div className={styles.section}>
      <Typography
        className={styles.sectionTitle}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('color_match_lesson_page.main_settings.question')}
      </Typography>
      <FormikField
        name="text"
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          multiline: true,
          className: styles.text,
          variant: 'text',
          placeholder: getTranslation(
            'color_match_lesson_page.main_settings.placeholder',
          ),
        }}
      />
    </div>
    <div className={cn(styles.section, styles.sectionImage)}>
      <Typography
        className={styles.sectionTitle}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('color_match_lesson_page.main_settings.image')}
      </Typography>
      <FormikField
        name="imageUrl"
        withControl={false}
        component={UploadField}
        componentProps={{
          readOnly,
          className: styles.image,
          variant: 'quizImage',
          accept: {
            'image/svg+xml': ['.svg'],
          },
        }}
      />
    </div>
    <div className={styles.section}>
      <Typography
        className={styles.sectionTitle}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('color_match_lesson_page.main_settings.color')}
      </Typography>
      <FormikField
        name="color"
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          multiline: true,
          className: styles.text,
          variant: 'text',
          placeholder: '#000000',
        }}
      />
    </div>
  </div>
);

export default React.memo(Question);
