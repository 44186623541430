import axios from 'axios';

export const createTwoColumnsLesson = (data) =>
  axios.post('/api/v1/two-columns-lessons', data);

export const updateTwoColumnsLessonById = ({ lessonId, data }) =>
  axios.put(`/api/v1/two-columns-lessons/${lessonId}`, data);

export const removeTwoColumnsLessonById = (lessonId) =>
  axios.delete(`/api/v1/two-columns-lessons/${lessonId}`);
