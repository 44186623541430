import { createSlice } from '@reduxjs/toolkit';

import { getUnsubscribeReasons } from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: null,
  perPage: 50,
};

const unsubscribeReasonsSlice = createSlice({
  name: 'unsubscribeReasons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUnsubscribeReasons.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

export default unsubscribeReasonsSlice.reducer;
