import { getTranslation } from 'helpers/getTranslation';

export const getAdminsTableColumns = () => [
  {
    key: 'name',
    label: getTranslation('admins_page.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('admins_page.table_keys.email'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('admins_page.table_keys.createdAt'),
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('admins_page.table_keys.status'),
    sortable: true,
  },
  // {
  //   key: 'updatedAt',
  //   label:  getTranslation('admins_page.table_keys.updated_at'),
  // },
];

export const getAdminsTableColumnsRootAdmin = () => [
  {
    key: 'name',
    label: getTranslation('admins_page.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('admins_page.table_keys.email'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('admins_page.table_keys.createdAt'),
    sortable: true,
  },
  {
    key: 'school',
    label: getTranslation('admins_page.table_keys.school'),
    sortable: false,
  },
  {
    key: 'status',
    label: getTranslation('admins_page.table_keys.status'),
    sortable: true,
  },
];
