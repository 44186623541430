import React from 'react';

import { getAutoLayoutDirectionKey } from 'helpers/getAutoLayoutDirectionKey';
import { getAutoLayoutDirectionTooltipValue } from 'helpers/getAutoLayoutDirectionTooltipValue';

import { Icon } from 'components/shared/Icon';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import styles from './AutoLayoutDirectionProperty.styles.scss';

const _renderTopLeftItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_LEFT': {
      return <Icon className={styles.icon} name="layoutVerticalLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_LEFT': {
      return <Icon className={styles.icon} name="layoutHorizontalTop" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_LEFT': {
      return <Icon className={styles.icon} name="layoutWrapLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderTopCenterItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_CENTER': {
      return <Icon className={styles.icon} name="layoutVerticalCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_CENTER': {
      return <Icon className={styles.icon} name="layoutHorizontalTop" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_CENTER': {
      return <Icon className={styles.icon} name="layoutWrapCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderTopRightItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_RIGHT': {
      return <Icon className={styles.icon} name="layoutVerticalRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_RIGHT': {
      return <Icon className={styles.icon} name="layoutHorizontalTop" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_RIGHT': {
      return <Icon className={styles.icon} name="layoutWrapRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderCenterLeftItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_LEFT': {
      return <Icon className={styles.icon} name="layoutVerticalLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_LEFT': {
      return <Icon className={styles.icon} name="layoutHorizontalCenter" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_LEFT': {
      return <Icon className={styles.icon} name="layoutWrapLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT': {
      return <Icon className={styles.icon} name="baseline" />;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderCenterItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_CENTER': {
      return <Icon className={styles.icon} name="layoutVerticalCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_CENTER': {
      return <Icon className={styles.icon} name="layoutHorizontalCenter" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_CENTER': {
      return <Icon className={styles.icon} name="layoutWrapCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER': {
      return <Icon className={styles.icon} name="baseline" />;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderCenterRightItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_RIGHT': {
      return <Icon className={styles.icon} name="layoutVerticalRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_RIGHT': {
      return <Icon className={styles.icon} name="layoutHorizontalCenter" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_RIGHT': {
      return <Icon className={styles.icon} name="layoutWrapRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return <Icon className={styles.icon} name="baseline" />;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderBottomLeftItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_LEFT': {
      return <Icon className={styles.icon} name="layoutVerticalLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_LEFT': {
      return <Icon className={styles.icon} name="layoutHorizontalBottom" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_LEFT': {
      return <Icon className={styles.icon} name="layoutWrapLeft" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderBottomCenterItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_CENTER': {
      return <Icon className={styles.icon} name="layoutVerticalCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_CENTER': {
      return <Icon className={styles.icon} name="layoutHorizontalBottom" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_CENTER': {
      return <Icon className={styles.icon} name="layoutWrapCenter" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderBottomRightItem = (layoutDirectionKey) => {
  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_RIGHT': {
      return <Icon className={styles.icon} name="layoutVerticalRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_RIGHT': {
      return <Icon className={styles.icon} name="layoutHorizontalBottom" />;
    }
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_RIGHT': {
      return <Icon className={styles.icon} name="layoutWrapRight" />;
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return null;
    }
    default: {
      return <div className={styles.circle} />;
    }
  }
};

const _renderContent = ({
  value: {
    layoutMode,
    layoutWrap,
    counterAxisAlignItems,
    primaryAxisAlignItems,
  },
}) => {
  const layoutDirectionKey = getAutoLayoutDirectionKey({
    layoutMode: layoutMode.value,
    layoutWrap: layoutWrap.value,
    counterAxisAlignItems: counterAxisAlignItems.value,
    primaryAxisAlignItems: primaryAxisAlignItems.value,
  });

  return (
    <div className={styles.grid}>
      <div className={styles.row}>
        <div className={styles.item}>
          {_renderTopLeftItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderTopCenterItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderTopRightItem(layoutDirectionKey)}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>
          {_renderCenterLeftItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderCenterItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderCenterRightItem(layoutDirectionKey)}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.item}>
          {_renderBottomLeftItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderBottomCenterItem(layoutDirectionKey)}
        </div>
        <div className={styles.item}>
          {_renderBottomRightItem(layoutDirectionKey)}
        </div>
      </div>
    </div>
  );
};

const _renderField = ({ namePrefix, readOnly, property, onChanged }) => (
  <FormikField
    name={namePrefix}
    withControl={false}
    component={PropertySelectField}
    componentProps={{
      type: 'checkbox',
      readOnly,
      className: styles.property,
      property,
      renderProperty: _renderContent,
      onChanged,
    }}
  />
);

const AutoLayoutDirectionProperty = ({
  isInitialState,
  readOnly,
  designType,
  namePrefix,
  property,
  onChanged,
}) => {
  const tooltip = getAutoLayoutDirectionTooltipValue(property);

  if (isInitialState || designType === 'PROTOTYPE') {
    if (tooltip) {
      return (
        <Tooltip
          text={tooltip}
          triggerElement={
            <div className={styles.propertyReadOnly}>
              {_renderContent(property)}
            </div>
          }
        />
      );
    }

    return (
      <div className={styles.propertyReadOnly}>{_renderContent(property)}</div>
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        text={tooltip}
        triggerElement={_renderField({
          namePrefix,
          readOnly,
          property,
          onChanged,
        })}
      />
    );
  }

  return _renderField({
    namePrefix,
    readOnly,
    property,
    onChanged,
  });
};

export default React.memo(AutoLayoutDirectionProperty);
