import { useEffect, useState } from 'react';

import { getFramesPreviews } from 'helpers/getFramesPreviews';

export const usePageFramesPreviews = ({
  currentPages,
  filePageId,
  fileId,
  setPages,
}) => {
  const [loadingFrames, setLoadingFrames] = useState(false);

  const _loadPageFrames = async ({ pages, pageId }) => {
    if (pageId) {
      const currentPage = pages.find((page) => page.id === pageId);

      if (!currentPage.loaded) {
        try {
          setLoadingFrames(true);

          const previews = await getFramesPreviews({
            layers: currentPage.frames,
            fileId,
          });

          setPages(
            pages.map((page) => {
              if (page.id === filePageId) {
                return {
                  ...page,
                  loaded: true,
                  frames: page.frames.map((frame) => ({
                    ...frame,
                    preview: previews[frame.id],
                  })),
                };
              }

              return page;
            }),
          );
          setLoadingFrames(false);
        } catch (error) {
          setLoadingFrames(false);
        }
      }
    }
  };

  useEffect(() => {
    _loadPageFrames({ pages: currentPages, pageId: filePageId });
  }, [currentPages, filePageId]);

  return [loadingFrames];
};
