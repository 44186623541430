import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';
import { Checkbox } from 'components/shared/Checkbox';
import { Button } from 'components/shared/Button';

import { Tooltip } from 'components/Tooltip';

import { Cell } from './components/Cell';

import cellStyles from './components/Cell/Cell.styles.scss';

import styles from './Table.styles.scss';

const Table = ({
  className,
  headerClassName,
  rowClassName,
  loading,
  selectable,
  noHeader,
  data,
  columns,
  actions,
  params,
  cellRenderer,
  onSortChange,
  onRowClick,
}) => (
  <div className={cn(styles.table, className)}>
    {!noHeader && (
      <div className={cn(styles.header, headerClassName)}>
        {selectable && <Checkbox className={styles.headerCheck} />}
        {columns.map((column) => (
          <div
            key={column.key}
            className={cn(cellStyles.cell, {
              [styles.sortableCell]: onSortChange,
            })}
            style={{
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              justifyContent: column.justifyContent,
            }}
            onClick={() => {
              if (column.sortable) {
                onSortChange(column.key);
              }
            }}
          >
            <Typography
              variant="body3"
              weight="medium"
              mode="compact"
              className={styles.headerText}
            >
              {column.label}
            </Typography>
            {column.key === params.sortBy && (
              <Icon
                name={
                  params.sortDirection === 'asc'
                    ? 'arrowSortUp'
                    : 'arrowSortDown'
                }
                className={styles.sortingIcon}
              />
            )}
          </div>
        ))}
      </div>
    )}
    {loading ? (
      <Loading />
    ) : (
      <div className={styles.body}>
        {data.map((item) => (
          <div
            key={item.id}
            className={cn(styles.row, item.rowClassName, rowClassName)}
            onClick={() => onRowClick(item)}
          >
            {selectable && <Checkbox className={styles.rowCheck} />}
            {columns.map((column) =>
              cellRenderer ? (
                cellRenderer(
                  { ...column, item, className: cellStyles.cell },
                  Cell,
                )
              ) : (
                <Cell
                  minWidth={column.minWidth}
                  maxWidth={column.maxWidth}
                  justifyContent={column.justifyContent}
                  key={column.key}
                >
                  {item[column.key]}
                </Cell>
              ),
            )}
            {actions && actions.length > 0 && (
              <div className={styles.actions}>
                {actions.map((action) => (
                  <Tooltip
                    key={action.key}
                    text={action.tooltipText}
                    triggerElement={
                      <Button
                        className={styles.action}
                        mode="icon"
                        variant="ghost"
                        size="sm"
                        iconName={action.icon}
                        onClick={(event) => {
                          event.stopPropagation();

                          action.onClick(item);
                        }}
                      />
                    }
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}
  </div>
);

Table.defaultProps = {
  selectable: false,
  params: {},
  actions: [],
  onRowClick: () => {},
};

export default React.memo(Table);
