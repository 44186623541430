import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Item.styles.scss';

const _renderContent = ({ text }) => (
  <Typography className={styles.text} variant="body2" mode="compact">
    {text}
  </Typography>
);

const Item = ({
  className,
  dragging,
  draggable,
  listeners,
  attributes,
  text,
  setNodeRef,
}) => {
  if (draggable) {
    return (
      <div
        className={cn(
          styles.item,
          { [styles.itemDragging]: dragging },
          className,
        )}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {_renderContent({ text })}
      </div>
    );
  }

  return (
    <div className={cn(styles.item, { [styles.itemDragging]: dragging })}>
      {_renderContent({ text })}
    </div>
  );
};

export default React.memo(Item);
