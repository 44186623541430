import React from 'react';

import { Rating } from 'components/shared/Rating';
import { Typography } from 'components/shared/Typography';

import { CoursePreview } from 'components/CoursePreview';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { StudentInfo } from '../StudentInfo';

import styles from './LessonRatingsItem.styles.scss';

const LessonRatingsItem = ({ lessonRating, locale }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.courseCell}>
          <CoursePreview
            className={styles.coursePreview}
            preview={lessonRating.course.preview}
          />
          <div className={styles.courseDescription}>
            <Typography
              className={styles.courseTitle}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {lessonRating.course.title ||
                getTranslation('common.text.untitled')}
            </Typography>
            <Typography
              className={styles.lessonTitle}
              variant="body3"
              mode="compact"
            >
              {`${
                lessonRating.module
                  ? lessonRating.module.title ||
                    getTranslation('common.text.untitled')
                  : ''
              } - ${
                lessonRating.lesson.title ||
                getTranslation('common.text.untitled')
              }`}
            </Typography>
          </div>
        </div>
        <StudentInfo data={lessonRating} />
        <Typography variant="body3" className={styles.date}>
          {lessonRating.createdAt &&
            formatDate(lessonRating.createdAt, 'dd MMM, yyyy HH:MM', locale)}
        </Typography>
      </div>

      <div className={styles.content}>
        {lessonRating.tags.length > 0 && (
          <div className={styles.tags}>
            {lessonRating.tags.map((tag) => (
              <div className={styles.tag} key={tag}>
                <Typography
                  className={styles.tagText}
                  variant="body3"
                  weight="medium"
                  mode="compact"
                >
                  {tag}
                </Typography>
              </div>
            ))}
          </div>
        )}
        <Rating readonly={true} value={lessonRating.rating} size={24} />
      </div>
    </>
  );
};

export default React.memo(LessonRatingsItem);
