import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';
import { getLessonStatusContextItems } from 'helpers/getLessonStatusContextItems';

import { Tag } from 'components/shared/Tag';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { LessonIcon } from 'components/LessonIcon';
import { StatusSelect } from 'components/StatusSelect';
import { Tooltip } from 'components/Tooltip';

import styles from './LessonItem.styles.scss';

const LessonItem = ({
  courseReadOnly,
  free,
  statusError,
  structure,
  id: lessonId,
  title,
  type,
  status,
  onClick,
  onEditClick,
  onStatusContextItemClick,
}) => (
  <div
    className={cn(styles.item, {
      [styles.readOnlyItem]: courseReadOnly,
      [styles.lessonStructure]: structure === 'LESSONS',
    })}
    onClick={onClick}
  >
    <div className={styles.inner}>
      <div className={styles.iconWrapper}>
        <LessonIcon className={styles.icon} type={type} />
      </div>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {title || getTranslation('common.text.untitled')}
      </Typography>
    </div>
    <div className={styles.actions}>
      {statusError && (
        <Tooltip
          text={statusError}
          triggerClassName={styles.warningIconWrapper}
          triggerElement={
            <Icon className={styles.warningIcon} name="warning" />
          }
        />
      )}
      {free && (
        <Tag className={styles.freeTag} variant="warning">
          {getTranslation('common.text.free')}
        </Tag>
      )}
      <StatusSelect
        size="sm"
        status={status.toLowerCase()}
        contextItems={getLessonStatusContextItems({ courseReadOnly, status })}
        onContextItemClick={(contextItem) =>
          onStatusContextItemClick({ contextItem, lessonId })
        }
      />
      <Button
        className={styles.action}
        size="sm"
        mode="icon"
        variant="ghost"
        iconName="edit"
        onClick={() => onEditClick(lessonId)}
      />
    </div>
  </div>
);

export default React.memo(LessonItem);
