import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as api from 'api/methods';

import { currentStudentSelector } from 'store/student/selectors';
import { grantStudentCoursesAccess } from 'store/student/actions';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { useFetch } from 'hooks/useFetch';

import { prepareCourses } from './ManageCoursesAccess.helpers';

import ManageCoursesAccess from './ManageCoursesAccess';

const ManageCoursesAccessContainer = ({
  open,
  schoolId,
  onClose,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const currentStudent = useSelector(currentStudentSelector);

  const [saveLoading, setSaveLoading] = useState(false);

  const { loading, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getSchoolCourses,
      immediately: false,
      stopRequest: !open,
    },
    { schoolId, params: {} },
    [open],
  );

  const handleSaveClick = async (data) => {
    try {
      setSaveLoading(true);

      await dispatch(
        grantStudentCoursesAccess({
          studentId: currentStudent.id,
          data: { ...data, schoolId },
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.course_access.title'),
          text: getTranslation('common.toast.course_access.text'),
        },
      });

      onClose();
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const preparedCourses = prepareCourses({ courses, currentStudent });
  const isTestStudent = currentStudent ? currentStudent.isTest : false;

  return (
    <ManageCoursesAccess
      {...restProps}
      open={open}
      loading={loading}
      saveLoading={saveLoading}
      isTestStudent={isTestStudent}
      courses={preparedCourses}
      onClose={onClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(ManageCoursesAccessContainer);
