import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/common';

import { getStudentById } from 'store/student/actions';
import { currentStudentSelector } from 'store/student/selectors';
import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { EditStudent } from './components/EditStudent';
import { GrantProAccess } from './components/GrantProAccess';

import { School } from './components/School';
import { ManageCoursesAccess } from './components/ManageCoursesAccess';

import {
  getStudentSchools,
  getLastUpdatedByCourses,
} from './StudentPage.helpers';

import StudentPage from './StudentPage';

const StudentPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { id: studentId } = useParams();

  const user = useSelector(userSelector);
  const currentStudent = useSelector(currentStudentSelector);

  const [loading, setLoading] = useState(true);

  const [editStudentOpen, openEditStudent, closeEditStudent] = useModal({
    defaultOpen: false,
  });
  const [
    grantProAccessOpen,
    openGrantProAccess,
    closeGrantProAccess,
    grantProAccessData,
  ] = useModal({
    defaultOpen: false,
  });
  const [
    manageCoursesAccessOpen,
    openManageCoursesAccess,
    closeManageCoursesAccess,
    manageCoursesAccessData,
  ] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    const _getStudentById = async () => {
      try {
        await dispatch(getStudentById(studentId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getStudentById();
  }, []);

  const handleEditClick = () => {
    openEditStudent();
  };

  const handleGrantProAccessClick = (school) => {
    openGrantProAccess({ schoolId: school.id });
  };

  const handleManageCoursesAccessClick = (school) => {
    openManageCoursesAccess({ schoolId: school.id });
  };

  const handleCourseClick = (course) => {
    navigate(`/courses/${course.id}`);
  };

  const studentSchools = getStudentSchools({ user, currentStudent });

  const schoolsTabs = studentSchools.map((school) => ({
    label: school.name,
    content: (
      <School
        locale={i18n.language}
        isRootAdmin
        courses={currentStudent.courses.filter(
          (course) => course.schoolId === school.id,
        )}
        school={school}
        student={currentStudent}
        status={currentStudent.status.find(
          (item) => item.schoolId === school.id,
        )}
        createdAt={currentStudent.createdAt}
        updatedAt={getLastUpdatedByCourses(currentStudent.courses)}
        onCourseClick={handleCourseClick}
        onManageCoursesAccessClick={() =>
          handleManageCoursesAccessClick(school)
        }
        onGrantProAccessClick={() => handleGrantProAccessClick(school)}
      />
    ),
  }));

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <>
      <StudentPage
        locale={i18n.language}
        loading={loading}
        isRootAdmin={isRootAdmin}
        schoolsTabs={schoolsTabs}
        studentSchools={studentSchools}
        currentStudent={currentStudent}
        onEditClick={handleEditClick}
        onCourseClick={handleCourseClick}
        onManageCoursesAccessClick={handleManageCoursesAccessClick}
      />
      <EditStudent open={editStudentOpen} onClose={closeEditStudent} />
      <GrantProAccess
        {...grantProAccessData}
        open={grantProAccessOpen}
        onClose={closeGrantProAccess}
      />
      <ManageCoursesAccess
        {...manageCoursesAccessData}
        open={manageCoursesAccessOpen}
        onClose={closeManageCoursesAccess}
      />
    </>
  );
};

export default React.memo(StudentPageContainer);
