import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './EmptyLayers.styles.scss';

const EmptyLayers = () => (
  <div className={styles.emptyLayers}>
    <Icon className={styles.icon} name="layers" />
    <Typography className={styles.title} variant="subtitle">
      Layers not found
    </Typography>
    <Typography className={styles.text} variant="body1">
      There are no layers in this file
    </Typography>
  </div>
);

export default React.memo(EmptyLayers);
