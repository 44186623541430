import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { TOKEN_KEY } from 'constants/token';

import * as api from 'api/methods';

import * as types from './types';

export const getUserInfo = createAsyncThunk(types.GET_USER_INFO, async () => {
  const data = await api.getUserInfo();

  return data;
});

export const setCurrentUser = createAction(types.SET_CURRENT_USER);

export const logoutUser = createAction(types.LOGOUT_USER, () => {
  localStorage.removeItem(TOKEN_KEY);

  return Promise.resolve();
});

export const getSchoolById = createAsyncThunk(
  types.GET_SCHOOL_BY_ID,
  async (schoolId) => {
    const school = await api.getSchoolById(schoolId);

    return school;
  },
);
