import { uniq as _uniq } from 'lodash';

const _getTaskImages = (layers) => {
  const images = [];

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];

    const fillsProperty = layer.properties.find(
      (property) => property.key === 'fills',
    );

    if (fillsProperty && fillsProperty.value.length) {
      const imagesHashes = fillsProperty.value
        .filter((fillProperty) => fillProperty.type === 'IMAGE')
        .map((fillProperty) => fillProperty.imageHash);

      images.push(...imagesHashes);
    }

    if (layer.children && layer.children.length) {
      images.push(..._getTaskImages(layer.children));
    }
  }

  return images;
};

export const getTaskMaterials = ({ fileImages, taskLayers }) => {
  const taskImages = _uniq(_getTaskImages(taskLayers));

  return fileImages.filter((fileImage) =>
    taskImages.some((taskImage) => taskImage === fileImage.imageRef),
  );
};
