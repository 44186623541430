import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { trainingsSelector } from 'store/trainings/selectors';

import { usePagination } from 'hooks/usePagination';

import TrainingsTable from './TrainingsTable';

const TrainingsTableContainer = ({ dataAction, params, ...restProps }) => {
  const { i18n } = useTranslation();
  const { currentPage, totalPages, perPage } = useSelector(trainingsSelector);

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction,
    currentPage,
    totalPages,
    perPage,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  return (
    <TrainingsTable
      {...restProps}
      locale={i18n.language}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      params={params}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
    />
  );
};

export default React.memo(TrainingsTableContainer);
