export const convertDataToApi = ({ data, isRootAdmin, schoolId }) => {
  if (isRootAdmin && data.school.value) {
    return {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      schoolId: data.school.value,
    };
  }

  return {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    schoolId,
  };
};
