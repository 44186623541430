import { createAsyncThunk } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';

import { ARCADE_STRUCTURES } from 'constants/common';

import { updateLessonStatusById } from 'store/lesson/actions';

import * as api from 'api/methods';

import * as types from './types';

const _getArcadeModules = async (arcadeId) => {
  const promises = [
    api.getArcadeModules(arcadeId),
    api.getArcadeLessons(arcadeId),
  ];

  const [modules, lessons] = await Promise.all(promises);

  return _orderBy(modules, ['order'], ['asc']).map((module) => ({
    ...module,
    lessons: lessons.filter((lesson) => lesson.moduleId === module.id),
  }));
};

export const getArcadeById = createAsyncThunk(
  types.GET_CURRENT_ARCADE,
  async (arcadeId) => {
    const arcade = await api.getArcadeById(arcadeId);

    if (arcade.structure === ARCADE_STRUCTURES.MODULES) {
      const modules = await _getArcadeModules(arcadeId);

      return {
        ...arcade,
        modules,
      };
    }

    if (arcade.structure === ARCADE_STRUCTURES.LESSONS) {
      const lessons = await api.getArcadeLessons(arcadeId);

      return { ...arcade, lessons };
    }

    return arcade;
  },
);

export const updateArcadeById = createAsyncThunk(
  types.UPDATE_ARCADE_BY_ID,
  async ({ arcadeId, data }) => {
    const arcade = await api.updateArcadeById({
      arcadeId,
      data,
    });

    if (data.structure && data.structure === ARCADE_STRUCTURES.MODULES) {
      const modules = await _getArcadeModules(arcadeId);

      return { ...arcade, modules };
    }

    if (data.structure === ARCADE_STRUCTURES.LESSONS) {
      const lessons = await api.getArcadeLessons(arcadeId);

      return { ...arcade, lessons };
    }

    return arcade;
  },
);

export const updateArcadeStatus = createAsyncThunk(
  types.UPDATE_ARCADE_STATUS,
  async ({ arcadeId, withLessons, status }, { dispatch, getState }) => {
    if (withLessons) {
      const state = getState();

      let lessons = [];

      if (state.currentArcade.structure === ARCADE_STRUCTURES.MODULES) {
        lessons = _flatten(
          state.currentArcade.modules.map((module) => module.lessons),
        );
      } else if (state.currentArcade.structure === ARCADE_STRUCTURES.LESSONS) {
        lessons = state.currentArcade.lessons;
      }

      const lessonsPromises = lessons.map((lesson) =>
        dispatch(
          updateLessonStatusById({
            lessonId: lesson.id,
            lessonType: lesson.type,
            data: { status },
          }),
        ),
      );

      await Promise.all(lessonsPromises);
    }

    const arcade = await api.updateArcadeById({
      arcadeId,
      data: { status },
    });

    return arcade;
  },
);

export const createArcadeModule = createAsyncThunk(
  types.CREATE_ARCADE_MODULE,
  async ({ arcadeId, data }) => {
    const module = await api.createArcadeModule({
      arcadeId,
      data,
    });

    return module;
  },
);

export const updateArcadeModuleById = createAsyncThunk(
  types.UPDATE_ARCADE_MODULE_BY_ID,
  async ({ arcadeId, moduleId, data }) => {
    const module = await api.updateArcadeModuleById({
      arcadeId,
      moduleId,
      data,
    });

    return module;
  },
);

export const removeArcadeModuleById = createAsyncThunk(
  types.REMOVE_ARCADE_MODULE_BY_ID,
  async ({ arcadeId, moduleId }) => {
    const module = await api.removeArcadeModuleById({ arcadeId, moduleId });

    return module;
  },
);

export const reorderArcadeLessons = createAsyncThunk(
  types.REORDER_ARCADE_LESSONS,
  async ({ arcadeId, data }) => {
    const lessons = await api.reorderArcadeLessons({ arcadeId, data });

    return { moduleId: data.moduleId, lessons };
  },
);

export const reorderArcadeModules = createAsyncThunk(
  types.REORDER_ARCADE_MODULES,
  async ({ arcadeId, data }) => {
    const modules = await api.reorderArcadeModules({ arcadeId, data });

    return modules;
  },
);
