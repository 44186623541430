import React from 'react';
import cn from 'classnames';
import format from 'date-fns/format';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Avatar } from 'components/shared/Avatar';
import { Drawer } from 'components/shared/Drawer';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';
import { CompletedIcon } from 'components/shared/CompletedIcon';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';

import { StatusTag } from 'components/StatusTag';
import { LessonIcon } from 'components/LessonIcon';

import styles from './StudentInfo.styles.scss';

const StudentInfo = ({ open, structure, studentStats, onClose }) => (
  <Drawer
    open={open}
    headerContent={
      studentStats && (
        <div className={styles.header}>
          <Avatar
            text={studentStats.name}
            size="sm"
            className={styles.avatar}
          />
          <div>
            <Typography variant="body2" mode="compact">
              {studentStats.name}
            </Typography>
            {studentStats.startedAt && (
              <Typography variant="body3" className={styles.dateText}>
                {getTranslation('dashboard_page.student_info.date_text')}
                {format(
                  new Date(studentStats.startedAt),
                  ' HH:mm, dd MMMM yyyy',
                )}
              </Typography>
            )}
          </div>
        </div>
      )
    }
    onClose={onClose}
  >
    {!studentStats ? (
      <div className={styles.loading}>
        <Loading variant="centered" />
      </div>
    ) : (
      <div className={styles.course}>
        {structure === 'MODULES' &&
          studentStats.modules.map((module, moduleIndex) => (
            <Collapsible
              key={module.id}
              className={styles.module}
              openedClassName={cn(styles.module, styles.moduleOpened)}
              transitionTime={150}
              triggerTagName="div"
              accordionPosition={moduleIndex}
              trigger={
                <div className={styles.moduleHeader}>
                  <img className={styles.modulePreview} src={module.preview} />
                  <Typography
                    variant="body2"
                    weight="medium"
                    mode="compact"
                    className={styles.moduleTitle}
                  >
                    {getTranslation('dashboard_page.student_info.module_title')}
                  </Typography>
                  {module.completed ? (
                    <CompletedIcon />
                  ) : (
                    <RoundProgressBar
                      withProgressText={true}
                      size={24}
                      progressColor="var(--color-green-primary)"
                      backLineColor="var(--color-green-primary)"
                      backLineOpacity={0.15}
                      lineSize={16}
                      progress={module.progress}
                    />
                  )}
                  <Typography
                    className={styles.progressText}
                    variant="body2"
                    weight="medium"
                    mode="compact"
                  >
                    {module.progress}%
                  </Typography>
                  <Icon name="chevronDown" className={styles.moduleIcon} />
                </div>
              }
            >
              <div className={styles.moduleInner}>
                {module.lessons.map((lesson) => (
                  <div className={styles.lesson}>
                    <div className={styles.iconWrapper}>
                      <LessonIcon className={styles.icon} type={lesson.type} />
                    </div>
                    <div className={styles.lessonInfo}>
                      <Typography
                        variant="body3"
                        weight="medium"
                        mode="compact"
                      >
                        {lesson.title}
                      </Typography>
                      {lesson.date && (
                        <Typography
                          variant="caption"
                          className={styles.lessonDateText}
                        >
                          {lesson.date}
                        </Typography>
                      )}
                    </div>
                    <StatusTag
                      variant={lesson.completed ? 'success' : 'default'}
                    >
                      {lesson.completed
                        ? getTranslation(
                            'dashboard_page.student_info.completed',
                          )
                        : getTranslation(
                            'dashboard_page.student_info.not_started',
                          )}
                    </StatusTag>
                  </div>
                ))}
              </div>
            </Collapsible>
          ))}
        {structure === 'LESSONS' &&
          studentStats.lessons.map((lesson) => (
            <div className={cn(styles.lesson, styles.lessonStructure)}>
              <div className={styles.iconWrapper}>
                <LessonIcon className={styles.icon} type={lesson.type} />
              </div>
              <div className={styles.lessonInfo}>
                <Typography variant="body3" weight="medium" mode="compact">
                  {lesson.title}
                </Typography>
                {lesson.date && (
                  <Typography
                    variant="caption"
                    className={styles.lessonDateText}
                  >
                    {lesson.date}
                  </Typography>
                )}
              </div>
              <StatusTag variant={lesson.completed ? 'success' : 'default'}>
                {lesson.completed
                  ? getTranslation('dashboard_page.student_info.completed')
                  : getTranslation('dashboard_page.student_info.not_started')}
              </StatusTag>
            </div>
          ))}
      </div>
    )}
  </Drawer>
);

export default React.memo(StudentInfo);
