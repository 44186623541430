import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { RoundProgressBar } from 'components/shared/RoundProgressBar';

import styles from './CourseProgress.styles.scss';

const CourseProgress = ({ className, progress }) => (
  <div className={cn(styles.progress, className)}>
    <RoundProgressBar
      size={20}
      progress={progress}
      progressColor="var(--color-green-primary)"
      backLineColor="var(--color-green-primary)"
      backLineOpacity={0.15}
      lineSize={16}
    />
    <Typography
      variant="caption"
      weight="medium"
      className={styles.progressText}
    >
      {getTranslation('course_preview_page.course_progress', { progress })}
    </Typography>
  </div>
);

export default React.memo(CourseProgress);
