export const getLayerStrokeWeight = (layer) => {
  if (!layer.strokeWeight && !layer.individualStrokeWeights) {
    return 0;
  }

  if (layer.individualStrokeWeights) {
    return layer.individualStrokeWeights;
  }

  if (layer.strokeWeight) {
    return layer.strokeWeight;
  }
};
