export const getComponentProperties = (layer) => {
  if (!layer.componentProperties) {
    return [];
  }

  const properties = [];

  for (const [propertyKey, property] of Object.entries(
    layer.componentProperties,
  )) {
    properties.push({
      key: propertyKey.split('#')[0],
      boundVariables: property.boundVariables,
      type: property.type,
      value: property.value,
    });
  }

  return properties;
};
