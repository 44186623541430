import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getTranslation } from 'helpers/getTranslation';

import { showToast } from 'helpers/showToast';
import { copyToClipboard } from 'helpers/copyToClipboard';

import { getSchoolCodes } from 'store/codes/actions';
import { userSelector } from 'store/auth/selectors';
import { schoolCodesSelector } from 'store/codes/selectors';

import ActivationCodes from './ActivationCodes';

const ActivationCodesContainer = () => {
  const dispatch = useDispatch();

  const { id: paramSchoolId } = useParams();

  const codes = useSelector(schoolCodesSelector);
  const user = useSelector(userSelector);

  const schoolId = paramSchoolId || user.schoolId;

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'startDate',
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getSchoolCodes = async () => {
      try {
        await dispatch(
          getSchoolCodes({
            schoolId,
            params: {
              page: 1,
              limit: 50,
              sortBy: sortParams.sortBy,
              sortDirection: sortParams.sortDirection,
            },
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getSchoolCodes();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getSchoolCodes({
          schoolId,
          params: {
            page: 1,
            limit: 50,
            sortBy: nextSortBy,
            sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
          },
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleCopyCodeClick = (code) => {
    copyToClipboard(code);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.clipboard_copy.title'),
        text: getTranslation('common.toast.clipboard_copy.text'),
      },
    });
  };

  return (
    <ActivationCodes
      loading={loading}
      codes={codes.data}
      schoolId={schoolId}
      sortParams={sortParams}
      onSortChange={handleSortChange}
      onCopyCodeClick={handleCopyCodeClick}
    />
  );
};

export default React.memo(ActivationCodesContainer);
