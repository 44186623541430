import React from 'react';

import { Input } from 'components/shared/Input';

const InputField = ({ field, helpers, onChanged, onBlur, ...restProps }) => {
  const handleChange = (event) => {
    field.onChange(event);

    if (onChanged) {
      onChanged(field.name, event.target.value);
    }
  };

  const handleBlur = (event) => {
    field.onBlur(event);

    if (onBlur) {
      onBlur(field.name, event.target.value);
    }
  };

  return (
    <Input
      {...restProps}
      name={field.name}
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default React.memo(InputField);
