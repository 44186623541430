import React from 'react';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { Modules } from '../Modules';

import { getTranslation } from 'helpers/getTranslation';

import styles from './ModulesSettings.styles.scss';

const ModulesSettings = ({
  courseReadOnly,
  modules,
  courseType,
  onAddModuleClick,
  onRemoveModuleClick,
  onEditLessonClick,
  onRemoveLessonClick,
}) => (
  <div className={styles.settings}>
    <Modules
      courseReadOnly={courseReadOnly}
      modules={modules}
      courseType={courseType}
      onRemoveModuleClick={onRemoveModuleClick}
      onEditLessonClick={onEditLessonClick}
      onRemoveLessonClick={onRemoveLessonClick}
    />
    {!courseReadOnly && (
      <Button
        className={styles.button}
        size="md"
        variant="link"
        startIcon={<Icon name="plus" />}
        onClick={onAddModuleClick}
      >
        {getTranslation('common.button.add_module')}
      </Button>
    )}
  </div>
);

export default React.memo(ModulesSettings);
