export const getLayerRotation = (transformMatrix) => {
  if (!transformMatrix) {
    return 0;
  }

  const angle =
    (Math.atan2(-transformMatrix[1][0], transformMatrix[0][0]) * 180) / Math.PI;

  return Number(angle.toFixed(2));
};
