import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { STATUSES } from 'constants/common';

import * as api from 'api/methods';

import { updateLessonById } from 'store/lesson/actions';

import { currentCourseSelector } from 'store/course/selectors';
import { currentLessonSelector } from 'store/lesson/selectors';

import { showToast } from 'helpers/showToast';
import { getChangedPropertiesByKeys } from 'helpers/getChangedPropertiesByKeys';
import { getTranslation } from 'helpers/getTranslation';

import { useStatusChange } from 'hooks/useStatusChange';

import {
  getChangedProperties,
  convertDataToApi,
  checkAnswersValid,
} from './ConnectionQuizPage.helpers';

import ConnectionQuizPage from './ConnectionQuizPage';

const ConnectionQuizPageContainer = (props) => {
  const lesson = useSelector(currentLessonSelector);

  const [saveLoading, setSaveLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);
  const [lastActionAt, setLastActionAt] = useState(lesson.updatedAt);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { courseId, lessonId } = useParams();

  const course = useSelector(currentCourseSelector);

  const [onStatusContextItemClick] = useStatusChange(lesson);

  const handleStatusContextItemClick = (contextItem, values) => {
    if (!checkAnswersValid(values)) {
      showToast({
        data: {
          variant: 'warning',
          title: getTranslation(
            'common.toast.connection_quiz_warning_text.title',
          ),
          text: getTranslation(
            'common.toast.connection_quiz_warning_text.text',
          ),
        },
      });

      return;
    }

    onStatusContextItemClick(contextItem);
  };

  const handleAutoSaveSubmit = async (values) => {
    if (lessonId !== values.id) {
      return;
    }

    const data = convertDataToApi(values);

    const changedPropertiesKeys = getChangedProperties({
      currentLesson: lesson,
      nextLesson: data,
    });

    if (changedPropertiesKeys && changedPropertiesKeys.length > 0) {
      setAutoSaveLoading(true);

      const updatedQuiz = await api.updateConnectionQuizById({
        lessonId,
        data: getChangedPropertiesByKeys({ data, keys: changedPropertiesKeys }),
      });

      setLastActionAt(updatedQuiz.updatedAt);

      setAutoSaveLoading(false);
    }
  };

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    try {
      const data = convertDataToApi(values);

      const changedPropertiesKeys = getChangedProperties({
        currentLesson: lesson,
        nextLesson: data,
      });

      const updatedQuiz = await dispatch(
        updateLessonById({
          lessonId,
          lessonType: lesson.type,
          data,
          changedPropertiesKeys,
        }),
      ).unwrap();

      setLastActionAt(updatedQuiz.updatedAt);

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.lesson_saved.title'),
          text: getTranslation('common.toast.lesson_saved.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const handlePreviewClick = () => {
    navigate(`/courses/${courseId}/lessons/${lessonId}/preview`);
  };

  const lessonReadOnly = lesson.status !== STATUSES.DRAFT;

  return (
    <ConnectionQuizPage
      {...props}
      saveLoading={saveLoading}
      autoSaveLoading={autoSaveLoading}
      readOnly={lessonReadOnly}
      lesson={lesson}
      lastActionAt={lastActionAt}
      courseTitle={course.title || getTranslation('common.text.untitled')}
      courseStatus={course.status}
      onAutoSaveSubmit={handleAutoSaveSubmit}
      onSaveClick={handleSaveClick}
      onPreviewClick={handlePreviewClick}
      onStatusContextItemClick={handleStatusContextItemClick}
    />
  );
};

export default React.memo(ConnectionQuizPageContainer);
