import React from 'react';

import { getFileTypeIcon } from 'helpers/getFileTypeIcon';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import styles from './TaskMaterial.styles.scss';

const TaskMaterial = ({ imageName }) => (
  <div className={styles.material}>
    <div className={styles.inner}>
      <Icon className={styles.icon} name={getFileTypeIcon('')} />
      <Typography
        className={styles.text}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {imageName}
      </Typography>
    </div>
    <Button variant="link" mode="icon" size="sm" iconName="insert" />
  </div>
);

export default React.memo(TaskMaterial);
