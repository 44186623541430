import React from 'react';

import ExpandedResultPreview from './ExpandedResultPreview';

const ExpandedResultPreviewContainer = ({
  onExitPreviewClick,
  ...restProps
}) => {
  const handleExitPreviewClick = () => {
    if (onExitPreviewClick) {
      onExitPreviewClick();
    }
  };

  return (
    <ExpandedResultPreview
      {...restProps}
      onExitPreviewClick={handleExitPreviewClick}
    />
  );
};

export default React.memo(ExpandedResultPreviewContainer);
