import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { IconTextProperty } from '../IconTextProperty';

import styles from './ConstraintsProperties.styles.scss';

const ConstraintsProperties = ({ properties }) => {
  const constraintsProperty = getLayerPropertyByKey({
    key: 'constraints',
    properties,
  });

  if (!constraintsProperty.value) {
    return null;
  }

  const constraintsHorizontal = constraintsProperty.value.horizontal;
  const constraintsVertical = constraintsProperty.value.vertical;

  return (
    <div className={styles.properties}>
      <div className={styles.propertiesRow}>
        <IconTextProperty
          isInitialState
          readOnly
          property={{
            key: 'constraintsHorizontal',
            value: constraintsHorizontal,
          }}
        />
      </div>
      <div className={styles.propertiesRow}>
        <IconTextProperty
          isInitialState
          readOnly
          property={{
            key: 'constraintsVertical',
            value: constraintsVertical,
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(ConstraintsProperties);
