export const getFileTypeIcon = (type) => {
  switch (type) {
    case 'pdf': {
      return 'filePdf';
    }
    case 'image/jpeg':
    case 'image/png': {
      return 'fileImage';
    }
    case 'video/avi':
    case 'video/mov': {
      return 'fileVideo';
    }
  }
};
