import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { isAuthenticatedSelector } from 'store/auth/selectors';

import Sidebar from './Sidebar';

const SidebarContainer = (props) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapseClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sidebar
      {...props}
      collapsed={collapsed}
      isAuthenticated={isAuthenticated}
      onCollapseClick={handleCollapseClick}
    />
  );
};

export default React.memo(SidebarContainer);
