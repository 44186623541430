import React from 'react';
import cn from 'classnames';

import { COURSE_STRUCTURES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { CoursePreviewLayout } from 'components/Layout';

import { Header } from './components/Header';
import { CourseResult } from './components/CourseResult';
import { Modules } from './components/Modules';

import styles from './CoursePreviewPage.styles.scss';

const CoursePreviewPage = ({
  loading,
  course,
  previewType,
  setPreviewType,
}) => (
  <CoursePreviewLayout setPreviewType={setPreviewType}>
    {loading ? (
      <Loading className={styles.loading} />
    ) : (
      <div
        className={cn(styles.page, {
          [styles.pluginPage]:
            previewType === getTranslation('common.text.plugin'),
        })}
      >
        <div
          className={
            previewType === getTranslation('common.text.web') && styles.header
          }
        >
          <Header {...course} previewType={previewType} />
          {course.resultPreview && (
            <CourseResult
              preview={course.resultPreview}
              previewType={previewType}
            />
          )}
        </div>
        {course.structure === COURSE_STRUCTURES.MODULES && (
          <Modules modules={course.modules} />
        )}
      </div>
    )}
  </CoursePreviewLayout>
);

export default React.memo(CoursePreviewPage);
