import { getAutoLayoutDirectionKey } from 'helpers/getAutoLayoutDirectionKey';

export const getAutoLayoutDirectionTooltipValue = (properties) => {
  const {
    value: {
      layoutMode,
      layoutWrap,
      counterAxisAlignItems,
      primaryAxisAlignItems,
    },
  } = properties;

  const layoutDirectionKey = getAutoLayoutDirectionKey({
    layoutMode: layoutMode.value,
    layoutWrap: layoutWrap.value,
    counterAxisAlignItems: counterAxisAlignItems.value,
    primaryAxisAlignItems: primaryAxisAlignItems.value,
  });

  switch (layoutDirectionKey) {
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_LEFT': {
      return 'Align top left';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_CENTER': {
      return 'Align top center';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_TOP_RIGHT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_TOP_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_TOP_RIGHT': {
      return 'Align top right';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_LEFT': {
      return 'Align left';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_CENTER': {
      return 'Align center';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_RIGHT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_RIGHT': {
      return 'Align right';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_LEFT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_LEFT': {
      return 'Align bottom left';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_CENTER':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_CENTER': {
      return 'Align bottom center';
    }
    case 'AUTO_LAYOUT_VERTICAL_ALIGN_BOTTOM_RIGHT':
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BOTTOM_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BOTTOM_RIGHT': {
      return 'Align bottom right';
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_LEFT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_LEFT': {
      return 'Align left baseline';
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_CENTER':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_CENTER': {
      return 'Align center baseline';
    }
    case 'AUTO_LAYOUT_HORIZONTAL_ALIGN_BASELINE_RIGHT':
    case 'AUTO_LAYOUT_WRAP_ALIGN_BASELINE_RIGHT': {
      return 'Align right baseline';
    }
    default: {
      return null;
    }
  }
};
