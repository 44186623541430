import React from 'react';
import MaskedInput from 'react-text-mask';
import cn from 'classnames';
import parse from 'date-fns/parse';

import { DEFAULT_DATE_FORMAT } from 'constants/common';

import { DATE_PIPE, MASK } from './DatePickerInput.constants';

import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';
import { Popover } from 'components/Popover';
import { DatePicker } from 'components/shared/DatePicker';

import styles from './DatePickerInput.styles.scss';

const DatePickerInput = ({
  inputRef,
  value,
  defaultMonth,
  onDaySelect,
  ...restProps
}) => (
  <MaskedInput
    {...restProps}
    keepCharPositions
    pipe={DATE_PIPE}
    mask={MASK}
    guide={false}
    placeholderChar=" "
    value={value}
    render={(ref, props) => (
      <Input
        {...props}
        inputRef={(element) => {
          ref(element);

          if (inputRef) {
            inputRef.current = element;
          }
        }}
      />
    )}
    endAdornment={
      <Popover
        placement="bottom-end"
        mode="controlled"
        xOffset={9}
        yOffset={15}
        triggerElement={({ isMounted }) => (
          <Icon
            className={cn(styles.icon, { [styles.iconActive]: isMounted })}
            name="calendar"
          />
        )}
      >
        <DatePicker
          defaultMonth={
            value ? parse(value, DEFAULT_DATE_FORMAT, new Date()) : new Date()
          }
          selectedDate={
            value ? parse(value, DEFAULT_DATE_FORMAT, new Date()) : null
          }
          onDaySelect={onDaySelect}
        />
      </Popover>
    }
  />
);

export default React.memo(DatePickerInput);
