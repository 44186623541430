import { COURSE_TYPES } from 'constants/common';

export const getLessonPathByCourseType = ({
  courseType,
  courseId,
  lessonId,
}) => {
  switch (courseType) {
    case COURSE_TYPES.COURSE: {
      return `/courses/${courseId}/lessons/${lessonId}`;
    }
    case COURSE_TYPES.TRAINING: {
      return `/trainings/${courseId}/lessons/${lessonId}`;
    }
    case COURSE_TYPES.ARCADE: {
      return `/arcades/${courseId}/lessons/${lessonId}`;
    }
  }
};
