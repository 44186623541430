import { getTranslation } from 'helpers/getTranslation';

export const getMenuItems = () => {
  return [
    {
      key: 'dashboard',
      icon: 'dashboard',
      text: getTranslation('components.sidebar.menu.dashboard'),
      to: '/dashboard',
    },
    {
      key: 'courses',
      icon: 'courses',
      text: getTranslation('components.sidebar.menu.courses'),
      to: '/courses',
    },
    {
      key: 'trainings',
      icon: 'trainings',
      text: getTranslation('components.sidebar.menu.trainings'),
      to: '/trainings',
    },
    {
      key: 'arcades',
      icon: 'arcades',
      text: getTranslation('components.sidebar.menu.arcades'),
      to: '/arcades',
    },
    {
      key: 'employees',
      icon: 'admins',
      text: getTranslation('components.sidebar.menu.admins'),
      to: '/admins',
    },
    {
      key: 'students',
      icon: 'students',
      text: getTranslation('components.sidebar.menu.students'),
      to: '/students',
    },
    {
      key: 'schools',
      icon: 'schools',
      text: getTranslation('components.sidebar.menu.schools'),
      to: '/schools',
    },
    {
      key: 'school',
      icon: 'schools',
      text: getTranslation('components.sidebar.menu.school'),
      to: '/school-profile',
    },
    {
      key: 'inbox',
      icon: 'mail',
      text: getTranslation('components.sidebar.menu.inbox'),
      to: '/inbox',
    },
  ];
};
