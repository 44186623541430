import { createAsyncThunk } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';

import { COURSE_STRUCTURES } from 'constants/common';

import { updateLessonStatusById } from 'store/lesson/actions';

import * as api from 'api/methods';

import * as types from './types';

const _getCourseModules = async (courseId) => {
  const promises = [
    api.getCourseModules(courseId),
    api.getCourseLessons(courseId),
  ];

  const [modules, lessons] = await Promise.all(promises);

  return _orderBy(modules, ['order'], ['asc']).map((module) => ({
    ...module,
    lessons: lessons.filter((lesson) => lesson.moduleId === module.id),
  }));
};

export const getCourseById = createAsyncThunk(
  types.GET_CURRENT_COURSE,
  async (courseId) => {
    const course = await api.getCourseById(courseId);

    if (course.structure === COURSE_STRUCTURES.MODULES) {
      const modules = await _getCourseModules(courseId);

      return {
        ...course,
        modules,
      };
    }

    if (course.structure === COURSE_STRUCTURES.LESSONS) {
      const lessons = await api.getCourseLessons(courseId);

      return { ...course, lessons };
    }

    return course;
  },
);

export const updateCourseById = createAsyncThunk(
  types.UPDATE_COURSE_BY_ID,
  async ({ courseId, data }) => {
    const course = await api.updateCourseById({
      courseId,
      data,
    });

    if (data.structure && data.structure === COURSE_STRUCTURES.MODULES) {
      const modules = await _getCourseModules(courseId);

      return { ...course, modules };
    }

    if (data.structure === COURSE_STRUCTURES.LESSONS) {
      const lessons = await api.getCourseLessons(courseId);

      return { ...course, lessons };
    }

    return course;
  },
);

export const updateCourseStatus = createAsyncThunk(
  types.UPDATE_COURSE_STATUS,
  async ({ courseId, withLessons, status }, { dispatch, getState }) => {
    if (withLessons) {
      const state = getState();

      let lessons = [];

      if (state.currentCourse.structure === COURSE_STRUCTURES.MODULES) {
        lessons = _flatten(
          state.currentCourse.modules.map((module) => module.lessons),
        );
      } else if (state.currentCourse.structure === COURSE_STRUCTURES.LESSONS) {
        lessons = state.currentCourse.lessons;
      }

      const lessonsPromises = lessons.map((lesson) =>
        dispatch(
          updateLessonStatusById({
            lessonId: lesson.id,
            lessonType: lesson.type,
            data: { status },
          }),
        ),
      );

      await Promise.all(lessonsPromises);
    }

    const course = await api.updateCourseById({
      courseId,
      data: { status },
    });

    return course;
  },
);

export const createCourseModule = createAsyncThunk(
  types.CREATE_COURSE_MODULE,
  async ({ courseId, data }) => {
    const module = await api.createCourseModule({
      courseId,
      data,
    });

    return module;
  },
);

export const updateCourseModuleById = createAsyncThunk(
  types.UPDATE_COURSE_MODULE_BY_ID,
  async ({ courseId, moduleId, data }) => {
    const module = await api.updateCourseModuleById({
      courseId,
      moduleId,
      data,
    });

    return module;
  },
);

export const removeCourseModuleById = createAsyncThunk(
  types.REMOVE_COURSE_MODULE_BY_ID,
  async ({ courseId, moduleId }) => {
    const module = await api.removeCourseModuleById({ courseId, moduleId });

    return module;
  },
);

export const reorderCourseLessons = createAsyncThunk(
  types.REORDER_COURSE_LESSONS,
  async ({ courseId, data }) => {
    const lessons = await api.reorderCourseLessons({ courseId, data });

    return { moduleId: data.moduleId, lessons };
  },
);

export const reorderCourseModules = createAsyncThunk(
  types.REORDER_COURSE_MODULES,
  async ({ courseId, data }) => {
    const modules = await api.reorderCourseModules({ courseId, data });

    return modules;
  },
);
