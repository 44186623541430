import React from 'react';
import { useFormikContext, getIn } from 'formik';

import NumberListBlock from './NumberListBlock';

const NumberListBlockContainer = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const handleKeyDown = (event, itemIndex) => {
    if (event.key === 'Enter') {
      const items = [...getIn(values, `${props.namePrefix}.data`)];

      items.splice(itemIndex + 1, 0, '');

      setFieldValue(`${props.namePrefix}.data`, items);
    }

    if (event.key === 'Backspace') {
      const currentItemValue = getIn(
        values,
        `${props.namePrefix}.data[${itemIndex}]`,
      );

      if (!currentItemValue && itemIndex !== 0) {
        const items = [...getIn(values, `${props.namePrefix}.data`)];

        items.splice(itemIndex, 1);

        setFieldValue(`${props.namePrefix}.data`, items);
      }
    }
  };

  return <NumberListBlock {...props} onKeyDown={handleKeyDown} />;
};

export default React.memo(NumberListBlockContainer);
