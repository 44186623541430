import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField, SelectField } from 'components/FormikFields';

import { validationSchema } from './PaymentSettings.formConfig';

import { premiumTypesOptions } from './PaymentSettings.config';

import { convertDataToForm } from './PaymentSettings.helpers';

import styles from './PaymentSettings.styles.scss';

const PaymentSettings = ({
  open,
  saveLoading,
  course,
  onClose,
  onSaveClick,
}) => (
  <Drawer open={open} headerContent="Payment settings" onClose={onClose}>
    <Formik
      enableReinitialize
      initialValues={convertDataToForm(course)}
      validationSchema={validationSchema}
      onSubmit={onSaveClick}
    >
      <Form className={styles.form}>
        <div className={styles.inner}>
          <FormikField
            name="premiumType"
            component={SelectField}
            componentProps={{
              options: premiumTypesOptions,
            }}
            controlProps={{
              required: true,
              label: 'Premuim Type',
            }}
          />
          <FormikField
            name="premiumPrice"
            component={InputField}
            controlProps={{
              label: 'Price',
            }}
          />
          <FormikField
            name="premiumOldPrice"
            component={InputField}
            controlProps={{
              label: 'Price Old (For Marketing)',
            }}
          />
          <FormikField
            name="premiumPriceId"
            component={InputField}
            controlProps={{
              label: 'Stripe Price Id',
            }}
          />
        </div>

        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              saveLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(PaymentSettings);
