import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { USER_ROLES, COURSE_TYPES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { useSearch } from 'hooks/useSearch';
import { useModal } from 'hooks/useModal';

import { getTranslation } from 'helpers/getTranslation';

import {
  getCoursesTableColumns,
  getCoursesTableColumnsRootAdmin,
} from 'pages/CoursesPage/CoursesPage.tableConfig';
import {
  getTrainingsTableColumnsRootAdmin,
  getTrainingsTableColumns,
} from 'pages/TrainingsPage/TrainingsPage.tableConfig';
import {
  getArcadesTableColumnsRootAdmin,
  getArcadesTableColumns,
} from 'pages/ArcadesPage/ArcadesPage.tableConfig';

const getColumnsByType = ({ isRootAdmin, type }) => {
  switch (type) {
    case COURSE_TYPES.COURSE: {
      return isRootAdmin
        ? getCoursesTableColumnsRootAdmin()
        : getCoursesTableColumns();
    }
    case COURSE_TYPES.TRAINING: {
      return isRootAdmin
        ? getTrainingsTableColumnsRootAdmin()
        : getTrainingsTableColumns();
    }
    case COURSE_TYPES.ARCADE: {
      return isRootAdmin
        ? getArcadesTableColumnsRootAdmin()
        : getArcadesTableColumns();
    }
  }
};

const _getRemoveCourseTitleByType = (type) => {
  switch (type) {
    case COURSE_TYPES.COURSE: {
      return getTranslation('hooks.use_courses.remove_course.title');
    }
    case COURSE_TYPES.TRAINING: {
      return getTranslation('hooks.use_courses.remove_training.title');
    }
    case COURSE_TYPES.ARCADE: {
      return getTranslation('hooks.use_courses.remove_arcade.title');
    }
  }
};

const _getRemoveCourseTextByType = (type) => {
  switch (type) {
    case COURSE_TYPES.COURSE: {
      return getTranslation('hooks.use_courses.remove_course.text');
    }
    case COURSE_TYPES.TRAINING: {
      return getTranslation('hooks.use_courses.remove_training.text');
    }
    case COURSE_TYPES.ARCADE: {
      return getTranslation('hooks.use_courses.remove_arcade.text');
    }
  }
};

const DEFAULT_ITEMS_PER_PAGE = 50;

export const useCourses = ({
  getItems,
  removeItemById,
  createItem,
  itemsPerPage,
  type,
  pathPrefix,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const [createOpen, openCreate, closeCreate] = useModal({
    defaultOpen: false,
  });
  const [
    removeConfirmOpen,
    openRemoveConfirm,
    closeRemoveConfirm,
    removeConfirmData,
  ] = useModal({
    defaultOpen: false,
  });
  const [removeOpen, openRemove, closeRemove, removeData] = useModal({
    defaultOpen: false,
  });

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'lastActionAt',
    sortDirection: 'desc',
  });

  const [searchQuery, handleSearchChange] = useSearch();

  useEffect(() => {
    const _getItems = async () => {
      try {
        await dispatch(
          getItems({
            page: 1,
            limit: DEFAULT_ITEMS_PER_PAGE,
            query: '',
            sortBy: sortParams.sortBy,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getItems();
  }, [searchQuery]);

  const handleRowClick = (itemId) => {
    navigate(`${pathPrefix}/${itemId}`);
  };

  const handleEditClick = (item) => {
    navigate(`${pathPrefix}/${item.id}`);
  };

  const handleRemoveClick = (item) => {
    openRemoveConfirm({
      title: _getRemoveCourseTitleByType(item.type),
      text: _getRemoveCourseTextByType(item.type),
      data: {
        item,
      },
    });
  };

  const handleConfirmRemoveClick = ({ item }) => {
    closeRemoveConfirm();

    openRemove({
      requestParams: item.id,
      onRemoved: () => {
        closeRemove();

        dispatch(removeItemById(item.id));
      },
    });
  };

  const handleCreateClick = () => {
    openCreate();
  };

  const handleContextItemClick = async (contextItem) => {
    try {
      const defaultData = {
        type: COURSE_TYPES.COURSE,
        platform: contextItem.value,
        schoolId: user.schoolId,
      };

      const item = await createItem(defaultData);

      navigate(`${pathPrefix}/${item.id}`);
    } catch (error) {
      console.warn(error); // TODO: catch error
    }
  };

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getItems({
          page: 1,
          limit: itemsPerPage,
          sortBy: nextSortBy,
          sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;
  const columns = getColumnsByType({ isRootAdmin, type });

  return {
    loading,
    isRootAdmin,
    createOpen,
    removeOpen,
    removeConfirmOpen,
    sortParams,
    columns,
    removeConfirmData,
    removeData,
    closeCreate,
    closeRemoveConfirm,
    closeRemove,
    onSearchChange: handleSearchChange,
    onSortChange: handleSortChange,
    onContextItemClick: handleContextItemClick,
    onRowClick: handleRowClick,
    onEditClick: handleEditClick,
    onRemoveClick: handleRemoveClick,
    onConfirmRemoveClick: handleConfirmRemoveClick,
    onCreateClick: handleCreateClick,
  };
};
