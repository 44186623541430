import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Difficulty.styles.scss';

const Difficulty = ({ className, value }) => (
  <div
    className={cn(
      styles.difficulty,
      {
        [styles.difficultyEasy]: value === 'EASY',
        [styles.difficultyMedium]: value === 'MEDIUM',
        [styles.difficultyHard]: value === 'HARD',
      },
      className,
    )}
  >
    {value === 'EASY' && <Icon className={styles.icon} name="difficultyEasy" />}
    {value === 'MEDIUM' && (
      <Icon className={styles.icon} name="difficultyMedium" />
    )}
    {value === 'HARD' && <Icon className={styles.icon} name="difficultyHard" />}
  </div>
);

export default React.memo(Difficulty);
