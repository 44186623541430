import _upperFirst from 'lodash/upperFirst';

import { COURSE_PLATFORMS_TEXT } from 'constants/common';

export const getCourseSubtitle = (platform) => {
  if (!platform) {
    return '';
  }

  return COURSE_PLATFORMS_TEXT[platform];
};

export const getCourseType = (type) => {
  return _upperFirst(type.toLowerCase());
};
