import React from 'react';
import { useField } from 'formik';

import { FormControl } from 'components/shared/FormControl';

const FormikField = ({
  withControl,
  name,
  component: Component,
  controlProps,
  componentProps,
}) => {
  const [field, meta, helpers] = useField(
    componentProps?.type === 'checkbox' || componentProps?.type === 'radio'
      ? {
          name,
          type: componentProps.type,
          value: componentProps.value,
        }
      : name,
  );

  const { touched, error } = meta;

  const haveError = touched && !!error;

  if (!withControl) {
    return (
      <Component
        haveError={haveError}
        name={name}
        error={error}
        field={field}
        helpers={helpers}
        {...componentProps}
      />
    );
  }

  return (
    <FormControl haveError={haveError} error={error} {...controlProps}>
      <Component
        haveError={haveError}
        name={name}
        field={field}
        helpers={helpers}
        {...componentProps}
      />
    </FormControl>
  );
};

FormikField.defaultProps = {
  withControl: true,
};

export default React.memo(FormikField);
