import React, { useState } from 'react';

import TimeInput from './TimeInput';

const TimeInputContainer = ({ value, onChange, ...restProps }) => {
  const [currentFormat, setCurrentFormat] = useState('AM');

  const handleTimeFormatChange = (nextFormat) => {
    setCurrentFormat(nextFormat);
  };

  const handleChange = (event) => {
    const nextValue = event.target.value;

    if (onChange) {
      onChange(`${nextValue} ${currentFormat}`);
    }
  };

  const currentValue = value ? value.split(' ')[0] : '';

  return (
    <TimeInput
      {...restProps}
      currentFormat={currentFormat}
      value={currentValue}
      onTimeFormatChange={handleTimeFormatChange}
      onChange={handleChange}
    />
  );
};

export default React.memo(TimeInputContainer);
