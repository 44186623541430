import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { userSelector } from 'store/auth/selectors';

import NotFoundPage from './NotFoundPage';

const NotFoundPageContainer = () => {
  const navigate = useNavigate();

  const user = useSelector(userSelector);

  const handleButtonClick = () => {
    navigate(user ? '/dashboard' : '/');
  };

  return <NotFoundPage onButtonClick={handleButtonClick} />;
};

export default React.memo(NotFoundPageContainer);
