import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { getTranslation } from 'helpers/getTranslation';

import { useModal } from 'hooks/useModal';

import { CONFIRM_MODAL_TITLE, REMOVE_TYPES } from './ModuleSettings.constants';

import { getModuleTitle, getLessonTitle } from './ModuleSettings.helpers';

import {
  createCourseModule,
  removeCourseModuleById,
} from 'store/course/actions';
import { removeLessonById } from 'store/lesson/actions';

import { DefaultModal } from 'components/DefaultModal';

import ModulesSettings from './ModulesSettings';

const ModulesSettingsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { id: courseId } = useParams();

  const { values } = useFormikContext();

  const [
    confirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    confirmModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const handleAddModuleClick = async () => {
    try {
      await dispatch(
        createCourseModule({
          courseId: values.id,
          data: { order: values.modules.length + 1 },
        }),
      ).unwrap();
    } catch (error) {
      console.warn(error);
    }
  };

  const handleRemoveModuleClick = (moduleId) =>
    openConfirmModal({
      type: REMOVE_TYPES.MODULE,
      moduleId,
      title: CONFIRM_MODAL_TITLE[REMOVE_TYPES.MODULE],
      text: getModuleTitle({ moduleId, modules: values.modules }),
    });

  const handleConfirmRemoveClick = async () => {
    setLoading(true);

    if (confirmModalData.type === REMOVE_TYPES.LESSON) {
      await dispatch(
        removeLessonById({ courseId, lessonId: confirmModalData.lessonId }),
      );
    }

    if (confirmModalData.type === REMOVE_TYPES.MODULE) {
      try {
        await dispatch(
          removeCourseModuleById({
            courseId,
            moduleId: confirmModalData.moduleId,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }
    }

    setLoading(false);

    closeConfirmModal();
  };

  const handleEditLessonClick = (lessonId) => {
    navigate(`/courses/${courseId}/lessons/${lessonId}`);
  };

  const handleRemoveLessonClick = (lesson) =>
    openConfirmModal({
      type: REMOVE_TYPES.LESSON,
      lessonId: lesson.id,
      title: CONFIRM_MODAL_TITLE[REMOVE_TYPES.LESSON],
      text: getLessonTitle({ lesson }),
    });

  return (
    <>
      <ModulesSettings
        courseReadOnly={values.readOnly}
        modules={values.modules}
        courseType={values.type}
        onAddModuleClick={handleAddModuleClick}
        onRemoveModuleClick={handleRemoveModuleClick}
        onEditLessonClick={handleEditLessonClick}
        onRemoveLessonClick={handleRemoveLessonClick}
      />
      <DefaultModal
        {...confirmModalData}
        loading={loading}
        open={confirmModalOpen}
        confirmText={getTranslation('course_page.module_settings.confirm_text')}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </>
  );
};

export default React.memo(ModulesSettingsContainer);
