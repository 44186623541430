import React, { useState } from 'react';
import {
  useFloating,
  useInteractions,
  useTransitionStyles,
  useDismiss,
  flip,
  shift,
  useClick,
} from '@floating-ui/react';

import Popover from './Popover';

const PopoverContainer = ({
  placement,
  mode,
  yOffset,
  xOffset,
  ...restProps
}) => {
  const [open, setOpen] = useState(
    mode === 'controlled' ? restProps.open : false,
  );

  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: placement || 'right-end',
    middleware: [flip(), shift()],
  });
  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    open: { opacity: 1, transform: 'translateY(0)' },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { ancestorScroll: true });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handlePopoverMouseDown = () => {
    setOpen(false);
  };

  const styleProps = {
    position: strategy,
    top: y + (yOffset || 0) || 0,
    left: x + (xOffset || 0) || 0,
  };

  return (
    <Popover
      {...restProps}
      isMounted={isMounted}
      refs={refs}
      animationStyles={styles}
      styleProps={styleProps}
      placement={placement}
      mode={mode}
      getReferenceProps={getReferenceProps}
      getFloatingProps={getFloatingProps}
      onPopoverMouseDown={handlePopoverMouseDown}
    />
  );
};

PopoverContainer.defaultProps = {
  mode: 'uncontrolled',
  yOffset: 0,
  xOffset: 0,
};

export default React.memo(PopoverContainer);
