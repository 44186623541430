import { createSlice } from '@reduxjs/toolkit';

import {
  getUserInfo,
  logoutUser,
  setCurrentUser,
  getSchoolById,
} from './actions';

const initialState = {
  user: null,
  school: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentUser, (state, action) => {
      state.user = action.payload;

      if (action.payload) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    });

    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    });

    builder.addCase(logoutUser, () => {
      return initialState;
    });

    builder.addCase(getSchoolById.fulfilled, (state, action) => {
      state.school = action.payload;
    });
  },
});

export default authSlice.reducer;
