import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './EmptyState.styles.scss';

const EmptyState = ({ className, icon, title, text }) => (
  <div className={cn(styles.emptyState, className)}>
    <Icon name={icon} className={styles.icon} />
    <Typography variant="subtitle" weight="semiBold" className={styles.title}>
      {title}
    </Typography>
    <Typography variant="body1" className={styles.text}>
      {text}
    </Typography>
  </div>
);

export default React.memo(EmptyState);
