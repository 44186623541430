import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';
import { Icon } from 'components/shared/Icon';

import { EmptyProperties } from '../EmptyProperties';
import { CategoryCheckbox } from '../CategoryCheckbox';
import { PrototypeProperties } from '../PrototypeProperties';

import styles from './PrototypeSection.styles.scss';

const PrototypeSection = ({
  isInitialState,
  readOnly,
  properties,
  categoryProperties,
  categoryKey,
  onCategoryCheckChanged,
}) => {
  const interactions = properties.find(
    (property) => property.key === 'reactions',
  );

  if (!interactions || !interactions.value.length) {
    return (
      <div className={styles.section}>
        <EmptyProperties />
      </div>
    );
  }

  return (
    <Collapsible
      transitionTime={150}
      triggerTagName="div"
      className={styles.section}
      openedClassName={cn(styles.section, styles.sectionOpened)}
      open
      trigger={({ onTriggerClick }) => {
        return (
          <div className={styles.header}>
            {!isInitialState && (
              <CategoryCheckbox
                className={styles.check}
                readOnly={readOnly}
                categoryKey={categoryKey}
                properties={properties}
                categoryProperties={categoryProperties}
                onChanged={onCategoryCheckChanged}
              />
            )}
            <div className={styles.headerInner} onClick={onTriggerClick}>
              <Typography
                className={styles.title}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                Interactions
              </Typography>
              <Icon className={styles.icon} name="chevronDown" />
            </div>
          </div>
        );
      }}
    >
      <div className={styles.inner}>
        <PrototypeProperties properties={properties} />
      </div>
    </Collapsible>
  );
};

export default React.memo(PrototypeSection);
