import React from 'react';

import { COURSE_TYPES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import {
  EditableTextField,
  UploadField,
  SwitchField,
} from 'components/FormikFields';

import { DifficultySelect } from '../DifficultySelect';
import { FormatSelect } from '../FormatSelect';

import styles from './MainSettings.styles.scss';

const MainSettings = ({ readOnly, type }) => (
  <div className={styles.settings}>
    {type !== COURSE_TYPES.TRAINING && (
      <FormikField
        name="preview"
        component={UploadField}
        componentProps={{
          className: styles.preview,
          readOnly,
          variant: 'preview',
          removeFileTitle: getTranslation(
            'course_page.main_settings.remove_preview_title',
          ),
          removeFileText: getTranslation(
            'course_page.main_settings.remove_preview_text',
          ),
        }}
      />
    )}
    <div className={styles.inner}>
      {type === COURSE_TYPES.ARCADE && (
        <div className={styles.format}>
          <Typography
            className={styles.difficultyTitle}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {getTranslation('course_page.main_settings.type')}
          </Typography>
          <div className={styles.sectionInner}>
            <FormikField
              name="format"
              component={FormatSelect}
              componentProps={{
                readOnly,
              }}
            />
          </div>
        </div>
      )}

      <div className={styles.difficulty}>
        <Typography
          className={styles.difficultyTitle}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {getTranslation('course_page.main_settings.difficulty')}
        </Typography>
        <div className={styles.sectionInner}>
          <FormikField
            name="difficulty"
            component={DifficultySelect}
            componentProps={{
              readOnly,
            }}
          />
        </div>
      </div>

      <FormikField
        name="title"
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          className: styles.title,
          multiline: true,
          variant: 'heading1',
        }}
      />

      <FormikField
        name="description"
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          className: styles.description,
          multiline: true,
          variant: 'text',
          placeholder: getTranslation(
            'course_page.main_settings.description_placeholder',
          ),
        }}
      />
    </div>

    <div className={styles.free}>
      <Typography
        className={styles.freeText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('course_page.main_settings.free_text')}
      </Typography>
      <FormikField
        name="free"
        withControl={false}
        component={SwitchField}
        componentProps={{
          readOnly,
          type: 'checkbox',
        }}
      />
    </div>
  </div>
);

export default React.memo(MainSettings);
