import React from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';

import ProfilePage from './ProfilePage';

const AdminPageContainer = () => {
  const user = useSelector(userSelector);

  return (
    <>
      <ProfilePage user={user} />
    </>
  );
};

export default React.memo(AdminPageContainer);
