import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { Table } from 'components/shared/Table';
import { Typography } from 'components/shared/Typography';

import { PublishStatusTag } from 'components/PublishStatusTag';
import { CoursePreview } from 'components/CoursePreview';

import { getCoursesTableColumns } from './CoursesCard.tableConfig';

import styles from './CoursesCard.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  locale,
) => {
  switch (key) {
    case 'title': {
      return (
        <div
          className={styles.titleCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <CoursePreview className={styles.preview} preview={item.preview} />
          <Typography
            className={styles.title}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {item.title}
          </Typography>
        </div>
      );
    }
    case 'lastActionAt':
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key]
            ? formatDistance(parseISO(item[key]), new Date(), {
                addSuffix: true,
                locale: locale === 'ru' ? ru : enUS,
              })
            : '-'}
        </DefaultCell>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <PublishStatusTag variant={item[key].toLowerCase()} />
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const CoursesCard = ({ locale, courses, onCourseClick }) => (
  <div className={styles.card}>
    <Table
      columns={getCoursesTableColumns()}
      actions={[]}
      data={courses}
      cellRenderer={(props, DefaultCell) =>
        cellRenderer(props, DefaultCell, locale)
      }
      onRowClick={onCourseClick}
    />
  </div>
);

export default React.memo(CoursesCard);
