import { getTranslation } from 'helpers/getTranslation';

export const TABLE_COLUMNS = [
  {
    key: 'student',
    label: getTranslation('dashboard_page.info_by_students.table_keys.student'),
  },
  {
    key: 'status',
    label: getTranslation('dashboard_page.info_by_students.table_keys.status'),
    minWidth: 120,
    maxWidth: 120,
    justifyContent: 'flex-end',
  },
  {
    key: 'courseProgress',
    label: getTranslation(
      'dashboard_page.info_by_students.table_keys.course_progress',
    ),
    minWidth: 120,
    maxWidth: 240,
    justifyContent: 'flex-end',
  },
];
