import React from 'react';
import { useSelector } from 'react-redux';

import { currentStudentSelector } from 'store/student/selectors';

import GrantProAccess from './GrantProAccess';

const GrantProAccessContainer = ({ open, schoolId, onClose, ...restProps }) => {
  const currentStudent = useSelector(currentStudentSelector);

  if (!currentStudent) {
    return null;
  }

  const handleGiveAccessClick = (values) => {
    console.log('Values', values);
    onClose();
  };

  const courses = currentStudent.courses.filter(
    (course) => course.schoolId === schoolId,
  );

  return (
    <GrantProAccess
      open={open}
      courses={courses}
      onClose={onClose}
      currentStudent={currentStudent}
      onGiveAccess={handleGiveAccessClick}
    />
  );
};

export default React.memo(GrantProAccessContainer);
