import _isEqual from 'lodash/isEqual';

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (currentLesson.designType !== nextLesson.designType) {
    properties.push('designType');
  }

  if (!_isEqual(currentLesson.goodToKnowBlocks, nextLesson.goodToKnowBlocks)) {
    properties.push('goodToKnowBlocks');
  }

  if (!_isEqual(currentLesson.initialState, nextLesson.initialState)) {
    properties.push('initialState');
  }

  if (!_isEqual(currentLesson.tasks, nextLesson.tasks)) {
    properties.push('tasks');
  }

  return properties;
};
