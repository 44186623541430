import React, { useState } from 'react';

import { Layer } from '../Layer';

const LayerBranch = ({
  currentLayer,
  layer,
  namePrefix,
  level,
  onLayerClick,
}) => {
  const [open, setOpen] = useState(layer.open || false);

  const hasChildren = layer.children && layer.children.length !== 0;

  const handleLayerArrowClick = (event) => {
    event.stopPropagation();

    setOpen((prevOpen) => !prevOpen);
  };

  const _renderLayerBranches = () => {
    if (hasChildren) {
      const nextLevel = level + 1;

      return layer.children.map((child, childIndex) => (
        <LayerBranch
          key={child.id}
          namePrefix={`${namePrefix}.children[${childIndex}]`}
          layer={child}
          currentLayer={currentLayer}
          level={nextLevel}
          onLayerClick={onLayerClick}
        />
      ));
    }
  };

  return (
    <>
      <Layer
        hasChildren={hasChildren}
        open={open}
        current={currentLayer?.id === layer.id}
        namePrefix={namePrefix}
        layer={layer}
        level={level}
        onArrowClick={handleLayerArrowClick}
        onClick={onLayerClick}
      />
      {open && _renderLayerBranches()}
    </>
  );
};

export default React.memo(LayerBranch);
