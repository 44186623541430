import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getSurveys } from 'store/surveys/actions';
import { surveysSelector } from 'store/surveys/selectors';

import { usePagination } from 'hooks/usePagination';

import { getTranslation } from 'helpers/getTranslation';

import Surveys from './Surveys';

const SurveysContainer = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const {
    data: surveys,
    currentPage,
    totalPages,
    perPage,
  } = useSelector(surveysSelector);

  const [loading, setLoading] = useState(true);

  const [sortParams, setSortParams] = useState({
    sortBy: {
      label: getTranslation('inbox_page.action.date'),
      value: 'createdAt',
    },
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getSurveys = async () => {
      try {
        await dispatch(
          getSurveys({
            page: 1,
            limit: perPage,
            sortBy: sortParams.sortBy.value,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getSurveys();
  }, [sortParams]);

  const handleSortChange = (values) => {
    setSortParams((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction: getSurveys,
    currentPage,
    totalPages,
    perPage,
    sortBy: sortParams.sortBy.value,
    sortDirection: sortParams.sortDirection,
  });

  return (
    <Surveys
      locale={i18n.language}
      loading={loading}
      surveys={surveys}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      sortParams={sortParams}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onSortChange={handleSortChange}
    />
  );
};

export default React.memo(SurveysContainer);
