import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getSchoolCodes = createAsyncThunk(
  types.GET_SCHOOL_CODES,
  async ({ schoolId, params }) => {
    const codes = await api.getSchoolCodes({ schoolId, params });

    return codes;
  },
);

export const generateCodes = createAsyncThunk(
  types.GENERATE_SCHOOL_CODES,
  async (data) => {
    const codes = await api.generateSchoolCodes(data);

    return codes;
  },
);
