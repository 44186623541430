import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { SelectField } from 'components/FormikFields';

import { platformOptions } from './CreateArcade.config';

import { initialValues, getValidationSchema } from './CreateArcade.formConfig';

import styles from './CreateArcade.styles.scss';

const CreateArcade = ({
  open,
  createLoading,
  schoolOptions,
  onClose,
  onCreateClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('arcades_page.create_arcade.title')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onCreateClick}
    >
      <Form className={styles.form}>
        <div className={styles.inner}>
          <FormikField
            name="platform"
            component={SelectField}
            componentProps={{ options: platformOptions() }}
            controlProps={{
              required: true,
              label: getTranslation(
                'arcades_page.create_arcade.platform_label',
              ),
            }}
          />
          <FormikField
            name="school"
            component={SelectField}
            componentProps={{
              options: schoolOptions,
            }}
            controlProps={{
              required: true,
              label: getTranslation('arcades_page.create_arcade.school_label'),
            }}
          />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              createLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.create')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(CreateArcade);
