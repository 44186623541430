import { IMAGE_SIZE, VIDEO_SIZE } from './Upload.constants';

import { getTranslation } from 'helpers/getTranslation';

export const getValidator = (file) => {
  if (file.type.includes('image/')) {
    if (file.size > IMAGE_SIZE) {
      return {
        code: '1',
        message: getTranslation('components.upload.image_validate'),
      };
    }
  }

  if (file.type.includes('video/')) {
    if (file.size > VIDEO_SIZE) {
      return {
        code: '2',
        message: getTranslation('components.upload.video_validate'),
      };
    }
  }

  return null;
};
