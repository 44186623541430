export const convertDataToForm = (data) => ({
  email: data.email,
  firstName: data.firstName,
  lastName: data.lastName,
  isTest: data.isTest,
  isAdmin: data.isAdmin,
});

export const convertDataToApi = (data) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  isTest: data.isTest,
  isAdmin: data.isAdmin,
});
