import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { DragMenu } from 'components/DragMenu';
import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './Column.styles.scss';

const Column = ({
  className,
  readOnly,
  variants,
  namePrefix,
  onAddVariantClick,
  onRemoveVariantClick,
}) => (
  <div className={cn(styles.column, className)}>
    <FormikField
      name={`${namePrefix}Title`}
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        multiline: true,
        className: styles.title,
        variant: 'heading3',
      }}
    />
    {variants && variants.length > 0 && (
      <div className={styles.variants}>
        {variants.map((variant, variantIndex) => (
          <div className={styles.variant} key={variant.id}>
            {!readOnly && (
              <DragMenu
                className={styles.variantMenu}
                withDrag={false}
                onRemoveClick={() => onRemoveVariantClick(variant.id)}
              />
            )}
            <FormikField
              key={variant.id}
              name={`${namePrefix}[${variantIndex}].text`}
              withControl={false}
              component={EditableTextField}
              componentProps={{
                className: readOnly && styles.readOnlyVariant,
                readOnly,
                multiline: true,
                variant: 'text',
                placeholder: getTranslation(
                  'two_columns_lesson_page.column.variant',
                  {
                    variantIndex: variantIndex + 1,
                  },
                ),
              }}
            />
          </div>
        ))}
      </div>
    )}
    {!readOnly && (
      <Button
        className={styles.button}
        size="sm"
        variant="link"
        startIcon={<Icon name="plus" />}
        onClick={onAddVariantClick}
      >
        {getTranslation('common.button.add_variant')}
      </Button>
    )}
  </div>
);

export default React.memo(Column);
