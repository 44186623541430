import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './ReadOnlySection.styles.scss';

const ReadOnlySection = ({ className, text }) => (
  <div className={cn(styles.section, className)}>
    <Icon className={styles.icon} name="warning" />
    <Typography className={styles.text} variant="body1">
      {text}
    </Typography>
  </div>
);

export default React.memo(ReadOnlySection);
