import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Tabs } from 'components/shared/Tabs';
import { Switch } from 'components/shared/Switch';
import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';

import { Header } from 'components/Header';
import { WebPreviewWrapper } from 'components/WebPreviewWrapper';
import { PluginPreviewWrapper } from 'components/PluginPreviewWrapper';

import styles from './LessonPreviewLayout.styles.scss';

const LessonPreviewLayout = ({
  className,
  children,
  size,
  isTheoryLesson,
  isFigmaLesson,
  onExitPreviewClick,
  setTestMode,
  setPreviewType,
  toolbarComponent,
}) => {
  return (
    <div className={cn(styles.layout, className)}>
      <Header />
      <div className={styles.toolbar}>
        <Button
          size="md"
          variant="outlined"
          startIcon={<Icon name="chevronLeft" />}
          onClick={onExitPreviewClick}
        >
          {getTranslation('common.button.exit_preview')}
        </Button>
        <div className={styles.actions}>
          <Typography variant="body2" className={styles.actionText}>
            {getTranslation('common.text.test_mode')}
          </Typography>

          <Switch onChange={(event) => setTestMode(event.target.checked)} />
        </div>
      </div>
      <div className={styles.inner}>
        <PluginPreviewWrapper
          size={size}
          isTheoryLesson={isTheoryLesson}
          toolbarComponent={toolbarComponent}
        >
          {children}
        </PluginPreviewWrapper>
        {/* <Tabs
          className={styles.tabs}
          innerClassName={styles.tabInner}
          setPreviewType={setPreviewType}
          textClassName={styles.tabsText}
          onTabChange={setPreviewType}
          defaultActiveTab={
            isFigmaLesson && getTranslation('common.text.plugin')
          }
          startActions={[
            {
              component: (
                <Button
                  size="md"
                  variant="outlined"
                  startIcon={<Icon name="chevronLeft" />}
                  onClick={onExitPreviewClick}
                >
                  {getTranslation('common.button.exit_preview')}
                </Button>
              ),
            },
          ]}
          actions={[
            {
              component: (
                <div className={styles.actions}>
                  <Typography variant="body2" className={styles.actionText}>
                    {getTranslation('common.text.test_mode')}
                  </Typography>

                  <Switch
                    onChange={(event) => setTestMode(event.target.checked)}
                  />
                </div>
              ),
            },
          ]}
          items={[
            {
              label: getTranslation('common.text.web'),
              disabled: isFigmaLesson,
              content: (
                <WebPreviewWrapper isTheoryLesson={isTheoryLesson}>
                  {children}
                </WebPreviewWrapper>
              ),
            },
            {
              label: getTranslation('common.text.plugin'),
              content: (
                <PluginPreviewWrapper
                  size={size}
                  isTheoryLesson={isTheoryLesson}
                  toolbarComponent={toolbarComponent}
                >
                  {children}
                </PluginPreviewWrapper>
              ),
            },
          ]}
        /> */}
      </div>
    </div>
  );
};

export default React.memo(LessonPreviewLayout);
