import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';
import { getPropertyTooltipValue } from 'helpers/getPropertyTooltipValue';

import { Typography } from 'components/shared/Typography';

import { ColorProperty } from '../ColorProperty';
import { TextTextProperty } from '../TextTextProperty';
import { IconTextProperty } from '../IconTextProperty';
import { StrokeWeightProperty } from '../StrokeWeightProperty';
import { TextIconProperty } from '../TextIconProperty';
import { EmptyProperties } from '../EmptyProperties';

import { getStrokeValue } from './StrokeProperties.helpers';

import styles from './StrokeProperties.styles.scss';

const StrokeProperties = ({
  isInitialState,
  readOnly,
  designType,
  namePrefix,
  properties,
  figmaFileImages,
  onPropertyChanged,
}) => {
  const strokes = getLayerPropertyByKey({ key: 'strokes', properties });

  if (!strokes || !strokes.value || strokes.value.length === 0) {
    return <EmptyProperties />;
  }

  const strokeDashes = getLayerPropertyByKey({
    key: 'dashPattern',
    properties,
  });
  const strokeCap = getLayerPropertyByKey({
    key: 'strokeCap',
    properties,
  });
  const strokeMiterAngle = getLayerPropertyByKey({
    key: 'strokeMiterAngle',
    properties,
  });

  const strokeJoin = getLayerPropertyByKey({
    key: 'strokeJoin',
    properties,
  });

  return (
    <div className={styles.root}>
      {strokes.value.map((stroke, strokeIndex) => (
        <div className={styles.item} key={strokeIndex}>
          <Typography
            className={styles.itemTitle}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            Stroke {strokeIndex + 1}
          </Typography>
          <div className={styles.itemInner}>
            <ColorProperty
              isInitialState
              readOnly
              property={{
                key: 'strokeColor',
                valueType: stroke.type,
                value: getStrokeValue({ stroke, figmaFileImages }),
              }}
            />
            <TextTextProperty
              isInitialState
              readOnly
              property={{
                key: 'strokeOpacity',
                value: stroke.opacity,
              }}
            />
            <IconTextProperty
              isInitialState
              readOnly
              property={{
                key: 'strokeBlendMode',
                value: stroke.blendMode,
              }}
            />
            {stroke.type === 'IMAGE' && (
              <>
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeScaleType',
                    value: stroke.scaleMode,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeScalingFactor',
                    value: stroke.scalingFactor,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeRotation',
                    value: stroke.rotation,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeExposure',
                    value: stroke.filters.exposure,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeContrast',
                    value: stroke.filters.contrast,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeSaturation',
                    value: stroke.filters.saturation,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeTemperature',
                    value: stroke.filters.temperature,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeTint',
                    value: stroke.filters.tint,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeHighlights',
                    value: stroke.filters.highlights,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'strokeShadows',
                    value: stroke.filters.shadows,
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}
      <div className={styles.commonProperties}>
        <TextTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'strokeAlign',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'strokeAlign',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <StrokeWeightProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'strokeWeight',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'strokeWeight',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        <TextTextProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'strokeStyle',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'strokeStyle',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
        {strokeDashes && strokeDashes.value.length > 0 && (
          <TextTextProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'dashPattern',
              properties,
              namePrefix,
            })}.check`}
            property={strokeDashes}
            onChanged={onPropertyChanged}
          />
        )}
        {strokeCap && strokeCap.value && (
          <TextIconProperty
            isInitialState={isInitialState}
            readOnly={readOnly}
            tooltip={getPropertyTooltipValue({
              key: 'strokeCap',
              properties,
            })}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'strokeCap',
              properties,
              namePrefix,
            })}.check`}
            property={strokeCap}
            onChanged={onPropertyChanged}
          />
        )}
        <TextIconProperty
          isInitialState={isInitialState}
          readOnly={readOnly}
          tooltip={getPropertyTooltipValue({
            key: 'strokeJoin',
            properties,
          })}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'strokeJoin',
            properties,
            namePrefix,
          })}.check`}
          property={strokeJoin}
          onChanged={onPropertyChanged}
        />
        {strokeJoin &&
          strokeJoin.value === 'MITER' &&
          strokeMiterAngle &&
          strokeMiterAngle.value && (
            <TextTextProperty
              isInitialState={isInitialState}
              readOnly={readOnly}
              designType={designType}
              namePrefix={`${getPropertyNamePrefix({
                propertyKey: 'strokeMiterAngle',
                properties,
                namePrefix,
              })}.check`}
              property={strokeMiterAngle}
              onChanged={onPropertyChanged}
            />
          )}
      </div>
    </div>
  );
};

export default React.memo(StrokeProperties);
