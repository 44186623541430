import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { getMenuItems } from './Menu.config';

import styles from './Menu.styles.scss';

const Menu = ({ className, collapsed }) => {
  const user = useSelector(userSelector);

  const items = getMenuItems().filter((menuItem) => {
    if (menuItem.key === 'schools' && user.role !== USER_ROLES.ROOT_ADMIN) {
      return false;
    }

    if (menuItem.key === 'school' && user.role === USER_ROLES.ROOT_ADMIN) {
      return false;
    }

    if (menuItem.key === 'dashboard' && user.role !== USER_ROLES.ROOT_ADMIN) {
      return false;
    }

    return true;
  });

  return (
    <div className={cn(styles.menu, className)}>
      {items.map((menuItem) => (
        <NavLink
          className={({ isActive }) =>
            isActive ? cn(styles.item, styles.itemActive) : styles.item
          }
          key={menuItem.text}
          to={menuItem.to}
        >
          <Icon className={styles.icon} name={menuItem.icon} />
          {!collapsed && (
            <Typography
              className={styles.text}
              variant="body1"
              weight="medium"
              mode="compact"
            >
              {menuItem.text}
            </Typography>
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default React.memo(Menu);
