import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './CountPercentCell.styles.scss';

const CountPercentCell = ({
  key,
  count,
  percent,
  minWidth,
  maxWidth,
  justifyContent,
  Component,
}) => (
  <Component
    key={key}
    minWidth={minWidth}
    maxWidth={maxWidth}
    justifyContent={justifyContent}
  >
    <Typography
      className={styles.leftText}
      component="span"
      variant="body3"
      mode="compact"
    >
      {count}
    </Typography>

    {percent !== undefined && (
      <Typography
        className={styles.rightText}
        component="span"
        variant="body3"
        mode="compact"
      >
        {percent}%
      </Typography>
    )}
  </Component>
);

export default React.memo(CountPercentCell);
