import { getTranslation } from 'helpers/getTranslation';

export const getCodeStatusVariant = (status) => {
  switch (status) {
    case 'NOT_ACTIVE': {
      return 'default';
    }
    case 'ACTIVE': {
      return 'success';
    }
    case 'EXPIRED': {
      return 'error';
    }
  }
};

export const getCodeStatusText = (status) => {
  switch (status) {
    case 'NOT_ACTIVE': {
      return getTranslation('components.school_codes_table.not_active');
    }
    case 'ACTIVE': {
      return getTranslation('components.school_codes_table.active');
    }
    case 'EXPIRED': {
      return getTranslation('components.school_codes_table.expired');
    }
  }
};
