import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';

import DifferenceLessonPreviewPage from './DifferenceLessonPreviewPage';

const DifferenceLessonPreviewPageContainer = () => {
  const lesson = useSelector(currentLessonSelector);

  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentCompareAnswer, setCurrentCompareAnswer] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  const handleAnswerClick = ({ answer, compareAnswer }) => {
    setCurrentAnswer(answer);
    setCurrentCompareAnswer(compareAnswer);
    setSubmitted(true);
  };

  const handleRestartLessonClick = () => {
    setCurrentAnswer(null);
    setCurrentCompareAnswer(null);
    setSubmitted(false);
  };

  return (
    <DifferenceLessonPreviewPage
      submitted={submitted}
      lesson={lesson}
      currentAnswer={currentAnswer}
      currentCompareAnswer={currentCompareAnswer}
      onAnswerClick={handleAnswerClick}
      onRestartLessonClick={handleRestartLessonClick}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
    />
  );
};

export default React.memo(DifferenceLessonPreviewPageContainer);
