export const getComponentPropertyDefinitions = (layer) => {
  if (!layer.componentPropertyDefinitions) {
    return [];
  }

  const properties = [];

  for (const [propertyKey, property] of Object.entries(
    layer.componentPropertyDefinitions,
  )) {
    properties.push({
      key: propertyKey.split('#')[0],
      defaultValue: property.defaultValue,
      type: property.type,
      variantOptions: property.variantOptions,
    });
  }

  return properties;
};
