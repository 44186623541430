import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { getStatusText } from 'helpers/getStatusText';
import { formatDate } from 'helpers/formatDate';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { StatusTag } from 'components/StatusTag';

import styles from './UserCard.styles.scss';

const UserCard = ({
  withStatus,
  avatar,
  name,
  email,
  status,
  createdAt,
  locale,
  onCopyEmailClick,
  onEditClick,
}) => (
  <div className={styles.card}>
    <div className={styles.inner}>
      <Avatar url={avatar} text={name || email} />
      <div className={styles.main}>
        <Typography
          className={styles.name}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {name || '-'}
        </Typography>
        {email && (
          <div className={styles.emailInfo}>
            <Typography className={styles.email} variant="body3" mode="compact">
              {email}
            </Typography>
            <Icon
              className={styles.copyIcon}
              name="copy"
              onClick={onCopyEmailClick}
            />
          </div>
        )}
        {withStatus && (
          <StatusTag
            className={styles.status}
            variant={getUserStatusVariant(status)}
          >
            {getStatusText(status)}
          </StatusTag>
        )}
      </div>
    </div>
    <div className={styles.info}>
      <Typography className={styles.createdAt} variant="body3" mode="compact">
        {getTranslation('components.user_card.registration_date')}{' '}
        {formatDate(createdAt, 'dd MMM, yyyy', locale)}
      </Typography>
      <div className={styles.actions}>
        <Button
          className={styles.action}
          startIcon={<Icon name="edit" />}
          size="md"
          variant="outlined"
          onClick={onEditClick}
        >
          {getTranslation('common.button.edit')}
        </Button>
      </div>
    </div>
  </div>
);

UserCard.defaultProps = {
  withStatus: true,
};

export default React.memo(UserCard);
