import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getLessonRatings } from 'store/lesson-ratings/actions';
import { lessonRatingsSelector } from 'store/lesson-ratings/selectors';

import { usePagination } from 'hooks/usePagination';

import { getTranslation } from 'helpers/getTranslation';

import LessonRatings from './LessonRatings';

const LessonRatingsContainer = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const {
    data: lessonsRatings,
    currentPage,
    totalPages,
    perPage,
  } = useSelector(lessonRatingsSelector);

  const [loading, setLoading] = useState(true);

  const [sortParams, setSortParams] = useState({
    sortBy: {
      label: getTranslation('inbox_page.action.date'),
      value: 'createdAt',
    },
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getLessonsRatings = async () => {
      try {
        await dispatch(
          getLessonRatings({
            page: 1,
            limit: perPage,
            sortBy: sortParams.sortBy.value,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getLessonsRatings();
  }, [sortParams]);

  const handleSortChange = (values) => {
    setSortParams((prevState) => ({
      ...prevState,
      ...values,
    }));
  };

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction: getLessonRatings,
    currentPage,
    totalPages,
    perPage,
    sortBy: sortParams.sortBy.value,
    sortDirection: sortParams.sortDirection,
  });

  return (
    <LessonRatings
      locale={i18n.language}
      loading={loading}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      sortParams={sortParams}
      lessonsRatings={lessonsRatings}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onSortChange={handleSortChange}
    />
  );
};

export default React.memo(LessonRatingsContainer);
