import React from 'react';
import { v4 as uuid } from 'uuid';

import TasksSection from './TasksSection';
import { useFormikContext } from 'formik';

const TasksSectionContainer = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddTaskClick = () => {
    const currentTasks = values.tasks;

    setFieldValue('tasks', [
      ...currentTasks,
      {
        id: uuid(),
        name: `Task ${currentTasks.length + 1}`,
        text: '',
        theoryBlocks: [],
        hintsBlocks: [],
        materials: [],
        layers: [], // TODO
      },
    ]);
  };

  return <TasksSection {...props} onAddTaskClick={handleAddTaskClick} />;
};

export default React.memo(TasksSectionContainer);
