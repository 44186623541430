export const getLineHeightUnit = (style) => {
  switch (style.lineHeightUnit) {
    case 'FONT_SIZE_%': {
      return 'PERCENT';
    }
    case 'INTRINSIC_%': {
      return 'AUTO';
    }
    case 'PIXELS': {
      return 'PIXELS';
    }
  }
};
