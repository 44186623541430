import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useMountTransition } from './Drawer.hooks';

import Drawer from './Drawer';

const _createPortalRoot = () => {
  const drawerRoot = document.createElement('div');

  drawerRoot.setAttribute('id', 'drawer-root');

  return drawerRoot;
};

const DrawerContainer = ({ open, removeWhenClosed, onClose, ...restProps }) => {
  const bodyRef = useRef(document.querySelector('body'));
  const portalRootRef = useRef(
    document.getElementById('drawer-root') || _createPortalRoot(),
  );
  const isTransitioning = useMountTransition(open, 300);

  useEffect(() => {
    bodyRef.current.appendChild(portalRootRef.current);

    const portal = portalRootRef.current;
    const bodyElement = bodyRef.current;

    return () => {
      portal.remove();

      bodyElement.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const _updatePageScroll = () => {
      if (open) {
        bodyRef.current.style.overflow = 'hidden';
      } else {
        bodyRef.current.style.overflow = '';
      }
    };

    _updatePageScroll();
  }, [open]);

  useEffect(() => {
    const onKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (open) {
      window.addEventListener('keyup', onKeyPress);
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress);
    };
  }, [open, onClose]);

  if (!isTransitioning && removeWhenClosed && !open) {
    return null;
  }

  return createPortal(
    <Drawer
      {...restProps}
      open={open}
      isTransitioning={isTransitioning}
      onClose={onClose}
    />,
    portalRootRef.current,
  );
};

DrawerContainer.defaultProps = {
  position: 'right',
  removeWhenClosed: true,
};

export default React.memo(DrawerContainer);
