import React from 'react';
import cn from 'classnames';

import { Select } from 'components/shared/Select';
import { Typography } from 'components/shared/Typography';

import styles from './CourseSelect.styles.scss';

const CourseSelect = ({ className, options, value, onChange }) => (
  <div className={cn(styles.select, className)}>
    <Typography className={styles.label}>Course</Typography>
    <Select
      className={styles.select}
      value={value}
      options={options}
      onChange={onChange}
    />
  </div>
);

export default React.memo(CourseSelect);
