import React, { useState } from 'react';

import CurrentAnswer from './CurrentAnswer';

const CurrentAnswerContainer = ({
  answer,
  compareAnswer,
  previewClassName,
}) => {
  const [currentAnswer, setCurrentAnswer] = useState(answer);

  const handleImageMouseDown = () => {
    setCurrentAnswer({ ...answer, url: compareAnswer.url });
  };

  const handleImageMouseUp = () => {
    setCurrentAnswer(answer);
  };

  return (
    <CurrentAnswer
      {...currentAnswer}
      onImageMouseDown={handleImageMouseDown}
      onImageMouseUp={handleImageMouseUp}
      previewClassName={previewClassName}
    />
  );
};

export default React.memo(CurrentAnswerContainer);
