import React from 'react';
import { useNavigate } from 'react-router-dom';

import StudentInfo from './StudentInfo';

const StudentInfoContainer = ({ data }) => {
  const navigate = useNavigate();

  const handleStudentClick = (studentId) => {
    navigate(`/students/${studentId}`);
  };

  return <StudentInfo data={data} onStudentClick={handleStudentClick} />;
};

export default React.memo(StudentInfoContainer);
