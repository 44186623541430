import axios from 'axios';

export const createDifferenceLesson = (data) =>
  axios.post('/api/v1/difference-lessons', data);

export const updateDifferenceLessonById = ({ lessonId, data }) =>
  axios.put(`/api/v1/difference-lessons/${lessonId}`, data);

export const removeDifferenceLessonById = (lessonId) =>
  axios.delete(`/api/v1/difference-lessons/${lessonId}`);
