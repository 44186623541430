import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';
import { currentCourseSelector } from 'store/course/selectors';

import { formatTasks } from './helpers/formatTasks';
import { getLessonsCount } from './FigmaLessonPreviewPage.helpers';

import FigmaLessonPreviewPage from './FigmaLessonPreviewPage';

const FigmaLessonPreviewPageContainer = () => {
  const lesson = useSelector(currentLessonSelector);
  const course = useSelector(currentCourseSelector);

  const [currentTasks] = useState(formatTasks(lesson.tasks));
  const [currentTaskIndex] = useState(0);
  const [previewExpanded, setPreviewExpanded] = useState(false);
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  const handleExpandClick = () => {
    setPreviewExpanded(true);
  };

  const handleExitPreviewClick = () => {
    setPreviewExpanded(false);
  };

  const completed = currentTasks.every((task) => task.completed);

  return (
    <>
      <FigmaLessonPreviewPage
        completed={completed}
        previewExpanded={previewExpanded}
        currentTasks={currentTasks}
        currentTaskIndex={currentTaskIndex}
        lessonTitle={lesson.title}
        currentLesson={getLessonsCount(course, lesson).lessonIndex}
        lessonsCount={getLessonsCount(course, lesson).totalLessons}
        resultPreview={lesson.resultPreview}
        goodToKnowBlocks={lesson.goodToKnowBlocks}
        onExpandClick={handleExpandClick}
        onExitPreviewClick={handleExitPreviewClick}
        previewType={previewType}
        setPreviewType={setPreviewType}
        isTestMode={isTestMode}
        setTestMode={setTestMode}
      />
    </>
  );
};

export default React.memo(FigmaLessonPreviewPageContainer);
