import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './FigmaFrame.styles.scss';

const FigmaFrame = ({ className, correct, preview, name }) => (
  <div className={cn(styles.frame, className)}>
    <div
      className={cn(styles.preview, {
        [styles.previewValid]: correct,
        [styles.previewInvalid]: !correct,
      })}
    >
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} name={correct ? 'check' : 'crossMini'} />
      </div>
      <img className={styles.previewImg} src={preview} />
    </div>
    <div className={styles.inner}>
      <Typography
        className={styles.name}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {name}
      </Typography>
    </div>
  </div>
);

export default React.memo(FigmaFrame);
