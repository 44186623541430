import { COURSE_PLATFORMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const platformOptions = [
  {
    label: getTranslation('courses_page.config.plugin_label'),
    value: COURSE_PLATFORMS.FIGMA_PLUGIN,
  },
  {
    label: getTranslation('courses_page.config.web_label'),
    value: COURSE_PLATFORMS.WEB,
  },
];
