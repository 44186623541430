import React, { useState } from 'react';

import Tabs from './Tabs';

const TabsContainer = ({
  items,
  defaultActiveTab,
  onTabChange,
  ...restProps
}) => {
  const [activeTab, setActiveTab] = useState(
    defaultActiveTab || items[0].label,
  );

  const handleTabClick = (nextTab) => {
    setActiveTab(nextTab);

    if (onTabChange) {
      onTabChange(nextTab);
    }
  };

  return (
    <Tabs
      {...restProps}
      items={items}
      activeTab={activeTab}
      onTabClick={handleTabClick}
    />
  );
};

export default React.memo(TabsContainer);
