import axios from 'axios';
import qs from 'qs';

// ------------------- COURSE API ------------------- //
export const createTraining = (data) => axios.post('/api/v1/trainings', data);

export const updateTrainingById = (
  { trainingId, data }, // NEW
) => axios.put(`/api/v1/trainings/${trainingId}`, data);

export const submitTraining = (trainingId) =>
  axios.post(`/api/v1/trainings/${trainingId}/submit`);

export const publishTraining = (trainingId) =>
  axios.post(`/api/v1/trainings/${trainingId}/publish`);

export const archiveTraining = (trainingId) =>
  axios.post(`/api/v1/trainings/${trainingId}/archive`);

export const restoreTraining = (trainingId) =>
  axios.post(`/api/v1/trainings/${trainingId}/restore`);

export const removeTraining = (trainingId) =>
  axios.delete(`/api/v1/trainings/${trainingId}`);

export const getTrainings = (params) =>
  axios.get(`/api/v1/trainings?${qs.stringify(params)}`);

export const getTrainingById = (trainingId) =>
  axios.get(`/api/v1/trainings/${trainingId}`);

export const getTrainingsStats = () => axios.get('/api/v1/trainings/stats');

export const getTrainingStats = (trainingId) =>
  axios.get(`/api/v1/trainings/${trainingId}/stats`);

export const getTrainingStatsByStudent = ({ trainingId, studentId }) =>
  axios.get(`/api/v1/trainings/${trainingId}/stats/${studentId}`);

// ------------------- COURSE API ------------------- //

// ------------------- COURSE MODULES API ------------------- //

export const getTrainingModules = (trainingId) =>
  axios.get(`/api/v1/trainings/${trainingId}/modules`);

export const createTrainingModule = ({ trainingId, data }) =>
  axios.post(`/api/v1/trainings/${trainingId}/modules`, data);

export const updateTrainingModuleById = ({ trainingId, moduleId, data }) =>
  axios.put(`/api/v1/trainings/${trainingId}/modules/${moduleId}`, data);

export const removeTrainingModuleById = ({ trainingId, moduleId }) =>
  axios.delete(`/api/v1/trainings/${trainingId}/modules/${moduleId}`);

export const reorderTrainingModules = ({ trainingId, data }) =>
  axios.post(`/api/v1/trainings/${trainingId}/modules/reorder`, data);

// ------------------- COURSE MODULES API ------------------- //

// ------------------- COURSE LESSONS API ------------------- //

export const getTrainingLessons = (trainingId) =>
  axios.get(`/api/v1/trainings/${trainingId}/lessons`);

export const reorderTrainingLessons = ({ trainingId, data }) =>
  axios.post(`/api/v1/trainings/${trainingId}/lessons/reorder`, data);

export const getTrainingLessonById = ({ trainingId, lessonId }) =>
  axios.get(`/api/v1/trainings/${trainingId}/lessons/${lessonId}`);

export const removeTrainingLessonById = ({ trainingId, lessonId }) =>
  axios.delete(`/api/v1/trainings/${trainingId}/lessons/${lessonId}`);
