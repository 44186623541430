import { createAsyncThunk } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import _flatten from 'lodash/flatten';

import { TRAINING_STRUCTURES } from 'constants/common';

import { updateLessonStatusById } from 'store/lesson/actions';

import * as api from 'api/methods';

import * as types from './types';

const _getTrainingModules = async (trainingId) => {
  const promises = [
    api.getTrainingModules(trainingId),
    api.getTrainingLessons(trainingId),
  ];

  const [modules, lessons] = await Promise.all(promises);

  return _orderBy(modules, ['order'], ['asc']).map((module) => ({
    ...module,
    lessons: lessons.filter((lesson) => lesson.moduleId === module.id),
  }));
};

export const getTrainingById = createAsyncThunk(
  types.GET_CURRENT_TRAINING,
  async (trainingId) => {
    const training = await api.getTrainingById(trainingId);

    if (training.structure === TRAINING_STRUCTURES.MODULES) {
      const modules = await _getTrainingModules(trainingId);

      return {
        ...training,
        modules,
      };
    }

    if (training.structure === TRAINING_STRUCTURES.LESSONS) {
      const lessons = await api.getTrainingLessons(trainingId);

      return { ...training, lessons };
    }

    return training;
  },
);

export const updateTrainingById = createAsyncThunk(
  types.UPDATE_TRAINING_BY_ID,
  async ({ trainingId, data }) => {
    const training = await api.updateTrainingById({
      trainingId,
      data,
    });

    if (data.structure && data.structure === TRAINING_STRUCTURES.MODULES) {
      const modules = await _getTrainingModules(trainingId);

      return { ...training, modules };
    }

    if (data.structure === TRAINING_STRUCTURES.LESSONS) {
      const lessons = await api.getTrainingLessons(trainingId);

      return { ...training, lessons };
    }

    return training;
  },
);

export const updateTrainingStatus = createAsyncThunk(
  types.UPDATE_TRAINING_STATUS,
  async ({ trainingId, withLessons, status }, { dispatch, getState }) => {
    if (withLessons) {
      const state = getState();

      let lessons = [];

      if (state.currentTraining.structure === TRAINING_STRUCTURES.MODULES) {
        lessons = _flatten(
          state.currentTraining.modules.map((module) => module.lessons),
        );
      } else if (
        state.currentTraining.structure === TRAINING_STRUCTURES.LESSONS
      ) {
        lessons = state.currentTraining.lessons;
      }

      const lessonsPromises = lessons.map((lesson) =>
        dispatch(
          updateLessonStatusById({
            lessonId: lesson.id,
            lessonType: lesson.type,
            data: { status },
          }),
        ),
      );

      await Promise.all(lessonsPromises);
    }

    const training = await api.updateTrainingById({
      trainingId,
      data: { status },
    });

    return training;
  },
);

export const createTrainingModule = createAsyncThunk(
  types.CREATE_TRAINING_MODULE,
  async ({ trainingId, data }) => {
    const module = await api.createTrainingModule({
      trainingId,
      data,
    });

    return module;
  },
);

export const updateTrainingModuleById = createAsyncThunk(
  types.UPDATE_TRAINING_MODULE_BY_ID,
  async ({ trainingId, moduleId, data }) => {
    const module = await api.updateTrainingModuleById({
      trainingId,
      moduleId,
      data,
    });

    return module;
  },
);

export const removeTrainingModuleById = createAsyncThunk(
  types.REMOVE_TRAINING_MODULE_BY_ID,
  async ({ trainingId, moduleId }) => {
    const module = await api.removeTrainingModuleById({ trainingId, moduleId });

    return module;
  },
);

export const reorderTrainingLessons = createAsyncThunk(
  types.REORDER_TRAINING_LESSONS,
  async ({ trainingId, data }) => {
    const lessons = await api.reorderTrainingLessons({ trainingId, data });

    return { moduleId: data.moduleId, lessons };
  },
);

export const reorderTrainingModules = createAsyncThunk(
  types.REORDER_TRAINING_MODULES,
  async ({ trainingId, data }) => {
    const modules = await api.reorderTrainingModules({ trainingId, data });

    return modules;
  },
);
