import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import styles from './DifficultySelect.styles.scss';

const DIFFICULTIES_OPTIONS = () => [
  {
    iconName: 'difficultyEasy',
    iconColor: '#34CB73',
    text: getTranslation('course_page.difficulty_select.easy'),
    value: 'EASY',
  },
  {
    iconName: 'difficultyMedium',
    iconColor: '#FCAE2F',
    text: getTranslation('course_page.difficulty_select.medium'),
    value: 'MEDIUM',
  },
  {
    iconName: 'difficultyHard',
    iconColor: '#F53149',
    text: getTranslation('course_page.difficulty_select.hard'),
    value: 'HARD',
  },
];

const DifficultySelect = ({ readOnly, value, onItemClick }) => (
  <div className={cn(styles.select, { [styles.selectDisabled]: readOnly })}>
    {DIFFICULTIES_OPTIONS().map((option) => (
      <div
        className={cn(styles.item, {
          [styles.itemActive]: value === option.value,
        })}
        key={option.value}
        onClick={() => onItemClick(option.value)}
      >
        <Icon
          className={styles.icon}
          name={option.iconName}
          style={{ color: option.iconColor }}
        />
        <Typography
          className={styles.text}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {option.text}
        </Typography>
      </div>
    ))}
  </div>
);

export default React.memo(DifficultySelect);
