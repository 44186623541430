import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './CompletedIcon.styles.scss';

const CompletedIcon = ({ className, iconClassName }) => (
  <div className={cn(styles.wrapper, className)}>
    <Icon className={cn(styles.icon, iconClassName)} name="checkMini" />
  </div>
);

export default React.memo(CompletedIcon);
