export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  type: data.type,
  title: data.title || '',
  status: data.status,
  free: data.free,
  text: data.text,
  imageUrl: data.imageUrl,
  color: data.color || '',
  readOnly,
  statusError: false,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  free: data.free,
  imageUrl: data.imageUrl,
  color: data.color,
  text: data.text,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (currentLesson.text !== nextLesson.text) {
    properties.push('text');
  }

  if (currentLesson.imageUrl !== nextLesson.imageUrl) {
    properties.push('imageUrl');
  }

  if (currentLesson.color !== nextLesson.color) {
    properties.push('color');
  }

  return properties;
};
