import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { Module } from '../Module';

import { prepareModule } from './Modules.helpers';

import styles from './Modules.styles.scss';

const Modules = ({ modules }) => (
  <div className={styles.modules}>
    <Typography className={styles.title} variant="subtitle">
      {getTranslation('course_preview_page.course_modules')}
    </Typography>

    <div className={styles.inner}>
      {modules.map((module) => (
        <Module key={module.id} {...prepareModule(module)} />
      ))}
    </div>
  </div>
);

export default React.memo(Modules);
