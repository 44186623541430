import React from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { InputField, SelectField, SwitchField } from 'components/FormikFields';
import { FormikField } from 'components/FormikField';
import { CoursesAccessList } from 'components/CoursesAccessList';
import { EmptyState } from 'components/EmptyState';

import { getValidationSchema, getInitialValues } from './AddStudent.formConfig';

import styles from './AddStudent.styles.scss';

const AddStudent = ({
  open,
  inviteLoading,
  coursesLoading,
  schoolsLoading,
  isRootAdmin,
  isSchool,
  currentCourses,
  schools,
  schoolOptions,
  currentSchoolId,
  onClose,
  onSchoolChanged,
  onInviteStudentClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('components.add_student.header_text')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={getInitialValues({
        isSchool,
        courses: currentCourses,
        schools,
        currentSchoolId,
      })}
      validationSchema={getValidationSchema({ isRootAdmin, isSchool })}
      onSubmit={onInviteStudentClick}
    >
      {({ values, setFieldValue }) => (
        <Form className={styles.form}>
          <div className={styles.inner}>
            <FormikField
              name="email"
              component={InputField}
              controlProps={{
                required: true,
                label: getTranslation('components.add_student.email'),
              }}
            />
            <FormikField
              name="firstName"
              component={InputField}
              controlProps={{
                label: getTranslation('components.add_student.first_name'),
              }}
            />
            <FormikField
              name="lastName"
              component={InputField}
              controlProps={{
                label: getTranslation('components.add_student.last_name'),
              }}
            />
            {isRootAdmin && (
              <FormikField
                name="isTest"
                component={SwitchField}
                controlProps={{
                  label: getTranslation('components.add_student.test_student'),
                }}
              />
            )}
            {isRootAdmin && (
              <FormikField
                name="isAdmin"
                component={SwitchField}
                controlProps={{
                  label: getTranslation('components.add_student.admin_student'),
                }}
              />
            )}
            {isRootAdmin && !isSchool && (
              <FormikField
                name="school"
                component={SelectField}
                componentProps={{
                  isDisabled: schoolsLoading,
                  options: schoolOptions,
                  onChanged: (nextSchool) =>
                    onSchoolChanged(nextSchool, { setFieldValue }),
                }}
                controlProps={{
                  required: true,
                  label: getTranslation('components.add_student.school'),
                }}
              />
            )}
            {currentSchoolId && (
              <div className={styles.section}>
                <Typography variant="body1" weight="medium">
                  {getTranslation('components.add_student.title')}
                </Typography>
                {coursesLoading ? (
                  <Loading />
                ) : (
                  <div
                    className={cn(styles.sectionInner, {
                      [styles.sectionInnerEmpty]:
                        !currentCourses || currentCourses.length === 0,
                    })}
                  >
                    {currentCourses && currentCourses.length > 0 ? (
                      <CoursesAccessList
                        isTestStudent={values.isTest}
                        isAdminStudent={values.isAdmin}
                        courses={currentCourses}
                      />
                    ) : (
                      <EmptyState
                        className={styles.emptyState}
                        icon="courses"
                        title={getTranslation(
                          'components.add_student.empty_state.title',
                        )}
                        text={getTranslation(
                          'components.add_student.empty_state.text',
                        )}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.action}
              size="md"
              variant="outlined"
              onClick={onClose}
            >
              {getTranslation('common.button.cancel')}
            </Button>
            <Button
              className={styles.action}
              disabled={coursesLoading}
              startIcon={
                inviteLoading && (
                  <Loading color={'var(--color-white-primary)'} />
                )
              }
              type="submit"
              size="md"
            >
              {getTranslation('common.button.invite')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </Drawer>
);

export default React.memo(AddStudent);
