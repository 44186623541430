import React from 'react';

import { Typography } from 'components/shared/Typography';

import { TextTextProperty } from '../TextTextProperty';
import { IconTextProperty } from '../IconTextProperty';
import { BooleanProperty } from '../BooleanProperty';
import { TransitionDirectionProperty } from '../TransitionDirectionProperty';
import { EmptyProperties } from '../EmptyProperties';

import styles from './PrototypeProperties.styles.scss';

const PrototypeProperties = ({ properties }) => {
  const interactions = properties.find(
    (property) => property.key === 'reactions',
  );

  if (!interactions || !interactions.value.length) {
    return (
      <div className={styles.properties}>
        <EmptyProperties />
      </div>
    );
  }

  return (
    <div className={styles.properties}>
      {interactions.value.map((interaction, interactionIndex) => {
        const triggerType = interaction.trigger
          ? interaction.trigger.type
          : null;
        const triggerDelay = interaction.trigger
          ? interaction.trigger.delay
          : null;
        const triggerKeyCodes = interaction.trigger
          ? interaction.trigger.keyCodes
          : null;
        let navigationType = interaction.action
          ? interaction.action.type
          : null;

        if (navigationType === 'NODE') {
          navigationType = interaction.action.navigation;
        }

        const transitionEasingType =
          interaction.action && interaction.action.transition
            ? interaction.action.transition.easing.type
            : null;

        let transitionEasingData = null;

        if (transitionEasingType === 'CUSTOM_CUBIC_BEZIER') {
          transitionEasingData =
            interaction.action.transition.easing.easingFunctionCubicBezier;
        }

        if (transitionEasingType === 'CUSTOM_SPRING') {
          transitionEasingData =
            interaction.action.transition.easing.easingFunctionSpring;
        }

        const transitionEasingDuration =
          interaction.action && interaction.action.transition
            ? interaction.action.transition.duration
            : null;
        const transitionType =
          interaction.action && interaction.action.transition
            ? interaction.action.transition.type
            : null;
        const transitionDirection =
          interaction.action && interaction.action.transition
            ? interaction.action.transition.direction
            : null;
        const transitionMatchLayers =
          interaction.action && interaction.action.transition
            ? interaction.action.transition.matchLayers
            : null;

        return (
          <div className={styles.interaction}>
            <Typography
              className={styles.interactionTitle}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              Interaction {interactionIndex + 1}
            </Typography>
            <div className={styles.interactionInner}>
              <div className={styles.interactionRow}>
                <TextTextProperty
                  readOnly
                  isInitialState
                  property={{
                    key: 'interactionTriggerType',
                    value: triggerType,
                  }}
                />
                {(triggerType === 'MOUSE_ENTER' ||
                  triggerType === 'MOUSE_LEAVE' ||
                  triggerType === 'MOUSE_UP' ||
                  triggerType === 'MOUSE_DOWN') && (
                  <TextTextProperty
                    readOnly
                    isInitialState
                    property={{
                      key: 'interactionTriggerDelay',
                      value: triggerDelay,
                    }}
                  />
                )}
                {triggerType === 'ON_KEY_DOWN' && (
                  <TextTextProperty
                    readOnly
                    isInitialState
                    property={{
                      key: 'interactionTriggerKeyCodes',
                      value: triggerKeyCodes,
                    }}
                  />
                )}
              </div>

              {interaction.action && (
                <>
                  <IconTextProperty
                    readOnly
                    isInitialState
                    property={{
                      key: 'interactionNavigationType',
                      value: navigationType,
                    }}
                    dependProperty={{
                      key: 'interactionDestination',
                      value: interaction.action.destination || null,
                    }}
                  />
                  {navigationType === 'URL' && (
                    <TextTextProperty
                      readOnly
                      isInitialState
                      property={{
                        key: 'interactionNavigationLinkUrl',
                        value: interaction.action.url,
                      }}
                    />
                  )}
                  {/* <IconNumberProperty
                    readOnly
                    property={{
                        key: 'interactionOffsetX',
                        value: interaction.action.navigation,
                    }}
                    />
                    <IconNumberProperty
                    readOnly
                    property={{
                        key: 'interactionOffsetY',
                        value: interaction.action.navigation,
                    }}
                    /> */}
                  {navigationType !== 'BACK' &&
                    navigationType !== 'CLOSE' &&
                    navigationType !== 'URL' && (
                      <>
                        <div className={styles.interactionRow}>
                          <TextTextProperty
                            readOnly
                            isInitialState
                            property={{
                              key: 'interactionTransition',
                              value: transitionType,
                            }}
                          />
                          {(transitionType === 'MOVE_IN' ||
                            transitionType === 'MOVE_OUT' ||
                            transitionType === 'PUSH' ||
                            transitionType === 'SLIDE_IN' ||
                            transitionType === 'SLIDE_OUT') && (
                            <TransitionDirectionProperty
                              property={{
                                key: 'interactionTransitionDirection',
                                value: transitionDirection,
                              }}
                            />
                          )}
                        </div>
                        {(transitionType === 'MOVE_IN' ||
                          transitionType === 'MOVE_OUT' ||
                          transitionType === 'PUSH' ||
                          transitionType === 'SLIDE_IN' ||
                          transitionType === 'SLIDE_OUT') && (
                          <BooleanProperty
                            readOnly
                            isInitialState
                            property={{
                              key: 'interactionTransitionMatchLayers',
                              value: transitionMatchLayers,
                            }}
                          />
                        )}
                      </>
                    )}

                  {navigationType !== 'BACK' &&
                    navigationType !== 'CLOSE' &&
                    navigationType !== 'URL' &&
                    transitionType && (
                      <div className={styles.interactionRow}>
                        <TextTextProperty
                          readOnly
                          isInitialState
                          property={{
                            key: 'interactionTransitionEasing',
                            value: transitionEasingType,
                          }}
                        />
                        <TextTextProperty
                          readOnly
                          isInitialState
                          property={{
                            key: 'interactionTransitionDuration',
                            value: transitionEasingDuration,
                          }}
                        />
                      </div>
                    )}
                  {transitionEasingType === 'CUSTOM_CUBIC_BEZIER' && (
                    <>
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionX1',
                          value: transitionEasingData.x1,
                        }}
                      />
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionX2',
                          value: transitionEasingData.x2,
                        }}
                      />
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionY1',
                          value: transitionEasingData.y1,
                        }}
                      />
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionY2',
                          value: transitionEasingData.y2,
                        }}
                      />
                    </>
                  )}
                  {transitionEasingType === 'CUSTOM_SPRING' && (
                    <>
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionStiffness',
                          value: transitionEasingData.stiffness,
                        }}
                      />
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionDamping',
                          value: transitionEasingData.damping,
                        }}
                      />
                      <TextTextProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionTransitionMass',
                          value: transitionEasingData.mass,
                        }}
                      />
                    </>
                  )}
                  {navigationType !== 'URL' &&
                    navigationType !== 'BACK' &&
                    navigationType !== 'CLOSE' && (
                      <BooleanProperty
                        readOnly
                        isInitialState
                        property={{
                          key: 'interactionPreserveScrollPosition',
                          value:
                            interaction.action.preserveScrollPosition || false,
                        }}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(PrototypeProperties);
