import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './SortButton.styles.scss';

const VARIANTS = {
  active: styles.active,
};

const SortButton = ({ variant, text, startIcon, ...restProps }) => (
  <button {...restProps} className={cn(styles.sortButton, VARIANTS[variant])}>
    <>
      {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
      <Typography variant="body2" mode="compact" weight="medium">
        {text}
      </Typography>
    </>
  </button>
);

export default React.memo(SortButton);
