import React from 'react';
import { getIn, useFormikContext } from 'formik';

import GoodToKnowSection from './GoodToKnowSection';

const GoodToKnowSectionContainer = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const handleAddClick = () => {
    setFieldValue('withGoodToKnow', true);
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();

    setFieldValue('withGoodToKnow', false);
    setFieldValue('goodToKnowBlocks', []);
  };

  const withGoodToKnow = getIn(values, 'withGoodToKnow');

  return (
    <GoodToKnowSection
      {...props}
      withGoodToKnow={withGoodToKnow}
      onAddClick={handleAddClick}
      onRemoveClick={handleRemoveClick}
    />
  );
};

export default React.memo(GoodToKnowSectionContainer);
