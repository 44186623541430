export const LINE_COLORS = {
  SHAMROCK: '#34CB73',
  RADIACAL_RED: '#F53149',
  ROYAL_BLUE_VIOLET: '#1182F6',
  LINK_WATER_BASE: '#DFE9F9',
};

export const ITEM_TYPES = {
  LEFT_ITEM: 'LEFT_ITEM',
  RIGHT_ITEM: 'RIGHT_ITEM',
};

export const LINE_PROPERTIES = {
  STROKE_WIDTH: 2,
};
