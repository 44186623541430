import React from 'react';

import { ADD_COURSE_CONTEXT_ITEMS } from 'constants/common';

import { getCourses } from 'store/courses/actions';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { MenuLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { ListToolbar } from 'components/Toolbar';
import { CoursesTable } from 'components/CoursesTable';
import { CreateButton } from 'components/CreateButton';

import styles from './CoursesPage.styles.scss';

const CoursesPage = ({
  loading,
  isRootAdmin,
  courses,
  sortParams,
  coursesColumns,
  onSearchChange,
  onSortChange,
  onContextItemClick,
  onCourseRowClick,
  onCreateCourseClick,
  onCourseRemoveClick,
  onCourseEditClick,
}) => {
  return (
    <MenuLayout>
      <ListToolbar
        title={getTranslation('courses_page.title')}
        actionComponent={
          isRootAdmin ? (
            <Button
              size="md"
              variant="link"
              startIcon={<Icon name="plusRound" />}
              onClick={onCreateCourseClick}
            >
              {getTranslation('common.button.create_course')}
            </Button>
          ) : (
            <CreateButton
              buttonText={getTranslation('common.button.create_course')}
              contextItems={ADD_COURSE_CONTEXT_ITEMS}
              onContextItemClick={onContextItemClick}
            />
          )
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {courses.length ? (
            <div className={styles.inner}>
              <CoursesTable
                withActions
                params={sortParams}
                data={courses}
                columns={coursesColumns}
                dataAction={getCourses}
                onSearchChange={onSearchChange}
                onSortChange={onSortChange}
                onRowClick={onCourseRowClick}
                onRemoveClick={onCourseRemoveClick}
                onEditClick={onCourseEditClick}
              />
            </div>
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="courses"
              title={getTranslation('courses_page.empty_state.title')}
              text={getTranslation('courses_page.empty_state.text')}
            />
          )}
        </>
      )}
    </MenuLayout>
  );
};

export default React.memo(CoursesPage);
