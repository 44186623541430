import React from 'react';

import styles from './DividerBlock.styles.scss';

const DividerBlock = ({ className }) => (
  <div className={className}>
    <div className={styles.divider} />
  </div>
);

export default React.memo(DividerBlock);
