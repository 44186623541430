import React, { useState } from 'react';

import ProgressCard from './ProgressCard';

const ProgressCardContainer = ({ studentsCount, ...restProps }) => {
  const [currentPercent, setCurrentPercent] = useState(null);

  const handlePieSliceMouseEnter = (node) => {
    setCurrentPercent(Number(((node.value * 100) / studentsCount).toFixed(2)));
  };

  const handlePieSliceMouseLeave = () => {
    setCurrentPercent(null);
  };

  return (
    <ProgressCard
      {...restProps}
      studentsCount={studentsCount}
      currentPercent={currentPercent}
      onPieSliceMouseEnter={handlePieSliceMouseEnter}
      onPieSliceMouseLeave={handlePieSliceMouseLeave}
    />
  );
};

export default React.memo(ProgressCardContainer);
