import React from 'react';
import cn from 'classnames';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';
import { getPropertyTooltipValue } from 'helpers/getPropertyTooltipValue';

import { AutoLayoutModeProperty } from '../AutoLayoutModeProperty';
import { AutoLayoutDirectionProperty } from '../AutoLayoutDirectionProperty';
import { AutoLayoutPaddingProperty } from '../AutoLayoutPaddingProperty';
import { BooleanProperty } from '../BooleanProperty';
import { IconNumberProperty } from '../IconNumberProperty';
import { EmptyProperties } from '../EmptyProperties';

import styles from './AutoLayoutProperties.styles.scss';

const AutoLayoutProperties = ({
  isInitialState,
  readOnly,
  properties,
  designType,
  namePrefix,
  onPropertyChanged,
}) => {
  const layoutMode = getLayerPropertyByKey({
    key: 'layoutMode',
    properties,
  });
  const layoutWrap = getLayerPropertyByKey({
    key: 'layoutWrap',
    properties,
  });

  if (!layoutMode || !layoutMode.value) {
    return <EmptyProperties />;
  }

  return (
    <div className={styles.properties}>
      <div className={cn(styles.item, styles.propertiesRow)}>
        <div className={styles.propertiesColumn}>
          <AutoLayoutModeProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            tooltip={getPropertyTooltipValue({
              key: 'layoutMode',
              properties,
              restProperties: layoutWrap,
            })}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'layoutMode',
              properties,
              namePrefix,
            })}.check`}
            property={{ value: { layoutMode, layoutWrap } }}
            onChanged={onPropertyChanged}
          />
          <IconNumberProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            tooltip="Horizontal gap between items"
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'itemSpacing',
              properties,
              namePrefix,
            })}.check`}
            property={getLayerPropertyByKey({ key: 'itemSpacing', properties })}
            dependProperty={layoutMode}
            onChanged={onPropertyChanged}
          />
          {layoutMode.value === 'HORIZONTAL' && layoutWrap.value === 'WRAP' && (
            <IconNumberProperty
              readOnly={readOnly}
              isInitialState={isInitialState}
              tooltip="Vertical gap between items"
              designType={designType}
              namePrefix={`${getPropertyNamePrefix({
                propertyKey: 'counterAxisSpacing',
                properties,
                namePrefix,
              })}.check`}
              property={getLayerPropertyByKey({
                key: 'counterAxisSpacing',
                properties,
              })}
              onChanged={onPropertyChanged}
            />
          )}
        </div>
        <div className={styles.propertiesColumn}>
          <AutoLayoutDirectionProperty
            readOnly={readOnly}
            isInitialState={isInitialState}
            designType={designType}
            namePrefix={`${getPropertyNamePrefix({
              propertyKey: 'counterAxisAlignItems',
              properties,
              namePrefix,
            })}.check`}
            property={{
              value: {
                layoutMode,
                layoutWrap,
                counterAxisAlignItems: getLayerPropertyByKey({
                  key: 'counterAxisAlignItems',
                  properties,
                }),
                primaryAxisAlignItems: getLayerPropertyByKey({
                  key: 'primaryAxisAlignItems',
                  properties,
                }),
              },
            }}
            onChanged={onPropertyChanged}
          />
        </div>
      </div>
      <div className={cn(styles.item, styles.propertiesRow)}>
        <AutoLayoutPaddingProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          paddingLeftNamePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paddingLeft',
            properties,
            namePrefix,
          })}.check`}
          paddingRightNamePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paddingRight',
            properties,
            namePrefix,
          })}.check`}
          paddingTopNamePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paddingTop',
            properties,
            namePrefix,
          })}.check`}
          paddingBottomNamePrefix={`${getPropertyNamePrefix({
            propertyKey: 'paddingBottom',
            properties,
            namePrefix,
          })}.check`}
          paddingLeftProperty={getLayerPropertyByKey({
            key: 'paddingLeft',
            properties,
          })}
          paddingRightProperty={getLayerPropertyByKey({
            key: 'paddingRight',
            properties,
          })}
          paddingTopProperty={getLayerPropertyByKey({
            key: 'paddingTop',
            properties,
          })}
          paddingBottomProperty={getLayerPropertyByKey({
            key: 'paddingBottom',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={cn(styles.item, styles.propertiesRow)}>
        <BooleanProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'strokesIncludedInLayout',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'strokesIncludedInLayout',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
      <div className={cn(styles.item, styles.propertiesRow)}>
        <BooleanProperty
          readOnly={readOnly}
          isInitialState={isInitialState}
          designType={designType}
          namePrefix={`${getPropertyNamePrefix({
            propertyKey: 'itemReverseZIndex',
            properties,
            namePrefix,
          })}.check`}
          property={getLayerPropertyByKey({
            key: 'itemReverseZIndex',
            properties,
          })}
          onChanged={onPropertyChanged}
        />
      </div>
    </div>
  );
};

export default React.memo(AutoLayoutProperties);
