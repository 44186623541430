import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TOKEN_KEY } from 'constants/token';

import * as api from 'api/methods';

import { setCurrentUser } from 'store/auth/actions';

import { convertDataToForm } from './AuthForm.helpers';

import AuthForm from './AuthForm';

const AuthFormContainer = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setFieldError }) => {
    setLoading(true);

    try {
      const { user, token } = await api.signinUser(convertDataToForm(values));

      dispatch(setCurrentUser(user));

      localStorage.setItem(TOKEN_KEY, token);

      setLoading(false);

      navigate('/courses');
    } catch (error) {
      setLoading(false);

      setFieldError('email', error.message);

      console.warn(error);
    }
  };

  const handleForgotPasswordClick = (values) => {
    navigate('/forgot-password', { state: { email: values.email } });
  };

  return (
    <AuthForm
      loading={loading}
      onSubmit={handleSubmit}
      onForgotPasswordClick={handleForgotPasswordClick}
    />
  );
};

export default React.memo(AuthFormContainer);
