const _convertRGBAtoHEX = (channels) => {
  const hexChannels = channels.map((entry) =>
    `0${entry.toString(16)}`.slice(-2),
  );

  const hex = `#${hexChannels.join('')}`;

  if (hex.length > 7) {
    return hex.slice(0, 7);
  }

  return hex;
};

const _parseRGBA = (raw) => {
  const channels = raw
    .replace(/rgba|rgb|\(|\)/g, '')
    .split(/,\s*/g)
    .map((entry, index) => {
      const number = parseFloat(entry, 10);

      return index === 3 ? Math.floor(number * 255) : number;
    });

  return channels;
};

const _getRGBAColor = (color) => {
  const { r, g, b, a } = color;

  return `rgba(${parseInt(r * 255, 10)}, ${parseInt(g * 255, 10)}, ${parseInt(
    b * 255,
    10,
  )}, ${a})`;
};

const _getHexColor = (data) =>
  _convertRGBAtoHEX(_parseRGBA(_getRGBAColor(data)));

const _gradientPositionToPercent = (position) =>
  `${(position * 100).toFixed(0)}%`;

const _diamondGradientPositionToPercent = (position) =>
  `${((position * 100) / 2).toFixed(0)}%`;

const _gradientPositionToDeg = (position) =>
  `${(position * 360).toFixed(0)}deg`;

const _getImageScaleMode = ({ scaleMode, scalingFactor }) => {
  switch (scaleMode) {
    case 'FILL': {
      return '50% / cover';
    }
    case 'FIT': {
      return '50% / contain';
    }
    case 'CROP': {
      return '';
    }
    case 'TILE': {
      return `0% 0% / ${(scalingFactor * 100).toFixed(2)}px ${(
        scalingFactor * 100
      ).toFixed(2)}px`;
    }
  }
};

export const getBackground = ({ type, value }) => {
  switch (type) {
    case 'SOLID': {
      return _getHexColor(value);
    }
    case 'GRADIENT_LINEAR': {
      const colors = value.gradientStops
        .map(
          (gradientStop) =>
            `${_getRGBAColor(gradientStop.color)} ${_gradientPositionToPercent(
              gradientStop.position,
            )}`,
        )
        .join(',');

      return `linear-gradient(180deg, ${colors})`;
    }
    case 'GRADIENT_RADIAL': {
      const colors = value.gradientStops
        .map(
          (gradientStop) =>
            `${_getRGBAColor(gradientStop.color)} ${_gradientPositionToPercent(
              gradientStop.position,
            )}`,
        )
        .join(',');

      return `radial-gradient(50% 50% at 50% 50%, ${colors})`;
    }
    case 'GRADIENT_ANGULAR': {
      const colors = value.gradientStops
        .map(
          (gradientStop) =>
            `${_getRGBAColor(gradientStop.color)} ${_gradientPositionToDeg(
              gradientStop.position,
            )}`,
        )
        .join(',');

      return `conic-gradient(from 180deg at 50% 50%, ${colors})`;
    }
    case 'GRADIENT_DIAMOND': {
      const colors = value.gradientStops
        .map(
          (gradientStop) =>
            `${_getRGBAColor(
              gradientStop.color,
            )} ${_diamondGradientPositionToPercent(gradientStop.position)}`,
        )
        .join(',');

      return `linear-gradient(to bottom right, ${colors}) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, ${colors}) bottom left / 50% 50% no-repeat, linear-gradient(to top left, ${colors}) top left / 50% 50% no-repeat, linear-gradient(to top right, ${colors}) top right / 50% 50% no-repeat`;
    }
    case 'IMAGE': {
      return `url(${value.imageUrl}) ${_getImageScaleMode({
        scaleMode: value.scaleMode,
        scalingFactor: value.scalingFactor,
      })} no-repeat`;
    }
    case 'VIDEO': {
      return `url(${value.imageUrl}) ${_getImageScaleMode({
        scaleMode: value.scaleMode,
        scalingFactor: value.scalingFactor,
      })} no-repeat`;
    }
  }
};

export const getPropertyValue = ({ type, value }) => {
  switch (type) {
    case 'SOLID': {
      return _getHexColor(value).replace('#', '').toUpperCase();
    }
    case 'GRADIENT_LINEAR': {
      return 'Linear';
    }
    case 'GRADIENT_RADIAL': {
      return 'Radial';
    }
    case 'GRADIENT_ANGULAR': {
      return 'Angular';
    }
    case 'GRADIENT_DIAMOND': {
      return 'Diamond';
    }
    case 'IMAGE': {
      return 'Image';
    }
    case 'VIDEO': {
      return 'Video';
    }
  }
};
