export const getPropertiesByCategory = ({
  properties,
  categoryProperties,
  categoryKey,
}) => {
  if (categoryKey === 'autoLayout') {
    const layoutWrap = properties.find(
      (property) => property.key === 'layoutWrap',
    );

    return properties
      .filter((property) => property.key !== 'primaryAxisAlignItems')
      .filter((property) => property.key !== 'layoutWrap')
      .filter((property) => {
        if (
          property.key === 'counterAxisSpacing' &&
          layoutWrap.value === 'NO_WRAP'
        ) {
          return false;
        }

        return true;
      })
      .filter((property) => categoryProperties.includes(property.key));
  }

  return properties.filter((property) =>
    categoryProperties.includes(property.key),
  );
};

const _checkLayerParent = ({ layerParent, currentLayerParent }) => {
  if (!layerParent && !currentLayerParent) {
    return true;
  }

  if (!layerParent && currentLayerParent) {
    return false;
  }

  if (layerParent && !currentLayerParent) {
    return false;
  }

  if (
    layerParent.type === currentLayerParent.type &&
    layerParent.name === currentLayerParent.name
  ) {
    return true;
  }

  return false;
};

export const getLayerPath = ({ layers, layerName, layerParent, layerPath }) => {
  let foundLayer = null;

  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];

    const currentLayerParent = layer.properties.find(
      (property) => property.key === 'parent',
    );

    if (
      layer.name === layerName &&
      _checkLayerParent({
        layerParent,
        currentLayerParent: currentLayerParent
          ? currentLayerParent.value
          : null,
      })
    ) {
      return `${layerPath}[${i}]`;
    }

    if (layer.children && layer.children.length > 0) {
      foundLayer = getLayerPath({
        layers: layer.children,
        layerName,
        layerParent,
        layerPath: `${layerPath}[${i}].children`,
      });

      if (foundLayer) {
        return foundLayer;
      }
    }
  }

  return foundLayer;
};
