import React from 'react';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import {
  getPropertyIconName,
  getPropertyValue,
} from './IconTextProperty.helpers';

import styles from './IconTextProperty.styles.scss';

const _renderField = ({
  namePrefix,
  readOnly,
  property,
  dependProperty,
  onChanged,
}) => (
  <FormikField
    name={namePrefix}
    withControl={false}
    component={PropertySelectField}
    componentProps={{
      type: 'checkbox',
      readOnly,
      property,
      dependProperty,
      renderProperty: _renderContent,
      onChanged,
    }}
  />
);

const _renderContent = ({ key, value, dependValue }) => (
  <>
    <Icon
      className={styles.icon}
      name={getPropertyIconName({
        type: key,
        value,
      })}
    />
    <Typography
      className={styles.value}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {getPropertyValue({ type: key, value, dependValue })}
    </Typography>
  </>
);

const IconTextProperty = ({
  isInitialState,
  readOnly,
  tooltip,
  designType,
  namePrefix,
  property,
  dependProperty,
  onChanged,
}) => {
  if (!property) {
    return null;
  }

  if (isInitialState || designType === 'PROTOTYPE') {
    if (tooltip) {
      return (
        <Tooltip
          text={tooltip}
          triggerElement={
            <div className={styles.propertyReadOnly}>
              {_renderContent({
                key: property.key,
                value: property.value,
                dependValue: dependProperty ? dependProperty.value : null,
              })}
            </div>
          }
        />
      );
    }

    return (
      <div className={styles.propertyReadOnly}>
        {_renderContent({
          key: property.key,
          value: property.value,
          dependValue: dependProperty ? dependProperty.value : null,
        })}
      </div>
    );
  }

  if (tooltip) {
    return (
      <Tooltip
        text={tooltip}
        triggerElement={_renderField({
          namePrefix,
          readOnly,
          property,
          dependProperty,
          onChanged,
        })}
      />
    );
  }

  return _renderField({
    namePrefix,
    readOnly,
    property,
    dependProperty,
    onChanged,
  });
};

export default React.memo(IconTextProperty);
