import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Typography } from 'components/shared/Typography';

import { ColorProperty } from '../ColorProperty';
import { TextTextProperty } from '../TextTextProperty';
import { IconTextProperty } from '../IconTextProperty';
import { IconNumberProperty } from '../IconNumberProperty';
import { EmptyProperties } from '../EmptyProperties';

import styles from './EffectsProperties.styles.scss';

const EffectsProperties = ({ properties }) => {
  const effects = getLayerPropertyByKey({ key: 'effects', properties });

  if (!effects || !effects.value || effects.value.length === 0) {
    return <EmptyProperties />;
  }

  return (
    <div className={styles.root}>
      {effects.value.map((effect, effectIndex) => (
        <div className={styles.item}>
          <Typography
            className={styles.itemTitle}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            Effect {effectIndex + 1}
          </Typography>
          <div className={styles.itemInner}>
            <div className={styles.propertiesRow}>
              <IconTextProperty
                isInitialState
                readOnly
                property={{
                  key: 'effectType',
                  value: effect.type,
                }}
              />
            </div>
            {(effect.type === 'BACKGROUND_BLUR' ||
              effect.type === 'LAYER_BLUR') && (
              <div className={styles.propertiesRow}>
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'effectBlur',
                    value: effect.radius,
                  }}
                />
              </div>
            )}
            {(effect.type === 'INNER_SHADOW' ||
              effect.type === 'DROP_SHADOW') && (
              <>
                <div className={styles.propertiesRow}>
                  <ColorProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectColor',
                      valueType: 'SOLID',
                      value: effect.color,
                    }}
                  />
                  <TextTextProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectOpacity',
                      value: effect.color.a,
                    }}
                  />
                </div>
                <div className={styles.propertiesRow}>
                  <IconNumberProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectX',
                      value: effect.offset.x,
                    }}
                  />
                  <TextTextProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectBlur',
                      value: effect.radius,
                    }}
                  />
                </div>
                <div className={styles.propertiesRow}>
                  <IconNumberProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectY',
                      value: effect.offset.y,
                    }}
                  />
                  <TextTextProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectSpread',
                      value: effect.spread,
                    }}
                  />
                </div>
                <div className={styles.propertiesRow}>
                  <IconTextProperty
                    isInitialState
                    readOnly
                    property={{
                      key: 'effectBlendMode',
                      value: effect.blendMode,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(EffectsProperties);
