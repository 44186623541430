import axios from 'axios';
import qs from 'qs';

export const createUser = (data) => axios.post('/api/v1/users', data);

export const getUsers = (params) =>
  axios.get(`/api/v1/users?${qs.stringify(params)}`);

export const getUserById = (userId) => axios.get(`/api/v1/users/${userId}`);

export const updateUserById = (userId, data) =>
  axios.put(`/api/v1/users/${userId}`, data);

export const deleteUserById = (userId) =>
  axios.delete(`/api/v1/users/${userId}`);

export const getUserActivityById = (userId) =>
  axios.get(`/api/v1/users/${userId}/activity`);

export const getUsersReviews = (userId) =>
  axios.get(`/api/v1/users/${userId}/reviews`);

export const grantProAccess = (userId) =>
  axios.post(`/api/v1/users/${userId}/grant`);

export const getUserCourses = ({ userId }) =>
  axios.get(`/api/v1/users/${userId}/courses`);
