import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Button } from 'components/shared/Button';

import { VIDEO_FORMAT_TAGS } from './UploadVideoBlock.constants';

import styles from './UploadVideoBlock.styles.scss';

const UploadVideoBlock = ({
  className,
  readOnly,
  loading,
  haveError,
  value,
  percentage,
  getInputProps,
  getRootProps,
  onRemoveClick,
  onBrowseClick,
}) => (
  <div
    {...getRootProps()}
    className={cn(
      styles.upload,
      { [styles.uploadWithValue]: !!value },
      { [styles.uploadHaveError]: haveError },
      className,
    )}
  >
    <input {...getInputProps()} className={styles.field} />
    {value && !loading && !readOnly && (
      <>
        <div className={styles.actionOverlay} />
        <Icon name="videoPreview" className={styles.playIcon} />
        <Button
          className={styles.action}
          variant="ghost"
          mode="icon"
          size="md"
          iconName="trash"
          iconColor="#fff"
          onClick={onRemoveClick}
        />
      </>
    )}
    {value ? (
      <video src={value} className={styles.preview} />
    ) : (
      <>
        {percentage > 0 && percentage <= 100 ? (
          <LinearProgressBar variant="upload" progress={percentage} />
        ) : (
          <div className={styles.inner} onClick={!readOnly && onBrowseClick}>
            <div className={styles.main}>
              <Icon className={styles.icon} name="video" />
              <Typography
                className={styles.text}
                variant="body1"
                mode="compact"
                weight="medium"
              >
                Add video
              </Typography>
            </div>
            <div className={styles.tags}>
              {VIDEO_FORMAT_TAGS.map((tag) => (
                <div className={styles.tag}>
                  <Typography
                    className={styles.tagText}
                    variant="body3"
                    mode="compact"
                    weight="medium"
                  >
                    {tag}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )}
  </div>
);

export default React.memo(UploadVideoBlock);
