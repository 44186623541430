import React from 'react';
import { useState, useEffect } from 'react';
import { CustomPicker } from 'react-color';
import tinycolor from 'tinycolor2';

import ColorPicker from './ColorPicker';

const ColorPickerContainer = ({ className, hex, onChange }) => {
  const [currentColor, setCurrentColor] = useState(null);

  useEffect(() => {
    const color = tinycolor(hex.replace('#', ''));

    setCurrentColor({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex(),
    });

    if (onChange) {
      onChange(`#${color.toHex()}`);
    }
  }, []);

  //   useEffect(() => {
  //     if (hex !== currentColor.hex) {
  //       const color = tinycolor(hex.replace('#', ''));

  //       setCurrentColor({
  //         hsv: color.toHsv(),
  //         hsl: color.toHsl(),
  //         hex: `#${color.toHex()}`,
  //       });
  //     }
  //   }, [hex, currentColor.hex]);

  const handleSaturationChange = (hsv) => {
    const color = tinycolor(hsv);

    setCurrentColor((prevColor) => ({ ...prevColor, hsv }));

    if (onChange) {
      onChange(`#${color.toHex()}`);
    }
  };

  const handleHueChange = (hue) => {
    const color = tinycolor(hue);

    setCurrentColor((prevColor) => ({
      ...prevColor,
      hsl: hue,
    }));

    if (onChange) {
      onChange(`#${color.toHex()}`);
    }
  };

  if (!currentColor) {
    return null;
  }

  return (
    <ColorPicker
      className={className}
      hsl={currentColor.hsl}
      hsv={currentColor.hsv}
      onSaturationChange={handleSaturationChange}
      onHueChange={handleHueChange}
    />
  );
};

export default CustomPicker(ColorPickerContainer);
