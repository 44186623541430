import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getReviews = createAsyncThunk(
  types.GET_REVIEWS,
  async (params) => {
    const reviews = await api.getStudentReviews(params);
    return reviews;
  },
);
