import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { Input } from 'components/shared/Input';

const PriceInput = ({ inputRef, maskOptions, ...restProps }) => {
  return (
    <MaskedInput
      {...restProps}
      {...(maskOptions ? { mask: createNumberMask(maskOptions) } : {})}
      render={(ref, props) => (
        <Input
          {...props}
          inputRef={(element) => {
            ref(element);

            if (inputRef) {
              inputRef.current = element;
            }
          }}
        />
      )}
    />
  );
};

export default React.memo(PriceInput);
