import React from 'react';

import { TimeInput } from 'components/shared/TimeInput';

const TimeInputField = ({ field, form, ...restProps }) => {
  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  return <TimeInput {...field} {...restProps} onChange={handleChange} />;
};

export default React.memo(TimeInputField);
