import { createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getLessonReports = createAsyncThunk(
  types.GET_LESSON_REPORTS,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const reports = isRootAdmin
      ? await api.getLessonsReports(params)
      : await api.getSchoolLessonReports({ schoolId: user.schoolId, params });

    return reports;
  },
);
