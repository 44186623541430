import { getTranslation } from 'helpers/getTranslation';

export const REMOVE_TYPES = {
  MODULE: 'MODULE',
  LESSON: 'LESSON',
};

export const CONFIRM_MODAL_TITLE = {
  MODULE: getTranslation('course_page.module_settings.constants.delete_module'),
  LESSON: getTranslation('course_page.module_settings.constants.delete_lesson'),
};
