import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';
import { modifyPreviewForTableRender } from 'helpers/modifyPreviewForTableRender';

import { Table } from 'components/shared/Table';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { StatusTag } from 'components/StatusTag';
import { EmptyState } from 'components/EmptyState';
import { CoursePreview } from 'components/CoursePreview';
import { PublishStatusTag } from 'components/PublishStatusTag';

import { getCoursesTableColumns } from './CoursesSection.tableConfig';

import {
  getCourseType,
  getCourseStatusText,
  getCourseStatusVariant,
} from './CoursesSection.helpers';

import styles from './CoursesSection.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  locale,
) => {
  switch (key) {
    case 'title': {
      console.log(item);

      return (
        <div
          className={styles.titleCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <CoursePreview className={styles.preview} preview={item.preview} />
          <Typography
            className={styles.title}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {item.title || getTranslation('common.text.untitled')}
          </Typography>
        </div>
      );
    }
    case 'type': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {getCourseType(item[key])}
        </DefaultCell>
      );
    }
    case 'updatedAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item.updatedAt
            ? formatDistance(parseISO(item.updatedAt), new Date(), {
                addSuffix: true,
                locale: locale === 'ru' ? ru : enUS,
              })
            : '-'}
        </DefaultCell>
      );
    }
    case 'courseProgress': {
      return (
        <div
          className={styles.progressCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <StatusTag
            variant={getCourseStatusVariant(item.progress, item.startedAt)}
          >
            {getCourseStatusText({
              progress: item.progress,
              iStartedAt: item.startedAt,
              completedLessons: item.completedLessons.length,
              lessonsCount: item.lessonsCount,
            })}
          </StatusTag>
        </div>
      );
    }
    case 'courseStatus': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <PublishStatusTag variant={item.status.toLowerCase()} />
        </div>
      );
    }
    case 'lessonsProgress': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item.completedLessons.length}/{item.lessonsCount}
        </DefaultCell>
      );
    }
    case 'startedAt': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] ? formatDate(item[key], 'dd MMM, yyyy', locale) : '-'}
        </DefaultCell>
      );
    }
    case 'completedAt': {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] ? formatDate(item[key], 'dd MMM, yyyy', locale) : '-'}
        </DefaultCell>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const CoursesSection = ({
  locale,
  isRootAdmin,
  courses,
  onCourseClick,
  onManageCoursesAccessClick,
  onGrantProAccessClick,
}) => (
  <div className={styles.section}>
    {isRootAdmin && (
      <div className={styles.header}>
        <Typography variant="subtitle">
          {getTranslation('student_page.courses_section.title')}
        </Typography>
        <Button
          className={styles.action}
          size="md"
          variant="link"
          onClick={onGrantProAccessClick}
        >
          {getTranslation('student_page.courses_section.pro_access_button')}
        </Button>
        <Button size="md" variant="link" onClick={onManageCoursesAccessClick}>
          {getTranslation('common.button.manage_access')}
        </Button>
      </div>
    )}
    {courses.length > 0 ? (
      <Table
        columns={getCoursesTableColumns()}
        actions={[]}
        data={modifyPreviewForTableRender(courses)}
        cellRenderer={(props, DefaultCell) =>
          cellRenderer(props, DefaultCell, locale)
        }
        onRowClick={onCourseClick}
      />
    ) : (
      <EmptyState
        className={styles.emptyState}
        icon="courses"
        title={getTranslation('student_page.courses_section.empty_state.title')}
        text={getTranslation('student_page.courses_section.empty_state.text')}
      />
    )}
  </div>
);

export default React.memo(CoursesSection);
