import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Tabs } from 'components/shared/Tabs';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { MainLayout } from 'components/Layout';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { StudentCard } from './components/StudentCard';
import { SchoolStudentCard } from './components/SchoolStudentCard';

import {
  getSchoolStudentStatus,
  getSchoolStudentCourses,
} from './StudentPage.helpers';

import styles from './StudentPage.styles.scss';
import { CoursesSection } from './components/CoursesSection';

const StudentPage = ({
  locale,
  loading,
  isRootAdmin,
  schoolsTabs,
  studentSchools,
  currentStudent,
  onEditClick,
  onCourseClick,
  onManageCoursesAccessClick,
}) => (
  <MainLayout
    toolbarComponent={
      <BreadcrumbsToolbar
        breadcrumbsData={{
          studentName: currentStudent?.name || currentStudent?.email,
        }}
      />
    }
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        <div className={styles.header}>
          {isRootAdmin ? (
            <StudentCard {...currentStudent} onEditClick={onEditClick} />
          ) : (
            <SchoolStudentCard
              locale={locale}
              student={currentStudent}
              studentStatus={getSchoolStudentStatus({
                currentStudent,
                studentSchools,
              })}
              studentCourses={getSchoolStudentCourses({
                currentStudent,
                studentSchools,
              })}
              onEditClick={onEditClick}
            />
          )}
        </div>

        <div className={styles.sectionHeader}>
          <Typography variant="heading2" weight="semiBold">
            {isRootAdmin
              ? getTranslation('student_page.schools')
              : getTranslation('student_page.courses_access')}
          </Typography>
          {!isRootAdmin && (
            <Button
              size="md"
              variant="link"
              onClick={() => onManageCoursesAccessClick(studentSchools[0])}
            >
              {getTranslation('student_page.manage_access.header')}
            </Button>
          )}
        </div>

        <div className={styles.inner}>
          {isRootAdmin ? (
            <Tabs
              className={styles.tabs}
              innerClassName={styles.tabsInner}
              items={schoolsTabs}
            />
          ) : (
            <CoursesSection
              isRootAdmin={false}
              courses={getSchoolStudentCourses({
                currentStudent,
                studentSchools,
              })}
              onCourseClick={onCourseClick}
              onManageCoursesAccessClick={() =>
                onManageCoursesAccessClick(studentSchools[0])
              }
            />
          )}
        </div>
      </>
    )}
  </MainLayout>
);

export default React.memo(StudentPage);
