import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const getlanguageOptions = () => [
  {
    label: getTranslation('schools_page.add_school.form_config.english_label'),
    value: 'en',
  },
  {
    label: getTranslation('schools_page.add_school.form_config.russian_label'),
    value: 'ru',
  },
];

export const initialValues = {
  preview: '',
  name: '',
  language: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    preview: Yup.string().ensure(),
    name: Yup.string().required(
      getTranslation('schools_page.add_school.form_config.name_error'),
    ),
    language: Yup.object().required(
      getTranslation('schools_page.add_school.form_config.language_error'),
    ),
  });
