import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import {
  ADD_STUDENT_CONTEXT_ITEMS,
  ADD_ADMIN_CONTEXT_ITEMS,
  ADD_COURSE_CONTEXT_ITEMS,
} from 'constants/common';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { Admins } from './components/Admins';
import { Content } from './components/Content';
import { Students } from './components/Students';
import { ActivationCodes } from './components/ActivationCodes';

import { CreateButton } from 'components/CreateButton';

export const getTabsItems = (isRootAdmin) => {
  if (isRootAdmin) {
    [
      {
        label: getTranslation('school_page.config.activation_codes'),
        content: <ActivationCodes />,
      },
      {
        label: getTranslation('school_page.config.content'),
        content: <Content />,
      },
      {
        label: getTranslation('school_page.config.students'),
        content: <Students />,
      },
      {
        label: getTranslation('school_page.config.admins'),
        content: <Admins />,
      },
    ];
  }

  return [
    {
      label: getTranslation('school_page.config.activation_codes'),
      content: <ActivationCodes />,
    },
    {
      label: getTranslation('school_page.config.content'),
      content: <Content />,
    },
    {
      label: getTranslation('school_page.config.students'),
      content: <Students />,
    },
  ];
};

export const getTabActions = ({
  currentTab,
  isRootAdmin,
  onAddStudentContextItemClick,
  onAddAdminContextItemClick,
  onAddCourseContextItemClick,
  onAddArcadeContextItemClick,
  onAddTrainingContextItemClick,
  onExportCodesClick,
  onGenerateCodeClick,
  onActivityReportClick,
}) => {
  switch (currentTab) {
    case 'Activation codes': {
      if (isRootAdmin) {
        return [
          {
            key: 'exportCodes',
            component: (
              <Button
                size="md"
                variant="link"
                startIcon={<Icon name="export" />}
                onClick={onExportCodesClick}
              >
                {getTranslation('common.button.export_codes')}
              </Button>
            ),
          },
          {
            key: 'generateCode',
            component: (
              <Button
                size="md"
                variant="link"
                startIcon={<Icon name="plusRound" />}
                onClick={onGenerateCodeClick}
              >
                {getTranslation('common.button.generate_codes')}
              </Button>
            ),
          },
        ];
      }

      return [
        {
          key: 'exportCodes',
          component: (
            <Button
              size="md"
              variant="link"
              startIcon={<Icon name="export" />}
              onClick={onExportCodesClick}
            >
              {getTranslation('common.button.export_codes')}
            </Button>
          ),
        },
      ];
    }
    case 'Content': {
      if (isRootAdmin) {
        return [
          {
            key: 'addArcade',
            component: (
              <CreateButton
                buttonText={getTranslation('common.button.add_arcade')}
                contextItems={ADD_COURSE_CONTEXT_ITEMS}
                onContextItemClick={onAddArcadeContextItemClick}
              />
            ),
          },
          {
            key: 'addTraining',
            component: (
              <CreateButton
                buttonText={getTranslation('common.button.add_training')}
                contextItems={ADD_COURSE_CONTEXT_ITEMS}
                onContextItemClick={onAddTrainingContextItemClick}
              />
            ),
          },
          {
            key: 'addCourse',
            component: (
              <CreateButton
                buttonText={getTranslation('common.button.add_course')}
                contextItems={ADD_COURSE_CONTEXT_ITEMS}
                onContextItemClick={onAddCourseContextItemClick}
              />
            ),
          },
        ];
      }

      return [];
    }
    case 'Students': {
      if (isRootAdmin) {
        return [
          {
            key: 'activityReport',
            component: (
              <Button size="md" variant="link" onClick={onActivityReportClick}>
                {getTranslation('common.button.activity_report')}
              </Button>
            ),
          },
          {
            key: 'addStudent',
            component: (
              <CreateButton
                buttonText={getTranslation('common.button.add_student')}
                contextItems={ADD_STUDENT_CONTEXT_ITEMS}
                onContextItemClick={onAddStudentContextItemClick}
              />
            ),
          },
        ];
      }

      return [
        {
          key: 'activityReport',
          component: (
            <Button size="md" variant="link" onClick={onActivityReportClick}>
              {getTranslation('common.button.activity_report')}
            </Button>
          ),
        },
      ];
    }
    case 'Admins': {
      return [
        {
          key: 'addAdmin',
          component: (
            <CreateButton
              buttonText={getTranslation('common.button.add_admin')}
              contextItems={ADD_ADMIN_CONTEXT_ITEMS}
              onContextItemClick={onAddAdminContextItemClick}
            />
          ),
        },
      ];
    }
  }
};
