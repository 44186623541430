import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './Checkbox.styles.scss';

const SIZES = {
  lg: styles.lg,
  sm: styles.sm,
};

const Checkbox = ({
  className,
  iconWrapperClassName,
  disabled,
  readOnly,
  size,
  icon,
  label,
  ...restProps
}) => (
  <div
    className={cn(
      styles.checkbox,
      SIZES[size],
      {
        [styles.checkboxWithLabel]: label,
        [styles.checkboxDisabled]: disabled,
        [styles.checkboxReadOnly]: readOnly,
      },
      className,
    )}
  >
    <input
      {...restProps}
      className={styles.field}
      type="checkbox"
      readOnly={readOnly}
    />
    <div className={cn(styles.iconWrapper, iconWrapperClassName)}>
      <Icon name={icon} className={styles.icon} />
    </div>
    {label && (
      <Typography
        className={styles.label}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {label}
      </Typography>
    )}
  </div>
);

Checkbox.defaultProps = {
  icon: 'checkMini',
  size: 'lg',
};

export default React.memo(Checkbox);
