import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { COURSE_STRUCTURES } from 'constants/common';

import { updateCourseById } from 'store/course/actions';
import { currentCourseSelector } from 'store/course/selectors';

export const useCheckCourseStructure = () => {
  const dispatch = useDispatch();

  const course = useSelector(currentCourseSelector);

  useEffect(() => {
    if (course && course.structure) {
      if (
        course.structure === COURSE_STRUCTURES.MODULES &&
        course.modules &&
        course.modules.length === 0
      ) {
        dispatch(
          updateCourseById({
            courseId: course.id,
            data: { structure: null },
          }),
        );

        return;
      }

      if (
        course.structure === COURSE_STRUCTURES.LESSONS &&
        course.lessons &&
        course.lessons.length === 0
      ) {
        dispatch(
          updateCourseById({
            courseId: course.id,
            data: { structure: null },
          }),
        );

        return;
      }
    }
  }, [course]);
};
