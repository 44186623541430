import React from 'react';
import { useDayPicker } from 'react-day-picker';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './DayContent.styles.scss';

const DayContent = ({ date, activeModifiers }) => {
  const {
    locale,
    formatters: { formatDay },
  } = useDayPicker();

  return (
    <Typography
      className={cn(styles.text, {
        [styles.textOutside]: activeModifiers.outside,
        [styles.textToday]: activeModifiers.today,
        [styles.textSelected]: activeModifiers.selected,
      })}
      variant="body3"
      mode="compact"
    >
      {formatDay(date, { locale })}
    </Typography>
  );
};

export default React.memo(DayContent);
