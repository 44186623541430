import React from 'react';

import { ColorInfo } from '../ColorInfo';
import { MatchInfo } from '../MatchInfo';

import styles from './ColorItem.styles.scss';

const ColorItem = ({
  withMatchInfo,
  imageRef,
  image,
  color,
  text,
  matchPercent,
}) => (
  <div className={styles.item}>
    <div
      className={styles.image}
      ref={imageRef}
      dangerouslySetInnerHTML={{ __html: image }}
    />
    <ColorInfo text={text} value={color} />
    {withMatchInfo && <MatchInfo value={matchPercent} />}
  </div>
);

export default React.memo(ColorItem);
