import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { USER_ROLES } from 'constants/common';

import { getAdmins } from 'store/admins/actions';
import { adminsSelector } from 'store/admins/selectors';
import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { AddAdmin } from 'components/AddAdmin';
import { ImportAdmins } from 'components/ImportAdmins';

import {
  getAdminsTableColumnsRootAdmin,
  getAdminsTableColumns,
} from './AdminsPage.tableConfig';

import AdminsPage from './AdminsPage';

const AdminsPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const admins = useSelector(adminsSelector);
  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(true);

  const [sortParams, setSortParams] = useState({
    sortBy: 'createdAt',
    sortDirection: 'desc',
  });

  const [addAdminOpen, openAddAdmin, closeAddAdmin] = useModal({
    defaultOpen: false,
  });
  const [importAdminsOpen, openImportAdmins, closeImportAdmins] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    const _getAdmins = async () => {
      try {
        await dispatch(
          getAdmins({
            page: 1,
            limit: 50,
            sortBy: sortParams.sortBy,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getAdmins();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getAdmins({
          page: 1,
          limit: admins.perPage,
          // search: searchValue === '' ? undefined : searchValue,
          sortBy: nextSortBy,
          sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleCreateAdminClick = (contextItem) => {
    if (contextItem.key === 'CREATE_NEW') {
      openAddAdmin();
    }

    if (contextItem.key === 'IMPORT') {
      openImportAdmins();
    }
  };

  const handleEditAdminClick = (admin) => {
    navigate(`/admins/${admin.id}`);
  };

  const handleAdminRowClick = (adminId) => {
    navigate(`/admins/${adminId}`);
  };

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;
  const adminsColumns = isRootAdmin
    ? getAdminsTableColumnsRootAdmin()
    : getAdminsTableColumns();

  return (
    <>
      <AdminsPage
        loading={loading}
        admins={admins.data}
        adminsColumns={adminsColumns}
        sortParams={sortParams}
        user={user}
        onSortChange={handleSortChange}
        onCreateAdminClick={handleCreateAdminClick}
        onEditAdminClick={handleEditAdminClick}
        onAdminRowClick={handleAdminRowClick}
      />
      <AddAdmin
        open={addAdminOpen}
        isRootAdmin={isRootAdmin}
        schoolId={user.schoolId}
        onClose={closeAddAdmin}
      />
      <ImportAdmins
        open={importAdminsOpen}
        schoolId={user.schoolId}
        onClose={closeImportAdmins}
      />
    </>
  );
};

export default React.memo(AdminsPageContainer);
