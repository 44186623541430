import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './CourseResult.styles.scss';

const CourseResult = ({ preview, previewType }) => (
  <div
    className={cn(styles.root, {
      [styles.webRoot]: previewType === getTranslation('common.text.web'),
    })}
  >
    <Typography className={styles.title} variant="subtitle">
      {getTranslation('course_preview_page.course_result_title')}
    </Typography>
    <div className={styles.inner}>
      <img className={styles.preview} src={preview} />
    </div>
  </div>
);

export default React.memo(CourseResult);
