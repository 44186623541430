import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFetch } from 'hooks/useFetch';

import { checkCourseStatsEmpty } from './DashboardPage.helpers';

import * as api from 'api/methods';

import DashboardPage from './DashboardPage';

const DashboardPageContainer = (props) => {
  const [loadingCourseStats, setLoadingCourseStats] = useState(true);
  const [isMoreInfo, setIsMoreInfo] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [currentCourseStats, setCurrentCourseStats] = useState(null);
  const { i18n } = useTranslation();

  const { loading: loadingCourses, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getCourses,
      immediately: true,
      stopRequest: false,
    },
    null,
    [],
  );

  useEffect(() => {
    if (courses && courses.length) {
      setCurrentCourse(courses[1]);
    }
  }, [courses]);

  useEffect(() => {
    const _getCourseStats = async () => {
      if (currentCourse) {
        setLoadingCourseStats(true);

        const courseStats = await api.getCourseStats(currentCourse.id);

        setCurrentCourseStats(courseStats);
        setLoadingCourseStats(false);
      }
    };

    _getCourseStats();
  }, [currentCourse]);

  const handleMoreInfoToggle = () => {
    setIsMoreInfo(!isMoreInfo);
  };

  const handleCourseChange = (option) => {
    const nextCourse = courses.find((course) => course.id === option.value);

    setIsMoreInfo(false);
    setCurrentCourse(nextCourse);
  };

  const isCurrentCourseStatsEmpty = checkCourseStatsEmpty({
    course: currentCourse,
    courseStats: currentCourseStats,
  });

  return (
    <DashboardPage
      {...props}
      locale={i18n.language}
      loadingCourses={loadingCourses}
      loadingCourseStats={loadingCourseStats}
      isMoreInfo={isMoreInfo}
      isCurrentCourseStatsEmpty={isCurrentCourseStatsEmpty}
      courses={courses}
      currentCourse={currentCourse}
      currentCourseStats={currentCourseStats}
      onCourseChange={handleCourseChange}
      onMoreInfoToggle={handleMoreInfoToggle}
    />
  );
};

export default React.memo(DashboardPageContainer);
