import React from 'react';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';
import { copyToClipboard } from 'helpers/copyToClipboard';

import { isTextCopyAvailable, getCopyText } from './Task.helpers';

import Task from './Task';

const TaskContainer = ({ layers, ...restProps }) => {
  const handleCopyTextClick = () => {
    const copyText = getCopyText(layers);

    copyToClipboard(copyText);

    showToast({
      data: {
        variant: 'success',
        title: getTranslation('common.toast.clipboard_copy_text.title'),
        text: getTranslation('common.toast.clipboard_copy_text.text'),
      },
    });
  };

  return (
    <Task
      {...restProps}
      isTextCopyAvailable={isTextCopyAvailable(layers)}
      onCopyTextClick={handleCopyTextClick}
    />
  );
};

export default React.memo(TaskContainer);
