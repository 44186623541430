import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

import { USER_ROLES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';
import { createDownloadLink } from 'helpers/createDownloadLink';

import * as api from 'api/methods';

import { getStudents, removeStudentById } from 'store/students/actions';
import { studentsSelector } from 'store/students/selectors';
import { userSelector } from 'store/auth/selectors';

import { useModal } from 'hooks/useModal';

import { AddStudent } from 'components/AddStudent';
import { ImportStudents } from 'components/ImportStudents';
import { DefaultModal } from 'components/DefaultModal';
import { RemoveWithStepsModal } from 'components/RemoveWithStepsModal';

import StudentsPage from './StudentsPage';
import {
  STUDENTS_TABLE_COLUMNS,
  STUDENTS_TABLE_COLUMNS_ROOT_ADMIN,
} from './StudentsPage.tableConfig';

const StudentsPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const students = useSelector(studentsSelector);
  const user = useSelector(userSelector);

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'updatedAt',
    sortDirection: 'desc',
  });

  const [addStudentOpen, openAddStudent, closeAddStudent] = useModal({
    defaultOpen: false,
  });
  const [importStudentsOpen, openImportStudents, closeImportStudents] =
    useModal({
      defaultOpen: false,
    });
  const [
    removeConfirmOpen,
    openRemoveConfirm,
    closeRemoveConfirm,
    removeConfirmData,
  ] = useModal({
    defaultOpen: false,
  });
  const [
    removeStudentOpen,
    openRemoveStudent,
    closeRemoveStudent,
    removeStudentData,
  ] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    const _getStudents = async () => {
      try {
        await dispatch(
          getStudents({
            page: 1,
            limit: 50,
            sortBy: sortParams.sortBy,
            sortDirection: sortParams.sortDirection,
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getStudents();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getStudents({
          page: 1,
          limit: students.perPage,
          // search: searchValue === '' ? undefined : searchValue,
          sortBy: nextSortBy,
          sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleCreateStudentClick = (contextItem) => {
    if (contextItem.key === 'CREATE_NEW') {
      openAddStudent();
    }

    if (contextItem.key === 'IMPORT') {
      openImportStudents();
    }
  };

  const handleEditStudentClick = (student) => {
    navigate(`/students/${student.id}`);
  };

  const handleRemoveStudentClick = (student) => {
    openRemoveConfirm({
      title: getTranslation('students_page.remove_student.title'),
      text: getTranslation('students_page.remove_student.text'),
      data: { student },
    });
  };

  const handleConfirmRemoveClick = ({ student }) => {
    closeRemoveConfirm();

    openRemoveStudent({
      requestParams: student.id,
      onRemoved: () => {
        closeRemoveStudent();

        dispatch(removeStudentById(student.id));
      },
    });
  };

  const handleStudentRowClick = (studentId) => {
    navigate(`/students/${studentId}`);
  };

  const handleExportStudentsClick = async () => {
    const response = await api.exportStudents();

    const today = format(new Date(), 'dd MMM yyyy');

    createDownloadLink(response, `students ${today}.xlsx`);
  };

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;
  const canCreateStudent = isRootAdmin;
  const studentsColumns = isRootAdmin
    ? STUDENTS_TABLE_COLUMNS_ROOT_ADMIN
    : STUDENTS_TABLE_COLUMNS;

  return (
    <>
      <StudentsPage
        loading={loading}
        canCreateStudent={canCreateStudent}
        students={students.data}
        studentsColumns={studentsColumns}
        sortParams={sortParams}
        onSortChange={handleSortChange}
        onStudentRowClick={handleStudentRowClick}
        onCreateStudentClick={handleCreateStudentClick}
        onEditStudentClick={handleEditStudentClick}
        onRemoveStudentClick={handleRemoveStudentClick}
        onExportStudentsClick={handleExportStudentsClick}
      />
      <AddStudent
        open={addStudentOpen}
        isRootAdmin={isRootAdmin}
        isSchool={false}
        onClose={closeAddStudent}
      />
      <ImportStudents
        open={importStudentsOpen}
        isRootAdmin={isRootAdmin}
        schoolId={user.schoolId}
        onClose={closeImportStudents}
      />
      <DefaultModal
        {...removeConfirmData}
        open={removeConfirmOpen}
        confirmText={getTranslation(
          'students_page.remove_student.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveConfirm}
        onClose={closeRemoveConfirm}
      />
      <RemoveWithStepsModal
        {...removeStudentData}
        open={removeStudentOpen}
        variant="STUDENT"
        title={getTranslation(
          'students_page.remove_student.remove_modal_title',
        )}
        onClose={closeRemoveStudent}
      />
    </>
  );
};

export default React.memo(StudentsPageContainer);
