import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getSchoolById = createAsyncThunk(
  types.GET_SCHOOL_BY_ID,
  async (schoolId) => {
    const school = await api.getSchoolById(schoolId);

    return school;
  },
);

export const updateSchoolById = createAsyncThunk(
  types.UPDATE_SCHOOL_BY_ID,
  async ({ schoolId, data }) => {
    const school = await api.updateSchoolById({ schoolId, data });

    return school;
  },
);
