import * as Yup from 'yup';

export const initialValues = {
  period: '',
  courses: [],
};

export const getValidationSchema = () => {
  return Yup.object().shape({
    period: Yup.object().required('Period is required'),
    courses: Yup.array().min(1, 'Please, select a course from the list'),
  });
};
