import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { STARS_COUNT } from './Rating.constants';

import styles from './Rating.styles.scss';

const Rating = ({
  withResponsive,
  withQualityValue,
  readonly,
  value,
  size,
  className,
  onMouseLeave,
  onStarMouseMove,
  onChange,
}) => (
  <div
    className={cn(
      styles.rating,
      { [styles.ratingReadonly]: readonly },
      { [styles.ratingResponsive]: withResponsive },
      className,
    )}
    onMouseLeave={onMouseLeave}
    style={{ width: `${size * STARS_COUNT}px` }}
  >
    {withQualityValue && (
      <div className={styles.ratingQualityValue}>
        <Typography variant="subtitle">
          {String(value.toFixed(1)).replace('.', ',')}
        </Typography>
      </div>
    )}
    <div
      className={cn(styles.stars, {
        [styles.starsWithQuality]: withQualityValue,
      })}
      style={{ width: `${(value * 100) / STARS_COUNT}%` }}
    >
      {Array.from(Array(STARS_COUNT)).map((_, index) => (
        <Icon
          key={index}
          name="star"
          style={!withResponsive ? { width: size, height: size } : {}}
          className={styles.star}
          onClick={() => onChange(index + 1)}
          onMouseMove={(event) => onStarMouseMove(event, index)}
        />
      ))}
    </div>
    <div
      className={cn(styles.emptyStars, {
        [styles.emptyStarsWithQuality]: withQualityValue,
      })}
    >
      {Array.from(Array(STARS_COUNT)).map((_, index) => (
        <Icon
          key={index}
          name="star"
          style={!withResponsive ? { width: size, height: size } : {}}
          className={styles.emptyStar}
          onMouseMove={(event) => onStarMouseMove(event, index)}
        />
      ))}
    </div>
  </div>
);

Rating.defaultProps = {
  value: 0,
  size: 16,
};

export default React.memo(Rating);
