import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as api from 'api/methods';

import { generateCodes } from 'store/codes/actions';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { useFetch } from 'hooks/useFetch';

import { convertDataToApi } from './GenerateCode.helpers';

import GenerateCode from './GenerateCode';
import { createDownloadLink } from '../../helpers/createDownloadLink';

const GenerateCodeContainer = ({ open, schoolId, onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const [generateLoading, setGenerateLoading] = useState(false);

  const { loading: loadingCourses, data: courses } = useFetch(
    {
      defaultData: [],
      fetcher: api.getSchoolCourses,
      immediately: false,
      stopRequest: !open,
    },
    { schoolId, params: {} },
    [open],
  );

  const handleGenerateClick = async (values, { resetForm }) => {
    try {
      setGenerateLoading(true);

      const codes = await dispatch(
        generateCodes(convertDataToApi({ ...values, schoolId })),
      ).unwrap();

      if (values.export) {
        const response = await api.exportSchoolCodes(schoolId, {
          codes: codes.map((code) => code.id),
        });

        createDownloadLink(response, 'promocodes.xlsx');
      }

      onClose();
      resetForm();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.codes_generated.title'),
          text: getTranslation('common.toast.codes_generated.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.title'),
        },
      });
    }

    setGenerateLoading(false);
  };

  return (
    <GenerateCode
      {...restProps}
      open={open}
      generateLoading={generateLoading}
      loadingCourses={loadingCourses}
      courses={courses}
      onClose={onClose}
      onGenerateClick={handleGenerateClick}
    />
  );
};

export default React.memo(GenerateCodeContainer);
