import React, { useState, useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';

import { EmptyLayers } from '../EmptyLayers';

import LayersSection from './LayersSection';

const LayersSectionContainer = ({
  figmaFileImages,
  namePrefix,
  ...restProps
}) => {
  const { values } = useFormikContext();

  const [currentLayerPrefix, setCurrentLayerPrefix] = useState(
    `${namePrefix}[0]`,
  );

  useEffect(() => {
    if (namePrefix && currentLayerPrefix !== `${namePrefix}[0]`) {
      setCurrentLayerPrefix(`${namePrefix}[0]`);
    }
  }, [namePrefix]);

  const handleLayerClick = (event, nextLayerPrefix) => {
    event.stopPropagation();

    setCurrentLayerPrefix(nextLayerPrefix);
  };

  const layers = getIn(values, namePrefix);
  const currentLayer = getIn(values, currentLayerPrefix);

  if (layers.length === 0) {
    return <EmptyLayers />;
  }

  return (
    <LayersSection
      {...restProps}
      readOnly={values.readOnly}
      figmaFileImages={figmaFileImages}
      layers={layers}
      designType={values.designType}
      currentLayer={currentLayer}
      currentLayerPrefix={currentLayerPrefix}
      namePrefix={namePrefix}
      onLayerClick={handleLayerClick}
    />
  );
};

export default React.memo(LayersSectionContainer);
