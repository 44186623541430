import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './StatusTag.styles.scss';

const VARIANTS = {
  default: styles.default,
  info: styles.info,
  success: styles.success,
  error: styles.error,
};

const StatusTag = ({ className, variant, children }) => (
  <div className={cn(styles.tag, VARIANTS[variant], className)}>
    <Typography
      className={styles.text}
      variant="body3"
      weight="medium"
      mode="compact"
    >
      {children}
    </Typography>
  </div>
);

export default React.memo(StatusTag);
