import _flatten from 'lodash/flatten';

const _rgb2lab = (rgb) => {
  let r = rgb[0] / 255,
    g = rgb[1] / 255,
    b = rgb[2] / 255,
    x,
    y,
    z;
  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
  x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
  y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
  z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;

  return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
};

const _hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const getMatchingPercent = (hexA, hexB) => {
  let labA = _rgb2lab(_hexToRgb(hexA));
  let labB = _rgb2lab(_hexToRgb(hexB));

  let deltaL = labA[0] - labB[0];
  let deltaA = labA[1] - labB[1];
  let deltaB = labA[2] - labB[2];

  let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
  let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);

  let deltaC = c1 - c2;

  let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;

  deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);

  let sc = 1.0 + 0.045 * c1;
  let sh = 1.0 + 0.015 * c1;

  let deltaLKlsl = deltaL / 1.0;
  let deltaCkcsc = deltaC / sc;
  let deltaHkhsh = deltaH / sh;

  let i =
    deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;

  return i < 0 ? 100 : (100 - Math.sqrt(i)).toFixed(0);
};

export const getLessonsCount = (course, lesson) => {
  const lessons = course.lessons
    ? course.lessons
    : _flatten(course.modules.map((module) => module.lessons));
  const lessonIndex = lessons.findIndex(
    (courseLesson) => courseLesson.id === lesson.id,
  );
  return {
    totalLessons: lessons.length,
    lessonIndex: lessonIndex + 1,
  };
};
