import React from 'react';
import { Formik, Form } from 'formik';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { getTranslation } from 'helpers/getTranslation';

import { Avatar } from 'components/shared/Avatar';
import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField, SwitchField } from 'components/FormikFields';

import { getValidationSchema } from './EditStudent.formConfig';

import { convertDataToForm } from './EditStudent.helpers';

import styles from './EditStudent.styles.scss';

const EditStudent = ({
  locale,
  open,
  isRootAdmin,
  saveLoading,
  onClose,
  currentStudent,
  onSaveClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('student_page.edit_student.title')}
    onClose={onClose}
  >
    <Typography className={styles.lastSave} variant="body3" mode="compact">
      {getTranslation('student_page.edit_student.last_save')}{' '}
      {formatDistance(new Date(), parseISO(currentStudent.updatedAt), {
        locale: locale === 'ru' ? ru : enUS,
      })}{' '}
      {getTranslation('student_page.edit_student.ago')}
    </Typography>
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={convertDataToForm(currentStudent)}
      validationSchema={getValidationSchema({ isRootAdmin })}
      onSubmit={onSaveClick}
    >
      <Form className={styles.form}>
        <div className={styles.main}>
          <div className={styles.header}>
            <Avatar
              size="md"
              url={currentStudent?.avatar}
              text={currentStudent?.name || currentStudent?.email}
            />
            <Typography
              className={styles.name}
              variant="body1"
              weight="medium"
              mode="compact"
            >
              {currentStudent?.name}
            </Typography>
          </div>
          <FormikField
            name="email"
            component={InputField}
            controlProps={{
              label: getTranslation('student_page.edit_student.email_label'),
            }}
            componentProps={{ disabled: true }}
          />
          {isRootAdmin && (
            <FormikField
              name="isTest"
              component={SwitchField}
              controlProps={{
                label: getTranslation('components.add_student.test_student'),
              }}
              componentProps={{ type: 'checkbox' }}
            />
          )}
          {isRootAdmin && (
            <FormikField
              name="isAdmin"
              component={SwitchField}
              controlProps={{
                label: getTranslation('components.add_student.admin_student'),
              }}
              componentProps={{ type: 'checkbox' }}
            />
          )}
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            type="submit"
            size="md"
            startIcon={
              saveLoading && (
                <Loading size={18} color="var(--color-white-primary)" />
              )
            }
          >
            {getTranslation('common.button.save')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(EditStudent);
