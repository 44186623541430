import React from 'react';
import { useDraggable } from '@dnd-kit/core';

import { Item } from '../Item';

const DraggableItem = ({ data, ...restProps }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: data.id,
    data,
  });

  return (
    <Item
      {...restProps}
      draggable
      attributes={attributes}
      listeners={listeners}
      text={data.text}
      setNodeRef={setNodeRef}
    />
  );
};

export default React.memo(DraggableItem);
