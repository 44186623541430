import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { getTranslation } from 'helpers/getTranslation';

import { getEllipsisText } from './BreadcrumbsToolbar.helpers';

import { Breadcrumbs } from 'components/Breadcrumbs';

import styles from './BreadcrumbsToolbar.styles.scss';

const BreadcrumbsToolbar = ({ breadcrumbsData, endAdornment }) => {
  const { courseTitle, lessonTitle, adminName, studentName, schoolName } =
    breadcrumbsData;

  const breadcrumbs = useBreadcrumbs([
    { path: '/', breadcrumb: null },
    {
      path: '/courses/create',
      breadcrumb: getTranslation('components.toolbar.create_course'),
    },
    {
      path: '/courses/:id',
      breadcrumb: getEllipsisText(
        courseTitle || getTranslation('common.text.untitled'),
      ),
    },
    { path: '/courses/:courseId/lessons', breadcrumb: null },
    {
      path: '/courses/:courseId/lessons/create',
      breadcrumb: getTranslation('components.toolbar.create_lesson'),
    },
    {
      path: '/courses/:courseId/lessons/:id',
      breadcrumb: getEllipsisText(
        lessonTitle || getTranslation('common.text.untitled'),
      ),
    },
    { path: '/admins/:id', breadcrumb: adminName },
    { path: '/students/:id', breadcrumb: studentName },
    { path: '/schools/:id', breadcrumb: schoolName },
    {
      path: '/trainings/:id',
      breadcrumb: getEllipsisText(
        courseTitle || getTranslation('common.text.untitled'),
      ),
    },
    { path: '/trainings/:trainingId/lessons', breadcrumb: null },
    {
      path: '/trainings/:trainingId/lessons/create',
      breadcrumb: getTranslation('components.toolbar.create_lesson'),
    },
    {
      path: '/trainings/:trainingId/lessons/:id',
      breadcrumb: getEllipsisText(
        lessonTitle || getTranslation('common.text.untitled'),
      ),
    },
    {
      path: '/arcades/:id',
      breadcrumb: getEllipsisText(
        courseTitle || getTranslation('common.text.untitled'),
      ),
    },
    { path: '/arcades/:arcadeId/lessons', breadcrumb: null },
    {
      path: '/arcades/:arcadeId/lessons/create',
      breadcrumb: getTranslation('components.toolbar.create_lesson'),
    },
    {
      path: '/arcades/:arcadeId/lessons/:id',
      breadcrumb: getEllipsisText(
        lessonTitle || getTranslation('common.text.untitled'),
      ),
    },
  ]);
  return (
    <div className={styles.toolbar}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {endAdornment}
    </div>
  );
};

export default React.memo(BreadcrumbsToolbar);
