import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField, RadioBoxField } from 'components/FormikFields';

import { FigmaFileAccess } from '../FigmaFileAccess';
import { FigmaFrame } from '../FigmaFrame';

import { getPageOptions } from './FigmaFileSettings.helpers';

import styles from './FigmaFileSettings.styles.scss';

const FigmaLessonSettings = ({
  loading,
  loadingFrames,
  pages,
  currentPage,
  onSyncClick,
}) => (
  <div className={styles.settings}>
    <div className={styles.field}>
      <FormikField
        name="fileUrl"
        component={InputField}
        controlProps={{
          className: styles.fileUrlControl,
          required: true,
          withVerticalSpace: false,
          label: getTranslation(
            'figma_lesson_page.file_settings.file_url_label',
          ),
        }}
      />
      <Button
        className={styles.button}
        size="sm"
        startIcon={
          loading && <Loading size={16} color="var(--color-white-primary)" />
        }
        onClick={onSyncClick}
      >
        {getTranslation('common.button.sync')}
      </Button>
    </div>
    <FigmaFileAccess />
    {!!pages.length && (
      <div className={styles.inner}>
        <Typography
          className={styles.pagesLabel}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          {getTranslation('figma_lesson_page.file_settings.pages_label')}
        </Typography>
        <div className={styles.pages}>
          {getPageOptions(pages).map((option) => (
            <FormikField
              name="filePageId"
              withControl={false}
              component={RadioBoxField}
              componentProps={{
                className: styles.page,
                type: 'radio',
                label: option.label,
                value: option.value,
              }}
            />
          ))}
        </div>

        {loadingFrames && <Loading />}
        {currentPage.loaded && (
          <div className={styles.frames}>
            {currentPage.frames.map((frame) => (
              <FigmaFrame className={styles.frame} key={frame.id} {...frame} />
            ))}
          </div>
        )}
      </div>
    )}
  </div>
);

export default React.memo(FigmaLessonSettings);
