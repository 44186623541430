import React from 'react';
import cn from 'classnames';

import { Header } from 'components/Header';

import styles from './LessonLayout.styles.scss';

const LessonLayout = ({
  className,
  sidebarComponent,
  toolbarComponent,
  children,
}) => (
  <div className={cn(styles.layout, className)}>
    <Header />
    <div className={styles.container}>
      {sidebarComponent}
      <div className={styles.inner}>
        {toolbarComponent}
        {children}
      </div>
    </div>
  </div>
);

export default React.memo(LessonLayout);
