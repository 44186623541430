import React, { useState, useEffect, useRef, createRef } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';

import { LINE_COLORS, ITEM_TYPES } from './ConnectionQuizPreviewPage.constants';

import {
  shuffle,
  getIntersectionArea,
  shuffleIncorrectAnswers,
} from './ConnectionQuizPreviewPage.helpers';

import ConnectionQuizPreviewPage from './ConnectionQuizPreviewPage';

const ConnectionQuizPreviewPageContainer = () => {
  const leftItemsRef = useRef();
  const rightItemsRef = useRef();
  const containerRef = useRef();
  const rootRef = useRef();

  const lesson = useSelector(currentLessonSelector);

  const [lineDrawing, setLineDrawing] = useState(false);
  const [linesValidated, setLinesValidated] = useState(false);
  const [rightItems, setRightItems] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [lines, setLines] = useState([]);
  const [correctLinesIds, setCorrectLinesIds] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);
  const [startLinePosition, setStartLinePosition] = useState({
    x: 0,
    y: 0,
    startId: null,
    startType: '',
  });

  useEffect(() => {
    if (lesson.answers.length) {
      const shuffledLeftItems = shuffle(
        lesson.answers.map((answer) => ({
          id: answer.id,
          content: answer.leftContent,
          type: ITEM_TYPES.LEFT_ITEM,
        })),
      );

      const shuffledRightItems = shuffle(
        lesson.answers.map((answer) => ({
          id: answer.id,
          content: answer.rightContent,
          type: ITEM_TYPES.RIGHT_ITEM,
        })),
      );

      leftItemsRef.current = Array.from(Array(shuffledLeftItems.length)).map(
        () => createRef(''),
      );

      rightItemsRef.current = Array.from(Array(shuffledRightItems.length)).map(
        () => createRef(''),
      );

      setLeftItems(shuffledLeftItems);
      setRightItems(shuffledRightItems);
    }
  }, [lesson.answers]);

  useEffect(() => {
    if (
      lines.length === lesson.answers.length &&
      !lineDrawing &&
      lines.length > 0
    ) {
      checkAnswers();
      checkLines();

      setLinesValidated(true);
    }
  }, [lineDrawing]);

  const startLineDrawing = ({ event, id, pointRef, type }) => {
    const pagePosition = rootRef.current.getBoundingClientRect();
    const elementPosition = pointRef.current.getBoundingClientRect();

    const canAddLine = lines.length === lesson.answers.length && !lineDrawing;

    if (!canAddLine) {
      setLineDrawing(true);

      setStartLinePosition({
        x:
          elementPosition.left +
          elementPosition.width -
          containerRef.current.offsetLeft -
          pagePosition.x,
        y:
          elementPosition.top +
          elementPosition.height / 2 -
          containerRef.current.offsetTop -
          pagePosition.y,
        startId: id,
        startType: type,
      });
    }
  };

  const handleProcessDrawing = (event) => {
    const leftItersectionArea = getIntersectionArea(
      leftItemsRef.current,
      event,
    );

    const rightItersectionArea = getIntersectionArea(
      rightItemsRef.current,
      event,
    );

    const currentIntersectionArea = leftItersectionArea || rightItersectionArea;

    let point4X;
    let point4Y;

    const pagePosition = rootRef.current.getBoundingClientRect();

    if (currentIntersectionArea) {
      point4X =
        currentIntersectionArea.left +
        currentIntersectionArea.diameter / 2 -
        rootRef.current.offsetLeft;
      point4Y =
        currentIntersectionArea.top +
        currentIntersectionArea.diameter / 2 -
        containerRef.current.offsetTop -
        pagePosition.y;
    } else {
      point4X = event.clientX - rootRef.current.offsetLeft;
      point4Y = event.clientY - containerRef.current.offsetTop - pagePosition.y;
    }

    const nextLines = [...lines];

    const lineLength = (point4X - startLinePosition.x) / 2;

    const point2X = startLinePosition.x + lineLength;

    const point3X = event.clientX - lineLength - rootRef.current.offsetLeft;

    nextLines[currentLineIndex] = {
      startId: startLinePosition.startId,
      startType: startLinePosition.startType,
      point1: { x: startLinePosition.x, y: startLinePosition.y },
      point2: {
        x: point2X,
        y: startLinePosition.y,
      },
      point3: {
        x: point3X,
        y: event.clientY - containerRef.current.offsetTop - pagePosition.y,
      },
      point4: {
        x: point4X,
        y: point4Y,
      },
    };

    setLines(nextLines);
  };

  const finishLineDrawing = ({ id, pointRef, type }) => {
    const pagePosition = rootRef.current.getBoundingClientRect();

    const elementBox = pointRef.current.getBoundingClientRect();

    const nextLines = [...lines];

    const point4X =
      elementBox.left + elementBox.width / 2 - rootRef.current.offsetLeft;

    nextLines[currentLineIndex] = {
      ...nextLines[currentLineIndex],
      finishId: id,
      finishType: type,
      color: LINE_COLORS.ROYAL_BLUE_VIOLET,
      point4: {
        x: point4X,
        y:
          elementBox.top +
          elementBox.height / 2 -
          containerRef.current.offsetTop -
          pagePosition.y,
      },
    };

    setLines(nextLines);
    setLineDrawing(false);
    setCurrentLineIndex(currentLineIndex + 1);
    setStartLinePosition({});
  };

  const checkAnswers = () => {
    setCorrectLinesIds(
      lines
        .filter((item) => item.startId === item.finishId)
        .map((item) => item.startId),
    );
  };

  const checkLines = () => {
    const checkedItems = lines.map((item) => {
      if (item.startId === item.finishId) {
        return { ...item, color: LINE_COLORS.SHAMROCK };
      } else {
        return { ...item, color: LINE_COLORS.RADIACAL_RED };
      }
    });

    setLines(checkedItems);
  };

  const deleteLine = (id, type) => {
    if (lines.length > 0 && !linesValidated) {
      const nextLines = [...lines];
      const updatedLines = nextLines.filter(
        (line) =>
          !(
            (line.startId === id && line.startType === type) ||
            (line.finishId === id && line.finishType === type)
          ),
      );

      setLines(updatedLines);
      setCurrentLineIndex(currentLineIndex - 1);
    }
  };

  const handleDeleteIncorrectLines = () => {
    const nextLines = [...lines];

    const updatedLines = nextLines
      .filter((line) => line.startId === line.finishId)
      .map((line) => ({ ...line, color: LINE_COLORS.ROYAL_BLUE_VIOLET }));

    setLines(updatedLines);

    const nextLeftItems = shuffleIncorrectAnswers(leftItems, updatedLines);
    const nextRightItems = shuffleIncorrectAnswers(rightItems, updatedLines);

    setLeftItems(nextLeftItems);
    setRightItems(nextRightItems);

    setCurrentLineIndex(
      currentLineIndex - nextLines.length + updatedLines.length,
    );

    setLinesValidated(false);
  };

  const handleItemClick = ({ event, id, pointRef, type, pointUsed }) => {
    if (startLinePosition.startType === type) {
      return;
    }

    const canDeleteLine = lines.some(
      (line) =>
        (line.startId === id && line.startType === type) ||
        (line.finishId === id && line.finishType === type),
    );

    if (canDeleteLine) {
      deleteLine(id, type);
    }

    if (lineDrawing && !pointUsed) {
      finishLineDrawing({ event, id, pointRef, type });
    } else {
      startLineDrawing({ event, id, pointRef, type });
    }
  };

  const handleRestartLessonClick = () => {
    setLinesValidated(false);
    setLines([]);
    setCorrectLinesIds([]);
    setCurrentLineIndex(0);
  };

  const handleChangePreviewType = (type) => {
    setPreviewType(type);
    handleRestartLessonClick();
  };

  return (
    <ConnectionQuizPreviewPage
      lineDrawing={lineDrawing}
      linesValidated={linesValidated}
      containerRef={containerRef}
      leftItemsRef={leftItemsRef}
      rightItemsRef={rightItemsRef}
      rootRef={rootRef}
      leftItems={leftItems}
      rightItems={rightItems}
      lines={lines}
      correctLinesIds={correctLinesIds}
      lesson={lesson}
      startLinePosition={startLinePosition}
      onProcessDrawing={handleProcessDrawing}
      onItemClick={handleItemClick}
      onTryAgainClick={handleDeleteIncorrectLines}
      onRestartLessonClick={handleRestartLessonClick}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
      onChangePreviewType={handleChangePreviewType}
    />
  );
};
export default React.memo(ConnectionQuizPreviewPageContainer);
