import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const getLessonStatusContextItems = ({ courseReadOnly, status }) => {
  if (!courseReadOnly) {
    switch (status) {
      case STATUSES.DRAFT: {
        return [
          {
            key: STATUSES.ARCHIVED,
            icon: 'archived',
            text: getTranslation(
              'helpers.get_lesson_status_context_item.move_to_archive',
            ),
            value: STATUSES.ARCHIVED,
          },
        ];
      }
      case STATUSES.ARCHIVED: {
        return [
          {
            key: STATUSES.DRAFT,
            icon: 'drafts',
            text: getTranslation(
              'helpers.get_lesson_status_context_item.restore',
            ),
            value: STATUSES.DRAFT,
          },
        ];
      }
    }
  }

  switch (status) {
    case STATUSES.DRAFT: {
      return [
        {
          key: STATUSES.PUBLISHED,
          icon: 'published',
          text: getTranslation(
            'helpers.get_lesson_status_context_item.publish',
          ),
          value: STATUSES.PUBLISHED,
        },
        {
          key: STATUSES.ARCHIVED,
          icon: 'archived',
          text: getTranslation(
            'helpers.get_lesson_status_context_item.move_to_archive',
          ),
          value: STATUSES.ARCHIVED,
        },
      ];
    }
    case STATUSES.PUBLISHED: {
      return [
        {
          key: STATUSES.DRAFT,
          icon: 'drafts',
          text: getTranslation(
            'helpers.get_lesson_status_context_item.move_to_draft',
          ),
          value: STATUSES.DRAFT,
        },
      ];
    }
    case STATUSES.ARCHIVED: {
      return [
        {
          key: STATUSES.DRAFT,
          icon: 'drafts',
          text: getTranslation(
            'helpers.get_lesson_status_context_item.restore',
          ),
          value: STATUSES.DRAFT,
        },
      ];
    }
  }
};
