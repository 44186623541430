import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './MatchInfo.styles.scss';

const MatchInfo = ({ value }) => (
  <div className={styles.info}>
    <Typography
      className={cn(styles.value, {
        [styles.valueLow]: value > 0 && value <= 30,
        [styles.valueMedium]: value > 30 && value <= 65,
        [styles.valueHigh]: value > 65,
      })}
      variant="subtitle"
    >
      {value}%
    </Typography>
    <Typography className={styles.text} variant="body3" mode="compact">
      {getTranslation('color_match_lesson_preview_page.match_info.matching')}
    </Typography>
  </div>
);

export default React.memo(MatchInfo);
