export const getPropertyIcon = (refType) => {
  switch (refType) {
    case 'characters': {
      return 'textChar';
    }
    case 'visible': {
      return 'booleanProperty';
    }
  }
};
