import { getTranslation } from 'helpers/getTranslation';

export const STUDENT_ACTIVATION_TYPES = {
  DIRECT: 'DIRECT',
  PROMOCODE: 'PROMOCODE',
};
export const USER_ROLES = {
  ROOT_ADMIN: 'ROOT_ADMIN',
  SCHOOL_ADMIN: 'SCHOOL_ADMIN',
};
export const STATUSES = {
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
};
export const COURSE_STRUCTURES = {
  MODULES: 'MODULES',
  LESSONS: 'LESSONS',
};
export const ARCADE_STRUCTURES = {
  MODULES: 'MODULES',
  LESSONS: 'LESSONS',
};
export const TRAINING_STRUCTURES = {
  MODULES: 'MODULES',
  LESSONS: 'LESSONS',
};
export const COURSE_STRUCTURE_TEXT = {
  MODULES: getTranslation('constants.common.modules'),
  LESSONS: getTranslation('constants.common.lessons'),
};
export const COURSE_CONTEXT_ITEMS = [
  {
    key: 'publish',
    icon: 'folder',
    text: getTranslation('constants.common.publish'),
  },
  {
    key: 'edit',
    icon: 'edit',
    text: getTranslation('constants.common.edit'),
  },
  {
    key: 'remove',
    icon: 'trash',
    text: getTranslation('constants.common.delete'),
  },
];
export const LESSON_TYPES = {
  FIGMA_LESSON: 'FIGMA_LESSON',
  THEORY_LESSON: 'THEORY_LESSON',
  QUIZ: 'QUIZ',
  CONNECTION_QUIZ: 'CONNECTION_QUIZ',
  DIFFERENCE_LESSON: 'DIFFERENCE_LESSON',
  CHAT_LESSON: 'CHAT_LESSON',
  TWO_COLUMNS_LESSON: 'TWO_COLUMNS_LESSON',
  COLOR_MATCH_LESSON: 'COLOR_MATCH_LESSON',
};
export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const COURSE_PLATFORMS = {
  FIGMA_PLUGIN: 'FIGMA_PLUGIN',
  WEB: 'WEB',
};
export const COURSE_PLATFORMS_TEXT = {
  FIGMA_PLUGIN: getTranslation('constants.common.plugin'),
  WEB: getTranslation('constants.common.web'),
};

export const COURSE_TYPES = {
  COURSE: 'COURSE',
  TRAINING: 'TRAINING',
  ARCADE: 'ARCADE',
};

export const CONTEXT_LESSONS_ITEMS = [
  {
    key: LESSON_TYPES.THEORY_LESSON,
    icon: 'theory',
    text: getTranslation('constants.common.theory'),
    value: LESSON_TYPES.THEORY_LESSON,
  },
  {
    key: LESSON_TYPES.QUIZ,
    icon: 'quiz',
    text: getTranslation('constants.common.quiz'),
    value: LESSON_TYPES.QUIZ,
  },
  {
    key: LESSON_TYPES.CONNECTION_QUIZ,
    icon: 'connectionQuiz',
    text: getTranslation('constants.common.connection_quiz'),
    value: LESSON_TYPES.CONNECTION_QUIZ,
  },
  {
    key: LESSON_TYPES.DIFFERENCE_LESSON,
    icon: 'difference',
    text: getTranslation('constants.common.difference'),
    value: LESSON_TYPES.DIFFERENCE_LESSON,
  },
  {
    key: LESSON_TYPES.TWO_COLUMNS_LESSON,
    icon: 'twoColumns',
    text: getTranslation('constants.common.two_columns'),
    value: LESSON_TYPES.TWO_COLUMNS_LESSON,
  },
  {
    key: LESSON_TYPES.COLOR_MATCH_LESSON,
    icon: 'colorMatch',
    text: getTranslation('constants.common.color_match'),
    value: LESSON_TYPES.COLOR_MATCH_LESSON,
  },
  {
    key: LESSON_TYPES.FIGMA_LESSON,
    icon: 'practice',
    text: getTranslation('constants.common.practice'),
    value: LESSON_TYPES.FIGMA_LESSON,
  },
];

export const STATUS_ICON = {
  draft: 'drafts',
  archived: 'archived',
  published: 'published',
};

export const STATUS_TEXT = {
  draft: getTranslation('constants.common.draft'),
  archived: getTranslation('constants.common.archived'),
  published: getTranslation('constants.common.published'),
};

export const ADD_STUDENT_CONTEXT_ITEMS = [
  {
    key: 'CREATE_NEW',
    icon: 'plus',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.create_new'),
    textColor: '#021930',
    value: 'CREATE_NEW',
  },
  {
    key: 'IMPORT',
    icon: 'import',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.import'),
    textColor: '#021930',
    value: 'IMPORT',
  },
];

export const ADD_COURSE_CONTEXT_ITEMS = [
  {
    key: COURSE_PLATFORMS.FIGMA_PLUGIN,
    icon: 'figma',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.figma_plugin'),
    textColor: '#021930',
    value: COURSE_PLATFORMS.FIGMA_PLUGIN,
  },
  {
    key: COURSE_PLATFORMS.WEB,
    icon: 'browser',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.web'),
    textColor: '#021930',
    value: COURSE_PLATFORMS.WEB,
  },
];

export const ADD_ADMIN_CONTEXT_ITEMS = [
  {
    key: 'CREATE_NEW',
    icon: 'plus',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.create_new'),
    textColor: '#021930',
    value: 'CREATE_NEW',
  },
  {
    key: 'IMPORT',
    icon: 'import',
    iconColor: '#1182F6',
    text: getTranslation('constants.common.import'),
    textColor: '#021930',
    value: 'IMPORT',
  },
];
export const FIGMA_LAYER_TYPES = {
  FRAME: 'FRAME',
  VECTOR: 'VECTOR',
  LINE: 'LINE',
  RECTANGLE: 'RECTANGLE',
  ELLIPSE: 'ELLIPSE',
  STAR: 'STAR',
  POLYGON: 'POLYGON',
  REGULAR_POLYGON: 'REGULAR_POLYGON',
  GROUP: 'GROUP',
  TEXT: 'TEXT',
  COMPONENT_SET: 'COMPONENT_SET',
  COMPONENT: 'COMPONENT',
  INSTANCE: 'INSTANCE',
  BOOLEAN_OPERATION: 'BOOLEAN_OPERATION',
};

export const PREMIUM_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  SINGLE_PAYMENT: 'SINGLE_PAYMENT',
};
