import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { getArcadesIcon, getArcadesText } from './ArcadesTag.helpers.js';

import styles from './ArcadesTag.styles.scss';

const ArcadesTag = ({
  format,
  iconWrapperClassName,
  iconClassName,
  textClassName,
}) => (
  <div className={styles.tag}>
    <div className={cn(styles.iconWrapper, iconWrapperClassName)}>
      <Icon
        name={getArcadesIcon(format)}
        className={cn(styles.icon, iconClassName)}
      />
    </div>
    <Typography
      className={cn(styles.tagText, textClassName)}
      mode="compact"
      weigth="medium"
      variant="body3"
    >
      {getArcadesText(format)}
    </Typography>
  </div>
);

export default React.memo(ArcadesTag);
