import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './NotificationCard.styles.scss';
import { getVariantIcon } from './NotificationCard.helpers';

const VARIANTS = {
  info: styles.cardInfo,
  warning: styles.cardWarning,
};

const NotificationCard = ({
  className,
  variant,
  title,
  text,
  onCloseClick,
}) => (
  <div className={cn(styles.card, VARIANTS[variant], className)}>
    <Icon name={getVariantIcon(variant)} className={styles.icon} />
    <div className={styles.inner}>
      {title && (
        <Typography variant="body2" weight="medium" className={styles.title}>
          {title}
        </Typography>
      )}
      <Typography variant="body3" className={styles.text}>
        {text}
      </Typography>
    </div>
    <Button
      variant="ghost"
      mode="icon"
      size="sm"
      iconName="cross"
      className={styles.button}
      onClick={onCloseClick}
    />
  </div>
);

NotificationCard.defaultProps = {
  variant: 'info',
};

export default React.memo(NotificationCard);
