import React, { useState, useEffect } from 'react';

import { getStepsByVariant } from './RemoveWithStepsModal.helpers';

import RemoveWithStepsModal from './RemoveWithStepsModal';

const RemoveWithStepsModalContainer = ({
  open,
  variant,
  requestParams,
  onRemoved,
  ...restProps
}) => {
  const [steps, setSteps] = useState(variant ? getStepsByVariant(variant) : []);
  const [currentStep, setCurrentStep] = useState(0);

  const _doStep = async () => {
    const step = steps[currentStep];

    try {
      setSteps((prevSteps) =>
        prevSteps.map((prevStep) => {
          if (prevStep.key === step.key) {
            return {
              ...prevStep,
              loading: true,
              error: false,
            };
          }

          return {
            ...prevStep,
            loading: false,
          };
        }),
      );

      await step.request(requestParams);

      setCurrentStep((prevStep) => prevStep + 1);
      setSteps((prevSteps) =>
        prevSteps.map((prevStep) => {
          if (prevStep.key === step.key) {
            return {
              ...prevStep,
              loading: false,
              completed: true,
            };
          }

          return {
            ...prevStep,
            loading: false,
          };
        }),
      );
    } catch (error) {
      setSteps((prevSteps) =>
        prevSteps.map((prevStep) => {
          if (prevStep.key === step.key) {
            return {
              ...prevStep,
              loading: false,
              error: true,
            };
          }

          return {
            ...prevStep,
            loading: false,
          };
        }),
      );
    }
  };

  useEffect(() => {
    if (open && steps.length > currentStep) {
      _doStep();
    }

    if (steps.length === currentStep && onRemoved) {
      onRemoved();
    }
  }, [open, currentStep]);

  const handleRetryClick = () => _doStep();

  return (
    <RemoveWithStepsModal
      {...restProps}
      open={open}
      steps={steps}
      onRetryClick={handleRetryClick}
    />
  );
};

export default React.memo(RemoveWithStepsModalContainer);
