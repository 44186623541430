import React from 'react';

import { CoursesSection } from '../CoursesSection';
import { ActivationSection } from '../ActivationSection';

const School = ({
  locale,
  isRootAdmin,
  courses,
  student,
  status,
  createdAt,
  updatedAt,
  onCourseClick,
  onManageCoursesAccessClick,
  onGrantProAccessClick,
}) => (
  <>
    <ActivationSection
      {...status}
      locale={locale}
      student={student}
      createdAt={createdAt}
      updatedAt={updatedAt}
    />

    <CoursesSection
      isRootAdmin={isRootAdmin}
      courses={courses}
      locale={locale}
      onCourseClick={onCourseClick}
      onManageCoursesAccessClick={onManageCoursesAccessClick}
      onGrantProAccessClick={onGrantProAccessClick}
    />
  </>
);

export default React.memo(School);
