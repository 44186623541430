import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import styles from './GoodToKnowSection.styles.scss';

const GoodToKnowSection = ({
  withGoodToKnow,
  onClick,
  onAddClick,
  onRemoveClick,
}) => {
  if (!withGoodToKnow) {
    return (
      <Button
        className={styles.button}
        size="md"
        variant="link"
        startIcon={<Icon name="plus" />}
        onClick={onAddClick}
      >
        {getTranslation('figma_lesson_page.good_to_know_section.add_section')}
      </Button>
    );
  }

  return (
    <div
      className={cn(styles.section, {
        [styles.sectionSelected]: false,
      })}
      onClick={onClick}
    >
      <div className={styles.inner}>
        <Typography
          className={styles.name}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {getTranslation(
            'figma_lesson_page.good_to_know_section.good_to_know',
          )}
        </Typography>
      </div>

      <Button
        className={styles.removeBtn}
        variant="ghost"
        mode="icon"
        size="sm"
        iconName="trash"
        onClick={onRemoveClick}
      />
    </div>
  );
};

export default React.memo(GoodToKnowSection);
