import React from 'react';
import { DragOverlay, DndContext, pointerWithin } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { DragMenu } from 'components/DragMenu';
import { SortableItem } from 'components/SortableItem';

import { Answer } from '../Answer';

import styles from './Answers.styles.scss';

const Answers = ({
  readOnly,
  answers,
  sensors,
  answersType,
  activeDragAnswer,
  activeDragAnswerIndex,
  onAnswerDragStart,
  onAnswerDragEnd,
  onAnswerDragOver,
  onAddAnswerClick,
  onRemoveAnswerClick,
}) => (
  <>
    <Typography
      className={styles.title}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('connection_quiz_page.answers.title')}
    </Typography>
    {answers && answers.length > 0 && (
      <div className={styles.answers}>
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragStart={onAnswerDragStart}
          onDragEnd={onAnswerDragEnd}
          onDragOver={onAnswerDragOver}
        >
          <SortableContext items={answers} strategy={() => null}>
            {answers.map((answer, answerIndex) => (
              <SortableItem
                className={styles.answer}
                key={answer.id}
                id={answer.id}
              >
                {({ listeners }) => (
                  <>
                    {!readOnly && (
                      <DragMenu
                        className={styles.answerMenu}
                        dragListeners={listeners}
                        onRemoveClick={() => onRemoveAnswerClick(answer)}
                      />
                    )}
                    <Answer
                      readOnly={readOnly}
                      order={answerIndex + 1}
                      answersType={answersType}
                      namePrefix={`answers[${answerIndex}]`}
                    />
                  </>
                )}
              </SortableItem>
            ))}
          </SortableContext>
          <DragOverlay>
            {activeDragAnswer ? (
              <Answer
                readOnly
                {...activeDragAnswer}
                order={activeDragAnswerIndex + 1}
                answersType={answersType}
                namePrefix={`answers[${activeDragAnswerIndex}]`}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    )}

    {!readOnly && (
      <Button
        className={styles.addBtn}
        size="sm"
        variant="link"
        startIcon={<Icon name="plus" />}
        onClick={onAddAnswerClick}
      >
        {getTranslation('common.button.add_answer')}
      </Button>
    )}
  </>
);

export default React.memo(Answers);
