import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { updateAdminById } from 'store/admin/actions';

import { currentAdminSelector } from 'store/admin/selectors';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import EditAdmin from './EditAdmin';

const EditAdminContainer = ({ onClose, ...restProps }) => {
  const [updateLoading, setUpdateLoading] = useState(false);

  const dispatch = useDispatch();
  const currentAdmin = useSelector(currentAdminSelector);
  const { i18n } = useTranslation();

  const handleSaveClick = async (values) => {
    setUpdateLoading(true);

    try {
      await dispatch(
        updateAdminById({
          adminId: values.id,
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        }),
      ).unwrap();

      onClose();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.admin_update.title'),
          text: getTranslation('common.toast.admin_update.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.erroe.title'),
          text: getTranslation('common.toast.erroe.text'),
        },
      });
    }

    setUpdateLoading(false);
  };

  return (
    <EditAdmin
      {...restProps}
      locale={i18n.language}
      updateLoading={updateLoading}
      currentAdmin={currentAdmin}
      onClose={onClose}
      onUpdateStudentClick={handleSaveClick}
    />
  );
};

export default React.memo(EditAdminContainer);
