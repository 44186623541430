import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './TypesSelect.styles.scss';

const SIZES = {
  md: styles.md,
  lg: styles.lg,
};

const TypesSelect = ({ options, size, onOptionClick }) => (
  <div className={cn(styles.select, SIZES[size])}>
    {options.map((option) => (
      <div
        className={styles.option}
        key={option.key}
        onClick={() => onOptionClick(option)}
      >
        <Icon className={styles.optionIcon} name={option.icon} />
        <Typography
          className={styles.optionText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {option.text}
        </Typography>
      </div>
    ))}
  </div>
);

TypesSelect.defaultProps = {
  size: 'lg',
};

export default React.memo(TypesSelect);
