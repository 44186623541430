import React, { useEffect, useRef } from 'react';

import EditableText from './EditableText';

const EditableTextContainer = ({ autoFocus, value, ...restProps }) => {
  const editableRef = useRef();

  useEffect(() => {
    if (editableRef.current && autoFocus && !value) {
      editableRef.current.focus();
    }
  }, [autoFocus, editableRef]);

  return (
    <EditableText {...restProps} editableRef={editableRef} value={value} />
  );
};

export default React.memo(EditableTextContainer);
