import React from 'react';

import { LessonPreviewLayout } from 'components/Layout';
import { PluginPreviewPage } from './components/PluginPreviewPage';
import { WebPreviewPage } from './components/WebPreviewPage';

const ConnectionQuizPreviewPage = ({
  rootRef,
  containerRef,
  leftItemsRef,
  rightItemsRef,
  lineDrawing,
  linesValidated,
  leftItems,
  rightItems,
  lines,
  correctLinesIds,
  startLinePosition,
  lesson,
  onProcessDrawing,
  onItemClick,
  onTryAgainClick,
  onRestartLessonClick,
  previewType,
  setPreviewType,
  isTestMode,
  setTestMode,
  onChangePreviewType,
  ...props
}) => (
  <LessonPreviewLayout
    setPreviewType={onChangePreviewType}
    setTestMode={setTestMode}
  >
    {previewType === 'Plugin' ? (
      <PluginPreviewPage
        {...props}
        lineDrawing={lineDrawing}
        linesValidated={linesValidated}
        containerRef={containerRef}
        leftItemsRef={leftItemsRef}
        rightItemsRef={rightItemsRef}
        rootRef={rootRef}
        leftItems={leftItems}
        rightItems={rightItems}
        lines={lines}
        correctLinesIds={correctLinesIds}
        lesson={lesson}
        startLinePosition={startLinePosition}
        onProcessDrawing={onProcessDrawing}
        onItemClick={onItemClick}
        onTryAgainClick={onTryAgainClick}
        onRestartLessonClick={onRestartLessonClick}
        isTestMode={isTestMode}
      />
    ) : (
      <WebPreviewPage
        {...props}
        lineDrawing={lineDrawing}
        linesValidated={linesValidated}
        containerRef={containerRef}
        leftItemsRef={leftItemsRef}
        rightItemsRef={rightItemsRef}
        rootRef={rootRef}
        leftItems={leftItems}
        rightItems={rightItems}
        lines={lines}
        correctLinesIds={correctLinesIds}
        lesson={lesson}
        startLinePosition={startLinePosition}
        onProcessDrawing={onProcessDrawing}
        onItemClick={onItemClick}
        onTryAgainClick={onTryAgainClick}
        onRestartLessonClick={onRestartLessonClick}
        isTestMode={isTestMode}
      />
    )}
  </LessonPreviewLayout>
);

export default React.memo(ConnectionQuizPreviewPage);
