import { createSlice } from '@reduxjs/toolkit';

import { getSchoolById, updateSchoolById } from './actions';

const schoolSlice = createSlice({
  name: 'currentSchool',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchoolById.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateSchoolById.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export default schoolSlice.reducer;
