export const getQuestionTextByType = (type) => {
  switch (type) {
    case 'aspects':
    case 'aspectsOther': {
      return '1. What characterizes you the most?';
    }
    case 'characterize':
    case 'characterizeOther': {
      return '2. What aspects of UI/UX design do you find most challenging?';
    }
    case 'expectations': {
      return '3. What do you expect from the learning process?';
    }
    case 'howFind':
    case 'howFindOther': {
      return '4. One small thing. How did you find us?';
    }
  }
};
