import React from 'react';

import { AddLessonButton } from 'components/AddLessonButton';

import { Lessons } from '../Lessons';

import styles from './LessonsSettings.styles.scss';

const LessonsSettings = ({
  courseReadOnly,
  lessons,
  courseType,
  onEditLessonClick,
  onRemoveLessonClick,
}) => (
  <div className={styles.settings}>
    <Lessons
      courseReadOnly={courseReadOnly}
      lessons={lessons}
      onEditLessonClick={onEditLessonClick}
      onRemoveLessonClick={onRemoveLessonClick}
    />
    <AddLessonButton
      buttonClassName={styles.button}
      lessons={lessons}
      courseType={courseType}
    />
  </div>
);

export default React.memo(LessonsSettings);
