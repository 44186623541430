import React, { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import _debounce from 'lodash/debounce';

const AutoSave = ({ debounceMs }) => {
  const { values, submitForm } = useFormikContext();

  const debouncedSubmit = useCallback(
    _debounce(() => {
      return submitForm();
    }, debounceMs),
    [submitForm, debounceMs],
  );

  useEffect(() => {
    return debouncedSubmit;
  }, [debouncedSubmit, values]);

  return null;
};

export default React.memo(AutoSave);
