import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';

import { removeLessonById } from 'store/lesson/actions';

import { getTranslation } from 'helpers/getTranslation';

import { getLessonPathByCourseType } from 'helpers/getLessonPathByCourseType';

import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import LessonsSettings from './LessonsSettings';

const LessonsSettingsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { values } = useFormikContext();

  const { id: courseId } = useParams();

  const [
    confirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    confirmModalData,
  ] = useModal({
    defaultOpen: false,
  });

  const handleEditLessonClick = (lessonId) => {
    navigate(
      getLessonPathByCourseType({
        courseType: values.type,
        courseId,
        lessonId,
      }),
    );
  };

  const handleConfirmRemoveClick = async () => {
    setLoading(true);

    await dispatch(
      removeLessonById({ courseId, lessonId: confirmModalData.lessonId }),
    );

    setLoading(false);

    closeConfirmModal();
  };

  const handleRemoveLessonClick = (lesson) =>
    openConfirmModal({
      lessonId: lesson.id,
      title: getTranslation('course_page.lessons_settings.remove_lesson_title'),
      text: getTranslation('course_page.lessons_settings.remove_lesson_text', {
        title: lesson.title || getTranslation('common.text.untitled'),
      }),
    });

  return (
    <>
      <LessonsSettings
        courseReadOnly={values.readOnly}
        lessons={values.lessons}
        courseType={values.type}
        onEditLessonClick={handleEditLessonClick}
        onRemoveLessonClick={handleRemoveLessonClick}
      />
      <DefaultModal
        {...confirmModalData}
        loading={loading}
        open={confirmModalOpen}
        confirmText={getTranslation(
          'course_page.lessons_settings.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </>
  );
};

export default React.memo(LessonsSettingsContainer);
