import React from 'react';
import { Formik, Form } from 'formik';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import {
  InputField,
  SelectField,
  CheckboxField,
} from 'components/FormikFields';
import { CoursesAccessList } from 'components/CoursesAccessList';
import { EmptyState } from 'components/EmptyState';

import {
  getInitialValues,
  getValidationSchema,
  getDurationOptions,
} from './GenerateCode.formConfig';

import styles from './GenerateCode.styles.scss';

const GenerateCode = ({
  open,
  generateLoading,
  loadingCourses,
  courses,
  currentSchool,
  onClose,
  onGenerateClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('components.generate_code.header_text')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={getInitialValues({ courses, currentSchool })}
      validationSchema={getValidationSchema()}
      onSubmit={onGenerateClick}
    >
      <Form className={styles.form}>
        <div className={styles.main}>
          <FormikField
            name="duration"
            component={SelectField}
            componentProps={{ options: getDurationOptions() }}
            controlProps={{
              required: true,
              label: getTranslation('components.generate_code.duration'),
            }}
          />
          <FormikField
            name="quantity"
            component={InputField}
            componentProps={{ type: 'number' }}
            controlProps={{
              required: true,
              label: getTranslation('components.generate_code.quantity'),
            }}
          />
          <div className={styles.section}>
            <Typography
              className={styles.sectionTitle}
              variant="body1"
              weight="medium"
            >
              {getTranslation('components.generate_code.courses_access')}
            </Typography>
            {loadingCourses ? (
              <Loading />
            ) : (
              <div
                className={cn(styles.sectionInner, {
                  [styles.sectionInnerEmpty]: !courses || courses.length === 0,
                })}
              >
                {courses && courses.length > 0 ? (
                  <CoursesAccessList courses={courses} />
                ) : (
                  <EmptyState
                    className={styles.emptyState}
                    icon="courses"
                    title={getTranslation(
                      'components.generate_code.empty_state.title',
                    )}
                    text={getTranslation(
                      'components.generate_code.empty_state.text',
                    )}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.export}>
            <FormikField
              name="export"
              withControl={false}
              component={CheckboxField}
              componentProps={{ type: 'checkbox' }}
            />
            <Typography
              className={styles.exportText}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {getTranslation('components.generate_code.export')}
            </Typography>
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.action}
              size="md"
              variant="outlined"
              onClick={onClose}
            >
              {getTranslation('commmon.button.cancel')}
            </Button>
            <Button
              className={styles.action}
              type="submit"
              size="md"
              startIcon={
                generateLoading && (
                  <Loading size={18} color="var(--color-white-primary)" />
                )
              }
            >
              {getTranslation('commmon.button.generate')}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(GenerateCode);
