import React from 'react';

import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';

import styles from './TextTextProperty.styles.scss';

const keyboardMap = [
  '', // [0]
  '', // [1]
  '', // [2]
  'CANCEL', // [3]
  '', // [4]
  '', // [5]
  'HELP', // [6]
  '', // [7]
  'BACK_SPACE', // [8]
  'TAB', // [9]
  '', // [10]
  '', // [11]
  'CLEAR', // [12]
  'ENTER', // [13]
  'ENTER_SPECIAL', // [14]
  '', // [15]
  'SHIFT', // [16]
  'CONTROL', // [17]
  'ALT', // [18]
  'PAUSE', // [19]
  'CAPS_LOCK', // [20]
  'KANA', // [21]
  'EISU', // [22]
  'JUNJA', // [23]
  'FINAL', // [24]
  'HANJA', // [25]
  '', // [26]
  'ESCAPE', // [27]
  'CONVERT', // [28]
  'NONCONVERT', // [29]
  'ACCEPT', // [30]
  'MODECHANGE', // [31]
  'SPACE', // [32]
  'PAGE_UP', // [33]
  'PAGE_DOWN', // [34]
  'END', // [35]
  'HOME', // [36]
  'LEFT', // [37]
  'UP', // [38]
  'RIGHT', // [39]
  'DOWN', // [40]
  'SELECT', // [41]
  'PRINT', // [42]
  'EXECUTE', // [43]
  'PRINTSCREEN', // [44]
  'INSERT', // [45]
  'DELETE', // [46]
  '', // [47]
  '0', // [48]
  '1', // [49]
  '2', // [50]
  '3', // [51]
  '4', // [52]
  '5', // [53]
  '6', // [54]
  '7', // [55]
  '8', // [56]
  '9', // [57]
  'COLON', // [58]
  'SEMICOLON', // [59]
  'LESS_THAN', // [60]
  'EQUALS', // [61]
  'GREATER_THAN', // [62]
  'QUESTION_MARK', // [63]
  'AT', // [64]
  'A', // [65]
  'B', // [66]
  'C', // [67]
  'D', // [68]
  'E', // [69]
  'F', // [70]
  'G', // [71]
  'H', // [72]
  'I', // [73]
  'J', // [74]
  'K', // [75]
  'L', // [76]
  'M', // [77]
  'N', // [78]
  'O', // [79]
  'P', // [80]
  'Q', // [81]
  'R', // [82]
  'S', // [83]
  'T', // [84]
  'U', // [85]
  'V', // [86]
  'W', // [87]
  'X', // [88]
  'Y', // [89]
  'Z', // [90]
  'OS_KEY', // [91] Windows Key (Windows) or Command Key (Mac)
  '', // [92]
  'CONTEXT_MENU', // [93]
  '', // [94]
  'SLEEP', // [95]
  'NUMPAD0', // [96]
  'NUMPAD1', // [97]
  'NUMPAD2', // [98]
  'NUMPAD3', // [99]
  'NUMPAD4', // [100]
  'NUMPAD5', // [101]
  'NUMPAD6', // [102]
  'NUMPAD7', // [103]
  'NUMPAD8', // [104]
  'NUMPAD9', // [105]
  'MULTIPLY', // [106]
  'ADD', // [107]
  'SEPARATOR', // [108]
  'SUBTRACT', // [109]
  'DECIMAL', // [110]
  'DIVIDE', // [111]
  'F1', // [112]
  'F2', // [113]
  'F3', // [114]
  'F4', // [115]
  'F5', // [116]
  'F6', // [117]
  'F7', // [118]
  'F8', // [119]
  'F9', // [120]
  'F10', // [121]
  'F11', // [122]
  'F12', // [123]
  'F13', // [124]
  'F14', // [125]
  'F15', // [126]
  'F16', // [127]
  'F17', // [128]
  'F18', // [129]
  'F19', // [130]
  'F20', // [131]
  'F21', // [132]
  'F22', // [133]
  'F23', // [134]
  'F24', // [135]
  '', // [136]
  '', // [137]
  '', // [138]
  '', // [139]
  '', // [140]
  '', // [141]
  '', // [142]
  '', // [143]
  'NUM_LOCK', // [144]
  'SCROLL_LOCK', // [145]
  'WIN_OEM_FJ_JISHO', // [146]
  'WIN_OEM_FJ_MASSHOU', // [147]
  'WIN_OEM_FJ_TOUROKU', // [148]
  'WIN_OEM_FJ_LOYA', // [149]
  'WIN_OEM_FJ_ROYA', // [150]
  '', // [151]
  '', // [152]
  '', // [153]
  '', // [154]
  '', // [155]
  '', // [156]
  '', // [157]
  '', // [158]
  '', // [159]
  'CIRCUMFLEX', // [160]
  'EXCLAMATION', // [161]
  'DOUBLE_QUOTE', // [162]
  'HASH', // [163]
  'DOLLAR', // [164]
  'PERCENT', // [165]
  'AMPERSAND', // [166]
  'UNDERSCORE', // [167]
  'OPEN_PAREN', // [168]
  'CLOSE_PAREN', // [169]
  'ASTERISK', // [170]
  'PLUS', // [171]
  'PIPE', // [172]
  'HYPHEN_MINUS', // [173]
  'OPEN_CURLY_BRACKET', // [174]
  'CLOSE_CURLY_BRACKET', // [175]
  'TILDE', // [176]
  '', // [177]
  '', // [178]
  '', // [179]
  '', // [180]
  'VOLUME_MUTE', // [181]
  'VOLUME_DOWN', // [182]
  'VOLUME_UP', // [183]
  '', // [184]
  '', // [185]
  'SEMICOLON', // [186]
  'EQUALS', // [187]
  'COMMA', // [188]
  'MINUS', // [189]
  'PERIOD', // [190]
  'SLASH', // [191]
  'BACK_QUOTE', // [192]
  '', // [193]
  '', // [194]
  '', // [195]
  '', // [196]
  '', // [197]
  '', // [198]
  '', // [199]
  '', // [200]
  '', // [201]
  '', // [202]
  '', // [203]
  '', // [204]
  '', // [205]
  '', // [206]
  '', // [207]
  '', // [208]
  '', // [209]
  '', // [210]
  '', // [211]
  '', // [212]
  '', // [213]
  '', // [214]
  '', // [215]
  '', // [216]
  '', // [217]
  '', // [218]
  'OPEN_BRACKET', // [219]
  'BACK_SLASH', // [220]
  'CLOSE_BRACKET', // [221]
  'QUOTE', // [222]
  '', // [223]
  'META', // [224]
  'ALTGR', // [225]
  '', // [226]
  'WIN_ICO_HELP', // [227]
  'WIN_ICO_00', // [228]
  '', // [229]
  'WIN_ICO_CLEAR', // [230]
  '', // [231]
  '', // [232]
  'WIN_OEM_RESET', // [233]
  'WIN_OEM_JUMP', // [234]
  'WIN_OEM_PA1', // [235]
  'WIN_OEM_PA2', // [236]
  'WIN_OEM_PA3', // [237]
  'WIN_OEM_WSCTRL', // [238]
  'WIN_OEM_CUSEL', // [239]
  'WIN_OEM_ATTN', // [240]
  'WIN_OEM_FINISH', // [241]
  'WIN_OEM_COPY', // [242]
  'WIN_OEM_AUTO', // [243]
  'WIN_OEM_ENLW', // [244]
  'WIN_OEM_BACKTAB', // [245]
  'ATTN', // [246]
  'CRSEL', // [247]
  'EXSEL', // [248]
  'EREOF', // [249]
  'PLAY', // [250]
  'ZOOM', // [251]
  '', // [252]
  'PA1', // [253]
  'WIN_OEM_CLEAR', // [254]
  '', // [255]
];

export const getPropertyLabel = ({ type, dependValue }) => {
  switch (type) {
    case 'opacity':
    case 'fillOpacity':
    case 'strokeOpacity':
    case 'layoutGridOpacity':
    case 'effectOpacity': {
      return 'Opacity:';
    }
    case 'layoutGridSize': {
      return 'Size:';
    }

    case 'layoutGridCount': {
      return 'Count:';
    }
    case 'layoutGridType': {
      return 'Type:';
    }
    case 'layoutGridWidth': {
      if (dependValue === 'COLUMNS') {
        return 'Width:';
      }

      if (dependValue === 'ROWS') {
        return 'Height:';
      }

      return '';
    }
    case 'layoutGridOffset': {
      if (dependValue === 'STRETCH') {
        return 'Margin:';
      }

      return 'Offset:';
    }
    case 'layoutGridGutter': {
      return 'Gutter:';
    }
    case 'fillRotation':
    case 'strokeRotation': {
      return 'Rotation:';
    }
    case 'fillScaleType':
    case 'strokeScaleType': {
      return 'Scale type:';
    }
    case 'fillExposure':
    case 'strokeExposure': {
      return 'Exposure:';
    }
    case 'fillContrast':
    case 'strokeContrast': {
      return 'Contrast:';
    }
    case 'fillSaturation':
    case 'strokeSaturation': {
      return 'Saturation:';
    }
    case 'fillTemperature':
    case 'strokeTemperature': {
      return 'Temperature:';
    }
    case 'fillTint':
    case 'strokeTint': {
      return 'Tint:';
    }
    case 'fillHighlights':
    case 'strokeHighlights': {
      return 'Highlights:';
    }
    case 'fillShadows':
    case 'strokeShadows': {
      return 'Shadows:';
    }
    case 'fillScalingFactor':
    case 'strokeScalingFactor': {
      return 'Scale factor:';
    }
    case 'strokeAlign': {
      return 'Align:';
    }
    case 'strokeStyle': {
      return 'Style:';
    }
    case 'dashPattern': {
      return 'Dashes:';
    }
    case 'strokeMiterAngle': {
      return 'Miter angle:';
    }
    case 'effectBlur': {
      return 'Blur:';
    }
    case 'effectSpread': {
      return 'Spread:';
    }
    case 'parent': {
      return 'Parent:';
    }
    case 'fontName': {
      return 'Font name:';
    }
    case 'fontSize': {
      return 'Font size:';
    }
    case 'listSpacing': {
      return 'List spacing:';
    }
    case 'paragraphSpacing': {
      return 'Paragraph spacing:';
    }
    case 'paragraphIndent': {
      return 'Paragraph indent:';
    }
    case 'arcDataEndingAngle': {
      return 'Sweep:';
    }
    case 'arcDataInnerRadius': {
      return 'Ratio:';
    }
    case 'characters': {
      return 'Characters:';
    }
    case 'layerOrder': {
      return 'Layer order:';
    }
    case 'booleanOperation': {
      return 'Operation:';
    }
    case 'vectorPaths': {
      return 'Vector paths';
    }
    case 'interactionTriggerType': {
      return 'Trigger:';
    }
    case 'interactionTriggerDelay': {
      return 'Delay:';
    }
    case 'interactionTriggerKeyCodes': {
      return 'Key:';
    }
    case 'interactionTransition': {
      return 'Transition:';
    }
    case 'interactionTransitionEasing': {
      return 'Animation:';
    }
    case 'interactionTransitionDuration': {
      return 'Animation time:';
    }
    case 'interactionTransitionX1': {
      return 'X1:';
    }
    case 'interactionTransitionX2': {
      return 'X2:';
    }
    case 'interactionTransitionY1': {
      return 'Y1:';
    }
    case 'interactionTransitionY2': {
      return 'Y2:';
    }
    case 'interactionTransitionStiffness': {
      return 'Stiffness:';
    }
    case 'interactionTransitionDamping': {
      return 'Damping:';
    }
    case 'interactionTransitionMass': {
      return 'Mass:';
    }
    case 'interactionNavigationLinkUrl': {
      return 'Link:';
    }
    default: {
      return null;
    }
  }
};

const _getGridTypeTextByValue = (value) => {
  switch (value) {
    case 'MIN': {
      return 'Left';
    }
    case 'MAX': {
      return 'Right';
    }
    case 'CENTER': {
      return 'Center';
    }
    case 'STRETCH': {
      return 'Stretch';
    }
  }
};

const _getStrokeAlignTextByValue = (value) => {
  switch (value) {
    case 'INSIDE': {
      return 'Inside';
    }
    case 'OUTSIDE': {
      return 'Outside';
    }
    case 'CENTER': {
      return 'Center';
    }
  }
};

const _getStrokeStyleTextByValue = (value) => {
  switch (value) {
    case 'SOLID': {
      return 'Solid';
    }
    case 'DASH': {
      return 'Dash';
    }
    case 'CUSTOM': {
      return 'Custom';
    }
  }
};

const _getBooleanOperationTextByValue = (value) => {
  switch (value) {
    case 'INTERSECT': {
      return 'Intersect';
    }
    case 'UNION': {
      return 'Union';
    }
    case 'EXCLUDE': {
      return 'Exclude';
    }
    case 'SUBTRACT': {
      return 'Subtract';
    }
  }
};

const _getInteractionTriggerTypeTextByValue = (value) => {
  switch (value) {
    case 'ON_CLICK': {
      return 'On click';
    }
    case 'ON_DRAG': {
      return 'On drag';
    }
    case 'WHILE_HOVERING': {
      return 'While hovering';
    }
    case 'WHILE_PRESSING': {
      return 'While pressing';
    }
    case 'ON_KEY_DOWN': {
      return 'Key/Gamepad';
    }
    case 'MOUSE_ENTER': {
      return 'Mouse enter';
    }
    case 'MOUSE_LEAVE': {
      return 'Mouse leave';
    }
    case 'MOUSE_DOWN': {
      return 'Mouse down';
    }
    case 'MOUSE_UP': {
      return 'Mouse up';
    }
  }
};

const _getInteractionTransitionTextByValue = (value) => {
  switch (value) {
    case 'SCROLL_ANIMATE': {
      return 'Animate';
    }
    case 'DISSOLVE': {
      return 'Dissolve';
    }
    case 'SMART_ANIMATE': {
      return 'Smart animate';
    }
    case 'MOVE_IN': {
      return 'Move in';
    }
    case 'MOVE_OUT': {
      return 'Move out';
    }
    case 'PUSH': {
      return 'Push';
    }
    case 'SLIDE_IN': {
      return 'Slide in';
    }
    case 'SLIDE_OUT': {
      return 'Slide out';
    }
  }
};

const _getInteractionTransitionEasingTextByValue = (value) => {
  switch (value) {
    case 'EASE_IN': {
      return 'Ease in';
    }
    case 'EASE_OUT': {
      return 'Ease out';
    }
    case 'EASE_IN_AND_OUT':
      return 'Ease in and out';
    case 'LINEAR': {
      return 'Linear';
    }
    case 'EASE_IN_BACK': {
      return 'Ease in back';
    }
    case 'EASE_OUT_BACK': {
      return 'Ease out back';
    }
    case 'EASE_IN_AND_OUT_BACK': {
      return 'Ease in and out back';
    }
    case 'CUSTOM_CUBIC_BEZIER': {
      return 'Custom bezier';
    }
    case 'GENTLE': {
      return 'Gentle';
    }
    case 'QUICK': {
      return 'Quick';
    }
    case 'BOUNCY': {
      return 'Bouncy';
    }
    case 'SLOW': {
      return 'Slow';
    }
    case 'CUSTOM_SPRING': {
      return 'Custom spring';
    }
  }
};

export const getPropertyValue = ({ type, value, dependValue }) => {
  switch (type) {
    case 'opacity': {
      if (value === 1) {
        return '100%';
      }

      return `${Number((value * 100).toFixed(3))}%`;
    }
    case 'layoutGridOpacity':
    case 'fillOpacity':
    case 'strokeOpacity':
    case 'effectOpacity': {
      if (value === 1) {
        return '100%';
      }

      return `${Number((value * 100).toFixed(2))}%`;
    }
    case 'layoutGridType': {
      return _getGridTypeTextByValue(value);
    }
    case 'layoutGridWidth': {
      if (dependValue === 'COLUMNS' || dependValue === 'ROWS') {
        return !value ? 'Auto' : value;
      }

      return value;
    }
    case 'layoutGridSize':
    case 'layoutGridCount':
    case 'layoutGridOffset':
    case 'layoutGridGutter':
    case 'fillRotation':
    case 'strokeRotation':
    case 'effectBlur':
    case 'effectSpread':
    case 'fontSize':
    case 'listSpacing':
    case 'paragraphSpacing':
    case 'paragraphIndent':
    case 'characters':
    case 'interactionNavigationLinkUrl': {
      return value;
    }
    case 'layerOrder': {
      return value + 1;
    }
    case 'fontName': {
      return `${value.family} ${value.style}`;
    }
    case 'fillScaleType':
    case 'strokeScaleType': {
      return _upperFirst(_toLower(value));
    }
    case 'fillExposure':
    case 'fillContrast':
    case 'fillSaturation':
    case 'fillTemperature':
    case 'fillTint':
    case 'fillHighlights':
    case 'fillShadows':
    case 'strokeExposure':
    case 'strokeContrast':
    case 'strokeSaturation':
    case 'strokeTemperature':
    case 'strokeTint':
    case 'strokeHighlights':
    case 'strokeShadows': {
      if (value === 0) {
        return 0;
      }

      return value.toFixed(2);
    }
    case 'fillScalingFactor':
    case 'strokeScalingFactor': {
      if (value === 1) {
        return '100%';
      }

      return `${Number((value * 100).toFixed(2))}%`;
    }
    case 'strokeAlign': {
      return _getStrokeAlignTextByValue(value);
    }
    case 'strokeStyle': {
      return _getStrokeStyleTextByValue(value);
    }
    case 'dashPattern': {
      return value.join(', ');
    }
    case 'strokeMiterAngle': {
      return `${Number(value.toFixed(2))}°`;
    }
    case 'parent': {
      if (!value || !value.name) {
        return 'None';
      }

      return value.name;
    }
    case 'arcDataEndingAngle': {
      const startingAngleDegree = (dependValue * 180) / Math.PI;
      const endingAngleDegree = (value * 180) / Math.PI - startingAngleDegree;

      const sweep = (endingAngleDegree / 360) * 100;

      return `${Number(sweep.toFixed(2))}%`;
    }
    case 'arcDataInnerRadius': {
      return `${Number((value * 100).toFixed(1))}%`;
    }
    case 'booleanOperation': {
      return _getBooleanOperationTextByValue(value);
    }
    case 'vectorPaths': {
      if (!value || value.length === 0) {
        return <span className={styles.missingValue}>Sync in plugin</span>;
      }

      return '';
    }
    case 'interactionTriggerType': {
      return _getInteractionTriggerTypeTextByValue(value);
    }
    case 'interactionTransition': {
      if (!value) {
        return 'Instant';
      }

      return _getInteractionTransitionTextByValue(value);
    }
    case 'interactionTransitionEasing': {
      if (!value) {
        return null;
      }

      return _getInteractionTransitionEasingTextByValue(value);
    }
    case 'interactionTransitionDuration':
    case 'interactionTriggerDelay': {
      if (!value) {
        return null;
      }

      return `${Number((value * 1000).toFixed(0))} ms`;
    }
    case 'interactionTransitionX1':
    case 'interactionTransitionX2':
    case 'interactionTransitionY1':
    case 'interactionTransitionY2':
    case 'interactionTransitionStiffness':
    case 'interactionTransitionDamping':
    case 'interactionTransitionMass': {
      if (!value) {
        return null;
      }

      return Number(value.toFixed(2));
    }

    case 'interactionTriggerKeyCodes': {
      if (!value) {
        return null;
      }

      return value.map((code) => keyboardMap[code]).join('+');
    }

    default: {
      return null;
    }
  }
};
