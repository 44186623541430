import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getStudents = createAsyncThunk(
  types.GET_STUDENTS,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const students = isRootAdmin
      ? await api.getStudents(params)
      : await api.getSchoolStudents({ schoolId: user.schoolId, params });

    return students;
  },
);

export const getSchoolStudents = createAsyncThunk(
  types.GET_SCHOOL_STUDENTS,
  async (data) => {
    const students = await api.getSchoolStudents(data);

    return students;
  },
);

export const createStudent = createAsyncThunk(
  types.CREATE_STUDENT,
  async (data) => {
    const student = await api.createStudent(data);

    return student;
  },
);

export const finishImportStudents = createAsyncThunk(
  types.FINISH_IMPORT_STUDENTS,
  async (data) => {
    const students = await api.finishImportStudents(data);

    return students;
  },
);

export const removeStudentById = createAction(types.REMOVE_STUDENT_BY_ID);
