export const getLayerLayoutGrids = (layer) => {
  const layoutGrids = layer.layoutGrids;

  if (!layoutGrids || !layoutGrids.length === 0) {
    return [];
  }

  return layoutGrids.map((layoutGrid) => {
    switch (layoutGrid.pattern) {
      case 'GRID': {
        return {
          pattern: layoutGrid.pattern,
          sectionSize: layoutGrid.sectionSize,
          visible: layoutGrid.visible,
          color: {
            r: layoutGrid.color.r,
            g: layoutGrid.color.g,
            b: layoutGrid.color.b,
            a: Number(layoutGrid.color.a.toFixed(2)),
          },
        };
      }
      case 'COLUMNS':
      case 'ROWS': {
        const properties = {
          alignment: layoutGrid.alignment,
          count: layoutGrid.count,
          gutterSize: layoutGrid.gutterSize,
          pattern: layoutGrid.pattern,
          visible: layoutGrid.visible,
          color: {
            r: layoutGrid.color.r,
            g: layoutGrid.color.g,
            b: layoutGrid.color.b,
            a: Number(layoutGrid.color.a.toFixed(2)),
          },
        };

        if (layoutGrid.alignment !== 'CENTER') {
          properties.offset = layoutGrid.offset;
        }

        if (layoutGrid.alignment !== 'STRETCH') {
          properties.sectionSize = layoutGrid.sectionSize;
        }

        return properties;
      }
    }
  });
};
