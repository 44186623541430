import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';
import { getSchools } from 'store/schools/actions';
import { schoolsSelector } from 'store/schools/selectors';

import { useModal } from 'hooks/useModal';

import { AddSchool } from './components/AddSchool';

import SchoolsPage from './SchoolsPage';

const SchoolsPageContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const schools = useSelector(schoolsSelector);

  const [loading, setLoading] = useState(true);

  const [addSchoolOpen, openAddSchool, closeAddSchool] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    if (user && user.role !== USER_ROLES.ROOT_ADMIN) {
      navigate('/dashboard', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    const _getSchools = async () => {
      try {
        await dispatch(getSchools()).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getSchools();
  }, []);

  const handleCreateSchoolClick = () => {
    openAddSchool();
  };

  const handleSchoolRowClick = (courseId) => {
    navigate(`/schools/${courseId}`);
  };

  const handleSchoolEditClick = (course) => {
    navigate(`/schools/${course.id}`);
  };

  return (
    <>
      <SchoolsPage
        loading={loading}
        schools={schools}
        onCreateSchoolClick={handleCreateSchoolClick}
        onSchoolRowClick={handleSchoolRowClick}
        onSchoolEditClick={handleSchoolEditClick}
      />
      <AddSchool open={addSchoolOpen} onClose={closeAddSchool} />
    </>
  );
};

export default React.memo(SchoolsPageContainer);
