import axios from 'axios';

export const createFigmaLesson = (data) =>
  axios.post('/api/v1/figma-lessons', data);

export const saveFigmaLessonMaterials = ({ lessonId, data }) =>
  axios.put(`/api/v1/figma-lessons/${lessonId}/materials`, data);

export const updateFigmaLessonById = ({ lessonId, data }) =>
  axios.put(`/api/v1/figma-lessons/${lessonId}`, data);

export const removeFigmaLessonById = (lessonId) =>
  axios.delete(`/api/v1/figma-lessons/${lessonId}`);
