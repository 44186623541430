import React from 'react';

import { ADD_STUDENT_CONTEXT_ITEMS } from 'constants/common';

import { getStudents } from 'store/students/actions';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { MenuLayout } from 'components/Layout';
import { ListToolbar } from 'components/Toolbar';
import { EmptyState } from 'components/EmptyState';
import { StudentsTable } from 'components/StudentsTable';
import { CreateButton } from 'components/CreateButton';

import styles from './StudentsPage.styles.scss';

const StudentsPage = ({
  loading,
  canCreateStudent,
  students,
  studentsColumns,
  sortParams,
  onSortChange,
  onStudentRowClick,
  onCreateStudentClick,
  onEditStudentClick,
  onRemoveStudentClick,
  onExportStudentsClick,
}) => {
  return (
    <MenuLayout>
      <ListToolbar
        title={getTranslation('students_page.title')}
        actionComponent={
          <div className={styles.toolbarItems}>
            <Button
              size="md"
              variant="link"
              startIcon={<Icon name="export" />}
              onClick={onExportStudentsClick}
            >
              {/* {getTranslation('common.button.activity_report')} */}
              Export students
            </Button>
            {canCreateStudent && (
              <CreateButton
                buttonText={getTranslation('common.button.create_student')}
                contextItems={ADD_STUDENT_CONTEXT_ITEMS}
                onContextItemClick={onCreateStudentClick}
              />
            )}
          </div>
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {students.length ? (
            <div className={styles.inner}>
              <StudentsTable
                columns={studentsColumns}
                data={students}
                params={sortParams}
                dataAction={getStudents}
                onSortChange={onSortChange}
                onEditStudentClick={onEditStudentClick}
                onRemoveStudentClick={onRemoveStudentClick}
                onStudentRowClick={onStudentRowClick}
              />
            </div>
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="student"
              title={getTranslation('students_page.empty_state.title')}
              text={getTranslation('students_page.empty_state.text')}
            />
          )}
        </>
      )}
    </MenuLayout>
  );
};

export default React.memo(StudentsPage);
