import _isEqual from 'lodash/isEqual';

export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  status: data.status,
  title: data.title,
  text: data.text,
  leftItem: data.leftItem,
  rightItem: data.rightItem,
  free: data.free,
  readOnly,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  text: data.text,
  leftItem: data.leftItem,
  rightItem: data.rightItem,
  free: data.free,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.text !== nextLesson.text) {
    properties.push('text');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (!_isEqual(currentLesson.leftItem, nextLesson.leftItem)) {
    properties.push('leftItem');
  }

  if (!_isEqual(currentLesson.rightItem, nextLesson.rightItem)) {
    properties.push('rightItem');
  }

  return properties;
};

export const checkQuestionsValidation = (values) =>
  values.leftItem?.correct || values.rightItem?.correct;
