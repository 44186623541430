import { createSlice } from '@reduxjs/toolkit';

import { getAdminById, updateAdminById } from './actions';

const adminSlice = createSlice({
  name: 'currentAdmin',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminById.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateAdminById.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export default adminSlice.reducer;
