import { COURSE_PLATFORMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const platformOptions = () => [
  {
    label: getTranslation('constants.common.figma_plugin'),
    value: COURSE_PLATFORMS.FIGMA_PLUGIN,
  },
  {
    label: getTranslation('constants.common.web'),
    value: COURSE_PLATFORMS.WEB,
  },
];
