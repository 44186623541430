import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Modal } from 'components/shared/Modal';
import { LinearProgressBar } from 'components/shared/LinearProgressBar';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { getStepsProgress } from './RemoveWithStepsModal.helpers';

import styles from './RemoveWithStepsModal.styles.scss';

const RemoveWithStepsModal = ({
  open,
  steps,
  title,
  onClose,
  onRetryClick,
}) => (
  <Modal className={styles.modal} open={open} centered onClose={onClose}>
    {title && (
      <Typography className={styles.title} variant="subtitle">
        {title}
      </Typography>
    )}
    <LinearProgressBar
      className={styles.bar}
      thumbClassName={styles.barThumb}
      trackClassName={styles.barTrack}
      progress={getStepsProgress(steps)}
    />
    <div className={styles.steps}>
      {steps.map((step) => (
        <div className={styles.step} key={step.key}>
          <Icon
            className={cn(styles.stepIcon, {
              [styles.stepIconCompleted]: step.completed,
              [styles.stepIconError]: step.error,
            })}
            name="checkRound"
          />
          <Typography
            className={styles.stepText}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {step.title}
          </Typography>
          {step.loading && <Loading className={styles.stepLoading} />}
          {step.error && (
            <Button
              className={styles.stepBtn}
              variant="link"
              size="sm"
              onClick={onRetryClick}
            >
              {getTranslation('common.button.retry')}
            </Button>
          )}
        </div>
      ))}
    </div>
  </Modal>
);

export default React.memo(RemoveWithStepsModal);
