import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';

const Filters = ({ open, addLoading, onClose }) => (
  <Drawer
    open={open}
    headerContent={getTranslation('inbox_page.filters.title')}
    onClose={onClose}
  />
);

export default React.memo(Filters);
