import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { UploadField, EditableTextField } from 'components/FormikFields';

import styles from './ImageBlock.styles.scss';

const ImageBlock = ({ readOnly, namePrefix }) => (
  <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
    <FormikField
      name={`${namePrefix}.data.url`}
      withControl={false}
      component={UploadField}
      componentProps={{
        readOnly,
        variant: 'imageBlock',
        accept: {
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
        },
      }}
    />
    <div className={styles.caption}>
      <div className={styles.captionLine} />
      <FormikField
        name={`${namePrefix}.data.caption`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          variant: 'text',
          placeholder: getTranslation(
            'components.theory_blocks.caption_placeholder',
          ),
        }}
      />
    </div>
  </div>
);

export default React.memo(ImageBlock);
