import React, { useState } from 'react';

import Modules from './Modules';

const ModulesContainer = ({ currentLesson, ...restProps }) => {
  const [activeModule, setActiveModule] = useState(
    currentLesson.moduleId || null,
  );

  const handleModuleClick = (moduleId) => {
    if (activeModule === moduleId) {
      setActiveModule(null);
    } else {
      setActiveModule(moduleId);
    }
  };

  return (
    <Modules
      {...restProps}
      activeModule={activeModule}
      currentLesson={currentLesson}
      onModuleClick={handleModuleClick}
    />
  );
};

export default React.memo(ModulesContainer);
