import React from 'react';

import { LessonTypeSelect } from 'components/LessonTypeSelect';

const LessonTypeSelectField = ({ field, form, ...restProps }) => {
  const handleChange = (nextValue) => {
    form.setFieldValue(field.name, nextValue);
  };

  return <LessonTypeSelect {...field} {...restProps} onChange={handleChange} />;
};

export default React.memo(LessonTypeSelectField);
