export const getComponentPropertyReferences = (layer) => {
  if (!layer.componentPropertyReferences) {
    return null;
  }

  const references = [];

  for (const [propertyType, propertyKey] of Object.entries(
    layer.componentPropertyReferences,
  )) {
    references.push({
      key: propertyKey.split('#')[0],
      type: propertyType,
    });
  }

  return references;
};
