import axios from 'axios';

export const createColorMatchLesson = (data) =>
  axios.post('/api/v1/color-match-lessons', data);

export const updateColorMatchLessonById = ({ lessonId, data }) =>
  axios.put(`/api/v1/color-match-lessons/${lessonId}`, data);

export const removeColorMatchLessonById = (lessonId) =>
  axios.delete(`/api/v1/color-match-lessons/${lessonId}`);
