import React from 'react';

import { CONTEXT_LESSONS_ITEMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { Popover } from 'components/Popover';
import { ContextMenu } from 'components/ContextMenu';

import styles from './StructureSelect.styles.scss';

const StructureSelect = ({ readOnly, onAddModuleClick, onAddLessonClick }) => (
  <div className={styles.select}>
    <div className={styles.option} onClick={onAddModuleClick}>
      <Icon className={styles.optionIcon} name="modules" />
      <Typography
        className={styles.optionText}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('course_page.structure_select.module_text')}
      </Typography>
    </div>
    <Popover
      triggerClassName={styles.optionTrigger}
      placement="bottom-start"
      triggerElement={({ isMounted }) => (
        <div className={styles.option}>
          <Icon className={styles.optionIcon} name="lessons" />
          <Typography
            className={styles.optionText}
            variant="body1"
            weight="medium"
            mode="compact"
          >
            {getTranslation('course_page.structure_select.lesson_text')}
          </Typography>
        </div>
      )}
    >
      <ContextMenu
        items={CONTEXT_LESSONS_ITEMS}
        onItemClick={(contextItem) => onAddLessonClick(contextItem)}
      />
    </Popover>
  </div>
);

export default React.memo(StructureSelect);
