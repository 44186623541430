import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Tabs } from 'components/shared/Tabs';

import { LayersTree } from '../LayersTree';
import { DesignSection } from '../DesignSection';
import { PrototypeSection } from '../PrototypeSection';
import { OptionsSection } from '../OptionsSection';

import styles from './LayersSection.styles.scss';

const LayersSection = ({
  className,
  readOnly,
  isInitialState,
  figmaFileImages,
  layers,
  designType,
  namePrefix,
  taskIndex,
  currentLayer,
  currentLayerPrefix,
  onLayerClick,
}) => {
  return (
    <div className={cn(styles.section, className)}>
      <Typography className={styles.title} variant="body1" weight="medium">
        {getTranslation('figma_lesson_page.main_settings.layers_section_title')}
      </Typography>
      <div className={styles.inner}>
        <div className={styles.layers}>
          <Typography
            className={styles.layersTitle}
            variant="body1"
            weight="medium"
          >
            {getTranslation(
              'figma_lesson_page.main_settings.layers_section_text',
            )}
          </Typography>
          <LayersTree
            readOnly={readOnly}
            data={layers}
            namePrefix={namePrefix}
            currentLayer={currentLayer}
            onLayerClick={onLayerClick}
          />
        </div>
        <div className={styles.main}>
          <Tabs
            className={styles.tabs}
            variant="properties"
            defaultActiveTab={'Design'}
            items={[
              {
                label: 'Design',
                content: (
                  <DesignSection
                    isInitialState={isInitialState}
                    properties={currentLayer?.properties || []}
                    figmaFileImages={figmaFileImages}
                    namePrefix={currentLayerPrefix}
                    layerType={currentLayer?.type}
                    taskIndex={taskIndex}
                  />
                ),
              },
              designType === 'PROTOTYPE' && {
                label: 'Prototype',
                content: (
                  <PrototypeSection
                    isInitialState={isInitialState}
                    readOnly={readOnly}
                    properties={currentLayer?.properties || []}
                    namePrefix={currentLayerPrefix}
                    layerType={currentLayer?.type}
                  />
                ),
              },
              !isInitialState && {
                label: 'Options',
                content: (
                  <OptionsSection
                    readOnly={readOnly}
                    namePrefix={currentLayerPrefix}
                    layerType={currentLayer?.type}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LayersSection);
