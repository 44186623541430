import React from 'react';
// import FocusTrap from 'focus-trap-react';
import cn from 'classnames';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './Drawer.styles.scss';

const Drawer = ({
  className,
  headerClassName,
  open,
  isTransitioning,
  withBackdrop,
  headerContent,
  children,
  position,
  onClose,
}) => (
  //   <FocusTrap active={open}>
  <div
    className={cn(styles.container, {
      [styles.open]: open,
      [styles.in]: isTransitioning,
    })}
  >
    <div
      className={cn(styles.drawer, { [styles[position]]: position }, className)}
    >
      <div
        className={cn(
          styles.header,
          {
            [styles.headerWithContent]: !!headerContent,
          },
          headerClassName,
        )}
      >
        {typeof headerContent === 'string' ? (
          <Typography
            className={styles.title}
            variant="subtitle"
            weight="semiBold"
          >
            {headerContent}
          </Typography>
        ) : (
          headerContent
        )}

        <Button
          variant="ghost"
          mode="icon"
          size="sm"
          iconName="cross"
          onClick={(event) => {
            event.stopPropagation();

            onClose();
          }}
        />
      </div>
      <div className={styles.inner}>{children}</div>
    </div>
    {withBackdrop && (
      <div
        className={styles.backdrop}
        onClick={(event) => {
          event.stopPropagation();

          onClose();
        }}
      />
    )}
  </div>
  //   </FocusTrap>
);

Drawer.defaultProps = {
  withCloseIcon: true,
  withBackdrop: true,
};

export default React.memo(Drawer);
