import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { AddLessonButton } from 'components/AddLessonButton';
import { UploadField, EditableTextField } from 'components/FormikFields';

import { Lessons } from '../Lessons';

import styles from './ModuleItem.styles.scss';

const ModuleItem = ({
  className,
  courseReadOnly,
  courseType,
  moduleIndex,
  module,
  onEditLessonClick,
  onRemoveLessonClick,
}) => (
  <div
    className={cn(
      styles.item,
      { [styles.readOnlyItem]: courseReadOnly },
      className,
    )}
  >
    <div className={styles.header}>
      {courseReadOnly ? (
        <>
          {module.preview ? (
            <img src={module.preview} className={styles.preview} />
          ) : (
            <FormikField
              name={`modules[${moduleIndex}].preview`}
              withControl={false}
              component={UploadField}
              componentProps={{
                readOnly: courseReadOnly,
                variant: 'preview',
                size: 'sm',
                removeFileTitle: getTranslation(
                  'course_page.module_item.remove_course_preview_title',
                ),
                removeFileText: getTranslation(
                  'course_page.module_item.remove_course_preview_text',
                ),
              }}
            />
          )}
          <Typography className={styles.title} variant="heading2" weight="bold">
            {module.title}
          </Typography>
        </>
      ) : (
        <>
          <FormikField
            name={`modules[${moduleIndex}].preview`}
            withControl={false}
            component={UploadField}
            componentProps={{
              readOnly: courseReadOnly,
              variant: 'preview',
              size: 'sm',
              removeFileTitle: getTranslation(
                'course_page.module_item.remove_module_preview_title',
              ),
              removeFileText: getTranslation(
                'course_page.module_item.remove_module_preview_text',
              ),
            }}
          />
          <FormikField
            name={`modules[${moduleIndex}].title`}
            withControl={false}
            component={EditableTextField}
            componentProps={{
              readOnly: courseReadOnly,
              className: styles.title,
              multiline: true,
              placeholder: getTranslation('common.text.untitled'),
              variant: 'heading2',
            }}
          />
        </>
      )}
    </div>
    <div className={styles.inner}>
      <Lessons
        courseReadOnly={courseReadOnly}
        namePrefix={`modules[${moduleIndex}]`}
        moduleId={module.id}
        lessons={module.lessons}
        onEditLessonClick={onEditLessonClick}
        onRemoveLessonClick={onRemoveLessonClick}
      />
      <AddLessonButton
        buttonClassName={styles.addBtn}
        lessons={module.lessons}
        moduleId={module.id}
        courseType={courseType}
      />
    </div>
  </div>
);

export default React.memo(ModuleItem);
