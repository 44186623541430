import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './TextBlock.styles.scss';

const TextBlock = ({ readOnly, type, namePrefix }) => (
  <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
    <FormikField
      name={`${namePrefix}.data`}
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        autoFocus: true,
        multiline: true,
        variant: type,
        placeholder: getTranslation(
          'components.theory_blocks.text_placeholder',
        ),
      }}
    />
  </div>
);

export default React.memo(TextBlock);
