import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';

import styles from './Action.styles.scss';

const Action = ({
  correct,
  submitted,
  allChecked,
  nextLoading,
  onTryAgainClick,
  onRestartLessonClick,
  onCheckClick,
}) => {
  if (submitted && correct) {
    return (
      <Button
        className={styles.action}
        size="sm"
        onClick={onRestartLessonClick}
      >
        {getTranslation('common.button.restart_lesson')}
      </Button>
    );
  }

  if (!submitted) {
    return (
      <Button
        className={styles.action}
        disabled={!allChecked}
        onClick={onCheckClick}
      >
        {getTranslation('common.button.check')}
      </Button>
    );
  }

  if (submitted && !correct) {
    return (
      <Button className={styles.action} onClick={onTryAgainClick}>
        {getTranslation('common.button.try_again')}
      </Button>
    );
  }
};

export default React.memo(Action);
