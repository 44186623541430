import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './UploadError.styles.scss';

const UploadError = () => (
  <div className={styles.error}>
    <Icon className={styles.icon} name="warning" />
    <Typography className={styles.title}>
      {getTranslation('components.import_admins.upload_error.title')}
    </Typography>
    <Typography className={styles.text}>
      {getTranslation('components.import_admins.upload_error.text')}
    </Typography>
    <Button className={styles.button} size="md">
      {getTranslation('common.button.try_again')}
    </Button>
  </div>
);

export default React.memo(UploadError);
