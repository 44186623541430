import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Button.styles.scss';

const SIZES = {
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
};

const MODES = {
  default: styles.defaultMode,
  icon: styles.iconMode,
};

const VARIANTS = {
  primary: styles.primary,
  secondary: styles.secondary,
  outlined: styles.outlined,
  link: styles.link,
  ghost: styles.ghost,
};

const Button = ({
  className,
  type,
  size,
  color,
  mode,
  variant,
  iconName,
  iconColor,
  children,
  startIcon,
  endIcon,
  ...restProps
}) => (
  <button
    {...restProps}
    type={type}
    className={cn(
      styles.button,
      {
        [styles.withStartIcon]: startIcon,
        [styles.withEndIcon]: endIcon,
      },
      MODES[mode],
      VARIANTS[variant],
      SIZES[size],
      className,
    )}
  >
    {mode === 'icon' ? (
      <Icon
        className={styles.buttonIcon}
        style={iconColor && { color: iconColor }}
        name={iconName}
      />
    ) : (
      <>
        {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
        {children}
        {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
      </>
    )}
  </button>
);

Button.defaultProps = {
  variant: 'primary',
  mode: 'default',
  size: 'lg',
  type: 'button',
};

export default React.memo(Button);
