import axios from 'axios';
import qs from 'qs';

export const createCourse = (data) => axios.post('/api/v1/courses', data);

export const updateCourseById = (
  { courseId, data }, // NEW
) => axios.put(`/api/v1/courses/${courseId}`, data);

export const submitCourse = (courseId) =>
  axios.post(`/api/v1/courses/${courseId}/submit`);

export const publishCourse = (courseId) =>
  axios.post(`/api/v1/courses/${courseId}/publish`);

export const archiveCourse = (courseId) =>
  axios.post(`/api/v1/courses/${courseId}/archive`);

export const restoreCourse = (courseId) =>
  axios.post(`/api/v1/courses/${courseId}/restore`);

export const removeCourse = (courseId) =>
  axios.delete(`/api/v1/courses/${courseId}`);

export const getCourses = (params) =>
  axios.get(`/api/v1/courses?${qs.stringify(params)}`);

export const getCourseById = (courseId) =>
  axios.get(`/api/v1/courses/${courseId}`);

export const getCoursesStats = () => axios.get('/api/v1/courses/stats');

export const getCourseStats = (courseId) =>
  axios.get(`/api/v1/courses/${courseId}/stats`);

export const getCourseStatsByStudent = ({ courseId, studentId }) =>
  axios.get(`/api/v1/courses/${courseId}/stats/${studentId}`);

export const getCourseModules = (courseId) =>
  axios.get(`/api/v1/courses/${courseId}/modules`);

export const createCourseModule = ({ courseId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/modules`, data);

export const updateCourseModuleById = ({ courseId, moduleId, data }) =>
  axios.put(`/api/v1/courses/${courseId}/modules/${moduleId}`, data);

export const removeCourseModuleById = ({ courseId, moduleId }) =>
  axios.delete(`/api/v1/courses/${courseId}/modules/${moduleId}`);

export const reorderCourseModules = ({ courseId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/modules/reorder`, data);

export const getCourseLessons = (courseId) =>
  axios.get(`/api/v1/courses/${courseId}/lessons`);

export const reorderCourseLessons = ({ courseId, data }) =>
  axios.post(`/api/v1/courses/${courseId}/lessons/reorder`, data);

export const getCourseLessonById = ({ courseId, lessonId }) =>
  axios.get(`/api/v1/courses/${courseId}/lessons/${lessonId}`);

export const removeCourseLessonById = ({ courseId, lessonId }) =>
  axios.delete(`/api/v1/courses/${courseId}/lessons/${lessonId}`);

export const removeCourseLessons = (courseId) =>
  axios.delete(`/api/v1/courses/${courseId}/remove-lessons`);

export const removeCourseModules = (courseId) =>
  axios.delete(`/api/v1/courses/${courseId}/remove-modules`);

export const removeCourseStudents = (courseId) =>
  axios.delete(`/api/v1/courses/${courseId}/remove-students`);
