import React from 'react';

import { MainInfo } from './components/MainInfo';
import { ActivationInfo } from './components/ActivationInfo';

import { getLastUpdatedByCourses } from '../../StudentPage.helpers';

import styles from './SchoolStudentCard.styles.scss';

const SchoolStudentCard = ({
  locale,
  student,
  studentStatus,
  studentCourses,
  onEditClick,
}) => (
  <div className={styles.card}>
    <MainInfo
      {...student}
      locale={locale}
      status={studentStatus.status}
      updatedAt={getLastUpdatedByCourses(studentCourses)}
      onEditClick={onEditClick}
    />
    <ActivationInfo {...studentStatus} locale={locale} />
  </div>
);

export default React.memo(SchoolStudentCard);
