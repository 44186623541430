export const getIconNameByValue = (value) => {
  if (value === 'checked') {
    return 'check';
  }

  if (value === 'partiallyChecked') {
    return 'minus';
  }
};

export const getCheckValue = (propertiesByCategory) => {
  if (propertiesByCategory.every((property) => property.check)) {
    return 'checked';
  }

  if (propertiesByCategory.some((property) => property.check)) {
    return 'partiallyChecked';
  }

  return 'none';
};
