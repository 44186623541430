import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Typography } from 'components/shared/Typography';

import { EmptyProperties } from '../EmptyProperties';

import styles from './ComponentProperties.styles.scss';

const ComponentProperties = ({ properties }) => {
  const parent = getLayerPropertyByKey({
    key: 'parent',
    properties,
  });
  const variantProperties = getLayerPropertyByKey({
    key: 'variantProperties',
    properties,
  });

  if (
    !variantProperties ||
    !variantProperties.value ||
    variantProperties.value.length === 0
  ) {
    return <EmptyProperties />;
  }

  if (parent.value && parent.value.type === 'COMPONENT_SET') {
    return (
      <div className={styles.root}>
        <div className={styles.item}>
          <Typography
            className={styles.itemTitle}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            Parent component:
          </Typography>
          <div className={styles.itemInner}>
            <div className={styles.property}>
              <Typography
                className={styles.propertyValue}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                {parent.value.name}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <Typography
            className={styles.itemTitle}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            Current variant:
          </Typography>
          <div className={styles.itemInner}>
            {variantProperties.value.map((property, propertyIndex) => (
              <div className={styles.property} key={propertyIndex}>
                <Typography
                  className={styles.propertyValue}
                  variant="body2"
                  weight="medium"
                  mode="compact"
                >
                  {property.key}: {property.value}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default React.memo(ComponentProperties);
