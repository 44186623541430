import * as api from 'api/methods';

export const getFramesPreviews = async ({ layers, fileId }) => {
  const promises = layers.map((layer) =>
    api
      .getFigmaNodePreview({ fileId, nodeId: layer.id, format: 'jpg' })
      .then((previewData) => {
        if (previewData.err) {
          return null;
        }

        return previewData.images;
      }),
  );

  const previews = await Promise.all(promises);

  return Object.assign({}, ...previews);
};
