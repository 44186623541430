import { createSlice } from '@reduxjs/toolkit';

import {
  getLessonById,
  updateLessonById,
  updateLessonStatusById,
  removeLessonById,
} from './actions';

const lessonSlice = createSlice({
  name: 'currentLesson',
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonById.fulfilled, (state, action) => {
      return {
        ...action.payload,
      };
    });

    builder.addCase(updateLessonStatusById.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(updateLessonById.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(removeLessonById.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default lessonSlice.reducer;
