import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { EmptyState } from 'components/EmptyState';
import { TablePagination } from 'components/TablePagination';

import { Actions } from '../Actions';
import { StudentsReviewsItem } from '../StudentsReviewsItem';

import styles from './StudentsReviews.styles.scss';

const StudentsReviews = ({
  locale,
  loading,
  studentsReviews,
  currentPage,
  totalPages,
  perPage,
  sortParams,
  onPerPageChange,
  onPrevPageClick,
  onNextPageClick,
  onSortChange,
}) => (
  <>
    {loading ? (
      <Loading />
    ) : studentsReviews.length > 0 ? (
      <>
        <Actions onSortChange={onSortChange} sortParams={sortParams} />
        {studentsReviews.map((studentReview) => (
          <div className={styles.item} key={studentReview.id}>
            <StudentsReviewsItem
              studentReview={studentReview}
              locale={locale}
            />
          </div>
        ))}
        <TablePagination
          currentPage={currentPage}
          perPage={perPage}
          totalPages={totalPages}
          onPerPageChange={onPerPageChange}
          onNextPageClick={onNextPageClick}
          onPrevPageClick={onPrevPageClick}
        />
      </>
    ) : (
      <EmptyState
        className={styles.emptyState}
        icon="mail"
        title={getTranslation('inbox_page.students_reviews.empty_state.title')}
        text=""
      />
    )}
  </>
);
export default React.memo(StudentsReviews);
