import React from 'react';

import { Table } from 'components/shared/Table';
import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';

import { StatusTag } from 'components/StatusTag';

import { TABLE_COLUMNS } from './InfoByStudents.tableConfig';

import styles from './InfoByStudents.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth, justifyContent },
  DefaultCell,
) => {
  switch (key) {
    case 'student': {
      return (
        <div key={key} className={styles.cell}>
          <Avatar size="sm" url={item[key].avatar} text={item[key].name} />
          <Typography
            className={styles.nameText}
            variant="body3"
            mode="compact"
          >
            {item[key].name}
          </Typography>
        </div>
      );
    }
    case 'status': {
      return (
        <StatusTag variant={item[key].variant}>{item[key].text}</StatusTag>
      );
    }
    case 'courseProgress': {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
        >
          <Typography
            className={styles.leftText}
            component="span"
            variant="body3"
            mode="compact"
          >
            {item[key]}%
          </Typography>
        </DefaultCell>
      );
    }
    default: {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
        >
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const InfoByStudents = ({ data, onStudentRowClick }) => (
  <Table
    columns={TABLE_COLUMNS}
    actions={[]}
    data={data}
    onRowClick={(item) => onStudentRowClick(item)}
    cellRenderer={(props, DefaultCell) => cellRenderer(props, DefaultCell)}
  />
);

export default React.memo(InfoByStudents);
