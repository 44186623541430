import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './WrongIcon.styles.scss';

const WrongIcon = ({ className, iconClassName }) => (
  <div className={cn(styles.wrapper, className)}>
    <Icon className={cn(styles.icon, iconClassName)} name="crossMini" />
  </div>
);

export default React.memo(WrongIcon);
