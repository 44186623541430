export const shuffle = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const getIntersectionArea = (ref, event) =>
  ref
    .map((item) => {
      const el = item.current.getBoundingClientRect();
      return { top: el.top, left: el.left, diameter: el.width };
    })
    .find(
      (position) =>
        event.clientX > position.left &&
        event.clientX < position.left + position.diameter &&
        event.clientY > position.top &&
        event.clientY < position.top + position.diameter,
    );

export const shuffleIncorrectAnswers = (answers, correctAnswers) => {
  const nextAnswers = answers.slice();

  const correctAnswersIds = correctAnswers.map((item) => item.startId);

  const incorrectAnswers = nextAnswers.filter(
    (item) => !correctAnswersIds.includes(item.id),
  );

  for (let i = incorrectAnswers.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [incorrectAnswers[i], incorrectAnswers[randomIndex]] = [
      incorrectAnswers[randomIndex],
      incorrectAnswers[i],
    ];
  }

  let nextIndex = 0;
  for (let i = 0; i < nextAnswers.length; i++) {
    if (!correctAnswersIds.includes(nextAnswers[i].id)) {
      nextAnswers[i] = incorrectAnswers[nextIndex];
      nextIndex++;
    }
  }

  return nextAnswers;
};
