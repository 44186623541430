export const getPropertyLabel = ({ type }) => {
  switch (type) {
    case 'strokeJoin': {
      return 'Join:';
    }
    case 'strokeCap': {
      return 'Dash cap:';
    }
    case 'textAutoResize': {
      return 'Resizing:';
    }
    case 'textAlignHorizontal': {
      return 'Horizontal alignment:';
    }
    case 'textAlignVertical': {
      return 'Vertical alignment:';
    }
    case 'textDecoration': {
      return 'Decoration:';
    }
    case 'textCase': {
      return 'Case:';
    }
    case 'textTruncation': {
      return 'Truncate text:';
    }
    case 'leadingTrim': {
      return 'Vertical trim:';
    }
    default: {
      return null;
    }
  }
};

const _getStrokeJoinIconByValue = (value) => {
  switch (value) {
    case 'MITER': {
      return 'strokeJoinMiter';
    }
    case 'BEVEL': {
      return 'strokeJoinBevel';
    }
    case 'ROUND': {
      return 'strokeJoinRound';
    }
    default: {
      return null;
    }
  }
};

const _getStrokeCapIconByValue = (value) => {
  switch (value) {
    case 'NONE': {
      return 'strokeCapNone';
    }
    case 'SQUARE': {
      return 'strokeCapSquare';
    }
    case 'ROUND': {
      return 'strokeCapRound';
    }
    default: {
      return null;
    }
  }
};

const _getTextResizeIconByValue = (value) => {
  switch (value) {
    case 'WIDTH_AND_HEIGHT': {
      return 'autoWidth';
    }
    case 'HEIGHT': {
      return 'autoHeight';
    }
    case 'TRUNCATE': {
      return 'fixedSize'; // TODO
    }
    case 'NONE': {
      return 'fixedSize'; // TODO
    }
  }
};

const _getTextAlignHorizontalIconByValue = (value) => {
  switch (value) {
    case 'LEFT': {
      return 'textAlignLeft';
    }
    case 'RIGHT': {
      return 'textAlignRight';
    }
    case 'CENTER': {
      return 'textAlignCenter';
    }
    case 'JUSTIFIED': {
      return 'textAlignJustified';
    }
  }
};

const _getTextAlignVerticalIconByValue = (value) => {
  switch (value) {
    case 'TOP': {
      return 'textAlignTop';
    }
    case 'CENTER': {
      return 'textAlignMiddle';
    }
    case 'BOTTOM': {
      return 'textAlignBottom';
    }
  }
};

const _getTextDecorationIconByValue = (value) => {
  switch (value) {
    case 'NONE': {
      return 'none';
    }
    case 'UNDERLINE': {
      return 'underline';
    }
    case 'STRIKETHROUGH': {
      return 'strikeThrough';
    }
  }
};

const _getTextCaseIconByValue = (value) => {
  switch (value) {
    case 'ORIGINAL': {
      return 'none';
    }
    case 'UPPER': {
      return 'uppercase';
    }
    case 'LOWER': {
      return 'lowercase';
    }
    case 'TITLE': {
      return 'titlecase';
    }
    case 'SMALL_CAPS': {
      return 'smallcaps';
    }
    case 'SMALL_CAPS_FORCED': {
      return 'smallcaps'; // TODO:
    }
  }
};

const _getTextTruncationIconByValue = (value) => {
  switch (value) {
    case 'DISABLED': {
      return 'none';
    }
    case 'ENDING': {
      return 'truncateText';
    }
  }
};

const _getTextVerticalTrimIconByValue = (value) => {
  switch (value) {
    case 'NONE': {
      return 'trimStandart';
    }
    case 'CAP_HEIGHT': {
      return 'trimCapHeight';
    }
  }
};

export const getPropertyIcon = ({ type, value }) => {
  switch (type) {
    case 'strokeJoin': {
      return _getStrokeJoinIconByValue(value);
    }
    case 'strokeCap': {
      return _getStrokeCapIconByValue(value);
    }
    case 'textAutoResize': {
      return _getTextResizeIconByValue(value);
    }
    case 'textAlignHorizontal': {
      return _getTextAlignHorizontalIconByValue(value);
    }
    case 'textAlignVertical': {
      return _getTextAlignVerticalIconByValue(value);
    }
    case 'textDecoration': {
      return _getTextDecorationIconByValue(value);
    }
    case 'textCase': {
      return _getTextCaseIconByValue(value);
    }
    case 'textTruncation': {
      return _getTextTruncationIconByValue(value);
    }
    case 'leadingTrim': {
      return _getTextVerticalTrimIconByValue(value);
    }
    default: {
      return null;
    }
  }
};
