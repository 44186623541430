import { getTranslation } from 'helpers/getTranslation';

export const getCoursesTableColums = () => [
  {
    key: 'title',
    label: getTranslation('school_page.courses.table_keys.title'),
    sortable: true,
    minWidth: 300,
  },
  {
    key: 'type',
    label: getTranslation('school_page.courses.table_keys.type'),
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('school_page.courses.table_keys.status'),
    sortable: true,
  },
  {
    key: 'authors',
    label: getTranslation('school_page.courses.table_keys.authors'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('school_page.courses.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'lastActionAt',
    label: getTranslation('school_page.courses.table_keys.updated_at'),
    sortable: true,
  },
];
