import React from 'react';

import { ADD_ADMIN_CONTEXT_ITEMS } from 'constants/common';

import { getAdmins } from 'store/admins/actions';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { MenuLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { ListToolbar } from 'components/Toolbar';
import { AdminsTable } from 'components/AdminsTable';
import { CreateButton } from 'components/CreateButton';

import styles from './AdminsPage.styles.scss';

const AdminsPage = ({
  loading,
  admins,
  adminsColumns,
  user,
  sortParams,
  onSortChange,
  onCreateAdminClick,
  onAdminRowClick,
  onEditAdminClick,
}) => {
  return (
    <MenuLayout>
      <ListToolbar
        title={getTranslation('admins_page.toolbar_title')}
        actionComponent={
          <CreateButton
            buttonText={getTranslation('admins_page.toolbar_action')}
            contextItems={ADD_ADMIN_CONTEXT_ITEMS}
            onContextItemClick={onCreateAdminClick}
          />
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {admins.length ? (
            <div className={styles.inner}>
              <AdminsTable
                columns={adminsColumns}
                data={admins}
                dataAction={getAdmins}
                user={user}
                params={sortParams}
                onSortChange={onSortChange}
                onAdminRowClick={onAdminRowClick}
                onEditAdminClick={onEditAdminClick}
              />
            </div>
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="employees"
              title={getTranslation('admins_page.empty_state.title')}
              text={getTranslation('admins_page.empty_state.text')}
            />
          )}
        </>
      )}
    </MenuLayout>
  );
};

export default React.memo(AdminsPage);
