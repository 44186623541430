import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import styles from './Toast.styles.scss';

const VARIANTS = {
  success: styles.toastSuccess,
  warning: styles.toastWarning,
  info: styles.toastInfo,
  error: styles.toastError,
};

const ICON_VARIANTS = {
  info: 'info',
  warning: 'warning',
  success: 'check',
  error: 'cross',
};
const Toast = ({ className, variant, title, text }) => (
  <div className={cn(styles.toast, VARIANTS[variant], className)}>
    <div className={styles.iconWrapper}>
      <Icon name={ICON_VARIANTS[variant]} className={styles.icon} />
    </div>
    <div className={styles.inner}>
      {title && (
        <Typography
          variant="body2"
          weight="medium"
          mode="compact"
          className={styles.title}
        >
          {title}
        </Typography>
      )}
      <Typography className={styles.text} variant="body3" mode="compact">
        {text}
      </Typography>
    </div>
    <Icon className={styles.closeIcon} name="crossMini" />
  </div>
);

export default React.memo(Toast);
