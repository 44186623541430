export const isTextCopyAvailable = (layers) => {
  for (const layer of layers) {
    if (layer.options.copy) {
      return true;
    }

    if (layer.children && layer.children.length) {
      return isTextCopyAvailable(layer.children);
    }
  }

  return false;
};

export const getCopyText = (layers) => {
  for (const layer of layers) {
    if (layer.options.copy) {
      return layer.properties.characters;
    }

    if (layer.children && layer.children.length) {
      return getCopyText(layer.children);
    }
  }

  return null;
};
