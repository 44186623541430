import React from 'react';

import {
  LINE_COLORS,
  LINE_PROPERTIES,
} from '../../ConnectionQuizPreviewPage.constants';

import styles from './Line.styles.scss';

const Line = ({
  containerRef,
  color,
  point1,
  point2,
  point3,
  point4,
  line,
}) => {
  return (
    <svg
      width={containerRef ? containerRef.current.offsetWidth : 0}
      height={containerRef ? containerRef.current.offsetHeight : 0}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <path
        d={`M ${point1.x} ${point1.y}
        C ${point2.x} ${point2.y},
        ${point3.x} ${point3.y},
        ${point4.x} ${point4.y}`}
        stroke={`${color ? color : LINE_COLORS.LINK_WATER_BASE}`}
        strokeWidth={LINE_PROPERTIES.STROKE_WIDTH}
      />
    </svg>
  );
};

export default React.memo(Line);
