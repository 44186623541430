import React from 'react';
import cn from 'classnames';

import { CONTEXT_LESSONS_ITEMS } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { Popover } from 'components/Popover';
import { ContextMenu } from 'components/ContextMenu';

import styles from './AddLessonButton.styles.scss';

const AddLessonButton = ({ buttonClassName, onAddLessonClick }) => (
  <Popover
    placement="right-end"
    triggerElement={({ isMounted }) => (
      <Button
        className={cn(
          styles.button,
          { [styles.buttonActive]: isMounted },
          buttonClassName,
        )}
        size="sm"
        variant="link"
        startIcon={<Icon name="plus" />}
      >
        {getTranslation('common.button.add_lesson')}
      </Button>
    )}
  >
    <ContextMenu
      items={CONTEXT_LESSONS_ITEMS}
      onItemClick={(contextItem) => onAddLessonClick(contextItem)}
    />
  </Popover>
);

export default React.memo(AddLessonButton);
