import { useDispatch } from 'react-redux';

import { showToast } from 'helpers/showToast';
import { scrollToTop } from 'helpers/scrollToTop';

export const usePagination = ({
  dataAction,
  totalPages,
  currentPage,
  perPage,
  sortBy,
  sortDirection,
}) => {
  const dispatch = useDispatch();

  const handlePerPageChange = async (nextPerPage) => {
    try {
      scrollToTop('.menu-layout-container');
      await dispatch(
        dataAction({
          page: 1,
          limit: nextPerPage,
          sortBy: sortBy || 'createdAt',
          sortDirection: sortDirection || 'desc',
        }),
      ).unwrap();
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }
  };

  const handleNextPageClick = async () => {
    try {
      const nextPage = currentPage + 1;

      if (nextPage > totalPages) {
        return;
      }

      scrollToTop('.menu-layout-container');

      await dispatch(
        dataAction({
          page: nextPage,
          limit: perPage,
          sortBy: sortBy || 'createdAt',
          sortDirection: sortDirection || 'desc',
        }),
      ).unwrap();
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }
  };

  const handlePrevPageClick = async () => {
    try {
      const prevPage = currentPage - 1;

      if (prevPage === 0) {
        return;
      }

      scrollToTop('.menu-layout-container');

      await dispatch(
        dataAction({
          page: prevPage,
          limit: perPage,
          sortBy: sortBy || 'createdAt',
          sortDirection: sortDirection || 'desc',
        }),
      ).unwrap();
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: 'Error!',
          text: error.message || 'Server error',
        },
      });
    }
  };

  return [handlePerPageChange, handlePrevPageClick, handleNextPageClick];
};
