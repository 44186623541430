export const isAnswersMultiple = (question) => {
  if (!question) {
    return false;
  }

  return question.answers.filter((answer) => answer.correct).length > 1;
};

export const isAnswersCorrect = ({
  questions,
  currentAnswers,
  currentQuestionIndex,
}) => {
  const currentQuestion = questions[currentQuestionIndex];

  if (!currentQuestion) {
    return false;
  }

  const correctAnswers = currentQuestion.answers.filter(
    (answer) => answer.correct,
  );

  const correct =
    correctAnswers.every((answer) => currentAnswers.includes(answer.id)) &&
    currentAnswers.length <= correctAnswers.length;

  return correct;
};

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const randomizeLessonAnswers = (less) => {
  return {
    ...less,
    questions: less.questions.map((question) => {
      return { ...question, answers: shuffleArray(question.answers) };
    }),
  };
};

export const isAswersEmpty = (question) => {
  return (question.answers.length === 0 || question.answersType === '') && true;
};
