import React from 'react';
import format from 'date-fns/format';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './Caption.styles.scss';

const Caption = ({
  displayMonth,
  onPrevMonthClick,
  onNextMonthClick,
  onPrevYearClick,
  onNextYearClick,
}) => (
  <div className={styles.caption}>
    <Button
      variant="outlined"
      mode="icon"
      size="sm"
      iconName="backward"
      iconColor="#1182F6"
      onClick={onPrevYearClick}
    />
    <Button
      className={styles.prevMonthBtn}
      variant="outlined"
      mode="icon"
      size="sm"
      iconName="chevronLeft"
      iconColor="#1182F6"
      onClick={onPrevMonthClick}
    />
    <Typography
      className={styles.text}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {format(displayMonth, 'MMMM yyyy')}
    </Typography>
    <Button
      className={styles.nextMonthBtn}
      variant="outlined"
      mode="icon"
      size="sm"
      iconName="chevronRight"
      iconColor="#1182F6"
      onClick={onNextMonthClick}
    />
    <Button
      variant="outlined"
      mode="icon"
      size="sm"
      iconName="forward"
      iconColor="#1182F6"
      onClick={onNextYearClick}
    />
  </div>
);

export default React.memo(Caption);
