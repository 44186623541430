export const getStrokeValue = ({ stroke, figmaFileImages }) => {
  switch (stroke.type) {
    case 'SOLID': {
      return stroke.color;
    }
    case 'GRADIENT_LINEAR':
    case 'GRADIENT_RADIAL':
    case 'GRADIENT_ANGULAR':
    case 'GRADIENT_DIAMOND': {
      return { gradientStops: stroke.gradientStops };
    }
    case 'IMAGE': {
      const image = figmaFileImages.find(
        (fileImage) => fileImage.imageRef === stroke.imageHash,
      );

      if (!image) {
        return { imageUrl: null, scaleMode: null };
      }

      return {
        imageUrl: image.imageUrl,
        scaleMode: stroke.scaleMode,
        scalingFactor: stroke.scalingFactor,
        rotation: stroke.rotation,
      };
    }
    case 'VIDEO': {
      const video = figmaFileImages.find(
        (fileImage) => fileImage.imageRef === stroke.videoHash,
      );

      if (!video) {
        return { imageUrl: null, scaleMode: null };
      }

      return {
        imageUrl: video.imageUrl,
        scaleMode: stroke.scaleMode,
        scalingFactor: stroke.scalingFactor,
        rotation: stroke.rotation,
      };
    }
  }
};
