import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './FormatSelect.styles.scss';

const OPTIONS = () => [
  {
    iconName: 'game',
    text: getTranslation('course_page.format_select.game'),
    value: 'GAME',
  },
  {
    iconName: 'challenge',
    text: getTranslation('course_page.format_select.challenge'),
    value: 'CHALLENGE',
  },
];

const FormatSelect = ({ readOnly, value, onItemClick }) => (
  <div className={cn(styles.select, { [styles.selectDisabled]: readOnly })}>
    {OPTIONS().map((option) => (
      <div
        className={cn(styles.item, {
          [styles.itemActive]: value === option.value,
        })}
        key={option.value}
        onClick={() => onItemClick(option.value)}
      >
        <Icon className={styles.icon} name={option.iconName} />
        <Typography
          className={styles.text}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {option.text}
        </Typography>
      </div>
    ))}
  </div>
);

export default React.memo(FormatSelect);
