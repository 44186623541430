export const getLayoutGridName = (layoutGrid) => {
  if (layoutGrid.pattern === 'GRID') {
    return `Grid ${layoutGrid.sectionSize}px`;
  }

  if (layoutGrid.pattern === 'COLUMNS') {
    return `${layoutGrid.count} columns (${
      layoutGrid.sectionSize ? `${layoutGrid.sectionSize}px` : 'auto'
    })`;
  }

  if (layoutGrid.pattern === 'ROWS') {
    return `${layoutGrid.count} rows (${
      layoutGrid.sectionSize ? `${layoutGrid.sectionSize}px` : 'auto'
    })`;
  }

  return 'Unknown';
};
