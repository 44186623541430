import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

const Action = ({ nextLoading, isLastLessonInCourse, onClick }) => {
  if (isLastLessonInCourse) {
    return (
      <Button onClick={onClick}>
        {getTranslation('common.button.finish_course')}
      </Button>
    );
  }

  return (
    <Button
      startIcon={nextLoading && <Loading color="var(--color-white-primary)" />}
      endIcon={<Icon name="arrowRight" />}
      onClick={onClick}
    >
      {getTranslation('common.button.next_lessons')}
    </Button>
  );
};

export default React.memo(Action);
