import React from 'react';
import cn from 'classnames';

import { LESSON_TYPES } from 'constants/common';

import { Icon } from 'components/shared/Icon';

import styles from './LessonIcon.styles.scss';

const _renderIcon = ({ className, type }) => {
  switch (type) {
    case LESSON_TYPES.QUIZ: {
      return <Icon className={cn(styles.icon, className)} name="quiz" />;
    }
    case LESSON_TYPES.CONNECTION_QUIZ: {
      return (
        <Icon className={cn(styles.icon, className)} name="connectionQuiz" />
      );
    }
    case LESSON_TYPES.THEORY_LESSON: {
      return <Icon className={cn(styles.icon, className)} name="theory" />;
    }
    case LESSON_TYPES.FIGMA_LESSON: {
      return <Icon className={cn(styles.icon, className)} name="practice" />;
    }
    case LESSON_TYPES.TWO_COLUMNS_LESSON: {
      return <Icon className={cn(styles.icon, className)} name="twoColumns" />;
    }
    case LESSON_TYPES.DIFFERENCE_LESSON: {
      return <Icon className={cn(styles.icon, className)} name="difference" />;
    }
    case LESSON_TYPES.COLOR_MATCH_LESSON: {
      return <Icon className={cn(styles.icon, className)} name="colorMatch" />;
    }
    default: {
      return null;
    }
  }
};

const LessonIcon = ({ className, type }) => (
  <div className={styles.iconWrapper}>{_renderIcon({ className, type })}</div>
);

export default React.memo(LessonIcon);
