import React from 'react';
import { useFormikContext } from 'formik';

import Question from './Question';

const QuestionContainer = ({ questionIndex, ...restProps }) => {
  const { setFieldValue } = useFormikContext();

  const handleAnswersTypeClick = (option) => {
    setFieldValue(`questions[${questionIndex}].answersType`, option.value);
  };

  return (
    <Question
      {...restProps}
      questionIndex={questionIndex}
      onAnswersTypeClick={handleAnswersTypeClick}
    />
  );
};

export default React.memo(QuestionContainer);
