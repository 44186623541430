import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField, UploadField } from 'components/FormikFields';

import styles from './Answer.styles.scss';

const _renderAnswerLeftContent = ({ readOnly, namePrefix, answersType }) => {
  switch (answersType) {
    case 'TEXT_TEXT': {
      return (
        <FormikField
          name={`${namePrefix}.leftContent`}
          withControl={false}
          component={EditableTextField}
          componentProps={{
            readOnly,
            multiline: true,
            className: styles.text,
            variant: 'text',
            placeholder: getTranslation(
              'connection_quiz_page.answer.left_placeholder',
            ),
          }}
        />
      );
    }
    case 'IMAGE_TEXT':
    case 'IMAGE_IMAGE': {
      return (
        <FormikField
          name={`${namePrefix}.leftContent`}
          withControl={false}
          component={UploadField}
          componentProps={{
            readOnly,
            className: styles.image,
            variant: 'quizImage',
          }}
        />
      );
    }
  }
};

const _renderAnswerRightContent = ({ readOnly, answersType, namePrefix }) => {
  switch (answersType) {
    case 'TEXT_TEXT':
    case 'IMAGE_TEXT': {
      return (
        <FormikField
          name={`${namePrefix}.rightContent`}
          withControl={false}
          component={EditableTextField}
          componentProps={{
            readOnly,
            multiline: true,
            className: styles.text,
            variant: 'text',
            placeholder: getTranslation(
              'connection_quiz_page.answer.right_placeholder',
            ),
          }}
        />
      );
    }
    case 'IMAGE_IMAGE': {
      return (
        <FormikField
          name={`${namePrefix}.rightContent`}
          withControl={false}
          component={UploadField}
          componentProps={{
            readOnly,
            className: styles.image,
            variant: 'quizImage',
          }}
        />
      );
    }
  }
};

const Answer = ({ readOnly, namePrefix, answersType, order }) => (
  <div className={cn(styles.answer, { [styles.readOnlyAnswer]: readOnly })}>
    <div className={styles.orderWrapper}>
      <Typography
        className={styles.order}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {order}
      </Typography>
    </div>
    {_renderAnswerLeftContent({ readOnly, answersType, namePrefix })}
    <div className={styles.line} />
    {_renderAnswerRightContent({ readOnly, answersType, namePrefix })}
  </div>
);

export default React.memo(Answer);
