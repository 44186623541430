import React from 'react';
import cn from 'classnames';

import { Collapsible } from 'components/shared/Collapsible';
import { Table } from 'components/shared/Table';
import { Typography } from 'components/shared/Typography';

import { importedStudentsTypes } from './UploadResultsStep.config';

import { STUDENTS_TABLE_COLUMNS } from './UploadResultsStep.tableConfig';

import { UploadResultHeader } from '../UploadResultHeader';

import styles from './UploadResultsStep.styles.scss';

const cellRenderer = ({ item, key, minWidth, maxWidth }, DefaultCell) => {
  switch (key) {
    case 'student': {
      return (
        <div
          className={styles.studentCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Typography
            className={styles.studentName}
            variant="body2"
            mode="compact"
          >
            {item.name}
          </Typography>
          <Typography
            className={styles.studentEmail}
            variant="body3"
            mode="compact"
          >
            {item.email}
          </Typography>
        </div>
      );
    }
    case 'row': {
      return (
        <div
          className={styles.rowCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Typography variant="body2" mode="compact" weight="medium">
            {item.row}
          </Typography>
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const UploadResultsStep = ({ importedStudents }) => {
  return (
    <>
      {importedStudentsTypes.map((importedStudentType) => {
        const students = importedStudents.filter(
          (importedStudent) =>
            importedStudent.status === importedStudentType.key,
        );

        return (
          <Collapsible
            key={importedStudentType.key}
            className={styles.results}
            openedClassName={cn(styles.results, styles.resultsOpened)}
            transitionTime={150}
            triggerTagName="div"
            trigger={
              <UploadResultHeader
                triggerIconClassName={styles.resultsTriggerIcon}
                iconName={importedStudentType.iconName}
                iconColor={importedStudentType.iconColor}
                title={importedStudentType.title}
                rowsCount={students.length}
              />
            }
          >
            {students && students.length > 0 && (
              <Table
                className={styles.resultsTable}
                data={students}
                columns={STUDENTS_TABLE_COLUMNS}
                cellRenderer={cellRenderer}
              />
            )}
          </Collapsible>
        );
      })}
    </>
  );
};

export default React.memo(UploadResultsStep);
