import React from 'react';

import { getSchoolAdmins } from 'store/admins/actions';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { AdminsTable } from 'components/AdminsTable';
import { EmptyState } from 'components/EmptyState';

import { getAdminsTableColumns } from './Admins.tableConfig';

import styles from './Admins.styles.scss';

const Admins = ({
  loading,
  admins,
  user,
  schoolId,
  sortParams,
  onSortChange,
  onEditAdminClick,
  onAdminRowClick,
}) =>
  loading ? (
    <Loading />
  ) : admins && admins.length > 0 ? (
    <AdminsTable
      columns={getAdminsTableColumns()}
      data={admins}
      dataAction={({ page, limit, sortBy, sortDirection }) =>
        getSchoolAdmins({
          schoolId,
          params: { page, limit, sortBy, sortDirection },
        })
      }
      user={user}
      params={sortParams}
      onSortChange={onSortChange}
      onEditAdminClick={onEditAdminClick}
      onAdminRowClick={onAdminRowClick}
    />
  ) : (
    <EmptyState
      className={styles.emptyState}
      icon="employees"
      title={getTranslation('school_page.admins.empty_state.title')}
      text={getTranslation('school_page.admins.empty_state.text')}
    />
  );

export default React.memo(Admins);
