import { createSlice } from '@reduxjs/toolkit';

import {
  getArcades,
  getSchoolArcades,
  updateArcadeById,
  removeArcadeById,
} from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: null,
  perPage: 10,
};

const arcadesSlice = createSlice({
  name: 'arcades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArcades.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(getSchoolArcades.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateArcadeById.fulfilled, (state, action) => {
      const { fieldName, updatedArcade } = action.payload;

      return {
        ...state,
        data: state.data.map((arcade) => {
          if (arcade.id === updatedArcade.id) {
            return {
              ...arcade,
              [fieldName]: updatedArcade[fieldName],
            };
          }

          return arcade;
        }),
      };
    });

    builder.addCase(removeArcadeById, (state, action) => {
      return {
        ...state,
        data: state.data.filter((arcade) => arcade.id !== action.payload),
      };
    });
  },
});

export default arcadesSlice.reducer;
