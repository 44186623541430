import { getTranslation } from 'helpers/getTranslation';

export const getInitialTableData = (courseStats) => {
  return courseStats.studentsProgress.map((student) => ({
    id: student.id,
    student: {
      name: student.name,
      avatar: student.avatar,
    },
    status: getCourseStatus({
      courseCompleted: student.courseCompleted,
      courseProgress: student.courseProgress,
    }),
    courseProgress: student.courseProgress,
  }));
};

const getCourseStatus = ({ courseCompleted, courseProgress }) => {
  if (courseCompleted) {
    return {
      variant: 'success',
      text: getTranslation('dashboard_page.info_by_students.helpers.completed'),
    };
  }

  if (courseProgress === 0) {
    return {
      variant: 'default',
      text: getTranslation(
        'dashboard_page.info_by_students.helpers.not_started',
      ),
    };
  }

  return {
    variant: 'info',
    text: getTranslation('dashboard_page.info_by_students.helpers.in_progress'),
  };
};
