import { FIGMA_LAYER_TYPES } from 'constants/common';

const _formatCornerRadius = (cornerRadius) => {
  if (typeof cornerRadius === 'number') {
    return { cornerRadius };
  }

  if (Array.isArray(cornerRadius)) {
    return {
      topLeftRadius: cornerRadius[0],
      topRightRadius: cornerRadius[1],
      bottomLeftRadius: cornerRadius[3],
      bottomRightRadius: cornerRadius[2],
    };
  }
};

const _formatLayoutProperties = (properties) => ({
  layoutMode: properties.layoutMode || 'NONE',
  counterAxisSizingMode: properties.counterAxisSizingMode,
  primaryAxisSizingMode: properties.primaryAxisSizingMode,
  itemSpacing: properties.itemSpacing,
  paddingLeft: properties.paddingLeft,
  paddingRight: properties.paddingRight,
  paddingTop: properties.paddingTop,
  paddingBottom: properties.paddingBottom,
  counterAxisAlignItems: properties.counterAxisAlignItems,
  primaryAxisAlignItems: properties.primaryAxisAlignItems,
});

export const formatLayerProperties = ({ layerType, properties }) => {
  switch (layerType) {
    case FIGMA_LAYER_TYPES.FRAME: {
      return {
        ..._formatCornerRadius(properties.cornerRadius),
        ..._formatLayoutProperties(properties),
      };
    }
    case FIGMA_LAYER_TYPES.GROUP: {
      return {
        ..._formatCornerRadius(properties.cornerRadius),
        ..._formatLayoutProperties(properties),
      };
    }
    case FIGMA_LAYER_TYPES.RECTANGLE: {
      return {
        ..._formatCornerRadius(properties.cornerRadius),
      };
    }
    case FIGMA_LAYER_TYPES.POLYGON:
    case FIGMA_LAYER_TYPES.REGULAR_POLYGON: {
      return {
        ..._formatCornerRadius(properties.cornerRadius),
        pointCount: properties.pointCount,
      };
    }
    case FIGMA_LAYER_TYPES.STAR: {
      return {
        ..._formatCornerRadius(properties.cornerRadius),
        innerRadius: properties.innerRadius,
        pointCount: properties.pointCount,
      };
    }
    case FIGMA_LAYER_TYPES.TEXT: {
      return {
        fontName: properties.fontName,
        characters: properties.characters,
        fontSize: properties.fontSize,
        letterSpacing: properties.letterSpacing,
        lineHeight: properties.lineHeight,
        paragraphIndent: properties.paragraphIndent,
        paragraphSpacing: properties.paragraphSpacing,
        textAlignHorizontal: properties.textAlignHorizontal,
        textAlignVertical: properties.textAlignVertical,
        textAutoResize: properties.textAutoResize,
        textCase: properties.textCase,
        textDecoration: properties.textDecoration,
      };
    }
    case FIGMA_LAYER_TYPES.ELLIPSE:
    case FIGMA_LAYER_TYPES.LINE: {
      return {};
    }
  }
};
