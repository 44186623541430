import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LESSON_TYPES, COURSE_STRUCTURES } from 'constants/common';

import * as api from 'api/methods';

import { createCourseModule, updateCourseById } from 'store/course/actions';

import StructureSelect from './StructureSelect';

const StructureSelectContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: courseId } = useParams();

  const handleAddModuleClick = async () => {
    try {
      await dispatch(
        createCourseModule({
          courseId,
          data: { order: 1 },
        }),
      ).unwrap();
      await dispatch(
        updateCourseById({
          courseId,
          data: { structure: COURSE_STRUCTURES.MODULES },
        }),
      ).unwrap();
    } catch (error) {
      console.warn(error);
    }
  };

  const handleAddLessonClick = async (contextItem) => {
    const nextLessonOrder = 1;

    let createdLesson = null;

    try {
      switch (contextItem.key) {
        case LESSON_TYPES.THEORY_LESSON: {
          createdLesson = await api.createTheoryLesson({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.FIGMA_LESSON: {
          createdLesson = await api.createFigmaLesson({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.QUIZ: {
          createdLesson = await api.createQuiz({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.CONNECTION_QUIZ: {
          createdLesson = await api.createConnectionQuiz({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.TWO_COLUMNS_LESSON: {
          createdLesson = await api.createTwoColumnsLesson({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.DIFFERENCE_LESSON: {
          createdLesson = await api.createDifferenceLesson({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
        case LESSON_TYPES.COLOR_MATCH_LESSON: {
          createdLesson = await api.createColorMatchLesson({
            courseId,
            order: nextLessonOrder,
          });

          break;
        }
      }

      await dispatch(
        updateCourseById({
          courseId,
          data: { structure: COURSE_STRUCTURES.LESSONS },
        }),
      ).unwrap();

      navigate(`/courses/${courseId}/lessons/${createdLesson.id}`);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <StructureSelect
      {...props}
      onAddModuleClick={handleAddModuleClick}
      onAddLessonClick={handleAddLessonClick}
    />
  );
};

export default React.memo(StructureSelectContainer);
