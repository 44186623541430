import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const getReadonlyLessonText = (lessonStatus) => {
  switch (lessonStatus) {
    case STATUSES.ARCHIVED: {
      return getTranslation('helpers.get_read_only_lesson_text.archived');
    }
    case STATUSES.PUBLISHED: {
      return getTranslation('helpers.get_read_only_lesson_text.published');
    }
    default: {
      return '';
    }
  }
};
