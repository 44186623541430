export const getPropertyTooltipValue = ({
  key,
  properties,
  restProperties,
}) => {
  if (!key) {
    return null;
  }

  const property = properties.find((prt) => prt.key === key);

  if (!property?.value) {
    return null;
  }

  if (key === 'textAutoResize') {
    switch (property.value) {
      case 'WIDTH_AND_HEIGHT': {
        return 'Auto width';
      }
      case 'HEIGHT': {
        return 'Auto height';
      }
      case 'TRUNCATE': {
        return 'Fixed size';
      }
      case 'NONE': {
        return 'Fixed size';
      }
    }
  }

  if (key === 'textAlignHorizontal') {
    switch (property.value) {
      case 'LEFT': {
        return 'Text align left';
      }
      case 'RIGHT': {
        return 'Text align right';
      }
      case 'CENTER': {
        return 'Text align center';
      }
      case 'JUSTIFIED': {
        return 'Text align justified';
      }
    }
  }

  if (key === 'textAlignVertical') {
    switch (property.value) {
      case 'TOP': {
        return 'Align top';
      }
      case 'CENTER': {
        return 'Align middle';
      }
      case 'BOTTOM': {
        return 'Align bottom';
      }
    }
  }

  if (key === 'textDecoration') {
    switch (property.value) {
      case 'NONE': {
        return 'None';
      }
      case 'UNDERLINE': {
        return 'Underline';
      }
      case 'STRIKETHROUGH': {
        return 'Strike through';
      }
    }
  }

  if (key === 'textCase') {
    switch (property.value) {
      case 'ORIGINAL': {
        return 'As typed';
      }
      case 'UPPER': {
        return 'Uppercase';
      }
      case 'LOWER': {
        return 'Lowercase';
      }
      case 'TITLE': {
        return 'Title case';
      }
      case 'SMALL_CAPS': {
        return 'Small caps';
      }
      case 'SMALL_CAPS_FORCED': {
        return 'Small caps';
      }
    }
  }

  if (key === 'layoutMode') {
    switch (property.value) {
      case 'VERTICAL': {
        return 'Vertical layout';
      }
      case 'HORIZONTAL': {
        if (restProperties?.value === 'WRAP') {
          return 'Wrap';
        }

        return 'Horizontal layout';
      }
    }
  }

  if (key === 'strokeJoin') {
    switch (property.value) {
      case 'MITER': {
        return 'Miter';
      }
      case 'BEVEL': {
        return 'Bevel';
      }
      case 'ROUND': {
        return 'Round';
      }
    }
  }

  if (key === 'strokeCap') {
    switch (property.value) {
      case 'NONE': {
        return 'None';
      }
      case 'SQUARE': {
        return 'Square';
      }
      case 'ROUND': {
        return 'Round';
      }
    }
  }

  if (key === 'leadingTrim') {
    switch (property.value) {
      case 'NONE': {
        return 'Standart';
      }
      case 'CAP_HEIGHT': {
        return 'Cap height to baseline';
      }
    }
  }

  return null;
};
