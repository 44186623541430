import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/common';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { updateStudentById } from 'store/student/actions';

import { userSelector } from 'store/auth/selectors';
import { currentStudentSelector } from 'store/student/selectors';

import { convertDataToApi } from './EditStudent.helpers';

import EditStudent from './EditStudent';

const EditStudentContainer = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const user = useSelector(userSelector);
  const currentStudent = useSelector(currentStudentSelector);

  const [saveLoading, setSaveLoading] = useState(false);

  if (!currentStudent) {
    return null;
  }

  const handleSaveClick = async (values) => {
    setSaveLoading(true);

    try {
      await dispatch(
        updateStudentById({
          studentId: currentStudent.id,
          data: convertDataToApi(values),
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.student_updated.title'),
          text: getTranslation('common.toast.student_updated.text'),
        },
      });

      onClose();
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.text'),
        },
      });
    }

    setSaveLoading(false);
  };

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <EditStudent
      {...restProps}
      locale={i18n.language}
      saveLoading={saveLoading}
      isRootAdmin={isRootAdmin}
      currentStudent={currentStudent}
      onClose={onClose}
      onSaveClick={handleSaveClick}
    />
  );
};

export default React.memo(EditStudentContainer);
