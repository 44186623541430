import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { USER_ROLES } from 'constants/common';

import * as api from 'api/methods';

import * as types from './types';

export const getArcades = createAsyncThunk(
  types.GET_ARCADES,
  async (params, { getState }) => {
    const state = getState();

    const user = state.auth.user;

    const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

    const arcades = isRootAdmin
      ? await api.getArcades(params)
      : await api.getSchoolArcades({ schoolId: user.schoolId, params });

    return arcades;
  },
);

export const getSchoolArcades = createAsyncThunk(
  types.GET_SCHOOL_ARCADES,
  async (data) => {
    const arcades = await api.getSchoolArcades(data);

    return arcades;
  },
);

export const updateArcadeById = createAsyncThunk(
  types.UPDATE_ARCADE_BY_ID,
  async ({ arcadeId, fieldName, data }) => {
    const arcade = await api.updateArcadeById({ arcadeId, data });

    return { fieldName, updatedArcade: arcade };
  },
);

export const removeArcadeById = createAction(types.REMOVE_ARCADE_BY_ID);
