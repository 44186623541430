import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { EmptyState } from 'components/EmptyState';
import { TablePagination } from 'components/TablePagination';

import { Actions } from '../Actions';
import { LessonReportsItem } from '../LessonReportsItem';

import styles from './LessonReports.styles.scss';

const LessonReports = ({
  locale,
  loading,
  lessonsReports,
  onPerPageChange,
  onPrevPageClick,
  onNextPageClick,
  currentPage,
  totalPages,
  perPage,
  sortParams,
  onSortChange,
}) => (
  <>
    {loading ? (
      <Loading />
    ) : lessonsReports.length > 0 ? (
      <>
        <Actions onSortChange={onSortChange} sortParams={sortParams} />
        {lessonsReports.map((lessonReport) => (
          <div className={styles.item} key={lessonReport.id}>
            <LessonReportsItem lessonReport={lessonReport} locale={locale} />
          </div>
        ))}
        <TablePagination
          currentPage={currentPage}
          perPage={perPage}
          totalPages={totalPages}
          // onSortChange={onSortChange}
          onPerPageChange={onPerPageChange}
          onNextPageClick={onNextPageClick}
          onPrevPageClick={onPrevPageClick}
        />
      </>
    ) : (
      <EmptyState
        className={styles.emptyState}
        icon="mail"
        title={getTranslation('inbox_page.lesson_reports.empty_state.title')}
        text=""
      />
    )}
  </>
);
export default React.memo(LessonReports);
