import React from 'react';

import { Question } from '../Question';
import { Answers } from '../Answers';

import styles from './Settings.styles.scss';

const Settings = ({ readOnly }) => (
  <div className={styles.settings}>
    <Question readOnly={readOnly} />
    <div className={styles.divider} />
    <Answers />
  </div>
);

export default React.memo(Settings);
