import React from 'react';

import { TheoryPreviewBlock } from 'components/TheoryPreviewBlock';

import { Action } from '../Action';

import styles from './WebPreviewPage.styles.scss';

const WebPreviewPage = ({
  nextLoading,
  isLastLessonInCourse,
  lesson,
  onNextLessonClick,
  isTestMode,
}) => (
  <div className={styles.page}>
    <div className={styles.inner}>
      <div className={styles.blocks}>
        {lesson.blocks.map((block) => (
          <TheoryPreviewBlock
            type={block.type}
            data={block.data}
            className={styles.theoryBlock}
          />
        ))}
      </div>
      <div className={styles.actions}>
        {isTestMode && (
          <Action
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            onClick={onNextLessonClick}
          />
        )}
      </div>
    </div>
  </div>
);

export default React.memo(WebPreviewPage);
