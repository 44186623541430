import React from 'react';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getTranslation } from 'helpers/getTranslation';

import {
  isAnswersMultiple,
  isAswersEmpty,
} from '../../QuizPreviewPage.helpers';

import { Typography } from 'components/shared/Typography';

import { TheoryPreviewBlock } from 'components/TheoryPreviewBlock';

import { Action } from '../Action';
import { AnswerText } from '../AnswerText';
import { AnswerImage } from '../AnswerImage';

import styles from './PluginPreviewPage.styles.scss';

const PluginPreviewPage = ({
  nextLoading,
  isLastLessonInCourse,
  correct,
  submitted,
  questions,
  currentAnswers,
  currentQuestion: currentQuestionIndex,
  onQuestionsSwiper,
  onCheckClick,
  onAnswerClick,
  onNextQuestionClick,
  onTryAgainClick,
  onNextLessonClick,
  onRestartLessonClick,
  previewType,
  isTestMode,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.inner}>
        <Typography
          className={styles.title}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTranslation('quiz_preview_page.title')} (
          {currentQuestionIndex + 1}/{questions.length})
        </Typography>
        <div className={styles.main}>
          <Swiper
            autoHeight={true}
            className={styles.swiper}
            allowTouchMove={false}
            spaceBetween={16}
            slidesPerView={1}
            onSwiper={onQuestionsSwiper}
          >
            {questions.map((question) => {
              const answersMultiple = isAnswersMultiple(question);

              return (
                <SwiperSlide key={question.id}>
                  <div key={question.id} className={styles.question}>
                    <div className={styles.blocks}>
                      {question.blocks.map((block) => (
                        <TheoryPreviewBlock
                          key={block.id}
                          type={block.type}
                          data={block.data}
                        />
                      ))}
                    </div>

                    <div
                      className={cn(styles.answers, {
                        [styles.emptyAnswers]: isAswersEmpty(question),
                      })}
                    >
                      {question.answers.map((answer, _, answers) => {
                        switch (question.answersType) {
                          case 'TEXT': {
                            return (
                              <AnswerText
                                key={answer.id}
                                {...answer}
                                checked={currentAnswers.includes(answer.id)}
                                submitted={submitted}
                                answersMultiple={answersMultiple}
                                type={question.answersType}
                                previewType={previewType}
                                isTestMode={isTestMode}
                                onClick={() => {
                                  if (!isTestMode) {
                                    return;
                                  }
                                  onAnswerClick({
                                    answer,
                                    answersMultiple,
                                  });
                                }}
                              />
                            );
                          }
                          case 'IMAGE': {
                            return (
                              <AnswerImage
                                key={answer.id}
                                {...answer}
                                checked={currentAnswers.includes(answer.id)}
                                submitted={submitted}
                                answersMultiple={answersMultiple}
                                type={question.answersType}
                                previewType={previewType}
                                isTestMode={isTestMode}
                                onClick={() => {
                                  if (!isTestMode) {
                                    return;
                                  }
                                  onAnswerClick({
                                    answer,
                                    answersMultiple,
                                  });
                                }}
                              />
                            );
                          }
                        }
                      })}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className={styles.actions}>
        {isTestMode && (
          <Action
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            submitted={submitted}
            correct={correct}
            questions={questions}
            currentAnswers={currentAnswers}
            currentQuestion={currentQuestionIndex}
            onNextQuestionClick={onNextQuestionClick}
            onTryAgainClick={onTryAgainClick}
            onCheckClick={onCheckClick}
            onNextLessonClick={onNextLessonClick}
            onRestartLessonClick={onRestartLessonClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(PluginPreviewPage);
