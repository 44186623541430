import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import LogoIcon from 'assets/img/logo.svg';

import styles from './Header.styles.scss';

const Header = ({ className }) => (
  <div className={cn(styles.header, className)}>
    <NavLink to="/courses">
      <LogoIcon className={styles.logo} />
    </NavLink>
  </div>
);

Header.defaultProps = {
  withMenu: false,
};

export default React.memo(Header);
