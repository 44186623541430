import React from 'react';
import { DayPicker } from 'react-day-picker';

import { Caption } from './components/Caption';
import { Head } from './components/Head';
import { HeadRow } from './components/HeadRow';
import { Row } from './components/Row';
import { Day } from './components/Day';
import { DayContent } from './components/DayContent';

import styles from './DatePicker.styles.scss';

const DatePicker = ({ defaultMonth, selectedDate, onDaySelect }) => (
  <DayPicker
    classNames={styles}
    components={{ Caption, Head, HeadRow, Row, Day, DayContent }}
    mode="single"
    defaultMonth={defaultMonth}
    selected={selectedDate}
    onDayClick={onDaySelect}
  />
);

export default React.memo(DatePicker);
