import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { UserCard } from 'components/UserCard';
import { MainLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { CoursesCard } from './components/CoursesCard';

import styles from './AdminPage.styles.scss';

const AdminPage = ({
  locale,
  loading,
  currentAdmin,
  onEditClick,
  onCourseClick,
}) => (
  <MainLayout
    toolbarComponent={
      <BreadcrumbsToolbar
        breadcrumbsData={{
          adminName: currentAdmin?.name || currentAdmin?.email,
        }}
      />
    }
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        <UserCard {...currentAdmin} onEditClick={onEditClick} />
        <div className={styles.section}>
          <Typography
            className={styles.sectionTitle}
            variant="heading2"
            weight="semiBold"
          >
            {getTranslation('admin_page.title')}
          </Typography>
          {currentAdmin.courses.length ? (
            <CoursesCard
              locale={locale}
              currentAdmin={currentAdmin}
              courses={currentAdmin.courses}
              onCourseClick={onCourseClick}
            />
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="courses"
              title={getTranslation('admin_page.empty_state.title')}
              text={getTranslation('admin_page.empty_state.text')}
            />
          )}
        </div>
      </>
    )}
  </MainLayout>
);

export default React.memo(AdminPage);
