import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCourseById } from 'store/course/actions';
import { getLessonById, removeLessonById } from 'store/lesson/actions';

import { currentCourseSelector } from 'store/course/selectors';
import { currentLessonSelector } from 'store/lesson/selectors';

import { showToast } from 'helpers/showToast';

import { getTranslation } from 'helpers/getTranslation';

import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import LessonProvider from './LessonProvider';

const LessonProviderContainer = () => {
  const { courseId, lessonId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const course = useSelector(currentCourseSelector);
  const lesson = useSelector(currentLessonSelector);

  const [loading, setLoading] = useState(true);

  const [
    confirmModalOpen,
    openConfirmModal,
    closeConfirmModal,
    confirmModalData,
  ] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    setLoading(true);

    Promise.all([
      dispatch(getCourseById(courseId)),
      dispatch(getLessonById({ courseId, lessonId })),
    ]).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    });
  }, [lessonId]);

  const handleConfirmRemoveClick = async () => {
    try {
      await dispatch(
        removeLessonById({ courseId, lessonId: confirmModalData.lessonId }),
      );

      closeConfirmModal();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.remove_lesson.title'),
          text: getTranslation('common.toast.remove_lesson.text'),
        },
      });

      navigate(`/courses/${course.id}`);
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.text'),
        },
      });
    }
  };

  const handleRemoveClick = (data) =>
    openConfirmModal({
      lessonId: data.id,
      title: getTranslation('providers.lesson_provider.confirm_modal.title'),
      text: getTranslation('providers.lesson_provider.confirm_modal.text', {
        title: data.title || getTranslation('common.text.untitled'),
      }),
    });

  return (
    <>
      <LessonProvider
        loading={loading}
        course={course}
        lesson={lesson}
        onRemoveClick={handleRemoveClick}
      />
      <DefaultModal
        {...confirmModalData}
        open={confirmModalOpen}
        confirmText={getTranslation(
          'providers.lesson_provider.confirm_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeConfirmModal}
        onClose={closeConfirmModal}
      />
    </>
  );
};

export default React.memo(LessonProviderContainer);
