import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './Question.styles.scss';

const Question = ({ readOnly }) => (
  <>
    <Typography
      className={styles.title}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('two_columns_lesson_page.question.title')}
    </Typography>
    <FormikField
      name="text"
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        multiline: true,
        className: styles.text,
        variant: 'text',
        placeholder: getTranslation(
          'two_columns_lesson_page.question.placeholder',
        ),
      }}
    />
  </>
);

export default React.memo(Question);
