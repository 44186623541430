import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { PreviewPlaceholder } from 'components/PreviewPlaceholder';

import { ConnectPoint } from '../ConnectPoint';

import styles from './Item.styles.scss';

const _renderContent = ({ itemType, answersType, content }) => {
  switch (answersType) {
    case 'TEXT_TEXT': {
      return (
        <Typography className={styles.text} variant="body2" mode="compact">
          {content}
        </Typography>
      );
    }
    case 'IMAGE_TEXT': {
      if (itemType === 'LEFT_ITEM') {
        return content ? (
          <img className={styles.img} src={content} />
        ) : (
          <PreviewPlaceholder variant="connectionQuiz" />
        );
      }

      if (itemType === 'RIGHT_ITEM') {
        return (
          <Typography className={styles.text} variant="body2" mode="compact">
            {content}
          </Typography>
        );
      }

      return null;
    }
    case 'IMAGE_IMAGE': {
      return content ? (
        <img className={styles.img} src={content} />
      ) : (
        <PreviewPlaceholder variant="connectionQuiz" />
      );
    }
  }
};

const _renderItem = ({
  className,
  lineDrawing,
  linesValidated,
  correctLine,
  pointRef,
  pointUsed,
  id,
  type,
  answersType,
  content,
  onClick,
  isTestMode,
}) => (
  <div
    className={cn(
      styles.item,
      {
        [styles.readOnlyItem]: !isTestMode,
        [styles.itemSuccess]: linesValidated && correctLine,
        [styles.itemError]: linesValidated && !correctLine,
        [styles.itemText]:
          answersType === 'IMAGE_TEXT' && type === 'RIGHT_ITEM',
        [styles.itemImage]:
          (answersType === 'IMAGE_TEXT' && type === 'LEFT_ITEM') ||
          answersType === 'IMAGE_IMAGE',
      },
      className,
    )}
    onClick={(event) => onClick({ event, id, pointRef, type, pointUsed })}
  >
    {_renderContent({ itemType: type, answersType, content })}
    <ConnectPoint
      lineDrawing={lineDrawing}
      linesValidated={linesValidated}
      correctLine={correctLine}
      rootRef={pointRef}
      id={id}
      pointUsed={pointUsed}
      type={type}
    />
  </div>
);

const Item = ({ answersType, type, ...props }) => {
  if (answersType === 'IMAGE_TEXT' && type === 'RIGHT_ITEM') {
    return (
      <div className={styles.itemWrapper}>
        {_renderItem({
          answersType,
          type,
          ...props,
        })}
      </div>
    );
  }

  return _renderItem({
    answersType,
    type,
    ...props,
  });
};

export default React.memo(Item);
