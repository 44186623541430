import React from 'react';
import { useSelector } from 'react-redux';

import { COURSE_TYPES } from 'constants/common';

import * as api from 'api/methods';

import { getTrainings, removeTrainingById } from 'store/trainings/actions';
import { trainingsSelector } from 'store/trainings/selectors';

import { useCourses } from 'hooks/useCourses';

import { getTranslation } from 'helpers/getTranslation';

import { DefaultModal } from 'components/DefaultModal';
import { RemoveWithStepsModal } from 'components/RemoveWithStepsModal';

import { CreateTraining } from './components/CreateTraining';

import TrainingsPage from './TrainingsPage';

const TrainingsPageContainer = () => {
  const trainings = useSelector(trainingsSelector);

  const {
    loading,
    isRootAdmin,
    createOpen,
    removeOpen,
    removeConfirmOpen,
    sortParams,
    columns,
    removeConfirmData,
    removeData,
    closeCreate,
    closeRemoveConfirm,
    closeRemove,
    onSearchChange,
    onSortChange,
    onContextItemClick,
    onRowClick,
    onEditClick,
    onRemoveClick,
    onConfirmRemoveClick,
    onCreateClick,
  } = useCourses({
    getItems: getTrainings,
    removeItemById: removeTrainingById,
    createItem: api.createTraining,
    itemsPerPage: trainings.perPage,
    type: COURSE_TYPES.TRAINING,
    pathPrefix: '/trainings',
  });

  return (
    <>
      <TrainingsPage
        loading={loading}
        isRootAdmin={isRootAdmin}
        trainings={trainings.data}
        sortParams={sortParams}
        columns={columns}
        onSearchChange={onSearchChange}
        onSortChange={onSortChange}
        onContextItemClick={onContextItemClick}
        onTrainingRowClick={onRowClick}
        onTrainingEditClick={onEditClick}
        onTrainingRemoveClick={onRemoveClick}
        onCreateTrainingClick={onCreateClick}
      />
      <CreateTraining open={createOpen} onClose={closeCreate} />
      <DefaultModal
        {...removeConfirmData}
        open={removeConfirmOpen}
        confirmText={getTranslation('trainings_page.confirm_text')}
        onConfirmClick={onConfirmRemoveClick}
        onCancelClick={closeRemoveConfirm}
        onClose={closeRemoveConfirm}
      />
      <RemoveWithStepsModal
        {...removeData}
        open={removeOpen}
        variant="COURSE"
        title={getTranslation('trainings_page.remove_modal_title')}
        onClose={closeRemove}
      />
    </>
  );
};

export default React.memo(TrainingsPageContainer);
