export const getLessonPreviewData = (lesson, course) => {
  const lessonTitle = lesson.title;
  const currentLesson = lesson.order;
  const lessonsCount =
    course.structure === 'LESSONS'
      ? course.lessons.length
      : course.modules.find((module) => module.id === lesson.moduleId).lessons
          .length;

  return { lessonTitle, currentLesson, lessonsCount };
};
