import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';

import { getBarTrackColor } from './ModulesCard.helpers';

import styles from './ModulesCard.styles.scss';

const ModulesCard = ({ className, modules }) => (
  <div className={cn(styles.card, className)}>
    <div className={styles.header}>
      <Typography
        className={styles.title}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('dashboard_page.modules_card.title')}
      </Typography>
      <Typography
        className={styles.text}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {modules.length}
      </Typography>
    </div>
    <div className={styles.inner}>
      {modules.map((module, moduleIndex) => (
        <div className={styles.item} key={module.id}>
          <div className={styles.itemBar}>
            <Tooltip
              text={`Module ${moduleIndex + 1}: ${
                module.title || getTranslation('common.text.untitled')
              } ${module.progress}%`}
              triggerElement={
                <div
                  className={styles.itemBarTrack}
                  style={{
                    backgroundColor: getBarTrackColor(module.completedPercent),
                    height:
                      module.completedPercent === 0
                        ? 4
                        : module.completedPercent,
                  }}
                />
              }
            />
          </div>
          <Typography
            className={styles.itemText}
            variant="caption"
            weight="medium"
            mode="compact"
          >
            {moduleIndex + 1}
          </Typography>
        </div>
      ))}
    </div>
  </div>
);

export default React.memo(ModulesCard);
