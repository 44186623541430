import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from 'store/auth/actions';
import { userSelector } from 'store/auth/selectors';

import UserMenu from './UserMenu';

const UserMenuContainer = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector(userSelector);

  const handleAvatarClick = () => {
    navigate('/profile');
  };

  const handleLogoutClick = async () => {
    await dispatch(logoutUser());

    navigate('/');
  };

  if (!user) {
    return null;
  }

  return (
    <UserMenu
      {...props}
      user={user}
      onAvatarClick={handleAvatarClick}
      onLogoutClick={handleLogoutClick}
    />
  );
};

export default React.memo(UserMenuContainer);
