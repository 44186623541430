import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { DefaultModal } from 'components/DefaultModal';

import styles from './RemoveFileModal.styles.scss';

const RemoveFileModal = ({
  open,
  loading,
  removeTitle,
  removeText,
  fileName,
  onClose,
  onCancelClick,
  onConfirmClick,
}) => (
  <DefaultModal
    open={open}
    loading={loading}
    title={removeTitle}
    text={
      typeof removeText === 'string' ? removeText : removeText({ fileName })
    }
    confirmText={getTranslation('components.remove_file_modal.confirm_text')}
    onConfirmClick={onConfirmClick}
    onCancelClick={onCancelClick}
    onClose={onClose}
  />
);

RemoveFileModal.defaultProps = {
  removeTitle: getTranslation('components.remove_file_modal.title'),
  removeText: getTranslation('components.remove_file_modal.text'),
};

export default React.memo(RemoveFileModal);
