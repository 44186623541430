import React from 'react';
import cn from 'classnames';
import { ResponsivePie } from '@nivo/pie';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './LessonsCard.styles.scss';

const CenteredMetric = ({ completed, lessonsCount, centerX, centerY }) => {
  //   let total = 0;
  //   dataWithArc.forEach((datum) => {
  //     total += datum.value;
  //   });

  return (
    <g>
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 20,
          lineHeight: 24,
          fontFamily: 'Inter',
          fontWeight: 600,
          fill: '#021930',
        }}
      >
        {completed}%
      </text>
      {/* <text
        x={centerX}
        y={centerY + 12}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fill: '#5C738C',
          fontSize: 10,
          lineHeight: 12,
          fontFamily: 'Inter',
          fontWeight: 400,
        }}
      >
        {(completed * lessonsCount) / 100}
      </text> */}
    </g>
  );
};

const LessonsCard = ({
  className,
  lessonsCount,
  completed,
  notStarted,
  completedData,
  notStartedData,
}) => (
  <div className={cn(styles.card, className)}>
    <div className={styles.header}>
      <Typography
        className={styles.title}
        variant="body1"
        weight="medium"
        mode="compact"
      >
        {getTranslation('dashboard_page.lessons_card.title')}
      </Typography>
      <Typography
        className={styles.text}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {lessonsCount}
      </Typography>
    </div>
    <div className={styles.inner}>
      <div className={styles.item}>
        <ResponsivePie
          isInteractive={false}
          layers={[
            'arcLinkLabels',
            'arcs',
            'arcLabels',
            'legends',
            (props) => (
              <CenteredMetric
                {...props}
                lessonsCount={lessonsCount}
                completed={completed}
              />
            ),
          ]}
          colors={{ datum: 'data.color' }}
          data={completedData}
          margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          innerRadius={0.8}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          theme={{
            legends: {
              text: {
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: 14,
                fontWeight: 500,
              },
            },
          }}
          tooltip={() => null}
        />
        <Typography
          className={styles.itemText}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          {getTranslation('dashboard_page.lessons_card.item_completed')}
        </Typography>
      </div>
      <div className={styles.item}>
        <ResponsivePie
          isInteractive={false}
          layers={[
            'arcLinkLabels',
            'arcs',
            'arcLabels',
            'legends',
            (props) => (
              <CenteredMetric
                {...props}
                lessonsCount={lessonsCount}
                completed={notStarted}
              />
            ),
          ]}
          colors={{ datum: 'data.color' }}
          margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          data={notStartedData}
          innerRadius={0.8}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          theme={{
            legends: {
              text: {
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: 14,
                fontWeight: 500,
              },
            },
          }}
          tooltip={() => null}
        />
        <Typography
          className={styles.itemText}
          variant="body3"
          weight="medium"
          mode="compact"
        >
          {getTranslation('dashboard_page.lessons_card.item_not_started')}
        </Typography>
      </div>
    </div>
  </div>
);

export default React.memo(LessonsCard);
