import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './RadioBox.styles.scss';

const RadioBox = ({
  className,
  checked,
  startAdornment,
  endAdornment,
  label,
  ...restProps
}) => (
  <div
    className={cn(
      styles.box,
      {
        [styles.boxChecked]: checked,
        [styles.boxWithStartAdornment]: !!startAdornment,
        [styles.boxWithEndAdornment]: !!endAdornment,
      },
      className,
    )}
    tabIndex="-1"
  >
    <input
      type="radio"
      className={styles.field}
      checked={checked}
      {...restProps}
    />
    <div className={styles.inner}>
      {startAdornment && (
        <div className={styles.startAdornment}>{startAdornment}</div>
      )}
      <Typography
        className={styles.label}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {label}
      </Typography>
      {endAdornment && (
        <div className={styles.endAdornment}>{endAdornment}</div>
      )}
    </div>
  </div>
);

export default React.memo(RadioBox);
