import React from 'react';

import { getSchoolCourses } from 'store/courses/actions';

import { getTranslation } from 'helpers/getTranslation';
import { modifyPreviewForTableRender } from 'helpers/modifyPreviewForTableRender';

import { Loading } from 'components/shared/Loading';

import { CoursesTable } from 'components/CoursesTable';
import { EmptyState } from 'components/EmptyState';

import { getCoursesTableColums } from './Content.tableConfig';

import styles from './Content.styles.scss';

const Content = ({
  loading,
  courses,
  schoolId,
  sortParams,
  onSortChange,
  onCourseRowClick,
}) =>
  loading ? (
    <Loading />
  ) : courses && courses.length > 0 ? (
    <CoursesTable
      columns={getCoursesTableColums()}
      data={modifyPreviewForTableRender(courses)}
      dataAction={({ page, limit, sortBy, sortDirection }) =>
        getSchoolCourses({
          schoolId,
          params: { page, limit, sortBy, sortDirection },
        })
      }
      params={sortParams}
      onSortChange={onSortChange}
      onRowClick={onCourseRowClick}
    />
  ) : (
    <EmptyState
      className={styles.emptyState}
      icon="courses"
      title={getTranslation('school_page.courses.empty_state.title')}
      text={getTranslation('school_page.courses.empty_state.text')}
    />
  );

export default React.memo(Content);
