import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';

import {
  FIGMA_CLIENT_ID,
  FIGMA_CLIENT_SECRET,
  FIGMA_REDIRECT_URI,
  FIGMA_STATE,
} from 'constants/env';
import { FIGMA_TOKEN_KEY, FIGMA_REFRESH_TOKEN_KEY } from 'constants/token';

import { useQuery } from 'hooks/useQuery';

import FigmaAuthPage from './FigmaAuthPage';

const FigmaAuthPageContainer = () => {
  const query = useQuery();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const code = query.get('code');
    const state = JSON.parse(query.get('state'));

    if (state && state.figmaState === FIGMA_STATE) {
      const params = {
        client_id: FIGMA_CLIENT_ID,
        client_secret: FIGMA_CLIENT_SECRET,
        redirect_uri: `${window.location.origin}${FIGMA_REDIRECT_URI}`,
        code,
        grant_type: 'authorization_code',
      };

      fetch(`https://www.figma.com/api/oauth/token?${qs.stringify(params)}`, {
        method: 'POST',
      })
        .then((response) => response.json())
        .then((response) => {
          setLoading(false);

          const { courseId, lessonId } = state;

          localStorage.setItem(FIGMA_TOKEN_KEY, response.access_token);
          localStorage.setItem(FIGMA_REFRESH_TOKEN_KEY, response.refresh_token);

          navigate(`/courses/${courseId}/lessons/${lessonId}`, {
            replace: true,
          });
        })
        .catch((error) => {
          console.warn(error);

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return <FigmaAuthPage loading={loading} />;
};

export default React.memo(FigmaAuthPageContainer);
