import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './DefaultTab.styles.scss';

const DefaultTab = ({
  className,
  active,
  disabled,
  label,
  onClick,
  textClassName,
}) => (
  <div
    className={cn(
      styles.tab,
      { [styles.tabActive]: active, [styles.tabDisabled]: disabled },
      className,
    )}
    onClick={() => {
      if (disabled) {
        return;
      }
      onClick(label);
    }}
  >
    <Typography
      className={cn(styles.text, textClassName)}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {label}
    </Typography>
  </div>
);

export default React.memo(DefaultTab);
