import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './EmptyProperties.styles.scss';

const EmptyProperties = () => (
  <div className={styles.root}>
    <Icon className={styles.icon} name="stop" />
    <Typography className={styles.text} variant="body1" weight="medium">
      Properties not set
    </Typography>
  </div>
);

export default React.memo(EmptyProperties);
