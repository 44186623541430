import { getTranslation } from 'helpers/getTranslation';

export const getDifficultyText = (difficulty) => {
  switch (difficulty) {
    case 'EASY': {
      return getTranslation('helpers.difficulty_text.easy');
    }
    case 'MEDIUM': {
      return getTranslation('helpers.difficulty_text.medium');
    }
    case 'HARD': {
      return getTranslation('helpers.difficulty_text.hard');
    }
  }
};
