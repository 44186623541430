import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { currentCourseSelector } from 'store/course/selectors';

import CourseTree from './CourseTree';

const CourseTreeContainer = (props) => {
  const navigate = useNavigate();

  const { values: currentLesson } = useFormikContext();

  const course = useSelector(currentCourseSelector);

  const handleLessonClick = (lesson) => {
    navigate(`/courses/${lesson.courseId}/lessons/${lesson.id}`);
  };

  return (
    <CourseTree
      {...props}
      course={course}
      currentLesson={currentLesson}
      onLessonClick={handleLessonClick}
    />
  );
};

export default React.memo(CourseTreeContainer);
