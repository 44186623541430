import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { userSelector } from 'store/auth/selectors';
import { studentsSelector } from 'store/students/selectors';
import { getSchoolStudents } from 'store/students/actions';

import Students from './Students';

const StudentsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: paramSchoolId } = useParams();

  const students = useSelector(studentsSelector);
  const user = useSelector(userSelector);

  const schoolId = paramSchoolId || user.schoolId;

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'updatedAt',
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getStudentsBySchoolId = async () => {
      try {
        await dispatch(
          getSchoolStudents({
            schoolId,
            params: {
              page: 1,
              limit: 10,
              sortBy: sortParams.sortBy,
              sortDirection: sortParams.sortDirection,
            },
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getStudentsBySchoolId();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getSchoolStudents({
          schoolId,
          params: {
            page: 1,
            limit: 50,
            sortBy: nextSortBy,
            sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
          },
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleEditStudentClick = (student) => {
    navigate(`/students/${student.id}`);
  };

  const handleStudentRowClick = (studentId) => {
    navigate(`/students/${studentId}`);
  };

  return (
    <Students
      loading={loading}
      students={students.data}
      schoolId={schoolId}
      sortParams={sortParams}
      onSortChange={handleSortChange}
      onEditStudentClick={handleEditStudentClick}
      onStudentRowClick={handleStudentRowClick}
    />
  );
};

export default React.memo(StudentsContainer);
