import React from 'react';

import { LESSON_TYPES } from 'constants/common';

import { Loading } from 'components/shared/Loading';

import { TheoryLessonPreviewPage } from 'pages/TheoryLessonPreviewPage';
import { QuizPreviewPage } from 'pages/QuizPreviewPage';
import { ConnectionQuizPreviewPage } from 'pages/ConnectionQuizPreviewPage';
import { FigmaLessonPreviewPage } from 'pages/FigmaLessonPreviewPage';
import { TwoColumnsLessonPreviewPage } from 'pages/TwoColumnsLessonPreviewPage';
import { DifferenceLessonPreviewPage } from 'pages/DifferenceLessonPreviewPage';
import { ColorMatchLessonPreviewPage } from 'pages/ColorMatchLessonPreviewPage';

const LessonPreviewProvider = ({ loading, lesson }) => {
  if (loading) {
    return <Loading />;
  }

  switch (lesson.type) {
    case LESSON_TYPES.THEORY_LESSON: {
      return <TheoryLessonPreviewPage />;
    }
    case LESSON_TYPES.QUIZ: {
      return <QuizPreviewPage />;
    }
    case LESSON_TYPES.CONNECTION_QUIZ: {
      return <ConnectionQuizPreviewPage />;
    }
    case LESSON_TYPES.FIGMA_LESSON: {
      return <FigmaLessonPreviewPage />;
    }
    case LESSON_TYPES.TWO_COLUMNS_LESSON: {
      return <TwoColumnsLessonPreviewPage />;
    }
    case LESSON_TYPES.DIFFERENCE_LESSON: {
      return <DifferenceLessonPreviewPage />;
    }
    case LESSON_TYPES.COLOR_MATCH_LESSON: {
      return <ColorMatchLessonPreviewPage />;
    }
  }
};

export default React.memo(LessonPreviewProvider);
