import React from 'react';
import cn from 'classnames';

import { STATUSES } from 'constants/common';

import { FormikField } from 'components/FormikField';
import { CheckboxField } from 'components/FormikFields';

import { CoursePreviewItem } from 'components/CoursePreviewItem';

import styles from './CoursesAccessList.styles.scss';

const CoursesAccessList = ({
  isTestStudent,
  isAdminStudent,
  courses,
  className,
}) => (
  <div className={styles.list}>
    {courses.map((course) => (
      <div
        className={cn(styles.item, className, {
          [styles.itemDisabled]:
            !isTestStudent &&
            course.status !== STATUSES.PUBLISHED &&
            !isAdminStudent,
        })}
        key={course.id}
      >
        <FormikField
          name="courses"
          component={CheckboxField}
          componentProps={{
            className: styles.check,
            size: 'sm',
            type: 'checkbox',
            value: course.id,
          }}
          withControl={false}
        />
        <CoursePreviewItem
          className={styles.itemInner}
          title={course.title}
          preview={course.preview}
          status={course.status}
        />
      </div>
    ))}
  </div>
);

export default React.memo(CoursesAccessList);
