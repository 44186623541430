import { createSlice } from '@reduxjs/toolkit';

import {
  getTrainings,
  getSchoolTrainings,
  updateTrainingById,
  removeTrainingById,
} from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: null,
  perPage: 10,
};

const trainingsSlice = createSlice({
  name: 'trainings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrainings.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(getSchoolTrainings.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateTrainingById.fulfilled, (state, action) => {
      const { fieldName, updatedTraining } = action.payload;

      return {
        ...state,
        data: state.data.map((training) => {
          if (training.id === updatedTraining.id) {
            return {
              ...training,
              [fieldName]: updatedTraining[fieldName],
            };
          }

          return training;
        }),
      };
    });

    builder.addCase(removeTrainingById, (state, action) => {
      return {
        ...state,
        data: state.data.filter((training) => training.id !== action.payload),
      };
    });
  },
});

export default trainingsSlice.reducer;
