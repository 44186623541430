import React from 'react';

import { getSchoolStudents } from 'store/students/actions';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { StudentsTable } from 'components/StudentsTable';
import { EmptyState } from 'components/EmptyState';

import { getStudentsTableColumns } from './Students.tableConfig';

import styles from './Students.styles.scss';

const Students = ({
  loading,
  students,
  schoolId,
  sortParams,
  onSortChange,
  onEditStudentClick,
  onStudentRowClick,
}) =>
  loading ? (
    <Loading />
  ) : students && students.length > 0 ? (
    <StudentsTable
      columns={getStudentsTableColumns()}
      data={students}
      dataAction={({ page, limit, sortBy, sortDirection }) =>
        getSchoolStudents({
          schoolId,
          params: { page, limit, sortBy, sortDirection },
        })
      }
      params={sortParams}
      onSortChange={onSortChange}
      onEditStudentClick={onEditStudentClick}
      onStudentRowClick={onStudentRowClick}
    />
  ) : (
    <EmptyState
      className={styles.emptyState}
      icon="student"
      title={getTranslation('school_page.students.empty_state.title')}
      text={getTranslation('school_page.students.empty_state.text')}
    />
  );

export default React.memo(Students);
