import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './ToolbarIcon.styles.scss';

const ToolbarIcon = ({ className, active, iconName, onClick }) => (
  <>
    <Icon
      className={cn(styles.icon, { [styles.iconActive]: active }, className)}
      name={iconName}
      onMouseDown={onClick}
    />
  </>
);

export default React.memo(ToolbarIcon);
