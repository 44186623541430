import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import styles from './CornerRadiusProperty.styles.scss';

const _getIconName = (index) => {
  switch (index) {
    case 0: {
      return 'radiusTopRight';
    }
    case 1: {
      return 'radiusTopLeft';
    }
    case 2: {
      return 'radiusBottomLeft';
    }
    case 3: {
      return 'radiusBottomRight';
    }
  }
};

const _renderDifferentRadiusContent = ({ value }) => (
  <div className={styles.items}>
    {value.map((item, itemIndex) => (
      <div className={styles.item}>
        <Icon className={styles.icon} name={_getIconName(itemIndex)} />
        <Typography
          className={styles.value}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {item}
        </Typography>
      </div>
    ))}
  </div>
);

const _renderRadiusContent = ({ value }) => (
  <>
    <Icon className={styles.icon} name="radiusTopRight" />
    <Typography
      className={styles.value}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {value}
    </Typography>
  </>
);

const _renderField = ({ namePrefix, readOnly, property, onChanged }) => (
  <FormikField
    name={namePrefix}
    withControl={false}
    component={PropertySelectField}
    componentProps={{
      type: 'checkbox',
      readOnly,
      property,
      renderProperty: _renderRadiusContent,
      onChanged,
    }}
  />
);

const CornerRadiusProperty = ({
  isInitialState,
  readOnly,
  tooltip,
  designType,
  namePrefix,
  property,
  onChanged,
}) => {
  if (
    typeof property.value === 'string' ||
    typeof property.value === 'number'
  ) {
    if (isInitialState || designType === 'PROTOTYPE') {
      if (tooltip) {
        return (
          <Tooltip
            text={tooltip}
            triggerElement={
              <div className={styles.propertyReadOnly}>
                {_renderRadiusContent(property)}
              </div>
            }
          />
        );
      }

      return (
        <div className={styles.propertyReadOnly}>
          {_renderRadiusContent(property)}
        </div>
      );
    }

    if (tooltip) {
      return (
        <Tooltip
          text={tooltip}
          triggerElement={_renderField({
            namePrefix,
            readOnly,
            property,
            onChanged,
          })}
        />
      );
    }

    return _renderField({
      namePrefix,
      readOnly,
      property,
      onChanged,
    });
  }

  if (Array.isArray(property.value)) {
    if (isInitialState) {
      return (
        <div className={styles.propertyReadOnly}>
          {_renderDifferentRadiusContent(property)}
        </div>
      );
    }

    return (
      <FormikField
        name={namePrefix}
        withControl={false}
        component={PropertySelectField}
        componentProps={{
          type: 'checkbox',
          readOnly,
          property,
          renderProperty: _renderDifferentRadiusContent,
          onChanged,
        }}
      />
    );
  }

  return null;
};

export default React.memo(CornerRadiusProperty);
