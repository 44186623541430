import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './CompletedIcon.styles.scss';

const CompletedIcon = ({ className }) => (
  <div className={cn(styles.wrapper, className)}>
    <Icon name="check" className={styles.icon} />
  </div>
);

export default React.memo(CompletedIcon);
