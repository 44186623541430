import { COURSE_STEPS, STUDENT_STEPS } from './RemoveWithStepsModal.config';

export const getStepsByVariant = (variant) => {
  switch (variant) {
    case 'COURSE': {
      return COURSE_STEPS.map((step) => ({
        ...step,
        loading: false,
        error: false,
        completed: false,
      }));
    }
    case 'STUDENT': {
      return STUDENT_STEPS.map((step) => ({
        ...step,
        loading: false,
        error: false,
        completed: false,
      }));
    }
  }
};

export const getStepsProgress = (steps) => {
  const completedSteps = steps.filter((step) => step.completed);

  return (completedSteps.length * 100) / steps.length;
};
