import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { InputField, SelectField } from 'components/FormikFields';
import { FormikField } from 'components/FormikField';

import {
  initialValues,
  getlanguageOptions,
  getValidationSchema,
} from './AddSchool.formConfig';

import styles from './AddSchool.styles.scss';

const AddSchool = ({ open, addLoading, onClose, onAddSchoolClick }) => (
  <Drawer
    open={open}
    headerContent={getTranslation('schools_page.add_school.title')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onAddSchoolClick}
    >
      <Form className={styles.form}>
        <div className={styles.inner}>
          <FormikField
            name="name"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('schools_page.add_school.name_label'),
            }}
          />
          <FormikField
            name="language"
            component={SelectField}
            controlProps={{
              required: true,
              label: getTranslation('schools_page.add_school.lang_label'),
            }}
            componentProps={{ options: getlanguageOptions() }}
          />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              addLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.add')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(AddSchool);
