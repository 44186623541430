import React from 'react';
import cn from 'classnames';

import { COURSE_STRUCTURES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { LessonIcon } from 'components/LessonIcon';

import styles from './LessonTitleCell.styles.scss';

const LessonTitleCell = ({
  courseStructure,
  minWidth,
  maxWidth,
  title,
  type,
}) => (
  <div
    className={cn(styles.cell, {
      [styles.lessonsStructureCell]:
        courseStructure === COURSE_STRUCTURES.LESSONS,
    })}
    style={{ minWidth, maxWidth }}
  >
    <div className={styles.iconWrapper}>
      <LessonIcon className={styles.icon} type={type} />
    </div>
    <div className={styles.inner}>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {title || getTranslation('common.text.untitled')}
      </Typography>
    </div>
  </div>
);

export default React.memo(LessonTitleCell);
