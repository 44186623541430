import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getTranslation } from 'helpers/getTranslation';

import { updateSchoolById } from 'store/school/actions';

import { currentSchoolSelector } from 'store/school/selectors';

import { showToast } from 'helpers/showToast';

import { convertDataToApi } from './EditSchool.helpers';

import EditSchool from './EditSchool';

const EditSchoolContainer = ({ onClose, ...restProps }) => {
  const dispatch = useDispatch();

  const [updateLoading, setUpdateLoading] = useState(false);

  const currentSchool = useSelector(currentSchoolSelector);

  const handleUpdateSchoolClick = async (values) => {
    setUpdateLoading(true);

    try {
      await dispatch(
        updateSchoolById({
          schoolId: values.id,
          data: convertDataToApi(values),
        }),
      ).unwrap();

      onClose();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.school_updated.title'),
          text: getTranslation('common.toast.school_updated.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }

    setUpdateLoading(false);
  };

  return (
    <EditSchool
      {...restProps}
      updateLoading={updateLoading}
      currentSchool={currentSchool}
      onClose={onClose}
      onUpdateSchoolClick={handleUpdateSchoolClick}
    />
  );
};

export default React.memo(EditSchoolContainer);
