import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from 'locales/en.json';
import ruTranslations from 'locales/ru.json';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: enTranslations,
    },
    ru: {
      translation: ruTranslations,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
