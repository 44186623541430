export const SOCIAL_ICONS = [
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/befront/',
  },
  {
    icon: 'instagram',
    link: 'https://www.instagram.com/befront.io/',
  },
  {
    icon: 'discord',
    link: 'https://discord.gg/XBr29KFEgd',
  },
  {
    icon: 'tiktok',
    link: 'https://www.tiktok.com/@befront.io',
  },
];
