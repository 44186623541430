import _orderBy from 'lodash/orderBy';

import { getLayerBaseProperties } from './getLayerBaseProperties';
import { getLayerProperties } from './getLayerProperties';

const _orderLayers = (layers) =>
  _orderBy(
    layers.map((layer, layerIndex) => ({
      ...layer,
      layerOrder: layers.length - 1 - layerIndex,
    })),
    [
      (layer) => {
        return layer.layerOrder;
      },
    ],
    ['asc'],
  );

const _getDefaultLayerOptions = (layer) => {
  if (layer.type === 'TEXT') {
    return { copy: false };
  }

  return {};
};

const _formatNextLayer = ({ layer, componentsLayers }) => ({
  id: layer.id,
  name: layer.name,
  type: layer.type,
  children: layer.children,
  properties: [
    ...getLayerBaseProperties(layer),
    ...getLayerProperties({ layer, componentsLayers }),
  ],
});

export const transformLayers = ({
  layers,
  currentLayers,
  componentsLayers,
  parent,
}) => {
  const nextLayers = [];

  const orderedLayers = _orderLayers(layers);

  for (let i = 0; i < orderedLayers.length; i++) {
    const nextLayer = _formatNextLayer({
      layer: {
        ...orderedLayers[i],
        parent,
      },
      componentsLayers,
    });

    const currentLayer = currentLayers.find(
      (layer) => layer.id === nextLayer.id,
    );

    if (!currentLayer) {
      nextLayers.push({
        ...nextLayer,
        options: _getDefaultLayerOptions(nextLayer),
        properties: nextLayer.properties.map((nextProperty) => {
          return {
            ...nextProperty,
            check: false,
          };
        }),
        children:
          nextLayer.children && nextLayer.children.length
            ? transformLayers({
                layers: nextLayer.children,
                currentLayers: [],
                componentsLayers,
                parent: {
                  type: nextLayer.type,
                  name: nextLayer.name,
                  relativeTransform: orderedLayers[i].relativeTransform,
                  parent,
                },
              })
            : [],
        parent,
      });
    } else {
      nextLayers.push({
        ...nextLayer,
        options:
          currentLayer.options && Object.keys(currentLayer.options).length > 0
            ? currentLayer.options
            : _getDefaultLayerOptions(nextLayer),
        properties: nextLayer.properties.map((nextProperty) => {
          const currentProperty = currentLayer?.properties.find(
            (property) => property.key === nextProperty.key,
          );

          if (currentProperty) {
            return {
              ...nextProperty,
              check: currentProperty.check,
              value:
                currentProperty.key === 'pointCount' ||
                currentProperty.key === 'innerRadius' ||
                currentProperty.key === 'reactions' ||
                currentProperty.key === 'vectorPaths'
                  ? currentProperty.value
                  : nextProperty.value,
            };
          }

          return {
            ...nextProperty,
            check: false,
          };
        }),
        children:
          nextLayer.children && nextLayer.children.length
            ? transformLayers({
                layers: nextLayer.children,
                currentLayers: currentLayer.children || [],
                componentsLayers,
                parent: {
                  type: nextLayer.type,
                  name: nextLayer.name,
                  relativeTransform: orderedLayers[i].relativeTransform,
                  parent,
                },
              })
            : [],
        parent,
      });
    }
  }

  return nextLayers;
};
