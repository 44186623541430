import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { LESSON_TYPES } from 'constants/common';

import { getLessonPathByCourseType } from 'helpers/getLessonPathByCourseType';

import * as api from 'api/methods';

import AddLessonButton from './AddLessonButton';

const AddLessonButtonContainer = ({
  moduleId,
  lessons,
  courseType,
  ...restProps
}) => {
  const navigate = useNavigate();

  const params = useParams();

  const courseId = params.courseId || params.id;

  const handleAddLessonClick = async (contextItem) => {
    const nextLessonOrder = lessons.length + 1;

    try {
      switch (contextItem.key) {
        case LESSON_TYPES.THEORY_LESSON: {
          const createdLesson = await api.createTheoryLesson({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.FIGMA_LESSON: {
          const createdLesson = await api.createFigmaLesson({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.QUIZ: {
          const createdQuiz = await api.createQuiz({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdQuiz.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.CONNECTION_QUIZ: {
          const createdLesson = await api.createConnectionQuiz({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.TWO_COLUMNS_LESSON: {
          const createdLesson = await api.createTwoColumnsLesson({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.DIFFERENCE_LESSON: {
          const createdLesson = await api.createDifferenceLesson({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
        case LESSON_TYPES.COLOR_MATCH_LESSON: {
          const createdLesson = await api.createColorMatchLesson({
            courseId,
            moduleId,
            order: nextLessonOrder,
          });

          navigate(
            getLessonPathByCourseType({
              courseType,
              courseId,
              lessonId: createdLesson.id,
            }),
          );

          break;
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <AddLessonButton {...restProps} onAddLessonClick={handleAddLessonClick} />
  );
};

export default React.memo(AddLessonButtonContainer);
