import React from 'react';
import cn from 'classnames';

import { Sidebar } from 'components/Sidebar';

import styles from './MenuLayout.styles.scss';

const MenuLayout = ({ className, mainClassName, children }) => (
  <div className={cn(styles.layout, className)}>
    <div className={cn(styles.container, 'menu-layout-container')}>
      <Sidebar />
      <div className={styles.inner}>
        <div className={cn(styles.main, mainClassName)}>{children}</div>
      </div>
    </div>
  </div>
);

export default React.memo(MenuLayout);
