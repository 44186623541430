import React from 'react';

import { useModal } from 'hooks/useModal';

import { Filters } from '../Filters';

import Actions from './Actions';

const ActionsContainer = ({ onSortChange, sortParams }) => {
  const [filtersOpen, openFilters, closeFilters] = useModal({
    defaultOpen: false,
  });

  const handleFiltersClick = () => {
    openFilters();
  };

  const handleSortClick = (values) => {
    onSortChange(values);
  };

  return (
    <>
      <Actions
        onFiltersClick={handleFiltersClick}
        onSortChange={handleSortClick}
        sortParams={sortParams}
      />
      <Filters open={filtersOpen} onClose={closeFilters} />
    </>
  );
};

export default React.memo(ActionsContainer);
