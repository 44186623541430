import React from 'react';

import { Typography } from 'components/shared/Typography';

import { CoursePreview } from 'components/CoursePreview';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { StudentInfo } from '../StudentInfo';

import styles from './LessonReportsItem.styles.scss';

const LessonReportsItem = ({ lessonReport, locale }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.courseCell}>
          <CoursePreview
            className={styles.coursePreview}
            preview={lessonReport.course.preview}
          />
          <div className={styles.courseDescription}>
            <Typography
              className={styles.courseTitle}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {lessonReport.course.title ||
                getTranslation('common.text.untitled')}
            </Typography>
            <Typography
              className={styles.lessonTitle}
              variant="body3"
              mode="compact"
            >
              {`${
                lessonReport.module
                  ? lessonReport.module.title ||
                    getTranslation('common.text.untitled')
                  : ''
              } - ${
                lessonReport.lesson.title ||
                getTranslation('common.text.untitled')
              }`}
            </Typography>
          </div>
        </div>
        <StudentInfo data={lessonReport} />
        <Typography variant="body3" className={styles.date}>
          {lessonReport.createdAt &&
            formatDate(lessonReport.createdAt, 'dd MMM, yyyy HH:MM', locale)}
        </Typography>
      </div>
      <div className={styles.content}>
        {lessonReport.tags.length > 0 && (
          <div className={styles.tags}>
            {lessonReport.tags.map((tag) => (
              <div className={styles.tag} key={tag}>
                <Typography
                  className={styles.tagText}
                  variant="body3"
                  weight="medium"
                  mode="compact"
                >
                  {tag}
                </Typography>
              </div>
            ))}
          </div>
        )}
        {lessonReport.text && (
          <Typography variant="body3" className={styles.text}>
            {lessonReport.text}
          </Typography>
        )}
      </div>
    </>
  );
};

export default React.memo(LessonReportsItem);
