import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { v4 as uuid } from 'uuid';

import * as api from 'api/methods';

import { getTranslation } from 'helpers/getTranslation';

import { useChangeOrder } from 'hooks/useChangeOrder';
import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import TheoryBlocks from './TheoryBlocks';

const TheoryBlocksContainer = ({ namePrefix, ...restProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const [removeModalOpen, openRemoveModal, closeRemoveModal, removeModalData] =
    useModal({
      defaultOpen: false,
    });

  const _handleModuleDragEnd = () => {};

  const blocks = getIn(values, namePrefix);

  const { sensors, onItemDragStart, onItemDragEnd, onItemDragOver } =
    useChangeOrder({
      withOrder: false,
      items: blocks,
      namePrefix,
      onItemDragEnd: _handleModuleDragEnd,
    });

  const handleBlockClick = (block) => {
    switch (block.key) {
      case 'heading1':
      case 'heading2':
      case 'heading3':
      case 'text': {
        setFieldValue(namePrefix, [
          ...blocks,
          { id: uuid(), type: block.key, data: '' },
        ]);

        break;
      }
      case 'spoiler': {
        setFieldValue(namePrefix, [
          ...blocks,
          { id: uuid(), type: block.key, data: { title: '', text: '' } },
        ]);

        break;
      }
      case 'numberList': {
        setFieldValue(namePrefix, [
          ...blocks,
          { id: uuid(), type: block.key, data: [''] },
        ]);

        break;
      }
      case 'markerList': {
        setFieldValue(namePrefix, [
          ...blocks,
          { id: uuid(), type: block.key, data: [''] },
        ]);

        break;
      }
      case 'image':
      case 'video': {
        setFieldValue(namePrefix, [
          ...blocks,
          { id: uuid(), type: block.key, data: { url: '', caption: '' } },
        ]);

        break;
      }
      case 'divider': {
        setFieldValue(namePrefix, [...blocks, { id: uuid(), type: block.key }]);

        break;
      }
    }
  };

  const handleRemoveBlockClick = (block) => {
    openRemoveModal({
      data: block,
      title: getTranslation('components.theory_blocks.remove_modal.title'),
      text: getTranslation('components.theory_blocks.remove_modal.text'),
    });
  };

  const handleConfirmRemoveClick = async (block) => {
    setFieldValue(
      namePrefix,
      blocks.filter((currentBlock) => currentBlock.id !== block.id),
    );

    if (block.type === 'image' && block.data.url) {
      await api.removeFile(block.data.url);
    }

    closeRemoveModal();
  };

  return (
    <>
      <TheoryBlocks
        {...restProps}
        readOnly={values.readOnly}
        blocks={blocks}
        sensors={sensors}
        namePrefix={namePrefix}
        onBlockDragStart={onItemDragStart}
        onBlockDragEnd={onItemDragEnd}
        onBlockDragOver={onItemDragOver}
        onBlockClick={handleBlockClick}
        onRemoveBlockClick={handleRemoveBlockClick}
      />
      <DefaultModal
        {...removeModalData}
        open={removeModalOpen}
        confirmText={getTranslation(
          'components.theory_blocks.remove_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveModal}
        onClose={closeRemoveModal}
      />
    </>
  );
};

export default React.memo(TheoryBlocksContainer);
