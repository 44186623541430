import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField, PasswordField } from 'components/FormikFields';

import { initialValues, getValidationSchema } from './AuthForm.formConfig';

import styles from './AuthForm.styles.scss';

const AuthForm = ({ loading, onSubmit, onForgotPasswordClick }) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={getValidationSchema()}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <Form className={styles.form}>
        <FormikField
          name="email"
          component={InputField}
          controlProps={{
            className: styles.field,
            label: getTranslation('auth_page.auth_form.label.email'),
          }}
        />
        <FormikField
          className={styles.field}
          name="password"
          component={PasswordField}
          controlProps={{
            className: styles.field,
            label: getTranslation('auth_page.auth_form.label.password'),
          }}
        />
        <div className={styles.actions}>
          <Button
            className={styles.action}
            type="submit"
            size="lg"
            startIcon={
              loading && <Loading color="var(--color-white-primary)" />
            }
          >
            {getTranslation('auth_page.auth_form.log_in')}
          </Button>
          <Button
            className={styles.action}
            variant="link"
            size="md"
            onClick={() => onForgotPasswordClick(values)}
          >
            {getTranslation('auth_page.auth_form.link')}
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

export default React.memo(AuthForm);
