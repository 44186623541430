import React from 'react';
import cn from 'classnames';

import { Avatar } from 'components/shared/Avatar';

import styles from './SchoolPreview.styles.scss';

const SchoolPreview = ({ className, preview, size, name, email }) =>
  preview ? (
    <img className={cn(styles.preview, className)} src={preview} />
  ) : (
    <Avatar
      className={cn(styles.preview, className)}
      size={size}
      text={name || email}
    />
  );

SchoolPreview.defaultProps = {
  size: 'sm',
};

export default React.memo(SchoolPreview);
