import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './Tag.styles.scss';

const VARIANTS = {
  info: styles.info,
  warning: styles.warning,
  success: styles.success,
};

const Tag = ({ className, textClassName, children, variant }) => (
  <div className={cn(styles.tag, VARIANTS[variant], className)}>
    <Typography
      className={cn(styles.text, textClassName)}
      variant="body3"
      mode="compact"
      weight="semiBold"
    >
      {children}
    </Typography>
  </div>
);

export default React.memo(Tag);
