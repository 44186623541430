import { getTranslation } from 'helpers/getTranslation';

export const getAdminsTableColumns = () => [
  {
    key: 'name',
    label: getTranslation('school_page.admins.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('school_page.admins.table_keys.email'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('school_page.admins.table_keys.start_date'),
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('school_page.admins.table_keys.status'),
    sortable: true,
  },
  // {
  //   key: 'updatedAt',
  //   label: 'Last activity',
  // },
];
