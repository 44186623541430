import { getLayerBoundingBox } from './getLayerBoundingBox';
import { formatLayerColor } from './formatLayerColor';
import { formatLayerEffect } from './formatLayerEffect';
import { getLayerRotation } from './getLayerRotation';
import { getLayerConstraints } from './getLayerConstraints';
import { getLayerOpacity } from './getLayerOpacity';
import { getLayerStrokeWeight } from './getLayerStrokeWeight';
import { getLayerStrokeCap } from './getLayerStrokeCap';
import { getLayerStrokeDashes } from './getLayerStrokeDashes';
import { getLayerStrokeJoin } from './getLayerStrokeJoin';
import { getLayerReactions } from './getLayerReactions';
import { getLayerStrokeStyle } from './getLayerStrokeStyle';
import { getComponentPropertyReferences } from './getComponentPropertyReferences';

export const getLayerBaseProperties = (layer) => {
  const baseProps = [
    ...Object.entries(getLayerBoundingBox(layer)).map(([key, value]) => ({
      key,
      value,
    })),
    {
      key: 'isMask',
      value: layer.isMask || false,
    },
    {
      key: 'locked',
      value: layer.locked || false,
    },
    {
      key: 'visible',
      value: layer.visible === undefined ? true : layer.visible,
    },
    {
      key: 'rotation',
      value: getLayerRotation(layer.relativeTransform),
    },
    {
      key: 'constraints',
      value: getLayerConstraints(layer),
    },
    {
      key: 'blendMode',
      value: layer.blendMode,
    },
    {
      key: 'opacity',
      value: getLayerOpacity(layer),
    },
    {
      key: 'fills',
      value: layer.fills.map((fill) => formatLayerColor(fill)),
    },
    {
      key: 'strokes',
      value: layer.strokes.map((stroke) => formatLayerColor(stroke)),
    },
    {
      key: 'strokeWeight',
      value: getLayerStrokeWeight(layer),
    },
    {
      key: 'strokeAlign',
      value: layer.strokeAlign,
    },
    {
      key: 'strokeStyle',
      value: getLayerStrokeStyle(layer),
    },
    {
      key: 'strokeCap',
      value: getLayerStrokeCap(layer),
    },
    {
      key: 'strokeMiterAngle',
      value: layer.strokeMiterAngle || 28.96,
    },
    {
      key: 'dashPattern',
      value: getLayerStrokeDashes(layer),
    },
    {
      key: 'strokeJoin',
      value: getLayerStrokeJoin(layer),
    },
    {
      key: 'effects',
      value: layer.effects.map((effect) => formatLayerEffect(effect)),
    },
    {
      key: 'parent',
      value: layer.parent
        ? {
            type: layer.parent.type,
            name: layer.parent.name,
          }
        : null,
    },
    {
      key: 'layerOrder',
      value: layer.layerOrder,
    },
    {
      key: 'reactions',
      value: getLayerReactions(layer),
    },
  ];

  const otherProps = [];

  if (layer.layoutMode) {
    otherProps.push({
      key: 'layoutSizingHorizontal',
      value: layer.layoutSizingHorizontal || null,
    });
    otherProps.push({
      key: 'layoutSizingVertical',
      value: layer.layoutSizingVertical || null,
    });
  }

  if (
    layer.parent &&
    (layer.parent.type === 'COMPONENT' || layer.parent.type === 'INSTANCE')
  ) {
    otherProps.push({
      key: 'componentPropertyReferences',
      value: getComponentPropertyReferences(layer) || [],
    });
  }

  return [...baseProps, ...otherProps];
};
