import React, { useState } from 'react';

import TableToolbar from './TableToolbar';

const TableToolbarContainer = (props) => {
  const [searchExpanded, setSearchExpanded] = useState(false);

  const handleSearchBtnClick = () => {
    setSearchExpanded(true);
  };

  const handleSearchBlur = () => {};

  return (
    <TableToolbar
      {...props}
      searchExpanded={searchExpanded}
      onSearchBtnClick={handleSearchBtnClick}
      onSearchBlur={handleSearchBlur}
    />
  );
};

export default React.memo(TableToolbarContainer);
