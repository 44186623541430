import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './Modal.styles.scss';

const Modal = ({
  withCloseIcon,
  open,
  centered,
  className,
  children,
  closeTimeoutMS,
  onClose,
}) => (
  <ReactModal
    portalClassName={styles.portal}
    overlayClassName={cn(styles.overlay, {
      [styles.overlayCentered]: centered,
    })}
    className={cn(styles.modal, className)}
    isOpen={open}
    closeTimeoutMS={closeTimeoutMS}
    onRequestClose={onClose}
  >
    {withCloseIcon && (
      <Icon name="crossMini" className={styles.icon} onClick={onClose} />
    )}
    {children}
  </ReactModal>
);

Modal.defaultProps = {
  open: false,
  withCloseIcon: true,
  centered: false,
  closeTimeoutMS: 300,
  variant: 'default',
};

export default React.memo(Modal);
