export const prepareCourses = ({ courses, currentStudent }) =>
  courses.map((course) => {
    const studentCourse = currentStudent.courses.find(
      (stCourse) => stCourse.id === course.id,
    );

    if (!studentCourse) {
      return {
        id: course.id,
        title: course.title,
        preview: course.preview,
        status: course.status,
        access: false,
      };
    }

    return {
      id: course.id,
      title: course.title,
      preview: course.preview,
      status: course.status,
      access: studentCourse.access,
    };
  });

export const convertDataToForm = (data) => ({
  courses: data.filter((course) => course.access).map((course) => course.id),
});
