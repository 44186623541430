import React from 'react';
import { Slate, Editable } from 'slate-react';
import cn from 'classnames';

import { ToolbarIcon } from './components/ToolbarIcon';

import { TEXT_ALIGN_TYPES } from './EditorInput.constants';

import {
  isLinkActive,
  isMarkActive,
  isBlockActive,
} from './EditorInput.helpers';

import styles from './EditorInput.styles.scss';

const EditorInput = ({
  className,
  editor,
  value,
  renderElement,
  renderLeaf,
  onChange,
  onKeyDown,
  onMarkIconClick,
  onBlockIconClick,
  onLinkIconClick,
}) => (
  <div
    className={cn(
      styles.editor,
      //   { [styles.editorWithError]: haveError },
      //   { [styles.editorDisabled]: disabled },
      className,
    )}
  >
    <Slate editor={editor} value={value} onChange={onChange}>
      <div className={styles.toolbar}>
        <div className={styles.marks}>
          <ToolbarIcon
            active={isMarkActive(editor, 'bold')}
            iconName="bold"
            onClick={() => onMarkIconClick('bold')}
          />
          <ToolbarIcon
            active={isMarkActive(editor, 'italic')}
            iconName="italic"
            onClick={() => onMarkIconClick('italic')}
          />
          <ToolbarIcon
            active={isMarkActive(editor, 'underline')}
            iconName="underline"
            onClick={() => onMarkIconClick('underline')}
          />
        </div>
        <div className={styles.blocks}>
          <ToolbarIcon
            active={isBlockActive(
              editor,
              'left',
              TEXT_ALIGN_TYPES.includes('left') ? 'align' : 'type',
            )}
            iconName="alignLeft"
            onClick={() => onBlockIconClick('left')}
          />
          <ToolbarIcon
            active={isBlockActive(
              editor,
              'center',
              TEXT_ALIGN_TYPES.includes('center') ? 'align' : 'type',
            )}
            iconName="alignCenter"
            onClick={() => onBlockIconClick('center')}
          />
          <ToolbarIcon
            active={isBlockActive(
              editor,
              'right',
              TEXT_ALIGN_TYPES.includes('right') ? 'align' : 'type',
            )}
            iconName="alignRight"
            onClick={() => onBlockIconClick('right')}
          />
        </div>
        <ToolbarIcon
          active={isLinkActive(editor)}
          iconName="link"
          onClick={() => {
            onLinkIconClick();
          }}
        />
      </div>
      <div className={styles.input}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          // onFocus={onFocus}
          // onBlur={onBlur}
        />
      </div>
    </Slate>
  </div>
);

export default React.memo(EditorInput);
