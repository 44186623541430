import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  school: '',
};

export const getValidationSchema = (isRootAdmin) => {
  if (isRootAdmin) {
    return Yup.object().shape({
      school: Yup.object().required(
        getTranslation('components.add_admin.validation.school'),
      ),
      email: Yup.string().required(
        getTranslation('components.add_admin.validation.email'),
      ),
      firstName: Yup.string().required(
        getTranslation('components.add_admin.validation.first_name'),
      ),
      lastName: Yup.string().required(
        getTranslation('components.add_admin.validation.last_name'),
      ),
    });
  }

  return Yup.object().shape({
    email: Yup.string().required(
      getTranslation('components.add_admin.validation.email'),
    ),
    firstName: Yup.string().required(
      getTranslation('components.add_admin.validation.first_name'),
    ),
    lastName: Yup.string().required(
      getTranslation('components.add_admin.validation.last_name'),
    ),
  });
};
