const DEFAULT_LEFT_OFFSET = 4;
const OPTION_WIDTH = 120;

export const calculateLeftOffset = ({ options, value }) => {
  const currentOptionIndex = options.findIndex(
    (option) => option.value === value,
  );

  if (currentOptionIndex === 0) {
    return DEFAULT_LEFT_OFFSET;
  }

  return OPTION_WIDTH * currentOptionIndex + DEFAULT_LEFT_OFFSET;
};
