import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import { AddLessonButton } from 'components/AddLessonButton';
import { ModulePreview } from 'components/ModulePreview';

import { LessonItem } from '../LessonItem';

import styles from './Modules.styles.scss';

const Modules = ({
  modules,
  activeModule,
  currentLesson,
  courseType,
  onModuleClick,
  onLessonClick,
}) =>
  modules.map((module, moduleIndex) => (
    <Collapsible
      key={module.id}
      className={styles.module}
      openedClassName={cn(styles.module, styles.moduleOpened)}
      transitionTime={150}
      triggerTagName="div"
      accordionPosition={moduleIndex}
      open={activeModule === module.id}
      trigger={
        <div className={styles.moduleHeader}>
          <ModulePreview
            order={module.order}
            className={styles.modulePreview}
            preview={module.preview}
          />
          <Typography
            variant="body2"
            weight="medium"
            mode="compact"
            className={styles.moduleTitle}
          >
            {module.title || getTranslation('common.text.untitled')}
          </Typography>
          <Icon name="chevronDown" className={styles.moduleIcon} />
        </div>
      }
      handleTriggerClick={() => onModuleClick(module.id)}
    >
      <div className={styles.moduleInner}>
        {module.lessons.map((lesson) => (
          <LessonItem
            key={lesson.id}
            currentLesson={currentLesson}
            lesson={lesson}
            onClick={onLessonClick}
          />
        ))}

        <AddLessonButton
          buttonClassName={styles.addBtn}
          lessons={module.lessons}
          moduleId={module.id}
          courseType={courseType}
        />
      </div>
    </Collapsible>
  ));

export default React.memo(Modules);
