import React, { useState } from 'react';

import PasswordInput from './PasswordInput';

const PasswordInputContainer = (props) => {
  const [valueVisible, setValueVisible] = useState(false);

  const handleEyeIconClick = () => {
    setValueVisible(!valueVisible);
  };

  return (
    <PasswordInput
      {...props}
      valueVisible={valueVisible}
      onEyeIconClick={handleEyeIconClick}
    />
  );
};

export default React.memo(PasswordInputContainer);
