export const getVariantIcon = (variant) => {
  switch (variant) {
    case 'info': {
      return 'info';
    }
    case 'warning': {
      return 'warning';
    }
    default: {
      return null;
    }
  }
};
