import React from 'react';
import cn from 'classnames';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import { ITEM_TYPES } from '../../ConnectionQuizPreviewPage.constants';

import styles from './ConnectPoint.styles.scss';

const ConnectPoint = ({
  linesValidated,
  correctLine,
  rootRef,
  pointUsed,
  type,
}) => (
  <div
    className={cn(styles.pointWrapper, {
      [styles.pointWrapperQuestion]: type === ITEM_TYPES.LEFT_ITEM,
      [styles.pointWrapperAnswer]: type === ITEM_TYPES.RIGHT_ITEM,
    })}
    ref={rootRef}
  >
    <div
      className={cn(styles.point, {
        [styles.pointCurrent]: pointUsed && !linesValidated,
        [styles.pointSuccess]: linesValidated && correctLine,
        [styles.pointError]: linesValidated && !correctLine,
      })}
    >
      {!linesValidated && <div className={styles.dot} />}
      {linesValidated && (correctLine ? <CompletedIcon /> : <WrongIcon />)}
    </div>
  </div>
);

export default React.memo(ConnectPoint);
