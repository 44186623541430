import { getTranslation } from 'helpers/getTranslation';

export const getCoursesTableColumns = () => {
  return [
    {
      key: 'title',
      label: getTranslation('student_page.courses_section.table_keys.title'),
      minWidth: 300,
    },
    {
      key: 'type',
      label: getTranslation('student_page.courses_section.table_keys.type'),
      sortable: true,
    },
    {
      key: 'courseStatus',
      label: getTranslation(
        'student_page.courses_section.table_keys.course_status',
      ),
      minWidth: 120,
    },
    {
      key: 'courseProgress',
      label: getTranslation(
        'student_page.courses_section.table_keys.course_progress',
      ),
      minWidth: 120,
    },
    {
      key: 'startedAt',
      label: getTranslation(
        'student_page.courses_section.table_keys.started_at',
      ),
      minWidth: 120,
    },
    {
      key: 'completedAt',
      label: getTranslation(
        'student_page.courses_section.table_keys.completed_at',
      ),
      minWidth: 120,
    },
    {
      key: 'updatedAt',
      label: getTranslation(
        'student_page.courses_section.table_keys.updated_at',
      ),
      minWidth: 120,
    },
  ];
};
