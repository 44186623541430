import React from 'react';

import { Typography } from 'components/shared/Typography';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { StudentInfo } from '../StudentInfo';

import styles from './StudentsUnsubscribeReasonsItem.styles.scss';

const StudentsUnsubscribeReasonsItem = ({ unsubscribeReason, locale }) => {
  return (
    <>
      <div className={styles.header}>
        <StudentInfo data={unsubscribeReason} />
        <div className={styles.emailFromContainer}>
          <Typography variant="body3" className={styles.emailFrom}>
            {getTranslation(
              'inbox_page.students_unsubscribe_reason.from_email',
            )}
          </Typography>
          <Typography variant="body3">
            {unsubscribeReason.emailTemplate}
          </Typography>
        </div>
        <Typography variant="body3" className={styles.date}>
          {unsubscribeReason.createdAt &&
            formatDate(
              unsubscribeReason.createdAt,
              'dd MMM, yyyy HH:MM',
              locale,
            )}
        </Typography>
      </div>
      <div className={styles.content}>
        {unsubscribeReason.tags.length > 0 && (
          <div className={styles.tags}>
            {unsubscribeReason.tags.map((tag) => (
              <div className={styles.tag} key={tag}>
                <Typography
                  className={styles.tagText}
                  variant="body3"
                  weight="medium"
                  mode="compact"
                >
                  {tag}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(StudentsUnsubscribeReasonsItem);
