import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { STATUSES } from 'constants/common';

import * as api from 'api/methods';

import { createStudent } from 'store/students/actions';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { useFetch } from 'hooks/useFetch';

import { getSchoolsOptions, convertDataToApi } from './AddStudent.helpers';

import AddStudent from './AddStudent';

const AddStudentContainer = ({
  open,
  isRootAdmin,
  isSchool,
  schoolId,
  onClose,
  ...restProps
}) => {
  const dispatch = useDispatch();

  const [inviteLoading, setInviteLoading] = useState(false);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [currentSchoolId, setCurrentSchoolId] = useState(
    isSchool ? schoolId : null,
  );
  const [currentCourses, setCurrentCourses] = useState([]);

  const { loading: schoolsLoading, data: schools } = useFetch(
    {
      defaultData: [],
      fetcher: api.getSchools,
      immediately: false,
      stopRequest: !open || !isRootAdmin,
    },
    null,
    [open],
  );

  useEffect(() => {
    const _getSchoolCourses = async () => {
      setCoursesLoading(true);

      const courses = await api.getSchoolCourses({
        schoolId,
        params: {},
      });

      setCurrentCourses(courses);
      setCoursesLoading(false);
    };

    if (open && isSchool) {
      _getSchoolCourses();
    }
  }, [open, isSchool]);

  useEffect(() => {
    if (!open) {
      setCurrentSchoolId(null);
    }

    if (open && !currentSchoolId && schoolId && isSchool) {
      setCurrentSchoolId(schoolId);
    }
  }, [open]);

  const handleSchoolChanged = async (nextSchool, { setFieldValue }) => {
    setCurrentSchoolId(nextSchool.value);

    try {
      setCoursesLoading(true);

      const courses = await api.getSchoolCourses({
        schoolId: nextSchool.value,
        params: {},
      });

      setCurrentCourses(courses);

      const currentSchool = schools.find(
        (school) => school.id === nextSchool.value,
      );
      const isDefaultSchool = currentSchool
        ? currentSchool.name === 'Befront'
        : false;

      if (isDefaultSchool) {
        setFieldValue(
          'courses',
          courses
            .filter((course) => course.status === STATUSES.PUBLISHED)
            .map((course) => course.id),
        );
      }

      setCoursesLoading(false);
    } catch (error) {
      setCoursesLoading(false);
    }
  };

  const handleInviteStudentClick = async (data, { resetForm }) => {
    try {
      setInviteLoading(true);

      await dispatch(
        createStudent(
          convertDataToApi({
            data,
            isRootAdmin,
            schoolId,
          }),
        ),
      ).unwrap();

      onClose();
      resetForm();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.student_invited.title'),
          text: getTranslation('common.toast.student_invited.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.text'),
        },
      });
    }

    setInviteLoading(false);
  };

  const schoolOptions = getSchoolsOptions(schools);

  return (
    <AddStudent
      {...restProps}
      open={open}
      inviteLoading={inviteLoading}
      coursesLoading={coursesLoading}
      schoolsLoading={schoolsLoading}
      isRootAdmin={isRootAdmin}
      isSchool={isSchool}
      currentCourses={currentCourses}
      schools={schools}
      schoolOptions={schoolOptions}
      currentSchoolId={currentSchoolId}
      onClose={onClose}
      onSchoolChanged={handleSchoolChanged}
      onInviteStudentClick={handleInviteStudentClick}
    />
  );
};

export default React.memo(AddStudentContainer);
