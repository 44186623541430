export const getParentComponent = ({ layer, componentsLayers }) => {
  const parentComponent = componentsLayers.find(
    (componentLayer) => componentLayer.id === layer.componentId,
  );

  if (!parentComponent) {
    return null;
  }

  return parentComponent.name;
};
