import _isEqual from 'lodash/isEqual';

export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  type: data.type,
  title: data.title || '',
  status: data.status,
  questions: data.questions,
  free: data.free,
  readOnly,
  statusError: false,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  questions: data.questions.map((question) => ({
    ...question,
    answers: question.answers.map((answer) => ({
      ...answer,
      correct: answer.correct || false,
    })),
  })),
  free: data.free,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (!_isEqual(currentLesson.questions, nextLesson.questions)) {
    properties.push('questions');
  }

  return properties;
};

export const checkCorrectAnswers = (values) =>
  !values.questions.some((question) =>
    question.answers.every((answer) => !answer.correct),
  );

export const checkAnswersValue = (values) =>
  values.questions.every((question) =>
    question.answers.every((answer) => answer.data !== ''),
  );
