import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';
import { coursesSelector } from 'store/courses/selectors';
import { getSchoolCourses } from 'store/courses/actions';

import Content from './Content';

const ContentContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id: paramSchoolId } = useParams();

  const courses = useSelector(coursesSelector);
  const user = useSelector(userSelector);

  const schoolId = paramSchoolId || user.schoolId;

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'lastActionAt',
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getCoursesBySchoolId = async () => {
      try {
        await dispatch(
          getSchoolCourses({
            schoolId,
            params: {
              page: 1,
              limit: 10,
              sortBy: sortParams.sortBy,
              sortDirection: sortParams.sortDirection,
            },
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getCoursesBySchoolId();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getSchoolCourses({
          schoolId,
          params: {
            page: 1,
            limit: 50,
            sortBy: nextSortBy,
            sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
          },
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleCourseRowClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <Content
      loading={loading}
      courses={courses.data}
      schoolId={schoolId}
      sortParams={sortParams}
      onSortChange={handleSortChange}
      onCourseRowClick={handleCourseRowClick}
    />
  );
};

export default React.memo(ContentContainer);
