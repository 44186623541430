import { COURSE_TYPES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const getTitleByType = (type) => {
  switch (type) {
    case COURSE_TYPES.COURSE: {
      return getTranslation(
        'course_page.course_result_preview.course_header_text',
      );
    }
    case COURSE_TYPES.TRAINING: {
      return getTranslation(
        'course_page.course_result_preview.training_header_text',
      );
    }
    case COURSE_TYPES.ARCADE: {
      return getTranslation(
        'course_page.course_result_preview.arcade_header_text',
      );
    }
  }
};
