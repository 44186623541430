import { initialValues, getLanguageOptions } from './EditSchool.formConfig';

export const convertDataToApi = (data) => ({
  preview: data.preview,
  name: data.name,
  language: data.language ? data.language.value : null,
});

export const convertDataToForm = (data) => {
  if (!data) {
    return initialValues;
  }

  return {
    id: data.id,
    name: data.name,
    preview: data.preview,
    language: getLanguageOptions().find(
      (option) => option.value === data.language,
    ),
  };
};
