import React from 'react';

import { Typography } from 'components/shared/Typography';

import styles from './ColorInfo.styles.scss';

const ColorInfo = ({ value, text }) => (
  <div className={styles.info}>
    <div className={styles.color} style={{ backgroundColor: value }} />
    <div className={styles.inner}>
      <Typography className={styles.text} variant="body3" mode="compact">
        {text}
      </Typography>
      <Typography
        className={styles.value}
        variant="body2"
        mode="compact"
        weight="medium"
      >
        {value.toUpperCase()}
      </Typography>
    </div>
  </div>
);

export default React.memo(ColorInfo);
