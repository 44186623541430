import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './TransitionDirectionProperty.styles.scss';

const TransitionDirectionProperty = ({ property }) => (
  <div className={styles.property}>
    <div
      className={cn(styles.item, {
        [styles.itemActive]: property.value === 'LEFT',
      })}
    >
      <Icon className={styles.itemIcon} name="transitionLeft" />
    </div>
    <div
      className={cn(styles.item, {
        [styles.itemActive]: property.value === 'RIGHT',
      })}
    >
      <Icon className={styles.itemIcon} name="layoutHorizontal" />
    </div>
    <div
      className={cn(styles.item, {
        [styles.itemActive]: property.value === 'BOTTOM',
      })}
    >
      <Icon className={styles.itemIcon} name="layoutVertical" />
    </div>
    <div
      className={cn(styles.item, {
        [styles.itemActive]: property.value === 'TOP',
      })}
    >
      <Icon className={styles.itemIcon} name="transitionTop" />
    </div>
  </div>
);

export default React.memo(TransitionDirectionProperty);
