import React from 'react';
import cn from 'classnames';

import { DEFAULT_PROGRESS } from './LinearProgressBar.constants';

import styles from './LinearProgressBar.styles.scss';

const VARIANTS = {
  default: styles.default,
  upload: styles.upload,
};

const LinearProgressBar = ({
  className,
  trackClassName,
  thumbClassName,
  variant,
  progress,
}) => (
  <div className={cn(styles.bar, VARIANTS[variant], className)}>
    <div
      className={cn(
        styles.thumb,
        { [styles.progressThumb]: progress > DEFAULT_PROGRESS },
        thumbClassName,
      )}
    >
      <div
        className={cn(styles.track, trackClassName)}
        style={{
          width: `${
            progress > DEFAULT_PROGRESS ? progress : DEFAULT_PROGRESS
          }%`,
        }}
      />
    </div>
  </div>
);

LinearProgressBar.defaultProps = {
  variant: 'default',
};

export default React.memo(LinearProgressBar);
