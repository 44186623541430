import React, { useState } from 'react';
import {
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
  useDismiss,
  flip,
  shift,
} from '@floating-ui/react';

import Tooltip from './Tooltip';

const TooltipContainer = ({ placement, ...restProps }) => {
  const [open, setOpen] = useState(false);

  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: placement || 'top',
    middleware: [flip(), shift()],
  });
  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    open: { opacity: 1, transform: 'translateY(-4px)' },
  });

  const hover = useHover(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  const styleProps = {
    position: strategy,
    top: y || 0,
    left: x || 0,
  };

  return (
    <Tooltip
      {...restProps}
      isMounted={isMounted}
      refs={refs}
      animationStyles={styles}
      styleProps={styleProps}
      getReferenceProps={getReferenceProps}
      getFloatingProps={getFloatingProps}
    />
  );
};

export default React.memo(TooltipContainer);
