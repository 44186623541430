export const getLayerPropertyByKey = ({ key, properties }) => {
  if (!key) {
    return null;
  }

  const property = properties.find((prt) => prt.key === key);

  if (!property) {
    return null;
  }

  return property;
};
