import React from 'react';

import { Typography } from 'components/shared/Typography';

import { formatDate } from 'helpers/formatDate';

import { StudentInfo } from '../StudentInfo';

import styles from './SurveyItem.styles.scss';
import { getQuestionTextByType } from './SurveyItem.helpers';

const SurveyItem = ({ survey, locale }) => {
  return (
    <>
      <div className={styles.header}>
        <StudentInfo data={survey} />
        <div className={styles.emailFromContainer}>
          <Typography variant="body3" className={styles.emailFrom}>
            Type
          </Typography>
          <Typography variant="body3">{survey.type}</Typography>
        </div>
        <Typography variant="body3" className={styles.date}>
          {survey.createdAt &&
            formatDate(survey.createdAt, 'dd MMM, yyyy HH:MM', locale)}
        </Typography>
      </div>
      <div className={styles.content}>
        {survey.answers
          .filter((answer) => {
            if (answer.key === 'aspects' && answer.value === 'Other') {
              return false;
            }

            if (answer.key === 'characterize' && answer.value === 'Other') {
              return false;
            }

            if (answer.key === 'howFind' && answer.value === 'Other') {
              return false;
            }

            if (
              answer.key === 'aspectsOther' &&
              survey.answers.find((ans) => ans.key === 'aspects').value !==
                'Other'
            ) {
              return false;
            }

            if (
              answer.key === 'characterizeOther' &&
              survey.answers.find((ans) => ans.key === 'characterize').value !==
                'Other'
            ) {
              return false;
            }

            if (
              answer.key === 'howFindOther' &&
              survey.answers.find((ans) => ans.key === 'howFind').value !==
                'Other'
            ) {
              return false;
            }

            return true;
          })
          .map((answer) => (
            <div className={styles.answer}>
              <Typography
                className={styles.answerTitle}
                variant="body3"
                mode="compact"
              >
                {getQuestionTextByType(answer.key)}
              </Typography>
              <Typography
                className={styles.answerText}
                variant="body3"
                weight="medium"
                mode="compact"
              >
                {answer.value || '-'}
              </Typography>
            </div>
          ))}
      </div>
    </>
  );
};

export default React.memo(SurveyItem);
