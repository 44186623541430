import React from 'react';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';
import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import styles from './StrokeWeightProperty.styles.scss';

const _renderStrokeContent = ({ value }) => (
  <>
    <Icon className={styles.icon} name="strokeWeight" />
    <Typography
      className={styles.value}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {value}
    </Typography>
  </>
);

const _renderDifferenceStrokeContent = ({ value }) => (
  <>
    <div className={styles.items}>
      <div className={styles.item}>
        <Icon className={styles.icon} name="strokeTop" />
        <Typography
          className={styles.value}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {value.top}
        </Typography>
      </div>
      <div className={styles.item}>
        <Icon className={styles.icon} name="strokeRight" />
        <Typography
          className={styles.value}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {value.right}
        </Typography>
      </div>
      <div className={styles.item}>
        <Icon className={styles.icon} name="strokeBottom" />
        <Typography
          className={styles.value}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {value.bottom}
        </Typography>
      </div>
      <div className={styles.item}>
        <Icon className={styles.icon} name="strokeLeft" />
        <Typography
          className={styles.value}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {value.left}
        </Typography>
      </div>
    </div>
  </>
);

const StrokeWeightProperty = ({
  isInitialState,
  readOnly,
  designType,
  namePrefix,
  property,
  onChanged,
}) => {
  if (
    typeof property.value === 'string' ||
    typeof property.value === 'number'
  ) {
    if (isInitialState || designType === 'PROTOTYPE') {
      return (
        <Tooltip
          text="Strokes per side"
          triggerClassName={styles.fieldTrigger}
          triggerElement={
            <div className={styles.propertyReadOnly}>
              {_renderStrokeContent(property)}
            </div>
          }
        />
      );
    }

    return (
      <Tooltip
        text="Strokes per side"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={namePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property,
              renderProperty: _renderStrokeContent,
              onChanged,
            }}
          />
        }
      />
    );
  }

  if (typeof property.value === 'object') {
    if (isInitialState) {
      return (
        <Tooltip
          text="Strokes per side"
          triggerClassName={styles.fieldTrigger}
          triggerElement={
            <div className={styles.propertyReadOnly}>
              {_renderDifferenceStrokeContent(property)}
            </div>
          }
        />
      );
    }

    return (
      <Tooltip
        text="Strokes per side"
        triggerClassName={styles.fieldTrigger}
        triggerElement={
          <FormikField
            name={namePrefix}
            withControl={false}
            component={PropertySelectField}
            componentProps={{
              type: 'checkbox',
              readOnly,
              property,
              renderProperty: _renderDifferenceStrokeContent,
              onChanged,
            }}
          />
        }
      />
    );
  }

  return null;
};

export default React.memo(StrokeWeightProperty);
