import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth';
import coursesReducer from './courses';
import courseReducer from './course';
import lessonReducer from './lesson';
import studentsReducer from './students';
import studentReducer from './student';
import adminsReducer from './admins';
import adminReducer from './admin';
import schoolReducer from './school';
import schoolsReducer from './schools';
import codesReducer from './codes';
import arcadesReducer from './arcades';
import arcadeReducer from './arcade';
import trainingsReducer from './trainings';
import trainingReducer from './training';
import lessonRatingsSlice from './lesson-ratings';
import lessonReportsSlice from './lesson-reports';
import reviewsSlice from './reviews';
import unsubscribeReasonsSlice from './unsubscribe-reasons';
import surveysSlice from './surveys';

export default configureStore({
  reducer: {
    auth: authReducer,
    courses: coursesReducer,
    currentCourse: courseReducer,
    currentLesson: lessonReducer,
    students: studentsReducer,
    currentStudent: studentReducer,
    admins: adminsReducer,
    currentAdmin: adminReducer,
    currentSchool: schoolReducer,
    schools: schoolsReducer,
    schoolCodes: codesReducer,
    arcades: arcadesReducer,
    currentArcade: arcadeReducer,
    trainings: trainingsReducer,
    currentTraining: trainingReducer,
    lessonRatings: lessonRatingsSlice,
    lessonReports: lessonReportsSlice,
    reviews: reviewsSlice,
    unsubscribeReasons: unsubscribeReasonsSlice,
    surveys: surveysSlice,
  },
});
