import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const getLanguageOptions = () => [
  {
    label: getTranslation('school_page.edit_school.form_config.english_label'),
    value: 'en',
  },
  {
    label: getTranslation('school_page.edit_school.form_config.russian_label'),
    value: 'ru',
  },
];

export const initialValues = {
  name: '',
  language: '',
  preview: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(
      getTranslation('school_page.edit_school.form_config.name_error'),
    ),
    preview: Yup.string().ensure(),
    language: Yup.object().required(
      getTranslation('school_page.edit_school.form_config.language_error'),
    ),
  });
