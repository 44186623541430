import { getTranslation } from 'helpers/getTranslation';

export const getArcadesTableColumnsRootAdmin = () => [
  {
    key: 'title',
    label: getTranslation('arcades_page.tableConfig.label_name'),
    minWidth: 400,
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('arcades_page.tableConfig.label_status'),
    sortable: true,
  },
  {
    key: 'authors',
    label: getTranslation('arcades_page.tableConfig.label_authors'),
    sortable: false,
  },
  {
    key: 'school',
    label: getTranslation('arcades_page.tableConfig.label_school'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('arcades_page.tableConfig.label_createdAt'),
    sortable: true,
  },
  {
    key: 'lastActionAt',
    label: getTranslation('arcades_page.tableConfig.label_lastActionAt'),
    sortable: true,
  },
];
export const getArcadesTableColumns = () => [
  {
    key: 'title',
    label: getTranslation('arcades_page.tableConfig.label_name'),
    minWidth: 400,
    sortable: true,
  },
  {
    key: 'status',
    label: getTranslation('arcades_page.tableConfig.label_status'),
    sortable: true,
  },
  {
    key: 'authors',
    label: getTranslation('arcades_page.tableConfig.label_authors'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('arcades_page.tableConfig.label_createdAt'),
    sortable: true,
  },
  {
    key: 'lastActionAt',
    label: getTranslation('arcades_page.tableConfig.label_lastActionAt'),
    sortable: true,
  },
];
