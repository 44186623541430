import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { getAccessPeriod } from './GrantProAccess.constants';

import { Drawer } from 'components/shared/Drawer';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { SelectField } from 'components/FormikFields';
import { FormikField } from 'components/FormikField';
import { CoursesAccessList } from 'components/CoursesAccessList';

import {
  getValidationSchema,
  initialValues,
} from './GrantProAccess.formConfig';

import styles from './GrantProAccess.styles.scss';

const GrantProAccess = ({ open, courses, onClose, onGiveAccess }) => (
  <Drawer
    open={open}
    headerContent={getTranslation('student_page.grant_pro_access.title')}
    onClose={onClose}
  >
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onGiveAccess}
    >
      {({ errors, touched }) => (
        <Form className={styles.form}>
          <div className={styles.main} />
          <FormikField
            name="period"
            component={SelectField}
            componentProps={{
              options: getAccessPeriod(),
            }}
            controlProps={{
              required: true,
              label: getTranslation(
                'student_page.grant_pro_access.period_label',
              ),
            }}
          />
          <CoursesAccessList courses={courses} className={styles.courseItem} />
          {touched.courses && errors.courses && (
            <Typography variant="caption" className={styles.errorText}>
              {errors.courses}
            </Typography>
          )}
          <div className={styles.actions}>
            <Button
              className={styles.action}
              size="md"
              variant="outlined"
              onClick={onClose}
            >
              {getTranslation('common.button.cancel')}
            </Button>
            <Button className={styles.action} type="submit" size="md">
              {getTranslation('common.button.give_access')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </Drawer>
);

export default React.memo(GrantProAccess);
