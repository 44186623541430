import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { TypesSelect } from 'components/TypesSelect';

import styles from './AnswersTypeSelect.styles.scss';

const AnswersTypeSelect = ({ onOptionClick }) => (
  <div className={styles.select}>
    <Typography className={styles.title} variant="subtitle">
      {getTranslation('connection_quiz_page.answers_type.title')}
    </Typography>
    <TypesSelect
      options={[
        {
          icon: 'textChar',
          text: getTranslation('connection_quiz_page.answers_type.text_text'),
          value: 'TEXT_TEXT',
        },
        {
          icon: 'imageText',
          text: getTranslation('connection_quiz_page.answers_type.image_text'),
          value: 'IMAGE_TEXT',
        },
        {
          icon: 'twoImages',
          text: getTranslation('connection_quiz_page.answers_type.image_image'),
          value: 'IMAGE_IMAGE',
        },
      ]}
      onOptionClick={onOptionClick}
    />
  </div>
);

export default React.memo(AnswersTypeSelect);
