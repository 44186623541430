import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { v4 as uuid } from 'uuid';

import * as api from 'api/methods';

import { getTranslation } from 'helpers/getTranslation';

import { useChangeOrder } from 'hooks/useChangeOrder';
import { useModal } from 'hooks/useModal';

import { DefaultModal } from 'components/DefaultModal';

import Answers from './Answers';

const AnswersContainer = ({ namePrefix, answersType, ...restProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const answers = getIn(values, `${namePrefix}.answers`);

  const [removeModalOpen, openRemoveModal, closeRemoveModal, removeModalData] =
    useModal({
      defaultOpen: false,
    });

  const _handleModuleDragEnd = () => {};

  const {
    sensors,
    activeDragItem,
    onItemDragStart,
    onItemDragEnd,
    onItemDragOver,
  } = useChangeOrder({
    withOrder: false,
    items: answers,
    namePrefix: `${namePrefix}.answers`,
    onItemDragEnd: _handleModuleDragEnd,
  });

  const handleAddAnswerClick = () => {
    setFieldValue(`${namePrefix}.answers`, [
      ...answers,
      { id: uuid(), data: '' },
    ]);
  };

  const handleRemoveAnswerClick = (answerId) => {
    openRemoveModal({
      data: answerId,
      title: getTranslation('quiz_page.answers.remove_modal.title'),
      text: getTranslation('quiz_page.answers.remove_modal.text'),
    });
  };

  const handleConfirmRemoveClick = async (answerId) => {
    const removedAnswer = answers.find((answer) => answer.id === answerId);

    setFieldValue(
      `${namePrefix}.answers`,
      answers.filter((answer) => answer.id !== answerId),
    );

    if (answersType === 'IMAGE' && removedAnswer.data !== '') {
      await api.removeFile({ path: removedAnswer.data });
    }

    closeRemoveModal();
  };

  const activeDragAnswerIndex = activeDragItem
    ? answers.findIndex((answer) => answer.id === activeDragItem.id)
    : -1;

  return (
    <>
      <Answers
        {...restProps}
        readOnly={values.readOnly}
        answers={answers}
        answersType={answersType}
        namePrefix={namePrefix}
        sensors={sensors}
        activeDragAnswer={activeDragItem}
        activeDragAnswerIndex={activeDragAnswerIndex}
        onAnswerDragStart={onItemDragStart}
        onAnswerDragEnd={onItemDragEnd}
        onAnswerDragOver={onItemDragOver}
        onAddAnswerClick={handleAddAnswerClick}
        onRemoveAnswerClick={handleRemoveAnswerClick}
      />
      <DefaultModal
        {...removeModalData}
        open={removeModalOpen}
        confirmText={getTranslation(
          'quiz_page.answers.remove_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveModal}
        onClose={closeRemoveModal}
      />
    </>
  );
};

export default React.memo(AnswersContainer);
