import React, { useState, useEffect } from 'react';

import * as api from 'api/methods';

import StudentInfo from './StudentInfo';

const StudentInfoContainer = ({ studentId, courseId, ...restProps }) => {
  const [studentStats, setStudentStats] = useState(null);

  useEffect(() => {
    const _getCoursesStats = async () => {
      try {
        const nextStudentStats = await api.getCourseStatsByStudent({
          courseId,
          studentId,
        });

        setStudentStats(nextStudentStats);
      } catch (error) {
        console.warn(error);
      }
    };

    if (studentId && courseId) {
      _getCoursesStats();
    }

    return () => {
      setStudentStats(null);
    };
  }, [studentId, courseId]);

  const structure = studentStats?.courseStructure;

  return (
    <StudentInfo
      {...restProps}
      structure={structure}
      studentStats={studentStats}
    />
  );
};

export default React.memo(StudentInfoContainer);
