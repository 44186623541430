import React from 'react';
import { useDropzone } from 'react-dropzone';

import { useModal } from 'hooks/useModal';

import { RemoveFileModal } from 'components/RemoveFileModal';

import { getValidator } from './Upload.helpers';

import Upload from './Upload';

const UploadContainer = ({
  accept,
  removeFileTitle,
  removeFileText,
  removeLoading,
  onFileDialogOpen,
  onDropAccepted,
  onDropRejected,
  onRemoveClick,
  ...restProps
}) => {
  const [modalOpen, openModal, closeModal, modalData] = useModal({
    defaultOpen: false,
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    maxFiles: 1,
    accept,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    validator: getValidator,
    onFileDialogOpen,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const handleRemoveClick = (file) => {
    openModal({ fileId: file.id, fileName: file.name });
  };

  const handleConfirmRemoveClick = async () => {
    try {
      await onRemoveClick(modalData.fileId);

      closeModal();
    } catch (error) {
      // TODO: add notification
    }
  };

  return (
    <>
      <Upload
        {...restProps}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        onBrowseClick={open}
        onRemoveClick={handleRemoveClick}
      />
      <RemoveFileModal
        {...modalData}
        open={modalOpen}
        loading={removeLoading}
        removeTitle={removeFileTitle}
        removeText={removeFileText}
        onClose={closeModal}
        onCancelClick={closeModal}
        onConfirmClick={handleConfirmRemoveClick}
      />
    </>
  );
};

UploadContainer.defaultProps = {
  accept: {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'video/avi': ['.avi'],
    'video/mov': ['.mov'],
    'video/mp4': ['.mp4'],
    'video/webm': ['.webm'],
  },
};

export default React.memo(UploadContainer);
