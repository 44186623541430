import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { EmptyState } from 'components/EmptyState';
import { TablePagination } from 'components/TablePagination';

import { Actions } from '../Actions';
import { LessonRatingsItem } from '../LessonRatingsItem';

import styles from './LessonRatings.styles.scss';

const LessonRatings = ({
  locale,
  loading,
  currentPage,
  totalPages,
  perPage,
  sortParams,
  lessonsRatings,
  onPerPageChange,
  onPrevPageClick,
  onNextPageClick,
  onSortChange,
}) => (
  <>
    {loading ? (
      <Loading />
    ) : lessonsRatings.length > 0 ? (
      <>
        <Actions sortParams={sortParams} onSortChange={onSortChange} />
        {lessonsRatings.map((lessonRating) => (
          <div className={styles.item} key={lessonRating.id}>
            <LessonRatingsItem lessonRating={lessonRating} locale={locale} />
          </div>
        ))}
        <TablePagination
          currentPage={currentPage}
          perPage={perPage}
          totalPages={totalPages}
          onPerPageChange={onPerPageChange}
          onNextPageClick={onNextPageClick}
          onPrevPageClick={onPrevPageClick}
        />
      </>
    ) : (
      <EmptyState
        className={styles.emptyState}
        icon="mail"
        title={getTranslation('inbox_page.lesson_ratings.empty_state.title')}
        text=""
      />
    )}
  </>
);
export default React.memo(LessonRatings);
