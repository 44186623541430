import React from 'react';
import { useSelector } from 'react-redux';

import { COURSE_TYPES } from 'constants/common';

import * as api from 'api/methods';

import { getCourses, removeCourseById } from 'store/courses/actions';
import { coursesSelector } from 'store/courses/selectors';

import { useCourses } from 'hooks/useCourses';

import { getTranslation } from 'helpers/getTranslation';

import { DefaultModal } from 'components/DefaultModal';
import { RemoveWithStepsModal } from 'components/RemoveWithStepsModal';

import { CreateCourse } from './components/CreateCourse';

import CoursesPage from './CoursesPage';

const CoursesPageContainer = () => {
  const courses = useSelector(coursesSelector);

  const {
    loading,
    isRootAdmin,
    createOpen,
    removeOpen,
    removeConfirmOpen,
    sortParams,
    columns,
    removeConfirmData,
    removeData,
    closeCreate,
    closeRemoveConfirm,
    closeRemove,
    onSearchChange,
    onSortChange,
    onContextItemClick,
    onRowClick,
    onEditClick,
    onRemoveClick,
    onConfirmRemoveClick,
    onCreateClick,
  } = useCourses({
    getItems: getCourses,
    removeItemById: removeCourseById,
    createItem: api.createCourse,
    itemsPerPage: courses.perPage,
    type: COURSE_TYPES.COURSE,
    pathPrefix: '/courses',
  });

  return (
    <>
      <CoursesPage
        loading={loading}
        isRootAdmin={isRootAdmin}
        courses={courses.data}
        sortParams={sortParams}
        coursesColumns={columns}
        onSearchChange={onSearchChange}
        onSortChange={onSortChange}
        onContextItemClick={onContextItemClick}
        onCourseRowClick={onRowClick}
        onCourseEditClick={onEditClick}
        onCourseRemoveClick={onRemoveClick}
        onCreateCourseClick={onCreateClick}
      />
      <CreateCourse open={createOpen} onClose={closeCreate} />
      <DefaultModal
        {...removeConfirmData}
        open={removeConfirmOpen}
        confirmText={getTranslation('courses_page.removing_confirm_text')}
        onConfirmClick={onConfirmRemoveClick}
        onCancelClick={closeRemoveConfirm}
        onClose={closeRemoveConfirm}
      />
      <RemoveWithStepsModal
        {...removeData}
        open={removeOpen}
        variant="COURSE"
        title={getTranslation('courses_page.remove_title')}
        onClose={closeRemove}
      />
    </>
  );
};

export default React.memo(CoursesPageContainer);
