const _formatContraintsHorizontalProperty = (horizontalConstraints) => {
  switch (horizontalConstraints) {
    case 'LEFT': {
      return 'MIN';
    }
    case 'RIGHT': {
      return 'MAX';
    }
    case 'LEFT_RIGHT': {
      return 'STRETCH';
    }
    case 'CENTER': {
      return 'CENTER';
    }
    case 'SCALE': {
      return 'SCALE';
    }
    default: {
      console.warn('horizontal constraints not expected');
    }
  }
};

const _formatContraintsVerticalProperty = (verticalConstraints) => {
  switch (verticalConstraints) {
    case 'TOP': {
      return 'MIN';
    }
    case 'BOTTOM': {
      return 'MAX';
    }
    case 'TOP_BOTTOM': {
      return 'STRETCH';
    }
    case 'CENTER': {
      return 'CENTER';
    }
    case 'SCALE': {
      return 'SCALE';
    }
    default: {
      console.warn('vertical constraints not expected');
    }
  }
};

const _formatStrokeWeightProperty = (strokeWeight) => {
  if (typeof strokeWeight === 'number') {
    return { strokeWeight };
  }

  if (typeof strokeWeight === 'object') {
    return {
      strokeWeight: Math.max(
        ...[
          strokeWeight.top,
          strokeWeight.bottom,
          strokeWeight.left,
          strokeWeight.right,
        ],
      ),
      strokeTopWeight: strokeWeight.top,
      strokeBottomWeight: strokeWeight.bottom,
      strokeLeftWeight: strokeWeight.left,
      strokeRightWeight: strokeWeight.right,
    };
  }
};

export const formatLayerBaseProperties = (properties) => ({
  locked: properties.locked || false,
  visible: properties.visible || true,
  blendMode: properties.blendMode || 'PATH_THROUGH',
  opacity: properties.opacity || 1,
  width: properties.width,
  height: properties.height,
  x: properties.x,
  y: properties.y,
  rotation: properties.rotation || 0,
  constraints: {
    horizontal: _formatContraintsHorizontalProperty(
      properties.constraints.horizontal,
    ),
    vertical: _formatContraintsVerticalProperty(
      properties.constraints.vertical,
    ),
  },
  fills: properties.fills,
  strokes: properties.strokes,
  ..._formatStrokeWeightProperty(properties.strokeWeight),
  strokeAlign: properties.strokeAlign,
  strokeCap: properties.strokeCap,
  dashPattern: properties.dashPattern || [],
  strokeJoin: properties.strokeJoin,
  effects: properties.effects,
});
