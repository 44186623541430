import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import { TheoryPreviewBlock } from 'components/TheoryPreviewBlock';

import styles from './GoodToKnowSection.styles.scss';

const GoodToKnowSection = ({ className, data, isTestMode }) => (
  <div className={className}>
    <Collapsible
      transitionTime={150}
      triggerDisabled={!isTestMode}
      triggerTagName="div"
      className={cn(styles.section, { [styles.readOnlySection]: !isTestMode })}
      openedClassName={cn(styles.section, styles.sectionOpened)}
      trigger={
        <div
          className={cn(styles.header, {
            [styles.readOnlyHeader]: !isTestMode,
          })}
        >
          <div className={styles.hint}>
            <Icon className={styles.hintIcon} name="hints" />
          </div>
          <Typography className={styles.title} variant="body1" mode="compact">
            {getTranslation('figma_lesson_preview_page.good_to_know.text')}
          </Typography>
          <Icon className={styles.icon} name="chevronDown" />
        </div>
      }
    >
      <div className={styles.inner}>
        {data?.blocks && data.blocks.length > 0 && (
          <div className={styles.inner}>
            {data.blocks.map((block) => (
              <TheoryPreviewBlock
                key={block.id}
                type={block.type}
                data={block.data}
              />
            ))}
          </div>
        )}
      </div>
    </Collapsible>
  </div>
);

export default React.memo(GoodToKnowSection);
