import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import * as api from 'api/methods';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { createAdmin } from 'store/admins/actions';

import { useFetch } from 'hooks/useFetch';

import { convertDataToApi } from './AddAdmin.helpers';

import AddAdmin from './AddAdmin';

const AddAdminContainer = ({
  open,
  isRootAdmin,
  isSchool,
  schoolId,
  onClose,
  ...restProps
}) => {
  const [inviteLoading, setInviteLoading] = useState(false);

  const dispatch = useDispatch();

  const { data: schools } = useFetch(
    {
      defaultData: [],
      fetcher: api.getSchools,
      immediately: false,
      stopRequest: !open || !isRootAdmin || isSchool,
    },
    null,
    [open],
  );

  const handleInviteAdminClick = async (data, { resetForm }) => {
    setInviteLoading(true);

    try {
      await dispatch(
        createAdmin(convertDataToApi({ data, isRootAdmin, schoolId })),
      ).unwrap();

      onClose();
      resetForm();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.admin_invited.title'),
          text: getTranslation('common.toast.admin_invited.text'),
        },
      });
    } catch (error) {
      console.warn(error);

      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: error.message || getTranslation('common.toast.error.text'),
        },
      });
    }

    setInviteLoading(false);
  };

  const schoolOptions = schools.map((school) => ({
    label: school.name,
    value: school.id,
  }));

  return (
    <AddAdmin
      {...restProps}
      open={open}
      inviteLoading={inviteLoading}
      isRootAdmin={isRootAdmin}
      isSchool={isSchool}
      schoolOptions={schoolOptions}
      onClose={onClose}
      onInviteAdminClick={handleInviteAdminClick}
    />
  );
};

export default React.memo(AddAdminContainer);
