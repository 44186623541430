import { getTranslation } from 'helpers/getTranslation';

export const STUDENTS_TABLE_COLUMNS = [
  {
    key: 'row',
    label: getTranslation('components.import_students.table_rows.row'),
    minWidth: '68px',
    maxWidth: '68px',
  },
  {
    key: 'student',
    label: getTranslation('components.import_students.table_rows.student'),
  },
];
