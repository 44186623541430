import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import styles from './Action.styles.scss';

const Action = ({
  nextLoading,
  isLastLessonInCourse,
  submitted,
  onCheckClick,
  onNextLessonClick,
}) => {
  if (!submitted) {
    return (
      <Button
        className={styles.action}
        endIcon={<Icon name="arrowRight" />}
        onClick={onCheckClick}
      >
        {getTranslation('color_match_lesson_preview_page.action.check_color')}
      </Button>
    );
  }

  if (submitted && isLastLessonInCourse) {
    return (
      <Button
        className={styles.action}
        disabled={!submitted}
        startIcon={
          nextLoading && <Loading color="var(--color-white-primary)" />
        }
        endIcon={<Icon name="arrowRight" />}
        onClick={onNextLessonClick}
      >
        {getTranslation('common.button.finish_course')}
      </Button>
    );
  }

  return (
    <Button
      className={styles.action}
      disabled={!submitted}
      startIcon={nextLoading && <Loading color="var(--color-white-primary)" />}
      endIcon={<Icon name="arrowRight" />}
      onClick={onNextLessonClick}
    >
      {getTranslation('color_match_lesson_preview_page.action.next_lesson')}
    </Button>
  );
};

export default React.memo(Action);
