import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { getSchoolCodes } from 'store/codes/actions';

import { Loading } from 'components/shared/Loading';

import { SchoolCodesTable } from 'components/SchoolCodesTable';
import { EmptyState } from 'components/EmptyState';

import { CODES_TABLE_COLUMNS } from './ActivationCodes.tableConfig';

import styles from './ActivationCodes.styles.scss';

const ActivationCodes = ({
  loading,
  codes,
  schoolId,
  sortParams,
  onSortChange,
  onCopyCodeClick,
}) =>
  loading ? (
    <Loading />
  ) : codes && codes.length > 0 ? (
    <SchoolCodesTable
      columns={CODES_TABLE_COLUMNS()}
      data={codes}
      params={sortParams}
      dataAction={({ page, limit, sortBy, sortDirection }) =>
        getSchoolCodes({
          schoolId,
          params: { page, limit, sortBy, sortDirection },
        })
      }
      onSortChange={onSortChange}
      onCopyCodeClick={onCopyCodeClick}
    />
  ) : (
    <EmptyState
      className={styles.emptyState}
      icon="hash"
      title={getTranslation('school_page.activation_codes.empty_state.title')}
      text={getTranslation('school_page.activation_codes.empty_state.text')}
    />
  );

export default React.memo(ActivationCodes);
