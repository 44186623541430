import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Collapsible } from 'components/shared/Collapsible';

import { ModulePreview } from 'components/ModulePreview';

import { Lesson } from '../Lesson';
import { LessonsCount } from '../LessonsCount';

import styles from './Module.styles.scss';

const Module = ({
  available,
  completed,
  lessons,
  preview,
  title,
  order,
  progress,
}) => (
  <Collapsible
    className={cn(styles.module, { [styles.moduleDisabled]: !available })}
    openedClassName={cn(
      styles.module,
      { [styles.moduleDisabled]: !available },
      styles.moduleOpened,
    )}
    transitionTime={150}
    triggerTagName="div"
    trigger={
      <>
        <div className={styles.header}>
          <ModulePreview
            className={styles.preview}
            preview={preview}
            order={order}
          />
          <Typography
            className={styles.title}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {title || getTranslation('common.text.untitled')}
          </Typography>
        </div>
        <div className={styles.inner}>
          <LessonsCount
            className={styles.count}
            iconClassName={styles.countIcon}
            count={lessons.length}
          />
        </div>
      </>
    }
  >
    {lessons.map((lesson) => (
      <Lesson key={lesson.id} {...lesson} />
    ))}
  </Collapsible>
);

export default React.memo(Module);
