export const getLayerStrokeStyle = (layer) => {
  if (!layer.strokeDashes) {
    return 'SOLID';
  }

  if (layer.strokeDashes.length === 2) {
    return 'DASH';
  }

  if (layer.strokeDashes.length === 4) {
    return 'CUSTOM';
  }

  return null;
};
