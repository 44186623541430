export const GET_CURRENT_TRAINING = 'training/GET_CURRENT_TRAINING';
export const UPDATE_TRAINING_BY_ID = 'training/UPDATE_TRAINING_BY_ID';
export const UPDATE_TRAINING_STATUS = 'training/UPDATE_TRAINING_STATUS';
export const CREATE_TRAINING_MODULE = 'training/CREATE_TRAINING_MODULE';
export const REMOVE_TRAINING_MODULE_BY_ID =
  'training/REMOVE_TRAINING_MODULE_BY_ID';
export const UPDATE_TRAINING_MODULE_BY_ID =
  'training/UPDATE_TRAINING_MODULE_BY_ID';
export const REORDER_TRAINING_LESSONS = 'training/REODER_TRAINING_LESSONS';
export const REORDER_TRAINING_MODULES = 'training/REORDER_TRAINING_MODULES';
