import { COURSE_STRUCTURES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

export const TABLE_COLUMNS = {
  [COURSE_STRUCTURES.MODULES]: [
    {
      key: 'title',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.module_title',
      ),
    },
    {
      key: 'completed',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.completed',
      ),
      minWidth: 120,
      maxWidth: 120,
      justifyContent: 'flex-end',
    },
    {
      key: 'inProgress',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.in_progress',
      ),
      minWidth: 120,
      maxWidth: 120,
      justifyContent: 'flex-end',
    },
    {
      key: 'notStarted',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.not_started',
      ),
      minWidth: 120,
      maxWidth: 120,
      justifyContent: 'flex-end',
    },
  ],
  [COURSE_STRUCTURES.LESSONS]: [
    {
      key: 'title',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.lesson_title',
      ),
    },
    {
      key: 'completed',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.completed',
      ),
      minWidth: 160,
      maxWidth: 160,
      justifyContent: 'flex-end',
    },
    {
      key: 'notStarted',
      label: getTranslation(
        'dashboard_page.info_by_module.table_keys.not_started',
      ),
      minWidth: 160,
      maxWidth: 160,
      justifyContent: 'flex-end',
    },
  ],
};
