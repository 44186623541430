import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { calculateLeftOffset } from './LessonTypeSelect.helpers';

import styles from './LessonTypeSelect.styles.scss';

const LessonTypeSelect = ({ options, value, onChange }) => (
  <div className={styles.select}>
    <div
      className={styles.selectedOverlay}
      style={{ left: calculateLeftOffset({ options, value }) }}
    />
    {options.map((option) => (
      <div
        className={cn(styles.option, {
          [styles.optionSelected]: option.value === value,
        })}
        onClick={() => onChange(option.value)}
      >
        <Icon className={styles.optionIcon} name={option.iconName} />
        <Typography
          className={styles.optionTitle}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {option.label}
        </Typography>
      </div>
    ))}
  </div>
);

export default React.memo(LessonTypeSelect);
