import React from 'react';
import cn from 'classnames';

import { Collapsible } from 'components/shared/Collapsible';
import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { BoundingBoxProperties } from '../BoundingBoxProperties';
import { AutoLayoutProperties } from '../AutoLayoutProperties';
import { ConstraintsProperties } from '../ConstraintsProperties';
import { LayerProperties } from '../LayerProperties';
import { LayoutGridProperties } from '../LayoutGridProperties';
import { FillProperties } from '../FillProperties';
import { StrokeProperties } from '../StrokeProperties';
import { EffectsProperties } from '../EffectsProperties';
import { TextProperties } from '../TextProperties';
import { ComponentSetProperties } from '../ComponentSetProperties';
import { ComponentInstanceProperties } from '../ComponentInstanceProperties';
import { ComponentProperties } from '../ComponentProperties';
import { ComponentRefProperties } from '../ComponentRefProperties';
import { CategoryCheckbox } from '../CategoryCheckbox';

import styles from './CategorySection.styles.scss';

const _renderProperties = ({ propertiesProps, figmaFileImages }) => {
  const { categoryVariant, layerType } = propertiesProps;

  switch (categoryVariant) {
    case 'BOUNDING_BOX': {
      return <BoundingBoxProperties {...propertiesProps} />;
    }
    case 'COMPONENT': {
      if (layerType === 'COMPONENT_SET') {
        return <ComponentSetProperties {...propertiesProps} />;
      }

      if (layerType === 'COMPONENT') {
        return <ComponentProperties {...propertiesProps} />;
      }

      if (layerType === 'INSTANCE') {
        return <ComponentInstanceProperties {...propertiesProps} />;
      }

      return <ComponentRefProperties {...propertiesProps} />;
    }
    case 'AUTO_LAYOUT': {
      return <AutoLayoutProperties {...propertiesProps} />;
    }
    case 'CONSTRAINTS': {
      return <ConstraintsProperties {...propertiesProps} />;
    }
    case 'LAYER': {
      return <LayerProperties {...propertiesProps} />;
    }
    case 'TEXT': {
      return <TextProperties {...propertiesProps} />;
    }
    case 'LAYOUT_GRID': {
      return <LayoutGridProperties {...propertiesProps} />;
    }
    case 'FILL': {
      return (
        <FillProperties
          {...propertiesProps}
          figmaFileImages={figmaFileImages}
        />
      );
    }
    case 'STROKE': {
      return (
        <StrokeProperties
          {...propertiesProps}
          figmaFileImages={figmaFileImages}
        />
      );
    }
    case 'EFFECTS': {
      return <EffectsProperties {...propertiesProps} />;
    }
  }
};

const CategorySection = ({
  isInitialState,
  readOnly,
  properties,
  categoryProperties,
  figmaFileImages,
  title,
  categoryKey,
  categoryVariant,
  namePrefix,
  layerType,
  designType,
  onCategoryCheckChanged,
  onPropertyChanged,
}) => {
  const propertiesProps = {
    properties,
    isInitialState,
    readOnly,
    categoryVariant,
    namePrefix,
    layerType,
    designType,
    onPropertyChanged,
  };

  return (
    <Collapsible
      transitionTime={150}
      triggerTagName="div"
      className={styles.section}
      openedClassName={cn(styles.section, styles.sectionOpened)}
      open
      trigger={({ onTriggerClick }) => {
        return (
          <div className={styles.header}>
            {!isInitialState && designType === 'DESIGN' && (
              <CategoryCheckbox
                className={styles.check}
                readOnly={readOnly}
                categoryKey={categoryKey}
                properties={properties}
                categoryProperties={categoryProperties}
                onChanged={onCategoryCheckChanged}
              />
            )}
            <div className={styles.headerInner} onClick={onTriggerClick}>
              <Typography
                className={styles.title}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                {title}
              </Typography>
              <Icon className={styles.icon} name="chevronDown" />
            </div>
          </div>
        );
      }}
    >
      <div className={styles.inner}>
        {_renderProperties({
          propertiesProps,
          figmaFileImages,
        })}
      </div>
    </Collapsible>
  );
};

export default React.memo(CategorySection);
