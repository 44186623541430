import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { userSelector } from 'store/auth/selectors';
import { adminsSelector } from 'store/admins/selectors';
import { getSchoolAdmins } from 'store/admins/actions';

import Admins from './Admins';

const AdminsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: schoolId } = useParams();

  const user = useSelector(userSelector);
  const admins = useSelector(adminsSelector);

  const [loading, setLoading] = useState(true);
  const [sortParams, setSortParams] = useState({
    sortBy: 'createdAt',
    sortDirection: 'desc',
  });

  useEffect(() => {
    const _getSchoolAdmins = async () => {
      try {
        await dispatch(
          getSchoolAdmins({
            schoolId,
            params: {
              page: 1,
              limit: 10,
              sortBy: sortParams.sortBy,
              sortDirection: sortParams.sortDirection,
            },
          }),
        ).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getSchoolAdmins();
  }, []);

  const handleSortChange = async (nextSortBy) => {
    try {
      await dispatch(
        getSchoolAdmins({
          schoolId,
          params: {
            page: 1,
            limit: 50,
            sortBy: nextSortBy,
            sortDirection: sortParams.sortDirection === 'asc' ? 'desc' : 'asc',
          },
        }),
      ).unwrap();

      setSortParams((prevState) => ({
        sortBy: nextSortBy,
        sortDirection: prevState.sortDirection === 'asc' ? 'desc' : 'asc',
      }));
    } catch (error) {
      console.warn(error);
    }
  };

  const handleEditAdminClick = (admin) => {
    navigate(`/admins/${admin.id}`);
  };

  const handleAdminRowClick = (adminId) => {
    navigate(`/admins/${adminId}`);
  };

  return (
    <Admins
      loading={loading}
      admins={admins.data}
      user={user}
      schoolId={schoolId}
      sortParams={sortParams}
      onSortChange={handleSortChange}
      onAdminRowClick={handleAdminRowClick}
      onEditAdminClick={handleEditAdminClick}
    />
  );
};

export default React.memo(AdminsContainer);
