import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { EditableTextField, SwitchField } from 'components/FormikFields';

import styles from './LessonHeader.styles.scss';

const LessonHeader = ({ readOnly }) => (
  <div className={styles.header}>
    <FormikField
      name="title"
      withControl={false}
      component={EditableTextField}
      componentProps={{
        className: styles.title,
        readOnly,
        variant: 'heading1',
        placeholder: getTranslation('common.text.untitled'),
      }}
    />

    <div className={styles.free}>
      <Typography
        className={styles.freeLabel}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('common.text.free_lesson')}
      </Typography>
      <FormikField
        name="free"
        withControl={false}
        component={SwitchField}
        componentProps={{ readOnly, type: 'checkbox' }}
      />
    </div>
  </div>
);

export default React.memo(LessonHeader);
