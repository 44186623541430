import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Tabs } from 'components/shared/Tabs';

import { InfoByModule } from '../InfoByModule';
import { InfoByStudents } from '../InfoByStudents';

import styles from './InfoCard.styles.scss';

const InfoCard = ({ className, courseStructure, courseStats }) => (
  <div className={cn(styles.card, className)}>
    <Tabs
      items={[
        {
          label: getTranslation('dashboard_page.info_card.content_label'),
          content: (
            <InfoByModule
              courseStats={courseStats}
              courseStructure={courseStructure}
            />
          ),
        },
        {
          label: getTranslation('dashboard_page.info_card.students_label'),
          content: <InfoByStudents courseStats={courseStats} />,
        },
      ]}
    />
  </div>
);

export default React.memo(InfoCard);
