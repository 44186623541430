export const getPropertyIcon = (type) => {
  if (type === 'VARIANT') {
    return 'variant';
  }

  if (type === 'BOOLEAN') {
    return 'booleanProperty'; // TODO
  }

  if (type === 'TEXT') {
    return 'textChar';
  }

  if (type === 'INSTANCE_SWAP') {
    return 'instance';
  }

  return null;
};

export const getPropertyValue = (property) => {
  if (property.type === 'BOOLEAN') {
    return property.value ? 'On' : 'Off';
  }

  return property.value;
};
