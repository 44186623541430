import React from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { useCourse } from 'hooks/useCourse';

import { MarketingSettings } from 'pages/CoursePage/components/MarketingSettings';

import TrainingPage from './TrainingPage';

const TrainingPageContainer = () => {
  const {
    loading,
    saveLoading,
    autoSaveLoading,
    readOnly,
    marketingSettingsOpen,
    data: training,
    lastActionAt,
    closeMarketingSettings,
    onAutoSaveSubmit,
    onSaveClick,
    onPreviewClick,
    onStatusContextItemClick,
    onMoreContextItemClick,
  } = useCourse();

  const user = useSelector(userSelector);
  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <>
      <TrainingPage
        loading={loading}
        isRootAdmin={isRootAdmin}
        saveLoading={saveLoading}
        autoSaveLoading={autoSaveLoading}
        training={training}
        lastActionAt={lastActionAt}
        readOnly={readOnly}
        onAutoSaveSubmit={onAutoSaveSubmit}
        onSaveClick={onSaveClick}
        onPreviewClick={onPreviewClick}
        onStatusContextItemClick={onStatusContextItemClick}
        onMoreContextItemClick={onMoreContextItemClick}
      />
      <MarketingSettings
        open={marketingSettingsOpen}
        onClose={closeMarketingSettings}
      />
    </>
  );
};

export default React.memo(TrainingPageContainer);
