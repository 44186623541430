import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import { CoursePreview } from 'components/CoursePreview';
import { PublishStatusTag } from 'components/PublishStatusTag';

import styles from './CoursePreviewItem.styles.scss';

const CoursePreviewItem = ({ className, preview, title, status }) => (
  <div className={cn(styles.item, className)}>
    <CoursePreview preview={preview} />
    <div className={styles.inner}>
      <Typography variant="body3" weight="medium" mode="compact">
        {title || getTranslation('common.text.untitled')}
      </Typography>
      <PublishStatusTag className={styles.tag} variant={status.toLowerCase()} />
    </div>
  </div>
);

export default React.memo(CoursePreviewItem);
