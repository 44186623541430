import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Typography } from 'components/shared/Typography';

import { TextTextProperty } from '../TextTextProperty';
import { ColorProperty } from '../ColorProperty';
import { EmptyProperties } from '../EmptyProperties';

import { getLayoutGridName } from './LayoutGridProperties.helpers';

import styles from './LayoutGridProperties.styles.scss';

const LayoutGridProperties = ({ properties }) => {
  const layoutGrids = getLayerPropertyByKey({ key: 'layoutGrids', properties });

  if (!layoutGrids || !layoutGrids.value || layoutGrids.value.length === 0) {
    return <EmptyProperties />;
  }

  return (
    <div className={styles.root}>
      {layoutGrids.value.map((layoutGrid) => (
        <div className={styles.item}>
          <Typography
            className={styles.itemTitle}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {getLayoutGridName(layoutGrid)}
          </Typography>
          <div className={styles.itemInner}>
            {layoutGrid.pattern === 'GRID' && (
              <>
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridSize',
                    value: layoutGrid.sectionSize,
                  }}
                />
                <ColorProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridColor',
                    valueType: 'SOLID',
                    value: layoutGrid.color,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridOpacity',
                    value: layoutGrid.color.a,
                  }}
                />
              </>
            )}
            {(layoutGrid.pattern === 'COLUMNS' ||
              layoutGrid.pattern === 'ROWS') && (
              <>
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridCount',
                    value: layoutGrid.count,
                  }}
                />
                <ColorProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridColor',
                    valueType: 'SOLID',
                    value: layoutGrid.color,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridOpacity',
                    value: layoutGrid.color.a,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridType',
                    value: layoutGrid.alignment,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridWidth',
                    value: layoutGrid.sectionSize,
                  }}
                  dependProperty={{ value: layoutGrid.pattern }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridOffset',
                    value: layoutGrid.offset,
                  }}
                  dependProperty={{ value: layoutGrid.alignment }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'layoutGridGutter',
                    value: layoutGrid.gutterSize,
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(LayoutGridProperties);
