import React from 'react';
import cn from 'classnames';
import _toLower from 'lodash/toLower';
import _upperFirst from 'lodash/upperFirst';
import formatDistance from 'date-fns/formatDistance';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getStatusText } from 'helpers/getStatusText';
import { getTranslation } from 'helpers/getTranslation';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { StatusTag } from 'components/StatusTag';

import { getActivationType } from './ActivationSection.helpers';

import styles from './ActivationSection.styles.scss';

const ActivationSection = ({
  locale,
  student,
  status,
  activationType,
  createdAt,
  updatedAt,
  accessExpireDate,
}) => (
  <div className={styles.card}>
    <div className={styles.inner}>
      <Typography className={styles.dateLabel} variant="body3">
        {getTranslation('student_page.activation_section.registration_label')}{' '}
        <span className={styles.dateText}>
          {formatDate(createdAt, 'dd MMM, yyyy', locale)}
        </span>
      </Typography>
      {updatedAt && (
        <Typography className={styles.dateLabel} variant="body3">
          {getTranslation('student_page.activation_section.activity_label')}{' '}
          <span className={styles.dateText}>
            {formatDistance(new Date(), updatedAt, {
              locale: locale === 'ru' ? ru : enUS,
            })}{' '}
            {getTranslation('student_page.activation_section.ago_label')}
          </span>
        </Typography>
      )}
    </div>
    <div className={styles.main}>
      <div className={styles.infoItems}>
        <div className={styles.info}>
          <Typography className={styles.infoLabel} variant="body3">
            {getTranslation('student_page.activation_section.account')}
          </Typography>
          {student.isTest ? (
            <Typography
              className={cn(styles.infoValue, styles.infoValueTest)}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {getTranslation('student_page.activation_section.test')}
            </Typography>
          ) : (
            <StatusTag
              className={styles.status}
              variant={getUserStatusVariant(status)}
            >
              {getStatusText(status)}
            </StatusTag>
          )}
        </div>
        <div className={styles.info}>
          <Typography className={styles.infoLabel} variant="body3">
            {getTranslation('student_page.activation_section.status_label')}
          </Typography>
          <Typography
            className={cn(styles.infoValue, {
              [styles.infoValueProSubscription]: student.isProSubscription,
              [styles.infoValueFree]: !student.isProSubscription,
            })}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {student.isProSubscription
              ? getTranslation('common.text.pro')
              : getTranslation('common.text.free')}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography className={styles.infoLabel} variant="body3">
            {getTranslation('student_page.activation_section.type_label')}
          </Typography>
          <Typography
            className={cn(
              styles.infoValue,
              styles[`infoValue${_upperFirst(_toLower(activationType))}`],
            )}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {getActivationType(activationType) ||
              getTranslation('student_page.activation_section.pending')}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography className={styles.infoLabel} variant="body3">
            {getTranslation('student_page.activation_section.expire_date')}
          </Typography>
          <Typography
            className={styles.infoValue}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            {accessExpireDate === 'NEVER'
              ? getTranslation('common.text.never')
              : formatDate(accessExpireDate, 'dd MMM, yyyy', locale)}
          </Typography>
        </div>
      </div>
      <Button size="md" variant="outlined" disabled>
        {getTranslation('common.button.manage')}
      </Button>
    </div>
  </div>
);

export default React.memo(ActivationSection);
