import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { USER_ROLES, COURSE_TYPES } from 'constants/common';

import { getSchoolById } from 'store/school/actions';
import { currentSchoolSelector } from 'store/school/selectors';
import { userSelector } from 'store/auth/selectors';

import * as api from 'api/methods';

import { createDownloadLink } from 'helpers/createDownloadLink';

import { useModal } from 'hooks/useModal';

import { AddStudent } from 'components/AddStudent';
import { AddAdmin } from 'components/AddAdmin';
import { GenerateCode } from 'components/GenerateCode';
import { ImportAdmins } from 'components/ImportAdmins';
import { ImportStudents } from 'components/ImportStudents';

import { EditSchool } from './components/EditSchool';

import { tabs } from './SchoolPage.config.js';

import SchoolPage from './SchoolPage';

const SchoolPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { id: paramSchoolId } = useParams();

  const currentSchool = useSelector(currentSchoolSelector);
  const user = useSelector(userSelector);

  const schoolId = paramSchoolId || user.schoolId;

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const [editSchoolOpen, openSchoolAdmin, closeSchoolAdmin, editSchoolData] =
    useModal({
      defaultOpen: false,
    });
  const [addStudentOpen, openAddStudent, closeAddStudent] = useModal({
    defaultOpen: false,
  });
  const [addAdminOpen, openAddAdmin, closeAddAdmin] = useModal({
    defaultOpen: false,
  });
  const [generateCodeOpen, openGenerateCode, closeGenerateCode] = useModal({
    defaultOpen: false,
  });
  const [importStudentsOpen, openImportStudents, closeImportStudents] =
    useModal({
      defaultOpen: false,
    });
  const [importAdminsOpen, openImportAdmins, closeImportAdmins] = useModal({
    defaultOpen: false,
  });

  useEffect(() => {
    const _getSchoolById = async () => {
      try {
        await dispatch(getSchoolById(schoolId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getSchoolById();
  }, []);

  const handleTabChange = (nextTab) => {
    setCurrentTab(nextTab);
  };

  const handleEditClick = () => {
    openSchoolAdmin({ school: currentSchool });
  };

  const handleAddStudentContextItemClick = (contextItem) => {
    if (contextItem.key === 'CREATE_NEW') {
      openAddStudent();
    }

    if (contextItem.key === 'IMPORT') {
      openImportStudents();
    }
  };

  const handleAddAdminContextItemClick = (contextItem) => {
    if (contextItem.key === 'CREATE_NEW') {
      openAddAdmin();
    }

    if (contextItem.key === 'IMPORT') {
      openImportAdmins();
    }
  };

  const handleAddCourseContextItemClick = async (contextItem) => {
    try {
      const defaultData = {
        type: COURSE_TYPES.COURSE,
        platform: contextItem.value,
        schoolId,
      };

      const course = await api.createCourse(defaultData);

      navigate(`/courses/${course.id}`);
    } catch (error) {
      console.warn(error); // TODO: catch error
    }
  };

  const handleAddArcadeContextItemClick = async (contextItem) => {
    try {
      const defaultData = {
        type: COURSE_TYPES.ARCADE,
        platform: contextItem.value,
        schoolId,
      };

      const arcade = await api.createArcade(defaultData);

      navigate(`/arcades/${arcade.id}`);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleAddTrainingContextItemClick = async (contextItem) => {
    try {
      const defaultData = {
        type: COURSE_TYPES.TRAINING,
        platform: contextItem.value,
        schoolId,
      };

      const training = await api.createTraining(defaultData);

      navigate(`/trainings/${training.id}`);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleGenerateCodeClick = () => {
    openGenerateCode();
  };

  const handleExportCodesClick = async () => {
    const response = await api.exportSchoolCodes(schoolId, null);

    createDownloadLink(response, 'promocodes.xlsx');
  };

  const handleActivityReportClick = async () => {
    const response = await api.getStudentsActivityReport(schoolId);

    createDownloadLink(response, 'report.xlsx');
  };

  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  return (
    <>
      <SchoolPage
        locale={i18n.locale}
        loading={loading}
        isRootAdmin={isRootAdmin}
        currentTab={currentTab}
        currentSchool={currentSchool}
        onTabChange={handleTabChange}
        onEditClick={handleEditClick}
        onAddStudentContextItemClick={handleAddStudentContextItemClick}
        onAddAdminContextItemClick={handleAddAdminContextItemClick}
        onAddCourseContextItemClick={handleAddCourseContextItemClick}
        onAddArcadeContextItemClick={handleAddArcadeContextItemClick}
        onAddTrainingContextItemClick={handleAddTrainingContextItemClick}
        onGenerateCodeClick={handleGenerateCodeClick}
        onExportCodesClick={handleExportCodesClick}
        onActivityReportClick={handleActivityReportClick}
      />
      <EditSchool
        {...editSchoolData}
        locale={i18n.locale}
        open={editSchoolOpen}
        onClose={closeSchoolAdmin}
      />
      <AddStudent
        open={addStudentOpen}
        isRootAdmin={isRootAdmin}
        isSchool
        schoolId={schoolId}
        onClose={closeAddStudent}
      />
      <AddAdmin
        open={addAdminOpen}
        isRootAdmin={isRootAdmin}
        isSchool
        schoolId={schoolId}
        onClose={closeAddAdmin}
      />
      <GenerateCode
        open={generateCodeOpen}
        schoolId={schoolId}
        currentSchool={currentSchool}
        onClose={closeGenerateCode}
      />
      <ImportAdmins
        open={importAdminsOpen}
        schoolId={schoolId}
        onClose={closeImportAdmins}
      />
      <ImportStudents
        open={importStudentsOpen}
        schoolId={schoolId}
        onClose={closeImportStudents}
      />
    </>
  );
};

export default React.memo(SchoolPageContainer);
