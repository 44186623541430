import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Checkbox } from 'components/shared/Checkbox';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { SwitchField } from 'components/FormikFields';

import styles from './MaterialsSection.styles.scss';

const MaterialsSection = ({
  withMaterials,
  readOnly,
  materials,
  namePrefix,
  onMaterialChecked,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <FormikField
          withControl={false}
          name={`${namePrefix}.withMaterials`}
          component={SwitchField}
          componentProps={{ readOnly, type: 'checkbox' }}
        />
        <Typography
          className={styles.headerText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTranslation(
            'figma_lesson_page.main_settings.materials_section_title',
          )}
        </Typography>
      </div>
      {withMaterials && (
        <>
          {materials && materials.length > 0 ? (
            <>
              <Typography
                className={styles.text}
                variant="body1"
                mode="compact"
              >
                {getTranslation(
                  'figma_lesson_page.main_settings.materials_section.select_image',
                )}
              </Typography>
              <div className={styles.materials}>
                {materials.map((material, materialIndex) => (
                  <div className={styles.material} key={material.imageRef}>
                    <Checkbox
                      readOnly={readOnly}
                      className={styles.materialCheck}
                      iconWrapperClassName={styles.materialCheckIconWrapper}
                      checked={material.check}
                      size="sm"
                      onChange={(event) =>
                        onMaterialChecked(
                          event,
                          `${namePrefix}.materials[${materialIndex}]`,
                          material,
                        )
                      }
                    />
                    <img
                      className={styles.materialPreview}
                      src={material.imageUrl}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <Typography className={styles.text} variant="body1" mode="compact">
              {getTranslation(
                'figma_lesson_page.main_settings.materials_section.empty_materials',
              )}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(MaterialsSection);
