import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from 'api/methods';

import * as types from './types';

export const getAdminById = createAsyncThunk(
  types.GET_ADMIN_BY_ID,
  async (adminId) => {
    const admin = await api.getAdminById(adminId);

    const courses = await api.getCoursesByAdminId(adminId);

    return { ...admin, courses };
  },
);

export const updateAdminById = createAsyncThunk(
  types.UPDATE_ADMIN_BY_ID,
  async ({ adminId, data }) => {
    const admin = await api.updateAdminById(adminId, data);

    return { ...admin };
  },
);
