import { createSlice } from '@reduxjs/toolkit';

import { getSchoolCodes, generateCodes } from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  perPage: 10,
  totalPages: null,
};

const codesSlice = createSlice({
  name: 'schoolCodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchoolCodes.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(generateCodes.fulfilled, (state, action) => {
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    });
  },
});

export default codesSlice.reducer;
