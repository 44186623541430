import * as Yup from 'yup';

import { getTranslation } from 'helpers/getTranslation';

export const initialValues = {
  platform: '',
  school: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    platform: Yup.object().required(
      getTranslation('arcades_page.form_config.platform_validate'),
    ),
    school: Yup.object().required(
      getTranslation('arcades_page.form_config.school_validate'),
    ),
  });
