import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

import ForgotPasswordPage from './ForgotPasswordPage';

const ForgotPasswordPageContainer = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [currentEmail, setCurrentEmail] = useState('');

  const handleBackClick = () => {
    navigate(ROUTES.HOME_PAGE);
  };

  const handleFormSubmitted = (email) => {
    setCurrentEmail(email);

    setCurrentStep(2);
  };

  return (
    <ForgotPasswordPage
      currentStep={currentStep}
      currentEmail={currentEmail}
      onFormSubmitted={handleFormSubmitted}
      onBackClick={handleBackClick}
    />
  );
};

export default React.memo(ForgotPasswordPageContainer);
