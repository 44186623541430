export const formatTime = (duration) => {
  const hrs = Math.round(duration / 3600);
  const mins = Math.round((duration % 3600) / 60);
  const secs = Math.round(duration % 60);

  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;

  return ret;
};
