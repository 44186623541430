import React from 'react';
import _upperFirst from 'lodash/upperFirst';
import formatDistance from 'date-fns/formatDistance';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';

import { ProTag } from 'components/ProTag';
import { StatusTag } from 'components/StatusTag';

import styles from './MainInfo.styles.scss';

const MainInfo = ({
  locale,
  isProSubscription,
  avatar,
  name,
  email,
  status,
  createdAt,
  updatedAt,
  onCopyEmailClick,
  onEditClick,
}) => (
  <div className={styles.info}>
    <div className={styles.inner}>
      <Avatar url={avatar} text={name || email} />
      <div className={styles.main}>
        <div className={styles.nameWrapper}>
          <Typography
            className={styles.name}
            variant="body1"
            weight="medium"
            mode="compact"
          >
            {name || '-'}
          </Typography>

          <div className={styles.tags}>
            {isProSubscription && <ProTag className={styles.tag} />}
          </div>
        </div>

        <div className={styles.emailWrapper}>
          <Typography className={styles.email} variant="body3" mode="compact">
            {email}
          </Typography>
          <Icon
            className={styles.copyIcon}
            name="copy"
            onClick={onCopyEmailClick}
          />
        </div>

        <StatusTag
          className={styles.status}
          variant={getUserStatusVariant(status)}
        >
          {_upperFirst(status.toLowerCase())}
        </StatusTag>
      </div>
    </div>

    <div className={styles.actions}>
      <div className={styles.activityInfo}>
        <Typography className={styles.dateLabel} variant="body3">
          {getTranslation(
            'student_page.school_student_card.main_info.registration_date',
          )}{' '}
          <span className={styles.dateText}>
            {formatDate(createdAt, 'dd MMM, yyyy', locale)}
          </span>
        </Typography>
        <Typography className={styles.dateLabel} variant="body3">
          {getTranslation(
            'student_page.school_student_card.main_info.last_activity',
          )}{' '}
          <span className={styles.dateText}>
            {formatDistance(new Date(), updatedAt, {
              locale: locale === 'ru' ? ru : enUS,
            })}{' '}
            {getTranslation('common.text.ago')}
          </span>
        </Typography>
      </div>
      <Button
        startIcon={<Icon name="edit" />}
        size="md"
        variant="outlined"
        onClick={onEditClick}
      >
        {getTranslation('common.button.edit')}
      </Button>
    </div>
  </div>
);

export default React.memo(MainInfo);
