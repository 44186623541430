import React from 'react';

import FormatSelect from './FormatSelect';

const FormatSelectContainer = ({ readOnly, field, helpers }) => {
  const handleItemClick = (itemValue) => {
    if (readOnly) {
      return;
    }

    helpers.setValue(itemValue);
  };

  return (
    <FormatSelect
      readOnly={readOnly}
      value={field.value}
      onItemClick={handleItemClick}
    />
  );
};

export default React.memo(FormatSelectContainer);
