import React from 'react';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';

import { PropertySelectField } from '../PropertySelectField';

import { getPropertyLabel, getPropertyValue } from './BooleanProperty.helpers';

import styles from './BooleanProperty.styles.scss';

const _renderContent = ({ key, value }) => (
  <>
    <Typography
      className={styles.label}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {getPropertyLabel({ type: key, value })}
    </Typography>
    <Typography
      className={styles.value}
      variant="body2"
      weight="medium"
      mode="compact"
    >
      {getPropertyValue({ type: key, value })}
    </Typography>
  </>
);

const BooleanProperty = ({
  isInitialState,
  readOnly,
  designType,
  namePrefix,
  property,
  onChanged,
}) => {
  if (!property) {
    return null;
  }

  if (isInitialState || designType === 'PROTOTYPE') {
    return (
      <div className={styles.propertyReadOnly}>{_renderContent(property)}</div>
    );
  }

  return (
    <FormikField
      name={namePrefix}
      withControl={false}
      component={PropertySelectField}
      componentProps={{
        type: 'checkbox',
        readOnly,
        property,
        renderProperty: _renderContent,
        onChanged,
      }}
    />
  );
};

export default React.memo(BooleanProperty);
