import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { reorderCourseModules } from 'store/course/actions';

import { useChangeOrder } from 'hooks/useChangeOrder';

import Modules from './Modules';

const ModulesContainer = ({ modules, ...restProps }) => {
  const dispatch = useDispatch();

  const { id: courseId } = useParams();

  const _handleModuleDragEnd = (items) => {
    dispatch(
      reorderCourseModules({
        courseId,
        data: { modules: items },
      }),
    );
  };

  const {
    sensors,
    activeDragItem,
    onItemDragStart,
    onItemDragEnd,
    onItemDragOver,
  } = useChangeOrder({
    items: modules,
    namePrefix: 'modules',
    onItemDragEnd: _handleModuleDragEnd,
  });

  return (
    <Modules
      {...restProps}
      modules={modules}
      sensors={sensors}
      activeDragModule={activeDragItem}
      onModuleDragOver={onItemDragOver}
      onModuleDragStart={onItemDragStart}
      onModuleDragEnd={onItemDragEnd}
    />
  );
};

export default React.memo(ModulesContainer);
