import React from 'react';
import { useSelector } from 'react-redux';

import { arcadesSelector } from 'store/arcades/selectors';

import { usePagination } from 'hooks/usePagination';

import ArcadesTable from './ArcadesTable';

const ArcadesTableContainer = ({
  locale,
  dataAction,
  params,
  ...restProps
}) => {
  const { currentPage, totalPages, perPage } = useSelector(arcadesSelector);

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction,
    currentPage,
    totalPages,
    perPage,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  return (
    <ArcadesTable
      {...restProps}
      locale={locale}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      params={params}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
    />
  );
};

export default React.memo(ArcadesTableContainer);
