import { COURSE_STRUCTURES, STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { initialValues } from './CoursePage.formConfig';

export const convertDataToForm = (data) => {
  if (!data) {
    return initialValues;
  }

  const commonData = {
    id: data.id,
    slug: data.slug || '',
    title: data.title || '',
    type: data.type,
    description: data.description || '',
    free: !data.premium,
    preview: data.preview,
    withResultPreview: data.resultPreview ? true : false,
    resultPreview: data.resultPreview,
    difficulty: data.difficulty || 'EASY',
    structure: data.structure,
    readOnly: data.status !== STATUSES.DRAFT,
  };

  if (data.structure === COURSE_STRUCTURES.LESSONS) {
    return {
      ...commonData,
      lessons: data.lessons,
    };
  }

  if (data.structure === COURSE_STRUCTURES.MODULES) {
    return {
      ...commonData,
      modules: data.modules,
    };
  }

  return commonData;
};

export const getContextItems = (status) => {
  switch (status) {
    case STATUSES.DRAFT: {
      return [
        {
          key: STATUSES.PUBLISHED,
          icon: 'published',
          text: getTranslation('course_page.helpers.publish_course'),
          value: STATUSES.PUBLISHED,
        },
        {
          key: 'PUBLISH_COURSE_AND_ALL_LESSONS',
          icon: 'published',
          text: getTranslation(
            'course_page.helpers.publish_course_and_lessons',
          ),
          value: 'PUBLISH_COURSE_AND_ALL_LESSONS',
        },
        {
          key: STATUSES.ARCHIVED,
          icon: 'archived',
          text: getTranslation('course_page.helpers.archive_course'),
          value: STATUSES.ARCHIVED,
        },
      ];
    }
    case STATUSES.PUBLISHED: {
      return [
        {
          key: STATUSES.DRAFT,
          icon: 'drafts',
          text: getTranslation('course_page.helpers.draft_course'),
          value: STATUSES.DRAFT,
        },
      ];
    }
    case STATUSES.ARCHIVED: {
      return [
        {
          key: STATUSES.DRAFT,
          icon: 'drafts',
          text: getTranslation('course_page.helpers.restore_course'),
          value: STATUSES.DRAFT,
        },
      ];
    }
  }
};

export const getReadonlyCourseText = (courseStatus) => {
  switch (courseStatus) {
    case STATUSES.PUBLISHED: {
      return getTranslation(
        'course_page.helpers.read_only_published_course_text',
      );
    }
    case STATUSES.ARCHIVED: {
      return getTranslation(
        'course_page.helpers.read_only_archived_course_text',
      );
    }
    default: {
      return '';
    }
  }
};
