import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import FigmaIcon from 'assets/img/figma-icon.svg';

import { Link } from 'components/shared/Link';
import { Icon } from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import styles from './FigmaFileAccess.styles.scss';

const SIZES = {
  lg: styles.rootLarge,
  sm: styles.rootSmall,
};

const FigmaFileAccess = ({ className, size, onProvideAccessClick }) => (
  <div className={cn(styles.root, SIZES[size], className)}>
    <div className={styles.inner}>
      <div className={styles.main}>
        <FigmaIcon className={styles.figmaIcon} />
        <Typography
          variant="body1"
          weight="medium"
          mode="compact"
          className={styles.title}
        >
          {getTranslation('figma_lesson_page.file_access.title')}
        </Typography>
      </div>
      {size === 'lg' && (
        <div className={styles.info}>
          <Link
            className={styles.infoLink}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {getTranslation('figma_lesson_page.file_access.info_link')}
          </Link>
          <Icon className={styles.infoIcon} name="openInWindow" />
        </div>
      )}
    </div>
    <Button
      className={styles.button}
      size="md"
      variant="link"
      onClick={onProvideAccessClick}
    >
      {getTranslation('common.button.provide_access')}
    </Button>
  </div>
);

FigmaFileAccess.defaultProps = {
  size: 'lg',
};

export default React.memo(FigmaFileAccess);
