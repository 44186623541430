import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { TypesSelect } from 'components/TypesSelect';
import { TheoryBlocks } from 'components/TheoryBlocks';

import { Answers } from '../Answers';

import styles from './Question.styles.scss';

const Question = ({
  readOnly,
  answersType,
  questionIndex,
  onAnswersTypeClick,
}) => (
  <div className={cn(styles.question, { [styles.readOnlyQuestion]: readOnly })}>
    <Typography
      className={styles.title}
      variant="body1"
      weight="medium"
      mode="compact"
    >
      {getTranslation('quiz_page.question.title', {
        questionIndex: questionIndex + 1,
      })}
    </Typography>
    <div className={styles.settings}>
      <TheoryBlocks namePrefix={`questions[${questionIndex}].blocks`} />
    </div>
    <div className={styles.divider} />
    {!answersType ? (
      <div className={styles.select}>
        <Typography
          className={styles.selectTitle}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTranslation('quiz_page.question.select_title')}
        </Typography>
        <TypesSelect
          size="md"
          options={[
            {
              icon: 'textChar',
              text: getTranslation('quiz_page.question.type_text_1'),
              value: 'TEXT',
            },
            {
              icon: 'image',
              text: getTranslation('quiz_page.question.type_text_2'),
              value: 'IMAGE',
            },
          ]}
          onOptionClick={onAnswersTypeClick}
        />
      </div>
    ) : (
      <Answers
        answersType={answersType}
        namePrefix={`questions[${questionIndex}]`}
      />
    )}
  </div>
);

export default React.memo(Question);
