import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { COURSE_TYPES } from 'constants/common';

import CoursePreviewLayout from './CoursePreviewLayout';

const CoursePreviewLayoutContainer = ({ type, ...restProps }) => {
  const { id: courseId } = useParams();

  const navigate = useNavigate();

  const handleExitPreviewClick = () => {
    if (type === COURSE_TYPES.TRAINING) {
      navigate(`/trainings/${courseId}`);
      return;
    }
    if (type === COURSE_TYPES.ARCADE) {
      navigate(`/arcades/${courseId}`);
      return;
    }
    navigate(`/courses/${courseId}`);
  };

  return (
    <CoursePreviewLayout
      {...restProps}
      onExitPreviewClick={handleExitPreviewClick}
    />
  );
};

export default React.memo(CoursePreviewLayoutContainer);
