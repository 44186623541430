import React from 'react';

import { PropertySelect } from '../PropertySelect';

const PropertySelectField = ({
  readOnly,
  haveError,
  field,
  helpers,
  error,
  onChanged,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (readOnly) {
      return;
    }

    field.onChange(event);

    if (onChanged) {
      onChanged(field.name, event.target.checked);
    }
  };

  return (
    <PropertySelect
      {...restProps}
      checked={field.checked}
      name={field.name}
      onChange={handleChange}
      onBlur={field.onBlur}
    />
  );
};

export default React.memo(PropertySelectField);
