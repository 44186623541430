import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import { LessonIcon } from '../LessonIcon';

import styles from './Lesson.styles.scss';

const Lesson = ({ className, available, completed, type, title }) => (
  <div
    className={cn(
      styles.lesson,
      { [styles.lessonDisabled]: !available },
      className,
    )}
  >
    <LessonIcon type={type} />
    <Typography
      variant="body3"
      weight="medium"
      mode="compact"
      className={styles.title}
    >
      {title || getTranslation('common.text.untitled')}
    </Typography>
  </div>
);

export default React.memo(Lesson);
