import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAdminById } from 'store/admin/actions';
import { currentAdminSelector } from 'store/admin/selectors';

import { useModal } from 'hooks/useModal';

import { EditAdmin } from './components/EditAdmin';

import AdminPage from './AdminPage';

const AdminPageContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { id: adminId } = useParams();

  const currentAdmin = useSelector(currentAdminSelector);

  const [loading, setLoading] = useState(true);

  const [editAdminOpen, openEditAdmin, closeEditAdmin, editAdminData] =
    useModal({
      defaultOpen: false,
    });

  useEffect(() => {
    const _getAdminById = async () => {
      try {
        await dispatch(getAdminById(adminId)).unwrap();
      } catch (error) {
        console.warn(error);
      }

      setLoading(false);
    };

    _getAdminById();
  }, []);

  const handleEditClick = () => {
    openEditAdmin({ admin: currentAdmin });
  };

  const handleCourseClick = (course) => {
    navigate(`/courses/${course.id}`);
  };

  return (
    <>
      <AdminPage
        locale={i18n.language}
        loading={loading}
        currentAdmin={currentAdmin}
        onCourseClick={handleCourseClick}
        onEditClick={handleEditClick}
      />
      <EditAdmin
        {...editAdminData}
        open={editAdminOpen}
        onClose={closeEditAdmin}
      />
    </>
  );
};

export default React.memo(AdminPageContainer);
