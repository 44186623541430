import React from 'react';

import { LessonPreviewLayout } from 'components/Layout';

import { WebPreviewPage } from './components/WebPreviewPage';
import { PluginPreviewPage } from './components/PluginPreviewPage';

const DifferenceLessonPreviewPage = ({
  submitted,
  currentAnswer,
  currentCompareAnswer,
  lesson,
  onAnswerClick,
  onRestartLessonClick,
  previewType,
  setPreviewType,
  isTestMode,
  setTestMode,
  ...props
}) => (
  <LessonPreviewLayout
    setPreviewType={setPreviewType}
    setTestMode={setTestMode}
  >
    {previewType === 'Plugin' ? (
      <PluginPreviewPage
        {...props}
        submitted={submitted}
        lesson={lesson}
        currentAnswer={currentAnswer}
        currentCompareAnswer={currentCompareAnswer}
        onAnswerClick={onAnswerClick}
        onRestartLessonClick={onRestartLessonClick}
        isTestMode={isTestMode}
        previewType={previewType}
      />
    ) : (
      <WebPreviewPage
        {...props}
        submitted={submitted}
        lesson={lesson}
        currentAnswer={currentAnswer}
        currentCompareAnswer={currentCompareAnswer}
        onAnswerClick={onAnswerClick}
        onRestartLessonClick={onRestartLessonClick}
        isTestMode={isTestMode}
      />
    )}
  </LessonPreviewLayout>
);

export default React.memo(DifferenceLessonPreviewPage);
