import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { Upload } from 'components/shared/Upload';

import { UploadError } from '../UploadError';

import styles from './UploadStep.styles.scss';

const UploadStep = ({
  percentage,
  uploadError,
  onDropAccepted,
  onDropRejected,
  onDownloadTemplateClick,
}) => (
  <div className={styles.step}>
    <Button
      className={styles.button}
      variant="outlined"
      startIcon={<Icon name="download" />}
      onClick={onDownloadTemplateClick}
    >
      {getTranslation('common.button.download_template')}
    </Button>
    {uploadError ? (
      <UploadError />
    ) : (
      <Upload
        className={styles.upload}
        variant="excelFile"
        accept={{
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
        }}
        percentage={percentage}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      />
    )}
  </div>
);

export default React.memo(UploadStep);
