import _upperFirst from 'lodash/upperFirst';
import { getTranslation } from 'helpers/getTranslation';

export const getCourseStatusVariant = (progress, startedAt) => {
  if (progress === 100) {
    return 'success';
  }

  if (progress > 0) {
    return 'info';
  }

  if (progress === 0 && startedAt) {
    return 'info';
  }

  return 'default';
};

export const getCourseStatusText = ({
  progress,
  startedAt,
  completedLessons,
  lessonsCount,
}) => {
  if (progress === 100) {
    return getTranslation('student_page.courses_section.status_text.completed');
  }

  if (progress > 0) {
    return getTranslation(
      'student_page.courses_section.status_text.in_progress',
      { completedLessons, lessonsCount },
    );
  }

  if (progress === 0 && startedAt) {
    return getTranslation(
      'student_page.courses_section.status_text.in_progress',
      { completedLessons, lessonsCount },
    );
  }

  return getTranslation('student_page.courses_section.status_text.not_started');
};

export const getCourseType = (type = '-') => {
  return _upperFirst(type.toLowerCase());
};
