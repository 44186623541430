import React, { useState } from 'react';

import * as api from 'api/methods';

import ForgotPasswordForm from './ForgotPasswordForm';
import { useLocation } from 'react-router-dom';

const ForgotPasswordFormContainer = ({ onFormSubmitted }) => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, { setFieldError }) => {
    setLoading(true);

    try {
      await api.forgotPassword(values);

      setLoading(false);

      if (onFormSubmitted) {
        onFormSubmitted(values.email);
      }
    } catch (error) {
      setLoading(false);

      setFieldError('email', error.message);
    }
  };

  return (
    <ForgotPasswordForm
      loading={loading}
      emailFromAuth={location.state.email}
      onSubmit={handleSubmit}
    />
  );
};

export default React.memo(ForgotPasswordFormContainer);
