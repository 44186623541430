import React from 'react';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';

import { STATUS_ICON } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Tag } from 'components/shared/Tag';
import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';
import { LessonIcon } from 'components/LessonIcon';

import styles from './LessonItem.styles.scss';

const LessonItem = ({ currentLesson, lesson, onClick }) => {
  const isCurrent = currentLesson.id === lesson.id;
  const lessonToShow = isCurrent ? currentLesson : lesson;

  return (
    <div
      className={cn(styles.lesson, {
        [styles.lessonCurrent]: isCurrent,
      })}
      key={lesson.id}
      onClick={() => onClick(lesson)}
    >
      <LessonIcon className={styles.icon} type={lesson.type} />
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
        alt={lessonToShow.title}
      >
        {lessonToShow.title || getTranslation('common.text.untitled')}
      </Typography>
      {lessonToShow.statusError && (
        <Tooltip
          text={lessonToShow.statusError}
          triggerClassName={styles.warningIconWrapper}
          triggerElement={
            <Icon className={styles.warningIcon} name="warning" />
          }
        />
      )}
      {lessonToShow.free && (
        <Tag className={styles.freeTag} variant="warning">
          {getTranslation('common.text.free')}
        </Tag>
      )}
      <Tooltip
        text={_upperFirst(lessonToShow.status.toLowerCase())}
        triggerClassName={styles.statusIconTrigger}
        triggerElement={
          <Icon
            className={cn(
              styles.statusIcon,
              styles[`${lessonToShow.status.toLowerCase()}StatusIcon`],
            )}
            name={STATUS_ICON[lessonToShow.status.toLowerCase()]}
          />
        }
      />
    </div>
  );
};

export default React.memo(LessonItem);
