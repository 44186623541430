import React from 'react';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';
import _toLower from 'lodash/toLower';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './ActivationInfo.styles.scss';

const ActivationInfo = ({ locale, activationType, accessExpireDate }) => (
  <div className={styles.info}>
    <div className={styles.inner}>
      <div className={styles.item}>
        <Typography className={styles.itemLabel} variant="body3">
          {getTranslation(
            'student_page.school_student_card.activation_info.activation_type',
          )}
        </Typography>
        <Typography
          className={cn(
            styles.itemValue,
            styles[`itemValue${_upperFirst(_toLower(activationType))}`],
          )}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {activationType || 'PENDING'}
        </Typography>
      </div>
      <div className={styles.item}>
        <Typography className={styles.itemLabel} variant="body3">
          {getTranslation(
            'student_page.school_student_card.activation_info.expire_date',
          )}
        </Typography>
        <Typography
          className={styles.itemValue}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          {accessExpireDate === 'NEVER'
            ? accessExpireDate
            : formatDate(accessExpireDate, 'dd MMM, yyyy', locale)}
        </Typography>
      </div>
    </div>
    <Button size="md" variant="outlined" disabled>
      {getTranslation('common.button.manage')}
    </Button>
  </div>
);

export default React.memo(ActivationInfo);
