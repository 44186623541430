import React from 'react';

import { COURSE_STRUCTURES } from 'constants/common';

import { Table } from 'components/shared/Table';

import { ModuleTitleCell } from '../ModuleTitleCell';
import { LessonTitleCell } from '../LessonTitleCell';
import { CountPercentCell } from '../CountPercentCell';

import { TABLE_COLUMNS } from './InfoByModule.tableConfig';

import styles from './InfoByModule.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth, justifyContent },
  DefaultCell,
  { courseStructure },
) => {
  switch (key) {
    case 'title': {
      const CellComponent =
        item.cellType === 'LESSON' ? LessonTitleCell : ModuleTitleCell;

      return (
        <CellComponent
          key={key}
          courseStructure={courseStructure}
          minWidth={minWidth}
          maxWidth={maxWidth}
          expanded={item.expanded}
          preview={item.preview}
          title={item.title}
          type={item.type}
          lessonsCount={item.lessonsCount}
        />
      );
    }
    case 'completed': {
      return (
        <CountPercentCell
          key={key}
          count={item.completedCount}
          percent={item.completedPercent}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
          Component={DefaultCell}
        />
      );
    }
    case 'inProgress': {
      return (
        <CountPercentCell
          key={key}
          count={item.inProgressCount}
          percent={item.inProgressPercent}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
          Component={DefaultCell}
        />
      );
    }
    case 'notStarted': {
      return (
        <CountPercentCell
          key={key}
          count={item.notStartedCount}
          percent={item.notStartedPercent}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
          Component={DefaultCell}
        />
      );
    }
    default: {
      return (
        <DefaultCell
          key={key}
          minWidth={minWidth}
          maxWidth={maxWidth}
          justifyContent={justifyContent}
        >
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const InfoByModule = ({ data, courseStructure, onRowClick }) => (
  <Table
    columns={TABLE_COLUMNS[courseStructure]}
    data={data.map((item) => {
      if (
        item.cellType === 'LESSON' &&
        courseStructure === COURSE_STRUCTURES.MODULES
      ) {
        return { ...item, rowClassName: styles.lessonRow };
      }

      return item;
    })}
    cellRenderer={(props, DefaultCell) =>
      cellRenderer(props, DefaultCell, { courseStructure })
    }
    onRowClick={onRowClick}
  />
);

export default React.memo(InfoByModule);
