import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import styles from './ExpandedResultPreview.styles.scss';

const ExpandedResultPreview = ({ preview, onExitPreviewClick }) => (
  <div className={styles.preview}>
    <div className={styles.toolbar}>
      <Button
        className={styles.button}
        size="md"
        variant="outlined"
        startIcon={<Icon name="chevronLeft" />}
        onClick={onExitPreviewClick}
      >
        {getTranslation('common.button.exit_preview')}
      </Button>
    </div>
    <div className={styles.inner}>
      <img className={styles.img} src={preview} />
    </div>
  </div>
);

export default React.memo(ExpandedResultPreview);
