import React from 'react';
import cn from 'classnames';

import { Button } from 'components/shared/Button';

import styles from './DragMenu.styles.scss';

const DragMenu = ({ className, withDrag, dragListeners, onRemoveClick }) => (
  <div className={cn(styles.menu, className)}>
    {withDrag && (
      <Button
        {...dragListeners}
        variant="ghost"
        mode="icon"
        size="sm"
        iconName="drag"
      />
    )}
    <Button
      variant="ghost"
      mode="icon"
      size="sm"
      iconName="trash"
      onClick={onRemoveClick}
    />
  </div>
);

DragMenu.defaultProps = {
  withDrag: true,
};

export default React.memo(DragMenu);
