import React from 'react';
import { Formik, Form } from 'formik';

import { formatDate } from 'helpers/formatDate';
import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Avatar } from 'components/shared/Avatar';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import styles from './EditAdmin.styles.scss';

const EditAdmin = ({
  open,
  locale,
  updateLoading,
  currentAdmin,
  onClose,
  onUpdateStudentClick,
}) => (
  <Drawer
    open={open}
    headerContent={getTranslation('admin_page.edit_admin.title')}
    onClose={onClose}
  >
    <Typography className={styles.lastSave} variant="body3" mode="compact">
      {getTranslation('admin_page.edit_admin.last_save')}
      {currentAdmin?.updatedAt &&
        formatDate(currentAdmin.updatedAt, 'dd MMM, yyyy', locale)}
    </Typography>
    <Formik
      enableReinitialize
      initialValues={currentAdmin}
      onSubmit={onUpdateStudentClick}
    >
      <Form className={styles.form}>
        <div className={styles.main}>
          <div className={styles.header}>
            <Avatar
              size="md"
              url={currentAdmin?.avatar}
              text={currentAdmin?.name || currentAdmin?.email}
            />
            <Typography
              className={styles.name}
              variant="body1"
              weight="medium"
              mode="compact"
            >
              {currentAdmin?.name}
            </Typography>
          </div>
          <FormikField
            name="email"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('admin_page.edit_admin.label.email'),
            }}
          />
          <FormikField
            name="firstName"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('admin_page.edit_admin.label.first_name'),
            }}
          />
          <FormikField
            name="lastName"
            component={InputField}
            controlProps={{
              required: true,
              label: getTranslation('admin_page.edit_admin.label.last_name'),
            }}
          />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.action}
            size="md"
            variant="outlined"
            onClick={onClose}
          >
            {getTranslation('common.button.cancel')}
          </Button>
          <Button
            className={styles.action}
            startIcon={
              updateLoading && <Loading color={'var(--color-white-primary)'} />
            }
            type="submit"
            size="md"
          >
            {getTranslation('common.button.save')}
          </Button>
        </div>
      </Form>
    </Formik>
  </Drawer>
);

export default React.memo(EditAdmin);
