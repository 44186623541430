import React from 'react';
import { Formik, Form } from 'formik';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import { FormikField } from 'components/FormikField';
import { InputField } from 'components/FormikFields';

import {
  initialValues,
  validationSchema,
} from './ForgotPasswordForm.formConfig';

import styles from './ForgotPasswordForm.styles.scss';

const ForgotPasswordForm = ({ loading, emailFromAuth, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{ ...initialValues, email: emailFromAuth }}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form className={styles.form}>
      <FormikField
        name="email"
        component={InputField}
        controlProps={{ className: styles.field, label: 'Email' }}
      />
      <Button
        className={styles.button}
        type="submit"
        size="md"
        startIcon={loading && <Loading color="var(--color-white-primary)" />}
      >
        {getTranslation('forgot_password_page.reset_button')}
      </Button>
    </Form>
  </Formik>
);

export default React.memo(ForgotPasswordForm);
