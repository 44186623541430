import React from 'react';
import cn from 'classnames';

import { TextBlock } from './TextBlock';
import { DividerBlock } from './DividerBlock';
import { ImageBlock } from './ImageBlock';
import { VideoBlock } from './VideoBlock';
import { SpoilerBlock } from './SpoilerBlock';

import styles from './TheoryPreviewBlock.styles.scss';
import { NumberListBlock } from './NumberListBlock';
import { MarkerListBlock } from './MarkerListBlock';

const TheoryPreviewBlock = ({ type, data, className }) => {
  switch (type) {
    case 'heading1':
    case 'heading2':
    case 'heading3':
    case 'text': {
      return (
        <TextBlock
          className={cn(styles.block, styles.textBlock)}
          type={type}
          data={data}
        />
      );
    }
    case 'spoiler': {
      return (
        <SpoilerBlock className={cn(styles.block, className)} data={data} />
      );
    }
    case 'numberList': {
      return (
        <NumberListBlock className={cn(styles.block, className)} data={data} />
      );
    }
    case 'markerList': {
      return (
        <MarkerListBlock className={cn(styles.block, className)} data={data} />
      );
    }
    case 'image': {
      return <ImageBlock className={cn(styles.block, className)} data={data} />;
    }
    case 'video': {
      return <VideoBlock className={cn(styles.block, className)} data={data} />;
    }
    case 'divider': {
      return (
        <DividerBlock className={cn(styles.block, className)} data={data} />
      );
    }
  }
};

export default React.memo(TheoryPreviewBlock);
