import _flatten from 'lodash/flatten';

export const getLessonsCount = (course, lesson) => {
  const lessons = course.lessons
    ? course.lessons
    : _flatten(course.modules.map((module) => module.lessons));
  const lessonIndex = lessons.findIndex(
    (courseLesson) => courseLesson.id === lesson.id,
  );
  return {
    totalLessons: lessons.length,
    lessonIndex: lessonIndex + 1,
  };
};
