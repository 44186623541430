export const getPageFrames = (page) => {
  const frames = page.children.filter((node) => node.type === 'FRAME');

  return frames;
};

export const getPageOptions = (pages) =>
  pages.map((page) => ({ label: page.name, value: page.id }));

export const isTaskFrame = (frameName) => /Task\s[0-9]+$/g.test(frameName);

export const isInitialTaskFrame = (frameName) =>
  /Initial State$/g.test(frameName);

const _checkFrameCorrect = ({ frame, designType }) => {
  switch (designType) {
    case 'DESIGN': {
      return isInitialTaskFrame(frame.name) || isTaskFrame(frame.name);
    }
    case 'PROTOTYPE': {
      return true;
    }
    default: {
      return false;
    }
  }
};

const _formatFrames = ({ frames, designType }) =>
  frames.map((frame) => ({
    id: frame.id,
    name: frame.name,
    correct: _checkFrameCorrect({ frame, designType }),
  }));

const _getPageDesignType = (page) => {
  const type = page.name.split(':')[0];

  if (type === 'Design') {
    return 'DESIGN';
  }

  if (type === 'Prototype') {
    return 'PROTOTYPE';
  }

  return null;
};

export const formatPages = (pages) =>
  pages.map((page) => ({
    id: page.id,
    name: page.name,
    frames: _formatFrames({
      frames: page.children,
      designType: _getPageDesignType(page),
    }),
    loaded: false,
  }));
