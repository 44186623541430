import React from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'constants/common';

import { userSelector } from 'store/auth/selectors';

import { useInboxCount } from './InboxPage.hooks';

import InboxPage from './InboxPage';

const InboxPageContainer = () => {
  const user = useSelector(userSelector);
  const isRootAdmin = user.role === USER_ROLES.ROOT_ADMIN;

  const requestConfig = {
    schoolId: user.schoolId,
    isRootAdmin,
    lessonsRatings: true,
    lessonsReports: true,
    studentReview: isRootAdmin,
    unsibscribeReasons: isRootAdmin,
    surveys: isRootAdmin,
  };

  const [
    loading,
    lessonsRatingsCount,
    lessonsReportsCount,
    studentReviewCount,
    unsibscribeReasonsCount,
    surveysCount,
  ] = useInboxCount(requestConfig);

  return (
    <InboxPage
      isRootAdmin={isRootAdmin}
      lessonsRatingsCount={lessonsRatingsCount}
      lessonsReportsCount={lessonsReportsCount}
      studentReviewCount={studentReviewCount}
      unsibscribeReasonsCount={unsibscribeReasonsCount}
      surveysCount={surveysCount}
      loading={loading}
    />
  );
};

export default React.memo(InboxPageContainer);
