import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';

import styles from './Action.styles.scss';

const Action = ({
  linesValidated,
  correctLinesIds,
  lines,
  onTryAgainClick,
  onRestartLessonClick,
}) => {
  const correct = correctLinesIds.length === lines.length;

  if (linesValidated && !correct) {
    return (
      <Button className={styles.action} onClick={onTryAgainClick}>
        {getTranslation('common.button.try_again')}
      </Button>
    );
  }

  if (linesValidated && correct) {
    return (
      <Button className={styles.action} onClick={onRestartLessonClick}>
        {getTranslation('common.button.restart_lesson')}
      </Button>
    );
  }

  return (
    <Button
      className={styles.action}
      disabled={!linesValidated}
      onClick={onRestartLessonClick}
    >
      {getTranslation('common.button.restart_lesson')}
    </Button>
  );
};

export default React.memo(Action);
