import _isEqual from 'lodash/isEqual';

export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  status: data.status,
  title: data.title,
  text: data.text,
  leftVariantsTitle: data.leftVariantsTitle,
  rightVariantsTitle: data.rightVariantsTitle,
  leftVariants: data.leftVariants,
  rightVariants: data.rightVariants,
  free: data.free,
  readOnly,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  text: data.text,
  leftVariantsTitle: data.leftVariantsTitle,
  rightVariantsTitle: data.rightVariantsTitle,
  leftVariants: data.leftVariants,
  rightVariants: data.rightVariants,
  free: data.free,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.text !== nextLesson.text) {
    properties.push('text');
  }

  if (currentLesson.leftVariantsTitle !== nextLesson.leftVariantsTitle) {
    properties.push('leftVariantsTitle');
  }

  if (currentLesson.rightVariantsTitle !== nextLesson.rightVariantsTitle) {
    properties.push('rightVariantsTitle');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (!_isEqual(currentLesson.leftVariants, nextLesson.leftVariants)) {
    properties.push('leftVariants');
  }

  if (!_isEqual(currentLesson.rightVariants, nextLesson.rightVariants)) {
    properties.push('rightVariants');
  }

  return properties;
};
