import React from 'react';

import { LayerBranch } from './components/LayerBranch';

import styles from './LayersTree.styles.scss';

const LayersTree = ({
  readOnly,
  data,
  namePrefix,
  currentLayer,
  onLayerCheckChanged,
  onLayerClick,
}) => {
  return (
    <div className={styles.tree}>
      {data.map((layer, layerIndex) => (
        <LayerBranch
          key={layer.id}
          readOnly={readOnly}
          layer={layer}
          currentLayer={currentLayer}
          namePrefix={`${namePrefix}[${layerIndex}]`}
          level={0}
          onLayerCheckChanged={onLayerCheckChanged}
          onLayerClick={onLayerClick}
        />
      ))}
    </div>
  );
};

export default React.memo(LayersTree);
