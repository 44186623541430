import React from 'react';

import { Switch } from 'components/shared/Switch';

const SwitchField = ({
  readOnly,
  haveError,
  helpers,
  field,
  form,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (readOnly) {
      return;
    }

    helpers.setValue(event.target.checked);
  };

  return (
    <Switch
      {...restProps}
      readOnly={readOnly}
      checked={field.checked}
      name={field.name}
      onChange={handleChange}
    />
  );
};

export default React.memo(SwitchField);
