import { useDispatch } from 'react-redux';

import { STATUSES } from 'constants/common';

import { showToast } from 'helpers/showToast';
import { getTranslation } from 'helpers/getTranslation';

import { updateLessonStatusById } from 'store/lesson/actions';

export const useStatusChange = (lesson) => {
  const dispatch = useDispatch();

  const _publishLesson = async (currentLesson) => {
    try {
      await dispatch(
        updateLessonStatusById({
          lessonId: lesson.id,
          lessonType: lesson.type,
          data: {
            ...currentLesson,
            status: STATUSES.PUBLISHED,
          },
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.publish_lesson.title'),
          text: getTranslation('common.toast.publish_lesson.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }
  };

  const _moveLessonToArchive = async (currentLesson) => {
    try {
      await dispatch(
        updateLessonStatusById({
          lessonId: lesson.id,
          lessonType: lesson.type,
          data: {
            ...currentLesson,
            status: STATUSES.ARCHIVED,
          },
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.archive_lesson.title'),
          text: getTranslation('common.toast.archive_lesson.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }
  };

  const _moveLessonToDraft = async (currentLesson) => {
    try {
      await dispatch(
        updateLessonStatusById({
          lessonId: lesson.id,
          lessonType: lesson.type,
          data: {
            ...currentLesson,
            status: STATUSES.DRAFT,
          },
        }),
      ).unwrap();

      showToast({
        data: {
          variant: 'success',
          title: getTranslation('common.toast.drafted_lesson.title'),
          text: getTranslation('common.toast.drafted_lesson.text'),
        },
      });
    } catch (error) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.error.title'),
          text: getTranslation('common.toast.error.text'),
        },
      });
    }
  };

  const handleStatusContextItemClick = (contextItem, currentLesson) => {
    switch (contextItem.value) {
      case STATUSES.PUBLISHED: {
        _publishLesson(currentLesson);

        break;
      }
      case STATUSES.DRAFT: {
        _moveLessonToDraft(currentLesson);

        break;
      }
      case STATUSES.ARCHIVED: {
        _moveLessonToArchive(currentLesson);

        break;
      }
    }
  };

  return [handleStatusContextItemClick];
};
