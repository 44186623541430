import { getTranslation } from 'helpers/getTranslation';

export const getAccessPeriod = () => [
  {
    label: getTranslation('student_page.grant_pro_access.1_month'),
    value: 1,
  },
  { label: getTranslation('student_page.grant_pro_access.2_months'), value: 2 },
  { label: getTranslation('student_page.grant_pro_access.3_months'), value: 3 },
  { label: getTranslation('student_page.grant_pro_access.4_months'), value: 4 },
  { label: getTranslation('student_page.grant_pro_access.5_months'), value: 5 },
  { label: getTranslation('student_page.grant_pro_access.6_months'), value: 6 },
  { label: getTranslation('student_page.grant_pro_access.7_months'), value: 7 },
  { label: getTranslation('student_page.grant_pro_access.8_months'), value: 8 },
  { label: getTranslation('student_page.grant_pro_access.9_months'), value: 9 },
  {
    label: getTranslation('student_page.grant_pro_access.10_months'),
    value: 10,
  },
  {
    label: getTranslation('student_page.grant_pro_access.11_months'),
    value: 11,
  },
  {
    label: getTranslation('student_page.grant_pro_access.1_year'),
    value: 12,
  },
];
