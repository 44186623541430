import React from 'react';

import LogoIcon from 'assets/img/logo.svg';
import SuccessIcon from 'assets/img/success.svg';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { Typography } from 'components/shared/Typography';

import { SocialLinks } from 'components/SocialLinks';

import { ForgotPasswordForm } from './components/ForgotPasswordForm';

import styles from './ForgotPasswordPage.styles.scss';

const ForgotPasswordPage = ({
  currentStep,
  currentEmail,
  onFormSubmitted,
  onBackClick,
}) => (
  <div className={styles.page}>
    <LogoIcon className={styles.logo} />
    <div className={styles.inner}>
      {currentStep === 1 && (
        <>
          <div className={styles.header}>
            <Button
              className={styles.back}
              variant="link"
              size="sm"
              startIcon={<Icon name="arrowLeft" />}
              onClick={onBackClick}
            >
              {getTranslation('forgot_password_page.back')}
            </Button>
          </div>
          <div className={styles.main}>
            <Typography className={styles.title} variant="subtitle">
              {getTranslation('forgot_password_page.step_1_title')}
            </Typography>
            <Typography className={styles.text} variant="body2">
              {getTranslation('forgot_password_page.step_1_text')}
            </Typography>
            <div className={styles.form}>
              <ForgotPasswordForm onFormSubmitted={onFormSubmitted} />
            </div>
          </div>
        </>
      )}
      {currentStep === 2 && (
        <>
          <SuccessIcon className={styles.successIcon} />
          <div className={styles.main}>
            <Typography className={styles.title} variant="subtitle">
              {getTranslation('forgot_password_page.step_2_title')}
            </Typography>
            <Typography className={styles.text} variant="body2">
              {getTranslation('forgot_password_page.step_2_text_1')}{' '}
              <Typography
                className={styles.textEmail}
                variant="body2"
                weight="medium"
              >
                {currentEmail}.
              </Typography>{' '}
              {getTranslation('forgot_password_page.step_2_text_2')}
            </Typography>
            <Button className={styles.button} onClick={onBackClick} size="md">
              {getTranslation('forgot_password_page.back_login')}
            </Button>
          </div>
        </>
      )}
    </div>
    <SocialLinks
      className={styles.icons}
      iconWrapperClassName={styles.iconWrapper}
      iconClassName={styles.icon}
    />
    <Typography variant="body3" className={styles.helpText}>
      {getTranslation('forgot_password_page.help_text')}
    </Typography>
    <Link
      className={styles.helpLink}
      href="mailto:info@befront.io"
      variant="body3"
      mode="compact"
    >
      {getTranslation('forgot_password_page.help_link')}
    </Link>
  </div>
);

export default React.memo(ForgotPasswordPage);
