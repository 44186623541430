import createDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const DATE_PIPE = createDatePipe('dd/mm/yyyy');

export const MASK = [
  /[0-1]/,
  /[0-9]/,
  '/',
  /[0-3]/,
  /[0-9]/,
  '/',
  /[1-2]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
