import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import {
  getPropertyIcon,
  getPropertyValue,
} from './ComponentSetProperties.helpers';

import styles from './ComponentSetProperties.styles.scss';

const ComponentSetProperties = ({ properties }) => {
  const propertyDefinitions = getLayerPropertyByKey({
    key: 'componentPropertyDefinitions',
    properties,
  });

  return (
    <div className={styles.root}>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        Properties:
      </Typography>
      <div className={styles.properties}>
        {propertyDefinitions.value.map(
          (propertyDefinition, propertyDefinitionIndex) => (
            <div className={styles.property} key={propertyDefinitionIndex}>
              <Icon
                className={styles.icon}
                name={getPropertyIcon(propertyDefinition.type)}
              />
              <Typography
                className={styles.value}
                variant="body2"
                weight="medium"
                mode="compact"
              >
                {getPropertyValue({
                  type: propertyDefinition.type,
                  key: propertyDefinition.key,
                  variantOptions: propertyDefinition.variantOptions,
                })}
              </Typography>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default React.memo(ComponentSetProperties);
