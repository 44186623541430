import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';

import {
  isAnswersCorrect,
  randomizeLessonAnswers,
} from './QuizPreviewPage.helpers';

import QuizPreviewPage from './QuizPreviewPage';

const QuizPreviewPageContainer = () => {
  const questionsSwiperRef = useRef();

  const lesson = useSelector(currentLessonSelector);

  const [submitted, setSubmitted] = useState(false);
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [shuffledLesson, setShuffledLesson] = useState(
    randomizeLessonAnswers(lesson),
  );
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  useEffect(() => {
    if (questionsSwiperRef.current) {
      questionsSwiperRef.current.updateAutoHeight(0);
    }
  }, [currentAnswers]);

  const handleQuestionsSwiper = (swiper) => {
    questionsSwiperRef.current = swiper;
  };

  const handleCheckClick = () => {
    setSubmitted(true);
  };

  const handleAnswerClick = ({ answer, answersMultiple }) => {
    if (!answersMultiple) {
      setSubmitted(true);

      setCurrentAnswers([answer.id]);

      return;
    }

    if (currentAnswers.indexOf(answer.id) > -1) {
      setCurrentAnswers(
        currentAnswers.filter((answerId) => answerId !== answer.id),
      );
    } else {
      setCurrentAnswers([...currentAnswers, answer.id]);
    }
  };

  const handleTryAgainClick = () => {
    setCurrentAnswers([]);
    setSubmitted(false);
    setShuffledLesson(randomizeLessonAnswers(lesson));
  };

  const handleNextQuestionClick = () => {
    if (currentQuestion + 1 < lesson.questions.length) {
      setCurrentAnswers([]);
      setCurrentQuestion(currentQuestion + 1);
      setSubmitted(false);

      if (questionsSwiperRef.current) {
        questionsSwiperRef.current.slideNext();
      }
    }
  };

  const handleRestartLessonClick = () => {
    setCurrentAnswers([]);
    setCurrentQuestion(0);
    setSubmitted(false);

    if (questionsSwiperRef.current) {
      questionsSwiperRef.current.slideTo(0);
    }
  };

  const correct = isAnswersCorrect({
    questions: lesson.questions,
    currentAnswers,
    currentQuestionIndex: currentQuestion,
  });

  const handleChangePreviewType = (type) => {
    setPreviewType(type);
    handleRestartLessonClick();
  };

  return (
    <QuizPreviewPage
      correct={correct}
      submitted={submitted}
      questions={shuffledLesson.questions}
      currentQuestion={currentQuestion}
      currentAnswers={currentAnswers}
      onQuestionsSwiper={handleQuestionsSwiper}
      onCheckClick={handleCheckClick}
      onTryAgainClick={handleTryAgainClick}
      onRestartLessonClick={handleRestartLessonClick}
      onAnswerClick={handleAnswerClick}
      onNextQuestionClick={handleNextQuestionClick}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
      onChangePreviewType={handleChangePreviewType}
    />
  );
};

export default React.memo(QuizPreviewPageContainer);
