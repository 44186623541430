import React from 'react';
import cn from 'classnames';

import styles from './WebPreviewWrapper.styles.scss';

const WebPreviewWrapper = ({ children, isTheoryLesson, isCoursePreview }) => (
  <div
    className={cn(styles.theoryMain, {
      [styles.main]: !isTheoryLesson,
      [styles.courseMain]: isCoursePreview,
    })}
  >
    {children}
  </div>
);

export default React.memo(WebPreviewWrapper);
