import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { currentLessonSelector } from 'store/lesson/selectors';
import { currentCourseSelector } from 'store/course/selectors';

import {
  getMatchingPercent,
  getLessonsCount,
} from './ColorMatchLessonPreviewPage.helpers';

import ColorMatchLessonPage from './ColorMatchLessonPreviewPage';

const ColorMatchLessonPageContainer = (props) => {
  const course = useSelector(currentCourseSelector);
  const lesson = useSelector(currentLessonSelector);

  const imageRef = useRef();

  const [currentColor, setCurrentColor] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [matchPercent, setMatchPercent] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [previewType, setPreviewType] = useState('Plugin');
  const [isTestMode, setTestMode] = useState(false);

  const handleColorChange = ({ hex }) => {
    setCurrentColor(hex);
  };

  const handleCheckColorClick = () => {
    setSubmitted(true);

    const percent = getMatchingPercent(lesson.color, currentColor);

    setMatchPercent(percent);
  };

  console.log(lesson.imageUrl);
  console.log(currentImage);

  useEffect(() => {
    if (lesson) {
      fetch(lesson.imageUrl)
        .then((response) => response.text())
        .then((response) => {
          setCurrentImage(response);
        });
    }
  }, [lesson]);

  useEffect(() => {
    if (imageRef.current && currentColor) {
      const svgImage = imageRef.current.children[0];

      if (svgImage) {
        svgImage.style = `color: ${currentColor}`;
      }
    }
  }, [currentColor, currentImage, imageRef]);

  return (
    <ColorMatchLessonPage
      {...props}
      submitted={submitted}
      imageRef={imageRef}
      lesson={lesson}
      currentColor={currentColor}
      currentImage={currentImage}
      matchPercent={matchPercent}
      onColorChange={handleColorChange}
      onCheckColorClick={handleCheckColorClick}
      previewType={previewType}
      setPreviewType={setPreviewType}
      isTestMode={isTestMode}
      setTestMode={setTestMode}
      lessonTitle={lesson.title}
      currentLesson={getLessonsCount(course, lesson).lessonIndex}
      lessonsCount={getLessonsCount(course, lesson).totalLessons}
    />
  );
};

export default React.memo(ColorMatchLessonPageContainer);
