import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';
import { getPropertyNamePrefix } from 'helpers/getPropertyNamePrefix';

import { Typography } from 'components/shared/Typography';

import { FormikField } from 'components/FormikField';
import { CheckboxField } from 'components/FormikFields';

import { ColorProperty } from '../ColorProperty';
import { TextTextProperty } from '../TextTextProperty';
import { IconTextProperty } from '../IconTextProperty';
import { EmptyProperties } from '../EmptyProperties';

import { getFillValue } from './FillProperties.helpers';

import styles from './FillProperties.styles.scss';

const FillProperties = ({ properties, figmaFileImages }) => {
  const fills = getLayerPropertyByKey({ key: 'fills', properties });

  if (!fills || !fills.value || fills.value.length === 0) {
    return <EmptyProperties />;
  }

  return (
    <div className={styles.root}>
      {fills.value.map((fill, fillIndex) => (
        <div className={styles.item} key={fillIndex}>
          <Typography
            className={styles.itemTitle}
            variant="body2"
            weight="medium"
            mode="compact"
          >
            Fill {fillIndex + 1}
          </Typography>
          <div className={styles.itemInner}>
            <ColorProperty
              isInitialState
              readOnly
              property={{
                key: 'fillColor',
                valueType: fill.type,
                value: getFillValue({ fill, figmaFileImages }),
              }}
            />
            <TextTextProperty
              isInitialState
              readOnly
              property={{
                key: 'fillOpacity',
                value: fill.opacity,
              }}
            />
            <IconTextProperty
              isInitialState
              readOnly
              property={{
                key: 'fillBlendMode',
                value: fill.blendMode,
              }}
            />
            {fill.type === 'IMAGE' && (
              <>
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillScaleType',
                    value: fill.scaleMode,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillScalingFactor',
                    value: fill.scalingFactor,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillRotation',
                    value: fill.rotation,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillExposure',
                    value: fill.filters.exposure,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillContrast',
                    value: fill.filters.contrast,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillSaturation',
                    value: fill.filters.saturation,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillTemperature',
                    value: fill.filters.temperature,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillTint',
                    value: fill.filters.tint,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillHighlights',
                    value: fill.filters.highlights,
                  }}
                />
                <TextTextProperty
                  isInitialState
                  readOnly
                  property={{
                    key: 'fillShadows',
                    value: fill.filters.shadows,
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(FillProperties);
