const _formatGradientColor = (data) => ({
  type: data.type,
  blendMode: data.blendMode,
  opacity: data.opacity || 1,
  gradientTransform: [
    [6.123234262925839e-17, 1, 0],
    [-1, 6.123234262925839e-17, 1],
  ],
  gradientStops: data.gradientStops.map((gradientStop) => ({
    position: gradientStop.position,
    color: {
      r: gradientStop.color.r,
      g: gradientStop.color.g,
      b: gradientStop.color.b,
      a: Number(gradientStop.color.a.toFixed(2)),
    },
  })),
});

const _formatSolidColor = (data) => ({
  type: data.type,
  blendMode: data.blendMode,
  opacity: data.opacity || 1,
  visible: data.visible,
  color: {
    r: data.color.r,
    g: data.color.g,
    b: data.color.b,
  },
});

const _formatScaleMode = (scaleMode) => {
  switch (scaleMode) {
    case 'STRETCH': {
      return 'CROP';
    }
    default: {
      return scaleMode;
    }
  }
};

export const formatLayerColor = (data) => {
  switch (data.type) {
    case 'SOLID': {
      return _formatSolidColor(data);
    }
    case 'GRADIENT_LINEAR':
    case 'GRADIENT_RADIAL':
    case 'GRADIENT_ANGULAR':
    case 'GRADIENT_DIAMOND': {
      return _formatGradientColor(data);
    }
    case 'IMAGE': {
      return {
        type: data.type,
        blendMode: data.blendMode,
        opacity: data.opacity || 1,
        rotation: data.rotation || 0,
        filters: {
          contrast: data.filters?.contrast || 0,
          exposure: data.filters?.exposure || 0,
          highlights: data.filters?.highlights || 0,
          saturation: data.filters?.saturation || 0,
          shadows: data.filters?.shadows || 0,
          temperature: data.filters?.temperature || 0,
          tint: data.filters?.tint || 0,
        },
        scaleMode: _formatScaleMode(data.scaleMode),
        scalingFactor: data.scalingFactor || 1,
        imageHash: data.imageRef,
      };
    }
    case 'VIDEO': {
      return {
        type: data.type,
        blendMode: data.blendMode,
        scaleMode: data.scaleMode,
        opacity: data.opacity || 1,
        rotation: data.rotation || 0,
        scalingFactor: data.scalingFactor || 1,
        filters: {
          contrast: data.filters?.contrast || 0,
          exposure: data.filters?.exposure || 0,
          highlights: data.filters?.highlights || 0,
          saturation: data.filters?.saturation || 0,
          shadows: data.filters?.shadows || 0,
          temperature: data.filters?.temperature || 0,
          tint: data.filters?.tint || 0,
        },
        videoHash: data.videoRef,
      };
    }
    default: {
      console.warn('NEW COLOR TO CONFIGURE');

      return null;
    }
  }
};
