import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import * as api from 'api/methods';

import { FIGMA_TOKEN_KEY } from 'constants/token';

import { getFigmaFileId } from 'helpers/getFigmaFileId';

import { showToast } from 'helpers/showToast';
import { formatPages } from './FigmaFileSettings.helpers';
import { getTranslation } from 'helpers/getTranslation';

import { usePageFramesPreviews } from './FigmaFileSettings.hooks';

import FigmaFileSettings from './FigmaFileSettings';

const FigmaFileSettingsContainer = () => {
  const { values, setFieldValue } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const fileId = getFigmaFileId(values.fileUrl);

  const [loadingFrames] = usePageFramesPreviews({
    currentPages: pages,
    fileId,
    filePageId: values.filePageId,
    setPages,
  });

  const handleSyncClick = async () => {
    const figmaToken = localStorage.getItem(FIGMA_TOKEN_KEY);

    if (!figmaToken) {
      showToast({
        data: {
          variant: 'error',
          title: getTranslation('common.toast.forbiden_access.title'),
          text: getTranslation('common.toast.forbiden_access.text'),
        },
      });

      return;
    }

    setPages([]);
    setLoading(true);

    try {
      const fileData = await api.getFigmaFile(fileId);

      const filePages = formatPages(fileData.document.children);

      setPages(filePages);

      if (filePages.length) {
        setFieldValue('fileId', fileId);
        setFieldValue('filePageId', filePages[0]?.id);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.warn(error);
    }
  };

  const currentPage = pages.find((page) => page.id === values.filePageId);

  return (
    <FigmaFileSettings
      loading={loading}
      loadingFrames={loadingFrames}
      pages={pages}
      currentPage={currentPage}
      onSyncClick={handleSyncClick}
    />
  );
};

export default React.memo(FigmaFileSettingsContainer);
