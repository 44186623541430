import React from 'react';

import { Modal } from 'components/shared/Modal';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';

import styles from './DefaultModal.styles.scss';
import { getTranslation } from '../../helpers/getTranslation';

const DefaultModal = ({
  open,
  loading,
  data,
  title,
  text,
  confirmText,
  cancelText,
  onConfirmClick,
  onCancelClick,
  onClose,
}) => (
  <Modal className={styles.modal} open={open} centered onClose={onClose}>
    {title && (
      <Typography className={styles.title} variant="subtitle">
        {title}
      </Typography>
    )}
    {text && (
      <Typography className={styles.text} variant="body2">
        {text}
      </Typography>
    )}
    <div className={styles.actions}>
      <Button variant="outlined" size="md" onClick={onCancelClick}>
        {cancelText}
      </Button>
      <Button
        disabled={loading}
        className={styles.confirmAction}
        size="md"
        startIcon={
          loading && <Loading size={18} color="var(--color-white-primary)" />
        }
        onClick={() => onConfirmClick(data)}
      >
        {confirmText}
      </Button>
    </div>
  </Modal>
);

DefaultModal.defaultProps = {
  confirmText: getTranslation('common.button.save'),
  cancelText: getTranslation('common.button.cancel'),
};

export default React.memo(DefaultModal);
