import React from 'react';

import { getLayerPropertyByKey } from 'helpers/getLayerPropertyByKey';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import {
  getPropertyIcon,
  getPropertyValue,
} from './ComponentInstanceProperties.helpers';

import styles from './ComponentInstanceProperties.styles.scss';

const ComponentInstanceProperties = ({ properties }) => {
  const componentProperties = getLayerPropertyByKey({
    key: 'componentProperties',
    properties,
  });

  return (
    <div className={styles.root}>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        Properties:
      </Typography>
      <div className={styles.properties}>
        {componentProperties.value.map((property, propertyIndex) => (
          <div className={styles.property} key={propertyIndex}>
            <Icon
              className={styles.icon}
              name={getPropertyIcon(property.type)}
            />
            <Typography
              className={styles.label}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {property.key}:
            </Typography>
            <Typography
              className={styles.value}
              variant="body2"
              weight="medium"
              mode="compact"
            >
              {getPropertyValue(property)}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ComponentInstanceProperties);
