import React from 'react';

import { Input } from 'components/shared/Input';
import { Icon } from 'components/shared/Icon';

import styles from './PasswordInput.styles.scss';

const PasswordInput = ({ valueVisible, onEyeIconClick, ...restProps }) => (
  <Input
    {...restProps}
    type={valueVisible ? 'text' : 'password'}
    endAdornment={
      valueVisible ? (
        <Icon className={styles.icon} name="eyeOff" onClick={onEyeIconClick} />
      ) : (
        <Icon className={styles.icon} name="eyeOn" onClick={onEyeIconClick} />
      )
    }
  />
);

export default React.memo(PasswordInput);
