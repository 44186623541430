import React from 'react';

import { UserCard } from 'components/UserCard';
import { MainLayout } from 'components/Layout';

const ProfilePage = ({ user }) => (
  <MainLayout>
    <UserCard {...user} withStatus={false} />
  </MainLayout>
);

export default React.memo(ProfilePage);
