import _orderBy from 'lodash/orderBy';

import { getTranslation } from 'helpers/getTranslation';

const _orderLayersToApi = (layers) =>
  _orderBy(
    layers,
    [
      (layer) => {
        const layerOrderProperty = layer.properties.find(
          (property) => property.key === 'layerOrder',
        );

        return layerOrderProperty.value;
      },
    ],
    ['desc'],
  );

const _formatProperty = (property) => {
  switch (property.key) {
    case 'innerRadius': {
      return Number(property.value);
    }
    case 'pointCount': {
      return Number(property.value);
    }
    case 'opacity': {
      return Number(property.value.toFixed(2));
    }
    default: {
      return property.value;
    }
  }
};

const _formatLayer = (layer) => {
  return {
    id: layer.id,
    name: layer.name,
    type: layer.type,
    checkProperties: layer.properties
      .filter((property) => property.check)
      .map((property) => property.key),
    properties: layer.properties.reduce((obj, property) => {
      obj[property.key] = _formatProperty(property);

      return obj;
    }, {}),
    options: layer.options,
    children:
      layer.children && layer.children.length
        ? _orderLayersToApi(layer.children).map((childLayer) =>
            _formatLayer(childLayer),
          )
        : [],
  };
};

const _formatComponentsLayer = (layer) => {
  return {
    id: layer.id,
    name: layer.name,
    type: layer.type,
    properties: layer.properties.reduce((obj, property) => {
      obj[property.key] = _formatProperty(property);

      return obj;
    }, {}),
    children:
      layer.children && layer.children.length
        ? layer.children.map((childLayer) => _formatComponentsLayer(childLayer))
        : [],
  };
};

const _formatTask = (task) => ({
  id: task.id,
  name: task.name,
  text: task.text,
  preview: task.preview,
  // theoryBlocks: task.theoryBlocks,
  hintsBlocks: task.hintsBlocks,
  materials: task.materials,
  layers: _orderLayersToApi(task.layers).map((layer) => _formatLayer(layer)),
});

export const convertDataToApi = (data) => {
  return {
    title: data.title || getTranslation('common.text.untitled'),
    free: data.free,
    fileId: data.fileId,
    filePageId: data.filePageId,
    designType: data.designType,
    goodToKnowBlocks: data.goodToKnowBlocks,
    tasks: data.tasks.map((task) => _formatTask(task)),
    initialState: data.initialState
      ? {
          preview: data.initialState.preview,
          componentsLayer: data.initialState.componentsLayer
            ? _formatComponentsLayer(data.initialState.componentsLayer)
            : null,
          layers: _orderLayersToApi(data.initialState.layers).map((layer) =>
            _formatLayer(layer),
          ),
        }
      : null,
  };
};
