import React from 'react';

import { Checkbox } from 'components/shared/Checkbox';

import { getPropertiesByCategory } from '../CategorySection/CategorySection.helpers';

import { getCheckValue, getIconNameByValue } from './CategoryCheckbox.helpers';

const CategoryCheckbox = ({
  readOnly,
  categoryProperties,
  properties,
  categoryKey,
  onChanged,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (readOnly) {
      return;
    }

    if (onChanged) {
      onChanged(categoryKey, event.target.checked);
    }
  };

  const propertiesByCategory = getPropertiesByCategory({
    properties,
    categoryKey,
    categoryProperties,
  });
  const checked = getCheckValue(propertiesByCategory);

  return (
    <Checkbox
      {...restProps}
      checked={checked === 'checked' || checked === 'partiallyChecked'}
      icon={getIconNameByValue(checked)}
      onChange={handleChange}
    />
  );
};

export default React.memo(CategoryCheckbox);
