import { createSlice } from '@reduxjs/toolkit';

import { getLessonReports } from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: null,
  perPage: 50,
};

const lessonReportsSlice = createSlice({
  name: 'lessonReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonReports.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});

export default lessonReportsSlice.reducer;
