import React from 'react';
import _upperFirst from 'lodash/upperFirst';

import { formatDate } from 'helpers/formatDate';
import { getStatusText } from 'helpers/getStatusText';
import { getTranslation } from 'helpers/getTranslation';
import { getSchoolStatusVariant } from 'helpers/getSchoolStatusVariant';
import { orderItemsByUpdatedAt } from 'helpers/orderItemsByUpdatedAt';

import { Table } from 'components/shared/Table';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

import { MenuLayout } from 'components/Layout';
import { EmptyState } from 'components/EmptyState';
import { ListToolbar } from 'components/Toolbar';
import { StatusTag } from 'components/StatusTag';
import { Tooltip } from 'components/Tooltip';
import { CoursePreview } from 'components/CoursePreview';
import { SchoolPreview } from 'components/SchoolPreview';
import { MoreItemsButton } from 'components/MoreItemsButton';

import { SCHOOLS_TABLE_COLUMNS } from './SchoolsPage.tableConfig';

import styles from './SchoolsPage.styles.scss';

const cellRenderer = ({ item, key, minWidth, maxWidth }, DefaultCell) => {
  switch (key) {
    case 'name': {
      return (
        <div
          className={styles.nameCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <SchoolPreview preview={item.preview} name={item.name} />
          <Typography
            className={styles.name}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {item[key]}
          </Typography>
        </div>
      );
    }
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {formatDate(item[key], 'dd.MM.yyyy')}
        </DefaultCell>
      );
    }
    case 'courses': {
      const courses = item.courses;
      const visibleCourses = courses.slice(0, 3);
      const hiddenCourses = courses.slice(3, courses.length);

      return (
        <div
          className={styles.coursesCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          {courses && courses.length ? (
            <>
              {visibleCourses.map((course) => (
                <Tooltip
                  key={course.id}
                  text={course.title || 'Untitled'}
                  triggerClassName={styles.courseTrigger}
                  triggerElement={
                    <CoursePreview
                      className={styles.coursePreview}
                      preview={course.preview}
                    />
                  }
                />
              ))}
              {courses.length > 3 && (
                <MoreItemsButton
                  className={styles.coursesMoreBtn}
                  moreCount={hiddenCourses.length}
                  item={item}
                  courses={courses}
                />
              )}
            </>
          ) : (
            '-'
          )}
        </div>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <StatusTag variant={getSchoolStatusVariant(item[key])}>
            {getStatusText(item[key])}
          </StatusTag>
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const SchoolsPage = ({
  loading,
  schools,
  onCreateSchoolClick,
  onSchoolRowClick,
  onSchoolEditClick,
}) => {
  return (
    <MenuLayout>
      <ListToolbar
        title={getTranslation('schools_page.title')}
        actionComponent={
          <Button
            size="md"
            variant="link"
            startIcon={<Icon name="plusRound" />}
            onClick={onCreateSchoolClick}
          >
            {getTranslation('common.button.create_school')}
          </Button>
        }
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {schools.length ? (
            <div className={styles.inner}>
              <Table
                columns={SCHOOLS_TABLE_COLUMNS}
                actions={[
                  {
                    key: 'edit',
                    icon: 'edit',
                    tooltipText: getTranslation('schools_page.tooltip'),
                    onClick: onSchoolEditClick,
                  },
                ]}
                data={orderItemsByUpdatedAt(schools)}
                cellRenderer={(props, DefaultCell) =>
                  cellRenderer(props, DefaultCell)
                }
                onRowClick={(school) => onSchoolRowClick(school.id)}
              />
            </div>
          ) : (
            <EmptyState
              className={styles.emptyState}
              icon="schools"
              title={getTranslation('schools_page.empty_state.title')}
              text={getTranslation('schools_page.empty_state.text')}
            />
          )}
        </>
      )}
    </MenuLayout>
  );
};

export default React.memo(SchoolsPage);
