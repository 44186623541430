import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { enUS, ru } from 'date-fns/locale';

import { formatDate } from 'helpers/formatDate';
import { getStatusText } from 'helpers/getStatusText';
import { getTranslation } from 'helpers/getTranslation';
import { getUserStatusVariant } from 'helpers/getUserStatusVariant';

import { Icon } from 'components/shared/Icon';
import { Table } from 'components/shared/Table';
import { Avatar } from 'components/shared/Avatar';
import { Typography } from 'components/shared/Typography';

import { Tooltip } from 'components/Tooltip';
import { StatusTag } from 'components/StatusTag';
import { CoursePreview } from 'components/CoursePreview';
import { TablePagination } from 'components/TablePagination';
import { SchoolPreview } from 'components/SchoolPreview';
import { MoreItemsButton } from 'components/MoreItemsButton';

import styles from './StudentsTable.styles.scss';

const cellRenderer = (
  { item, key, minWidth, maxWidth },
  DefaultCell,
  { onCopyEmailClick },
  locale,
) => {
  switch (key) {
    case 'name': {
      return (
        <div
          className={styles.nameCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Avatar size="sm" text={item.name || item.email} />
          <Typography
            className={styles.name}
            variant="body3"
            weight="medium"
            mode="compact"
          >
            {item[key] || '-'}
          </Typography>
        </div>
      );
    }
    case 'email': {
      return (
        <div
          className={styles.nameCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <Typography className={styles.email} variant="body3" mode="compact">
            {item[key] || '-'}
          </Typography>
          <Icon
            className={styles.copyIcon}
            name="copy"
            onClick={(event) => {
              event.stopPropagation();
              onCopyEmailClick(item[key]);
            }}
          />
        </div>
      );
    }
    case 'createdAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {formatDate(item[key], 'dd MMM, yyyy', locale)}
        </DefaultCell>
      );
    }
    case 'updatedAt': {
      return (
        <DefaultCell key={key} style={{ minWidth, maxWidth }}>
          {item[key]
            ? formatDistance(parseISO(item[key]), new Date(), {
                addSuffix: true,
                locale: locale === 'ru' ? ru : enUS,
              })
            : '-'}
        </DefaultCell>
      );
    }
    case 'schools': {
      const schools = item.schools;

      return (
        <div
          className={styles.schoolCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          {schools.map((school) => (
            <Tooltip
              key={school.id}
              text={school.name}
              triggerClassName={styles.schoolTrigger}
              triggerElement={
                <SchoolPreview preview={school.preview} name={school.name} />
              }
            />
          ))}
        </div>
      );
    }
    case 'courses': {
      const courses = item.courses;
      const visibleCourses = courses.slice(0, 3);
      const hiddenCourses = courses.slice(3, courses.length);

      return (
        <div
          className={styles.coursesCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          {courses && courses.length ? (
            <>
              {visibleCourses.map((course) => (
                <Tooltip
                  key={course.id}
                  text={course.title || getTranslation('common.text.untitled')}
                  triggerClassName={styles.courseTrigger}
                  triggerElement={
                    <CoursePreview
                      className={styles.coursePreview}
                      preview={course.preview}
                    />
                  }
                />
              ))}
              {courses.length > 3 && (
                <MoreItemsButton
                  className={styles.coursesMoreBtn}
                  moreCount={hiddenCourses.length}
                  item={item}
                  courses={courses}
                />
              )}
            </>
          ) : (
            '-'
          )}
        </div>
      );
    }
    case 'status': {
      return (
        <div
          className={styles.statusCell}
          key={key}
          style={{ minWidth, maxWidth }}
        >
          <StatusTag variant={getUserStatusVariant(item[key])}>
            {getStatusText(item[key])}
          </StatusTag>
        </div>
      );
    }
    default: {
      return (
        <DefaultCell key={key} minWidth={minWidth} maxWidth={maxWidth}>
          {item[key] || '-'}
        </DefaultCell>
      );
    }
  }
};

const StudentsTable = ({
  locale,
  className,
  columns,
  data,
  params,
  currentPage,
  totalPages,
  perPage,
  onSortChange,
  onPerPageChange,
  onNextPageClick,
  onPrevPageClick,
  onEditStudentClick,
  onRemoveStudentClick,
  onStudentRowClick,
  onCopyEmailClick,
}) => (
  <>
    <Table
      className={className}
      columns={columns}
      actions={[
        {
          key: 'edit',
          icon: 'edit',
          tooltipText: getTranslation('components.students_table.edit_tooltip'),
          onClick: onEditStudentClick,
        },
        {
          key: 'remove',
          icon: 'trash',
          tooltipText: getTranslation(
            'components.students_table.remove_tooltip',
          ),
          onClick: onRemoveStudentClick,
        },
      ]}
      data={data}
      params={params}
      cellRenderer={(props, DefaultCell) =>
        cellRenderer(props, DefaultCell, { onCopyEmailClick }, locale)
      }
      onSortChange={onSortChange}
      onRowClick={(student) => onStudentRowClick(student.id)}
    />
    <TablePagination
      currentPage={currentPage}
      perPage={perPage}
      totalPages={totalPages}
      onSortChange={onSortChange}
      onPerPageChange={onPerPageChange}
      onNextPageClick={onNextPageClick}
      onPrevPageClick={onPrevPageClick}
    />
  </>
);

export default React.memo(StudentsTable);
