import React from 'react';

import { FIGMA_LAYER_TYPES } from 'constants/common';

import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { FormikField } from 'components/FormikField';
import { CheckboxField } from 'components/FormikFields';

import styles from './OptionsSection.styles.scss';

const OptionsSection = ({ readOnly, namePrefix, layerType }) => (
  <div className={styles.section}>
    {layerType === FIGMA_LAYER_TYPES.TEXT ? (
      <div className={styles.option}>
        <FormikField
          name={`${namePrefix}.options.copy`}
          withControl={false}
          component={CheckboxField}
          componentProps={{
            className: styles.optionCheck,
            type: 'checkbox',
            readOnly,
          }}
        />
        <Typography
          className={styles.optionText}
          variant="body2"
          weight="medium"
          mode="compact"
        >
          Copy text
        </Typography>
      </div>
    ) : (
      <div className={styles.empty}>
        <Icon className={styles.emptyIcon} name="stop" />
        <Typography
          className={styles.emptyText}
          variant="body1"
          weight="medium"
        >
          No available options
        </Typography>
      </div>
    )}
  </div>
);

export default React.memo(OptionsSection);
