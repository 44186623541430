import { createSlice } from '@reduxjs/toolkit';

import {
  getCourses,
  getSchoolCourses,
  updateCourseById,
  removeCourseById,
} from './actions';

const initialState = {
  data: [],
  currentPage: 1,
  totalPages: null,
  perPage: 10,
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCourses.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(getSchoolCourses.fulfilled, (_, action) => {
      return action.payload;
    });

    builder.addCase(updateCourseById.fulfilled, (state, action) => {
      const { fieldName, updatedCourse } = action.payload;

      return {
        ...state,
        data: state.data.map((course) => {
          if (course.id === updatedCourse.id) {
            return {
              ...course,
              [fieldName]: updatedCourse[fieldName],
            };
          }

          return course;
        }),
      };
    });

    builder.addCase(removeCourseById, (state, action) => {
      return {
        ...state,
        data: state.data.filter((course) => course.id !== action.payload),
      };
    });
  },
});

export default coursesSlice.reducer;
