import { getTranslation } from 'helpers/getTranslation';

export const STUDENTS_TABLE_COLUMNS = [
  {
    key: 'name',
    label: getTranslation('students_page.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('students_page.table_keys.email'),
    sortable: true,
  },
  {
    key: 'createdAt',
    label: getTranslation('students_page.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'courses',
    label: getTranslation('students_page.table_keys.courses'),
    sortable: false,
  },
  {
    key: 'status',
    label: getTranslation('students_page.table_keys.status'),
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: getTranslation('students_page.table_keys.updated_at'),
    sortable: true,
  },
];
export const STUDENTS_TABLE_COLUMNS_ROOT_ADMIN = [
  {
    key: 'name',
    label: getTranslation('students_page.table_keys.name'),
    sortable: true,
  },
  {
    key: 'email',
    label: getTranslation('students_page.table_keys.email'),
    sortable: true,
  },
  {
    key: 'schools',
    label: getTranslation('students_page.table_keys.schools'),
    sortable: false,
  },
  {
    key: 'createdAt',
    label: getTranslation('students_page.table_keys.created_at'),
    sortable: true,
  },
  {
    key: 'updatedAt',
    label: getTranslation('students_page.table_keys.updated_at'),
    sortable: true,
  },
];
