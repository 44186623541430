const _getFontWeightByNumber = ({ fontFamily, fontWeight }) => {
  switch (fontWeight) {
    case 100: {
      return 'Thin';
    }
    case 200: {
      return _checkFontFamily(fontFamily) ? 'Extra Light' : 'ExtraLight';
    }
    case 300: {
      return 'Light';
    }
    case 400: {
      return 'Regular';
    }
    case 500: {
      return 'Medium';
    }
    case 600: {
      return _checkFontFamily(fontFamily) ? 'Semi Bold' : 'SemiBold';
    }
    case 700: {
      return 'Bold';
    }
    case 800: {
      return _checkFontFamily(fontFamily) ? 'Extra Bold' : 'ExtraBold';
    }
    case 900: {
      return 'Black';
    }
  }
};

const _checkFontFamily = (fontFamily) => fontFamily === 'Inter';

export const getFontStyle = ({ fontWeight, fontFamily, italic }) => {
  if (fontWeight === 400 && italic) {
    return 'Italic';
  }

  if (fontWeight !== 400 && italic) {
    return `${_getFontWeightByNumber({ fontFamily, fontWeight })} Italic`;
  }

  if (!italic) {
    return _getFontWeightByNumber({ fontFamily, fontWeight });
  }
};
