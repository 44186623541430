import React from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';

import * as api from 'api/methods';

import { getTranslation } from 'helpers/getTranslation';

import { useModal } from 'hooks/useModal';
import { useChangeOrder } from 'hooks/useChangeOrder';

import { DefaultModal } from 'components/DefaultModal';

import Answers from './Answers';

const AnswersContainer = () => {
  const { values, setFieldValue } = useFormikContext();

  const [removeModalOpen, openRemoveModal, closeRemoveModal, removeModalData] =
    useModal({
      defaultOpen: false,
    });

  const _handleModuleDragEnd = () => {};

  const {
    sensors,
    activeDragItem,
    onItemDragStart,
    onItemDragEnd,
    onItemDragOver,
  } = useChangeOrder({
    withOrder: false,
    items: values.answers,
    namePrefix: 'answers',
    onItemDragEnd: _handleModuleDragEnd,
  });

  const handleAddAnswerClick = () => {
    setFieldValue('answers', [
      ...values.answers,
      { id: uuid(), leftContent: '', rightContent: '' },
    ]);
  };

  const handleRemoveAnswerClick = (answer) => {
    openRemoveModal({
      data: answer,
      title: getTranslation('connection_quiz_page.answers.remove_modal.title'),
      text: getTranslation('connection_quiz_page.answers.remove_modal.text'),
    });
  };

  const handleConfirmRemoveClick = async (answer) => {
    setFieldValue(
      'answers',
      values.answers.filter((currentAnswer) => currentAnswer.id !== answer.id),
    );

    if (answer.leftContent.indexOf('/s3/') > -1) {
      await api.removeFile(answer.leftContent.substring(1));
    }

    if (answer.rightContent.indexOf('/s3/') > -1) {
      await api.removeFile(answer.rightContent.substring(1));
    }

    closeRemoveModal();
  };

  const activeDragAnswerIndex = activeDragItem
    ? values.answers.findIndex((answer) => answer.id === activeDragItem.id)
    : -1;

  return (
    <>
      <Answers
        readOnly={values.readOnly}
        answers={values.answers}
        answersType={values.answersType}
        sensors={sensors}
        activeDragAnswer={activeDragItem}
        activeDragAnswerIndex={activeDragAnswerIndex}
        onAnswerDragStart={onItemDragStart}
        onAnswerDragEnd={onItemDragEnd}
        onAnswerDragOver={onItemDragOver}
        onAddAnswerClick={handleAddAnswerClick}
        onRemoveAnswerClick={handleRemoveAnswerClick}
      />
      <DefaultModal
        {...removeModalData}
        open={removeModalOpen}
        confirmText={getTranslation(
          'connection_quiz_page.answers.remove_modal.confirm_text',
        )}
        onConfirmClick={handleConfirmRemoveClick}
        onCancelClick={closeRemoveModal}
        onClose={closeRemoveModal}
      />
    </>
  );
};

export default React.memo(AnswersContainer);
