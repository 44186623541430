import axios from 'axios';
import qs from 'qs';

export const getSchools = () => axios.get('/api/v1/schools');

export const createSchool = (data) => axios.post('/api/v1/schools', data);

export const updateSchoolById = ({ schoolId, data }) =>
  axios.put(`/api/v1/schools/${schoolId}`, data);

export const getSchoolById = (schoolId) =>
  axios.get(`/api/v1/schools/${schoolId}`);

export const getSchoolStudents = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/students?${qs.stringify(params)}`);

export const getSchoolAdmins = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/admins?${qs.stringify(params)}`);

export const getSchoolCourses = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/courses?${qs.stringify(params)}`);

export const getSchoolArcades = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/arcades?${qs.stringify(params)}`);

export const getSchoolTrainings = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/trainings?${qs.stringify(params)}`);

export const getSchoolLessonRatings = ({ schoolId, params }) =>
  axios.get(`/api/v1/schools/${schoolId}/lesson-rates?${qs.stringify(params)}`);

export const getSchoolLessonReports = ({ schoolId, params }) =>
  axios.get(
    `/api/v1/schools/${schoolId}/lesson-reports?${qs.stringify(params)}`,
  );

export const getStudentsActivityReport = (schoolId) =>
  axios.post(
    `/api/v1/schools/${schoolId}/students/activity-report`,
    {},
    {
      responseType: 'blob',
    },
  );
