import { getTranslation } from 'helpers/getTranslation';

export const getAdminsTableColumns = () => [
  {
    key: 'row',
    label: getTranslation('components.import_admins.table_rows.row'),
    minWidth: '68px',
    maxWidth: '68px',
  },
  {
    key: 'admin',
    label: getTranslation('components.import_admins.table_rows.admin'),
  },
];
