import { uniq as _uniq } from 'lodash';

import { getTaskMaterials } from './getTaskMaterials';

export const getTasksMaterials = ({ fileImages, tasks, initialState }) => {
  const tasksImages = [];

  tasksImages.push(
    ...getTaskMaterials({ fileImages, taskLayers: initialState.layers }),
  );

  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];

    tasksImages.push(
      ...getTaskMaterials({ fileImages, taskLayers: task.layers }),
    );
  }

  return fileImages.filter((fileImage) =>
    _uniq(tasksImages).some(
      (taskImage) => taskImage.imageRef === fileImage.imageRef,
    ),
  );
};
