import React from 'react';

import { Tabs } from 'components/shared/Tabs';
import { Loading } from 'components/shared/Loading';

import { SchoolCard } from 'components/SchoolCard';
import { MainLayout } from 'components/Layout';
import { BreadcrumbsToolbar } from 'components/Toolbar';

import { getTabActions, getTabsItems } from './SchoolPage.helpers';

import styles from './SchoolPage.styles.scss';

const SchoolPage = ({
  locale,
  loading,
  isRootAdmin,
  currentSchool,
  currentTab,
  onTabChange,
  onEditClick,
  onAddStudentContextItemClick,
  onAddAdminContextItemClick,
  onAddCourseContextItemClick,
  onAddTrainingContextItemClick,
  onAddArcadeContextItemClick,
  onGenerateCodeClick,
  onExportCodesClick,
  onActivityReportClick,
}) => (
  <MainLayout
    toolbarComponent={
      isRootAdmin && (
        <BreadcrumbsToolbar
          breadcrumbsData={{
            schoolName: currentSchool?.name,
          }}
        />
      )
    }
  >
    {loading ? (
      <Loading />
    ) : (
      <>
        <SchoolCard
          {...currentSchool}
          locale={locale}
          withStatus={isRootAdmin}
          withEdit={isRootAdmin}
          onEditClick={onEditClick}
        />
        <div className={styles.inner}>
          <Tabs
            className={styles.tabs}
            items={getTabsItems(isRootAdmin)}
            actions={getTabActions({
              currentTab,
              isRootAdmin,
              onAddStudentContextItemClick,
              onAddAdminContextItemClick,
              onAddCourseContextItemClick,
              onAddArcadeContextItemClick,
              onAddTrainingContextItemClick,
              onGenerateCodeClick,
              onExportCodesClick,
              onActivityReportClick,
            })}
            onTabChange={onTabChange}
          />
        </div>
      </>
    )}
  </MainLayout>
);

export default React.memo(SchoolPage);
