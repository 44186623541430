import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './MoreItemsButton.styles.scss';

const MoreItemsButton = ({ className, moreCount, onClick }) => (
  <div className={cn(styles.button, className)} onClick={onClick}>
    <Typography
      className={styles.text}
      variant="body3"
      weight="medium"
      mode="compact"
    >
      +{moreCount}
    </Typography>
  </div>
);

export default React.memo(MoreItemsButton);
