import React from 'react';
import cn from 'classnames';

import { Sidebar } from 'components/Sidebar';

import styles from './MainLayout.styles.scss';

const MainLayout = ({ className, children, toolbarComponent }) => (
  <div className={cn(styles.layout, className)}>
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.inner}>
        <div className={styles.main}>
          {toolbarComponent}
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default React.memo(MainLayout);
