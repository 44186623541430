export const getLineHeightValue = (style) => {
  switch (style.lineHeightUnit) {
    case 'FONT_SIZE_%': {
      return style.lineHeightPercentFontSize;
    }
    case 'INTRINSIC_%': {
      return 100;
    }
    case 'PIXELS': {
      return style.lineHeightPx;
    }
  }
};
