import React from 'react';
import parseISO from 'date-fns/parseISO';
import formatDistance from 'date-fns/formatDistance';
import { enUS, ru } from 'date-fns/locale';
import cn from 'classnames';

import { STATUSES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Button } from 'components/shared/Button';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';
import { Icon } from 'components/shared/Icon';

import { StatusSelect } from 'components/StatusSelect';
import { Popover } from 'components/Popover';
import { ContextMenu } from 'components/ContextMenu';

import styles from './ToolbarActions.styles.scss';

const ToolbarActions = ({
  locale,
  autoSaveLoading,
  saveLoading,
  isRootAdmin,
  withRemove,
  contextItems,
  moreContextItems,
  removeData,
  updatedAt,
  status,
  onPreviewClick,
  onSaveClick,
  onRemoveClick,
  onMoreContextItemClick,
  onStatusContextItemClick,
}) => (
  <div className={styles.actions}>
    {autoSaveLoading && (
      <div className={styles.autosaveLoading}>
        <Loading />
        <Typography
          variant="body2"
          mode="compact"
          className={styles.autosaveText}
        >
          {getTranslation('components.toolbar_actions.saving')}
        </Typography>
      </div>
    )}
    {updatedAt && (
      <Typography
        className={styles.lastUpdated}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation('components.toolbar_actions.last_save')}{' '}
        {formatDistance(new Date(), parseISO(updatedAt), {
          locale: locale === 'ru' ? ru : enUS,
        })}{' '}
        {getTranslation('components.toolbar_actions.ago')}
      </Typography>
    )}
    <StatusSelect
      className={styles.statusSelect}
      contextItems={contextItems}
      status={status.toLowerCase()}
      onContextItemClick={onStatusContextItemClick}
    />
    <div className={styles.buttons}>
      {withRemove && status !== STATUSES.PUBLISHED && (
        <Button
          variant="outlined"
          size="md"
          mode="icon"
          iconName="trash"
          iconColor="#1182F6"
          onClick={() => onRemoveClick(removeData)}
        />
      )}
      <Button
        className={styles.button}
        variant="outlined"
        size="md"
        onClick={onPreviewClick}
      >
        {getTranslation('common.button.preview')}
      </Button>
      {status === STATUSES.DRAFT && (
        <Button
          className={styles.button}
          variant="outlined"
          size="md"
          startIcon={saveLoading && <Loading size={18} />}
          onClick={onSaveClick}
        >
          {getTranslation('common.button.save')}
        </Button>
      )}
      {isRootAdmin && moreContextItems && moreContextItems.length > 0 && (
        <Popover
          placement="bottom-end"
          triggerElement={({ isMounted }) => (
            <Button
              className={cn(styles.moreBtn, {
                [styles.moreBtnActive]: isMounted,
              })}
              variant="ghost"
              mode="icon"
              size="md"
              iconName="more"
            />
          )}
        >
          <ContextMenu
            className={styles.moreMenu}
            items={moreContextItems}
            onItemClick={(contextItem) => onMoreContextItemClick(contextItem)}
          />
        </Popover>
      )}
    </div>
  </div>
);

export default React.memo(ToolbarActions);
