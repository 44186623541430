import { COURSE_STRUCTURES } from 'constants/common';

export const getInitialTableData = ({ courseStructure, courseStats }) => {
  if (courseStructure === COURSE_STRUCTURES.LESSONS) {
    return courseStats.lessonsProgress.map((lesson) => ({
      ...lesson,
      cellType: 'LESSON',
    }));
  }

  if (courseStructure === COURSE_STRUCTURES.MODULES) {
    return courseStats.modulesProgress.map((module) => ({
      ...module,
      lessonsCount: courseStats.lessonsProgress.filter(
        (lesson) => lesson.moduleId === module.id,
      ).length,
      cellType: 'MODULE',
      expanded: false,
    }));
  }

  return null;
};
