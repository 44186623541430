import _isEqual from 'lodash/isEqual';

export const convertDataToForm = (data, readOnly) => ({
  id: data.id,
  moduleId: data.moduleId,
  courseId: data.courseId,
  status: data.status,
  title: data.title,
  text: data.text,
  type: data.type,
  answers: data.answers,
  answersType: data.answersType,
  free: data.free,
  readOnly,
});

export const convertDataToApi = (data) => ({
  title: data.title,
  text: data.text,
  answersType: data.answersType,
  answers: data.answers,
  free: data.free,
});

export const getChangedProperties = ({ currentLesson, nextLesson }) => {
  const properties = [];

  if (currentLesson.title !== nextLesson.title) {
    properties.push('title');
  }

  if (currentLesson.text !== nextLesson.text) {
    properties.push('text');
  }

  if (currentLesson.answersType !== nextLesson.answersType) {
    properties.push('answersType');
  }

  if (currentLesson.free !== nextLesson.free) {
    properties.push('free');
  }

  if (!_isEqual(currentLesson.answers, nextLesson.answers)) {
    properties.push('answers');
  }

  return properties;
};

export const checkAnswersValid = (lesson) =>
  lesson.answers.every(
    (answer) => answer.leftContent !== '' && answer.rightContent !== '',
  );
