import React from 'react';

import { Loading } from 'components/shared/Loading';

import styles from './FigmaAuthPage.styles.scss';

const FigmaAuthPage = () => (
  <div className={styles.page}>
    <Loading className={styles.loading} />
  </div>
);

export default React.memo(FigmaAuthPage);
