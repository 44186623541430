import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { LessonPreviewLayout } from 'components/Layout';
import { PluginPreviewPage } from './components/PluginPreviewPage';
import { WebPreviewPage } from './components/WebPreviewPage';

const QuizPreviewPage = ({
  correct,
  submitted,
  questions,
  currentAnswers,
  currentQuestion,
  onQuestionsSwiper,
  onAnswerClick,
  onNextQuestionClick,
  onTryAgainClick,
  onCheckClick,
  onRestartLessonClick,
  previewType,
  setPreviewType,
  isTestMode,
  setTestMode,
  onChangePreviewType,
  ...props
}) => {
  return (
    <LessonPreviewLayout
      setPreviewType={onChangePreviewType}
      setTestMode={setTestMode}
    >
      {previewType === 'Plugin' ? (
        <PluginPreviewPage
          {...props}
          correct={correct}
          submitted={submitted}
          questions={questions}
          currentQuestion={currentQuestion}
          currentAnswers={currentAnswers}
          onQuestionsSwiper={onQuestionsSwiper}
          onCheckClick={onCheckClick}
          onAnswerClick={onAnswerClick}
          onTryAgainClick={onTryAgainClick}
          onNextQuestionClick={onNextQuestionClick}
          onRestartLessonClick={onRestartLessonClick}
          previewType={previewType}
          isTestMode={isTestMode}
        />
      ) : (
        <WebPreviewPage
          {...props}
          correct={correct}
          submitted={submitted}
          questions={questions}
          currentQuestion={currentQuestion}
          currentAnswers={currentAnswers}
          onQuestionsSwiper={onQuestionsSwiper}
          onCheckClick={onCheckClick}
          onAnswerClick={onAnswerClick}
          onTryAgainClick={onTryAgainClick}
          onNextQuestionClick={onNextQuestionClick}
          onRestartLessonClick={onRestartLessonClick}
          isTestMode={isTestMode}
        />
      )}
    </LessonPreviewLayout>
  );
};

export default React.memo(QuizPreviewPage);
