import React from 'react';

import { Select } from 'components/shared/Select';

const SelectField = ({ field, helpers, onChanged, ...restProps }) => {
  const handleChange = (value) => {
    helpers.setValue(value);

    if (onChanged) {
      onChanged(value);
    }
  };

  return <Select {...field} {...restProps} onChange={handleChange} />;
};

export default React.memo(SelectField);
