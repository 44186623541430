import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import styles from './ModuleTitleCell.styles.scss';

const ModuleTitleCell = ({
  minWidth,
  maxWidth,
  expanded,
  preview,
  title,
  lessonsCount,
}) => (
  <div className={styles.cell} style={{ minWidth, maxWidth }}>
    {preview && <img className={styles.preview} src={preview} />}
    <div className={styles.inner}>
      <Typography
        className={styles.title}
        variant="body3"
        weight="medium"
        mode="compact"
      >
        {title || getTranslation('common.text.untitled')}
      </Typography>
      <div className={styles.count}>
        <Typography className={styles.countText} variant="body3" mode="compact">
          {getTranslation('dashboard_page.module_title_cell.count_text', {
            lessonsCount,
          })}
        </Typography>
        <Icon
          className={cn(styles.countIcon, { [styles.expandedIcon]: expanded })}
          name="chevronDown"
        />
      </div>
    </div>
  </div>
);

export default React.memo(ModuleTitleCell);
