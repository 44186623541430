import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Drawer } from 'components/shared/Drawer';
import { Typography } from 'components/shared/Typography';

import { SchoolPreview } from 'components/SchoolPreview';
import { CoursePreview } from 'components/CoursePreview';

import styles from './MoreItems.styles.scss';

const MoreItems = ({ open, onClose, item, courses }) => (
  <Drawer
    open={open}
    headerContent={getTranslation('components.more_items.header_text')}
    onClose={onClose}
  >
    <div className={styles.inner}>
      <div className={styles.header}>
        <SchoolPreview
          className={styles.preview}
          preview={item?.preview}
          name={item?.name}
        />
        <Typography
          className={styles.name}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {item?.name}
        </Typography>
      </div>
      <div className={styles.courses}>
        {courses.map((course) => (
          <div className={styles.course} key={course.id}>
            <CoursePreview
              className={styles.coursePreview}
              preview={course.preview}
            />
            <Typography
              className={styles.courseTitle}
              variant="body3"
              weight="medium"
              mode="compact"
            >
              {course.title || getTranslation('common.text.untitled')}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  </Drawer>
);

export default React.memo(MoreItems);
