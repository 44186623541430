import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCourseById } from 'store/course/actions';
import { getLessonById } from 'store/lesson/actions';

import { currentLessonSelector } from 'store/lesson/selectors';

import LessonPreviewProvider from './LessonPreviewProvider';

const LessonPreviewProviderContainer = () => {
  const { courseId, lessonId } = useParams();

  const dispatch = useDispatch();

  const lesson = useSelector(currentLessonSelector);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    Promise.all([
      dispatch(getCourseById(courseId)),
      dispatch(getLessonById({ courseId, lessonId })),
    ]).finally(() => {
      setLoading(false);
    });
  }, [lessonId]);

  return <LessonPreviewProvider loading={loading} lesson={lesson} />;
};

export default React.memo(LessonPreviewProviderContainer);
