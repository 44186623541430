import * as api from 'api/methods';

export const getFigmaFileImages = async (fileId) => {
  const images = [];

  const fileImages = await api.getFigmaFileImages(fileId);

  for (const [imageRef, imageUrl] of Object.entries(fileImages.meta.images)) {
    images.push({ imageRef, imageUrl });
  }

  return images;
};
