import { getTranslation } from 'helpers/getTranslation';

export const SCHOOLS_TABLE_COLUMNS = [
  {
    key: 'name',
    label: getTranslation('schools_page.table_keys.name'),
  },
  {
    key: 'email',
    label: getTranslation('schools_page.table_keys.email'),
  },
  {
    key: 'createdAt',
    label: getTranslation('schools_page.table_keys.created_at'),
  },
  {
    key: 'courses',
    label: getTranslation('schools_page.table_keys.courses'),
  },
  {
    key: 'status',
    label: getTranslation('schools_page.table_keys.status'),
  },
  {
    key: 'studentsCount',
    label: getTranslation('schools_page.table_keys.students_count'),
  },
];
