import React from 'react';
import cn from 'classnames';

import { COURSE_STRUCTURES } from 'constants/common';

import { getTranslation } from 'helpers/getTranslation';

import { Loading } from 'components/shared/Loading';

import { CoursePreviewLayout } from 'components/Layout';

import { Header } from '../CoursePreviewPage/components/Header';
import { Modules } from '../CoursePreviewPage/components/Modules';
import { CourseResult } from '../CoursePreviewPage/components/CourseResult';

import styles from './TrainingPreviewPage.styles.scss';

const TrainingPreviewPage = ({
  loading,
  training,
  previewType,
  setPreviewType,
}) => {
  return (
    <CoursePreviewLayout
      setPreviewType={setPreviewType}
      type={training && training.type}
    >
      {loading ? (
        <Loading className={styles.loading} />
      ) : (
        <div
          className={cn(styles.page, {
            [styles.pluginPage]:
              previewType === getTranslation('common.text.plugin'),
          })}
        >
          <div
            className={
              previewType === getTranslation('common.text.web') && styles.header
            }
          >
            <Header {...training} previewType={previewType} />
            {training.resultPreview && (
              <CourseResult
                preview={training.resultPreview}
                previewType={previewType}
              />
            )}
          </div>
          {training.structure === COURSE_STRUCTURES.MODULES && (
            <Modules modules={training.modules} />
          )}
        </div>
      )}
    </CoursePreviewLayout>
  );
};

export default React.memo(TrainingPreviewPage);
