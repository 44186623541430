import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';

import styles from './Toolbar.styles.scss';

const Toolbar = ({ lessonTitle, currentLesson, lessonsCount }) => (
  <div className={styles.toolbar}>
    <div className={styles.inner}>
      <Typography
        className={styles.title}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {lessonTitle || getTranslation('common.text.untitled')}
      </Typography>
      <div className={styles.textWrapper}>
        <Typography
          className={styles.text}
          variant="caption"
          weight="medium"
          mode="compact"
        >
          {currentLesson}/{lessonsCount}
        </Typography>
      </div>
    </div>
  </div>
);

export default React.memo(Toolbar);
