import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Tabs } from 'components/shared/Tabs';
import { Loading } from 'components/shared/Loading';
import { Typography } from 'components/shared/Typography';

import { MenuLayout } from 'components/Layout';

import { LessonReports } from './components/LessonReports';
import { LessonRatings } from './components/LessonRatings';
import { StudentsReviews } from './components/StudentsReviews';
import { StudentsUnsubscribeReasons } from './components/StudentsUnsubscribeReasons';
import { Surveys } from './components/Surveys';

import styles from './InboxPage.styles.scss';

const InboxPage = ({
  isRootAdmin,
  loading,
  lessonsRatingsCount,
  lessonsReportsCount,
  studentReviewCount,
  unsibscribeReasonsCount,
  surveysCount,
}) => (
  <MenuLayout mainClassName={styles.page}>
    <Typography variant="heading2" className={styles.title}>
      {getTranslation('inbox_page.title')}
    </Typography>
    {loading ? (
      <Loading />
    ) : (
      <Tabs
        variant="count"
        className={styles.tabs}
        innerClassName={styles.inner}
        items={
          isRootAdmin
            ? [
                {
                  label: getTranslation(
                    'inbox_page.tabs_labels.lessons_reports',
                  ),
                  count: lessonsReportsCount,
                  content: <LessonReports />,
                },
                {
                  label: getTranslation(
                    'inbox_page.tabs_labels.lesson_ratings',
                  ),
                  count: lessonsRatingsCount,
                  content: <LessonRatings />,
                },
                {
                  label: getTranslation('inbox_page.tabs_labels.reviews'),
                  count: studentReviewCount,
                  content: <StudentsReviews />,
                },
                {
                  label: getTranslation(
                    'inbox_page.tabs_labels.unsubscribe_reasons',
                  ),
                  count: unsibscribeReasonsCount,
                  content: <StudentsUnsubscribeReasons />,
                },
                {
                  label: 'Surveys',
                  count: surveysCount,
                  content: <Surveys />,
                },
              ]
            : [
                {
                  label: getTranslation(
                    'inbox_page.tabs_labels.lessons_reports',
                  ),
                  count: lessonsReportsCount,
                  content: <LessonReports />,
                },
                {
                  label: getTranslation(
                    'inbox_page.tabs_labels.lesson_ratings',
                  ),
                  count: lessonsRatingsCount,
                  content: <LessonRatings />,
                },
              ]
        }
        // actions={[
        //   {
        //     component: (
        //       <Button
        //         className={styles.action}
        //         variant="link"
        //         size="md"
        //         startIcon={<Icon name="export" />}
        //       >
        //            {getTranslation('common.button.export')}
        //       </Button>
        //     ),
        //   },
        // ]}
      />
    )}
  </MenuLayout>
);

export default React.memo(InboxPage);
