import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { EditableTextField } from 'components/FormikFields';

import styles from './SpoilerBlock.styles.scss';

const SpoilerBlock = ({ readOnly, namePrefix }) => (
  <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
    <FormikField
      name={`${namePrefix}.data.title`}
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        placeholder: getTranslation('components.theory_blocks.spoiler_title'),
        variant: 'heading3',
      }}
    />
    <FormikField
      name={`${namePrefix}.data.text`}
      withControl={false}
      component={EditableTextField}
      componentProps={{
        readOnly,
        placeholder: getTranslation(
          'components.theory_blocks.spoiler_placeholder',
        ),
        variant: 'text',
      }}
    />
  </div>
);

export default React.memo(SpoilerBlock);
