import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { schoolCodesSelector } from 'store/codes/selectors';

import { usePagination } from 'hooks/usePagination';

import SchoolCodesTable from './SchoolCodesTable';

const SchoolCodesTableContainer = ({ dataAction, params, ...restProps }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { currentPage, totalPages, perPage } = useSelector(schoolCodesSelector);

  const [onPerPageChange, onPrevPageClick, onNextPageClick] = usePagination({
    dataAction,
    currentPage,
    totalPages,
    perPage,
    sortBy: params.sortBy,
    sortDirection: params.sortDirection,
  });

  const handleStudentClick = (studentId) => {
    navigate(`/students/${studentId}`);
  };

  return (
    <SchoolCodesTable
      {...restProps}
      locale={i18n.language}
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      params={params}
      onPerPageChange={onPerPageChange}
      onPrevPageClick={onPrevPageClick}
      onNextPageClick={onNextPageClick}
      onStudentClick={handleStudentClick}
    />
  );
};

export default React.memo(SchoolCodesTableContainer);
