import React from 'react';
import cn from 'classnames';

import { getTranslation } from 'helpers/getTranslation';

import { Icon } from 'components/shared/Icon';
import { Typography } from 'components/shared/Typography';

import { WrongIcon } from 'components/WrongIcon';
import { CompletedIcon } from 'components/CompletedIcon';

import styles from './CurrentAnswer.styles.scss';

const CurrentAnswer = ({
  correct,
  url,
  text,
  onImageMouseDown,
  onImageMouseUp,
  previewClassName,
}) => {
  return (
    <div className={styles.answer}>
      <div
        className={cn(styles.preview, previewClassName, {
          [styles.previewCorrect]: correct,
          [styles.previewIncorrect]: !correct,
        })}
        onMouseDown={onImageMouseDown}
        onMouseUp={onImageMouseUp}
      >
        <img className={styles.previewImg} src={url} />
        <div
          className={cn(styles.iconWrapper, {
            [styles.iconWrapperCorrect]: correct,
            [styles.iconWrapperIncorrect]: !correct,
          })}
        >
          {correct ? (
            <CompletedIcon
              className={styles.iconWrapper}
              iconClassName={styles.icon}
            />
          ) : (
            <WrongIcon
              className={styles.iconWrapper}
              iconClassName={styles.icon}
            />
          )}
        </div>
      </div>

      <Typography
        className={styles.previewText}
        variant="body2"
        weight="medium"
        mode="compact"
      >
        {getTranslation(
          'difference_lesson_preview_page.current_answer.preview_text',
        )}
      </Typography>

      <div
        className={cn(styles.comment, {
          [styles.commentCorrect]: correct,
          [styles.commentIncorrect]: !correct,
        })}
      >
        <Typography className={styles.commentText} variant="body2">
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(CurrentAnswer);
