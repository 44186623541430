import React from 'react';

import { Checkbox } from 'components/shared/Checkbox';

const CheckboxField = ({
  readOnly,
  haveError,
  field,
  helpers,
  error,
  onChanged,
  ...restProps
}) => {
  const handleChange = (event) => {
    if (readOnly) {
      return;
    }

    field.onChange(event);

    if (onChanged) {
      onChanged(field.name, event.target.checked);
    }
  };

  return (
    <Checkbox
      {...restProps}
      checked={field.checked}
      name={field.name}
      onChange={handleChange}
      onBlur={field.onBlur}
    />
  );
};

export default React.memo(CheckboxField);
