import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import styles from './ModulePreview.styles.scss';

const ModulePreview = ({ className, preview, order }) =>
  preview ? (
    <img className={cn(styles.preview, className)} src={preview} />
  ) : (
    <div className={styles.outerСircle}>
      <div className={styles.innerСircle}>
        <Typography variant="body1" weight="semiBold" className={styles.order}>
          {order}
        </Typography>
      </div>
    </div>
  );

export default React.memo(ModulePreview);
