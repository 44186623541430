import React from 'react';
import cn from 'classnames';

import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import { FormikField } from 'components/FormikField';
import { UploadField, EditableTextField } from 'components/FormikFields';

import styles from './VideoBlock.styles.scss';
import { ErrorMessage } from 'formik';

const VideoBlock = ({ readOnly, namePrefix }) => (
  <div className={cn(styles.block, { [styles.readOnlyBlock]: readOnly })}>
    <FormikField
      name={`${namePrefix}.data.url`}
      withControl={false}
      component={UploadField}
      componentProps={{
        readOnly,
        variant: 'videoBlock',
        accept: {
          'video/mp4': ['.mp4'],
          'video/webm': ['.webm'],
          'video/avi': ['.avi'],
          'video/mov': ['.mov'],
        },
      }}
    />
    {/* <Typography variant="caption" className={styles.rulesText}>
      You can only upload: .mp4, .webm, .avi, .mov
    </Typography> */}
    <ErrorMessage name={`${namePrefix}.data.url`}>
      {(message) => (
        <Typography className={styles.errorText} variant="caption">
          {message}
        </Typography>
      )}
    </ErrorMessage>
    <div className={styles.caption}>
      <div className={styles.captionLine} />
      <FormikField
        name={`${namePrefix}.data.caption`}
        withControl={false}
        component={EditableTextField}
        componentProps={{
          readOnly,
          variant: 'text',
          placeholder: getTranslation(
            'components.theory_blocks.caption_placeholder',
          ),
        }}
      />
    </div>
  </div>
);

export default React.memo(VideoBlock);
