import React from 'react';
import cn from 'classnames';

import LogoIcon from 'assets/img/logo.svg';

import { Icon } from 'components/shared/Icon';

import styles from './PluginPreviewWrapper.styles.scss';

const PluginPreviewWrapper = ({
  children,
  size,
  isTheoryLesson,
  toolbarComponent,
}) => (
  <div className={cn(styles.preview, styles[size])}>
    <div className={styles.header}>
      <LogoIcon className={styles.logo} />
      <Icon className={styles.icon} name="close" />
    </div>
    {toolbarComponent}
    <div
      className={cn(styles.inner, {
        [styles.theoryLessonInner]: isTheoryLesson,
      })}
    >
      {children}
    </div>
  </div>
);

PluginPreviewWrapper.defaultProps = {
  size: 'lg',
};

export default React.memo(PluginPreviewWrapper);
