import React from 'react';

import { Typography } from 'components/shared/Typography';

import { getTranslation } from 'helpers/getTranslation';

import { Action } from '../Action';
import { ColorPicker } from '../ColorPicker';

import styles from './PluginPreviewPage.styles.scss';
import { ColorItem } from '../ColorItem';

const PluginPreviewPage = ({
  nextLoading,
  isLastLessonInCourse,
  submitted,
  imageRef,
  lesson,
  currentColor,
  currentImage,
  matchPercent,
  onColorChange,
  onCheckColorClick,
  onNextLessonClick,
  isTestMode,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.inner}>
        <Typography
          className={styles.text}
          variant="subtitle"
          weight="semiBold"
        >
          {lesson.text}
        </Typography>
        <div className={styles.main}>
          {submitted ? (
            <div className={styles.items}>
              <ColorItem
                image={currentImage}
                color={lesson.color}
                text={getTranslation(
                  'color_match_lesson_preview_page.original',
                )}
                withMatchInfo={false}
              />
              <ColorItem
                image={currentImage}
                color={currentColor}
                text={getTranslation(
                  'color_match_lesson_preview_page.your_choice',
                )}
                matchPercent={matchPercent}
                withMatchInfo
              />
            </div>
          ) : (
            <>
              <div
                className={styles.image}
                ref={imageRef}
                dangerouslySetInnerHTML={{ __html: currentImage }}
              />
              <ColorPicker className={styles.picker} onChange={onColorChange} />
            </>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        {isTestMode && (
          <Action
            nextLoading={nextLoading}
            isLastLessonInCourse={isLastLessonInCourse}
            submitted={submitted}
            onNextLessonClick={onNextLessonClick}
            onCheckClick={onCheckColorClick}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(PluginPreviewPage);
