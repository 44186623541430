import React from 'react';
import cn from 'classnames';

import { Icon } from 'components/shared/Icon';

import styles from './PropertySelect.styles.scss';

const PropertySelect = ({
  className,
  checked,
  property,
  dependProperty,
  renderProperty,
  ...restProps
}) => (
  <div
    className={cn(
      styles.select,
      { [styles.selectChecked]: checked },
      className,
    )}
  >
    {renderProperty({
      key: property.key,
      value: property.value,
      dependValue: dependProperty ? dependProperty.value : null,
    })}
    <input
      {...restProps}
      className={styles.field}
      type="checkbox"
      checked={checked}
    />
    <div className={styles.iconWrapper}>
      <Icon className={styles.icon} name="checkMini" />
    </div>
  </div>
);

export default React.memo(PropertySelect);
